
const RTAPIIcon = ({fill, height}: {height?: number; fill?: string}) => {

  return <span className='p-[2.5px] inline-block w-[32px]' >
    <svg width="28" height={height ?? "30"} viewBox="0 0 18 21" fill="#4b7cf7" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.382 4.968C4.97575 3.69107 6.95781 2.99674 9 3C11.125 3 13.078 3.736 14.618 4.968L16.071 3.515L17.485 4.929L16.032 6.382C17.0897 7.70615 17.7521 9.30203 17.9428 10.986C18.1336 12.6699 17.845 14.3735 17.1103 15.9007C16.3756 17.4279 15.2246 18.7167 13.7898 19.6186C12.355 20.5205 10.6947 20.999 9 20.999C7.30528 20.999 5.64498 20.5205 4.2102 19.6186C2.77541 18.7167 1.62443 17.4279 0.889727 15.9007C0.155021 14.3735 -0.133558 12.6699 0.0572003 10.986C0.247958 9.30203 0.910301 7.70615 1.96801 6.382L0.515005 4.93L1.92901 3.516L3.382 4.968ZM10 11V6.495L5.00001 13H8V17.5L13 11H10ZM5.00001 0H13V2H5.00001V0Z" fill={fill ?? "#4b7cf7"} />
    </svg>
  </span>
}

export default RTAPIIcon;