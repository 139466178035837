/* eslint-disable @typescript-eslint/no-unused-vars */
import { first, keys, values } from 'lodash';
import { parse } from 'papaparse';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilStateLoadable } from 'recoil';
import { HorizontalLine } from '../../../components/HorizontalLine';
import LoadingIndicator from '../../../components/LoadingIndicator';
import DatabaseSelector from '../../database/DatabaseSelector';

import {
  DatabasesQueryState,
  SelectedDBState,
} from '../../database/DatabaseState';
import SchemaSelector from '../../database/SchemaSelector';
import {
  SchemasQueryState,
  SelectedSchemaState,
} from '../../database/SchemaState';
import './table-cells.css';
import csvfile from '../../../assets/icons/csv.svg';
import { IoIosArrowBack } from 'react-icons/io';
import PaleButton from '../../../components/interactive/PaleButton';
import { BsAsterisk } from 'react-icons/bs';
import { UploadPayload } from './types';
import { analytics } from '../../../App';

interface ISubmitUploadProps {
  s3: string;
  file: File;
  tableName?: string;
  onPrev: () => void;
  onNext: (payload: UploadPayload) => void;
}

export default function SubmitUpload({
  s3,
  file,
  tableName,
  onPrev,
  onNext,
}: ISubmitUploadProps) {
  const [showLoading, toggleLoading] = useState(false);
  const [rows, setRows] = useState<string[][] | undefined>(undefined);
  const [newTable, setNewTable] = useState<string | undefined>(
    tableName || file.name.split('.')[0]
  );
  const [selectedDB, setDB] = useRecoilState(SelectedDBState);
  const [selectedSchema, setSchema] = useRecoilState(SelectedSchemaState);
  const [{ state: databasesState }] =
    useRecoilStateLoadable(DatabasesQueryState);
  const databasesLoading = databasesState === 'loading';
  const [{ state: schemasState }] = useRecoilStateLoadable(SchemasQueryState);
  const schemasLoading = schemasState === 'loading';

  const handleDatabaseSelect = useCallback(
    (value?: string) => {
      setDB(value);
    },
    [setDB]
  );

  const handleSchemaSelect = useCallback(
    (value: string) => {
      if (selectedDB) {
        setSchema({ database: selectedDB, schema: value });
      }
    },
    [selectedDB, setSchema]
  );

  useEffect(() => {
    const loadingTimeout = setTimeout(() => toggleLoading(true), 1000);
    const parseCSV = () => {
      return new Promise((resolve) => {
        const csvRows: string[][] = [];
        if (file) {
          parse(file, {
            worker: true,
            header: true,
            step(row, parser) {
              const rowData = row.data as string[];
              if (rowData) {
                csvRows.push(rowData);
              }
              if (csvRows.length > 4) {
                parser.abort();
              }
            },
            complete: () => {
              toggleLoading(false);
              clearTimeout(loadingTimeout);
              setRows([...csvRows]);
              resolve('done');
            },
          });
        }
      });
    };
    parseCSV();
  }, []);

  useEffect(() => {
    /*
     * This is hard coded for the 'main' category
     */
    if (selectedDB === 'AwsDataCatalog') {
      setSchema({ database: selectedDB, schema: 'default' });
    }
  }, [selectedDB]);

  return (
    <>
      <div className='flex px-6 py-3 items-center'>
        <PaleButton
          onClick={() => {
            onPrev();
          }}
        >
          <IoIosArrowBack size='1.8rem' />
          <div className='w-3' />
          Back
        </PaleButton>
        <div className='flex-1' />
        <button
          className='btn btn-primary btn-wide'
          onClick={() => {
            if (!newTable) {
              toast.error('Table name cannot be empty!');
            } else if (selectedDB && selectedDB && selectedSchema) {
              analytics.track('Upload CSV - Submitted', {})
              onNext({
                s3,
                database: selectedDB,
                schema: selectedSchema.schema || '',
                table: newTable,
              });
            }
          }}
        >
          Submit
        </button>
      </div>
      <HorizontalLine />
      <div className='flex flex-col w-full h-full'>
        <div className='flex w-full items-center h-16'>
          <div className='h-full inline-flex items-center w-1/2'>
            <div className='w-6 h-full flex-shrink-0' />
            <img src={csvfile} className='h-3/4 w-auto' />
            <div className='w-3' />
            <span className='text-xl'>CSV File</span>
          </div>
          <div className='h-full inline-flex items-center bg-base-200 text-xl w-1/2'>
            <div className='w-3' />
            Data Preview (Top 5 Rows Shown)
          </div>
        </div>
        <HorizontalLine />
        <div className='w-full h-[55vh] inline-flex space-x-5'>
          <div className='flex-1 h-full inline-flex w-1/2'>
            <div className='w-6 h-full' />
            <div className='flex-1 h-full'>
              <div className='h-4' />
              {/* <div className='inline-flex w-full items-center'>
                <span className='font-bold w-1/5 flex-shrink-0'>Category</span>
                {databasesLoading && (
                  <>
                    <div className='w-1 flex-shrink-0' />
                    <LoadingIndicator
                      radius='1rem'
                      stroke='0.5rem'
                      className='justify-center'
                    />
                  </>
                )}
                <div className='w-5' />
                <BsAsterisk className='text-error' />
                <div className='w-5' />
                <DatabaseSelector
                  value={selectedDB}
                  onSelect={handleDatabaseSelect}
                />
              </div>
              <div className='h-3' /> */}
              {/* <div className='inline-flex w-full items-center'>
                <div className='w-[27%] flex-shrink-0' />
                <span className='text-sm font-light'>
                  Category is how the data is organized based on the common
                  access needs.
                </span>
              </div>
              <div className='h-8' />
              <div className='inline-flex w-full items-center'>
                <span className='font-bold w-1/5 flex-shrink-0'>Database</span>
                {schemasLoading && (
                  <>
                    <div className='w-1 flex-shrink-0' />
                    <LoadingIndicator
                      radius='1rem'
                      stroke='0.5rem'
                      className='justify-center'
                    />
                  </>
                )}
                <div className='w-5' />
                <BsAsterisk className='text-error' />
                <div className='w-5' />
                <SchemaSelector
                  value={selectedSchema?.schema}
                  onSelect={handleSchemaSelect}
                />
              </div>
              <div className='h-3' /> */}
              {/* <div className='inline-flex w-full items-center'>
                <div className='w-[27%] flex-shrink-0' />
                <span className='text-sm font-light'>
                  Database is a collection of tables that usually come from the
                  same data source, such as the same blockchain Ethereum.
                </span>
              </div>
              <div className='h-8' /> */}
              <div className='inline-flex w-full items-center'>
                <span className='font-bold w-1/5 flex-shrink-0'>
                  Table Name
                </span>
                <div className='w-5' />
                <BsAsterisk className='text-error' />
                <div className='w-5' />
                <input
                  className='input input-sm input-bordered w-full'
                  type='text'
                  placeholder='Your table name'
                  onChange={(e) => setNewTable(e.target.value)}
                  defaultValue={newTable}
                />
              </div>
              <div className='h-3' />
              <div className='inline-flex w-full items-center'>
                <div className='w-[27%] flex-shrink-0' />
                <span className='text-sm font-light'>
                  Pick a name to help you identify this table in ZettaBlock, the
                  default name is the name of your uploaded file.
                </span>
              </div>
              <div className='h-8' />
              <div className='inline-flex w-full'>
                <div className='w-[27%] flex-shrink-0' />
                <button
                  className='btn btn-primary btn-sm w-1/5'
                  onClick={() => {
                    if (!newTable) {
                      toast.error('Table name cannot be empty!');
                    } else {
                      onNext({
                        s3,
                        database: selectedDB || '',
                        schema: selectedSchema?.schema || '',
                        table: newTable,
                      });
                    }
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className='flex-1 h-full w-1/2 bg-base-200'>
            <div className='h-4' />
            <div className='max-h-[calc(100%_-_3rem)] overflow-auto px-3'>
              {showLoading ? (
                <div className='w-full h-full flex flex-col items-center justify-center'>
                  <h2 className='text-lg flex'>Loading Preview</h2>
                  <div className='h-5' />
                  <LoadingIndicator radius='8rem' stroke='0.5rem' />
                </div>
              ) : (
                rows && (
                  <table className='table w-full border-solid'>
                    <thead className='bg-base-300 w-full'>
                      <tr>
                        {keys(first(rows)).map((col, index) => (
                          <th className='border-2 border-gray-300' key={index}>
                            {col}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row, index) => (
                        <tr key={index}>
                          {values(row).map((value, valIndex) => (
                            <td
                              key={valIndex}
                              className='border-2 border-gray-300'
                            >
                              {value}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
