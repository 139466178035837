/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { TableApi, TableApiV2 } from "../../api/client";
import { getTableDetailById } from "../workspace/apis";
import { PoTableMetadata } from "../../api/__gen__/data-contracts";

const getTables = async (publicVisible: boolean) => {
  const client = publicVisible ? TableApi() : TableApiV2();

  const resp = await client.catalogTreeList();

  return resp.data || {};
}

const LIST = [
  'Raw',
  'Decoded',
  'Transfers',
  'Balances',
  'DEX',
  'NFT',
  'Misc',
]

const convertToTree = (catalog: any): any => {
  return Object.keys(catalog).map(key => {
    return {
      name: key,
      children: LIST.map((label: string) => {
        if (!catalog[key][label]) return null
        return {
          name: label,
          children: catalog[key][label]
        }
      }).filter(e => e !== null)
    }
  })
}

interface CatalogProps {
  name: string,
  children: {
    name: string,
    children: PoTableMetadata[]
  }[]
}

const useCatalogService = (publicVisible: boolean) => {
  const [isLoading, setIsLoading] = useState(false)

  const [catalog, setCatalog] = useState<CatalogProps[]>([])
  const [myTables, setMyTables] = useState<PoTableMetadata[]>([])
  const [priceTables, setPriceTables] = useState<PoTableMetadata[]>([])

  const [tableDetail, setTableDetail] = useState<any>([])

  const fetchAndSetTableDetail = async (tableId?: string) => {
    setIsLoading(true)
    const resp = await getTableDetailById(tableId || '')
    setTableDetail(resp.column || [])
    setIsLoading(false)

    return resp.column
  }

  const fetchAndSetTables = async () => {
    setIsLoading(true)
    const catalogRes = await getTables(publicVisible);

    setCatalog(convertToTree(catalogRes.catalog || {}));
    setMyTables((catalogRes as any)?.my_tables || [])
    setPriceTables((catalogRes as any)?.price_tables || [])
    setIsLoading(false)
  }

  useEffect(() => {
    fetchAndSetTables()
  }, [])


  return {
    catalog,
    isLoading,
    fetchAndSetTableDetail,
    tableDetail,
    myTables,
    priceTables
  }
}

export default useCatalogService;