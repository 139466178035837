/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ExploreItem, ExploreItemType } from "./components/ExploreItem"
import { useExploreData } from "../useExploreData"
import LoadingIndicator from "../../components/LoadingIndicator"
import { ExploreTopBar } from "./ExploreApis"
import { GRAPHQL_APIS } from "../Explore"



const ExploreGraphQL = () => {
  return <div className="min-w-[1240px]">
    <ExploreTopBar
      text1="Explore"
      text2={
        `Browse GraphQL APIs (${2})`
      }
    />

    <div >
      {/* List of Items */}
      <div id="section-content" className=" flex justify-between flex-wrap  min-w-[1080px] w-[1080px] 2xl:min-w-[1600px] 2xl:w-[1600px] 3xl:mx-auto mx-[80px] 2xl:mx-[auto] mt-[50px]">
        {
          GRAPHQL_APIS.map((item, index) => {
            return <ExploreItem type={ExploreItemType.GRAPHQL} key={index}
              title={item?.name}
              author={item?.owner?.name}
              imgUrl={item?.image}
              description={item?.description}
              item={item}
              from="explore"
            />
          })
        }
      </div>
    </div>
  </div>
}

export default ExploreGraphQL;