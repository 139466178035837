// https://coolors.co/0057ff-e86413-2bd6b4-ecd436-2480d3-d68092-ff1428-48a9a6-655257-64e477
export const defaultChartTheme = [
  '#0057FF',
  '#E86413',
  '#2BD6B4',
  '#ECD436',
  '#2480D3',
  '#D68092',
  '#FF1428',
  '#48A9A6',
  '#655257',
  '#64E477',
];

export const BroswerFloatLengthMax = 12;
