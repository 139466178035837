import classNames from 'classnames';
import { ReactNode } from 'react';
import { AiFillLayout } from 'react-icons/ai';
import { MdDashboard } from 'react-icons/md';
import { RiArchiveDrawerFill, RiProfileFill, RiTimerFlashLine } from 'react-icons/ri';
import { useNavigate } from 'react-router';
import { HorizontalLine } from '../../../components/HorizontalLine';
import { useWorkspace } from '../../../layouts/components/workspace-hooks';
import { slugify } from '../../../utils/slugify';
import envs from '../../../config/envs';
import useFeatures from '../../../hooks/useFeatures';
import { analytics } from '../../../App';

function NavItem({ label, icon, isBeta }: { label: string; icon: ReactNode, isBeta?: boolean }) {
  const [{ menu, isMyWorkspace }] = useWorkspace();

  const navigate = useNavigate();

  const menuCls = isMyWorkspace ? 'text-purple-600' : '';
  const path = slugify(label.toLowerCase());

  return (
    <div
      className={classNames(
        'inline-flex btn btn-ghost items-center normal-case text-[0.94rem] px-1 opacity-50',
        menu === path && ['text-primary font-bold opacity-100', menuCls],
        isBeta && ' relative pr-8'
      )}
      onClick={() => {
        analytics.track(`Explorer Navbar - ${label} - clicked`);
        navigate(path);
      }}
    >
      {icon}
      <div className='w-2' />
      {label}
      {isBeta && <span className={classNames('p-[2px] h-4 text-[8px] badge badge-secondary absolute top-[2px] right-[2px]')}>
        alpha
      </span>}
    </div>
  );
}
export default function ExplorerNavbar() {
  const [{ isMyWorkspace }] = useWorkspace();
  const showUnderDevFeatures = useFeatures();

  return (
    <div className='flex flex-col w-full'>
      <div className='flex items-center w-full justify-between px-4 py-3'>
        <div className='inline-flex items-center space-x-3'>
          <NavItem label='APIs' icon={<MdDashboard size='1rem' />} />
          <NavItem label='Queries' icon={<AiFillLayout size='1rem' />} />
          <NavItem label='Dashboards' icon={<RiProfileFill size='1rem' />} />
          {!envs.isCircleIntegrationEnabled && isMyWorkspace && (
            <NavItem
              label='Data Sources'
              icon={<RiArchiveDrawerFill size='1rem' />}
            />
          )}
          {
            isMyWorkspace && (<NavItem isBeta label='Realtime API' icon={<RiTimerFlashLine size='1rem' />} />)
          }
          {
            showUnderDevFeatures && isMyWorkspace && (<NavItem
              isBeta
              label='Contracts' icon={<RiArchiveDrawerFill size='1rem' />} />)
          }
        </div>
      </div>
      <HorizontalLine />
    </div>
  );
}
