import classNames from 'classnames';
import { formatDistance } from 'date-fns/esm';
import { memo, useRef } from 'react';
import { ModelGetUserApiProgressResponse } from '../../api/__gen__/data-contracts';
import useClickOutside from '../../hooks/click-ouside';

function ViewApiProgressPopup({
  idxValues,
  open,
  setOpen,
}: {
  open?: boolean;
  setOpen: (v: boolean) => void;
  idxValues: ModelGetUserApiProgressResponse;
}) {
  const ref = useRef<HTMLDivElement | null>(null);

  useClickOutside(ref, () => {
    setOpen(false);
  });

  return (
    <div
      ref={ref}
      className={classNames('w-0 h-0 relative', {
        hidden: !open,
      })}
    >
      <div
        className={classNames(
          'absolute shadow-xl right-0 bg-white rounded-xl top-3 z-20 px-3 py-2',
          {
            hidden: !open,
          }
        )}
      >
        <div className='flex flex-col gap-2 whitespace-nowrap w-[13rem]'>
          <div className='font-bold text-2xl'>Progress</div>
          <div className='py-2'>
            <div className='divider-x' />
          </div>
          {[
            {
              label: 'Progress',
              value: `${idxValues.progress}%`,
            },
            {
              label: 'Data Freshness',
              value: idxValues
                ? `${formatDistance(
                    new Date(
                      // @ts-ignore
                      new Date() -
                        (idxValues.dataFreshnessInSeconds || 0) * 1000
                    ),
                    new Date()
                  )}`
                : '-',
            },
          ].map((i) => (
            <div key={i.label} className='flex justify-between'>
              <span>{i.label}</span>
              <span>{i.value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default memo(ViewApiProgressPopup);
