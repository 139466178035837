// const fetch

import { ModelCreateQueryPromptRequest } from '../../api/__gen__/data-contracts';
import { QuerypromptApi } from '../../api/client';

export const fetchQueryPrompts = () => {
  const client = QuerypromptApi();

  return client;

  // return client.querypromptDetail();
};

export const searchQueryPrompts = async (
  chain: string,
  tags: string[],
  type: string
) => {
  const client = QuerypromptApi();

  const res = await client.searchCreate({
    chain,
    tags,
    type: type || 'graphql',
  });

  return res.data.query_prompts || [];
};

export const getAllQueryPrompts = async () => {
  const client = QuerypromptApi();

  const eth = await client.searchCreate({
    chain: 'ethereum',
    tags: [],
    type: 'graphql',
  });
  const sui = await client.searchCreate({
    chain: 'sui',
    tags: [],
    type: 'graphql',
  });
  const stellar = await client.searchCreate({
    chain: 'stellar',
    tags: [],
    type: 'graphql',
  });
  const defi = await client.searchCreate({
    chain: 'defi',
    tags: [],
    type: 'graphql',
  });

  return {
    ethereum: eth.data.query_prompts || [],
    sui: sui.data.query_prompts || [],
    stellar: stellar.data.query_prompts || [],
    defi: defi.data.query_prompts || [],
  };
};

export const createQueryPrompt = async (
  request: ModelCreateQueryPromptRequest
) => {
  const client = QuerypromptApi();

  const res = await client.querypromptCreate({ ...request, type: 'graphql' });

  return res.data;
};

export const updateQueryPrompt = async (
  id: string,
  request: ModelCreateQueryPromptRequest
) => {
  const client = QuerypromptApi();

  const res = await client.querypromptCreate2(id, { ...request });

  return res.data;
};

export const deleteQueryPrompt = async (id: string) => {
  const client = QuerypromptApi();

  const res = await client.querypromptDelete(id);

  return res.data;
};
