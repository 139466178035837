import produce from 'immer';
import { first, sortBy, values } from 'lodash';
import { useState } from 'react';
import { useRecoilCallback, useRecoilState } from 'recoil';
import { CommonChartEntity } from '../chart/types';

import { AChartsList, removeChart } from './ChartsState';
import { StaticTabType } from './components/TabButtonV3';
import { PreviewQueryResultId } from './QueryBuilderHooks';
import { AStaticTabType, SQueryResults } from './QueryState';

export const useDeleteChart = () => {
  const [charts] = useRecoilState(AChartsList);
  const [loading, setLoading] = useState(false);

  const deleteChart = useRecoilCallback(
    ({ set }) =>
      async (chart: CommonChartEntity) => {
        setLoading(true);

        // TODO deal with error
        // No await
        removeChart({ chartId: chart.id });

        const nCharts = produce(charts, (draft) => {
          delete draft[chart.id];
        });

        const firstChart = first(sortBy(values(nCharts), 'createdTime'));
        if (firstChart) {
          set(AStaticTabType, {
            label: `${firstChart.type} chart`,
            key: firstChart.id,
          });
        } else {
          set(AStaticTabType, {
            label: '+ new visualization',
          });
        }

        set(AChartsList, nCharts);

        setLoading(false);
      },
    [charts]
  );

  return [deleteChart, loading] as const;
};

export const useDeletePreviewTab = () => {
  const deletePreviewTab = useRecoilCallback(
    ({ set }) =>
      async (nextTab: StaticTabType) => {
        set(AStaticTabType, nextTab);
        set(SQueryResults(PreviewQueryResultId), null);
      }
  );
  return [deletePreviewTab] as const;
};