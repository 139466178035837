import { useNavigate } from 'react-router';
import Icon from './Icon';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Button, Form, Input, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useCreateDashboard } from '../../modules/dashboard/NewDashboardHooks';
import { track } from '../tracking';

const BuildButton = () => {
  const navigate = useNavigate();

  const [showAddDashboard, setShowAddDashboard] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [createDashboard, { loading }] = useCreateDashboard();

  return (
    <>
      {' '}
      <button
        onClick={() => {
          track('Build Button Clicked', {
            path: '/v2/build',
          });
          navigate('/v2/build', {
            replace: true,
            state: { from: 'build-button' },
          });
        }}
        className='bg-[#dae0ff] h-[30px] text-[#263DFF] flex items-center text-lg rounded-[4px] mr-[5px]'
      >
        <span className='flex items-center justify-center gap-2 h-full border-r border-[#B7C2FF] text-[12px] px-[16.5px] py-[8.5px]'>
          <Icon iconName='buildIcon' className='w-[14px]' />
          BUILD
        </span>
        <div
          className='dropdown dropdown-bottom dropdown-end'
          onClick={(e) => e.stopPropagation()}
        >
          <div tabIndex={0} role='button'>
            <div className='flex items-center justify-center p-[8px]'>
              <Icon iconName='plusIcon' className='w-[14px]' />
            </div>
          </div>
          <ul
            tabIndex={0}
            className=' mt-[10px] bg-[#fff] dropdown-content z-[1] menu shadow rounded-[4px] w-[133px] border-[#E3E6ED] border'
          >
            <div className='border-[#e3e6ed]'>
              <li>
                <div
                  className='p-[10px] flex items-center gap-[10px] text-[12px] leading-[12px] text-[#1A1A1A] '
                  onClick={() => {
                    track('Build Builder Button Clicked', {
                      path: '/v2/build',
                    });
                    navigate('/v2/build', {
                      replace: true,
                      state: { from: 'build-button' },
                    });
                  }}
                >
                  <Icon iconName='builder' className='w-[14px] h-[14px]' />
                  Query Builder
                </div>
              </li>
              <li className='border-y'>
                <div
                  className='p-[10px] flex items-center gap-[10px] text-[12px] leading-[12px] text-[#1A1A1A]'
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowAddDashboard(true);
                  }}
                >
                  <Icon iconName='dash' className='w-[14px] h-[14px]' />
                  Dashboard
                </div>
              </li>
              <li>
                <Link
                  className='p-[10px] flex items-center gap-[10px] text-[12px] leading-[12px] text-[#1A1A1A] '
                  to='/v2/data-sources'
                >
                  <Icon iconName='datasource' className='w-[14px] h-[14px]' />
                  Datasource
                </Link>
              </li>
            </div>
          </ul>
        </div>
      </button>
      <Modal
        open={showAddDashboard}
        onCancel={() => setShowAddDashboard(false)}
        title='Create Dashboard'
        footer={null}
      >
        <Form className='mt-10' labelCol={{ span: 6 }}>
          <Form.Item label='Name'>
            <Input
              name='name'
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label='Description' className='mt-2'>
            <TextArea
              name='description'
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Form.Item>
          <div className='flex items-center justify-end'>
            <Button
              disabled={loading}
              onClick={async () => {
                //
                const newDash = await createDashboard({
                  displayName: name,
                  uiOptions: {
                    rowHeight: 50,
                    autoSave: true,
                  },
                  description,
                });
                setShowAddDashboard(false);
                setName('');
                setDescription('');

                navigate(`/v2/workspace/dashboards/${newDash?.id}`);
              }}
            >
              Create Dashboard
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default BuildButton;
