/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelAddPaymentMethodRequest,
  ModelAddPaymentMethodResponse,
  ModelDeletePaymentMethodRequest,
  ModelDeletePaymentMethodResponse,
  ModelGetPaymentSubscriptionDetailsResponse,
  ModelListPaymentMethodsResponse,
  ModelListPaymentProductsResponse,
  ModelListTransactionHistoryResponse,
  ModelPaymentSubscriptionRequest,
  ModelPaymentSubscriptionResponse,
  ModelSetDefaultPaymentMethodRequest,
  ModelSetDefaultPaymentMethodResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Payment<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description list the transaction history
   *
   * @tags payment
   * @name HistoryListUserIdList
   * @summary list the transaction history
   * @request GET:/payment/history/list/:user_id
   */
  historyListUserIdList = (
    userId: string,
    query: { start_time: string; end_time: string },
    params: RequestParams = {}
  ) =>
    this.request<ModelListTransactionHistoryResponse, any>({
      path: `/payment/history/list/${userId}`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description add payment method
   *
   * @tags payment
   * @name MethodAddCreate
   * @summary add payment method
   * @request POST:/payment/method/add
   */
  methodAddCreate = (
    request: ModelAddPaymentMethodRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelAddPaymentMethodResponse, any>({
      path: `/payment/method/add`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description set default payment method
   *
   * @tags payment
   * @name MethodDefaultCreate
   * @summary set default payment method
   * @request POST:/payment/method/default
   */
  methodDefaultCreate = (
    request: ModelSetDefaultPaymentMethodRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelSetDefaultPaymentMethodResponse, any>({
      path: `/payment/method/default`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description delete payment method
   *
   * @tags payment
   * @name MethodDeleteCreate
   * @summary delete payment method
   * @request POST:/payment/method/delete
   */
  methodDeleteCreate = (
    request: ModelDeletePaymentMethodRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelDeletePaymentMethodResponse, any>({
      path: `/payment/method/delete`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description list payment methods
   *
   * @tags payment
   * @name MethodListUserIdList
   * @summary list payment methods
   * @request GET:/payment/method/list/:user_id
   */
  methodListUserIdList = (userId: string, params: RequestParams = {}) =>
    this.request<ModelListPaymentMethodsResponse, any>({
      path: `/payment/method/list/${userId}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description list all available products for a user
   *
   * @tags payment
   * @name ProductListUserIdList
   * @summary list all available products for a user
   * @request GET:/payment/product/list/:user_id
   */
  productListUserIdList = (userId: string, params: RequestParams = {}) =>
    this.request<ModelListPaymentProductsResponse, any>({
      path: `/payment/product/list/${userId}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description downgrade the subscription
   *
   * @tags subscription
   * @name SubscriptionDowngradeCreate
   * @summary downgrade the subscription
   * @request POST:/payment/subscription/downgrade
   */
  subscriptionDowngradeCreate = (
    request: ModelPaymentSubscriptionRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelPaymentSubscriptionResponse, any>({
      path: `/payment/subscription/downgrade`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get subscription details
   *
   * @tags subscription
   * @name SubscriptionGetUserIdList
   * @summary get subscription details
   * @request GET:/payment/subscription/get/:user_id
   */
  subscriptionGetUserIdList = (userId: string, params: RequestParams = {}) =>
    this.request<ModelGetPaymentSubscriptionDetailsResponse, any>({
      path: `/payment/subscription/get/${userId}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description upgrade the subscription
   *
   * @tags subscription
   * @name SubscriptionSubscribeCreate
   * @summary upgrade the supscription
   * @request POST:/payment/subscription/subscribe
   */
  subscriptionSubscribeCreate = (
    request: ModelPaymentSubscriptionRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelPaymentSubscriptionResponse, any>({
      path: `/payment/subscription/subscribe`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description upgrade the subscription
   *
   * @tags subscription
   * @name SubscriptionUpgradeCreate
   * @summary upgrade the supscription
   * @request POST:/payment/subscription/upgrade
   */
  subscriptionUpgradeCreate = (
    request: ModelPaymentSubscriptionRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelPaymentSubscriptionResponse, any>({
      path: `/payment/subscription/upgrade`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
