import { useState, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { createMyDashboard } from './dashboard-api';
import {
  convertUIDashboardToDBModel,
  DashboardFormData,
} from './dashboard-models';
import { MyDashboardListState } from './DashboardListState';

export function useCreateDashboard() {
  const [loading, setLoading] = useState(false);
  const [myDashboards, setMyDashboards] = useRecoilState(MyDashboardListState);

  const createDashboard = useCallback(
    async (formData: Partial<DashboardFormData>) => {
      setLoading(true);

      const resp = await createMyDashboard(
        // @ts-ignore
        convertUIDashboardToDBModel(formData)
      );

      setLoading(false);

      const newDash = resp?.data as DashboardFormData;

      if (resp?.data) {
        setMyDashboards([
          {
            ...formData,
            ...newDash,
            createdTime: new Date().getTime(),
          },
          ...(myDashboards || []),
        ]);
        return newDash;
      }

      return null;
    },
    [myDashboards, setMyDashboards]
  );

  return [createDashboard, { loading }] as const;
}
