/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames"
import { useEffect, useState } from "react"
import { FaRegFolder, FaRegFile } from "react-icons/fa"
import { FiFolderPlus } from "react-icons/fi"
import { IoIosMore, IoIosArrowDown, IoIosArrowForward } from "react-icons/io"
import { Table } from "antd"
import { MdOutlineSpaceDashboard } from "react-icons/md"
import { EntityTypes } from "./constant"
import { ImTable2 } from "react-icons/im"
import { getRecents } from "./apis"

const getIconByType = (type: string) => {
  switch (type) {
    case EntityTypes.dashboard:
      return <MdOutlineSpaceDashboard size={14} />
    case EntityTypes.table:
      return <ImTable2 />
    case EntityTypes.api:
      return <FaRegFile />

    default:
      return <FaRegFile />
  }
}

const RecentWorkspace = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [recentItems, setRecentItems] = useState<any>([])

  const fetchAndSetRecentItems = async () => {
    setIsLoading(true)
    const data = await getRecents()
    setRecentItems(data)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchAndSetRecentItems()
  }, [])

  const columns = [
    {
      title: "TITLE",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => {
        return <div className={
          classNames(
            "flex gap-2 items-center",

          )
        }>
          {
            getIconByType(record.type)
          }

          <div className="line-clamp-1">
            {text}
          </div>
        </div>
      }
    },
    {
      title: "TYPE",
      dataIndex: "type",
      key: "type"
    },
    // {
    //   title: "Created",
    //   dataIndex: "created_at",
    //   key: "created_at"
    // },
    {
      title: "UPDATED",
      dataIndex: "updated_at",
      key: "updated_at"
    }, {
      // title: "Role",
      // dataIndex: "role",
      // key: "role"
    },
    // {
    //   title: 'Action',
    //   dataIndex: '',
    //   key: 'x',
    //   render: () => <IoIosMore />,
    // },
  ]
  return <Table
    expandable={
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        expandIcon: ({ expanded, onExpand, record }: any) => {
          if (record.children) {
            return <span className="float-left mr-2 mt-1 cursor-pointer" onClick={e => onExpand(record, e)}>
              {expanded ? <IoIosArrowDown /> : <IoIosArrowForward />}
            </span>
          }
          return null

        }
      }
    }
    dataSource={recentItems}
    columns={columns}
    loading={isLoading}
    pagination={false}
  />
}


export default RecentWorkspace