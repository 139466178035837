import { AxiosError } from 'axios';
import { CommunityApi, MyApi, UserApiV2 } from '../../api/client';
import {
  ModelCreateUserApiRequest,
  ModelUpdateUserApiRequest,
} from '../../api/__gen__/data-contracts';

export async function postCreateApi(params: ModelCreateUserApiRequest) {
  try {
    const client = UserApiV2();

    let resultDB = 'zettadb1';
    if (params.database?.startsWith('realtime')) {
      resultDB = params.database;
    }
    
    // if (
    //   params.dataIngestIntervalSeconds &&
    //   params.dataIngestIntervalSeconds >= 0
    // ) {
    //   params.database = 'AwsDataCatalog';
    // }

    if (
      params.dataIngestIntervalSeconds &&
      params.dataIngestIntervalSeconds > 0 &&
      params.resultWriteMode === 'Append'
    ) {
      resultDB = 'zedb1';
    }

    const resp = await client.userapisCreate({
      ...params,
      dataIngestIntervalSeconds:
        params.dataIngestIntervalSeconds === -1
          ? 0
          : params.dataIngestIntervalSeconds,
      resultDatabase: resultDB,
    });
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function getApiDetail(apiId: string) {
  try {
    const client = UserApiV2();
    const resp = await client.userapisDetail(apiId);
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function postUpdateApi(
  apiId: string,
  params: ModelUpdateUserApiRequest
) {
  try {
    const client = UserApiV2();
    const resp = await client.userapisCreate2(apiId, params);
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function getFetchMyApis() {
  try {
    const client = MyApi();
    const resp = await client.userapisList();
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function getFetchApiLogs(apiId: string) {
  try {
    const client = UserApiV2();
    const resp = await client.eventsDetail(apiId);
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: {
          ...error?.response?.data,
          status: error.response?.status,
        },
      };
    }
  }

  return null;
}

// TODO change to api clone
export async function postCloneApi({ apiId }: { apiId: string }) {
  try {
    const client = UserApiV2();
    const resp = await client.userapisDetail(apiId);

    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function getIndexProgress(apiId: string) {
  try {
    const client = UserApiV2();
    const resp = await client.progressDetail(apiId);

    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function getApiStats(apiId: string) {
  try {
    const client = UserApiV2();
    const resp = await client.statisticsDetail(apiId);

    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function getFetchCommunityApis() {
  try {
    const client = CommunityApi();
    const resp = await client.userapisList();
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function postDeleteApi(queryId: string) {
  try {
    const client = UserApiV2();
    // TODO use query delete
    const resp = await client.userapisDelete(queryId, {
      //
    });

    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error.response,
        data: error?.response?.data,
      };
    }
  }

  return null;
}
