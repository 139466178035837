export const INTERVAL_SELECTIONS = [
  { value: -1, label: 'real-time' },
  { value: 0, label: 'no refresh' },
  { value: 5 * 60, label: '5 mintues' },
  { value: 15 * 60, label: '15 mintues' },
  { value: 30 * 60, label: '30 mins' },
  { value: 60 * 60, label: '1 hr' },
  { value: 3 * 60 * 60, label: '3 hrs' },
  { value: 6 * 60 * 60, label: '6 hrs' },
  { value: 12 * 60 * 60, label: '12 hrs' },
  { value: 24 * 60 * 60, label: '24 hrs' },
];

type TimeMappingProps = {
  [key: number]: string;
};

export const TIME_MAPPING: TimeMappingProps = {
  '0': 'no refresh',
  '300': '5 mins',
  '900': '15 mins',
  '1800': '30 mins',
  '3600': '1 hr',
  '10800': '3 hrs',
  '21600': '6 hrs',
  '43200': '12 hrs',
  '86400': '24 hrs',
};

type AccessMappingProps = {
  [key: string]: string;
};

export const ACCESS_MAPPING: AccessMappingProps = {
  private: 'Private',
  org_internal: 'Org Internal',
  public: 'Public',
};
