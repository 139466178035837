import { MarketplaceApi } from '../../../api/client';

export const getAuthors = async (type: string) => {
  const client = MarketplaceApi();

  const res = client.creatorsList({ marketplaceType: type });

  return (await res).data;
};

export const getTenants = async (type: string) => {
  const client = MarketplaceApi();

  const res = client.tenantList({
    marketplaceType: type,
  });

  return (await res).data.items || [];
};
