/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useRef, useState } from 'react';
import { loadChart } from './StandaloneChartState';
import { convertDBChartToUIChart } from './utils';
import {
  ChartEntity,
  isAreaChart,
  isBarChart,
  isLineChart,
  isNumberChart,
  isScatterChart,
  isTableChartV2,
} from './types';
import { set } from 'lodash';
import { toast } from 'react-toastify';

import '../../css/blinker.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PublicChartApi } from '../../api/client';
import LogoBlinkerCenter from '../../components/app/LogoBlinkerCenter';
import classNames from 'classnames';
import { DefaultAutoDismissMs } from '../../config/toast';
import Logo from '../../components/app/Logo';
import useLoadChartData from './StandaloneChartHooks';
import {
  convertDBParamsStrToParams,
  convertParamsArrToMap,
  convertParamsToDBParamsStr,
} from '../queryBuilder/QueryPadState';
import BarChart from './components/BarChart';
import ChartDisplayName from './components/ChartDisplayName';
import TableChartV2 from './components/TableChartV2';
import ShowDetail from '../../components/typographic/ShowDetail';
import NumberChart from './components/NumberChart';
import ScatterChart from './components/ScatterChart';
import AreaChart from './components/AreaChart';
import LineChart from './components/LineChart';
import { useRecoilState } from 'recoil';
import { AuthState } from '../auth/AuthState';
import moment from 'moment';
import envs from '../../config/envs';

export default function StandaloneChart({
  standalone,
  chartId,
  queryBasePath = '/query-builder',
  privateApi = true,
  isDashboardOwner = false,
}: {
  standalone?: boolean;
  chartId: string;
  queryBasePath?: string;
  privateApi?: boolean;
  isDashboardOwner?: boolean;
}) {
  // current login user information
  const [auth] = useRecoilState(AuthState);
  const navigate = useNavigate();

  const [urlParams] = useSearchParams();

  const [isChartLoading, setIsChartLoading] = useState(false);
  const [chart, setChart] = useState<ChartEntity>();

  // @ts-ignore
  function getHydratedParams() {
    // @ts-ignore
    const queryParams = convertDBParamsStrToParams(chart?.queryParamsStr || '');
    const queryMap = convertParamsArrToMap(queryParams);

    queryParams.forEach((qParam) => {
      const { name } = qParam;
      const urlParam = urlParams.get(name);
      if (urlParam) {
        set(queryMap, [name, 'value'], urlParam);
      }
    });

    const paramsStrHydrated = convertParamsToDBParamsStr(
      Object.values(queryMap)
    );

    return paramsStrHydrated;
  }

  const hydratedParams = getHydratedParams();

  const queryId = chart?.queryId;
  const queryrunId = chart?.queryrunId;
  const queryrunFinishTime = chart?.queryrunFinishTime;
  const resultCacheExpireMillis = chart?.resultCacheExpireMillis;

  const isCurrentUserChartOwner = useMemo(() => {
    if (!chart?.creator?.id || !auth?.id) {
      return false;
    }

    return chart.creator.id === auth.id;
  }, [chart, auth]);

  const isCurrentUserSameOrgAsChartOwner = useMemo(() => {
    return chart?.creator?.tenant === auth?.tenant;
  }, [
    auth, chart
  ])

  const [queryResultCache, loadChartData] = useLoadChartData({
    queryId,
    queryParamsStr: hydratedParams,
    privateApi,
    queryrunId,
    isCurrentUserChartOwner,
    isDashboardOwner,
    queryrunFinishTime,
    resultCacheExpireMillis,
  });

  const isDataLoading = queryResultCache?.loading;
  const load = async () => {
    if (chartId) {
      setIsChartLoading(true);

      const resp = await loadChart(
        { chartId },
        privateApi ? undefined : PublicChartApi()
      );

      if (resp?.data) {
        const uiChart = convertDBChartToUIChart({
          id: resp?.data.id,
          uiOptionsStr: resp?.data.uiOptionsStr,
          ...resp.data,
        });

        setChart(uiChart);
      } else {
        toast.error('failed to load chart');
      }
      setIsChartLoading(false);
    }
  };
  const refreshedData = useRef(false)
  // trigger loading data when chart is fetched
  useEffect(() => {
    if (queryId) {
      // TODO: After BE support refresh data frequency, we can remove this hard-coded magic number
      if (!refreshedData.current && queryrunFinishTime && queryrunFinishTime < Date.now() - 1000 * 60 * 60 * 24) {
        loadChartData({ queryId, queryParamsStr: hydratedParams, noCache: true }).then(() => {
          load().then(() => {
            refreshedData.current = true
          });
        });
      } else {
        loadChartData({ queryId, queryParamsStr: hydratedParams, noCache: !!queryrunFinishTime });
      }
    }
  }, [queryId, hydratedParams, queryrunFinishTime]);


  // Load chart
  useEffect(() => {
    load();
  }, [chartId]);

  if (
    !chart ||
    isChartLoading ||
    isDataLoading ||
    !queryResultCache?.response
  ) {
    return <LogoBlinkerCenter />;
  }

  let chartComp;
  if (isBarChart(chart) && chart.type === 'bar') {
    chartComp = (
      <BarChart
        chart={chart}
        queryResults={queryResultCache?.response?.results}
      />
    );
  } else if (isTableChartV2(chart)) {
    if (queryResultCache?.response?.results) {
      chartComp = (
        <TableChartV2
          queryResults={queryResultCache?.response?.results}
          chart={chart}
        />
      );
    }
  } else if (isNumberChart(chart)) {
    if (queryResultCache?.response?.results) {
      chartComp = (
        <NumberChart
          queryResults={queryResultCache?.response?.results}
          chart={chart}
        />
      );
    }
  } else if (isLineChart(chart)) {
    if (queryResultCache?.response?.results) {
      chartComp = (
        <LineChart
          queryResults={queryResultCache?.response?.results}
          chart={chart}
        />
      );
    }
  } else if (isScatterChart(chart)) {
    if (queryResultCache?.response?.results) {
      chartComp = (
        <ScatterChart
          queryResults={queryResultCache?.response?.results}
          chart={chart}
        />
      );
    }
  } else if (isAreaChart(chart)) {
    if (queryResultCache?.response?.results) {
      chartComp = (
        <AreaChart
          queryResults={queryResultCache?.response?.results}
          chart={chart}
        />
      );
    }
  }

  const error = queryResultCache?.response?.error;

  const refreshData = async () => {
    if (!privateApi) {
      toast.info(
        'Refresh data is only available for logged in users. Please login first.'
      );
      return;
    }
    if (queryId) {
      await loadChartData({
        queryId,
        queryParamsStr: hydratedParams,
        noCache: true,
      });
      await load();
    }
  };

  return (
    <div
      className={classNames(
        'standalone-chart-base flex flex-col items-center justify-center w-full relative',
        standalone ? 'h-[calc(100vh-100px)]' : 'h-[100%]'
      )}
    >
      <div className='flex justify-between items-center w-full my-2'>
        <div
          className='pr-2 opacity-[0.5] text-sm'
          title={
            chart.queryrunFinishTime
              ? moment(chart.queryrunFinishTime).format('YYYY-MM-DD hh:mm:ss')
              : 'Unknown'
          }
        >
          Last updated:{' '}
          {chart.queryrunFinishTime
            ? moment(chart.queryrunFinishTime).fromNow()
            : 'Unknown'}
        </div>
        {isCurrentUserSameOrgAsChartOwner && <button
          className='btn btn-primary btn-outline btn-xs'
          onClick={refreshData}
        >
          Refresh
        </button>}
      </div>

      <div
        className={classNames(
          'h-[calc(100%-24px)] w-full relative flex flex-col'
        )}
      >
        {standalone && (
          <div
            className='w-full cursor-pointer hover:underline'
            onClick={() => {
              if (chart?.queryId) {
                navigate(`${queryBasePath}/${chart?.queryId}`);
              } else {
                toast.error('Query id is missing for chart', {
                  autoClose: DefaultAutoDismissMs,
                });
              }
            }}
          >
            <ChartDisplayName>
              {chart.displayName || 'Untitled chart'}
            </ChartDisplayName>
          </div>
        )}
        {error ? ( // Temp solution, will ask BE provide status later
          error.includes('Query has not yet finished') ? (
            <LogoBlinkerCenter />
          ) : (
            <div className='flex h-full w-full items-center justify-center'>
              <ShowDetail
                initialLabel='There was an error'
                showMoreLabel='(Show message)'
                detail={error}
              />
            </div>
          )
        ) : (
          chartComp
        )}
      </div>
      {!error && (
        <div
          className={classNames('items-center justify-center',
            'h-full w-full absolute flex opacity-5  pointer-events-none'
          )}
        >
          <div>
            {
              envs.isCircleIntegrationEnabled ? null
                : <Logo />
            }
          </div>
        </div>
      )}
    </div>
  );
}
