/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames"
import { useEffect, useState } from "react"
import { FaRegFolder, FaRegFile } from "react-icons/fa"
import { FiFilePlus, FiFolderPlus } from "react-icons/fi"
import { IoIosMore, IoIosArrowDown, IoIosArrowForward } from "react-icons/io"
import { Table } from "antd"
import { fetchMyDashboards } from "../../modules/dashboard/DashboardListState"
import moment from "moment"
import { Link } from "react-router-dom"
import { track } from "../tracking"

const DashboardWorkspace = () => {
  const [dataSource, setDataSource] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  const fetchAndSetDataSource = async () => {
    setIsLoading(true)
    const resp = await fetchMyDashboards()

    setDataSource(resp?.data?.items || [])
    setIsLoading(false)
  }
  useEffect(() => {
    fetchAndSetDataSource()
  }, [])

  const columns = [
    {
      title: "TITLE",
      dataIndex: "displayName",
      key: "displayName",
      sorter: (a: any, b: any) => a.displayName?.localeCompare(b.displayName),
      render: (text: any, record: any) => {
        return <Link onClick={() => {
          track('Dashboard Workspace - Open Dashboard', {
            dashboardId: record.id
          })
        }} to={`/v2/workspace/dashboards/${record.id}?from=workspace`}>
          <div className={
            classNames(
              "flex gap-2 items-center",
            )
          }>
            <FaRegFile />
            <div className="line-clamp-1">{text || 'Untitled'}</div>

          </div>
        </Link>
      }
    },
    {
      title: "TYPE",
      dataIndex: "type",
      key: "type",
      render: () => {
        return <div className="flex gap-2 items-center" key={Math.random()}>
          <FiFilePlus />
          <span>Dashboard</span>
        </div>
      }

    },
    {
      title: "CREATED",
      dataIndex: "publishedTime",
      key: "publishedTime",
      render: (text: any) => {
        if (!text) return ''
        return new Date(text).toLocaleDateString()
      }
    },
    {
      title: "UPDATED",
      dataIndex: "updatedTime",
      key: "updatedTime",
      render: (text: any) => {
        if (!text) return ''
        return new Date(text).toLocaleDateString()
      }
    }, {
      title: "AUTHOR",
      dataIndex: "creator",
      key: "creator",
      render: (creator) => {
        return creator?.displayName || creator?.name || 'Unknown'
      }
    },
    {
      title: 'ACTION',
      dataIndex: '',
      key: 'x',
      render: () => <div className="cursor-pointer">
        <IoIosMore />
      </div>,
    },
  ]
  return <Table
    pagination={false}
    loading={isLoading}
    expandable={
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        expandIcon: ({ expanded, onExpand, record }: any) => {
          if (record.children) {
            return <span className="float-left mr-2 mt-1 cursor-pointer" onClick={e => onExpand(record, e)}>
              {expanded ? <IoIosArrowDown /> : <IoIosArrowForward />}
            </span>
          }
          return null

        }
      }
    }
    dataSource={dataSource}
    columns={columns}

  />
}


export default DashboardWorkspace