import classNames from 'classnames';
import { useState, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router';
import envs from '../../config/envs';
import { AuthApi } from '../../api/client';
import { ActiveLink } from '../../components/ActiveLink';
import { UserAvatar } from '../../modules/auth/UserAvatar';
import useAuth from '../../hooks/auth';
import Modal from '../../components/modals/Modal';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IoIosCopy } from 'react-icons/io';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

export default function ProfileMenu() {
  const [modalOpen, setModalOpen] = useState(false);
  const [cookies, , removeCookie] = useCookies(['auth']);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [{ auth, isSignedIn }, { setAuth }] = useAuth();

  const apiKey = useMemo(() => {
    if (!auth) return '';

    return auth.defaultApiKey || '';
  }, [auth]);

  const authMouseDown = async () => {
    if (!isSignedIn) {
      navigate('/auth/login');
    } else {
      if (auth) {
        const authApi = AuthApi();
        try {
          const resp = await authApi.logoutCreate();
          if (resp.data.success) {
            setAuth(null);
          }
        } catch (error) {
          setAuth(null);
        }
      }
      if (cookies.auth) {
        removeCookie('auth', { path: '/' });
      }
    }
    navigate('/auth/login')
  };

  return (
    <div
      className='dropdown dropdown-end'
      onBlur={() => {
        // Make items clickable
        setTimeout(() => setOpen(false), 200);
      }}
    >
      <button
        className='flex flex-1 space-x-3'
        onClick={() => {
          setOpen(!open);
        }}
      >
        <UserAvatar />
      </button>
      <ul
        className={classNames(
          'mt-3 p-2 shadow bg-base-100 rounded-box w-52',
          open ? 'menu menu-compact dropdown-content ' : 'hidden'
        )}
      >
        {isSignedIn && (
          <>
            {auth && (
              <li>
                <CopyToClipboard text={apiKey}>
                  <button
                    onClick={() => {
                      toast.success('Default API Key copied', {
                        autoClose: 3000,
                      });
                    }}
                  >
                    My API Key{' '}
                    <IoIosCopy size='1.2rem' className='opacity-60' />
                  </button>
                </CopyToClipboard>
              </li>
            )}
            {/* <li>
              <button
                onClick={() => {
                  setModalOpen(true);
                }}
                className='justify-between'
              >
                {'Upgrade plan'}
              </button>
            </li> */}
            <li>
              {/* <a
                href={`${envs.StripeCustomerPortal}?prefilled_email=${auth?.email}`}
              >
                My plan
              </a> */}
              <Link to={'/plans'}>
                My plan
              </Link>
            </li>
          </>
        )}
        <li>
          <a target='_blank' rel='noreferrer' href='https://feedback.zettablock.com/'>Feedback</a>
        </li>
        <li>
          <ActiveLink
            to='/'
            onMouseDown={authMouseDown}
            onClick={(e) => { e.preventDefault() }}
            activeNames='active'
            className='justify-between'
          >
            {isSignedIn ? 'Logout' : 'Login'}
          </ActiveLink>
        </li>
      </ul>

      <Modal
        open={modalOpen}
        onClickBackdrop={() => setModalOpen(false)}
        className='max-w-full'
      >
        <a
          href='https://docsend.com/view/4yjh7wivyxkg9s5z'
          className='underline block text-center pb-4'
        >
          Detailed pricing plan
        </a>
        <stripe-pricing-table
          pricing-table-id={envs.StripePricingTableId}
          publishable-key={envs.StripePublishableKey}
          customer-email={auth?.email}
        ></stripe-pricing-table>
      </Modal>
    </div>
  );
}