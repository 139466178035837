/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link, NavLink, Route, Routes, useMatch, useNavigate, useParams } from "react-router-dom"
import { Button, Form, Input, Modal, Table } from 'antd'
import { FiFolderPlus } from "react-icons/fi"
import { useState } from "react"
import { IoIosArrowDown, IoIosArrowForward, IoIosMore } from "react-icons/io"
import classNames from "classnames"
import { FaRegFile, FaRegFolder } from "react-icons/fa"
import ApiWorkspace from "./ApiWorkspace"
import TableWorkspace from "./TableWorkspace"
import QueryWorkspace from "./QueryWorkspace"
import DashboardWorkspace from "./DashboardWorkspace"
import RecentWorkspace from "./RecentWorkspace"

import importIcon from './import.svg'
import dashboardIcon from './dashboard.svg'
import { useCreateDashboard } from "../../modules/dashboard/NewDashboardHooks"
import TextArea from "antd/es/input/TextArea"
import { track } from "../tracking"

const testData: any = [
  {
    name: 'My APIs',
    id: '1',
    key: '1',
    type: 'apis',
    children: [
      {
        name: 'My API 1',
        id: '2',
        key: '2',
        type: 'api',
      },
      {
        name: 'My API 2',
        id: '3',
        key: '3',
        type: 'api',
      },
      {
        name: 'My API 3',
        id: '4',
        key: '4',
        type: 'api',
      },
    ]
  },
  {
    name: 'My APIsssss2',
    id: '5',
    key: '5',
    type: 'apis',
    children: [
      {
        name: 'My API 11',
        id: '6',
        key: '6',
        type: 'api',
      },
      {
        name: 'My API 21',
        id: '7',
        key: '7',
        type: 'api',
      },
      {
        name: 'My API 3',
        id: '8',
        key: '8',
        type: 'api',
      },
    ]
  }
]


export const Tabs = () => {
  const getClassNames = ({ isActive }: any) => {
    return classNames(
      'text-[14px] leading-[14px] tab pb-[20px] h-auto',
      {
        'tab-active font-semibold border-[#fff] border-b': isActive,
      }
    );
  }
  // {/* Tabs - API, Query, Table, Dashboard, My Fav */}
  return <div role="tablist" className="tabs gap-[30px] pt-[25px]">
    <NavLink to={`/v2/workspace/apis`} role="tab"
      className={getClassNames}
    >API</NavLink>
    <NavLink to={`/v2/workspace/tables`} role="tab" className={getClassNames}>Table</NavLink>
    <NavLink to={`/v2/workspace/queries`} role="tab" className={getClassNames}>Query</NavLink>
    <NavLink to={`/v2/workspace/dashboards`} role="tab" className={getClassNames}>Dashboard</NavLink>
    <NavLink to={`/v2/workspace/recents`} role="tab" className={getClassNames}>Recents</NavLink>
  </div>
}
const Workspace = () => {
  const params = useParams();

  const match = useMatch('/v2/workspace/:type')
  const [createDashboard, { loading }] = useCreateDashboard();
  const navigate = useNavigate()

  const [showAddDashboard, setShowAddDashboard] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')

  const renderToolbar = () => {

    switch (match?.params?.type) {
      case 'apis':
        break;
      case 'queries':
        break;
      case 'tables':
        return null
        return <Link to="/v2/data-sources/" className="border bg-[#fafafa] px-[12px] py-[8px] h-[30px] flex items-center justify-center mt-[18px] mb-[12px] rounded">
          <img src={importIcon} width={14} className="mr-[5px]" />
          Import Table
        </Link>
        break;
      case 'dashboards':
        return <div className={
          classNames("border bg-[#fafafa] px-[12px] py-[8px] h-[30px] flex items-center justify-center mt-[18px] mb-[12px] cursor-pointer rounded", {
            'opacity-50': loading
          })
        }
          onClick={() => {
            setShowAddDashboard(true)
          }}
        >
          <img src={dashboardIcon} width={14} className="mr-[5px]" />
          Create Dashboard
        </div>

        break;

      default:
        break;
    }

    return null
  }

  return <div
    className=" pb-10"
  >
    <div className="px-[25px] flex justify-between border-b mb-[30px] text-[12px]">
      <Tabs />
      {
        renderToolbar()
      }
    </div>


    <div className="px-[30px]">
      <Routes>
        <Route path="/apis" element={<div>
          <ApiWorkspace />
        </div>} />
        <Route path="/tables" element={<div>
          <TableWorkspace />
        </div>} />
        <Route path="/queries" element={<div>
          <QueryWorkspace />
        </div>} />
        <Route path="/dashboards" element={<div><DashboardWorkspace /></div>} />
        <Route path="/recents" element={<div><RecentWorkspace /></div>} />
      </Routes>
    </div>

    <Modal
      open={showAddDashboard}
      onCancel={() => setShowAddDashboard(false)}
      title="Create Dashboard"
      footer={null}
    >
      <Form
        className="mt-10"
        labelCol={{ span: 6 }}
      >
        <Form.Item label="Name">

          <Input name="name" onChange={(e) => {
            setName(e.target.value)
          }} />
        </Form.Item>
        <Form.Item label='Description' className="mt-2">
          <TextArea name="description"
            onChange={(e) => {
              setDescription(e.target.value)
            }}
          />
        </Form.Item>
        <div className="flex items-center justify-end">
          <Button disabled={loading} onClick={async () => {
            // 
            const newDash = await createDashboard({
              displayName: name,
              uiOptions: {
                rowHeight: 50,
                autoSave: true
              },
              description
            })
            setShowAddDashboard(false)
            setName('')
            setDescription('')

            track('Dashboard Workspace - Create Dashboard', {
              dashboardId: newDash?.id
            })

            navigate(`/v2/workspace/dashboards/${newDash?.id}`)
          }}>
            Create Dashboard
          </Button>
        </div>
      </Form>
    </Modal>
  </div>
}

export default Workspace