/* eslint-disable @typescript-eslint/no-explicit-any */
import LayoutV2 from './layouts/LayoutV2';
// import Explore from './v2/Explore';
// import ExploreApis from './v2/explore/ExploreApis';
import Workspace from './v2/workspace/Workspace';
import ApiProject from './v2/workspace/api-project/ApiProject';
// import ExploreTables from './v2/explore/ExploreTables';
// import ExploreDashboards from './v2/explore/ExploreDashboards';
import TableProject from './v2/components/TableProject';
import ApiKeyPageV2 from './v2/api-key/ApiKeyPageV2';
import { ApiUsageV2 } from './v2/api-key/ApiUsageV2';
import DashboardViewV2 from './v2/components/DashboadViewV2';
import DataSourceListPageV2 from './v2/workspace/DataSourceListPageV2';
import SearchResults from './v2/search/SearchResults';
import Upload from './modules/connectors/Upload';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import AuthenticationPage from './v2/auth/Auth';
import Build from './v2/build/Build';
// import useAuth from './hooks/auth';
import { useEffect, useState } from 'react';
import { isEmpty, startsWith } from 'lodash';
import { AuthState } from './modules/auth/AuthState';
import { useRecoilState } from 'recoil';
import { useCookies } from 'react-cookie';
import { AuthApi } from './api/client';
import ChoosePlan from './v2/onboarding/ChoosePlan';
import AddPaymentMethod from './v2/onboarding/AddPaymentMethod';
// import Billing from './v2/billing/Billing';
import ImagePicker from './v2/image-upload/ImagePicker';
// import ExploreQueries from './v2/explore/components/ExploreQueries';
import ExploreGraphQL from './v2/explore/ExploreGraphQL';
import GraphQlProject from './v2/workspace/api-project/graphql/GraphQlProject';
import Catalog from './v2/components/catalog/Catalog';
import GqlApi from './v2/components/gql-api/GqlApi';
import ExploreGqlApis from './v2/explore/ExploreGqlApis';
import ExploreTablesV3 from './v2/explore/ExploreTablesV3';
import ExploreApisV2 from './v2/explore/ExploreApisV2';
import ExploreQueriesV2 from './v2/explore/ExploreQueriesV2';
import ExploreDashboardsV2 from './v2/explore/ExploreDashboardsV2';
import BillingV2 from './v2/billing/BillingV2';
import Freshness from './v2/freshness/Freshness';
import ExploreProjects from './v2/explore/ExploreProjects';
import ExploreTables from './v2/explore/ExploreTables';
import { track } from './v2/tracking';
import PublicDashboard from './v2/share/dashboard/PublicDashboard';
import { FloatButton } from 'antd';
import { BiQuestionMark } from 'react-icons/bi';
import Icon from './v2/components/Icon';
import { startOnboardingWorkflow } from './v2/onboarding/api';
import Questionnaire from './v2/onboarding/Questionnaire';
import { toast } from 'react-toastify';

// This is the base Routes file for the product v2

function AuthRouter() {
  const navigate = useNavigate();
  const location = useLocation();
  const [auth, setAuth] = useRecoilState(AuthState);
  const [cookies] = useCookies(['auth']);

  useEffect(() => {
    track(`Page View: ${location.pathname}`, {
      path: location.pathname,
      search: location.search,
      user: auth?.id,
      email: auth?.email,
    });
  }, [location]);

  useEffect(() => {
    const refreshAccessToken = async () => {
      const client = AuthApi();
      try {
        const resp = await client.postAuth();
        if (resp && resp.data.accessToken && resp.data.user?.id) {
          setAuth({
            accessToken: resp.data.accessToken,
            id: resp.data.user.id,
            name: resp.data.user.name,
            email: resp.data.user.email,
            profileImage: resp.data.user.profileImage,
            tenant: resp.data.user.tenant,
            defaultApiKey: resp.data.user.defaultApiKey,
            subscription: resp.data.subscription,
            whitelisted: resp.data.user.isInAllowList,
          });
        } else {
          setAuth(null);
        }

        setTimeout(() => {}, 1);
      } catch (error) {
        setAuth(null);
      }
    };
    refreshAccessToken();
  }, []);
  useEffect(() => {
    if (location.pathname.includes('onboarding/questionnaire')) {
      return;
    }
    if (
      auth?.subscription &&
      auth.subscription.planChosen &&
      !(auth.subscription as any)?.onboardingCompleted
    ) {
      setTimeout(() => {
        startOnboardingWorkflow(auth.tenant, auth.email, auth.id).then(
          (res) => {
            if (!res) return;
            if (
              (res.data as any) &&
              res.data.user_email &&
              res.data.user_id &&
              res.data.tenant
            ) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              if ((res.data as any).state_name === 'help_guide') {
                navigate(
                  `/v2/explore/projects?runTour=true&state_id=${res?.data.state_id}`
                );
              } else {
                navigate('/v2/onboarding/questionnaire');
              }
            }
          }
        );
      }, 0);
    }
  }, [
    auth?.subscription?.planChosen,
    (auth?.subscription as any)?.onboardingCompleted,
    location.pathname,
  ]);

  useEffect(() => {
    const isAuthRoute = startsWith(location.pathname, '/v2/auth');
    if (!isAuthRoute && auth === null && isEmpty(cookies.auth)) {
      if (location.pathname.includes('public/dashboards')) {
        return;
      }
      const backParam = encodeURIComponent(location.pathname);
      navigate(`/v2/auth/login${backParam ? `?back=${backParam}` : ''}`);
    }

    if (
      auth?.subscription &&
      auth.subscription.plan === 'free' &&
      auth.subscription.planChosen === false
    ) {
      if (location.pathname.includes('v2/onboarding')) {
        return;
      }
      navigate('/v2/onboarding/choose-plan');
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // TODO: Remove this after the onboarding bug is fixed
  }, [cookies.auth, auth, location.pathname]);

  return null;
}

const FloatButtonGroup = ({ onClose }: { onClose: () => void }) => {
  const navigate = useNavigate();

  return (
    <div className='w-[350px] relative left-[-350px] bg-[#fff] border rounded-[4px]'>
      <div className='flex justify-between items-center px-[20px] py-[15px] border-b'>
        <div className='font-bold text-[16px]'>Help</div>
        <div className='cursor-pointer' onClick={onClose}>
          <Icon iconName='close' />
        </div>
      </div>
      <div className='p-[15px]'>
        <a
          href='https://docs.zettablock.com/docs/getting-started-with-web3'
          target='_blank'
          rel='noreferrer'
          className='cursor-pointer bg-[#fafafa] border rounded-[6px] flex items-center font-bold text-[14px] px-[15px] py-[12px] gap-[15px] mb-[10px]'
        >
          <Icon iconName='magic1' />
          Getting Started With Web3
        </a>
        <a
          href='https://docs.zettablock.com/docs/price-feed-api-swap-count-in-the-past-24h'
          target='_blank'
          rel='noreferrer'
          className='cursor-pointer bg-[#fafafa] border rounded-[6px] flex items-center font-bold text-[14px] px-[15px] py-[12px] gap-[15px] mb-[20px]'
        >
          <Icon iconName='lightbulb1' />
          Common Use Cases
        </a>

        <div className='font-[500]'>
          <div className='flex gap-[10px] items-center cursor-pointer mb-[25px]'>
            <Icon iconName='smile' />
            <div
              className='text-[#777A80] text-[14px]'
              onClick={() => {
                onClose();
                navigate('/v2/explore/projects?runTour=true');
              }}
            >
              Get Started
            </div>
          </div>
          <a
            href='https://discord.com/channels/954612105292705833/1225467179227414639'
            target='_blank'
            rel='noreferrer'
            className='flex gap-[10px] items-center cursor-pointer mb-[25px]'
          >
            <Icon iconName='notification' />
            <div className='text-[#777A80] text-[14px]'>Request Support</div>
          </a>
          {/* <a href='mailto:team@zettablock.com' className='flex gap-[10px] items-center cursor-pointer mb-[25px]'>
          <Icon iconName='message' />
          <div className='text-[#777A80] text-[14px]'>
            Give Us Feedback
          </div>
        </a> */}
          <a
            href='mailto:sales@zettablock.com'
            className='flex gap-[10px] items-center cursor-pointer'
          >
            <Icon iconName='chat' />
            <div className='text-[#777A80] text-[14px]'>Contact Sales</div>
          </a>
        </div>
      </div>
    </div>
  );
};

// TODO: Extract this to a separate file
const SimpleAuth = ({ children }: { children?: React.ReactNode }) => {
  // const [{ auth }] = useAuth()
  // if (auth?.tenant && auth?.tenant !== 'zettablock.com') {
  //   return <Navigate to="/my-workspace/" />
  // }
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <AuthRouter />
      {children || <Outlet />}
      {
        <FloatButton.Group
          className='developer-support'
          trigger='click'
          type='default'
          open={isOpen}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          style={{ right: 44 }}
          icon={<BiQuestionMark />}
        >
          <FloatButtonGroup
            onClose={() => {
              setIsOpen(!isOpen);
            }}
          />
        </FloatButton.Group>
      }
    </div>
  );
};

export const ProductV2Routes = () => {
  const location = useLocation();
  useEffect(() => {
    toast.dismiss();
  }, [location]);
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/v2/explore' />} />

      <Route path='/auth/login' element={<AuthenticationPage type='login' />} />
      <Route
        path='/auth/register'
        element={<AuthenticationPage type='register' />}
      />
      <Route
        path='/auth/verify/:emailAddress'
        element={<AuthenticationPage type='verify' />}
      />
      {/* <Route path='/' element={<LayoutV2 />}> */}
      <Route path='/public/tables' element={<ExploreTables publicVisible />} />
      <Route
        path='/public/tables/:tableId'
        element={<ExploreTables publicVisible />}
      />
      {/* </Route> */}

      <Route path='/' element={<SimpleAuth />}>
        <Route
          path='/public/dashboards/:dashboardId'
          element={<PublicDashboard />}
        />
        <Route
          path='/upload'
          element={<ImagePicker onChange={() => {}} value='' />}
        ></Route>
        <Route path='/onboarding/choose-plan' element={<ChoosePlan />} />
        <Route path='/onboarding/subscribe' element={<AddPaymentMethod />} />
        <Route path='/onboarding/questionnaire' element={<Questionnaire />} />

        <Route path='/cata' element={<Catalog />} />

        <Route path='/' element={<LayoutV2 />}>
          <Route path='/billing' element={<BillingV2 />} />
          <Route path='/freshness' element={<Freshness />} />
          <Route path='/build/:id' element={<Build newBuild={false} />} />
          <Route path='/build' element={<Build newBuild />} />

          <Route path='/data-sources' element={<DataSourceListPageV2 />} />
          <Route path='/search-results' element={<SearchResults />} />
          <Route path='/data-sources/type/:type' element={<Upload />} />

          <Route
            path='/explore'
            element={<Navigate to='/v2/explore/projects' replace />}
          />
          <Route path='/explore/apis' element={<ExploreApisV2 />} />
          <Route path='/explore/projects' element={<ExploreProjects />} />
          <Route path='/explore/tables' element={<ExploreTables />} />
          <Route path='/explore/tables/:tableId' element={<ExploreTables />} />
          <Route path='/explore/graphql' element={<ExploreGraphQL />} />
          <Route path='/explore/graphql/:id' element={<GraphQlProject />} />
          <Route path='/explore/graphql-apis' element={<ExploreGqlApis />} />
          <Route path='/explore/graphql-apis/:apiId/*' element={<GqlApi />} />

          <Route
            path='/explore/apis/:projectId/*'
            element={<ApiProject type='explore' />}
          />

          <Route path='/explore/tables' element={<ExploreTablesV3 />} />
          <Route
            path='/explore/tables/:projectId/*'
            element={<TableProject type='explore' />}
          />

          <Route path='/explore/dashboards' element={<ExploreDashboardsV2 />} />
          <Route path='/explore/queries' element={<ExploreQueriesV2 />} />
          <Route
            path='/explore/dashboards/:dashboardId'
            element={<DashboardViewV2 />}
          />

          <Route
            path='/workspace'
            element={<Navigate to='/v2/workspace/apis' />}
          />
          <Route
            path='/workspace/apis/:projectId/*'
            element={<ApiProject type='workspace' />}
          />
          <Route
            path='/workspace/tables/:projectId/*'
            element={<TableProject type='workspace' />}
          />
          <Route
            path='/workspace/dashboards/:dashboardId'
            element={<DashboardViewV2 />}
          />
          <Route path='/workspace/graphql-apis/:apiId/*' element={<GqlApi />} />
          <Route path='/workspace/*' element={<Workspace />} />

          <Route path='/api-key' element={<ApiKeyPageV2 />} />
          <Route path='/api-key/:apiKeyId' element={<ApiUsageV2 />} />
          <Route path='/api-key/:apiKeyId/v2' element={<ApiUsageV2 isV2 />} />
          <Route path='*' element={<Navigate to='/v2/explore' />} />
        </Route>
        <Route path='*' element={<Navigate to='/v2/explore' />} />
      </Route>

      <Route path='/' element={<Navigate to='/v2/explore' />} />
      <Route path='*' element={<Navigate to='/v2/explore' />} />
    </Routes>
  );
};
