import { Tag } from "antd"

interface LabelsDisplayProps {
  labels: string[]
}

const LabelsDisplay = ({
  labels
}: LabelsDisplayProps) => {
  if (labels?.length === 0) return null

  return <div className="flex gap-[5px]">
    {
      labels.map((label: string, index: number) => (<Tag className="cursor-pointer text-[10px] border-none bg-[#ebebeb] font-semibold px-[10px]" key={index}>
        {label}
      </Tag>))
    }
  </div>


}

export default LabelsDisplay