import { ChangeEventHandler } from "react";
import { FaFilter } from "react-icons/fa";

interface FilterSelectProps {
  options: FilterSelectOption[];
  value: string;
  placeholder: string;
  onChange: ChangeEventHandler<HTMLSelectElement>,
  icon?: JSX.Element;
}

interface FilterSelectOption {
  value: string;
  label: string;
}

const FilterSelect = ({
  options,
  value,
  placeholder,
  onChange,
  icon
}: FilterSelectProps): JSX.Element => {

  return <div className="relative mx-2">
    <div className="absolute top-[calc(50%-6px)] left-[0.75rem]">
      {icon ?? <FaFilter size={12} color="#878e95" />}
    </div>
    <select onChange={onChange} className="select select-bordered max-w-[150px] pl-[2rem] text-[#878e95]" value={value}>
      <option disabled selected value={''}> {placeholder} </option>
      {options.map(option => {
        return <option value={option.value}>
          {option.label}
        </option>
      })}
    </select>

  </div>
}

export default FilterSelect;