import { atom, selector } from 'recoil';
import { toast } from 'react-toastify';
import { DatabaseApi } from '../../api/client';
import { SelectedSchemaState } from './SchemaState';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { DefaultAutoDismissMs } from '../../config/toast';

// eslint-disable-next-line
export const TableSchemaCache = new Map<string, any>();

export const FetchTableSchema = selector({
  key: 'FetchTableSchema',
  get: async ({ get }) => {
    try {
      const selectedSchema = get(SelectedSchemaState);

      if (!selectedSchema || !selectedSchema?.database) {
        return [];
      }

      const client = DatabaseApi();
      const resp = await client.schemasTablesDetail(
        selectedSchema.database,
        selectedSchema.schema || ''
      );
      const { items } = resp.data;

      if (Array.isArray(items)) {
        TableSchemaCache.set('cache', items);
        return items;
      }
    } catch (error) {
      // @ts-ignore
      const msg = error?.response?.data?.message;

      toast.error(msg || 'Error loading schemas.', {
        autoClose: DefaultAutoDismissMs,
      });
    }

    return [];
  },
});

export const TableSchemasQueryState = atom({
  key: 'TableSchemasQueryState',
  default: FetchTableSchema,
});

export const SelectedTableSchemaState = atom<string | undefined>({
  key: 'SelectedTableSchemaState',
  default: undefined,
});

/**
 * get selected table name
 * OR
 * get the first table name from the list when
 *    selected table name is not available anymore
 */
export const SelectedOrFirstTable = selector({
  key: 'SelectedOrFirstTable',
  get: ({ get }) => {
    try {
      const tableList = get(TableSchemasQueryState);
      const tableName = get(SelectedTableSchemaState);

      if (isEmpty(tableList) || !tableName) {
        return null;
      }

      const tableInList = tableList.find((t) => t.name === tableName);
      if (tableInList) {
        return tableName;
      }

      return tableList?.[0].name;
    } catch (error) {
      // ignore error: loading status
    }

    return null;
  },
});

export const SelectedTableSchemaDetailState = selector({
  key: 'SelectedTableSchemaDetailState',
  get: ({ get }) => {
    try {
      const tableList = get(TableSchemasQueryState);
      const tableName = get(SelectedOrFirstTable);

      if (isEmpty(tableList) || !tableName) {
        return null;
      }

      return tableList.find((t) => t.name === tableName);
    } catch (error) {
      // ignore error: loading status
    }

    return null;
  },
});

export function useQueryPadInject() {
  const handleInject = useCallback(
    (keyword: string) => {
      const { view } = window;

      if (!view) return;

      const from = view.state.selection.ranges[0].from || 0;
      const to = view.state.selection.ranges[0].to || 0;

      const tr = view.state.update(
        {
          changes: [
            { from, to, insert: '' },
            { from, insert: keyword },
          ],
          selection: { anchor: from + keyword.length },
        },
        {
          scrollIntoView: true,
        }
      );

      view.dispatch(tr);
      view.focus();
    },
    [window.view]
  );

  return handleInject;
}
