/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactECharts from 'echarts-for-react';
import { DefaultEChartOptions } from '../chart/utils/echart';
import { memo } from 'react';
import Logo from '../../components/app/Logo';
import envs from '../../config/envs';

const generateBarChartOption = (data: any, propertyName = 'numOfCalls') => {
  const res = {
    ...DefaultEChartOptions,
    version: 2,
    chartOptions: {
      showLegend: true,
    },
    xAxis: {
      type: 'category',
      data: data.map((e: any) =>
        new Date(e.startSeconds * 1000).toUTCString().replaceAll('00:00:00 ', '')
      ),
    },
    yAxis: {
      type: 'value',
    },
    legend: {
      orient: 'horizontal',
      top: 0,
      right: 'center',
    },
    grid: {
      right: '0%',
    },
    series: {
      type: 'line',
      data: data.map((e: any) => e[propertyName]),
    },
  };

  return res;
};

export const ApiUsageLineChart = memo(
  ({ data, propertyName }: { data: any; propertyName: string }) => {
    if (!data) return null;

    return (
      data && (
        <div className='w-full relative'>
          <ReactECharts
            option={generateBarChartOption(data, propertyName)}
          ></ReactECharts>
          <div className='h-full w-full absolute flex justify-center opacity-30 top-0 items-center pointer-events-none'>
            <div>
              {
                envs.isCircleIntegrationEnabled ? null
                  : <Logo />
              }
            </div>
          </div>
        </div>
      )
    );
  }
);
