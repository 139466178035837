/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEqual } from 'lodash';
import { useState } from 'react';
import { useWorkspace } from '../../../layouts/components/workspace-hooks';
import ProcessingUpload from './ProcessingUpload';
import SubmitUpload from './SubmitUpload';
import { UploadPayload, UploadStatus } from './types';
import UploadDetails from './UploadDetails';
import { TableApiV2 } from '../../../api/client';
import { toast } from 'react-toastify';

export default function CSV() {
  const [s3Url, setS3Url] = useState<string>();
  const [file, setFile] = useState<File>();
  const [uploadPayload, setUploadPayload] = useState<UploadPayload>();
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('details');
  const [, { navigate }] = useWorkspace();

  return (
    <>
      {uploadStatus === 'details' && (
        <UploadDetails
          s3Url={s3Url}
          selectedFile={file}
          onPrev={() => navigate(-1)}
          onNext={(url, uploadFile) => {
            setS3Url(url);
            setFile(uploadFile);
            setUploadStatus('submit');
          }}
        />
      )}
      {uploadStatus === 'submit' && s3Url && file && (
        <SubmitUpload
          tableName={uploadPayload?.table}
          s3={s3Url}
          file={file}
          onPrev={() => setUploadStatus('details')}
          onNext={(payload) => {
            if (!isEqual(payload, uploadPayload)) {
              setUploadPayload(payload);
            }
            try {
              const client = TableApiV2()
              client.importCreate({
                database: payload.schema,
                data_source: payload.database,
                table: payload.table
              })
            } catch (error) {
              toast.error('Error uploading file');
            }
            setUploadStatus('processing');
          }}
        />
      )}
      {uploadStatus === 'processing' && uploadPayload && (
        <ProcessingUpload
          payload={uploadPayload}
          onNext={() => {
            navigate('/v2/build')
          }}
          setUploadStatus={setUploadStatus}
        />
      )}
    </>
  );
}
