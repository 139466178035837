import { padStart, toInteger } from 'lodash';
import React, { memo, useState } from 'react';

const getTimeInSec = (date?: Date | null) => {
  if (!date) {
    return 0;
  }
  return date.getTime();
};
const padZero = (num: number) => padStart(`${num}`, 2, '0');

type InputDate = Date | null | undefined;

function StopWatchV3({
  startedAt,
  endedAt,
  executionMillis
}: {
  startedAt?: InputDate;
  endedAt?: InputDate;
  executionMillis?: number
}) {
  const [time, setTime] = useState<number>(0);
  const isValidStart = !!startedAt;
  const running = isValidStart && endedAt === null;

  React.useEffect(() => {
    let interval: NodeJS.Timer | null = null;

    if (running === true) {
      interval = setInterval(() => {
        const now = getTimeInSec(new Date());
        const newTime = now - getTimeInSec(startedAt);
        setTime(newTime);
      }, 30);
    } else if (interval) {
      clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [running]);

  const getRenderTime = (start: InputDate, end: InputDate) => {
    // when endedAt not provided
    if (running) {
      return time;
    }

    if (end === undefined) {
      return 0;
    }

    return time
  }

  // TODO: replace rendertime with value from BE
  // removing polling interval to accommodate max delay
  const renderTime = isValidStart ? executionMillis || getRenderTime(startedAt, endedAt) : 0;
    
  const mills = padZero(toInteger((renderTime % 1000) / 10));
  const sec = padZero(toInteger((renderTime / 1000) % 60));
  const min = padZero(toInteger((renderTime / 1000 / 60) % 60));
  const hour = padZero(toInteger((renderTime / 1000 / 60 / 60) % 60));

  return (
    <div className='text-xs opacity-80 min-w-[4.6rem]'>
      {hour}:{min}:{sec}.{mills}
    </div>
  );
}

export default memo(StopWatchV3);
