/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-expressions */
import { Link, Outlet } from 'react-router-dom';
import './layout-v2.scss';
import BuildButton from '../v2/components/BuildButton';
import { useScrollToTopV2 } from '../components/app/ScrollToTop';
import MainSidebar from './MainSidebar';
import { ReactNode, useContext, useMemo } from 'react';
import { GlobalSettingsContext } from '../v2/context/global-settings';
import SearchBar from '../v2/components/search-bar/SearchBar';

import classNames from 'classnames';
import Icon from '../v2/components/Icon';
import useAuth from '../hooks/auth';
import ProfileAvatar from '../modules/auth/ProfileAvatar';
import { AuthApi } from '../api/client';

import { track } from '../v2/tracking';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

export const UserProfileMenuV2 = () => {
  const [{ auth }, { setAuth }] = useAuth();

  const apiKey = useMemo(() => {
    if (!auth) return '';

    return auth.defaultApiKey || '';
  }, [auth]);
  const handleClick = () => {
    const elem = document.activeElement;
    if (elem) {
      (elem as HTMLElement).blur();
    }
  };

  return (
    <div className='dropdown dropdown-bottom dropdown-end'>
      <div tabIndex={0} role='button' className=''>
        <ProfileAvatar
          className='w-[24px] bg-[#d3d3d3]'
          creator={{
            profileImage: auth?.profileImage,
          }}
        />
      </div>
      <ul
        tabIndex={0}
        className='dropdown-content z-[1] menu 0 shadow bg-base-100 rounded-[4px] w-[200px] left-[-88px] top-[30px]'
      >
        <li>
          <div className='py-[20px] flex items-center flex-col  gap-0 border-b'>
            <ProfileAvatar
              className='w-[50px] bg-[#d3d3d3]'
              creator={{
                profileImage: auth?.profileImage,
              }}
            />
            <div className='mt-[10px] text-[#2B2F33] text-[12px] font-bold'>
              {auth?.name || auth?.email?.split('@')[0]}
            </div>
            <div className='mt-[5px] text-[#777A80] text-[10px]'>
              {auth?.email}
            </div>
          </div>
        </li>
        <li>
          <div className='w-full flex justify-between items-center'>
            <Link
              to='/v2/api-key'
              className='flex-1 flex items-center gap-[12px] text-[12px] text-[#1A1A1A] hover:text-[#1A1A1A] font-[500]'
              onClick={handleClick}
            >
              <Icon iconName='apikey' className='w-[14px]' /> API Keys
            </Link>
            {apiKey && (
              <CopyToClipboard text={apiKey}>
                <div
                  onClick={() => {
                    toast.success('API Key Copied!', {
                      position: 'top-center',
                      autoClose: 2000,
                    });
                  }}
                >
                  <Icon iconName='copy' className='w-[14px] h-[14px]' />
                </div>
              </CopyToClipboard>
            )}
          </div>
        </li>
        <li className='border-t'>
          <Link
            to='/v2/billing'
            className='text-[12px] text-[#1A1A1A] hover:text-[#1A1A1A] font-[500]'
            onClick={handleClick}
          >
            <Icon iconName='billing' className='w-[14px]' /> Billing
          </Link>
        </li>
        <li className='border-t'>
          <a
            onClick={() => {
              track('Menu - Click', {
                type: 'external link',
                to: 'https://docs.zettablock.com/docs/zettablock-overview',
              });
            }}
            href='https://docs.zettablock.com/docs/zettablock-overview'
            target='_blank'
            rel='noreferrer'
            className='text-[12px] text-[#1A1A1A] hover:text-[#1A1A1A] font-[500]'
          >
            <Icon iconName='qs' className='w-[14px]' /> Quick Start
          </a>
        </li>

        <li className='border-t'>
          <a
            onClick={() => {
              track('Menu - Click', {
                type: 'external link',
                to: 'https://discord.gg/zettablock',
              });
            }}
            href='https://discord.gg/zettablock'
            target='_blank'
            rel='noreferrer'
            className='text-[12px] text-[#1A1A1A] hover:text-[#1A1A1A] font-[500]'
          >
            <Icon iconName='support' className='w-[14px]' /> Support
          </a>
        </li>
        <li className='border-t'>
          <button
            className='pl-[15px] w-full text-left flex gap-[10px] items-center text-[12px] font-[500]'
            onClick={async () => {
              const authApi = AuthApi();
              try {
                const resp = await authApi.logoutCreate();
                if (resp.data.success) {
                  setAuth(null);
                }
              } catch (error) {
                setAuth(null);
              }
            }}
          >
            <Icon iconName='logout' className='w-[14px]' /> Logout
          </button>
        </li>
      </ul>
    </div>
  );
};

const LayoutV2 = ({ children }: { children?: ReactNode }) => {
  useScrollToTopV2();
  const { toggleMiniSidebar } = useContext(GlobalSettingsContext);

  return (
    <div className='flex min-w-[1240px] '>
      {/* left sidebar */}
      <MainSidebar />
      {/* mainContent */}
      <div className='flex-1 max-h-screen'>
        {/* globalactions */}
        <div className='flex justify-between h-10 border-b items-center bg-[#fff] box-content	'>
          <div className='flex items-center'>
            {
              <div
                className='h-[40px] w-[40px] flex justify-center items-center cursor-pointer'
                onClick={toggleMiniSidebar}
              >
                <div className='w-[30px] h-[30px] flex justify-center items-center bg-[#f4f4f4] rounded-[4px] '>
                  <Icon iconName='menu' className='w-[16px] h-[16px]' />
                </div>
              </div>
            }
            <div
              className={classNames('w-[300px]', {
                // 'border-l': isMiniSidebar
              })}
            >
              <SearchBar width='w-[400px]' showBorder={false} />
            </div>
          </div>
          <div className='flex-1'></div>
          <div className='flex items-center gap-[20px]'>
            <div className='flex items-center gap-[30px]'>
              <a
                href='https://www.zettablock.com/pricing'
                target='_blank'
                rel='noreferrer'
                className='text-[#2B2F33] text-[12px] font-[500]'
              >
                Pricing
              </a>
              <a
                href='https://docs.zettablock.com/reference/api-intro'
                target='_blank'
                rel='noreferrer'
                className='text-[#2B2F33] text-[12px] font-[500]'
              >
                API
              </a>
              <a
                href='https://docs.zettablock.com/'
                target='_blank'
                rel='noreferrer'
                className='text-[#2B2F33] text-[12px] font-[500]'
              >
                Docs
              </a>
            </div>
            <UserProfileMenuV2 />
            <BuildButton />
          </div>
        </div>

        <div
          className='h-[calc(100vh-41px)] overflow-y-auto bg-[#fff] v2'
          id='subpage'
        >
          {children || <Outlet />}
        </div>
      </div>
    </div>
  );
};

export default LayoutV2;
