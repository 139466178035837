/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { Form, Input, InputRef, Select, Table } from "antd"
import classNames from "classnames"
import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react"
import { TabName } from "./TableProject"
import { FiDatabase } from "react-icons/fi"

import testMD from './test.md'
import { useNavigate, useParams } from "react-router"
import { getTableDetailById, getTablePreview, updateTableColumnsMetadata } from "../workspace/apis"
import { FormInstance, useForm } from "antd/es/form/Form"
import MarkdownBlock from "./MarkdownBlock"
import { ModelGetTableDetailResponse } from "../../api/__gen__/data-contracts"
import ImagePicker from "../image-upload/ImagePicker"
import ChainDisplay from "../workspace/api-project/components/ChainDisplay"
import LabelsDisplay from "../workspace/api-project/components/LabelsDisplay"
import ChainSelect from "../workspace/api-project/components/ChainSelect"
import Playground from "./Playground"
import { useSearchParams } from "react-router-dom"

interface EditableRowProps {
  index: number;
}

const EditableContext = createContext<FormInstance<any> | null>(null);
interface Item {
  [key: string]: string
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      // eslint-disable-next-line no-console
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

// eslint-disable-next-line react/prop-types
export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = useForm()
  return <Form form={form} component={false}>
    <EditableContext.Provider value={form}>
      <tr {...props} />
    </EditableContext.Provider>
  </Form>
}

export const TableDisplay = ({
  publicVisible = false,
  isEditMode = false,
  onUpdate
}: {
  isEditMode?: boolean
  publicVisible?: boolean
  onUpdate: (tableId: string, tableMetadata: {
    example: string
    summary: string
    desc: string
  }) => void
}) => {

  const params = useParams()

  const [tableDetail, setTableDetail] = useState<ModelGetTableDetailResponse>({})

  const [previewData, setPreviewData] = useState<any>([])
  const [columns, setColumns] = useState<any>([])
  const [currentTab, setCurrentTab] = useState(TabName.COLUMNS)

  useEffect(() => {
    const
      getAndSetData = async () => {
        if (!tableDetail || !tableDetail.table?.schema || !tableDetail.table.database || !tableDetail.table.name) return
        const res = await getTablePreview('auto', tableDetail.table.database, tableDetail.table.name || '')


        setPreviewData(res.rows)
        setColumns(res.metadata.columns.map(e => ({
          title: e.name,
          type: e.type,
          key: e.name,
          dataIndex: e.name,
          width: 200,
        })))
      }

    getAndSetData()

  }, [tableDetail?.table, currentTab])

  const fetchAndSetTableDetail = async (tableId: string) => {
    const resp = await getTableDetailById(tableId);
    setTableDetail(resp)
  }
  useEffect(() => {
    if (!params.tableId) return
    fetchAndSetTableDetail(params.tableId)
  }, [params.tableId])

  const [md, setMd] = useState<any>('')

  const navigate = useNavigate()

  useEffect(() => {
    fetch(testMD).then((res) => res.text()).then((text) => {
      setMd(text)
    })
  })

  const tabs = Object.values(TabName)



  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };



  const [tableMetadata, setTableMetadata] = useState<any>({
    example: '',
    summary: '',
    desc: '',
  })

  useEffect(() => {
    setTableMetadata({
      example: tableDetail?.table?.example,
      summary: tableDetail?.table?.summary,
      desc: tableDetail?.table?.desc,
      chains: tableDetail?.table?.chains,
      labels: tableDetail?.table?.labels,
      image: tableDetail?.table?.image,
    })
  }, [tableDetail])

  const [searchParams] = useSearchParams()
  useEffect(() => {
    if (searchParams.get('tab')) {
      setCurrentTab(searchParams.get('tab') as TabName)
    } else {
      setCurrentTab(TabName.COLUMNS)
    }
  }, [searchParams.get('tab')])

  const renderTabContent = useCallback(() => {
    switch (currentTab) {
      case TabName.OVERVIEW:
        return <div className="h-[calc(100vh-400px)] overflow-auto "><div className="p-[30px]">
          <MarkdownBlock
            isEditMode={isEditMode}
            value={
              isEditMode ? tableMetadata.desc : tableDetail?.table?.desc || ''
            }
            onChange={(e) => {
              setTableMetadata({
                ...tableMetadata,
                desc: e
              })
            }}
          />
        </div></div>
      case TabName.COLUMNS:
        return <div className="h-[calc(100vh-400px)] overflow-auto"><div className="p-[30px]">
          <Table
            pagination={false}
            dataSource={tableDetail?.column || []}
            components={components}
            rowClassName={() => 'editable-row'}
            columns={[
              {
                title: 'TITLE',
                dataIndex: 'name',
                key: 'name',
                render: (text: string) => {
                  return <div className="flex items-center">
                    <FiDatabase />
                    <div className="ml-2">{text}</div>
                  </div>

                },
                sorter: (a: any, b: any) => a.name.localeCompare(b.name),
              },
              {
                title: 'TYPE',
                dataIndex: 'type',
                key: 'type',
                sorter: (a: any, b: any) => a.type.localeCompare(b.type),
              },
              {
                title: 'DESCRIPTION',
                dataIndex: 'desc',
                key: 'desc',
                render: (text: string) => {
                  return text || '-'
                },
                onCell: (record) => {
                  return {
                    record,
                    editable: true,
                    dataIndex: 'desc',
                    title: 'Description',
                    handleSave: async (row: any) => {
                      await updateTableColumnsMetadata(
                        tableDetail?.table?.id || '',
                        row.desc,
                        row.name
                      )
                      await fetchAndSetTableDetail(params?.tableId || '')
                    }
                  }
                },
                sorter: (a: any, b: any) => a.desc.localeCompare(b.desc),
              },
              {
                title: 'DEFAULT',
                dataIndex: 'default',
                key: 'default',
              },
            ]}
          />
        </div></div>
      case TabName.PLAYGROUND:
        if (!tableDetail?.table?.graph_ql) return <div className="p-4 text-center pt-12">
          No GraphQL endpoint found
        </div>

        return <div className="h-[calc(100vh-400px)] overflow-auto"><Playground direction="vertical" url={
          tableDetail?.table?.graph_ql
        } /></div>
      case TabName.PREVIEW:
        return <div className="h-[calc(100vh-400px)] overflow-scroll xscroll"><div className="p-[30px]">
          <Table
            pagination={false}
            bordered
            className="preview-table"
            // scroll={{
            //   x: 2000,
            // }}
            dataSource={previewData}
            columns={columns}
          />
        </div></div>
      case TabName.EXAMPLE:
        return <div className="h-[calc(100vh-400px)] overflow-auto"><div className="px-8 py-8">
          <MarkdownBlock
            isEditMode={isEditMode}
            value={
              isEditMode ? tableMetadata.example : tableDetail?.table?.example || ''
            }
            onChange={(e) => {
              setTableMetadata({
                ...tableMetadata,
                example: e
              })
            }}
          />
        </div></div>

      default:
        return null
    }
  }, [currentTab, tableDetail?.table, tableMetadata, isEditMode, previewData])



  return <div className="min-w-[940px] w-[940px] overflow-y-auto mx-auto">
    {/* title and discription */}
    <div className="flex-1 min-w-fit px-[30px] py-[30px]">
      <div>
        <div className="text-[20px] font-bold mb-[10px]">
          <div className="flex gap-3 items-center justify-between">
            {
              `${tableDetail.table?.database || 'Untitled Database'} - ${tableDetail?.table?.name || 'Untitled Table'}`
            }
            {
              publicVisible ? null : <div onClick={() => {
                navigate(`/v2/build?tableId=${tableDetail.table?.id}`)
              }}
                className="cursor-pointer text-[12px] p-[5px] border rounded-[4px]"
              >
                Use this table
              </div>
            }
          </div>
          <div className="mt-[15px]">
            <ChainDisplay
              chains={tableMetadata?.chains || []}
              className="mb-[15px]"
            />
            <LabelsDisplay labels={tableMetadata?.labels || []} />

          </div>

        </div>
        <div className="mt-2 w-1/2">
          {
            !isEditMode ? null :
              <ChainSelect
                onChange={(e) => {
                  setTableMetadata({
                    ...tableMetadata,
                    chains: e
                  })
                }}
                value={tableMetadata?.chains || []}
              />

          }

        </div>
        {
          isEditMode && <div className="mt-2 w-1/2">
            <ImagePicker
              onChange={(e) => {
                setTableMetadata({
                  ...tableMetadata,
                  image: e
                })
              }}
              value={tableMetadata?.image || ''} />
          </div>
        }
        <div className="mt-2 w-1/2">
          {
            !isEditMode ? null : <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Labels"
              value={tableMetadata.labels || []}
              onChange={(e) => {
                setTableMetadata({
                  ...tableMetadata,
                  labels: e
                })
              }}
              options={tableMetadata.labels?.map(e => {
                return {
                  label: e,
                  value: e
                }
              })}
            />
          }

        </div>
        <div className="" >
          {isEditMode ? <div className="flex gap-2 items-center group">
            <Input
              value={tableMetadata.summary}
              onChange={(e) => {
                setTableMetadata({
                  ...tableMetadata,
                  summary: e.target.value
                })
              }}
            />
            <div className="min-w-[80px] h-[36px]">
              <button className="btn btn-primary  group-hover:block" onClick={() => {
                if (params.tableId) {
                  onUpdate(params.tableId, tableMetadata)
                }

              }}>Update</button>
            </div>
          </div> :
            <span>
              {tableDetail?.table?.summary}
            </span>}

        </div>
      </div>
    </div>
    {/* Tabs */}
    <div className="w-full">
      <div role="tablist" className="tabs border-b w-full gap-3">
        <div className="pl-[15px]"></div>
        {
          tabs.map((tab) => {
            if (tab === TabName.PLAYGROUND && (!tableDetail?.table?.graph_ql || publicVisible)) { return null }
            return <a
              className={classNames('tab pb-[18px] text-[14px] h-auto px-[5px]', {
                'border-b border-[#263dff] font-semibold text-[#000]': tab === currentTab,
              })}
              key={Math.random().toString()} role="tab" onClick={
                () => {
                  setCurrentTab(tab)
                  window.history.pushState({}, '', `?tab=${tab}&from=${searchParams.get('from')}`)
                }
              }>
              {tab}
            </a>
          })
        }
      </div>
    </div>
    {/* tab content */}
    {
      renderTabContent()
    }
  </div>
}
