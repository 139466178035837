/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelCreateTriggerRequest,
  ModelCreateTriggerResponse,
  ModelDeleteTriggerResponse,
  ModelGetTriggerResponse,
  ModelUpdateTriggerRequest,
  ModelUpdateTriggerResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Triggers<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description create trigger on top of the userapi endpoint
   *
   * @tags example
   * @name TriggersCreate
   * @summary create trigger
   * @request POST:/triggers
   */
  triggersCreate = (
    request: ModelCreateTriggerRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelCreateTriggerResponse, any>({
      path: `/triggers`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get trigger
   *
   * @tags example
   * @name TriggersDetail
   * @summary get trigger
   * @request GET:/triggers/{id}
   */
  triggersDetail = (id: string, params: RequestParams = {}) =>
    this.request<ModelGetTriggerResponse, any>({
      path: `/triggers/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description update trigger
   *
   * @tags example
   * @name TriggersCreate2
   * @summary update trigger
   * @request POST:/triggers/{id}
   * @originalName triggersCreate
   * @duplicate
   */
  triggersCreate2 = (
    id: string,
    request: ModelUpdateTriggerRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelUpdateTriggerResponse, any>({
      path: `/triggers/${id}`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description delete trigger
   *
   * @tags example
   * @name TriggersDelete
   * @summary delete trigger
   * @request DELETE:/triggers/{id}
   */
  triggersDelete = (id: string, params: RequestParams = {}) =>
    this.request<ModelDeleteTriggerResponse, any>({
      path: `/triggers/${id}`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
