import produce from 'immer';
import { omit, pick, set } from 'lodash';
import { ModelQueryChartItem } from '../../api/__gen__/data-contracts';
import { BarChartUiOptions } from '../queryBuilder/components/BarChartComposeV2Hooks';
import { convertDBParamsStrToParams } from '../queryBuilder/QueryPadState';
import { TEChartTypeType } from './components/EChart';
import {
  ChartEntity,
  CommonChartEntityKeys,
  isEChartV2,
  TableChartEntity,
} from './types';
import { BarChartV2 } from './types-v2';

export function parseChartUiOptions(uiOptionsStr?: string) {
  let parsedOptions;
  try {
    parsedOptions = JSON.parse(uiOptionsStr || '{}');
  } catch (e) {
    // TODO better defaulting the uiOptions object
    return { type: 'table' } as TableChartEntity;
  }
  return parsedOptions;
}

export function isBarChartV2UiOptions(
  uiOptions: unknown
): uiOptions is BarChartUiOptions {
  return (uiOptions as BarChartUiOptions).dataOptions !== undefined;
}

export function convertDBChartToUIChart(
  dbChart: ModelQueryChartItem
): ChartEntity {
  const uiOptions = parseChartUiOptions(dbChart.uiOptionsStr);

  const queryParams = convertDBParamsStrToParams(dbChart?.queryParamsStr || '');

  if (isBarChartV2UiOptions(uiOptions)) {
    return {
      ...dbChart,
      id: dbChart?.id || '',
      type: dbChart.type as TEChartTypeType,
      queryParams,
      uiOptions,
    } as BarChartV2;
  }

  const chartVx = {
    ...dbChart,
    ...uiOptions,
    queryParams,
  };

  return {
    ...chartVx,
    uiOptions,
  };
}

export function convertUIChartToDBChart(
  uiChart: ChartEntity
): ModelQueryChartItem {
  if (isEChartV2(uiChart)) {
    return {
      ...uiChart,
      uiOptionsStr: JSON.stringify(uiChart.uiOptions),
    };
  }

  const dbChart = produce(uiChart, (draft) => {
    set(draft, 'options.xAxis.data', []);
  });

  return {
    ...pick(uiChart, CommonChartEntityKeys),
    uiOptionsStr: JSON.stringify(omit(dbChart, ['id'])),
  };
}
