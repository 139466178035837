/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { IoFlash } from 'react-icons/io5';
import QueryBuilderV2 from '../../modules/queryBuilder/QueryBuilderV2';
import { BiArrowBack } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Button, Form, Input, Radio, Tag, Select } from 'antd';
import CodeExample from '../components/CodeExample';
import { useNewApiStep2 } from '../../modules/api/NewApiHook';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  AQueryRunErrorMessage,
  Column,
  SQueryResults,
} from '../../modules/queryBuilder/QueryState';
import { useQueryIdV3 } from '../../modules/queryBuilder/QueryPadStateV3';
import { INTERVAL_SELECTIONS } from '../../modules/api/const';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import { useQueryRunV3 } from '../../modules/queryBuilder/QueryBuilderHooksV3';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getTableDetailById } from '../workspace/apis';
import { toast } from 'react-toastify';
import IncrementalApiQueryPad from '../../modules/api/IncrementalApiQueryPad';
import useAuth from '../../hooks/auth';
import { clientSqlDefaultLimit } from '../../modules/dashboard/config';
import { useQueryPadInject } from '../../modules/database/TableSchemaState';
import {
  convertDBParamsStrToParams,
  formatSql,
} from '../../modules/queryBuilder/QueryPadState';
import { tempFilterFn } from '../../modules/queryBuilder/TableColumnsV3';
import { formatColumn } from '../../modules/queryBuilder/utils/sql';
import {
  PreviewQueryResultId,
  useRunPreviewData,
} from '../../modules/queryBuilder/QueryBuilderHooks';
import { track } from '../tracking';
import DragabblePreview from './DragabblePreview';
import usePreviewData from './usePreviewData';

export const ColumnRow = () => {};
export const CloumnGroup = ({ onChange, columns = [] }: any) => {
  return (
    <div className=''>
      <div className='text-[12px] text-[#777A80] mb-[10px]'>
        Column to Indexing
      </div>
      <div className='h-[150px] max-h-[150px] overflow-y-auto p-[10px] border rounded'>
        {columns.map((item: any) => {
          return (
            <label className='flex items-center w-full hover:bg-[#f2f2f2] p-[5px] rounded  gap-[8px] mb-[5px]'>
              <input
                type='checkbox'
                onChange={(e) => {
                  onChange(item, e.target.checked);
                }}
              />
              <div className='flex justify-between flex-1'>
                <div className='text-[12px] text-[#2D2F34]'>{item.name}</div>
                <div className='text-[10px] text-[777A80] p-[5px] rounded bg-[#f2f2f2] h-[20px] leading-[10px]'>
                  {item.type}
                </div>
              </div>
            </label>
          );
        })}
      </div>
    </div>
  );
};
const FormItem = Form.Item;
const { TextArea } = Input;
const options: any = [];
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}
const GraphQlForm = () => {
  // get data from useNewApiStep2
  const [
    { rpcStatusQueryLoading, rpcStatusCreating, values, queryDetail },
    { createNewApi, setValues },
  ] = useNewApiStep2(true);
  const queryId = useQueryIdV3();

  const [queryResults] = useRecoilState(SQueryResults(queryId));

  const columns = queryResults?.results?.metadata?.columns || [];

  const columnsOptions = columns.map((item: any) => {
    return {
      label: item.name,
      value: item.name,
    };
  });

  const [form] = Form.useForm();

  const onChange = (item: Column, checked: boolean) => {
    const newValues = values.resultIndexes ? [...values.resultIndexes] : [];

    if (checked) {
      // add
      newValues.push({ columns: [item.name] });
    } else {
      const idx = newValues.findIndex((i) => i.columns?.includes(item.name));
      // remove
      if (idx > -1) {
        newValues.splice(idx, 1);
      }
    }

    setValues({ ...values, resultIndexes: newValues });
  };

  return (
    <div className='py-4 px-4 flex flex-col justify-between h-[calc(100vh-96px)]'>
      <Form
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
        form={form}
        layout='vertical'
        onFinish={async () => {
          await createNewApi(true);

          // resetIncrementalRelatedFields
          setValues({
            ...values,
            resultPrimaryKey: [],
            resultWriteMode: undefined,
            init: undefined,
            resultDatabase: 'zettadb1',
          });
        }}
        onValuesChange={(e) => {
          if (e.dataIngestIntervalSeconds !== undefined) {
            if (e.dataIngestIntervalSeconds !== -1) {
              if (e.dataIngestIntervalSeconds === 0) {
                setValues({
                  ...values,
                  database: queryDetail.database,
                  ...e,
                  resultWriteMode: undefined,
                });
              } else {
                setValues({ ...values, database: queryDetail.database, ...e });
              }
            } else {
              setValues({
                ...values,
                database: queryDetail.database?.startsWith('realtime')
                  ? queryDetail.database
                  : 'realtimeDB',
                ...e,
              });
            }

            return;
          }

          if (e.displayName) {
            setValues({ ...values, displayName: e.displayName });
          } else {
            setValues({ ...values, ...e });
          }
        }}
      >
        <div className='use-form'>
          <FormItem
            label='Title'
            name='displayName'
            rules={[{ required: true, message: 'API name is required' }]}
          >
            <Input placeholder='Title' />
          </FormItem>
          <FormItem label='Description(optional)' name='description'>
            <TextArea rows={4} placeholder='Optional' />
          </FormItem>
          <FormItem
            label='Data Refresh Frequency'
            name='dataIngestIntervalSeconds'
            rules={[
              { required: true, message: 'Data Refresh Frequency is required' },
            ]}
          >
            <Select
              style={{ width: '100%' }}
              placeholder='Please select'
              defaultValue={undefined}
              options={
                queryDetail.database?.startsWith('realtime')
                  ? INTERVAL_SELECTIONS
                  : INTERVAL_SELECTIONS.filter((i: any) => i.value !== -1)
              }
            />
          </FormItem>

          <div className='mb-[20px]'>
            <CloumnGroup onChange={onChange} columns={columns} />
          </div>
          {(values.resultWriteMode === 'Append' ||
            values.dataIngestIntervalSeconds === -1) && (
            <FormItem
              label='Set Primary Key'
              name='resultPrimaryKey'
              rules={[{ required: true, message: ' Primary Key is required' }]}
            >
              <Select
                mode='multiple'
                allowClear
                style={{ width: '100%' }}
                placeholder='Please select'
                defaultValue={[]}
                options={columnsOptions}
              />
            </FormItem>
          )}
          {values.dataIngestIntervalSeconds &&
          values.dataIngestIntervalSeconds > 0 ? (
            <FormItem
              label='Enable Incremental Data Refresh'
              name='resultWriteMode'
            >
              <Select
                style={{ width: '100%' }}
                placeholder='Please select'
                value={values.resultWriteMode}
                options={[
                  {
                    label: 'Yes',
                    value: 'Append',
                  },
                  {
                    label: 'No',
                    value: '',
                  },
                ]}
              />
            </FormItem>
          ) : null}
        </div>

        <div className=''>
          <FormItem>
            <button
              type='submit'
              className={classNames('w-full btn', {
                'btn-disabled': rpcStatusCreating.isLoading,
                'loading':  rpcStatusCreating.isLoading,
                'btn-primary': !rpcStatusCreating.isLoading,
              })}
            >
              DEPLOY GRAPHQL
            </button>
          </FormItem>
        </div>
      </Form>
    </div>
  );
};

const SqlSection = () => {
  const [format, setFormat] = useState('json');
  const renderContents = () => {
    switch (format) {
      case 'json':
        return (
          <>
            <div className='mb-6'>
              <CodeExample code='asdasdasdas' title='Installation' />
            </div>
            <div className='mb-6'>
              <CodeExample code='asdasdasdas' title='Code Example - JSON' />
            </div>
          </>
        );
      case 'csv':
        return (
          <>
            <div className='mb-6'>
              <CodeExample code='asdasdasdas' title='Installation' />
            </div>
            <div className='mb-6'>
              <CodeExample code='asdasdasdas' title='Code Example - CSV' />
            </div>
          </>
        );
      default:
        return (
          <>
            <div className='mb-6'>
              <CodeExample code='asdasdasdas' title='Installation' />
            </div>
            <div className='mb-6'>
              <CodeExample code='asdasdasdas' title='Code Example' />
            </div>
          </>
        );
    }
  };

  const commonClassNamses = 'flex-1 tab';
  const activeClassNames =
    'font-semibold tab-active border-[#263dff] text-[#263dff] bg-[#f6f7f9]';

  return (
    <div className='p-3'>
      {/* tabs */}
      <div
        role='tablist'
        className='tabs border rounded flex overflow-hidden mb-6 mt-3'
      >
        <a
          role='tab'
          className={classNames(commonClassNamses, {
            [activeClassNames]: format === 'json',
          })}
          onClick={() => {
            setFormat('json');
          }}
        >
          JSON
        </a>
        <a
          role='tab'
          className={classNames(commonClassNamses, {
            [activeClassNames]: format === 'csv',
          })}
          onClick={() => {
            setFormat('csv');
          }}
        >
          CSV
        </a>
      </div>
      {/* contents */}
      {renderContents()}
    </div>
  );
};

export const UseSection = ({ onClose }: any) => {
  const [currentUseTab, setCurrentUseTab] = useState('graphql');

  const renderContents = () => {
    switch (currentUseTab) {
      case 'graphql':
        return <GraphQlForm />;
      case 'sql':
        return <SqlSection />;
      default:
        return <GraphQlForm />;
    }
  };

  return (
    <div className='fixed right-0 bg-[#fff] h-screen top-0 w-[360px] border-l z-40'>
      <div className='border-b flex justify-between items-center px-3 py-2 font-semibold'>
        <div>USE</div>
        <div>
          <MdClose onClick={onClose} className='cursor-pointer' />
        </div>
      </div>

      {/* tabs */}
      <div role='tablist' className='tabs tabs-bordered gap-4 px-2 border-b'>
        <a
          role='tab'
          className={classNames(
            'text-lg font-semibold tab py-3 px-1 mb-[-2px] h-auto border-b border-transparent',
            {
              'tab-active border-[#263dff]': currentUseTab === 'graphql',
            }
          )}
          onClick={() => {
            setCurrentUseTab('graphql');
          }}
        >
          GraphQL
        </a>
        {/* <a role="tab" className={
        classNames('text-lg font-semibold tab py-3 px-1 mb-[-2px] h-auto border-b border-transparent', {
          'tab-active border-[#263dff]': currentUseTab === 'sql'
        })}
        onClick={() => {
          setCurrentUseTab('sql')
        }}
      >
        SQL API
      </a> */}
      </div>
      {/* contents */}
      <div className='max-h-[calc(100vh-96px)] overflow-auto'>
        {renderContents()}
      </div>
    </div>
  );
};

const Build = ({ newBuild }: any) => {
  const [
    { rpcStatusQueryLoading, rpcStatusCreating, values, queryDetail },
    { createNewApi, setValues },
  ] = useNewApiStep2(true);

  const { previewDataList } = usePreviewData();

  const [searchParams] = useSearchParams();

  const fetchAndSetTableDetail = async (tableId: string) => {
    const resp = await getTableDetailById(tableId);
    return resp;
  };

  const [runPreviewData] = useRunPreviewData(true);
  const setQueryRunErrorMessage = useSetRecoilState(AQueryRunErrorMessage);

  const injectKeyword = useQueryPadInject();
  const handleFormat = useCallback(
    async (item: any, cols: any) => {
      if (item?.database) {
        injectKeyword(
          formatSql(
            `SELECT ${tempFilterFn(
              item?.name,
              item.database,
              cols || [],
              item?.database?.startsWith('realtime')
            )
              ?.map((e) => formatColumn(e.name))
              .join(', ')}\n  FROM ${item.database}.${
              item?.name
            } limit ${clientSqlDefaultLimit}`,
            {
              language: item?.sqlDialect,
            }
          )
        );
      }
    },
    [injectKeyword, fetchAndSetTableDetail]
  );
  useEffect(() => {
    const tableId = searchParams.get('tableId');
    if (tableId) {
      fetchAndSetTableDetail(tableId).then((res) => {
        handleFormat(res.table, res?.column);
        setQueryRunErrorMessage('');

        track('Preview Data - Persisted table ID from URL', {
          tableId,
          database: res.table?.database,
          schema: res.table?.database,
          table: res.table?.name,
        });
        // should not open table preview by default
        /* runPreviewData({
          queryId: PreviewQueryResultId,
          selectedDB: res.table?.database,
          schema: res.table?.database,
          table: res.table?.name,
        }); */
      });
    }
  }, [searchParams]);

  const [{ auth }] = useAuth();
  const resetIncrementalRelatedFields = () => {
    // resultPrimaryKey
    // reset resultWriteMode
    setValues({
      ...values,
      resultPrimaryKey: [],
      resultWriteMode: undefined,
      init: undefined,
      resultDatabase: 'zettadb1',
    });
  };

  useEffect(() => {
    resetIncrementalRelatedFields();
  }, []);

  const [showUse, setShowUse] = useState(false);
  const [{ menu }, { getPath }] = useWorkspace();
  const [{ queryValues }] = useQueryRunV3({ queryPath: `${getPath(menu)}` });

  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(-1);
  };
  const hasParamsStr =
    queryDetail?.paramsStr &&
    convertDBParamsStrToParams(queryDetail?.paramsStr).length > 0;

  return (
    <div id='drag-container' className='min-w-[1240px] w-full relative'>
      <QueryBuilderV2
        isV2
        newBuild={newBuild}
        onUseButtonClick={() => {
          setShowUse(true);
          resetIncrementalRelatedFields();
        }}
        disabled={!!hasParamsStr}
      />
      {showUse && (
        <UseSection
          onClose={() => {
            setShowUse(false);
            resetIncrementalRelatedFields();
          }}
        />
      )}
      {showUse && values.resultWriteMode === 'Append' && (
        <div className='fixed right-[360px] bg-[#fff] h-screen top-0 w-[360px] border-l z-40'>
          <div className='px-[20px] py-[12px] border-b text-[12px] text-[#2D2F34] leading-[16px]'>
            Incremental SQL Code
          </div>
          <IncrementalApiQueryPad />
        </div>
      )}
      {previewDataList.map((item: any) => {
        return <DragabblePreview key={item.key} previewData={item} />;
      })}
    </div>
  );
};

export default Build;
