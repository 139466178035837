import { useMemo } from "react";
import { useRecoilState } from "recoil";
import UsageBar from "../components/UsageBar"
import { AuthState } from "../modules/auth/AuthState";
import { formatNum } from "../utils/format";

const UsageDisplay = () => {
  const [auth] = useRecoilState(AuthState);

  const isTrialUser = useMemo(() => {
    if (!auth) return false;
    if (!auth.subscription) return false;

    if (auth.tenant !== 'community' && auth.subscription?.isTrial) {
      return true;
    }

    return false;
  }, [auth]);

  const isFreePlanUser = useMemo(() => {
    if (!auth || !auth.subscription) return false;
    if (auth.tenant === 'community' && auth.subscription?.plan === 'free') {
      return true;
    }

    return false;
  }, [auth]);

  const isBuildPlanUser = useMemo(() => {
    if (!auth || !auth.subscription) return false;
    if (auth.tenant === 'community' && auth.subscription?.plan === 'build') {
      return true;
    }

    return false;
  }, [auth]);

  const isEnterpriseUser = useMemo(() => {
    if (!auth || !auth.subscription) return false;
    if (auth.tenant !== 'community' && !auth.subscription?.isTrial) {
      return true;
    }

    return false;
  }, [auth]);

  const usagePercentage = useMemo(() => {
    const res = {
      percentage: 0,
      details: '',
    };
    if (!auth || !auth.subscription) return res;
    if (
      auth.subscription.cuLimit === undefined ||
      auth.subscription.cuUsed === undefined
    )
      return res;

    const percentage = Math.trunc(
      (100 * auth.subscription.cuUsed) / auth.subscription.cuLimit
    );

    res.percentage = percentage > 100 ? 100 : percentage;
    res.details = `${formatNum(auth.subscription.cuUsed)}/${formatNum(
      auth.subscription.cuLimit
    )}`;

    return res;
  }, [auth]);

  const progress = useMemo(() => {
    const res = {
      percentage: 0,
      daysLeft: '',
    };
    if (!auth || !auth.subscription) return res;

    const { trialPeriod, createdTime } = auth.subscription;

    if (trialPeriod === undefined || createdTime === undefined) return res;

    const currentTime = Date.now();

    res.percentage = Math.trunc(
      (100 * (currentTime - createdTime)) / trialPeriod
    );
    const daysLeft = Math.floor(
      (createdTime + trialPeriod - currentTime) / (1000 * 24 * 3600)
    );
    res.daysLeft = `${daysLeft < 0 ? 0 : daysLeft} Day${daysLeft !== 1 ? 's' : ''
      } Left`;

    return res;
  }, [auth]);

  const reachLimit = useMemo(() => {
    if (isEnterpriseUser) return '';

    if (isTrialUser && progress.percentage >= 100) {
      return 'your trial period for this has been expired';
    }

    return usagePercentage.percentage >= 100
      ? "you've reached your CU usage limit"
      : '';
  }, [isTrialUser, isEnterpriseUser, progress, usagePercentage]);

  return <div className='flex flex-1 space-x-3'>
    {isTrialUser && (
      <UsageBar
      fullWidth
        warning={!!reachLimit}
        progress={progress.percentage}
        title={'Trial Enterprise'}
        details={progress.daysLeft}
      />
    )}

    {isFreePlanUser && (
      <UsageBar
      fullWidth
        warning={!!reachLimit}
        progress={usagePercentage.percentage}
        title='Free Plan'
        details={usagePercentage.details}
      />
    )}

    {isEnterpriseUser && (
      <UsageBar
      fullWidth
        warning={!!reachLimit}
        progress={usagePercentage.percentage}
        title='Enterprise'
        details={usagePercentage.details}
      />
    )}

    {isBuildPlanUser && (
      <UsageBar
      fullWidth
        warning={false}
        progress={usagePercentage.percentage}
        title='Build'
        details={usagePercentage.details}
      />
    )}
    {!!reachLimit && (
      <>
        <input
          type='checkbox'
          id='my-modal'
          className='modal-toggle'
          defaultChecked
        />
        <div className='modal' style={{ marginLeft: 0 }}>
          <div className='modal-box'>
            <h3 className='font-bold text-lg'>{reachLimit}</h3>
            <p className='py-4'>
              Hi there! It looks like {reachLimit}. If you have any issues
              or questions, please don’t hesitate to contact our friendly
              support team at{' '}
              <a className='text-primary' href='mailto:team@zettablock.com'>
                team@zettablock.com
              </a>
              !
              <br />
              <br />
              Happy Building, <br />
              ZettaBlock Support Team
            </p>
            <div className='modal-action'>
              <label htmlFor='my-modal' className='btn'>
                OK
              </label>
            </div>
          </div>
        </div>
      </>
    )}

  </div>
}

export default UsageDisplay;