import { AxiosError } from 'axios';
import { QueryApi } from '../../api/client';

export async function fetchQueryruns({ queryId }: { queryId?: string }) {
  if (!queryId) {
    return null;
  }

  try {
    const client = QueryApi();
    const resp = await client.queryrunsDetail(queryId);
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}
