import { atom, useRecoilValue } from "recoil";

export const PreviewDataState = atom({
  key: 'PREVIEW_DATA_STATE',
  default: {},
});

const usePreviewData = () => {
  const previewData = useRecoilValue(PreviewDataState);
  const previewDataList = Object.keys(previewData).map(e => {
    return { ...previewData[e], key: e };
  });

  const getPreviewDataForTable = (shema?: string, table?: string) => {
    if (!shema || !table) return previewData;

    return previewData[`${shema}.${table}`] || [];
  }

  return {
    getPreviewDataForTable,
    previewDataList
  }
}

export default usePreviewData