import { ChangeEvent } from 'react';
import classNames from 'classnames';

export interface IOption {
  label: string;
  value: string;
  disabled?: boolean;
}

export default function Selector({
  options,
  onSelect,
  className,
  value,
  loading,
  disabled,
}: {
  options: IOption[];
  onSelect: (event: ChangeEvent<HTMLSelectElement>) => void;
  className?: string;
  value?: string;
  loading?: boolean;
  disabled?: boolean;
}) {
  return (
    <select
      disabled={disabled}
      value={loading ? 'loading' : value}
      className={classNames('select', className)}
      onChange={onSelect}
    >
      {loading ? (
        <option disabled value='loading'>
          Loading ...
        </option>
      ) : (
        options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))
      )}
    </select>
  );
}
