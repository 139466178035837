import classNames from 'classnames';
import { useCallback, useState } from 'react';

import Modal from '../../../components/modals/Modal';
import { useDashboardParam } from '../DashboardViewPageState';
import { useDashboardDelete } from './DashboardDeleteState';

export default function DashboardDeleteButtonV3({
  onDeleted,
}: {
  onDeleted: (answer: boolean) => void;
}) {
  const [open, setOpen] = useState(false);
  const dashboardId = useDashboardParam();
  const [deleteDashboard, { loading }] = useDashboardDelete(dashboardId);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <button
        className='btn btn-warning'
        onClick={() => {
          setOpen(true);
        }}
      >
        <span>Delete</span>
      </button>
      <Modal
        open={open}
        className='flex flex-col space-y-4'
        onClickBackdrop={() => setOpen(false)}
      >
        <h3 className='flex items-center space-x-2 font-bold text-lg'>
          <span>Deleting the dashboard</span>
        </h3>
        <div className='flex-1 pb-2 overflow-y-auto max-h-[50vh] space-y-1'>
          Are you sure?
        </div>
        <div className='modal-action items-center'>
          <button
            type='button'
            className='btn btn-outline'
            onClick={() => {
              onDeleted(false);
              handleClose();
            }}
          >
            CANCEL
          </button>
          <button
            type='button'
            className={classNames('btn btn-warning', {
              loading,
            })}
            onClick={async () => {
              const success = await deleteDashboard();

              if (success) {
                onDeleted(success);
                handleClose();
              }
            }}
          >
            DELETE
          </button>
        </div>
      </Modal>
    </>
  );
}
