import { produce } from 'immer';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { atomFamily, useRecoilState } from 'recoil';
import { setDeep } from '../../../utils/object';
import { TableChartOptions, TableChartUiOptions } from '../../chart/table-type';
import { isTableChartV2, TableChartEntity } from '../../chart/types';
import { SelectChartByIdState, updateChart } from '../ChartsState';

export const DefaultTableChartUiOptions: TableChartUiOptions = {
  version: 2,
};

export const DefaultTableChart: Omit<TableChartEntity, 'id'> = {
  type: 'table',
  displayName: 'Table Chart',
  uiOptions: DefaultTableChartUiOptions,
};

export const TableChartOptionsState = atomFamily<TableChartOptions, string>({
  key: 'TableChartOptionsState',
  default: {
    displayName: 'Bar chart',
    uiOptions: DefaultTableChartUiOptions,
  },
});

export function useTableChartOptions(chartId: string, readonly?: boolean) {
  const [chart, setChart] = useRecoilState(
    SelectChartByIdState({ id: chartId })
  );
  const [chartOptions, setChartOptionsState] = useRecoilState(
    TableChartOptionsState(chartId)
  );

  const [updating, setUpdating] = useState(false);

  const debouncedEventHandler = useMemo(
    () =>
      debounce((nChartOptions: TableChartOptions) => {
        // trigger rerender chart
        const nChart = {
          ...chart,
          ...nChartOptions,
          type: 'table',
          id: chart?.id || '',
        } as const;

        setChart(nChart);

        // update chart api
        updateChart({ chart: nChart });

        setUpdating(false);
      }, 600),
    [chart, setChart]
  );

  const setChartOptions = useCallback(
    (nChartOptions: TableChartOptions) => {
      if (readonly) {
        return;
      }

      setUpdating(true);

      // update options
      setChartOptionsState(nChartOptions);
      // trigger rerender by updating chart
      debouncedEventHandler(nChartOptions);
    },
    [debouncedEventHandler]
  );

  const setChartOptionsByMap = useCallback(
    // eslint-disable-next-line
    (valueMap: Record<string, any>) => {
      setChartOptions(
        produce(chartOptions, (draft) => {
          setDeep(draft, valueMap);
        })
      );
    },
    [chartOptions, setChartOptions]
  );

  const [init, setInit] = useState(false);
  // initial setup and update chart
  useEffect(() => {
    if (!init && isTableChartV2(chart) && chart.uiOptions) {
      setInit(true);
      setChartOptions({
        uiOptions: chart.uiOptions,
        displayName: chart.displayName,
      });
    }
  }, [chart, init]);

  return [
    { chartOptions, updating },
    { setChartOptions, setChartOptionsByMap },
  ] as const;
}
