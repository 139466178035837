

import { Responsive, WidthProvider } from 'react-grid-layout';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { BsFillGearFill } from 'react-icons/bs';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty, isEqual, max } from 'lodash';

import { BiRocket, BiGitRepoForked } from 'react-icons/bi';
import {
  RiDashboardFill,
  RiSettings4Line,
} from 'react-icons/ri';
import produce from 'immer';
import { useRecoilState } from 'recoil';

import '../../css/react-grid-layout.css';
import '../../css/react-resizable.css';

import PageLoading from '../../components/app/PageLoading';

import usePrompt from '../../hooks/router';
import useAuth from '../../hooks/auth';
import { convertNumberToFormatted, DateTimeFormat } from '../../utils/date';

import { useWorkspace } from '../../layouts/components/workspace-hooks';

import useTitle from '../../hooks/useTitle';

import { ACCESS_MAPPING } from '../../modules/api/const';
import { AuthState, COMMUNITY } from '../../modules/auth/AuthState';
import ProfileAvatar from '../../modules/auth/ProfileAvatar';
import StandaloneChart from '../../modules/chart/StandaloneChart';
import { ChartEntity } from '../../modules/chart/types';
import DashPublishModal from '../../modules/dashboard/DashPublishModal';
import DashSettingModal from '../../modules/dashboard/DashSettingModal';
import DashUnpublishModal from '../../modules/dashboard/DashUnpublishModal';
import DashboardChartConfigModal from '../../modules/dashboard/DashboardChartConfigModal';
import { useMyDashboard, useDashboardEdit, useSaveDashboard, validateDashboardLayouts } from '../../modules/dashboard/DashboardViewPageState';
import DashboardWidgetConfigModal from '../../modules/dashboard/DashboardWidgetConfigModal';
import NewMdWidgetButtonV3 from '../../modules/dashboard/NewMdWidgetButtonV3';
import NewVizSelectorV3 from '../../modules/dashboard/NewVizSelectorV3';
import DashboardDeleteButtonV3 from '../../modules/dashboard/components/DashboardDeleteModalV3';
import DashboardParams from '../../modules/dashboard/components/DashboardParams';
import { MdWidgetType, DefaultGirdItemConfigV3 } from '../../modules/dashboard/dashboard-models';
import { getParamsForAllCharts } from '../../modules/dashboard/utils/charts';
import EditButton from './EditButton';
import Icon from './Icon';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import ShareButton from './ShareButton';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function DashboardViewV2({ isPublic }: { isPublic?: boolean }) {
  /**
   * If we want to remove the resize handle icon
   * see https://github.com/react-grid-layout/react-grid-layout/issues/839
   */
  const [{ menu, }, { getPath, navigate }] = useWorkspace();
  const [searchParams] = useSearchParams();

  const onBackButtonClick = useCallback(() => {
    switch (searchParams.get('from')) {
      case 'home':
        navigate('/v2/explore')
        break;
      case 'explore':
        navigate(`/v2/explore/dashboards`)
        break;
      case 'workspace':
        navigate(`/v2/workspace/dashboards`)
        break;
      case 'search':
        navigate(`/v2/search-results?keyword=${searchParams.get('keyword')}`)
        break;

      default:
        navigate('/v2/explore')
        break;
    }


  }, [searchParams, navigate])
  const isMyWorkspace = true
  const { dashboardId } = useParams<{ dashboardId: string }>();
  const [auth] = useRecoilState(AuthState);

  // latest dashboard fetched from server
  const [dashboard, loading] = useMyDashboard(!!auth);

  const [formData, { updateFormData, reset }] = useDashboardEdit(dashboard);
  const [saving, { saveDashboard, deboundedSaveDashboard }] =
    useSaveDashboard();
  const autoSave = formData?.uiOptions.autoSave;

  const [, { isOwner }] = useAuth(dashboard);
  const [isEditMode, setIsEditMode] = useState(searchParams.get('editMode') === 'true');
  const allowEdit = isMyWorkspace && isOwner;
  const isEditView = allowEdit && isEditMode;

  useTitle('Dashboard', dashboard?.displayName || 'New Dashboard');

  // auto save
  useEffect(() => {
    if (isEditView && autoSave && dashboard && formData) {
      // prevent initial call
      if (!isEqual(formData, dashboard)) {
        if (formData?.uiOptions.autoSave) {
          deboundedSaveDashboard(formData);
        }
      }
    }
  }, [deboundedSaveDashboard, formData]);

  const dashToRender = isEditView ? formData : dashboard;

  const layouts = dashToRender?.uiOptions?.layouts || [];
  const charts = dashToRender?.charts || [];
  const widgets = dashToRender?.uiOptions?.widgets || {};

  const [configureChart, setConfigureChart] = useState<ChartEntity>();
  const [configureWidget, setConfigureWidget] = useState<MdWidgetType>();

  const changed = !isEqual(dashboard, formData);

  usePrompt('Discard changes?', !autoSave && isEditView && changed);

  const allParams = useMemo(() => getParamsForAllCharts(charts), [charts]);

  const [openSetting, setOpenSetting] = useState(false);
  const [openPublish, setOpenPublish] = useState(false);

  if (loading && !dashToRender) {
    return <PageLoading className='bg-transparent' />;
  }

  // @ts-ignore
  const published = dashToRender?.access === 'public';

  const vizButtons = (
    <>
      <NewVizSelectorV3
        selectedViz={formData?.charts}
        onChange={(nCharts) => {
          if (formData) {
            const newFormData = produce(formData, (draft) => {
              draft.charts = nCharts;
              const nWidgets = formData?.uiOptions?.widgets || {};

              if (!draft?.uiOptions?.layouts) {
                draft.uiOptions.layouts = [];
              }

              const nLayouts = draft.uiOptions.layouts || [];

              const existingLayouts = nLayouts.filter((layout) => {
                return (
                  (layout && nWidgets[layout.i]) ||
                  nCharts.find((chart) => chart.id === layout.i)
                );
              });

              let counter = 0;

              const nextX = (counter++ % 2) * 6;
              const currentY = max(nLayouts.map((l) => l.y)) || 0;
              const nextY = currentY
                ? currentY + DefaultGirdItemConfigV3.h
                : currentY;

              const newLayouts = nCharts
                .filter((chart) => {
                  return !nLayouts?.find((layout) => chart.id === layout.i);
                })
                .map((newChart) => {
                  return {
                    i: newChart.id,
                    x: nextX,
                    y: nextY,
                    ...DefaultGirdItemConfigV3,
                  };
                });

              draft.uiOptions.layouts = [...existingLayouts, ...newLayouts];
            });

            updateFormData(newFormData);
          }
        }}
      />
      <NewMdWidgetButtonV3
        onCreate={(nWidget) => {
          const newFormData = produce(formData, (draft) => {
            if (!draft?.uiOptions?.layouts) {
              draft.uiOptions.layouts = [];
            }

            const nLayouts = draft.uiOptions.layouts;
            const currentY = max(nLayouts.map((l) => l.y)) || 0;
            const nextY = currentY
              ? currentY + DefaultGirdItemConfigV3.h
              : currentY;

            draft.uiOptions.layouts.push({
              i: nWidget.id,
              x: 0,
              y: nextY,
              ...DefaultGirdItemConfigV3,
            });

            if (!draft?.uiOptions?.widgets) {
              draft.uiOptions.widgets = {};
            }

            draft.uiOptions.widgets[nWidget.id] = nWidget;
          });

          updateFormData(newFormData);
        }}
      />
    </>
  );

  const isEmptyDash = isEmpty(layouts);

  const renderDashboardWidgetConfigModal = () => {
    return configureWidget && (
      <DashboardWidgetConfigModal
        widget={configureWidget}
        onClose={() => {
          setConfigureWidget(undefined);
        }}
        onApply={(widget) => {
          // update
          const newFormData = validateDashboardLayouts(
            produce(formData, (draft) => {
              if (draft?.uiOptions?.widgets && widget) {
                draft.uiOptions.widgets[widget.id] = widget;
              }
            })
          );

          updateFormData(newFormData);
          setConfigureWidget(undefined);
        }}
        onDelete={(widget) => {
          const newFormData = validateDashboardLayouts(
            produce(formData, (draft) => {
              if (draft?.uiOptions?.widgets && widget) {
                delete draft?.uiOptions?.widgets[widget.id];
              }
            })
          );

          updateFormData(newFormData);
          setConfigureWidget(undefined);
        }}
      />
    )
  }

  const renderDashboardChartConfigModal = () => {
    return configureChart && (
      <DashboardChartConfigModal
        chart={configureChart}
        onClose={() => {
          setConfigureChart(undefined);
        }}
        onDelete={(chart) => {
          if (formData) {
            const newFormData = validateDashboardLayouts(
              produce(formData, (draft) => {
                draft.charts = draft.charts.filter(
                  (item) => item.id !== chart?.id
                );
              })
            );

            updateFormData(newFormData);
          }
          setConfigureChart(undefined);
        }}
      />
    )
  }

  const renderDashboardConfigModal = () => {
    if (!isMyWorkspace) return null

    return <>
      {renderDashboardWidgetConfigModal()}
      {renderDashboardChartConfigModal()}
    </>
  }

  return (
    <div className='v2-dashboard'>
      {!isPublic && <div className="flex justify-between items-center px-[30px] py-[13px] border-b">
        <div className="flex items-center">
          <div className="flex gap-4 items-center text-[#282828] cursor-pointer" onClick={onBackButtonClick}>
            <Icon iconName='back' className="w-[30px] h-[30px]" />
            <div className="font-semibold text-[14px] leading-[14px]">
              {dashboard?.displayName || 'Unknown'}
            </div>
          </div>
          <div className="text-sm text-[#999ca0] flex items-center gap-2">
            <div className="divider divider-horizontal"></div>
            {/* <div className="w-6 h-6 bg-[#e3e3e3] rounded"></div> */}
            <ProfileAvatar creator={dashboard?.creator} className='w-6 bg-[#e3e3e3]' />
            By {dashboard?.creator?.name || dashboard?.creator?.email || 'Unknown'}
            {/* Use moment here to get relative time */}
          </div>
        </div>
        <div className='flex-1'>

          <div className='flex items-center justify-end'>
            <div className='flex space-x-3'>
              {dashboardId && (
                <>
                  {isEditView && (
                    <>
                      <button
                        className='btn btn-primary gap-2'
                        onClick={() => {
                          setOpenSetting(!openSetting);
                        }}
                      >
                        <RiSettings4Line size='1.1rem' />
                        Setting
                      </button>
                      {openSetting && (
                        <DashSettingModal
                          openSetting={openSetting}
                          setOpenSetting={setOpenSetting}
                        />
                      )}

                      {published ? (
                        <div
                          className={classNames({
                            'tooltip tooltip-bottom tooltip-warning opacity-90': auth?.tenant === COMMUNITY,
                          })}
                          data-tip='Please upgrade the plan to make dashboard private'
                        >
                          <button
                            className={classNames('published-button btn btn-primary gap-2 hover:btn-error font-bold', {
                              'gray-bg': auth?.tenant === COMMUNITY,
                            })}
                            disabled={auth?.tenant === COMMUNITY}
                            onClick={() => {
                              setOpenPublish(!openPublish);
                            }}
                          >
                            <BiRocket size='1.1rem' />
                            {auth?.tenant === COMMUNITY ? (
                              <span>Unpublish</span>
                            ) : (
                              <>
                                <span className='when-not-hover'>
                                  Published
                                </span>
                                <span className='when-hover'>Unpublish</span>
                              </>
                            )}
                          </button>
                          {openPublish && (
                            <DashUnpublishModal
                              openPublish={openPublish}
                              setOpenPublish={setOpenPublish}
                            />
                          )}
                        </div>
                      ) : (
                        <>
                          <button
                            className='btn btn-primary gap-2'
                            onClick={() => {
                              setOpenPublish(!openPublish);
                            }}
                          >
                            <BiRocket size='1.1rem' />
                            <span>Publish</span>
                          </button>
                          {openPublish && (
                            <DashPublishModal
                              openPublish={openPublish}
                              setOpenPublish={setOpenPublish}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                  {
                    !isEditView && <CopyToClipboard text={`${window.location.href.replace('explore', 'public')}`}>
                      <div
                        // className='btn btn-primary'
                        onClick={() => {
                          toast.success('Link copied', {
                            autoClose: 3000,
                          });
                        }}
                      >
                        <ShareButton />
                      </div>
                    </CopyToClipboard>
                  }

                  {/* Navigate from dashboard Editing from community dashboard */}
                  {!isMyWorkspace && isOwner && (
                    <>
                      <button
                        className='btn btn-primary gap-2'
                        onClick={() => {
                          navigate(`/my-workspace/${menu}/${dashboardId}?editMode=true`);
                        }}
                      >
                        Edit
                      </button>
                      <div className='border-r my-[0.2rem] border-gray-300' />
                    </>
                  )}

                  {/* Show Edit button if is not in Edit mode */}
                  {
                    allowEdit && <>
                      {
                        !isEditMode ? <EditButton showText={false} onClick={() => {
                          setIsEditMode(prevMode => !prevMode)
                        }} /> : <button className='btn btn-primary gap-2' onClick={() => {
                          setIsEditMode(prevMode => !prevMode)
                        }}>
                          {
                            isEditMode ? 'Preview' : 'Edit'
                          }
                        </button>
                      }

                      <div className='border-r my-[0.2rem] border-gray-300' />
                    </>
                  }

                  {isEditView && (
                    <>
                      <DashboardDeleteButtonV3
                        onDeleted={(success) => {
                          if (success) {
                            // redirec user to list
                            navigate('/v2/workspace/dashboards')
                          }
                        }}
                      />
                      <div className='border-r my-[0.2rem] border-gray-300' />
                    </>
                  )}

                  <span className='hidden'>
                    <button
                      className={classNames(
                        'flex btn btn-primary items-center gap-2',
                        {
                          hidden: !dashboardId,
                          // loading: isCloning,
                        }
                      )}
                      onClick={() => {
                        // clone();
                      }}
                    >
                      <BiGitRepoForked size='1.1rem' />
                    </button>
                  </span>

                  {/* <StarButton
                    id={dashboardId}
                    isStarred={dashToRender?.star}
                    starCount={dashToRender?.starCount}
                    isListView={false}
                    page={'dashboard'}
                  /> */}
                </>
              )}
            </div>
          </div>
        </div>
      </div>}
      {
        isPublic && <div className='px-[120px] flex justify-between items-center py-[20px] border-b'>
          <div className="flex items-center gap-[10px]">
            <div className="flex gap-4 items-center text-[#282828] cursor-pointer" onClick={onBackButtonClick}>
              <div className="font-semibold text-[14px] leading-[14px]">
                {dashboard?.displayName || 'Unknown'}
              </div>
            </div>
            <div className="divider divider-horizontal m-0 h-[20px]"></div>

            <div className="text-[12px] text-[#999ca0] flex items-center gap-2">
              {/* <div className="w-6 h-6 bg-[#e3e3e3] rounded"></div> */}
              <ProfileAvatar creator={dashboard?.creator} className='w-[20px] bg-[#e3e3e3]' />
              By {dashboard?.creator?.name || dashboard?.creator?.email || 'Unknown'}
              {/* Use moment here to get relative time */}
            </div>
          </div>
          <CopyToClipboard text={`${window.location.href.replace('explore', 'public')}`} onCopy={() => {
            toast.success('Link copied', {
              autoClose: 3000,
              position: 'top-center',
            });
          }}><div className='text-[12px] flex items-center gap-[5px] font-[500] cursor-pointer'>
              <Icon iconName='share' className='w-[14px] h-[14px]' />
              Share
            </div></CopyToClipboard>
        </div>
      }


      {isEditView && !isEmptyDash && (
        <div className='fixed bottom-0 z-40 w-full'>
          <div className='w-[1200px] pb-8 px-8 flex justify-center mx-[auto]'>
            <div className='bg-white rounded-xl z-40 p-3 shadow-lg w-full'>
              <div className='flex items-center justify-between'>
                <div className='flex-auto flex items-center gap-3'>
                  <RiDashboardFill size='1.4rem' className='text-primary' />
                  {vizButtons}
                </div>
                <div className='flex items-center flex-none gap-3'>
                  <div className='flex items-center gap-2'>
                    <label
                      className='opacity-70 font-bold cursor-pointer'
                      htmlFor='auto-save-switch'
                    >
                      Auto save
                    </label>
                    <input
                      type='checkbox'
                      id='auto-save-switch'
                      className='toggle toggle-primary'
                      checked={formData?.uiOptions?.autoSave || false}
                      onChange={() => {
                        const data = produce(formData, (draft) => {
                          draft.uiOptions.autoSave =
                            !formData?.uiOptions.autoSave;
                        });

                        updateFormData(data);

                        saveDashboard(data);
                      }}
                    />
                  </div>

                  {!autoSave && (
                    <>
                      <button
                        className={'btn btn-outline'}
                        onClick={() => {
                          reset();
                          navigate(-1);
                        }}
                      >
                        {!autoSave ? 'Cancel' : 'Done'}
                      </button>

                      <button
                        className={classNames('btn btn-primary', {
                          'cursor-progress loading': saving,
                          'btn-disabled': formData?.uiOptions.autoSave,
                        })}
                        onClick={async () => {
                          // TODO error handle
                          await saveDashboard(formData);
                        }}
                      >
                        Save
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={classNames('flex flex-col space-y-3 pb-[90px]', {
        'p-6': !isPublic,
        'px-[100px]': isPublic,
      })}>
        <div className='flex hidden '>
          <div className='flex-auto flex items-center space-x-3 overflow-hidden'>
            <div className='flex-none'>
              <RiDashboardFill size='2.4rem' className='text-primary' />
            </div>
            <div className='flex flex-col w-full max-w-[48rem] items-start'>
              <div className='font-bold whitespace-nowrap'>
                {dashToRender?.displayName || dashToRender?.id}
                {isMyWorkspace && dashToRender?.access && (
                  <span className='self-center bg-base-content bg-opacity-10 py-[0.1rem] mx-2 px-1 text-[0.7rem] rounded-md opacity-50 font-semibold'>
                    {ACCESS_MAPPING[dashToRender.access]}
                  </span>
                )}
              </div>
              {dashToRender?.creator?.name && (
                <div className='flex items-center text-sm font-semibold gap-1'>
                  <ProfileAvatar
                    className='bg-[#263dff] w-4'
                    creator={dashToRender?.creator}
                  />
                  <div className='opacity-50'>
                    {dashToRender?.creator?.name}
                  </div>
                  <div className='opacity-50'>
                    •{' '}
                    {convertNumberToFormatted(dashToRender?.createdTime, {
                      formatStr: DateTimeFormat,
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

        </div>

        <div className='h-1' />

        <DashboardParams allParams={allParams} />

        <div className='h-full w-full'>
          {isEmpty(layouts) && (
            <div className='flex flex-col items-center justify-center min-h-[60vh] gap-5'>
              <span>This Dashboard is Empty.</span>
              {isEditView && (
                <div className='flex items-center gap-3'>{vizButtons}</div>
              )}
            </div>
          )}
          <ResponsiveGridLayout
            layouts={{ lg: layouts }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            margin={[15, 15]}
            rowHeight={dashToRender?.uiOptions?.rowHeight}
            containerPadding={[0, 0]}
            draggableCancel={'input,select'}
            useCSSTransforms={false}
            isDraggable={isEditView}
            isResizable={isEditView}
            onLayoutChange={(nLayout) => {
              const newFormData = produce(formData, (draft) => {
                if (draft?.uiOptions?.layouts) {
                  draft.uiOptions.layouts = nLayout;
                }
              });

              updateFormData(newFormData);
            }}
          >
            {layouts.map(({ i }) => {
              const chart = charts?.find((c) => c.id === i);
              if (chart) {
                return (
                  <div
                    key={i}
                    id={i}
                    className={classNames(
                      'flex flex-col space-y-3 p-3 rounded-2xl bg-base-100 shadow-md border overflow-none',
                      {
                        'cursor-grab': isEditView,
                      }
                    )}
                  >
                    <div className='flex items-center'>
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <Link
                            to={`/v2/build/${chart?.queryId
                              }?chartId=${chart?.id}&dashboardId=${dashboard?.id}&dashboardName=${dashboard?.displayName}`}
                          >
                            <div
                              className={classNames(
                                'font-bold w-full hover:underline',
                                {
                                  'opacity-40': isEditView,
                                }
                              )}
                            >
                              {chart?.displayName ||
                                chart?.displayName ||
                                chart?.id}
                            </div>
                          </Link>
                        </div>
                        {isEditView && (
                          <button
                            className={classNames(
                              'btn btn-sm btn-ghost rounded-md btn-primary'
                            )}
                            onClick={() => {
                              setConfigureChart(chart);
                            }}
                          >
                            <BsFillGearFill />
                          </button>
                        )}
                      </div>
                    </div>
                    <div
                      className={classNames('flex-1 h-0', {
                        'cursor-grab': isEditView,
                      })}
                      tabIndex={-1}
                    >
                      <StandaloneChart
                        standalone={false}
                        privateApi={!!auth}
                        chartId={i}
                        queryBasePath={`${getPath('queries')}`}
                        isDashboardOwner={isOwner}
                      />
                    </div>
                  </div>
                );
              }

              const widget = widgets[i];
              if (widget) {
                return (
                  <div
                    key={i}
                    id={i}
                    className={classNames(
                      'flex flex-col p-3 rounded-2xl bg-base-100 shadow-md',
                      {
                        'cursor-grab': isEditView,
                      }
                    )}
                  >
                    {isEditView && (
                      <div className='relative'>
                        <div className='absolute right-0'>
                          <button
                            className={classNames(
                              'btn btn-sm btn-ghost btn-primary'
                            )}
                            onClick={() => {
                              setConfigureWidget(widget);
                            }}
                          >
                            <BsFillGearFill />
                          </button>
                        </div>
                      </div>
                    )}

                    <div className='w-full min-w-full h-full overflow-auto prose z-markdown'>
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {widget.mdContent}
                      </ReactMarkdown>
                    </div>
                  </div>
                );
              }

              return null;
            })}
          </ResponsiveGridLayout>

          {
            // If the user is the owner of the dashboard, show the config modals
            // for charts and widgets
            renderDashboardConfigModal()
          }
        </div>
      </div>
    </div>
  );
}


//   <div className='flex-1'>
//     <div className='flex w-full items-center'>
//       <div className='flex-1 flex items-center'>
//         {isEditView ? (
//           <input
//             type='text'
//             className='input w-full text-xl font-bold'
//             placeholder='Name your dashboard'
//             value={dashToRender?.displayName}
//             onChange={(e) => {
//               updateFormData({
//                 ...formData,
//                 displayName: e.target.value || '',
//               });
//             }}
//           />
//         ) : (
//           null
//           // <H1>{dashToRender?.displayName || 'Unnamed dashboard'}</H1>
//         )}
//       </div>
//       <div className='w-4' />
//       <div>
//         {isOwner && isEditView ? (
//           <div className='flex space-x-2'>
//             <button
//               className={'btn btn-warning'}
//               onClick={handleSwitchToView}
//             >
//               {!autoSave ? 'Cancel' : 'Done'}
//             </button>
//             <NewMdWidgetButton
//               onCreate={(nWidget) => {
//                 const newFormData = produce(formData, (draft) => {
//                   if (!draft?.uiOptions?.layouts) {
//                     draft.uiOptions.layouts = [];
//                   }

//                   draft.uiOptions.layouts.push({
//                     i: nWidget.id,
//                     x: 0,
//                     y: 0,
//                     ...DefaultGirdItemConfig,
//                   });

//                   if (!draft?.uiOptions?.widgets) {
//                     draft.uiOptions.widgets = {};
//                   }

//                   draft.uiOptions.widgets[nWidget.id] = nWidget;
//                 });

//                 updateFormData(newFormData);
//               }}
//             />
//             <NewVizSelector
//               selectedViz={formData?.charts}
//               onChange={(nCharts) => {
//                 if (formData) {
//                   const newFormData = produce(formData, (draft) => {
//                     draft.charts = nCharts;
//                     const nWidgets = formData?.uiOptions?.widgets || {};

//                     if (!draft?.uiOptions?.layouts) {
//                       draft.uiOptions.layouts = [];
//                     }

//                     const existingLayouts = draft.uiOptions.layouts.filter(
//                       (layout) => {
//                         return (
//                           (layout && nWidgets[layout.i]) ||
//                           nCharts.find((chart) => chart.id === layout.i)
//                         );
//                       }
//                     );

//                     let counter = 0;
//                     const newLayouts = nCharts
//                       .filter((chart) => {
//                         return !draft.uiOptions.layouts?.find(
//                           (layout) => chart.id === layout.i
//                         );
//                       })
//                       .map((newChart) => {
//                         return {
//                           i: newChart.id,
//                           x: (counter++ % 2) * 6,
//                           y: 0,
//                           ...DefaultGirdItemConfig,
//                         };
//                       });

//                     draft.uiOptions.layouts = [
//                       ...existingLayouts,
//                       ...newLayouts,
//                     ];
//                   });

//                   updateFormData(newFormData);
//                 }
//               }}
//             />
//             <button
//               className={classNames('btn btn-primary', {
//                 'cursor-progress loading': saving,
//                 'btn-disabled': formData.uiOptions.autoSave,
//               })}
//               onClick={async () => {
//                 // TODO error handle
//                 await saveDashboard(formData);

//                 setIsEditView(false);
//               }}
//             >
//               Save
//             </button>

//             <div className='flex items-center'>
//               <input
//                 type='checkbox'
//                 className='toggle toggle-primary'
//                 checked={formData.uiOptions.autoSave}
//                 onChange={() => {
//                   const data = produce(formData, (draft) => {
//                     draft.uiOptions.autoSave = !formData.uiOptions.autoSave;
//                   });

//                   updateFormData(data);

//                   saveDashboard(data);
//                 }}
//               />
//               <div className='w-2' />
//               <span className='text'>Auto save</span>
//             </div>
//           </div>
//         ) : (
//           <div className='flex items-center space-x-2'>
//             <CopyToClipboard text={`${window.location.href}`}>
//               <div
//                 // className='btn btn-primary'
//                 onClick={() => {
//                   toast.success('Link copied', {
//                     autoClose: DefaultAutoDismissMs,
//                   });
//                 }}
//               >
//                 <ShareButton />
//               </div>
//             </CopyToClipboard>
//             {isOwner && (
//               // <DashboardDeleteButton
//               //   onDeleted={(success) => {
//               //     if (success) {
//               //       // redirec user to list
//               //       navigate(-1);
//               //     }
//               //   }}
//               // />
//               <DeleteButton />
//             )}
//             {isOwner && (
//               <EditButton showText={false} onClick={() => {
//                 setIsEditView(true);
//               }} />
//               // <button
//               //   className='btn btn-ghost opacity-50 hover:opacity-80'
//               //   onClick={() => {
//               //     setIsEditView(true);
//               //   }}
//               // >
//               //   <AiFillEdit size='1.5rem' />
//               // </button>
//             )}
//           </div>
//         )}
//       </div>
//     </div>


//   </div>
// </div>