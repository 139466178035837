import '../../css/react-grid-layout.css';
import '../../css/react-resizable.css';

import Modal from '../../components/modals/Modal';
import { ChartEntity } from '../chart/types';

export default function DashboardChartConfigModal({
  chart,
  onClose,
  onDelete,
}: {
  chart: ChartEntity;
  onClose: () => void;
  onDelete: (chart: ChartEntity) => void;
}) {
  return (
    <Modal open={!!chart.id}>
      <h3 className='font-bold text-lg'>Configure Visualization</h3>
      <div className='h-4' />
      <div className='pb-2'>{chart.displayName || chart.id}</div>
      <div className='modal-action items-center'>
        <div className='w-2' />
        <button type='button' className='btn btn-outline' onClick={onClose}>
          Cancel
        </button>
        <button
          type='button'
          className='btn btn-warning'
          onClick={() => {
            onDelete(chart);
          }}
        >
          Delete
        </button>
      </div>
    </Modal>
  );
}
