import { HTMLAttributes, memo, useState } from 'react';

const ShowDetail = memo(
  ({
    initialLabel,
    showMoreLabel,
    detail,
    ...props
  }: {
    initialLabel: string;
    showMoreLabel: string;
    detail: string;
  } & HTMLAttributes<HTMLDivElement>) => {
    const [open, setOpen] = useState(false);

    return (
      <div {...props}>
        {!open ? initialLabel : detail}{' '}
        {!open && (
          <span
            className='cursor-pointer opacity-50'
            onClick={() => {
              setOpen(true);
            }}
          >
            {showMoreLabel}
          </span>
        )}
      </div>
    );
  }
);

export default ShowDetail;
