/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useState } from "react";
import Logo from "../../components/app/Logo";
import Icon from "../components/Icon";
import classNames from "classnames";
import { Input, Radio, Space } from "antd";
import useAuth from "../../hooks/auth";
import { moveOnboardingWorkflowBackward, moveOnboardingWorkflowForward, startOnboardingWorkflow } from "./api";
import { ModelOnboardingResponse, ModelWorkflowStateDetail } from "../../api/__gen__/data-contracts";
import { useNavigate } from "react-router";
import LoadingIndicator from "../../components/LoadingIndicator";

const step1 = {
  "Business / Enterprise": {
    "selected": false
  },
  "Freelancer": {
    "selected": false
  },
  "Hobbyist": {
    "selected": false
  },
  "Others": {
    "content": "",
    "selected": false
  },
  "Startup": {
    "selected": false
  },
  "Student": {
    "selected": false
  }
}
const step3 = {
  "Analytics": {
    "selected": false
  },
  "Compliance and security": {
    "selected": false
  },
  "DeFi": {
    "selected": false
  },
  "Others": {
    "content": "",
    "selected": false
  },
  "Wallet": {
    "selected": false
  },
  "dApp": {
    "selected": false
  }
}

const step2 = {
  "Data Analyst": {
    "selected": false
  },
  "Data Scientist": {
    "selected": false
  },
  "Developer": {
    "selected": false
  },
  "Manager": {
    "selected": false
  },
  "Others": {
    "content": "",
    "selected": false
  }
}

const Survey = ({ currentStep, onNext, onBack, defaultValues
}: {
  currentStep: string,
  defaultValues?: any,
  onBack: (
    selected: string,
    content: string
  ) => void, onNext: (
    selected: string,
    content: string
  ) => void
}) => {
  const questions = [
    'What brings you to Zettablock?',
    'Describe yourself:',
    'What do you plan to use ZettaBlock for:'
  ]
  const [q1, setQ1] = useState<string | undefined>(undefined);
  const [q1Other, setQ1Other] = useState<string | undefined>(undefined);
  const [q2, setQ2] = useState<string | undefined>(undefined);
  const [q2Other, setQ2Other] = useState<string | undefined>(undefined);
  const [q3, setQ3] = useState<string | undefined>(undefined);
  const [q3Other, setQ3Other] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (defaultValues) {
      setQ1(defaultValues.q1)
      setQ1Other(defaultValues.q1Other)
      setQ2(defaultValues.q2)
      setQ2Other(defaultValues.q2Other)
      setQ3(defaultValues.q3)
      setQ3Other(defaultValues.q3Other)
    }
  }, [defaultValues])

  const onChange = (e) => {
    setQ1(e.target.value);
  };


  const onChange2 = (e) => {
    setQ2(e.target.value);
  };


  const onChange3 = (e) => {
    setQ3(e.target.value);
  };

  const renderQuestionTitle = () => {
    switch (currentStep) {
      case 'reason_to_choose_zettablock':
        return questions[0]
      case 'self_description':
        return questions[1]
      case 'goal_to_achieve':
        return questions[2]

      default:
        return null;
    }
  }
  const renderQuestion = () => {
    switch (currentStep) {
      case 'reason_to_choose_zettablock':
        return <Radio.Group onChange={onChange} value={q1}>
          <Space direction="vertical">
            <Radio value={'Hobbyist'}>Hobbyist</Radio>
            <Radio value={"Startup"}>Startup</Radio>
            <Radio value={'Freelancer'}>Freelancer</Radio>
            <Radio value={'Business / Enterprise'}>Business / Enterprise</Radio>
            <Radio value={'Student'}>Student</Radio>
            <Radio value={'Others'}>
              Others
            </Radio>
            {q1 === 'Others' ? (
              <Input
                value={q1Other}
                onChange={(e) => setQ1Other(e.target.value)}
                className="w-full ml-[26px]"
              />
            ) : null}
          </Space>
        </Radio.Group>
      case 'self_description':
        return <Radio.Group onChange={onChange2} value={q2}>
          <Space direction="vertical">
            <Radio value="Developer">Developer</Radio>
            <Radio value={'Data Scientist'}>Data Scientist</Radio>
            <Radio value="Data Analyst">Data Analyst</Radio>
            <Radio value='Manager'>Manager</Radio>
            <Radio value='Others'>
              Others
            </Radio>
            {q2 === 'Others' ? (
              <Input
                value={q2Other}
                onChange={(e) => setQ2Other(e.target.value)}
                className="w-full ml-[26px]"
              />
            ) : null}
          </Space>
        </Radio.Group>
      case 'goal_to_achieve':
        return <Radio.Group onChange={onChange3} value={q3}>
          <Space direction="vertical">
            <Radio value="dApp">dApp</Radio>
            <Radio value="DeFi">DeFi</Radio>
            <Radio value="Wallet">Wallet</Radio>
            <Radio value="Analytics">Analytics</Radio>
            <Radio value="Compliance and security">Compliance and security</Radio>
            <Radio value={'Others'}>
              Others
            </Radio>
            {q3 === 'Others' ? (
              <Input
                value={q3Other}
                onChange={(e) => setQ3Other(e.target.value)}
                className="w-full ml-[26px]"
              />
            ) : null}
          </Space>
        </Radio.Group>

      default:
        return null;
    }
  }

  const isNextDisabled = useMemo(() => {
    switch (currentStep) {
      case 'reason_to_choose_zettablock':
        return q1 === undefined || (q1 === 'Others' && q1Other === undefined);
      case 'self_description':
        return q2 === undefined || (q2 === 'Others' && q2Other === undefined);
      case 'goal_to_achieve':
        return q3 === undefined || (q3 === 'Others' && q3Other === undefined);

      default:
        return true;
    }
  }, [currentStep, q1, q2, q3])

  return <div className='bg-[#fff] mt-8  w-[480px] mx-auto rounded-[4px] border'>
    <div className="flex items-center gap-[10px] p-[20px] ">
      {
        [1, 2, 3].map(e => {
          const mapping = {
            'reason_to_choose_zettablock': 1,
            'self_description': 2,
            'goal_to_achieve': 3
          }
          return <div key={e} className={classNames(
            "h-[3px] w-[140px] rounded-[6px]",
            {
              'bg-[#263DFF]': mapping[currentStep] >= e,
              'bg-[#F2F5FB]': mapping[currentStep] < e
            }
          )}></div>
        })
      }
    </div>

    <div className="p-[20px] pt-0 text-[16px] font-semibold text-left">
      {renderQuestionTitle()}
    </div>

    <div className="border-t p-[40px]">
      {
        renderQuestion()
      }
    </div>

    <div className="flex gap-[20px] items-center p-[40px] pt-0">
      <button onClick={() => {
        if (currentStep === 'reason_to_choose_zettablock') {
          onBack(q1 || '', q1Other || '')
        }
        if (currentStep === 'self_description') {
          onBack(q2 || '', q2Other || '')
        }
        if (currentStep === 'goal_to_achieve') {
          onBack(q3 || '', q3Other || '')
        }
      }} className="flex items-center justify-center gap-[10px] border rounded-[4px] bg-[#fafafa] text-[#1A1A1A] text-[14px] w-full py-[15px] font-semibold leading-[14px]" >
        Back
      </button>
      <button disabled={isNextDisabled} onClick={() => {
        if (currentStep === 'reason_to_choose_zettablock') {
          onNext(q1 || '', q1Other || '')
        }
        if (currentStep === 'self_description') {
          onNext(q2 || '', q2Other || '')
        }
        if (currentStep === 'goal_to_achieve') {
          onNext(q3 || '', q3Other || '')
        }
      }} className={
        classNames("flex items-center justify-center gap-[10px] border rounded-[4px]  text-[#FFFFFF] text-[14px] w-full py-[15px] font-semibold leading-[14px]", {
          'bg-[#263DFF]': !isNextDisabled,
          'bg-[#263DFF33]': isNextDisabled
        })
      }>
        Next
      </button>
    </div>
  </div >

}

const getCurrentSelectedOption = (options) => {
  if (!options) return undefined;

  const keys = Object.keys(options);
  for (let i = 0; i < keys.length; i++) {
    if (options[keys[i]].selected) {
      return keys[i]
    }
  }
  return undefined;
}

const getDefaultValues = (currentState: any) => {
  const stateName = currentState?.state_name;
  if (stateName) {
    switch (stateName) {
      case 'introduction':
        return {}
      case 'self_description':
        return {
          q2: getCurrentSelectedOption(currentState?.state_detail?.options),
          q2Other: currentState?.state_detail?.options?.Others?.content,
        }
      case 'goal_to_achieve':
        return {
          q3: getCurrentSelectedOption(currentState?.state_detail?.options),
          q3Other: currentState?.state_detail?.options?.Others?.content,
        }
      case 'reason_to_choose_zettablock':
        return {
          q1: getCurrentSelectedOption(currentState?.state_detail?.options),
          q1Other: currentState?.state_detail?.options?.Others?.content,
        }
      default:
        break;
    }
  }

  return {}
}

const JoinDiscord = ({ onNext }: {
  onNext: () => void,
}) => {
  return <div className='bg-[#fff] mt-8 pb-10 w-[480px] mx-auto'>
    <div className='bg-[#FBFCFD] px-8 py-6 flex justify-between items-center'>
      <span className=' font-bold'>
        Join ZettaBlock community to
      </span>
    </div>
    <div className="p-[40px] text-[12px] text-[#777A80]">
      <Icon iconName="users" className="w-[80px] h-[80px] mx-auto mb-[40px]" />

      <p className="mb-4">
        Connect with other builders, and learn how to develop your dApps & APIs using ZettaBlock.
      </p>

      <p className="mb-4"> Get latest announcements including product updates, office hour, hackathons, tournaments ...
      </p>
      <p className="mb-4"> Get 24/7 support from the ZettaBlock team and community.
      </p>
      <p > Propose and discuss ideas to make ZettaBlock better!
      </p>

    </div>
    <div className="px-[40px] pb-[40px]">
      <a className="flex items-center justify-center gap-[10px] border rounded-[4px] bg-[#fafafa] text-[#1A1A1A] text-[14px] w-full py-[12px] font-semibold leading-[14px]" href="https://discord.gg/zettablock" rel="noreferrer" target="_blank">
        <Icon iconName="discord" /> Join Discord
      </a>
    </div>
    <div className="px-[40px]">
      <button onClick={onNext} className="flex items-center justify-center gap-[10px] border rounded-[4px] bg-[#263DFF] text-[#FFFFFF] text-[14px] w-full py-[15px] font-semibold leading-[14px]" >
        Next
      </button>
    </div>
  </div>
}



const Questionnaire = () => {
  const navigate = useNavigate()
  const [{ auth }] = useAuth();
  const [defaultValues, setDetaulValues] = useState<any>({})
  const [loading, setLoading] = useState(false)

  const [currentWorkflowState, setCurrentWorkflowState] = useState<ModelOnboardingResponse | undefined>()

  // first check the current state
  useEffect(() => {
    const fetchAndSetCurrentWorkflowState = async () => {
      setLoading(true)
      if (auth && (auth.subscription as any).onboardingCompleted) {
        navigate('/v2/explore/projects')
      }
      if (auth && auth.tenant && auth.email && auth.id) {
        const res = await startOnboardingWorkflow(auth.tenant, auth.email, auth.id)
        if (res && res.data.user_email && res.data.user_id && res.data.tenant && res.data.state_id) {
          setCurrentWorkflowState(res.data)
          setDetaulValues(getDefaultValues(res?.data))
        }

        if ((res?.data as any)?.state_name === 'help_guide') {
          navigate(`/v2/explore/projects?runTour=true&state_id=${res?.data.state_id}`)
        }
      }
      setLoading(false)
    }
    setTimeout(() => {
      fetchAndSetCurrentWorkflowState()
    }, 0);

  }, [auth])

  const renderSteps = () => {
    if (loading) {
      return <div className="flex items-center justify-center h-[300px]">
        <LoadingIndicator />
      </div>
    }

    const stateId = currentWorkflowState?.state_id;
    const stateName = (currentWorkflowState as any)?.state_name;
    if (!stateId || !stateName || !auth) {
      return null;
    }


    switch (stateName) {
      case 'introduction':
        return <JoinDiscord onNext={async () => {
          const res = await moveOnboardingWorkflowForward(stateId, currentWorkflowState as ModelWorkflowStateDetail, auth.tenant, auth.email, auth.id)
          setCurrentWorkflowState((res?.data as any))
          setDetaulValues(getDefaultValues(res?.data))
        }} />
      case 'self_description':
        return <Survey
          defaultValues={defaultValues}
          onBack={async (selected: string, content: string) => {
            const options = JSON.parse(JSON.stringify(step2))
            if (options[selected]) {
              options[selected].selected = true;
              if (selected === 'Others') {
                // update the state
                options[selected].content = content;
              }
            }
            const res = await moveOnboardingWorkflowBackward(stateId, { ...currentWorkflowState.state_detail, options } as any, auth.tenant, auth.email, auth.id)
            setCurrentWorkflowState((res?.data as any))
            setDetaulValues(getDefaultValues(res?.data))
          }}
          onNext={async (selected: string, content: string) => {
            const options = JSON.parse(JSON.stringify(step2))
            if (options[selected]) {
              options[selected].selected = true;
              if (selected === 'Others') {
                // update the state
                options[selected].content = content;
              }
            }
            const res = await moveOnboardingWorkflowForward(stateId, { ...currentWorkflowState.state_detail, options } as any, auth.tenant, auth.email, auth.id)
            setCurrentWorkflowState((res?.data as any))
            setDetaulValues(getDefaultValues(res?.data))
          }}
          currentStep={stateName} />
      case 'goal_to_achieve':
        return <Survey
          defaultValues={defaultValues}
          onBack={async (selected: string, content: string) => {
            const options = JSON.parse(JSON.stringify(step3))
            if (options[selected]) {
              options[selected].selected = true;
              if (selected === 'Others') {
                // update the state
                options[selected].content = content;
              }
            }
            const res = await moveOnboardingWorkflowBackward(stateId, { ...currentWorkflowState.state_detail, options } as any, auth.tenant, auth.email, auth.id)
            setDetaulValues(getDefaultValues(res?.data))
            setCurrentWorkflowState((res?.data as any))
          }}
          onNext={async (selected: string, content: string) => {
            const options = JSON.parse(JSON.stringify(step3))
            if (options[selected]) {
              options[selected].selected = true;
              if (selected === 'Others') {
                // update the state
                options[selected].content = content;
              }
            }
            const res = await moveOnboardingWorkflowForward(stateId, { ...currentWorkflowState.state_detail, options } as any, auth.tenant, auth.email, auth.id)
            navigate(`/v2/explore/projects?runTour=true&state_id=${res?.data.state_id}`)
          }}
          currentStep={stateName} />
      case 'reason_to_choose_zettablock':
        // case 'assd':
        return <Survey
          defaultValues={defaultValues}
          onBack={async (selected: string, content: string) => {
            const options = JSON.parse(JSON.stringify(step1))
            if (options[selected]) {
              options[selected].selected = true;
              if (selected === 'Others') {
                // update the state
                options[selected].content = content;
              }
            }
            const res = await moveOnboardingWorkflowBackward(stateId, { ...currentWorkflowState.state_detail, options } as any, auth.tenant, auth.email, auth.id)
            setCurrentWorkflowState((res?.data as any))
            setDetaulValues(getDefaultValues(res?.data))
          }}
          onNext={async (selected: string, content: string) => {
            const options = JSON.parse(JSON.stringify(step1))
            if (options[selected]) {
              options[selected].selected = true;
              if (selected === 'Others') {
                // update the state
                options[selected].content = content;
              }
            }
            const res = await moveOnboardingWorkflowForward(stateId, { ...currentWorkflowState.state_detail, options } as any, auth.tenant, auth.email, auth.id)
            setCurrentWorkflowState((res?.data as any))
            setDetaulValues(getDefaultValues(res?.data))
          }}
          currentStep={stateName} />

      default:
        return null
    }
  }
  return <div className="p-[50px] w-full">
    <div className="mx-auto mb-[50px] flex justify-center">
      <Logo />
    </div>
    {renderSteps()}
  </div >
}
export default Questionnaire;