import { Responsive, WidthProvider } from 'react-grid-layout';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { BsFillGearFill } from 'react-icons/bs';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { isEmpty, isEqual, max } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { BiRocket, BiGitRepoForked } from 'react-icons/bi';
import {
  RiDashboardFill,
  RiSettings4Line,
  RiShareBoxFill,
} from 'react-icons/ri';
import produce from 'immer';
import { useRecoilState } from 'recoil';
import { AuthState, COMMUNITY } from '../auth/AuthState';

import '../../css/react-grid-layout.css';
import '../../css/react-resizable.css';
import {
  useDashboardEdit,
  useMyDashboard,
  useSaveDashboard,
  validateDashboardLayouts,
} from './DashboardViewPageState';

import StandaloneChart from '../chart/StandaloneChart';
import { ChartEntity } from '../chart/types';
import { DefaultAutoDismissMs } from '../../config/toast';
import PageLoading from '../../components/app/PageLoading';
import { getParamsForAllCharts } from './utils/charts';
import DashboardParams from './components/DashboardParams';
import usePrompt from '../../hooks/router';
import { DefaultGirdItemConfigV3, MdWidgetType } from './dashboard-models';
import DashboardWidgetConfigModal from './DashboardWidgetConfigModal';
import DashboardChartConfigModal from './DashboardChartConfigModal';
import useAuth from '../../hooks/auth';
import { convertNumberToFormatted, DateTimeFormat } from '../../utils/date';
import ProfileAvatar from '../auth/ProfileAvatar';
import NewMdWidgetButtonV3 from './NewMdWidgetButtonV3';
import NewVizSelectorV3 from './NewVizSelectorV3';
import DashSettingModal from './DashSettingModal';
import DashUnpublishModal from './DashUnpublishModal';
import DashPublishModal from './DashPublishModal';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import DashboardDeleteButtonV3 from './components/DashboardDeleteModalV3';
import StarButton from '../api/components/StarButton';
import { ACCESS_MAPPING } from '../api/const';
import useTitle from '../../hooks/useTitle';
import { analytics } from '../../App';

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function DashboardViewPageV3() {
  /**
   * If we want to remove the resize handle icon
   * see https://github.com/react-grid-layout/react-grid-layout/issues/839
   */
  const [{ menu, isMyWorkspace }, { getPath, navigate }] = useWorkspace();
  const { dashboardId } = useParams<{ dashboardId: string }>();
  const [searchParams] = useSearchParams();
  const [auth] = useRecoilState(AuthState);

  // latest dashboard fetched from server
  const [dashboard, loading] = useMyDashboard(!!auth);

  const [formData, { updateFormData, reset }] = useDashboardEdit(dashboard);
  const [saving, { saveDashboard, deboundedSaveDashboard }] =
    useSaveDashboard();
  const autoSave = formData?.uiOptions.autoSave;

  const [, { isOwner }] = useAuth(dashboard);
  const [isEditMode, setIsEditMode] = useState(searchParams.get('editMode') === 'true');
  const allowEdit = isMyWorkspace && isOwner;
  const isEditView = allowEdit && isEditMode;

  useTitle('Dashboard', dashboard?.displayName || 'New Dashboard');

  // auto save
  useEffect(() => {
    if (isEditView && autoSave && dashboard && formData) {
      // prevent initial call
      if (!isEqual(formData, dashboard)) {
        if (formData?.uiOptions.autoSave) {
          deboundedSaveDashboard(formData);
        }
      }
    }
  }, [deboundedSaveDashboard, formData]);

  const dashToRender = isEditView ? formData : dashboard;

  const layouts = dashToRender?.uiOptions?.layouts || [];
  const charts = dashToRender?.charts || [];
  const widgets = dashToRender?.uiOptions?.widgets || {};

  const [configureChart, setConfigureChart] = useState<ChartEntity>();
  const [configureWidget, setConfigureWidget] = useState<MdWidgetType>();

  const changed = !isEqual(dashboard, formData);

  usePrompt('Discard changes?', !autoSave && isEditView && changed);

  const allParams = useMemo(() => getParamsForAllCharts(charts), [charts]);

  const [openSetting, setOpenSetting] = useState(false);
  const [openPublish, setOpenPublish] = useState(false);

  if (loading && !dashToRender) {
    return <PageLoading className='bg-transparent' />;
  }

  // @ts-ignore
  const published = dashToRender?.access === 'public';

  const vizButtons = (
    <>
      <NewVizSelectorV3
        selectedViz={formData?.charts}
        onChange={(nCharts) => {
          if (formData) {
            const newFormData = produce(formData, (draft) => {
              draft.charts = nCharts;
              const nWidgets = formData?.uiOptions?.widgets || {};

              if (!draft?.uiOptions?.layouts) {
                draft.uiOptions.layouts = [];
              }

              const nLayouts = draft.uiOptions.layouts || [];

              const existingLayouts = nLayouts.filter((layout) => {
                return (
                  (layout && nWidgets[layout.i]) ||
                  nCharts.find((chart) => chart.id === layout.i)
                );
              });

              let counter = 0;

              const nextX = (counter++ % 2) * 6;
              const currentY = max(nLayouts.map((l) => l.y)) || 0;
              const nextY = currentY
                ? currentY + DefaultGirdItemConfigV3.h
                : currentY;

              const newLayouts = nCharts
                .filter((chart) => {
                  return !nLayouts?.find((layout) => chart.id === layout.i);
                })
                .map((newChart) => {
                  return {
                    i: newChart.id,
                    x: nextX,
                    y: nextY,
                    ...DefaultGirdItemConfigV3,
                  };
                });

              draft.uiOptions.layouts = [...existingLayouts, ...newLayouts];
            });

            updateFormData(newFormData);
          }
        }}
      />
      <NewMdWidgetButtonV3
        onCreate={(nWidget) => {
          const newFormData = produce(formData, (draft) => {
            if (!draft?.uiOptions?.layouts) {
              draft.uiOptions.layouts = [];
            }

            const nLayouts = draft.uiOptions.layouts;
            const currentY = max(nLayouts.map((l) => l.y)) || 0;
            const nextY = currentY
              ? currentY + DefaultGirdItemConfigV3.h
              : currentY;

            draft.uiOptions.layouts.push({
              i: nWidget.id,
              x: 0,
              y: nextY,
              ...DefaultGirdItemConfigV3,
            });

            if (!draft?.uiOptions?.widgets) {
              draft.uiOptions.widgets = {};
            }

            draft.uiOptions.widgets[nWidget.id] = nWidget;
          });

          updateFormData(newFormData);
        }}
      />
    </>
  );

  const isEmptyDash = isEmpty(layouts);

  const renderDashboardWidgetConfigModal = () => {
    return configureWidget && (
      <DashboardWidgetConfigModal
        widget={configureWidget}
        onClose={() => {
          setConfigureWidget(undefined);
        }}
        onApply={(widget) => {
          // update
          const newFormData = validateDashboardLayouts(
            produce(formData, (draft) => {
              if (draft?.uiOptions?.widgets && widget) {
                draft.uiOptions.widgets[widget.id] = widget;
              }
            })
          );

          updateFormData(newFormData);
          setConfigureWidget(undefined);
        }}
        onDelete={(widget) => {
          const newFormData = validateDashboardLayouts(
            produce(formData, (draft) => {
              if (draft?.uiOptions?.widgets && widget) {
                delete draft?.uiOptions?.widgets[widget.id];
              }
            })
          );

          updateFormData(newFormData);
          setConfigureWidget(undefined);
        }}
      />
    )
  }

  const renderDashboardChartConfigModal = () => {
    return configureChart && (
      <DashboardChartConfigModal
        chart={configureChart}
        onClose={() => {
          setConfigureChart(undefined);
        }}
        onDelete={(chart) => {
          if (formData) {
            const newFormData = validateDashboardLayouts(
              produce(formData, (draft) => {
                draft.charts = draft.charts.filter(
                  (item) => item.id !== chart?.id
                );
              })
            );

            updateFormData(newFormData);
          }
          setConfigureChart(undefined);
        }}
      />
    )
  }

  const renderDashboardConfigModal = () => {
    if (!isMyWorkspace) return null

    return <>
      {renderDashboardWidgetConfigModal()}
      {renderDashboardChartConfigModal()}
    </>
  }

  return (
    <>
      <Helmet>
        <title>{dashToRender?.displayName}</title>
      </Helmet>

      {isEditView && !isEmptyDash && (
        <div className='fixed bottom-0 z-40 w-full'>
          <div className='w-[1200px] pb-8 px-8 flex justify-center mx-[auto]'>
            <div className='bg-white rounded-xl z-40 p-3 shadow-lg w-full'>
              <div className='flex items-center justify-between'>
                <div className='flex-auto flex items-center gap-3'>
                  <RiDashboardFill size='1.4rem' className='text-primary' />
                  {vizButtons}
                </div>
                <div className='flex items-center flex-none gap-3'>
                  <div className='flex items-center gap-2'>
                    <label
                      className='opacity-70 font-bold cursor-pointer'
                      htmlFor='auto-save-switch'
                    >
                      Auto save
                    </label>
                    <input
                      type='checkbox'
                      id='auto-save-switch'
                      className='toggle toggle-primary'
                      checked={formData?.uiOptions?.autoSave || false}
                      onChange={() => {
                        const data = produce(formData, (draft) => {
                          draft.uiOptions.autoSave =
                            !formData?.uiOptions.autoSave;
                        });

                        updateFormData(data);

                        saveDashboard(data);
                      }}
                    />
                  </div>

                  {!autoSave && (
                    <>
                      <button
                        className={'btn btn-outline'}
                        onClick={() => {
                          reset();
                          navigate(-1);
                        }}
                      >
                        {!autoSave ? 'Cancel' : 'Done'}
                      </button>

                      <button
                        className={classNames('btn btn-primary', {
                          'cursor-progress loading': saving,
                          'btn-disabled': formData?.uiOptions.autoSave,
                        })}
                        onClick={async () => {
                          // TODO error handle
                          await saveDashboard(formData);
                        }}
                      >
                        Save
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className='flex flex-col space-y-3 pb-[90px]'>
        <div className='flex'>
          <div className='flex-auto flex items-center space-x-3 overflow-hidden'>
            <div className='flex-none'>
              <RiDashboardFill size='2.4rem' className='text-primary' />
            </div>
            <div className='flex flex-col w-full max-w-[48rem] items-start'>
              <div className='font-bold whitespace-nowrap'>
                {dashToRender?.displayName || dashToRender?.id}
                {isMyWorkspace && dashToRender?.access && (
                  <span className='self-center bg-base-content bg-opacity-10 py-[0.1rem] mx-2 px-1 text-[0.7rem] rounded-md opacity-50 font-semibold'>
                    {ACCESS_MAPPING[dashToRender.access]}
                  </span>
                )}
              </div>
              {dashToRender?.creator?.name && (
                <div className='flex items-center text-sm font-semibold gap-1'>
                  <ProfileAvatar
                    className='bg-primary w-4'
                    creator={dashToRender?.creator}
                  />
                  <div className='opacity-50'>
                    {dashToRender?.creator?.name}
                  </div>
                  <div className='opacity-50'>
                    •{' '}
                    {convertNumberToFormatted(dashToRender?.createdTime, {
                      formatStr: DateTimeFormat,
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='flex items-center flex-none'>
            <div className='flex space-x-3'>
              {dashboardId && (
                <>
                  {isEditView && (
                    <>
                      <button
                        className='btn btn-primary gap-2'
                        onClick={() => {
                          setOpenSetting(!openSetting);
                        }}
                      >
                        <RiSettings4Line size='1.1rem' />
                        Setting
                      </button>
                      {openSetting && (
                        <DashSettingModal
                          openSetting={openSetting}
                          setOpenSetting={setOpenSetting}
                        />
                      )}

                      {published ? (
                        <div
                          className={classNames({
                            'tooltip tooltip-warning opacity-90': auth?.tenant === COMMUNITY,
                          })}
                          data-tip='Please upgrade the plan to make dashboard private'
                        >
                          <button
                            className='published-button btn btn-primary gap-2 hover:btn-error font-bold'
                            disabled={auth?.tenant === COMMUNITY}
                            onClick={() => {
                              setOpenPublish(!openPublish);
                            }}
                          >
                            <BiRocket size='1.1rem' />
                            {auth?.tenant === COMMUNITY ? (
                              <span>Unpublish</span>
                            ) : (
                              <>
                                <span className='when-not-hover'>
                                  Published
                                </span>
                                <span className='when-hover'>Unpublish</span>
                              </>
                            )}
                          </button>
                          {openPublish && (
                            <DashUnpublishModal
                              openPublish={openPublish}
                              setOpenPublish={setOpenPublish}
                            />
                          )}
                        </div>
                      ) : (
                        <>
                          <button
                            className='btn btn-primary gap-2'
                            onClick={() => {
                              setOpenPublish(!openPublish);
                            }}
                          >
                            <BiRocket size='1.1rem' />
                            <span>Publish</span>
                          </button>
                          {openPublish && (
                            <DashPublishModal
                              openPublish={openPublish}
                              setOpenPublish={setOpenPublish}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}

                  {/* Navigate from dashboard Editing from community dashboard */}
                  {!isMyWorkspace && isOwner && (
                    <>
                      <button
                        className='btn btn-primary gap-2'
                        onClick={() => {
                          navigate(`/my-workspace/${menu}/${dashboardId}?editMode=true`);
                        }}
                      >
                        Edit
                      </button>
                      <div className='border-r my-[0.2rem] border-gray-300' />
                    </>
                  )}

                  {/* Show Edit button if is not in Edit mode */}
                  {
                    allowEdit && <>
                      <button className='btn btn-primary gap-2' onClick={() => {
                        setIsEditMode(prevMode => !prevMode)
                      }}>
                        {
                          isEditMode ? 'Preview' : 'Edit'
                        }
                      </button>
                      <div className='border-r my-[0.2rem] border-gray-300' />
                    </>
                  }

                  {isEditView && (
                    <>
                      <DashboardDeleteButtonV3
                        onDeleted={(success) => {
                          if (success) {
                            // redirec user to list
                            navigate(getPath('dashboards'));
                          }
                        }}
                      />
                      <div className='border-r my-[0.2rem] border-gray-300' />
                    </>
                  )}

                  <span className='hidden'>
                    <button
                      className={classNames(
                        'flex btn btn-primary items-center gap-2',
                        {
                          hidden: !dashboardId,
                          // loading: isCloning,
                        }
                      )}
                      onClick={() => {
                        // clone();
                      }}
                    >
                      <BiGitRepoForked size='1.1rem' />
                    </button>
                  </span>

                  <StarButton
                    id={dashboardId}
                    isStarred={dashToRender?.star}
                    starCount={dashToRender?.starCount}
                    isListView={false}
                    page={'dashboard'}
                  />

                  <CopyToClipboard
                    text={`${window.origin}/community/dashboards/${dashboardId}`}
                  >
                    <button
                      className='flex btn btn-primary items-center gap-2'
                      onClick={() => {
                        toast.success('Share link copied', {
                          autoClose: DefaultAutoDismissMs,
                        });
                        analytics.track('Dashboard - Copy share link', {});
                      }}
                    >
                      <RiShareBoxFill size='1.1rem' />
                    </button>
                  </CopyToClipboard>
                </>
              )}
            </div>
          </div>
        </div>

        <div className='h-1' />

        <DashboardParams allParams={allParams} />

        <div className='h-full w-full'>
          {isEmpty(layouts) && (
            <div className='flex flex-col items-center justify-center min-h-[60vh] gap-5'>
              <span>This Dashboard is Empty.</span>
              {isEditView && (
                <div className='flex items-center gap-3'>{vizButtons}</div>
              )}
            </div>
          )}
          <ResponsiveGridLayout
            layouts={{ lg: layouts }}
            cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
            margin={[15, 15]}
            rowHeight={dashToRender?.uiOptions?.rowHeight}
            containerPadding={[0, 0]}
            draggableCancel={'input,select'}
            useCSSTransforms={false}
            isDraggable={isEditView}
            isResizable={isEditView}
            onLayoutChange={(nLayout) => {
              const newFormData = produce(formData, (draft) => {
                if (draft?.uiOptions?.layouts) {
                  draft.uiOptions.layouts = nLayout;
                }
              });

              updateFormData(newFormData);
            }}
          >
            {layouts.map(({ i }) => {
              const chart = charts?.find((c) => c.id === i);
              if (chart) {
                return (
                  <div
                    key={i}
                    id={i}
                    className={classNames(
                      'flex flex-col space-y-3 p-3 rounded-2xl bg-base-100 shadow-md',
                      {
                        'cursor-grab': isEditView,
                      }
                    )}
                  >
                    <div className='flex items-center'>
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <Link
                            to={`${getPath('queries')}/${chart?.queryId
                              }?chartId=${chart?.id}&dashboardId=${dashboard?.id}&dashboardName=${dashboard?.displayName}`}
                          >
                            <div
                              className={classNames(
                                'font-bold w-full hover:underline',
                                {
                                  'opacity-40': isEditView,
                                }
                              )}
                            >
                              {chart?.displayName ||
                                chart?.displayName ||
                                chart?.id}
                            </div>
                          </Link>
                        </div>
                        {isEditView && (
                          <button
                            className={classNames(
                              'btn btn-sm btn-ghost rounded-md btn-primary'
                            )}
                            onClick={() => {
                              setConfigureChart(chart);
                            }}
                          >
                            <BsFillGearFill />
                          </button>
                        )}
                      </div>
                    </div>
                    <div
                      className={classNames('flex-1 h-0', {
                        'cursor-grab': isEditView,
                      })}
                      tabIndex={-1}
                    >
                      <StandaloneChart
                        standalone={false}
                        privateApi={!!auth}
                        chartId={i}
                        queryBasePath={`${getPath('queries')}`}
                        isDashboardOwner={isOwner}
                      />
                    </div>
                  </div>
                );
              }

              const widget = widgets[i];
              if (widget) {
                return (
                  <div
                    key={i}
                    id={i}
                    className={classNames(
                      'flex flex-col p-3 rounded-2xl bg-base-100 shadow-md',
                      {
                        'cursor-grab': isEditView,
                      }
                    )}
                  >
                    {isEditView && (
                      <div className='relative'>
                        <div className='absolute right-0'>
                          <button
                            className={classNames(
                              'btn btn-sm btn-ghost btn-primary'
                            )}
                            onClick={() => {
                              setConfigureWidget(widget);
                            }}
                          >
                            <BsFillGearFill />
                          </button>
                        </div>
                      </div>
                    )}

                    <div className='w-full min-w-full h-full overflow-auto prose z-markdown'>
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {widget.mdContent}
                      </ReactMarkdown>
                    </div>
                  </div>
                );
              }

              return null;
            })}
          </ResponsiveGridLayout>

          {
            // If the user is the owner of the dashboard, show the config modals
            // for charts and widgets
            renderDashboardConfigModal()
          }
        </div>
      </div>
    </>
  );
}
