/* eslint-disable @typescript-eslint/no-explicit-any */
import { sql } from '@codemirror/lang-sql';
import ReactCodeMirror from '@uiw/react-codemirror';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { MyApi, StreamApi } from '../../api/client';
import { ModelQueryrunSummary } from '../../api/__gen__/data-contracts';
import ChartDisplayName from '../chart/components/ChartDisplayName';
import TableChartV3 from '../chart/components/TableChartV3';
import { TableChartEntity } from '../chart/types';
import { queryResultFormat } from '../queryBuilder/api-utils';
import { STATUS } from './QueryHistoryListPage';
import { zToast } from '../toast/toast';
import { codemirrorTheme } from '../../config/theme';

const QueryRunHistory = () => {
  const { queryRunId } = useParams();
  const location = useLocation();

  const editor = useRef();

  const [results, setResults] = useState<any>(null);
  const [item, setItem] = useState(location.state);

  useEffect(() => {
    const client: any = StreamApi();
    const QueryParamsForGetResults = {
      includeMetadata: true,
      includeColumnName: true,
    };

    const getResult = async () => {
      const resultResp = await client.queryrunsResultDetail(
        queryRunId,
        QueryParamsForGetResults
      );

      const formattedResult = await queryResultFormat('queryId', resultResp);
      if (formattedResult.error) {
        zToast.error(formattedResult.error);
        return;
      }

      setResults(formattedResult.results);
    };

    const getQueryHistoryList = async () => {
      if (!location.state) {
        const clientApi = MyApi();
        const res = await clientApi.queryrunsList();
        const items: ModelQueryrunSummary[] = res.data?.items || [];
        const selectedItem = items.find((e) => {
          return e.id === queryRunId;
        });
        setItem(selectedItem);
      }
    };
    getQueryHistoryList();

    getResult();
  }, [queryRunId]);

  if (!item) return null;

  return (
    <>
      <div className='mt-4 mb-3 flex font-bold items-center overflow-hidden overflow-ellipsis whitespace-nowrap'>
        {(item as any).queryName}
        <span
          className={classNames(
            'ml-2 badge badge-outline',
            STATUS[(item as any).state]?.className || STATUS.UNKNOWN.className
          )}
        >
          {STATUS[(item as any).state]?.label || STATUS.UNKNOWN.label}
        </span>
      </div>

      <ReactCodeMirror
        theme={codemirrorTheme}
        extensions={[sql()]}
        value={(item as any).text}
      />
      <div
        key='editor'
        className={classNames('flex-1 h-full', {
          border: true,
        })}
        ref={editor as unknown as React.RefObject<HTMLDivElement>}
      />
      {/* Result */}
      {(item as any).state === 'SUCCEEDED' ? (
        <div>
          <ChartDisplayName className='mt-4 overflow-hidden overflow-ellipsis whitespace-nowrap'>
            Query Results
          </ChartDisplayName>
          {results && (
            <TableChartV3
              chart={{} as TableChartEntity}
              queryResults={results}
            />
          )}
        </div>
      ) : (
        <div className='mt-4 alert bg-[#fdf3f1] border-[#db5d45] border text-sm font-semibold'>
          <span
            className={classNames(
              'ml-2 badge badge-outline',
              STATUS[(item as any).state]?.className || STATUS.UNKNOWN.className
            )}
          >
            {STATUS[(item as any).state]?.label || STATUS.UNKNOWN.label}
          </span>
          {(item as any).errMsg ??
            'Something went wrong... Please reach out to support team.'}
        </div>
      )}
    </>
  );
};

export default QueryRunHistory;
