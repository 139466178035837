/* eslint-disable @typescript-eslint/no-unused-vars */
import ReactCodeMirror, { EditorView } from '@uiw/react-codemirror';
import { Button } from 'antd';
import { useContext } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { BiCopy } from 'react-icons/bi';
import { ApiProjectContext } from '../api-project-context';
import { fetchOpenapi } from '../../utils';
import { codemirrorTheme } from '../../../../config/theme';

const CodeEditor = ({
  language,
  codeSnippet,
  method,
  path,
}: {
  language?: string;
  codeSnippet?: string;
  method?: string;
  path?: string;
}) => {
  return (
    <div className='pb-3 min-h-[100px]'>
      <ReactCodeMirror
        theme={codemirrorTheme}
        extensions={[EditorView.lineWrapping]}
        value={codeSnippet || 'const a = 4;'}
        readOnly
      />
    </div>
  );
};

export default CodeEditor;
