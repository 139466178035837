import classNames from 'classnames';
import { pick } from 'lodash';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import LoadingIndicator from '../../components/LoadingIndicator';
import Modal from '../../components/modals/Modal';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import AddEntityButton from '../explorer/components/AddEntityButton';
import { DashboardFormData, DefaultDashboard } from './dashboard-models';
import { useCreateDashboard } from './NewDashboardHooks';
import { analytics } from '../../App';

export default function NewDashboardV3({
  onSuccess,
}: {
  onSuccess?: (dashboardId?: string) => void;
}) {
  const navigate = useNavigate();
  const [openCreate, setOpenCreate] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Partial<DashboardFormData>>({
    // TODO: figure out ts error fix
    // @ts-ignore
    defaultValues: DefaultDashboard,
  });

  const [createDashboard, { loading }] = useCreateDashboard();
  const [{ menu }, { getPath }] = useWorkspace();

  const handleClose = useCallback(() => {
    if (loading) return;

    setOpenCreate(false);
    reset();
  }, [setOpenCreate, reset]);

  return (
    <>
      <AddEntityButton
        title='Create Dashboard'
        onClick={() => {
          analytics.track('Dashboard - Create dashboard clicked');
          setOpenCreate(!openCreate);
        }}
      />
      <Modal open={openCreate} onClickBackdrop={handleClose}>
        <form
          className='mx-auto'
          onSubmit={handleSubmit(async (values) => {
            if (loading) return;

            const newDash = await createDashboard(
              pick(values, ['displayName', 'uiOptions', 'description'])
            );
            if (newDash?.id) {
              analytics.track('Dashboard - Dashboard Created', {
                values: pick(values, ['displayName', 'uiOptions'])
              })

              if (onSuccess) {
                onSuccess(newDash.id)
                handleClose();
                return;
              }

              navigate(`${getPath(menu)}/${newDash.id}`);
            }
          })}
        >
          <h3 className='font-bold text-lg'>New Dashboard</h3>
          <div className='h-4' />
          <div className='pb-2'>
            <div className='form-control w-full'>
              <label className='label'>
                <span className='label-text'>Dashboard Name</span>
              </label>
              <input
                type='text'
                className={classNames('input input-bordered w-full', {
                  'input-warning': errors.displayName?.type,
                })}
                {...register('displayName', {
                  required: true,
                  minLength: 2,
                  maxLength: 80,
                })}
              />
              <label className='label'>
                <span className='label-text-alt text-warning'>
                  {errors.displayName?.type}
                </span>
              </label>
            </div>
          </div>
          <div className='pb-2'>
            <div className='form-control w-full'>
              <label className='label'>
                <span className='label-text'>Description</span>
              </label>
              <input
                type='text'
                className='input input-bordered w-full'
                {...register('description')}
              />
            </div>
          </div>
          <div className='modal-action items-center'>
            {loading && <LoadingIndicator />}
            <div className='w-2' />
            <button
              type='button'
              className='btn btn-outline'
              onClick={handleClose}
            >
              Cancel
            </button>
            <button type='submit' className='btn btn-primary'>
              Create
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
}
