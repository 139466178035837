import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { atom, atomFamily, selectorFamily } from 'recoil';
import {
  DatabaseApi,
  PreviewApi,
  QueryApi,
  QueryRunsApi,
  StreamApi,
} from '../../api/client';
import {
  ModelGetQueryDetailResponse,
  ModelGetQueryrunDetailResponse,
  ModelSubmitQueryRequest,
  ModelSubmitQueryResponse,
} from '../../api/__gen__/data-contracts';
import { Queries } from '../../api/__gen__/Queries';
import { setRecoilState } from '../recoil/utils';
import { queryResultFormat } from './api-utils';
import { StaticTabType } from './components/TabButton';
import { PollingInterval } from './const';
import { zToast } from '../toast/toast';

export const AStaticTabType = atom<StaticTabType>({
  key: 'AStaticTabType',
  default: {
    label: 'results',
  },
});

export const APreviewTableTitle = atom<string | undefined>({
  key: 'APreviewTableTitle',
  default: 'Preview Data',
});

export const AFetchingPreview = atom<boolean | undefined>({
  key: 'AFetchingPreview',
  default: false,
});

export const ForkedQueryFromState = atomFamily<
  ModelGetQueryDetailResponse | null,
  string | undefined
>({
  key: 'ForkedQueryFromState',
  default: null,
});

export const AQueryRunStartAt = atom<Date | null>({
  key: 'AQueryRunStartAt',
  default: null,
});

export const AQueryRunMetrics = atom<{
  read: number;
  write: number;
  cu: number;
  executionMillis: number;
}>({
  key: 'AQueryRunMetrics',
  default: {
    read: 0,
    write: 0,
    cu: 0,
    executionMillis: 0,
  },
});

export const AQueryRunErrorMessage = atom<string>({
  key: 'AQueryRunErrorMessage',
  default: '',
});

export const QueryState = atom<string | undefined>({
  key: 'QueryState',
  default: '',
});

interface QueryRunStopper {
  cancelQuery: () => void;
}

export const QueryRunStopper = atom<QueryRunStopper | undefined>({
  key: 'QueryRunStopper',
  default: undefined,
});

export const TempQueryRunId = atom<string>({
  key: 'TempQueryRunId',
  default: '',
});

export const QueryDisplayNameState = atom<string | undefined>({
  key: 'QueryDisplayNameState',
  default: '',
});

export const CacheTTLSate = atom<number>({
  key: 'CacheTTLSate',
  default: 3 * 60 * 1000,
});

export const HighlightedTextState = atom<string | undefined>({
  key: 'HighlightedTextState',
  default: '',
});

/**
 * TODO: add more query attributes manaing in this state
 */
export const AQueryDetails = atomFamily<
  ModelGetQueryDetailResponse,
  string | undefined | null
>({
  key: 'AQueryDetails',
  default: {},
});

export type QueryId = string | null | undefined;
export interface Metadata {
  queryrunId?: string;
  columns: Column[];

  databaseId?: string;
}
export interface Column {
  name: string;
  type: string;
  width?: number | string;
  maxWidth?: number | string;
}
export type QueryResultResult = {
  resultReceivedAt?: Date;
  // eslint-disable-next-line
  metadata: Metadata;
  rows: Record<string, string | number>[];
  csvString?: string;
};
export type TQueryResult = {
  queryId?: string | null | undefined;
  error?: string | null;
  create?: ModelSubmitQueryResponse | null;
  results?: QueryResultResult;
} | null;

/**
 * Consolidated query result cahce for all queries in one map by id
 * This is for
 * 1. caching all query results and reuse it on next render
 * 2. sharing query result for the different chart that are built on same query
 */

export const AQueryResults = atomFamily<TQueryResult, QueryId>({
  key: 'AQueryResults',
  default: null,
});

export const SQueryResults = selectorFamily<TQueryResult, QueryId>({
  key: 'SQueryResults',
  set:
    (queryId: QueryId) =>
    ({ set }, nValue) =>
      set(AQueryResults(queryId), nValue),
  get:
    (queryId?: QueryId) =>
    ({ get }) =>
      get(AQueryResults(queryId)),
});

const pollingResults = async (
  id: string
  // eslint-disable-next-line
): Promise<ModelGetQueryrunDetailResponse> => {
  // polling happens here until you get ERROR or retry limit
  const client = QueryRunsApi();
  const resp = await client.statusDetail(
    id,
    // @ts-ignore
    { query: { includeMetadata: true, includeColumnName: true } }
  );

  return new Promise((resolve, reject) => {
    try {
      if (
        !resp ||
        resp.data.state === 'RUNNING' ||
        resp.data.state === 'QUEUED'
      ) {
        setTimeout(async () => {
          try {
            const respT = await pollingResults(id);
            resolve(respT);
          } catch (error) {
            reject(error);
          }
        }, PollingInterval);

        return;
      }

      resolve(resp.data);
    } catch (error) {
      // eslint-disable-next-line
      console.error('polling error', error);
      reject(error);
    }
  });
};
const QueryParamsForGetResults = {
  // TODO fix type when API updates
  // @ts-ignore
  includeMetadata: true,
  includeColumnName: true,
  // TODO remove this line before publishing
  // uncommend line below to test timeout
  // maxWaitMillis: 0,
};
export const cancelQuery = async (
  queryId: string,
  abortController: AbortController
) => {
  try {
    // cancel request
    abortController.abort();

    // stop query
    const client = QueryApi();
    if (queryId) {
      await client.stopCreate(queryId, {});
      toast.info('Query run stopped');
    }
  } catch (err) {
    toast.error('Something wrong with stop');
  }
};

export async function runQueryById(
  {
    queryId,
    paramsStr,
    resultCacheExpireMillis,
    databaseId,
  }: {
    paramsStr: string;
    queryId: string;
    resultCacheExpireMillis?: number;
    databaseId?: string;
  },
  apiClient?: Queries<unknown>
): Promise<TQueryResult> {
  try {
    // Note(jjin): We are adding an aborting logic here
    // in the future it should be: return a callback to cancel instead of updating the Recoil state
    const controller = new AbortController();
    setRecoilState(QueryRunStopper, {
      cancelQuery: () => {
        cancelQuery(queryId, controller);
      },
    });

    const client = apiClient || QueryApi();

    const resultResp = await client.executeCreate(
      queryId,
      QueryParamsForGetResults,
      {
        paramsStr,
        resultCacheExpireMillis,
        databaseId,
      },
      {
        signal: controller.signal,
      }
      // TODO fix type when API updates
      // @ts-ignore
    );

    const formattedResult = await queryResultFormat(queryId, resultResp);
    return formattedResult;
  } catch (error) {
    if (error instanceof AxiosError) {
      // handle timeout
      if (error?.response?.status === 302) {
        const queryRunId = error?.response.data.queryrunId;
        // start polling
        const result = await pollingResults(queryRunId);
        if (result) {
          // success

          const client = StreamApi();
          const resultResp = await client.queryrunsResultDetail(
            queryRunId, // TODO fix type when API updates
            // @ts-ignore
            QueryParamsForGetResults
          );
          // @ts-ignore
          const formattedResult = await queryResultFormat(queryId, resultResp);
          if (formattedResult.error) {
            zToast.error(formattedResult.error);
          }
          return formattedResult;
        }
      }

      // other errors than timeout error
      // eslint-disable-next-line
      console.error('running query by id error', error);

      const formattedResult = await queryResultFormat(queryId, error.response);
      return formattedResult;
    }
  }

  return null;
}

export async function createQuery({
  database,
  paramsStr,
  query,
  resultCacheExpireMillis,
  databaseId,
  ...params
}: {
  database?: string;
  databaseId?: string;
} & ModelSubmitQueryRequest): Promise<TQueryResult> {
  try {
    const client = DatabaseApi();
    if (!database) {
      return null;
    }

    const resp = await client.queriesCreate(database, {
      query,
      limit: 10,
      paramsStr,
      resultCacheExpireMillis,
      databaseId,
      ...params,
    } as ModelSubmitQueryRequest);

    const newQueryId = resp.data?.id;
    if (newQueryId) {
      const resultResp = await runQueryById({
        paramsStr: paramsStr || '',
        queryId: newQueryId,
        resultCacheExpireMillis,
        databaseId,
      });
      if (resultResp) {
        return {
          create: resp.data,
          ...resultResp,
        };
      }
    }

    return {
      create: resp.data,
    };
  } catch (error) {
    // eslint-disable-next-line
    console.error('running query error', error);

    if (error instanceof AxiosError) {
      return {
        error: `${error?.response?.data?.message}`,
      };
    }
  }

  return null;
}

export async function getPreviewData({
  source,
  database,
  table,
}: {
  source: string;
  database: string;
  table: string;
}): Promise<TQueryResult> {
  try {
    const client = PreviewApi();
    if (!database) {
      return null;
    }

    const resp = await client.databaseTablePreviewDetail(
      source,
      database,
      table
    );

    const formattedResult = await queryResultFormat('preview', resp);

    return formattedResult;
  } catch (error) {
    // eslint-disable-next-line
    console.error('running query error', error);

    if (error instanceof AxiosError) {
      return {
        error: `${error?.response?.data?.message}`,
      };
    }
  }

  return null;
}

export async function updateQueryText({
  queryId,
  query,
  paramsStr,
}: {
  queryId: string;
  query: string;
  paramsStr: string;
}) {
  try {
    const client = QueryApi();
    const resp = await client.textCreate(queryId, { query, paramsStr });

    return resp;
  } catch (error) {
    // eslint-disable-next-line
    console.error('updating query error', error);

    if (error instanceof AxiosError) {
      return {
        ...error.response,
        error: `${error?.response?.data?.message}`,
        data: `${error?.response?.data}`,
      };
    }
  }

  return null;
}

export async function loadQuery({ queryId }: { queryId: string }) {
  try {
    // NOTE new Date().toLocalString()
    if (queryId.match(/^\w{8}/)) {
      return { data: { text: '' } };
    }
    const client = QueryApi();
    const resp = await client.detailDetail(queryId);

    return resp;
  } catch (error) {
    // eslint-disable-next-line
    console.error('running query error', error);

    if (error instanceof AxiosError) {
      return {
        ...error.response,
        error: `${error?.response?.data?.message}`,
      };
    }
  }

  return { data: {} };
}

// https://datalego.atlassian.net/browse/DL-374
// this is for running highlighted text selected in query pad
// this will create a query but Bo asked UI to use it for now
export async function runQueryByText({
  database,
  paramsStr,
  query,
  queryId,
}: {
  database: string;
  paramsStr: string;
  queryId: string;
  query: string;
}): Promise<TQueryResult> {
  try {
    const client = DatabaseApi();
    if (!database) {
      return null;
    }

    // Note(jjin): We are adding an aborting logic here
    // in the future it should be: return a callback to cancel instead of updating the Recoil state
    const controller = new AbortController();
    setRecoilState(QueryRunStopper, {
      cancelQuery: () => {
        cancelQuery(queryId, controller);
      },
    });

    const resp = await client.executeQueryCreate(
      database,
      {
        query,
        paramsStr,
      },
      {
        // TODO fix type when API updates
        // @ts-ignore
        query: {
          includeMetadata: true,
          includeColumnName: true,
        },
        signal: controller.signal,
      }
    );

    const formattedResult = await queryResultFormat(queryId, resp);
    return formattedResult;
  } catch (error) {
    // eslint-disable-next-line
    console.error('running query by text error', error);

    if (error instanceof AxiosError) {
      const formattedResult = await queryResultFormat(queryId, error.response);

      return formattedResult;
    }
  }

  return null;
}

export async function makeCloneQuery({ queryId }: { queryId: string }) {
  try {
    const client = QueryApi();
    const resp = await client.cloneCreate(queryId, {
      retainOriginalDB: true,
    });

    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}
