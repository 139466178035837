import { AxiosError } from 'axios';

import { CommunityApi, MyApi } from '../../api/client';

export async function fetchMyQueries() {
  try {
    const client = MyApi();
    const resp = await client.queriesList();
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function fetchQueries() {
  try {
    const client = MyApi();
    const resp = await client.queriesList();
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function fetchCommunityQueries() {
  try {
    const client = CommunityApi();
    const resp = await client.queryList();
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}
