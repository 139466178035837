/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, HttpClient, RequestParams } from './http-client';

export class Stream<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description stream queryrun result to client in CSV format (default) or other formats
   *
   * @tags Data Lake APIs
   * @name QueryrunsResultDetail
   * @summary Stream queryrun result
   * @request GET:/stream/queryruns/{id}/result
   */
  queryrunsResultDetail = (
    id: string,
    query: { includeColumnName: boolean; includeMetadata: boolean },
    params: RequestParams = {}
  ) =>
    this.request<string, any>({
      path: `/stream/queryruns/${id}/result`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      ...params,
    });
}
