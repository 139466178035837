/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from "react"

import backBtnIcon from '../../workspace/api-project/components/back.svg'
import CopyToClipboard from "react-copy-to-clipboard"
import { toast } from "react-toastify"
import classNames from "classnames"
import { NavLink, Route, Routes, useNavigate, useParams, useSearchParams } from "react-router-dom"
import Overview from "./Overview"
import Playground from "../Playground"
import ViewApiLogs from "../../../modules/api/ViewApiLogs"
import envs from "../../../config/envs"
import { useEditApi } from "../../../modules/api/ViewApiHooks"
import Icon from "../Icon"
import useAuth from "../../../hooks/auth"
import { Popconfirm } from "antd"
import { Triggers } from "../../../modules/api/Triggers"
import { ApiView } from "../../../api/client"
import TriggerModal from "../../../modules/api/TriggerModal"
import { RiSettings4Line, RiShareBoxFill } from "react-icons/ri"
import ApiSettingModal from "../../../modules/api/ApiSettingModal"
import { COMMUNITY } from "../../../modules/auth/AuthState"
import { BiGitRepoForked, BiRocket } from "react-icons/bi"
import ApiUnpublishModal from "../../../modules/api/ApiUnpublishModal"
import ApiPublishModal from "../../../modules/api/ApiPublishModal"
import { useApiClone } from "../../../modules/api/ApiCloneHooks"
import StarButton from "../../../modules/api/components/StarButton"
import { DefaultAutoDismissMs } from "../../../config/toast"

enum TabType {
  OVERVIEW = 'overview',
  PLAYGROUND = 'playground',
  LOGS = 'logs',
  USAGE = 'usage',
  TRIGGERS = 'triggers'
}

export const Tabs = ({ isRealtimeApi, isOwner }: {
  isRealtimeApi?: boolean
  isOwner?: boolean
}) => {
  const { apiId } = useParams();
  const [searchParams] = useSearchParams()

  const getClassNames = ({ isActive }: any) => {
    return classNames(
      'text-[14px] leading-[14px] tab pb-[20px] h-auto px-[5px]',
      {
        'tab-active font-semibold border-[#fff] border-b': isActive,
      }
    );
  }
  // {/* Tabs - API, Query, Table, Dashboard, My Fav */}
  return <div role="tablist" className="tabs gap-[25px] mx-[25px]">
    <NavLink to={`/v2/workspace/graphql-apis/${apiId}/overview?from=${searchParams.get('from')}`} role="tab"
      className={getClassNames}
    >Overview</NavLink>
    <NavLink to={`/v2/workspace/graphql-apis/${apiId}/playground?from=${searchParams.get('from')}`} role="tab" className={getClassNames}>Playground</NavLink>
    <NavLink to={`/v2/workspace/graphql-apis/${apiId}/logs?from=${searchParams.get('from')}`} role="tab" className={getClassNames}>Logs</NavLink>
    {
      isRealtimeApi && isOwner && <NavLink to={`/v2/workspace/graphql-apis/${apiId}/triggers?from=${searchParams.get('from')}`} role="tab" className={getClassNames}>Triggers</NavLink>
    }
    {/* <NavLink to={`/v2/workspace/graphql-apis/${apiId}/usage`} role="tab" className={getClassNames}>Usage</NavLink> */}
  </div>
}

const URLDisplay = ({ url }: { url: string }) => {

  return <CopyToClipboard text={url}>
    <div className="min-w-[600px] w-[600px] p-[5px] border rounded bg-[#fafafa] flex items-center gap-[5px] cursor-pointer " onClick={() => {
      toast.success('Copied to clipboard', {
        autoClose: 2000,
        position: 'top-center',
      })
    }}>
      <div className="bg-[#e5e7fb] text-[#263dff] text-[10px] py-[4px] px-[10px] border-[#263dff] border rounded w-[40px] h-[20px] leading-[10px] font-bold">
        URL
      </div>
      <div className="text-[12px] text-[#1A1A1A] font-normal">
        {url}
      </div>
    </div>
  </CopyToClipboard >
}

const GqlApi = () => {

  const [currentTab, setCurrentTab] = useState<TabType>(TabType.OVERVIEW)
  const { apiId } = useParams();
  const [{ values }, { deleteApi }] = useEditApi();
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [data, setData] = useState<any>([])

  useEffect(() => {
    const fetchData = async () => {
      if (!apiId) return
      const client = ApiView()
      const res = await client.triggersDetail(apiId)

      setData(res.data.items || [])
    }

    fetchData()
  }, [apiId])

  const handleSave = (type: string) => {
    setTimeout(() => {
      navigate(`/v2/workspace/graphql-apis/${apiId}/triggers`)
      toast.success(`Trigger ${type} succeeded.`, {
        autoClose: 2000,
        position: 'top-center',
      })
      const fetchData = async () => {
        if (!apiId) return
        const client = ApiView()
        const res = await client.triggersDetail(apiId)

        setData(res.data.items || [])
      }

      fetchData()
    }, 2000);
  }

  const [openSetting, setOpenSetting] = useState(false);
  const [openPublish, setOpenPublish] = useState(false);
  const [clone, { isCloning }] = useApiClone({
    queryPath: '/v2/workspace/graphql-apis/',
  });
  const published = values.access === 'public';
  const paused = values.state === 'Paused'
  const [{ auth }] = useAuth()

  const isOwner = useMemo(() => {
    if (!auth || !values) return false

    return values.creator?.id === auth?.id
  }, [auth, values])

  const backToHome = useCallback(() => {
    switch (searchParams.get('from')) {
      case 'home':
        navigate('/v2/explore')
        break;
      case 'explore':
        navigate(`/v2/explore/apis`)
        break;
      case 'workspace':
        navigate(`/v2/workspace/apis`)
        break;
      case 'search':
        navigate(`/v2/search-results?keyword=${searchParams.get('keyword')}`)
        break;

      default:
        navigate('/v2/explore')
        break;
    }
  }, [searchParams, navigate])

  return <div className="min-w-[1240px]">
    <div className="h-[60px] border-b flex items-center justify-between">
      {/* Header */}
      <div className="h-[60px] flex items-center">
        <div className="cursor-pointer" onClick={backToHome}>
          <img src={backBtnIcon} className="w-[30px] h-[30px] ml-[30px] mr-[20px] mt-[18px] mb-[12px]" />
        </div>
        <div className="flex items-center mt-[26px] mb-[20px]">
          <div className="text-[14px] font-semibold">
            {values.displayName}
          </div>
          <div className="w-[1px] bg-[#F2F2F2] h-[20px] mx-[10px]"></div>
          {/* <div className="mx-[10px]">/</div> */}
          <div className="text-[12px] text-[#757D8D]">
            {values.tenant}
          </div>
        </div>
      </div>



    </div>
    {/* Tab Header */}
    <div className="border-b ">
      <div className="w-full flex justify-between">
        <div className="p-[30px] flex gap-[20px] min-w-[570px]">
          <div className="overflow-hidden ">
            <Icon
              className="w-[60px] h-[60px] rounded-[4px]"
              iconName="apiicon"
            />
          </div>
          <div className="h-full">
            <div className="items-center flex mb-[10px]">
              <div className="text-[20px] font-semibold leading-[24px]">
                {values.displayName}
              </div>
            </div>
            <URLDisplay url={`${envs.ZettaOpenApi}/dataset/${apiId}/graphql`} />
          </div>
        </div>
        <div className="p-[30px]">
          <div className='flex space-x-3'>
            {values.state !== 'Deleted' ? (
              apiId && (
                <>
                  {isOwner && (
                    <>
                      <button
                        className='btn btn-primary gap-2'
                        onClick={() => {
                          setOpenSetting(!openSetting);
                        }}
                      >
                        <RiSettings4Line size='1.1rem' />
                        Setting
                      </button>
                      {openSetting && (
                        <ApiSettingModal
                          openSetting={openSetting}
                          setOpenSetting={setOpenSetting}
                        />
                      )}

                      {
                        !envs.isCircleIntegrationEnabled && (
                          published ? (
                            <div
                              className={
                                classNames({
                                  'tooltip tooltip-warning opacity-90':
                                    auth?.tenant === COMMUNITY,
                                })}
                              data-tip='Please upgrade the plan to make userapi private'
                            >
                              <button
                                className='published-button btn btn-primary gap-2 hover:btn-error font-bold'
                                disabled={auth?.tenant === COMMUNITY}
                                onClick={() => {
                                  setOpenPublish(!openPublish);
                                }}
                              >
                                <BiRocket size='1.1rem' />
                                {auth?.tenant === COMMUNITY ? (
                                  <span>Unpublish</span>
                                ) : (
                                  <>
                                    <span className='when-not-hover'>
                                      Published
                                    </span>
                                    <span className='when-hover'>Unpublish</span>
                                  </>
                                )}
                              </button>
                              {openPublish && (
                                <ApiUnpublishModal
                                  openPublish={openPublish}
                                  setOpenPublish={setOpenPublish}
                                />
                              )}
                            </div>
                          ) : (
                            <>
                            <Popconfirm
                              title={<div className="w-[200px]">This API is paused and it will not return the up-to-date data until it finish a new round of sync. Are you sure you want to publish it?</div>}
                              onCancel={() => { }}
                              onConfirm={() => {
                                setOpenPublish(!openPublish);
                              }}
                              disabled={!paused}
                            >
                              <button
                                className='btn btn-primary gap-2'
                                onClick={paused ? undefined : () => {
                                  setOpenPublish(!openPublish);
                                }}
                              >
                                <BiRocket size='1.1rem' />
                                <span>Publish</span>
                              </button>
                              </Popconfirm>
                              {openPublish && (
                                <ApiPublishModal
                                  openPublish={openPublish}
                                  setOpenPublish={setOpenPublish}
                                />
                              )}
                            </>
                          ))
                      }
                      {
                        isOwner && <Popconfirm
                          title="Are you sure to delete this API?"
                          onCancel={() => { }}
                          onConfirm={async () => {
                            await deleteApi()
                            navigate('/v2/workspace/apis')
                          }}
                        >
                          <button
                            className='btn btn-warning'
                          >
                            <span>Delete</span>
                          </button>
                        </Popconfirm>
                      }

                      <div className='border-r my-[0.2rem] border-gray-300' />
                    </>
                  )}
                  <span className='hidden'>
                    <button
                      className={classNames(
                        'flex btn btn-primary items-center gap-2',
                        {
                          hidden: !apiId,
                          loading: isCloning,
                        }
                      )}
                      onClick={() => {
                        clone();
                      }}
                    >
                      <BiGitRepoForked size='1.1rem' />
                    </button>
                  </span>
                  <StarButton
                    id={apiId}
                    isStarred={values?.star}
                    starCount={values?.starCount}
                    isListView={false}
                    page={'api'}
                  />
                  <CopyToClipboard text={window.location.href}>
                    <button
                      className='flex btn btn-primary items-center gap-2'
                      onClick={() => {
                        toast.success('Share link copied', {
                          autoClose: DefaultAutoDismissMs,
                        });
                      }}
                    >
                      <RiShareBoxFill size='1.1rem' />
                    </button>
                  </CopyToClipboard>
                </>
              )
            ) : (
              <div
                className='badge-lg badge badge-outline cursor-pointer tooltip'
                data-tip='API cannot be queried once deleted. Also you can still view the deleted API in the API history under Usage portal.'
              >
                Deleted
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <Tabs isOwner={isOwner} isRealtimeApi={values.database?.startsWith('realtime')} />
      </div>
    </div>


    {/* Tab Content */}
    <Routes>
      <Route path="/overview" element={<Overview title="" />} />
      <Route path="/playground" element={<Playground url={`${envs.ZettaBackendApiPrivate}/userapis/${apiId}/graphql`} />} />
      <Route path="/logs" element={<ViewApiLogs />} />
      <Route path="/usage" element={<div>Usage</div>} />
      <Route path="/triggers" element={
        auth?.id === values.creator?.id ? <div className="px-[30px]">
          <Triggers
            baseUrl={`/v2/workspace/graphql-apis/${apiId}/`}
            data={data}
            apiId={apiId || ''}
          />
        </div> : null
      } />
      <Route path="/create-trigger" element={<div className="px-[30px]"><TriggerModal apiId={apiId} onSave={handleSave} /></div>} />
      <Route path="/edit-trigger/:triggerId" element={<div className="px-[30px]"><TriggerModal apiId={apiId} onSave={handleSave} /></div>} />

    </Routes>
  </div>
}

export default GqlApi