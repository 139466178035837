import classNames from 'classnames';
import { HTMLAttributes, memo, useEffect, useState } from 'react';
import { BsFillStarFill, BsStar } from 'react-icons/bs';
import { ReactionApi } from '../../../api/client';

type ResourceTypeMappingProps = {
  [key: string]: string;
};

export const RESOURCE_TYPE_MAPPING: ResourceTypeMappingProps = {
  api: 'userapis',
  query: 'queries',
  dashboard: 'dashboards',
};

function StarButton({
  id,
  starCount,
  isStarred,
  isListView = true,
  page,
}: {
  id: string | undefined;
  starCount: number | undefined;
  isStarred: boolean | undefined;
  isListView?: boolean;
  page: 'api' | 'query' | 'dashboard';
} & HTMLAttributes<HTMLButtonElement>) {
  const [starCountVal, setStarCount] = useState(0);
  const [starCountUpdstedVal, setStarCountUpdated] = useState(false);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (isStarred) {
      setSelected(true);
    }
  }, [isStarred]);

  return (
    <button
      className={classNames(
        'btn items-center gap-2',
        isListView
          ? 'btn-ghost no-animation flex-nowrap hover:!bg-transparent'
          : 'btn-primary'
      )}
      onClick={async () => {
        if (!id) {
          return;
        }
        const client = ReactionApi();
        const success = await client?.actionCreate(
          RESOURCE_TYPE_MAPPING[page],
          id,
          'star'
        );
        const starData = { ...success.data };
        if (starData) {
          setStarCount(Number(starData?.count || 0));
          setStarCountUpdated(true);
          if (starData.status === 'enabled') {
            setSelected(true);
          } else {
            setSelected(false);
          }
        }
      }}
    >
      {selected ? <BsFillStarFill size='1.1rem' /> : <BsStar size='1.1rem' />}
      {starCountUpdstedVal ? starCountVal : starCount || 0}
    </button>
  );
}

export default memo(StarButton);
