import CodeMirror from '@uiw/react-codemirror';
import CopyToClipboard from 'react-copy-to-clipboard';
import { markdown, markdownLanguage } from '@codemirror/lang-markdown';
import { languages } from '@codemirror/language-data';
import { EditorView } from '@codemirror/view';
import { IoCopyOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { codemirrorTheme } from '../../config/theme';

interface CodeEampleProps {
  title: string;
  code: string;
}

const test_code = `## Title

\`\`\`jsx
function Demo() {
  return <div>demo</div>
}
\`\`\`

\`\`\`bash
# Not dependent on uiw.
npm install @codemirror/lang-markdown --save
npm install @codemirror/language-data --save
\`\`\`

[weisit ulr](https://uiwjs.github.io/react-codemirror/)

\`\`\`go
package main
import "fmt"
func main() {
  fmt.Println("Hello, 世界")
}
\`\`\`
`;

const CodeExample = ({ title = '', code = '' }: CodeEampleProps) => {
  return (
    <div className='border rounded overflow-hidden'>
      {/* head section */}
      <div className='flex justify-between items-center bg-[#fafafa] py-2 px-3 '>
        <div className='font-semibold'>{title}</div>
        <div className='flex items-center'>
          <CopyToClipboard text={code}>
            <button
              onClick={() => {
                toast.success('Copied!', {
                  autoClose: 2000,
                  position: 'top-center',
                });
              }}
            >
              <IoCopyOutline />
            </button>
          </CopyToClipboard>
        </div>
      </div>
      {/* code section */}
      <div className=''>
        <CodeMirror
          width='320px'
          readOnly
          theme={codemirrorTheme}
          basicSetup={{
            lineNumbers: false,
          }}
          value={test_code}
          extensions={[
            EditorView.lineWrapping,
            markdown({ base: markdownLanguage, codeLanguages: languages }),
          ]}
        />
      </div>
    </div>
  );
};

export default CodeExample;
