/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */

import seachIcon from './search.svg'
import tableIcon from './table.svg'
import { CatalogContext, CatalogProvider } from './catalog-context'
import { useCallback, useContext } from "react";
import backIcon from './arrow-left.svg'
import addIcon from './arrow-right.svg'
import previewIcon from './preview.svg'
import LoadingIndicator from '../../../components/LoadingIndicator';
import { PreviewQueryResultId, useRunPreviewData } from '../../../modules/queryBuilder/QueryBuilderHooks';
import { useQueryPadInject } from '../../../modules/database/TableSchemaState';
import { tempFilterFn } from '../../../modules/queryBuilder/TableColumnsV3';
import { formatSql } from '../../../modules/queryBuilder/QueryPadState';
import { formatColumn } from '../../../modules/queryBuilder/utils/sql';
import { clientSqlDefaultLimit } from '../../../modules/dashboard/config';
import Icon from '../Icon';

const SearchBar = () => {
  const {
    setTableKeyword,
    category,
    table,
    setCategoryKeyword,
    setColumnKeyword,
  } = useContext(CatalogContext)
  if (category === '' && !table?.id) {
    return <div className='p-[15px] border-y flex gap-[10px] items-center box-border'>
      <img src={seachIcon} className='w-[16px] h-[16px]' />
      <input className='text-[#C2C6CC] text-[14px] leading-[14px] h-[14px] outline-none w-full' type="text" placeholder={"Search for dataset"} onChange={(e) => {
        setCategoryKeyword(e.target.value)
      }} />
    </div>
  }

  if (!table?.id) {
    return <div className='p-[15px] border-y flex gap-[10px] items-center box-border'>
      <img src={seachIcon} className='w-[16px] h-[16px]' />
      <input className='text-[#C2C6CC] text-[14px] leading-[14px] h-[14px] outline-none w-full' type="text" placeholder="Search for table" onChange={(e) => {
        setTableKeyword(e.target.value)
      }} />
    </div>
  }
  if (table.id) {
    return <div className='p-[15px] border-y flex gap-[10px] items-center box-border'>
      <img src={seachIcon} className='w-[16px] h-[16px]' />
      <input className='text-[#C2C6CC] text-[14px] leading-[14px] h-[14px] outline-none w-full' type="text" placeholder="Search for column" onChange={(e) => {
        setColumnKeyword(e.target.value)
      }} />
    </div>
  }

  return null
}


const CatagoryRow = ({
  label,
  image,
  description
}: {
  label: string
  description: string
  image: string
}) => {
  const { setCataegory } = useContext(CatalogContext)

  return <div
    onClick={() => setCataegory(label)}
    className={
      'flex gap-[10px] p-[10px] hover:bg-[#F2F2F2] cursor-pointer items-center box-border mx-[5px] rounded mb-[10px]'
    }>
    <img src={image} alt="" className='w-[40px] h-[40px] rounded-[6px]' />

    <div className='max-w-[206px]'>
      <div className='font-bold text-[14px] text-[#2B2F33]'>
        {label}
      </div>
      <div className='text-[12px] text-[#2D2F34]'>
        {description}
      </div>
    </div>
  </div>
}

const Catalog = () => {
  // State
  const { categories, category, table, setCataegory, isLoading, tables, setTable, tableDetail, setChain, chain,
    tableKeyword,
    categoryKeyword,
    columnKeyword,
    fetchAndSetTableDetail
  } = useContext(CatalogContext)
  const [runPreviewData] = useRunPreviewData(true);
  const injectKeyword = useQueryPadInject();
  const handleInject = useCallback(
    (value: string) => {
      injectKeyword(value);
    },
    [injectKeyword]
  );

  const handleFormat = useCallback(
    async (item: any) => {
      const tableDetails = await fetchAndSetTableDetail(item.id)
      if (item?.database) {
        injectKeyword(
          formatSql(
            `SELECT ${tempFilterFn(item?.name, item.database, tableDetails || [], item?.database?.startsWith('realtime'))
              ?.map((e) => formatColumn(e.name))
              .join(', ')}\n  FROM ${item.database}.${item?.name
            } limit ${clientSqlDefaultLimit}`,
            {
              language: item?.sqlDialect,
            }
          )
        );
      }
    },
    [injectKeyword, fetchAndSetTableDetail]
  );

  const renderContent = () => {
    if (category === '' && !table?.id) {
      return <>
        <div className='h-[50px] px-[15px] py-[18px]'>
          <div className='font-bold text-[14px] text-[#2B2F33]'>
            Browse datasets
          </div>
        </div>

        <div>
          {
            categories.filter(e => e.name.toLowerCase().includes(categoryKeyword.toLocaleLowerCase())).map((item: any) => {
              return <CatagoryRow key={item.name} label={item.name} description={item.description} image={item.image} />
            })
          }
        </div>
      </>
    }

    if (!table?.id) {
      const getTables = () => {
        return (Object.values(tables) as any[]).reduce((acc: any, e: any) => {
          return [...acc, ...e.filter((item: any) => {
            return item.labels?.some((label: string) => {
              if (chain) {
                return label.toLowerCase() === category.toLowerCase() && item.chains[0].toLowerCase() === chain.toLowerCase()
              }
              return label.toLowerCase() === category.toLowerCase()
            })
          })]
        }, [])
      }

      const getChains = () => {
        return Array.from(new Set((Object.values(tables) as any[]).reduce((acc: any, e: any) => {
          return [...acc, ...e]
        }, []).map((item: any) => {
          return item.chains?.[0]
        }))).filter(e => !!e)
      }

      return <>
        <div className='h-[50px] px-[15px] py-[18px] items-center flex gap-[5px]'>
          <div className='cursor-pointer' onClick={() => {
            setCataegory('')
            setChain('')
          }}>
            <img src={backIcon} className='w-[14px] h-[14px]' />
          </div>
          <div className='font-bold text-[14px] text-[#2B2F33] flex justify-between items-center w-full'>
            <div className='max-w-[180px] overflow-hidden'>
              {category}
            </div>
            <div>
              <select
                value={chain}
                onChange={(e) => {
                  setChain(e.target.value)
                }} className='outline-none max-w-[98px]'>
                <option value=''>
                  All Chains
                </option>
                {
                  getChains().map((item: any) => {
                    return <option value={item}>
                      {item}
                    </option>
                  })
                }
              </select>
            </div>
          </div>
        </div >

        {
          isLoading ? <LoadingIndicator /> : <div>
            {
              getTables().filter(e => {
                if (e.name.toLowerCase().includes(tableKeyword.toLocaleLowerCase())) return true
                if (e.chains[0].toLowerCase().includes(tableKeyword.toLocaleLowerCase())) return true
                return false
              }).map((item: any) => {
                return <div className='group mb-[5px] hover:bg-[#f2f2f2] mx-[5px] p-[10px] cursor-pointer rounded' onClick={() => {
                  setTable(item)
                }}>

                  <div className='flex justify-between items-center'>
                    <div className='flex gap-[5px] items-center mb-[8px]'>
                      <img src={tableIcon} className='w-[14px] h-[14px]' />
                      <span className='font-semibold text-[12px] max-w-[300px] inline-block truncate text-ellipsis overflow-hidden ' title={item.chains[0]}>
                        {item.chains[0]}
                      </span>
                      <Icon
                        className='w-[14px] h-[14px]'
                        iconName={item.chains[0]}
                      />
                    </div>
                    {/*  */}
                    <div className='hidden group-hover:flex gap-[10px] items-center'>
                      <img src={previewIcon} className='w-[12px] h-[12px] cursor-pointer' onClick={(e) => {
                        e.stopPropagation()

                        runPreviewData({
                          queryId: PreviewQueryResultId,
                          selectedDB: item.database,
                          schema: item?.database,
                          table: item?.name,
                        });
                      }} />
                      <img src={addIcon} className='w-[12px] h-[12px] cursor-pointer' onClick={(e) => {
                        e.stopPropagation()
                        handleInject(`${item?.database}.${item?.name}`);
                      }} />

                      <div onClick={(e) => {
                        e.stopPropagation()
                        handleFormat(item)
                      }} >
                        <Icon
                          iconName='plus'
                        />
                      </div>

                    </div>
                  </div>


                  <div className='text-[12px] pl-[19px] h-[12px] flex justify-between items-center'>
                    <span className='inline-block truncate text-ellipsis overflow-hidden text-[#2D2F34] text-[12px] leading-[12px]' title={item.name}>
                      {item.name}
                    </span>
                    {
                      item.type !== 'datalake' && <div className='mt-[8px] flex items-center gap-[2px] w-[60px] h-[12px] px-[3px] py-[1.5px] text-[9px] leading-[9px] bg-[#e8ebfd] text-[#263DFF] rounded-full'>
                        <Icon
                          className='w-[9px] h-[9px] cursor-pointer'
                          iconName='zflash'
                        />
                        Realtime
                      </div>
                    }
                  </div>
                </div>
              })
            }
          </div>
        }
      </>
    }

    if (table.id) {
      return <div>
        <div className='h-[50px] px-[15px] py-[18px] items-center flex gap-[5px]'>
          <div className='cursor-pointer' onClick={() => { setTable('') }}>
            <img src={backIcon} className='w-[14px] h-[14px]' />
          </div>
          <div className='font-bold text-[14px] text-[#2B2F33] flex-1'>
            <div className='flex justify-between items-center'>
              <div className='flex gap-[5px] items-center'>
                {/* <img src={tableIcon} className='w-[14px] h-[14px]' /> */}
                <span className='font-semibold text-[12px] max-w-[300px] inline-block truncate text-ellipsis overflow-hidden ' title={table.chains[0]}>
                  {table.name}
                </span>
                {/* <img src={tableIcon} className='w-[14px] h-[14px]' /> */}
              </div>
              <div className='flex gap-[10px] items-center'>
                <img src={previewIcon} className='w-[12px] h-[12px] cursor-pointer' onClick={(e) => {
                  e.stopPropagation()

                  runPreviewData({
                    queryId: PreviewQueryResultId,
                    selectedDB: table.database,
                    schema: table?.database,
                    table: table?.name,
                  });
                }} />
                <img src={addIcon} className='w-[12px] h-[12px] cursor-pointer' onClick={(e) => {
                  e.stopPropagation()
                  handleInject(`${table?.database}.${table?.name}`);
                }} />
              </div>
            </div>
          </div>
        </div >
        <div className='mt-[10px]'>
          {
            isLoading ? <LoadingIndicator /> : tableDetail.filter(e => e.name.toLowerCase().includes(columnKeyword.toLocaleLowerCase())).map((item: any) => {
              return <div className='mx-[10px] hover:bg-[#f2f2f2] flex items-center justify-between mb-[5px] px-[10px] py-[7px] rounded cursor-pointer' onClick={() => {
                handleInject(item.name)
              }}>
                <div className='text-[12px] text-[#2D2F34]'>
                  {item.name}
                </div>
                <div className='text-[10px] text-[777A80] p-[5px] rounded bg-[#f2f2f2] h-[20px] leading-[10px]'>
                  {item.type}
                </div>
              </div>
            })
          }

        </div>
      </div>
    }


    return null
  }




  return <div className="w-[300px] h-screen bg-[#fff] overflow-y-auto overflow-x-hidden">
    {/* Search Bar */}
    <SearchBar />

    {/* Content */}
    {renderContent()}

  </div>
}

const CatalogWithProvider = () => {
  return <CatalogProvider>
    <Catalog />
  </CatalogProvider>
}

export default CatalogWithProvider;
