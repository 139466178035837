import { useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';

import { ModelUser  } from '../api/__gen__/data-contracts';
import { BasicAuthUser } from '../layouts/types';
import { AuthState } from '../modules/auth/AuthState';

export default function useAuth<T extends { creator?: ModelUser  | null }>(
  entity?: T | null
) {
  const [{ auth: basicAuth }] = useCookies<'auth', { auth?: BasicAuthUser }>([
    'auth',
  ]);

  const [auth, setAuth] = useRecoilState(AuthState);

  const allowV3UI = true;

  const getIsOwner = (target?: T | null) => {
    if (
      // auth v2
      (auth?.id && auth.id === target?.creator?.id) ||
      // basic auth
      (basicAuth && basicAuth?.username === target?.creator?.name)
    ) {
      return true;
    }

    return false;
  };

  const isOwner = getIsOwner(entity);

  const isSignedIn = useMemo(() => {
    return auth?.id || basicAuth?.username;
  }, [auth, basicAuth]);

  return [
    { auth, basicAuth, allowV3UI, isSignedIn, isOwner },
    { isOwner, getIsOwner, setAuth },
  ] as const;
}
