import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TParam } from '../types';
import { parseDate } from '../../../utils/date';
import { format } from 'date-fns';
import classNames from 'classnames';

const commonClsNames =
  'w-full active:outline-none focus:outline-none px-1 text-xs';

export default function QueryParamInputV3({
  queryParam,
  onChange,
  className,
  idx,
  readOnly,
}: {
  queryParam: TParam;
  className?: string;
  onChange: (value?: string) => void;
  idx: number;
  readOnly?: boolean;
}) {
  if (queryParam.type === 'date') {
    return (
      <DatePicker
        className={classNames(commonClsNames, className)}
        selected={parseDate(queryParam.value, 'yyyy-MM-dd')}
        readOnly={readOnly}
        onChange={(date) => {
          const value = date ? format(date, 'yyyy-MM-dd') : '';
          onChange(value);
        }}
        dateFormat='yyyy-MM-dd'
      />
    );
  }

  if (queryParam.type === 'datetime') {
    return (
      <DatePicker
        className={classNames(commonClsNames, className)}
        selected={parseDate(queryParam.value, 'yyyy-MM-dd HH:mm:ss')}
        readOnly={readOnly}
        onChange={(date) => {
          const value = date ? format(date, 'yyyy-MM-dd HH:mm:ss') : '';
          onChange(value);
        }}
        showTimeInput
        dateFormat='yyyy-MM-dd HH:mm:ss'
      />
    );
  }

  if (queryParam.type === 'number') {
    return (
      <input
        className={classNames(commonClsNames, className)}
        value={queryParam.value}
        type='number'
        readOnly={readOnly}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    );
  }

  return (
    <input
      className={classNames(commonClsNames, className)}
      value={queryParam.value}
      placeholder={`value${idx + 1}`}
      readOnly={readOnly}
      type='text'
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
}
