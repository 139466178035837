import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';
import './Explore.scss';
import { ExploreItem, ExploreItemType } from './explore/components/ExploreItem';
import { useExploreData } from './useExploreData';
import {
  ModelListDashboardItem,
  PoProject,
} from '../api/__gen__/data-contracts';
import LoadingIndicator from '../components/LoadingIndicator';

import eth from '../modules/realtime-api/assets/ethereum.svg';
import sui from '../modules/realtime-api/assets/Sui.svg';
import stellar from '../modules/realtime-api/assets/Stellar.svg';
import defi from '../modules/realtime-api/assets/DeFi.svg';

export const GRAPHQL_APIS = [
  {
    name: 'Ethereum',
    description: '123131312312312',
    owner: { name: 'ZettaBlock' },
    id: 'ethereum',
    image: eth,
    implemented: true,
    // chains: ['ethereum'],
    detail:
      'Our GraphQL API offers real-time access to Ethereum blockchain data, including raw data (blocks, transactions, logs, traces), token transfers (Native, ERC20, ERC721, ERC1155), and token balances (Native, ERC20), enabling comprehensive on-chain analysis and dApp development.',
    labels: [] as string[],
    tableCount: 13,
  },
  {
    name: 'Sui',
    id: 'sui',
    image: sui,
    implemented: true,
    // chains: ['sui'],
    detail:
      'Our GraphQL API offers real-time access to Sui blockchain raw data, including checkpoints, transaction blocks, transactions, balance changes, object changes and events.',
    labels: [] as string[],
    tableCount: 6,
  },
  {
    name: 'Stellar',
    id: 'stellar',
    image: stellar,
    implemented: true,
    // chains: ['stellar'],
    detail:
      'Our GraphQL API offers real-time access to Stellar blockchain raw data, including accounts, assets, claimable balances, contracts, events, history_effects, history_ledgers, history_operations, history_transactions, etc.',
    labels: [] as string[],
    tableCount: 12,
  },
  {
    name: 'DeFi',
    id: 'defi',
    image: defi,
    implemented: true,
    chains: ['Sui'],
    detail:
      'Our GraphQL API offers real-time access to DeFi abstraction data, including newly deployed liquidity pools, pool liquidity actions and swaps, for various blockchains (Ethereum, Sui, etc).',
    labels: [] as string[],
    tableCount: 3,
  },
];

const ExploreSection = ({
  title,
  sectionItems,
  type,
}: {
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sectionItems:
    | ModelListDashboardItem[]
    | PoProject[]
    | {
        name: string;
        image: string;
        owner: { name: string };
        detail: string;
      }[];
  type: ExploreItemType;
}) => {
  return (
    <div className='mb-[100px]'>
      {/* title and view more */}
      <div className='flex justify-between mb-[40px]'>
        <div>
          <h2 className='text-[26px] font-bold'>{title}</h2>
        </div>
        <div className='flex items-center cursor-pointer text-[#777A80]'>
          <Link to={`/v2/explore/${type}`}>More</Link> <IoIosArrowForward />
        </div>
      </div>

      <div id='section-content' className=' flex justify-between flex-wrap'>
        {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          sectionItems.map((item, index: number) => {
            return (
              <ExploreItem
                title={item?.name || item?.displayName}
                author={item?.owner?.name || item?.creator?.name}
                imgUrl={item?.image}
                description={item?.detail || item?.description}
                item={item}
                key={index}
                type={type || ExploreItemType.API}
                from='home'
              />
            );
          })
        }
      </div>
    </div>
  );
};

const Explore = () => {
  const {
    publicApis,
    publicTables,
    publicDashboards,
    isLoading,
    publicQueries,
    publicGraphQlApis,
  } = useExploreData();

  return (
    <div className='min-w-[1080px] w-[1080px] 2xl:min-w-[1600px] 2xl:w-[1600px] 3xl:mx-auto mx-[80px] mt-[60px]'>
      <div className='flex justify-center '>
        <div>
          {isLoading ? (
            <div className='flex justify-center items-center'>
              <LoadingIndicator />
            </div>
          ) : (
            <>
              <ExploreSection
                sectionItems={publicApis}
                title='APIs'
                type={ExploreItemType.API}
              />
              <ExploreSection
                sectionItems={GRAPHQL_APIS}
                title='Realtime GraphQL APIs'
                type={ExploreItemType.GRAPHQL}
              />
              <ExploreSection
                sectionItems={publicGraphQlApis.slice(0, 6)}
                title='GraphQL APIs'
                type={ExploreItemType.GRAPHQL_API}
              />
              <ExploreSection
                sectionItems={publicTables}
                title='Tables'
                type={ExploreItemType.TABLE}
              />
              <ExploreSection
                sectionItems={publicQueries.slice(0, 12)}
                title='Queries'
                type={ExploreItemType.QUERY}
              />
              <ExploreSection
                sectionItems={publicDashboards.slice(0, 12)}
                title='Dashboards'
                type={ExploreItemType.DASHBOARD}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Explore;
