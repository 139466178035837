/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import { RiErrorWarningLine } from "react-icons/ri";
import { ModelGetUserApiProgressResponse, ModelGetUserApiResponse } from "../../../api/__gen__/data-contracts";
import moment from "moment";
import { useState, useMemo, useEffect } from "react";
import classNames from "classnames";

interface ProgressDisplayProps {
  indexingPercent: number;
  idxValues: ModelGetUserApiProgressResponse;
  apiDetail: ModelGetUserApiResponse;
  setTriggerPoll: React.Dispatch<React.SetStateAction<boolean>>;
}
enum IndexingStatus {
  indexing = 'Indexing',
  lastSync = 'Last Sync',
  Syncing = 'Syncing',
}

const ProgressDisplay = ({
  indexingPercent,
  idxValues,
  apiDetail,
  setTriggerPoll,
}: ProgressDisplayProps) => {
  const [openProgressPopup, setOpenProgressPopup] = useState(false);

  const isIndexing = useMemo(() => {
    const progress = idxValues.progress ?? 100;
    return progress < 100;
  }, [idxValues]);

  const indexingStatus = useMemo(() => {
    const progress = idxValues.progress ?? 100;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return progress < 100
      ? IndexingStatus.indexing
      : (idxValues as any).refreshInProgress
        ? IndexingStatus.Syncing
        : IndexingStatus.lastSync;
  }, [idxValues]);

  const { dataIngestedTime, dataIngestIntervalSeconds } = apiDetail;

  const [lastRefresh, setLastRefresh] = useState('Unknown');
  const [nextRefresh, setNextRefresh] = useState('Unknown');

  useEffect(() => {
    const i = setInterval(() => {
      const progress = idxValues.progress ?? 100;
      setLastRefresh(
        progress < 100
          ? ''
          : dataIngestedTime === 0
            ? 'Unknown'
            : moment(dataIngestedTime).fromNow()
      );

      if (
        dataIngestedTime !== undefined &&
        dataIngestIntervalSeconds !== undefined
      ) {
        const nextSyncTime =
          dataIngestedTime + dataIngestIntervalSeconds * 1000;
        const nextSync = (idxValues as any).refreshInProgress
          ? ''
          : new Date().getTime() - nextSyncTime < 0
            ? moment(nextSyncTime).fromNow()
            : 'Very soon';
        setNextRefresh(nextSync);

        if (
          nextSyncTime - new Date().getTime() < 60000 &&
          nextSyncTime - new Date().getTime() > -60000
        ) {
          setTriggerPoll(true);
        } else {
          setTriggerPoll(false);
        }
      }
    }, 1000);

    return () => {
      if (i) clearInterval(i);
    };
  }, [apiDetail]);

  const renderIndexingInfo = () => {
    if (!idxValues.progress) return null;

    const ongoing = idxValues.progress !== 100;
    const oneTimeSync = !dataIngestIntervalSeconds;

    const displayText = ongoing
      ? 'Progress'
      : oneTimeSync
        ? 'Never'
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (idxValues as any).refreshInProgress
          ? 'Sync In Progress'
          : 'Next Sync';

    return (
      <>
        <div
          className='text-xs flex items-center gap-1 cursor-pointer'
          onClick={() => {
            setOpenProgressPopup(!openProgressPopup);
          }}
        >
          {displayText}
          <RiErrorWarningLine size='0.8rem' className='opacity-70' />
          {/* <ViewApiProgressPopup
            open={openProgressPopup}
            idxValues={idxValues}
            setOpen={setOpenProgressPopup}
          /> */}
        </div>
        {!oneTimeSync && ongoing && (
          <div className='text-xs'>{indexingPercent}%</div>
        )}
        {!oneTimeSync && !ongoing && (
          <div className='text-xs'>{nextRefresh}</div>
        )}
      </>
    );
  };
  // eslint-disable-next-line no-console
  console.log(renderIndexingInfo)

  const displayText = () => {
    if (!idxValues.progress) return null;

    const ongoing = idxValues.progress !== 100;
    const oneTimeSync = !dataIngestIntervalSeconds;

    const text = ongoing
      ? 'Progress'
      : oneTimeSync
        ? 'Never'
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (idxValues as any).refreshInProgress
          ? 'Sync In Progress'
          : 'Next Sync';

    return <>
      <div className="text-[#777A80]">
        {text}
      </div>

      {!oneTimeSync && ongoing && (<div className="font-semibold">
        {indexingPercent}%
      </div>)}
      {!oneTimeSync && !ongoing && (<div className="font-semibold">
        {nextRefresh}
      </div>)}
    </>
  }


  return <div className="w-[840px] min-w-[840px] px-[30px] py-[20px]">
    <div className="flex justify-between mb-[10px] text-[12px] leading-[14px]">
      <div className="flex gap-[5px]">
        <div className="text-[#777A80]">
          {indexingStatus}
        </div>
        <div className="font-semibold">
          {lastRefresh}
        </div>
      </div>
      <div className="flex gap-[5px]">
        {
          displayText()
        }
      </div>
    </div>
    <div>
      <div className='relative'>
        <div className='w-full h-[5px] bg-[#F6F7F9] rounded-[5px]'></div>
        <div
          className={classNames(
            'h-[5px] absolute top-0 rounded-[5px]',
            isIndexing || dataIngestIntervalSeconds
              ? 'bg-[#0057FF]'
              : 'bg-[#a1a4a9]'
          )}
          style={{ width: `${indexingPercent}%` }}
        ></div>
      </div>
    </div>

  </div>
}

export default ProgressDisplay;