/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames"
import { useEffect, useState } from "react"
import { FaRegFolder, FaRegFile } from "react-icons/fa"
import { FiFolderPlus } from "react-icons/fi"
import { IoIosMore, IoIosArrowDown, IoIosArrowForward } from "react-icons/io"
import { Dropdown, Form, Input, Modal, Popconfirm, Table } from "antd"
import { deleteQuery, getQueries, renameQuery } from "./apis"
import { ModelQuerySummary } from "../../api/__gen__/data-contracts"
import { Link } from "react-router-dom"
import { BiEdit } from "react-icons/bi"
import { MdDelete } from "react-icons/md"
import { toast } from "react-toastify"
import useAuth from "../../hooks/auth"
import { track } from "../tracking"

const QueryWorkspace = () => {
  const [dataSource, setDataSource] = useState<ModelQuerySummary[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [newName, setNewName] = useState('')
  const [selectedQuery, setSelectedQuery] = useState<string | null>(null)


  const [{ auth }] = useAuth()

  const fetchAndSetData = async () => {
    if (auth?.id) {
      setIsLoading(true)
      const queries = await getQueries()
      setDataSource(queries)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchAndSetData()
  }, [])

  const columns = [
    {
      title: "NAME",
      dataIndex: "displayName ",
      key: "displayName",
      sorter: (a: any, b: any) => a.displayName?.localeCompare(b.displayName),
      render: (text: any, record: any) => {
        return <Link
          onClick={() => {
            track('Query Workspace - Open Query', {
              queryId: record.id
            })
          }}
          to={`/v2/build/${record.id}?from=workspace`}> <div className={
            classNames(
              "flex gap-2 items-center",
            )
          }>

            {<FaRegFile />}
            <div className="line-clamp-1">
              {record.displayName}
            </div>


          </div></Link>
      }
    },
    {
      title: "TYPE",
      dataIndex: "type",
      key: "type",
      render: (text: any, record: any) => {
        return <div className={
          classNames(
            "flex gap-2 items-center",
          )
        }>
          Query</div>
      }
    },
    {
      title: "CREATED",
      dataIndex: "createdTime",
      key: "createdTime",
      render(text: any) {
        return new Date(text).toLocaleDateString()
      }
    },
    {
      title: "UPDATED",
      dataIndex: "updatedTime",
      key: "updatedTime",
      render(text: any) {
        return new Date(text).toLocaleDateString()
      }
    }, {
      title: "AUTHOR",
      dataIndex: "creator",
      key: "creator",
      render: (creator) => {
        return creator?.displayName || creator?.name || 'Unknown'
      }
    },
    {
      title: 'ACTION',
      dataIndex: '',
      key: 'x',
      render: (text: string, record: any, dataIndex: number) => {
        if (auth?.id === record.creator.id) {
          return <div className="cursor-pointer flex items-center justify-center">

            <Dropdown
              trigger={['click']}
              menu={{
                items: [
                  {
                    key: '1', label: <div className="flex gap-2 items-center" onClick={(e) => {
                      setIsEditModalOpen(true)
                      setSelectedQuery(record.id)
                    }}>
                      <BiEdit /> Rename
                    </div>
                  },
                  {
                    key: '3', label: <Popconfirm title="Are you sure to delete this query?" onConfirm={async () => {
                      await deleteQuery(record.id)
                      toast.success(`Successfuly deleted Query`)
                      track('Query Workspace - Delete Query', {
                        queryId: record.id
                      })
                      fetchAndSetData()
                    }} onCancel={() => { }} okButtonProps={{
                      style: {
                        backgroundColor: '#253cf7',
                        borderColor: '#253cf7',
                        fontWeight: '600'
                      }
                    }}>
                      <div className="text-[#ec5e57] flex gap-2 items-center">
                        <MdDelete /> Delete
                      </div>
                    </Popconfirm>

                    // <div className="flex gap-2 items-center text-[#ec5e57]" onClick={async (e) => {
                    //   if (record.type === 'folder') {
                    //     await deleteProject(record.id)
                    //     setDataSource((prev) => {
                    //       return prev.filter((proj: any) => proj.id !== record.id)
                    //     })
                    //   } else {
                    //     await deleteApi(record.id)
                    //   }
                    //   toast.success(`Successfuly deleted ${record.type === 'folder' ? 'Project' : 'API'}`)
                    // }}>
                    //   <MdDelete /> Delete
                    // </div>
                  },
                ]
              }}>
              <IoIosMore />
            </Dropdown>

          </div>
        }
        return null
      },
    },
  ]
  return <>
    <Table
      pagination={false}
      loading={isLoading}
      expandable={
        {
          // eslint-disable-next-line react/no-unstable-nested-components
          expandIcon: ({ expanded, onExpand, record }: any) => {
            if (record.children) {
              return <span className="float-left mr-2 mt-1 cursor-pointer" onClick={e => onExpand(record, e)}>
                {expanded ? <IoIosArrowDown /> : <IoIosArrowForward />}
              </span>
            }
            return null

          }
        }
      }
      dataSource={dataSource}
      columns={columns}

    />
    <Modal open={isEditModalOpen} onOk={() => { setIsEditModalOpen(false) }} onCancel={() => { setIsEditModalOpen(false) }} footer={null}>
      <div className="px-4 pt-5 pb-2">
        <Form.Item label="Name">
          <Input onChange={(e) => {
            setNewName(e.target.value)
          }} />
        </Form.Item>
        <div className="mt-4 text-right">
          <button className="btn btn-primary" onClick={async () => {
            await renameQuery(newName, selectedQuery as string)
            setNewName('')
            setSelectedQuery(null)
            setIsEditModalOpen(false)
            fetchAndSetData()
            track('Query Workspace - Rename Query', {
              queryId: selectedQuery
            })
            toast.success('Successfuly updated query name', {
              autoClose: 2000,
              position: 'top-center',
            })
          }}>
            Update Name
          </button>
        </div>
      </div>
    </Modal>
  </>
}


export default QueryWorkspace