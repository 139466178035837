import classNames from 'classnames';
import { HTMLAttributes, memo } from 'react';

function SaturatedButton({
  className,
  ...props
}: HTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      className={classNames(
        'btn btn-sm btn-outline bg-white border-gray-300 hover:bg-white text-base-content hover:text-base-content capitalize border-opacity-50 gap-1 items-center flex',
        className
      )}
      {...props}
    >
      {props.children}
    </button>
  );
}

export default memo(SaturatedButton);
