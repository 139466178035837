/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelEmailLoginRequest,
  ModelEmailVerifyResponse,
  ModelLoginRequest,
  ModelLoginResponse,
  ModelLogoutResponse,
  ModelRefreshResponse,
  ModelUserActivationResendRequest,
  ModelUserActivationResendResponse,
  ModelUserSigninRequest,
  ModelUserSigninResponse,
  ModelUserSignupActivateResponse,
  ModelUserSignupRequest,
  ModelUserSignupResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Auth<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description resend the activation email
   *
   * @tags activation
   * @name ActivationResendCreate
   * @summary resend the activation email
   * @request POST:/auth/activation/resend
   */
  activationResendCreate = (
    request: ModelUserActivationResendRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelUserActivationResendResponse, any>({
      path: `/auth/activation/resend`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description login with google and create an account if necessary
   *
   * @tags login
   * @name LoginEmailCreate
   * @summary login with google and create an account if necessary
   * @request POST:/auth/login/email
   */
  loginEmailCreate = (
    request: ModelEmailLoginRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelLoginResponse, any>({
      path: `/auth/login/email`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description login with google and create an account if necessary
   *
   * @tags example
   * @name LoginGoogleCreate
   * @summary login with google and create an account if necessary
   * @request POST:/auth/login/google
   */
  loginGoogleCreate = (
    request: ModelLoginRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelLoginResponse, any>({
      path: `/auth/login/google`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description logout out of the account
   *
   * @tags example
   * @name LogoutCreate
   * @summary logout out of the account
   * @request POST:/auth/logout
   */
  logoutCreate = (params: RequestParams = {}) =>
    this.request<ModelLogoutResponse, any>({
      path: `/auth/logout`,
      method: 'POST',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description refresh tokens and return logged user information
   *
   * @tags example
   * @name PostAuth
   * @summary refresh tokens and return logged user information
   * @request POST:/auth/me
   */
  postAuth = (params: RequestParams = {}) =>
    this.request<ModelRefreshResponse, any>({
      path: `/auth/me`,
      method: 'POST',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description login with google and create an account if necessary
   *
   * @tags login
   * @name RegisterCreate
   * @summary login with google and create an account if necessary
   * @request POST:/auth/register
   */
  registerCreate = (
    request: ModelEmailLoginRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelLoginResponse, any>({
      path: `/auth/register`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description sign in with username and password
   *
   * @tags signin
   * @name SigninCreate
   * @summary sign in with username and password
   * @request POST:/auth/signin
   */
  signinCreate = (
    request: ModelUserSigninRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelUserSigninResponse, any>({
      path: `/auth/signin`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description register user account with email
   *
   * @tags signup
   * @name SignupCreate
   * @summary register account with email
   * @request POST:/auth/signup
   */
  signupCreate = (
    request: ModelUserSignupRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelUserSignupResponse, any>({
      path: `/auth/signup`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description user activate the registration through email verification
   *
   * @tags signup
   * @name SignupActivateIdCreate
   * @summary user activate the registration
   * @request POST:/auth/signup/activate/:id
   */
  signupActivateIdCreate = (id: string, params: RequestParams = {}) =>
    this.request<ModelUserSignupActivateResponse, any>({
      path: `/auth/signup/activate/${id}`,
      method: 'POST',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description login with google and create an account if necessary
   *
   * @tags example
   * @name VerifyEmailCreate
   * @summary login with google and create an account if necessary
   * @request POST:/auth/verify/email/{verificationToken}
   */
  verifyEmailCreate = (verificationToken: string, params: RequestParams = {}) =>
    this.request<ModelEmailVerifyResponse, any>({
      path: `/auth/verify/email/${verificationToken}`,
      method: 'POST',
      format: 'json',
      ...params,
    });
}
