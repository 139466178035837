const backendApi = process.env.REACT_APP_ZETTA_BACKEND_API || '';

export function getEnvs() {
  if (!process.env.REACT_APP_GA4_ID) {
    // eslint-disable-next-line
    console.error('MISSING: google analytic id');
  }

  const envs = {
    BackendUrl: process.env.REACT_APP_BACKEND_URL,
    ZettaBackendApiPrivate: `${backendApi}/qugate/v1`,
    ZettaBackendApiPrivateV2: `${backendApi}/qugate/v2`,
    ZettaBackendApiPublic: `${backendApi}/pubgate/v1`,
    GoogleClientID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    ga4Id: process.env.REACT_APP_GA4_ID || '',
    isProd: process.env.NODE_ENV === 'production',
    isProdRuntime: process.env.REACT_APP_ENV === 'production',
    hotjarId: process.env.REACT_APP_HOTJARID,
    ZettaOpenApi: 'https://api.zettablock.com/api/v1',
    StripeCustomerPortal: process.env.REACT_APP_STRIPE_CUS_PORTAL,
    StripePricingTableId: process.env.REACT_APP_STRIPE_PRICING_TABLE_ID,
    StripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    isCircleIntegrationEnabled:
      process.env.REACT_APP_CIRCLE_INTEGRATION === 'true' ||
      localStorage.getItem('isCircleIntegrationEnabled') === 'true',
    STRIPE_PRODUCT_ID_FREE: 'aa2d6e4f578eb0cfaba23beef76c2194',
    STRIPE_PRODUCT_ID_BUILD: 'b0da275520918e23dd615e2a747528f1',
    REACT_APP_STRIPE_SECRET_KEY: process.env.REACT_APP_STRIPE_SECRET_KEY || '',
    STRIPE_FREE_PLAN_ID: process.env.REACT_APP_STRIPE_FREE_PLAN_ID,
    STRIPE_BUILD_PLAN_ID: process.env.REACT_APP_STRIPE_BUILD_PLAN_ID,
  };

  return envs;
}

export default getEnvs();
