import { Link } from "react-router-dom";
import Logo from "../../../components/app/Logo";
import useAuth from "../../../hooks/auth";

const Navbar = () => {
  const [{auth}] = useAuth();
  
  return <div className="flex justify-between items-center px-[20px] py-[10px] border-b min-w-[1080px] bg-[#f8f7fa]">
    <Logo />
    <div className="flex gap-[30px] items-center">
      <div className="flex gap-[30px]">
        <a href="/v2/auth/login" className="text-[000] text-[16px]">Products</a>
        <a href="/v2/auth/register" className="text-[000] text-[16px] ml-4">Pricing</a>
        <a href="/v2/auth/register" className="text-[000] text-[16px] ml-4">Company</a>
        <a href="/v2/auth/register" className="text-[000] text-[16px] ml-4">Blog</a>
        <a href="/v2/auth/register" className="text-[000] text-[16px] ml-4">Docs</a>
      </div>
      {
        !auth?.id && <div className="flex items-center gap-[10px]">
        <Link to="/v2/auth/login" className="font-[500] text-[18px] leading-[18px] px-[20px] py-[11px] rounded-[60px] bg-transparent text-[#263DFF] hover:text-[#263DFF] get-started">Login</Link>
        <Link to="/v2/auth/signup" className="font-[500] text-[18px] leading-[18px] px-[20px] py-[11px] rounded-[5px] bg-[#263DFF] text-[#fff] get-started">Sign up</Link>
      </div>}
    </div>
  </div>
}

export default Navbar;