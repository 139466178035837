import { format, isValid, parse } from 'date-fns';

export function parseDate(
  dateStr?: string,
  formatStr = 'yyyy-MM-dd HH:mm:ss',
  defaultValue = new Date()
) {
  try {
    if (!dateStr) {
      return defaultValue;
    }

    const parsed = parse(dateStr, formatStr, defaultValue);

    if (isValid(parsed)) {
      return parsed;
    }

    return defaultValue;
  } catch (error) {
    // eslint-disable-next-line
    console.error('ignore parsing error', error);
  }

  return defaultValue;
}

export const DateFormat = 'yyyy-MM-dd';
export const DateTimeFormat = 'yyyy-MM-dd HH:mm:ss';

export function convertStrToDate(str: string) {
  return format(parseDate(str, 'yyyy-MM-dd'), 'yyyy-MM-dd');
}

export function convertStrToDatetime(str?: string) {
  if (!str) return '';

  return format(parseDate(str, 'yyyy-MM-dd HH:mm:ss'), 'yyyy-MM-dd HH:mm:ss');
}

export function convertNumberToDatetime(value?: number) {
  if (!value) return '';

  return format(value, 'yyyy-MM-dd HH:mm:ss');
}

export function convertNumberToFormatted(
  value?: number,
  {
    formatStr = 'yyyy-MM-dd HH:mm:ss',
    fallbackStr,
  }: { fallbackStr?: string; formatStr?: string } = {}
) {
  if (!value) return fallbackStr || '';

  return format(value, formatStr);
}
