/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelPatchPersonalSubscriptionRequest,
  ModelPatchSubscriptionRequest,
  ModelPatchSubscriptionResponse,
  ModelPutSubscriptionRequest,
  ModelPutSubscriptionResponse,
  ModelUpdatePlanChosenRequest,
  ModelUpdatePlanChosenResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Subscription<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description put subscription
   *
   * @tags example
   * @name SubscriptionUpdate
   * @summary put subscription
   * @request PUT:/subscription
   */
  subscriptionUpdate = (
    request: ModelPutSubscriptionRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelPutSubscriptionResponse, any>({
      path: `/subscription`,
      method: 'PUT',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description patch subscription
   *
   * @tags example
   * @name SubscriptionPartialUpdate
   * @summary patch subscription
   * @request PATCH:/subscription
   */
  subscriptionPartialUpdate = (
    request: ModelPatchSubscriptionRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelPatchSubscriptionResponse, any>({
      path: `/subscription`,
      method: 'PATCH',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description update plan chosen state of free user
   *
   * @tags example
   * @name FreePlanChosenCreate
   * @summary update query config
   * @request POST:/subscription/free/planChosen
   */
  freePlanChosenCreate = (
    request: ModelUpdatePlanChosenRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelUpdatePlanChosenResponse, any>({
      path: `/subscription/free/planChosen`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description patch personal subscription
   *
   * @tags example
   * @name PersonalPartialUpdate
   * @summary patch personal subscription
   * @request PATCH:/subscription/personal
   */
  personalPartialUpdate = (
    request: ModelPatchPersonalSubscriptionRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelPatchSubscriptionResponse, any>({
      path: `/subscription/personal`,
      method: 'PATCH',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
