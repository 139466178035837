import { useMemo } from "react";
import { useEditApi, useIndexingProgress } from "../../../modules/api/ViewApiHooks";
import ViewApiOverviewEditor from "../../../modules/api/ViewApiOverviewEditor";
import { TIME_MAPPING } from "../../../modules/api/const";
import { DateFormat, convertNumberToFormatted } from "../../../utils/date";
import ProgressDisplay from "./ProgressDisplay";
import { formatDataUsageInBytes, numberWithCommas } from "../../../utils/format";

interface OverviewProps {
  title: string
}

const Overview = ({
  title
}: OverviewProps) => {
  const [{ values },] = useEditApi();
  const isAppendMode = useMemo(() => {
    return values.writeMode === 'Append';
  }, [values]);

  const [{ values: idxValues, setTriggerPoll }] = useIndexingProgress();

  return <div className="w-[1240px] min-w-[1240px] flex mx-auto">
    <div className="w-[400px] min-w-[400px] p-[30px] h-[calc(100vh-262px)] border-r overflow-auto">
      <div className="text-[20px] font-semibold mb-[15px]">
        {title || 'GQL API'}
      </div>

      <div className="h-[1px] w-full border-b">

      </div>

      <div className="">
        <div className="flex gap-[10px] pt-[30px] ">
          <div className="max-w-[165px] w-[165px]">
            <div className="text-[12px] text-[#777A80] mb-[5px]">
              CREATED DATE
            </div>
            <div className="text-[12px] text-[#1A1A1A] font-semibold">
              {convertNumberToFormatted(values.createdTime, {
                formatStr: DateFormat,
                fallbackStr: '-',
              })}
            </div>
          </div>
          <div className="max-w-[165px] w-[165px]">
            <div className="text-[12px] text-[#777A80] mb-[5px]">
              PUBLISHED DATE
            </div>
            <div className="text-[12px] text-[#1A1A1A] font-semibold">
              {convertNumberToFormatted(values.publishedTime, {
                formatStr: DateFormat,
                fallbackStr: '-',
              })}
            </div>
          </div>
        </div>
        <div className="flex gap-[10px] pt-[30px] ">
          <div className="max-w-[165px] w-[165px]">
            <div className="text-[12px] text-[#777A80] mb-[5px]">
              LAST UPDATED
            </div>
            <div className="text-[12px] text-[#1A1A1A] font-semibold">
              {convertNumberToFormatted(values.updatedTime, {
                formatStr: DateFormat,
                fallbackStr: '-',
              })}
            </div>
          </div>
          <div className="max-w-[165px] w-[165px]">
            <div className="text-[12px] text-[#777A80] mb-[5px]">
              PUBLISHED BY
            </div>
            <div className="text-[12px] text-[#1A1A1A] font-semibold">
              {values.creator?.name}
            </div>
          </div>
        </div>
        <div className="flex gap-[10px] pt-[30px] ">
          <div className="max-w-[330px] w-[330px]">
            <div className="text-[12px] text-[#777A80] mb-[5px]">
              DATA REFRESH INTERVAL
            </div>
            <div className="text-[12px] text-[#1A1A1A] font-semibold">
              {values.dataIngestIntervalSeconds
                ? TIME_MAPPING[values.dataIngestIntervalSeconds]
                : 'no refresh'}
            </div>
          </div>

        </div>
        <div className="flex gap-[10px] pt-[30px] ">
          <div className="max-w-[330px] w-[330px]">
            <div className="text-[12px] text-[#777A80] mb-[5px]">
              INCREMENTAL DATA REFRESH
            </div>
            <div className="text-[12px] text-[#1A1A1A] font-semibold">
              {isAppendMode ? 'enabled' : 'not enabled'}
            </div>
          </div>
        </div>
        <div className="flex gap-[10px] pt-[30px] ">
          <div className="max-w-[330px] w-[330px]">
            <div className="text-[12px] text-[#777A80] mb-[5px]">
              COMPUTE UNITS PER 100 RECORDS

            </div>
            <div className="text-[12px] text-[#1A1A1A] font-semibold">
              {values.computeUnitPer100Records}
            </div>
          </div>
        </div>
        <div className="flex gap-[10px] pt-[30px] ">
          <div className="max-w-[330px] w-[330px]">
            <div className="text-[12px] text-[#777A80] mb-[5px]">
              LAST REFRESH COMPUTE UNITS

            </div>
            <div className="text-[12px] text-[#1A1A1A] font-semibold">
              {values.lastRefreshCU}
            </div>
          </div>
        </div>
        <div className="flex gap-[10px] pt-[30px] ">
          <div className="max-w-[330px] w-[330px]">
            <div className="text-[12px] text-[#777A80] mb-[5px]">
              CURRENT DATA SIZE

            </div>
            <div className="text-[12px] text-[#1A1A1A] font-semibold">
              {values.dataTotalSize
                ? formatDataUsageInBytes(values.dataTotalSize)
                : 'Unknown'}
            </div>
          </div>
        </div>

        <div className="flex gap-[10px] pt-[30px] ">
          <div className="max-w-[330px] w-[330px]">
            <div className="text-[12px] text-[#777A80] mb-[5px]">
              CURRENT NUMBER OF RECORDS
            </div>
            <div className="text-[12px] text-[#1A1A1A] font-semibold">
              {numberWithCommas(values.numberOfRecords)}
            </div>
          </div>
        </div>
      </div>

    </div>
    <div className="w-[840px] min-w-[840px]">
      <div className="border-b">
        <ProgressDisplay
          indexingPercent={idxValues.progress || 0}
          idxValues={idxValues}
          apiDetail={values}
          setTriggerPoll={setTriggerPoll}
        />
      </div>
      <div className="h-[calc(100vh-331px)] overflow-y-auto">
        <ViewApiOverviewEditor />
      </div>
    </div>

  </div>
}

export default Overview