import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { RiDashboardFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import LogoBlinkerCenter from '../../components/app/LogoBlinkerCenter';
import { useClientPageSize } from '../../hooks/network';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import { convertNumberToFormatted, DateFormat } from '../../utils/date';
import StarButton from '../api/components/StarButton';
import { ACCESS_MAPPING } from '../api/const';
import ProfileAvatar from '../auth/ProfileAvatar';
import {
  useCommunityDashboards,
  useMyDashboards,
} from '../dashboard/dashboard-hooks';
import OnVisible from './components/OnVisible';
import useAuth from '../../hooks/auth';
import ListFilters from './components/ListFilters';

export default function DashboardListPage() {
  const [{ menu, isMyWorkspace }, { getPath }] = useWorkspace();

  // my-workspace dashboards
  const [
    { myDashboards, rpcStatus: myDashboardsRpcStatus },
    { getMyDashboards },
  ] = useMyDashboards();
  const [myDashboardsPageSize, { showMore: myDashboardsShowMore }] =
    useClientPageSize('dashboard-list');

  // community dashboards
  const [
    { communityDashboards, rpcStatus: communityDashboardsRpcStatus },
    { getCommunityDashboards },
  ] = useCommunityDashboards();
  const [
    communityDashboardsPageSize,
    { showMore: communityDashboardsShowmore },
  ] = useClientPageSize('community-dashboard-list');

  useEffect(() => {
    if (isMyWorkspace) {
      getMyDashboards();
    } else {
      getCommunityDashboards();
    }
  }, [isMyWorkspace]);

  // filter by keyword and creator
  const [keyword, setKeyword] = useState('');
  const [createdBy, setCreatedBy] = useState('all');
  const filteredItems = useMemo(() => {
    return (isMyWorkspace ? myDashboards : communityDashboards).items
      ?.filter(e => {
        if (createdBy && createdBy !== 'all') {
          return e.creator?.id === createdBy
        }
        return e
      })
      .filter(e => {
        if (e.displayName) {
          return e.displayName?.toLowerCase().includes(keyword.toLowerCase())
        }
        return e.id?.toLowerCase().includes(keyword.toLowerCase())
      }).slice(
        0,
        isMyWorkspace ? myDashboardsPageSize : communityDashboardsPageSize
      )
  }, [keyword, createdBy, isMyWorkspace, myDashboards, communityDashboards, myDashboardsPageSize, communityDashboardsPageSize])

  const [{ auth }] = useAuth();

  if (
    (isMyWorkspace &&
      !myDashboards?.items?.length &&
      myDashboardsRpcStatus.isLoading) ||
    (!isMyWorkspace &&
      !communityDashboards?.items?.length &&
      communityDashboardsRpcStatus.isLoading)
  ) {
    return (
      <div className='flex w-full items-center py-20 justify-center'>
        <LogoBlinkerCenter />
      </div>
    );
  }

  return (
    <div className='explorer flex flex-col w-full p-3 overflow-x-auto'>
      <ListFilters
        workspaceList={myDashboards}
        communityList={communityDashboards}
        isMyWorkspace={isMyWorkspace}
        auth={auth}
        onKeywordChange={setKeyword}
        onCreatorChange={setCreatedBy}
        creator={createdBy}
      />

      <table className='table w-full'>
        <thead className='rounded-full w-full'>
          <tr className='w-full font-semibold opacity-30'>
            <th className='px-4 py-2 w-full'>NAME</th>
            <th className='px-4 py-2'>
              {isMyWorkspace ? 'CREATED BY' : 'PUBLISHED BY'}
            </th>
            <th className='px-4 py-2'>
              <div className='inline items-center text-center'>
                {isMyWorkspace ? 'CREATED AT' : 'PUBLISH DATE'}
              </div>
            </th>
            {isMyWorkspace && (
              <th className='px-4 py-2'>
                <span className='flex items-center text-center'>
                  LAST UPDATED
                </span>
              </th>
            )}
            <th className='px-4 py-2'>STARS</th>
          </tr>
        </thead>
        <tbody className='rounded-full w-full'>
          {(isMyWorkspace && isEmpty(myDashboards.items)) ||
            (!isMyWorkspace && isEmpty(communityDashboards.items) && (
              <td colSpan={1000} className='text-center opacity-30 p-20'>
                No dashboards
              </td>
            ))}
          {filteredItems?.map(
            ({
              id,
              displayName,
              creator,
              updatedTime,
              createdTime,
              publishedTime,
              starCount,
              access,
            }) => (
              <tr
                key={id}
                className='font-semibold hover:bg-primary hover:bg-opacity-10 hover:rounded-lg items-center overflow-hidden'
              >
                <td className='p-2 overflow-hidden'>
                  <Link key={id} to={`${getPath(menu)}/${id}`}>
                    <div className='flex flex-auto items-center text-lg space-x-2 pr-2 overflow-hidden w-[35rem]'>
                      <RiDashboardFill
                        size='2rem'
                        className='text-blue-600'
                      />
                      {/* TODO : revisit once upload dashboard image is ready */}
                      {/* {displayName && displayName.length % 2 === 0 ? (<div className='card w-10 h-10 ml-1 mr-1 rounded-lg image-full before:!bg-transparent'>
                        <figure>
                          <img
                            src='https://placeimg.com/400/225/arch'
                            alt='ZB'
                          />
                        </figure>
                      </div>) : (
                      <RiDashboardFill size='2.5rem' className='text-blue-200 bg-current ml-1 mr-1 rounded-lg' />
                      )} */}
                      <span
                        title={displayName || id}
                        className='flex-grow-0 overflow-hidden whitespace-nowrap text-ellipsis'
                      >
                        {displayName || id}
                      </span>
                      {isMyWorkspace && access && (
                        <span className='self-center bg-base-content bg-opacity-10 py-[0.15rem] px-2 text-xs rounded-md opacity-50 font-semibold top-[0.4rem]'>
                          {ACCESS_MAPPING[access]}
                        </span>
                      )}
                    </div>
                  </Link>
                </td>
                <td className='p-2 overflow-hidden'>
                  <Link key={id} to={`${getPath(menu)}/${id}`}>
                    <div className='flex flex-auto w-[12rem] justify-start items-center gap-3'>
                      <ProfileAvatar
                        creator={creator}
                        className='flex-none w-6 bg-base-content bg-opacity-30'
                      />
                      <span
                        title={creator?.name || 'API Owner'}
                        className='flex-auto overflow-hidden whitespace-nowrap text-ellipsis'
                      >
                        {creator?.name || 'API Owner'}
                      </span>
                    </div>
                  </Link>
                </td>
                <td className='p-2 w-8 opacity-50 text-center'>
                  <Link key={id} to={`${getPath(menu)}/${id}`}>
                    <div>
                      {convertNumberToFormatted(
                        isMyWorkspace ? createdTime : publishedTime,
                        {
                          formatStr: DateFormat,
                        }
                      )}
                    </div>
                  </Link>
                </td>
                {isMyWorkspace && (
                  <td className='p-2 w-8 opacity-50 text-center'>
                    <Link key={id} to={`${getPath(menu)}/${id}`}>
                      <div>
                        {convertNumberToFormatted(updatedTime, {
                          formatStr: DateFormat,
                        })}
                      </div>
                    </Link>
                  </td>
                )}
                <td className='p-2 w-8 opacity-50 text-center'>
                  <StarButton
                    id={id}
                    isStarred={false} // TODO : replace after getting star value from API
                    starCount={starCount}
                    page={'dashboard'}
                  />
                </td>
              </tr>
            )
          )}
          <OnVisible
            onVisible={() => {
              if (isMyWorkspace) {
                myDashboardsShowMore();
              } else {
                communityDashboardsShowmore();
              }
            }}
          />
        </tbody>
      </table>
    </div>
  );
}
