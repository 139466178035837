/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { Input, Modal } from "antd";
import classNames from "classnames";
import { BiUpload, BiTrash } from "react-icons/bi";
import { toast } from "react-toastify";
import { ImageApi } from "../../api/client";
import ImageUploading from 'react-images-uploading';

const ImageUpload = ({
  isOpen,
  onClose,
  onConfirm
}: any) => {
  const [name, setName] = useState<string>('')
  const [project, setProject] = useState<string>('')


  const [images, setImages] = useState<any>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onChange = (imageList, _addUpdateIndex) => {
    setImages(imageList);
  };

  const onClear = () => {
    setName('')
    setProject('')
    setImages([])

    onClose()
  }

  return <div>
    <Modal
      onCancel={() => {
        onClear()
      }}
      title="Image Upload" open={isOpen}
      footer={
        <div className="flex w-full gap-6">
          <button
            className="flex-1 btn btn-primary bg-[#fafafa] hover:bg-[#f1f1f1] hover:border-[#dedede] text-[#333] border-[#dedee0]"
            onClick={() => {
              onClear()
            }}
          >Cancel</button>
          <button disabled={images.length < 1} className={
            classNames("flex-1 btn btn-primary hover:bg-[#271c99] bg-[#263DFF] border-[#263DFF]", {
            })
          }
            onClick={async () => {
              const client = ImageApi()
              if (!name || !project) {
                toast.error('Name and Project are required', {
                  autoClose: 2000,
                  position: 'top-center',
                })
                return
              }

              try {
                const image = (await client.imageCreate(images[0]))?.data?.image
                toast.success('Image uploaded successfully', {
                  autoClose: 2000,
                  position: 'top-center',
                })

                if (!image?.id) {
                  toast.error('Error uploading image', {
                    autoClose: 2000,
                    position: 'top-center',
                  })
                  return
                }

                await client.imageCreate2(image.id, {
                  ...image,
                  name: name || image.name,
                  project: project || image.project
                })


                onConfirm()
              } catch (error) {
                // console.log(error);
              }
            }}
          >Confirm</button>
        </div>
      }
    >
      <ImageUploading
        value={images}
        onChange={onChange}
        dataURLKey="data_url"
      // acceptType={['jpg', 'png']}
      >
        {({
          imageList,
          onImageUpload,
          // onImageRemoveAll,
          // onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="my-6">
            <div className="font-semibold mb-2">
              Image
            </div>
            {
              imageList.length === 0 && <button
                className="flex w-full h-36 border border-[#e3e6ec] rounded-md justify-center items-center"
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                <div>
                  <div>Drop file to upload or Click to browse</div>
                  <div className="btn bg-[#fafafa] hover:bg-[#f1f1f1] text-[#333] hover:border-[#dedee0] border-[#dedee0] mt-3">
                    <BiUpload size={24} />
                    Upload
                  </div>
                </div>

              </button>
            }
            &nbsp;
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            {
              imageList.length ? <div className="flex justify-center pt-3 border">
                <div className="w-full">
                  {imageList.map((image, index) => (
                    <div key={index} className="text-center pb-3">
                      <img src={image.data_url} alt="" width="100" className="mx-auto" />
                      <div className="image-item__btn-wrapper">
                        {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                        {/* <button onClick={() => onImageRemove(index)}>Remove</button> */}
                      </div>
                    </div>
                  ))}
                  <div className="p-3 border-t flex justify-between items-center" >
                    <span>
                      {imageList?.[0]?.file?.name || 'No file selected'}
                    </span>
                    <BiTrash onClick={() => onImageRemove(0)} />
                  </div>
                  <div>
                    <div className="border-t">
                      <Input onChange={(e) => {
                        setName(e.target.value)
                      }} className="focus:outline-none w-full border-none" name="image_name" placeholder="Image Name" />
                    </div>
                    <div className="border-t">
                      <Input onChange={(e) => {
                        setProject(e.target.value)
                      }} className="focus:outline-none w-full border-none" placeholder="Image Project" />
                    </div>
                  </div>
                </div>
              </div> : null
            }
          </div>
        )}
      </ImageUploading>
    </Modal>

  </div>
}

export default ImageUpload;