/* eslint-disable @typescript-eslint/no-explicit-any */
import { sql } from '@codemirror/lang-sql';
import { useCodeMirror } from '@uiw/react-codemirror';
import { useRef, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { ApiQueryTextState } from './ApiQueryPadHooks';
import { useApiId } from './ViewApiHooks';
import { codemirrorTheme } from '../../config/theme';

const EditApiQuery = ({ defaultQuery, onChange }: any) => {
  const editor = useRef<HTMLDivElement>();
  const apiId = useApiId();
  const [query] = useRecoilState(ApiQueryTextState(apiId));
  // const [{ values }] = useEditApi();

  const { setContainer } = useCodeMirror({
    theme: codemirrorTheme,
    onChange,
    defaultValue: defaultQuery,
    container: editor.current,
    extensions: [sql()],
    value: query,
  });

  useEffect(() => {
    if (editor.current) {
      setContainer(editor.current);
    }
  }, [editor]);

  return (
    <div className='flex h-full w-full relative group'>
      <div
        key='editor'
        className='flex-1 overflow-y-auto border'
        ref={editor as React.RefObject<HTMLDivElement>}
      />
    </div>
  );
};

export default EditApiQuery;
