/* eslint-disable @typescript-eslint/no-explicit-any */
import { Input, Select } from "antd";
import Modal from "antd/es/modal/Modal";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { ImagesApi } from "../../api/client";
import { ModelMultiMedia } from "../../api/__gen__/data-contracts";
import { toast } from "react-toastify";
import { BiPlus } from "react-icons/bi";
import ImageUpload from "./ImageUpload";


const ImagePicker = ({
  value,
  onChange
}: {
  value: string;
  onChange: (value: string) => void;
}) => {
  const [selectedImage, setSelectedImage] = useState<any | null>(null)
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState<ModelMultiMedia[]>([]);
  const [projectOptions, setProjectOptions] = useState<{
    label: string;
    value: string;
  }[]>([]);

  const [selectedProject, setSelectedProject] = useState<string>('all');

  const [showUploadModal, setShowUploadModal] = useState(false);

  const fetchAndSetImages = async () => {
    const client = ImagesApi();
    try {
      const resp = await client.imagesList();
      setImages(resp.data.images || []);

      const options = [{
        label: 'All',
        value: 'all'
      }, ...resp.data.images?.map(e => {
        return {
          label: e.project?.toLocaleUpperCase() || '',
          value: e.project || ''
        }
      }).filter((e, index) => {
        return resp.data.images?.findIndex((image) => {
          return image.project === e.value
        }) === index
      }) || []]


      setProjectOptions(options)

    } catch (error) {
      toast.error('Error fetching images')
    }
  }

  useEffect(() => {
    fetchAndSetImages();

  }, [])
  return <div>
    {/* <button onClick={() => setIsOpen(true)}>Image</button> */}

    <Input placeholder="Image URL" value={value || ''} onClick={() => setIsOpen(true)} />

    <Modal
      onCancel={() => {
        setIsOpen(false)
      }}
      title="Select Image"
      open={isOpen}
      footer={<div className="flex w-full gap-6">
        <button
          className="flex-1 btn btn-primary bg-[#fafafa] hover:bg-[#f1f1f1] hover:border-[#dedede] text-[#333] border-[#dedee0]"
          onClick={() => {
            // setImages([])
            // setShowUploadModal(false)
            setIsOpen(false)
          }}
        >Cancel</button>
        <button
          onClick={() => {
            onChange(selectedImage?.url || '')
            setIsOpen(false)
          }}
          disabled={!selectedImage} className={
            classNames("flex-1 btn btn-primary hover:bg-[#271c99] bg-[#263DFF] border-[#263DFF]", {
            })
          }>Confirm</button>
      </div>}
    >
      <div className="my-6">
        <div className="font-semibold mb-2">
          Image
        </div>
        <div className="border rounded overflow-hidden">
          <div className="bg-[#fafafa] flex justify-end p-3">
            <Select className="w-[200px]" value={selectedProject} options={projectOptions} placeholder="project" onChange={(e) => {
              setSelectedProject(e)
              if (selectedImage && selectedImage.project !== e) {
                setSelectedImage(null)
              }
            }} />
          </div>

          <div className="p-3 border-y flex flex-wrap gap-3 h-[200px] overflow-auto">
            <div
              onClick={() => setShowUploadModal(true)}
              className="h-20 w-20 flex justify-center items-center border rounded cursor-pointer">
              <BiPlus size={26} />
            </div>
            {
              images.filter(e => {
                if (selectedProject !== 'all') {
                  return e.project === selectedProject
                }
                return true
              }).map((image, index) => {
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                return <img className={
                  classNames("object-contain	h-20 w-20 cursor-pointer", {
                    'border border-[#263DFF]': selectedImage?.id === image.id,
                  })
                } src={image.url} key={index} onClick={() => { setSelectedImage(image) }} />
              })
            }

          </div>
          <div className="bg-[#fafafa] p-3">
            {
              selectedImage?.name
            }
          </div>
        </div>
      </div>

    </Modal>
    <ImageUpload
      isOpen={showUploadModal}
      onClose={async () => {

        setShowUploadModal(false)
      }}
      onConfirm={
        async () => {
          await fetchAndSetImages()
          setShowUploadModal(false)
        }
      }
    />
  </div>
}

export default ImagePicker;