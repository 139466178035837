import { HTMLAttributes, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';

import {
  DefaultTParamType,
  ParamTypeValues,
  TParam,
  TParamType,
} from '../types';
import { convertStrToDate, convertStrToDatetime } from '../../../utils/date';
import { trim } from 'lodash';
import QueryParamInputV3 from './QueryParamInputV3';

export default function QueryParamsConfigModalV3({
  queryParam,
  onSave,
  onRemove,
  startOpen,
  onClose,
  idx,
  ...props
}: {
  queryParam: TParam;
  startOpen?: boolean;
  onSave: (queryParam: TParam) => void;
  onRemove: () => void;
  onClose?: () => void;
  idx: number;
} & HTMLAttributes<HTMLDivElement>) {
  const [open, setOpen] = useState(startOpen);
  const [formValue, setFormValue] = useState(queryParam);

  const handleClose = useCallback(() => {
    setOpen(false);

    if (onClose) {
      onClose();
    }
  }, [setOpen]);

  if (!open || !queryParam) {
    return null;
  }

  return (
    <div {...props}>
      <div className='px-3 py-2'>
        <div className='flex items-center'>
          <label className='w-[120px] block'>
            <span className='label-text text-xs opacity-60 font-semibold'>
              Name
            </span>
          </label>
          <div className='flex-shrink w-[130px]'>
            <input
              className='input input-sm w-full active:outline-none focus:outline-none p-0 font-semibold'
              value={formValue.name}
              onChange={(e) => {
                setFormValue({
                  ...formValue,
                  name: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className='divider-x' />
      <div className='px-3 py-2'>
        <div className='flex items-center'>
          <label className='w-[120px] block'>
            <span className='label-text text-xs opacity-60 font-semibold'>
              Type
            </span>
          </label>
          <div className='flex-shrink w-[130px]'>
            <select
              className='select select-sm w-full active:outline-none focus:outline-none p-0 font-semibold'
              value={formValue.type || DefaultTParamType}
              onChange={(e) => {
                const { defaultValue } = formValue;
                const type = e.target.value as TParamType;

                switch (type) {
                  case 'date': {
                    const date = convertStrToDate(defaultValue || '');

                    setFormValue({
                      ...formValue,
                      value: date,
                      defaultValue: date,
                      type,
                    });
                    return;
                  }

                  case 'datetime': {
                    const date = convertStrToDatetime(defaultValue || '');

                    setFormValue({
                      ...formValue,
                      value: date,
                      defaultValue: date,
                      type,
                    });
                    return;
                  }
                  case 'string':
                  case 'number': {
                    setFormValue({
                      ...formValue,
                      value: '1',
                      defaultValue: '1',
                      type,
                    });
                    return;
                  }
                }

                setFormValue({
                  ...formValue,
                  type,
                });
              }}
            >
              {ParamTypeValues.map((typeValue) => (
                <option key={typeValue} value={typeValue}>
                  {typeValue}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className='divider-x' />
      <div className='px-3 py-2'>
        <div className='flex items-center'>
          <label className='w-[120px] block'>
            <span className='label-text text-xs opacity-60 font-semibold'>
              Default Value
            </span>
          </label>
          <div className='flex-shrink w-[130px]'>
            <QueryParamInputV3
              className='input input-sm font-semibold'
              idx={idx}
              queryParam={{
                ...formValue,
                value: formValue.defaultValue,
              }}
              onChange={(value) => {
                setFormValue({
                  ...formValue,
                  value,
                  defaultValue: value,
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className='divider-x' />
      <div className='flex space-x-2 justify-between px-3 py-2'>
        <div className='flex gap-3'>
          <button
            type='button'
            className='text-sm text-primary font-bold'
            onClick={() => {
              if (!trim(formValue.value)) {
                toast.warning('Default value required');
                return;
              }

              // only alpha numeric allowed
              // According to Scott -> we should support numeric + alphabetic + underscore
              const alphaNumRegLower = /^[a-zA-Z0-9 _-]*$/i;

              if (
                !formValue.name.match(alphaNumRegLower) ||
                !formValue.type.match(alphaNumRegLower)
              ) {
                toast.warning('invalid param name or type');
                return;
              }

              onSave(formValue);
              handleClose();
            }}
          >
            Save
          </button>
          <button
            type='button'
            className='text-sm text-error font-semibold'
            onClick={() => {
              onRemove();
              handleClose();
            }}
          >
            Remove
          </button>
        </div>
        <button
          type='button'
          className='text-sm opacity-60'
          onClick={handleClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
