import { TSeriesType } from '../chart/components/EChart';

export const PollingInterval = 1000;

export type UpdateChartFuncType = (
  name: string | undefined,
  newOptions: Partial<TSeriesType>
) => void;

export const SERIES_OPTION_ROW = 'SERIES_OPTION_ROW';

type Command = {
  name: string;
  keys: {
    mac: string[];
    pc: string[];
  };
};

export const COMMANDS_PER_PAGE = 6;

export const COMMANDS: Command[] = [
  { name: 'undo selection', keys: { mac: ['⌘', 'U'], pc: ['Ctrl', 'U'] } },
  {
    name: 'run query',
    keys: { mac: ['⌘/Ctrl', 'Enter'], pc: ['Ctrl', 'Enter'] },
  },
];

export const dbGroup1 = [
  'arbitrum_mainnet',
  'ethereum_mainnet',
  'ethereum',
  'iotex_mainnet',
  'polygon_mainnet',
  'ripple_mainnet',
  'solana_mainnet',
  'aptos_mainnet',
  'aptos_testnet',
  'goerli',
  'bsc_mainnet',
  'polygon_mumbai',
  'ethereum_goerli',
  'zksync_goerli',
  'zksync_mainnet',
  'polygon_zkevm_mainnet',
  'bitcoin',
  'avalanche_mainnet',
  'sui_mainnet',
  'base_mainnet',
  'beacon_testnet',
  'avalanche_pchain_mainnet',
  'near_mainnet',
  'optimism_mainnet',
  'stellar_mainnet',
];

export const dbGroup2 = [
  'nft',
  'nft_listing',
  'prices',
  'ens',
  'dex',
  'bridges',
];
