/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ModelUsageSummary } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Apikey<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description get monthly usage by api key
   *
   * @tags example
   * @name UsageDetail
   * @summary get monthly usage by api key
   * @request GET:/apikey/{apiKey}/usage
   */
  usageDetail = (
    apiKey: string,
    query: { year: number; month: number },
    params: RequestParams = {}
  ) =>
    this.request<ModelUsageSummary, any>({
      path: `/apikey/${apiKey}/usage`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get usage by api key and date range
   *
   * @tags example
   * @name UsageDaterangeDetail
   * @summary get usage by api key and date range
   * @request GET:/apikey/{apiKey}/usage/daterange
   */
  usageDaterangeDetail = (
    apiKey: string,
    query: { start_date: string; end_date: string },
    params: RequestParams = {}
  ) =>
    this.request<ModelUsageSummary, any>({
      path: `/apikey/${apiKey}/usage/daterange`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get usage by api key and date range
   *
   * @tags example
   * @name UsageDaterangeDetail2
   * @summary get usage by api key and date range
   * @request GET:/apikey/{api_key}/usage/daterange
   * @originalName usageDaterangeDetail
   * @duplicate
   */
  usageDaterangeDetail2 = (
    apiKey: string,
    query: { start_date: string; end_date: string },
    params: RequestParams = {}
  ) =>
    this.request<ModelUsageSummary, any>({
      path: `/apikey/${apiKey}/usage/daterange`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
