import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { RiSwapFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import LogoBlinkerCenter from '../../components/app/LogoBlinkerCenter';
import { useClientPageSize } from '../../hooks/network';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import { convertNumberToFormatted, DateFormat } from '../../utils/date';
import { useMyApis, useCommunityApis } from '../api/api-hooks';
import StarButton from '../api/components/StarButton';
import { ACCESS_MAPPING } from '../api/const';
import ProfileAvatar from '../auth/ProfileAvatar';
import OnVisible from './components/OnVisible';

import RTAPIIcon from './components/RealtimeApiIcon';
import useAuth from '../../hooks/auth';
import ListFilters from './components/ListFilters';

export default function Apis() {
  const [{ menu, isMyWorkspace }, { getPath }] = useWorkspace();
  const [{ auth }] = useAuth();

  // my-workspace apis
  const [{ myApis, rpcStatus: myApisRpcStatus }, { getMyApis }] = useMyApis();

  const [myApisPageSize, { showMore: myApisShowMore, },] =
    useClientPageSize('apis-list');

  // community apis
  const [
    { communityApis, rpcStatus: communityApisRpcStatus },
    { getCommunityApis },
  ] = useCommunityApis();

  const [communityApisPageSize, { showMore: communityApisShowMore, }] =
    useClientPageSize('community-apis-list');

  const [apiType, setApiType] = useState('');

  // filter by keyword and creator
  const [keyword, setKeyword] = useState('');
  const [createdBy, setCreatedBy] = useState('all');
  const filteredItems = useMemo(() => {
    return (isMyWorkspace ? myApis : communityApis).items
      ?.filter(e => {
        if (createdBy && createdBy !== 'all') {
          return e.creator?.id === createdBy
        }
        return e
      })
      .filter(e => {
        if (e.displayName) {
          return e.displayName?.toLowerCase().includes(keyword.toLowerCase())
        }
        return e.id?.toLowerCase().includes(keyword.toLowerCase())
      }).filter(e => {
        if (apiType === '') {
          return e
        } if (apiType === 'realtime') {
          return e.resultDatabase?.startsWith('realtime');
        }
        return !e.resultDatabase?.startsWith('realtime');
      }).slice(
        0,
        isMyWorkspace ? myApisPageSize : communityApisPageSize
      )
  }, [keyword, createdBy, isMyWorkspace, myApis, communityApis, myApisPageSize, communityApisPageSize, apiType])

  useEffect(() => {
    if (isMyWorkspace) {
      getMyApis();
    } else {
      getCommunityApis();
    }
  }, [isMyWorkspace]);

  if (
    (isMyWorkspace && !myApis?.items?.length && myApisRpcStatus.isLoading) ||
    (!isMyWorkspace &&
      !communityApis?.items?.length &&
      communityApisRpcStatus.isLoading)
  ) {
    return (
      <div className='flex w-full items-center py-20 justify-center'>
        <LogoBlinkerCenter />
      </div>
    );
  }

  return (
    <div className='explorer flex flex-col w-full p-3 overflow-x-auto'>
      <ListFilters
        workspaceList={myApis}
        communityList={communityApis}
        isMyWorkspace={isMyWorkspace}
        auth={auth}
        onKeywordChange={setKeyword}
        onCreatorChange={setCreatedBy}
        onApiTypeChange={setApiType}
        apiType={apiType}
        creator={createdBy}
      />
      <table className='table w-full'>
        <thead className='rounded-full w-full'>
          <tr className='w-full uppercase font-semibold opacity-30'>
            <th className='px-4 py-2 w-full'>NAME</th>
            <th className='px-4 py-2'>
              {isMyWorkspace ? 'CREATED BY' : 'PUBLISHED BY'}
            </th>
            <th className='px-4 py-2'>
              <div className='inline items-center text-center'>
                {isMyWorkspace ? 'CREATED AT' : 'PUBLISH DATE'}
              </div>
            </th>
            {isMyWorkspace && (
              <th className='px-4 py-2'>
                <span className='flex items-center text-center'>
                  LAST UPDATED
                </span>
              </th>
            )}
            <th className='px-4 py-2'>STARS</th>
          </tr>
        </thead>
        <div className='h-2' />
        <tbody className='rounded-full w-full'>
          {(isMyWorkspace && isEmpty(myApis.items)) ||
            (!isMyWorkspace && isEmpty(communityApis.items) && (
              <td colSpan={1000} className='text-center opacity-30 p-20'>
                No APIs
              </td>
            ))}
          {filteredItems?.map(
            ({
              id,
              displayName,
              creator,
              updatedTime,
              createdTime,
              publishedTime,
              starCount,
              access,
              resultDatabase,
            }) => (
              <tr
                key={id}
                className='font-semibold hover:bg-primary hover:bg-opacity-10 hover:rounded-lg items-center overflow-hidden'
              >
                <td className='p-2 overflow-hidden'>
                  <Link key={id} to={`${getPath(menu)}/${id}`}>
                    <div className='flex flex-auto items-center text-lg space-x-2 pr-2 overflow-hidden w-[35rem]'>
                      {
                        resultDatabase?.startsWith('realtime') ? <RTAPIIcon /> :
                          <RiSwapFill
                            size='2rem'
                            className='text-primary flex-none'
                          />}
                      <span
                        title={displayName || id}
                        className='flex-grow-0 overflow-hidden whitespace-nowrap text-ellipsis'
                      >
                        {displayName || id}
                      </span>
                      {isMyWorkspace && access && (
                        <span className='self-center bg-base-content bg-opacity-10 py-[0.15rem] px-2 text-xs rounded-md opacity-50 font-semibold top-[0.4rem]'>
                          {ACCESS_MAPPING[access]}
                        </span>
                      )}
                      {
                        resultDatabase?.startsWith('realtime') ? <span className='self-center bg-base-content bg-opacity-10 py-[0.15rem] px-2 text-xs rounded-md opacity-50 font-semibold top-[0.4rem]'>
                          Realtime
                        </span> : ""
                      }
                    </div>
                  </Link>
                </td>
                <td className='p-2 overflow-hidden'>
                  <Link key={id} to={`${getPath(menu)}/${id}`}>
                    <div className='flex flex-auto w-[12rem] justify-start items-center gap-3'>
                      <ProfileAvatar
                        creator={creator}
                        className='flex-none w-6 bg-base-content bg-opacity-30'
                      />
                      <span
                        title={creator?.name || 'API Owner'}
                        className='flex-auto overflow-hidden whitespace-nowrap text-ellipsis'
                      >
                        {creator?.name || 'API Owner'}
                      </span>
                    </div>
                  </Link>
                </td>
                <td className='p-2 w-8 opacity-50 text-center'>
                  <Link key={id} to={`${getPath(menu)}/${id}`}>
                    <div>
                      {convertNumberToFormatted(
                        isMyWorkspace ? createdTime : publishedTime,
                        {
                          formatStr: DateFormat,
                        }
                      )}
                    </div>
                  </Link>
                </td>
                {isMyWorkspace && (
                  <td className='p-2 w-8 opacity-50 text-center'>
                    <Link key={id} to={`${getPath(menu)}/${id}`}>
                      <div>
                        {convertNumberToFormatted(updatedTime, {
                          formatStr: DateFormat,
                        })}
                      </div>
                    </Link>
                  </td>
                )}
                <td className='p-2 w-8 opacity-50 text-center'>
                  <StarButton
                    id={id}
                    isStarred={false} // TODO : replace after getting star value from API
                    starCount={starCount}
                    page={'api'}
                  />
                </td>
              </tr>
            )
          )}
          <OnVisible
            onVisible={() => {
              if (isMyWorkspace) {
                myApisShowMore();
              } else {
                communityApisShowMore();
              }
            }}
          />
        </tbody>
      </table>
    </div>
  );
}
