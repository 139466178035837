import { Layout } from 'react-grid-layout';
import {
  ModelGetDashboardResponse,
  ModelUpdateDashboardRequest,
} from '../../api/__gen__/data-contracts';
import { ChartEntity } from '../chart/types';
import { convertDBChartToUIChart } from '../chart/utils';

export type UiOptionsType = {
  layouts?: Layout[];
  widgets?: Record<string, MdWidgetType>;
  autoSave?: boolean;

  // react-grid-layout configs
  rowHeight?: number;
};

export type MdWidgetType = {
  id: string;
  displayName: string;
  type: 'md-text';
  mdContent: string;
};

export const DefaultGridConfigV2 = {
  rowHeight: 50,
};

export const DefaultGirdItemConfig = {
  w: 6,
  h: 6,
};

export const DefaultGirdItemConfigV3 = {
  w: 5,
  h: 6,
};

export type DashboardFormData = Omit<
  ModelGetDashboardResponse,
  'uiOptionsStr' | 'charts'
> & {
  id?: string;
  uiOptions: UiOptionsType;
  charts: ChartEntity[];
};

export type FormDataType = DashboardFormData | null;

export const DefaultDashboard: Partial<DashboardFormData> = {
  uiOptions: {
    autoSave: true,
    // setting newly created new values
    ...DefaultGridConfigV2,
  },
};

export function serializeUIOptions(uiOptions: UiOptionsType): string {
  return JSON.stringify(uiOptions);
}

export function parseUIOptionsStr(uiOptionsStr?: string): UiOptionsType {
  const defaultValue = { layouts: [] };
  try {
    return JSON.parse(uiOptionsStr || JSON.stringify(defaultValue));
  } catch (e) {
    // eslint-disable-next-line
    console.error('ignore paring error', uiOptionsStr, e);
  }

  return defaultValue;
}

export function convertUIDashboardToUpdateModel(
  formData: DashboardFormData
): ModelUpdateDashboardRequest {
  return {
    ...formData,
    uiOptionsStr: serializeUIOptions(formData.uiOptions),
    chartsIds: formData.charts.map((c) => c.id),
  };
}

export function convertUIDashboardToDBModel(
  formData: DashboardFormData
): ModelGetDashboardResponse {
  return {
    ...formData,
    uiOptionsStr: serializeUIOptions(formData.uiOptions),
    charts: formData.charts,
  };
}

export function convertDBDashboardToUIDashboard(
  dashboard: ModelGetDashboardResponse
): DashboardFormData {
  return {
    ...dashboard,
    id: dashboard.id || '',
    uiOptions: parseUIOptionsStr(dashboard.uiOptionsStr),
    charts: dashboard.charts?.map(convertDBChartToUIChart) || [],
  };
}
