import axios, { AxiosError } from 'axios';
import { sleep } from '../../../utils/debug';

export async function createConnection({
  formData,
  mock,
}: {
  formData: FormData;
  mock?: boolean;
}) {
  if (mock) {
    sleep(2);
    return {
      data: {
        job_id: '4a533072-6847-48c9-8600-05ade01ecb01',
        status: 'submitted',
      },
    };
  }
  try {
    // eslint-disable-next-line
    const res = await axios.post(
      'https://csv-api.zettablock.com/register_postgres',
      formData,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    return res;
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error.response?.data,
      };
    }
  }
  return { data: null };
}

export interface ConnectionStatus {
  status: 'queued' | 'processing' | 'finished' | 'errored';
}

export async function pollConnectionStatus({
  jobId,
}: {
  jobId: string;
  // eslint-disable-next-line
}): Promise<{
  data: ConnectionStatus | null;
}> {
  try {
    const resp = await axios.get(
      `https://csv-api.zettablock.com/connection-detail/${jobId}`
    );

    return new Promise((resolve, reject) => {
      try {
        if (resp.data.status !== 'finished' && resp.data.status !== 'errored') {
          setTimeout(async () => {
            try {
              const respT = await pollConnectionStatus({ jobId });
              resolve(respT);
            } catch (error) {
              reject(error);
            }
          }, 3000);

          return;
        }

        resolve(resp);
      } catch (error) {
        // eslint-disable-next-line
        console.error('polling error', error);
        reject(error);
      }
    });
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
  }

  return { data: null };
}
