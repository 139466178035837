import CodeEditor from "./CodeEditor";

const ResponseSnippet = ({ response }: {
  response: string
}) => {
  return <div className="border rounded overflow-hidden">
    <div className="font-semibold text-[14px] mb-2 px-3 py-2 bg-[#fafafa]">
      Response
    </div>
    <div className="mt-4">
      <CodeEditor codeSnippet={response} />
    </div>
  </div>
}

export default ResponseSnippet;