/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo, useEffect, useState } from 'react';
import {
  RiHomeFill,
  RiSettings4Line,
  RiShareBoxFill,
  RiSwapFill,
} from 'react-icons/ri';
import { formatDistanceToNow } from 'date-fns';
import { BiCopy, BiGitRepoForked, BiRocket } from 'react-icons/bi';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { NavLink, Route, Routes, useNavigate } from 'react-router-dom';
import { useEditApi } from './ViewApiHooks';
import { DefaultAutoDismissMs } from '../../config/toast';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import envs from '../../config/envs';
import ViewApiOverview from './ViewApiOverview';
import ApiSettingModal from './ApiSettingModal';
import ApiPublishModal from './ApiPublishModal';
import { useApiClone } from './ApiCloneHooks';
import ApiUnpublishModal from './ApiUnpublishModal';
import ApiInfoModal from './ApiInfoModal';
import ViewApiLogs from './ViewApiLogs';
import GraphqlV3 from '../dataset/tabs/GraphqlV3';
import useAuth from '../../hooks/auth';
import StarButton from './components/StarButton';
import { ACCESS_MAPPING } from './const';
import { COMMUNITY } from '../auth/AuthState';
import Modal from '../../components/modals/Modal';
import TriggerModal from './TriggerModal';


import RTAPIIcon from '../explorer/components/RealtimeApiIcon';
import { Triggers } from './Triggers';
import { ApiView } from '../../api/client';
// import useFeatures from '../../hooks/useFeatures';
import { AlphaBadge } from '../../components/badges/AlphaBadge';
import useTitle from '../../hooks/useTitle';
import { analytics } from '../../App';




const TAB_LINKS = [
  {
    path: 'overview',
    title: 'Overview',
    icon: <RiHomeFill />,
  },
  {
    path: 'playground',
    title: 'Playground',
    icon: <RiHomeFill />,
  },
  {
    title: 'Logs',
    path: 'logs',
    icon: <RiHomeFill />,
  },
  {
    title: 'Triggers',
    path: 'triggers',
    icon: <RiHomeFill />,
  },
];

function ViewApi() {
  const navigate = useNavigate();

  const [{ apiId, rpcStatus, values, rpcStatusDel }, { deleteApi }] =
    useEditApi();
  const [{ menu, isMyWorkspace }, { getPath }] = useWorkspace();

  const [data, setData] = useState<any>([])

  useEffect(() => {
    const fetchData = async () => {
      if (!apiId) return
      const client = ApiView()
      const res = await client.triggersDetail(apiId)

      setData(res.data.items || [])
    }

    fetchData()
  }, [apiId])

  useEffect(() => {
    if (values.database?.startsWith('realtime')) {
      analytics.track('API - RealtimeDB API Viewed', {})
    }
  }, [values])


  const handleSave = (type: string) => {
    setTimeout(() => {
      navigate(`${getPath(menu)}/${apiId}/triggers`)
      toast.success(`Trigger ${type} succeeded.`)
      const fetchData = async () => {
        if (!apiId) return
        const client = ApiView()
        const res = await client.triggersDetail(apiId)

        setData(res.data.items || [])
      }

      fetchData()
    }, 2000);
  }




  const [openSetting, setOpenSetting] = useState(false);
  const [openPublish, setOpenPublish] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  // TODO repalce api clone
  const [clone, { isCloning }] = useApiClone({
    queryPath: `${getPath(menu)}`,
  });

  // @ts-ignore
  const published = values.access === 'public';
  const [{ auth }, { getIsOwner }] = useAuth();
  const isAuthor = getIsOwner(values);

  useTitle('API', values.displayName)

  return (
    <>
      <div className='flex'>
        <div className='flex-auto flex items-center space-x-3 overflow-hidden'>
          <div>
            {values.resultDatabase?.startsWith('realtime') ? <div className='flex items-center bg-[#261be6] rounded pr-3 pl-1 py-1'><RTAPIIcon fill={"#fff"} height={21} /><span className='text-sm font-semibold text-[#fff]'>REAL-TIME</span> </div> : <RiSwapFill size='2.2rem' className='text-primary' />}

          </div>
          <div className='flex items-center space-x-2 overflow-hidden pr-2'>
            <span
              title={values.displayName}
              className='flex-auto font-bold text-lg overflow-hidden text-ellipsis whitespace-nowrap'
            >
              {values.displayName}
            </span>
            {values.createdTime && (
              <span className='text-sm opacity-50 flex-shrink-0'>
                published{' '}
                {formatDistanceToNow(values.createdTime, {
                  addSuffix: true,
                })}
              </span>
            )}
            {isMyWorkspace && values.access && (
              <span className='self-center bg-base-content bg-opacity-10 py-[0.1rem] mx-2 px-1 text-[0.7rem] rounded-md opacity-50 font-semibold'>
                {ACCESS_MAPPING[values.access]}
              </span>
            )}
          </div>
        </div>

        <div className='flex items-center flex-none'>
          <div className='flex space-x-3'>
            {values.state !== 'Deleted' ? (
              apiId && (
                <>
                  {isMyWorkspace && isAuthor && (
                    <>
                      <button
                        className='btn btn-primary gap-2'
                        onClick={() => {
                          setOpenSetting(!openSetting);
                        }}
                      >
                        <RiSettings4Line size='1.1rem' />
                        Setting
                      </button>
                      {openSetting && (
                        <ApiSettingModal
                          openSetting={openSetting}
                          setOpenSetting={setOpenSetting}
                        />
                      )}

                      {
                        !envs.isCircleIntegrationEnabled && (
                          published ? (
                            <div
                              className={
                                classNames({
                                  'tooltip tooltip-warning opacity-90':
                                    auth?.tenant === COMMUNITY,
                                })}
                              data-tip='Please upgrade the plan to make userapi private'
                            >
                              <button
                                className='published-button btn btn-primary gap-2 hover:btn-error font-bold'
                                disabled={auth?.tenant === COMMUNITY}
                                onClick={() => {
                                  setOpenPublish(!openPublish);
                                }}
                              >
                                <BiRocket size='1.1rem' />
                                {auth?.tenant === COMMUNITY ? (
                                  <span>Unpublish</span>
                                ) : (
                                  <>
                                    <span className='when-not-hover'>
                                      Published
                                    </span>
                                    <span className='when-hover'>Unpublish</span>
                                  </>
                                )}
                              </button>
                              {openPublish && (
                                <ApiUnpublishModal
                                  openPublish={openPublish}
                                  setOpenPublish={setOpenPublish}
                                />
                              )}
                            </div>
                          ) : (
                            <>
                              <button
                                className='btn btn-primary gap-2'
                                onClick={() => {
                                  setOpenPublish(!openPublish);
                                }}
                              >
                                <BiRocket size='1.1rem' />
                                <span>Publish</span>
                              </button>
                              {openPublish && (
                                <ApiPublishModal
                                  openPublish={openPublish}
                                  setOpenPublish={setOpenPublish}
                                />
                              )}
                            </>
                          ))
                      }

                      <button
                        className='btn btn-warning'
                        onClick={() => {
                          setOpenDelete(true);
                        }}
                      >
                        <span>Delete</span>
                      </button>

                      <div className='border-r my-[0.2rem] border-gray-300' />
                    </>
                  )}
                  {!isMyWorkspace && isAuthor && (
                    <>
                      <button
                        className='btn btn-primary gap-2'
                        onClick={() => {
                          navigate(`/my-workspace/${menu}/${apiId}`);
                        }}
                      >
                        Edit
                      </button>
                      <div className='border-r my-[0.2rem] border-gray-300' />
                    </>
                  )}
                  <span className='hidden'>
                    <button
                      className={classNames(
                        'flex btn btn-primary items-center gap-2',
                        {
                          hidden: !apiId,
                          loading: isCloning,
                        }
                      )}
                      onClick={() => {
                        clone();
                      }}
                    >
                      <BiGitRepoForked size='1.1rem' />
                    </button>
                  </span>
                  <StarButton
                    id={apiId}
                    isStarred={values?.star}
                    starCount={values?.starCount}
                    isListView={false}
                    page={'api'}
                  />
                  <CopyToClipboard text={window.location.href}>
                    <button
                      className='flex btn btn-primary items-center gap-2'
                      onClick={() => {
                        toast.success('Share link copied', {
                          autoClose: DefaultAutoDismissMs,
                        });
                      }}
                    >
                      <RiShareBoxFill size='1.1rem' />
                    </button>
                  </CopyToClipboard>
                </>
              )
            ) : (
              <div
                className='badge-lg badge badge-outline cursor-pointer tooltip'
                data-tip='API cannot be queried once deleted. Also you can still view the deleted API in the API history under Usage portal.'
              >
                Deleted
              </div>
            )}
          </div>
        </div>
        {/* <div className='flex items-center'>
          <div className='flex space-x-3'>
            <button
              className='btn btn-primary gap-2'
              onClick={() => {
                updateApi();
                // createNewApi();
              }}
            >
              <RiSkipForwardLine size='1.1rem' />
              Create API
            </button>
          </div>
        </div> */}
      </div >
      <div className='h-6' />

      <div className='flex min-w-[40rem]'>
        <div className='rounded-l-lg inline-block border-r bg-primary-content'>
          <span
            className='inline-block text-xs opacity-30 font-semibold px-1 pb-3 pt-1 text-center justify-center'
            style={{ writingMode: 'vertical-lr', transform: 'rotate(180deg)' }}
          >
            INFO
          </span>
        </div>
        <div className='rounded-r-lg bg-primary-content text-sm'>
          <div className='flex flex-col gap-1 py-2 px-3'>
            <div className='text-xs font-bold opacity-30'>GraphQL URL</div>
            <CopyToClipboard
              text={`${envs.ZettaOpenApi}/dataset/${apiId}/graphql`}
            >
              <div
                className='flex gap-2 items-center cursor-pointer'
                onClick={() => {
                  setOpenInfoModal(true);
                }}
              >
                <div className='flex-1'>{`${envs.ZettaOpenApi}/dataset/${apiId}/graphql`}</div>
                <BiCopy size='.8rem' className='opacity-40' />
              </div>
            </CopyToClipboard>

            {openInfoModal && (
              <ApiInfoModal open={openInfoModal} setOpen={setOpenInfoModal} />
            )}
          </div>
        </div>
      </div>

      <div className='h-6' />

      <div className='lg:basis-2/5 basis-full bg-base-100 rounded-xl'>
        <div className='flex border-b'>
          <div className='flex py-2 px-4 items-center'>
            {TAB_LINKS.filter(e => {
              if ((!values.database?.startsWith('realtime') && values.writeMode !== 'Append') && e.title === 'Triggers') {
                return false
              }
              return true
            }).map((tab) => (
              <NavLink
                key={tab.title}
                className={({ isActive }) =>
                  classNames(
                    'flex mr-4 px-4 py-2 items-center rounded-xl',
                    isActive
                      ? 'bg-base-content bg-opacity-10 text-primary font-bold'
                      : 'opacity-40'
                  )
                }
                to={`${getPath(menu)}/${apiId}/${tab.path}`}
                onClick={() => {
                  analytics.track(`API - ${tab.title} Tab Clicked - ${values.database?.startsWith('realtime') ? "Realtime" : "Normal"}`, {
                    apiId
                  })
                }}
              >
                {tab.icon} <span className='ml-2'>{tab.title}</span> {tab.title === 'Triggers' && <AlphaBadge className='badge-sm ml-2 mb-2' />}
              </NavLink>
            ))}
          </div>
        </div>

        <Routes>
          <Route path='playground' element={<GraphqlV3 />} />
          <Route path='logs' element={<ViewApiLogs />} />
          <Route
            path='overview'
            element={<ViewApiOverview rpcStatus={rpcStatus} />}
          />
          {
            <><Route path="triggers" element={<Triggers apiId={apiId || ''} data={data} baseUrl={`${getPath(menu)}/${apiId}/`} />} />
              <Route path="create-trigger" element={<TriggerModal apiId={apiId} onSave={handleSave} />} />
              <Route path="edit-trigger/:triggerId" element={<TriggerModal baseUrl={`${getPath(menu)}/${apiId}/`} apiId={apiId} onSave={handleSave} />} /></>
          }
        </Routes>
      </div>

      <Modal
        open={openDelete}
        className='flex flex-col space-y-4'
        onClickBackdrop={() => setOpenDelete(false)}
      >
        <h3 className='flex items-center space-x-2 font-bold text-lg'>
          <span>Archiving the API</span>
        </h3>
        <div className='flex-1 pb-2 overflow-y-auto max-h-[50vh] space-y-1'>
          Are you sure? It cannot be queried once deleted. Also you can still
          view the deleted API in the API history under Usage portal.
        </div>
        <div className='modal-action items-center'>
          <button
            type='button'
            className='btn btn-outline'
            onClick={async () => {
              setOpenDelete(false);
            }}
          >
            CANCEL
          </button>
          <button
            type='button'
            className={classNames('btn btn-warning', {
              loading: rpcStatusDel.isLoading,
            })}
            onClick={async () => {
              const success = await deleteApi();
              if (success) {
                setOpenDelete(false);
                navigate('/my-workspace/apis');
              }
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
}

export default memo(ViewApi);