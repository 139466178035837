import classNames from "classnames";
import chainIcon from './chain.svg'

import { useState } from "react";
import { PoProject } from "../../api/__gen__/data-contracts";
import { ExploreDataEnum, useExploreData } from "../useExploreData";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Link } from "react-router-dom";
import Icon from "../components/Icon";
import { CHAINS, LABELS, LabelTags } from "./ExploreTablesV3";
import { GRAPHQL_APIS } from "../Explore";
// import Icon from "v2/components/Icon";


interface TagProps {
  name: string;
  id: string;
  image?: string;
  className?: string;
  isActive?: boolean;
  activeClassName?: string;
  onClick?: (id: string) => void;
  activeName?: string;
}
export const Tag = ({
  name,
  activeName,
  id,
  image,
  className,
  activeClassName,
  isActive,
  onClick
}: TagProps) => {

  return <div className={classNames(
    'flex h-[24px] px-[8px] py-[4px] rounded-full items-center gap-[5px] cursor-pointer w-auto',
    className && {
      [className]: !isActive
    },
    activeClassName && {
      [activeClassName]: isActive
    }
  )}
    onClick={() => { onClick?.(id) }}
  >
    {!image ? <Icon iconName={isActive ? (activeName || name) : name} className="w-[14px] h-[14px]" /> : <img src={image} className="w-[14px] h-[14px]" />}
    <div className="text-[12px] leading-[12px]">
      {name}
    </div>
  </div>
}

interface TagProps {
  name: string;
  id: string;
}
interface ChainTagsProps {
  chains: TagProps[];
  selectedChains: string[];
  onSelectChain: (chain: string) => void;
}

const ChainTags = ({
  chains,
  selectedChains,
  onSelectChain,
}: ChainTagsProps) => {

  return <div className="w-[1100px] min-w-[1100px] flex flex-wrap gap-[13px]">
    {
      chains.map((chain, index) => {
        return <Tag
          key={index}
          onClick={onSelectChain}
          id={chain.id}
          name={chain.name}
          isActive={selectedChains.includes(chain.id)}
          className="bg-[#F3F3F3] text-[#282828]"
          activeClassName="bg-[#243AF3] text-[#ffffff]"
        />
      })
    }
  </div>
}


const Separator = ({ text }: {
  text: string
}) => {

  return <div className="flex items-center gap-[14px]">
    <div className="text-[#777A80] text-[14px]">
      {text}
    </div>
    <div className="flex-1 h-[1px] bg-[#E3E6ED]"></div>
  </div>
}



export const TagDisplay = ({
  onClick,
  active,
  id,
  name,
  className
}: {
  onClick?: (chain: string) => void
  active: boolean
  id: string
  name: string,
  className?: string
}) => {
  return <div
    onClick={() => { onClick?.(id) }}
    className={
      classNames('rounded flex py-[6px] px-[10px] items-center gap-[4px] bg-[#fff] text-[14px] leading-[12px] cursor-pointer', {
        'bg-[#263dff] text-[#fff]': active,
      },
        className
      )
    }
  >
    <img src={chainIcon} className="w-[20px] h-[20px]" />
    {name}
  </div>
}

interface ApiCardProps {
  apiProject: PoProject
}
const ApiCard = ({
  apiProject
}: ApiCardProps) => {
  return <Link to={`/v2/explore/graphql/${apiProject?.id}?from=explore`}>
    <div className="rounded bg-[#fff] w-[520px] h-[150px] flex gap-[15px] p-[10px] hover:bg-[#fafafa] cursor-pointer">
      <img className="w-[50px] h-[50px] object-contain" src={apiProject.image} />
      <div className="flex flex-col justify-between flex-1">
        <div>
          <div className="mb-[8px] font-semibold text-[16px]">
            {apiProject.name}
          </div>
          <div className="text-[14px] text-[#777A80] line-clamp-3">
            {apiProject.detail}
          </div>
        </div>
        <div className="flex gap-[10px] items-center">
          <div>
            <Tag
              name="Ethereum"
              image={apiProject.image}
              id="ethereum"
              className="bg-[#F3F3F3] text-[#282828]"
            />
          </div>
          {/* {apiProject.chains?.length && <div className="pr-[0.5em]">
            <OverlapImages
              className="w-[20px] h-[20px] rounded-full object-contain"
              images={[apiProject.image || '']}
            />
          </div>} */}
          <div className="text-[12px] font-semibold text-[#B7BAC2]">
            1 API
          </div>
        </div>
      </div>
    </div>
  </Link>
}

const ExploreApisV2 = () => {

  const [selectedChains, setSelectedChains] = useState<string[]>([])


  const onSelectChain = (chain: string) => {
    if (selectedChains.includes(chain)) {
      setSelectedChains(selectedChains.filter((c) => c !== chain))
    } else {
      setSelectedChains([chain])
    }
  }
  const [selectedLabels, setSelectedLabels] = useState<string[]>([])


  const onSelectLabel = (label: string) => {
    if (selectedLabels.includes(label)) {
      setSelectedLabels(selectedLabels.filter((c) => c !== label))
    } else {
      setSelectedLabels([label])
    }
  }

  const {
    // publicApis,
    // refecth,
    isLoading
  } = useExploreData([ExploreDataEnum.APIS])

  const filteredApis = GRAPHQL_APIS.filter((api) => {
    if (selectedChains.length === 0 && selectedLabels.length === 0) return true

    if (api.chains?.some((chain) => selectedChains.map(e => e.toLocaleLowerCase()).includes(chain.toLowerCase()))) {
      return true
    }

    if (api.labels?.some((label) => selectedLabels.map(e => e.toLocaleLowerCase()).includes(label.toLowerCase()))) {
      return true
    }

    return false
  })

  const renderApiProjects = () => {
    if (isLoading) {
      return <div className="h-[300px] w-full flex justify-center items-center">
        <LoadingIndicator />
      </div>
    }



    return <div className="flex flex-wrap gap-[60px] gap-y-[30px]" id="prebuilt-api">
      {
        filteredApis.map((api, index) => {
          return <ApiCard key={index} apiProject={api} />
        })
      }
    </div>
  }


  return <div className="min-w-[1240px] w-[1240px] px-[70px] py-[40px] mx-auto">
    {/* Title */}
    <div className="text-left mb-[24px] font-semibold">
      <div className="text-[24px] text-[#2B2F33]leading-[24px]">
        APIs
      </div>
    </div>

    {/* Labels Filter */}
    <div className="mb-[8px]">
      <LabelTags labels={LABELS} selectedLabels={selectedLabels} onSelectLabel={onSelectLabel} />
    </div>
    {/* Chains Filter */}
    <div className="mb-[50px]">
      <ChainTags chains={CHAINS} selectedChains={selectedChains} onSelectChain={onSelectChain} />
    </div>


    {/* Separator */}
    <div className="mb-[20px]">
      <Separator text={`${filteredApis.length} Pre-Built API Projects`} />

    </div>

    {/* Api Cards */}
    {
      renderApiProjects()
    }


  </div>
}

export default ExploreApisV2;