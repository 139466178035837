import { BsAsterisk } from 'react-icons/bs';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router';
import { HorizontalLine } from '../../../components/HorizontalLine';
import PaleButton from '../../../components/interactive/PaleButton';
import mongodb from '../../../assets/icons/mongo.svg';
import { HTMLAttributes, useEffect, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import usePrompt from '../../../hooks/router';
import { createMongoConnection, pollMongoConnectionStatus } from './client';
import { capitalize } from 'lodash';
import useAuth from '../../../hooks/auth';

function RequiredIcon({ className }: { className?: string }) {
  return (
    <BsAsterisk
      size='0.7rem'
      className={classNames('text-error mt-3', className)}
    />
  );
}

function InputGroup({
  label,
  children,
}: HTMLAttributes<HTMLDivElement> & { label?: string }) {
  return (
    <div className='flex space-x-4'>
      <span className='font-semibold w-1/5 flex-shrink-0'>{label}</span>
      <RequiredIcon />
      <div className='w-full'>{children}</div>
    </div>
  );
}

const defaultValues = {} as Record<string, string>;

interface JobStatus {
  job_id?: string;
  status?: string;
}

// TODO refactor useForm
export default function Mongodb() {
  const navigate = useNavigate();
  const [authSource, setAuthSource] = useState(
    defaultValues?.auth_source || ''
  );
  const [database, setDatabase] = useState(defaultValues?.database || '');
  const [clusterUrl, setClusterUrl] = useState(
    defaultValues?.cluster_url || ''
  );
  const [mongoUsername, setMongoUsername] = useState(
    defaultValues?.mongo_user || ''
  );
  const [password, setPassword] = useState(defaultValues?.password || '');

  const [status, setStatus] = useState<JobStatus>();

  const [{ auth, basicAuth }] = useAuth();

  const submit = async () => {
    toast.dismiss();

    const username = auth?.email || basicAuth?.username;

    if (!username) {
      toast.warn('Please login first');
      return;
    }

    if (
      !authSource ||
      !database ||
      !clusterUrl ||
      !mongoUsername ||
      !password
    ) {
      toast.warn('Please check input values (invalid values)');
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append('auth_source', authSource);
    bodyFormData.append('database', database);
    bodyFormData.append('cluster_url', clusterUrl);
    bodyFormData.append('user', mongoUsername);
    bodyFormData.append('mongo_user', mongoUsername);
    bodyFormData.append('password', password);

    // TODO: make sure connection api adapt to auth v2
    bodyFormData.append('username', 'aeowief');

    const res = await createMongoConnection({
      // mock: true,
      formData: bodyFormData,
    });

    const error = res?.data?.error;

    if (error) {
      toast.error(error);
    } else if (res?.data?.job_id) {
      setStatus(res.data);
    } else {
      toast.warning('request failed');
    }
  };

  useEffect(() => {
    async function run(jobId: string) {
      setStatus({
        ...status,
        status: 'processing',
      });

      const res = await pollMongoConnectionStatus({ jobId });

      setStatus({
        ...status,
        status: res?.data?.status,
      });
    }

    if (status?.job_id && status?.status === 'submitted') {
      // start polling
      run(status.job_id);
    }
  }, [status?.status]);

  const isProcessing =
    !!status?.job_id &&
    status?.status !== 'finished' &&
    status?.status !== 'errored';

  usePrompt(
    'The connection is being created. Leaving this page would not cancel it. Still want to leave?',
    isProcessing
  );

  return (
    <>
      <div className='flex px-6 py-3 items-center'>
        <PaleButton onClick={() => navigate(-1)}>
          <IoIosArrowBack size='1.8rem' />
          <div className='w-3' />
          Back
        </PaleButton>
        <div className='flex-1' />
        <button className='btn btn-primary hidden'>Save and Test</button>
      </div>
      <HorizontalLine />
      <div className='flex flex-col'>
        <div className='flex'>
          <div className='flex-1'>
            <div className='py-3 px-6 flex items-center'>
              <img src={mongodb} className='w-10' />
              <div className='w-3' />
              <span className='text-xl'>MongoDB</span>
            </div>
          </div>
          <div className='flex-1 flex items-center text-xl bg-base-200'>
            <div className='p-5'>Setup Guide</div>
          </div>
        </div>
        <HorizontalLine />
        <div className='flex'>
          <div className='flex-1 flex'>
            <div className='flex-1 p-5 flex flex-col space-y-6'>
              <InputGroup label='Cluster URL'>
                <input
                  className='input input-bordered w-full'
                  readOnly={isProcessing}
                  type='text'
                  placeholder='Your Cluster Url'
                  onChange={(e) => setClusterUrl(e.target.value)}
                  defaultValue={clusterUrl}
                />
                <div className='h-2' />
                <span className='text-sm font-light'>
                  The URL of a cluster to connect to (we support MongoDB Altas)
                </span>
              </InputGroup>
              <InputGroup label='Database Name'>
                <input
                  className='input input-bordered w-full'
                  readOnly={isProcessing}
                  type='text'
                  placeholder='Your database name'
                  onChange={(e) => setDatabase(e.target.value)}
                  defaultValue={database}
                />
                <div className='h-2' />
                <span className='text-sm font-light'>
                  The database you want to replicate.
                </span>
              </InputGroup>
              <InputGroup label='User'>
                <input
                  className='input input-bordered w-full'
                  readOnly={isProcessing}
                  type='text'
                  placeholder='Your MongoDB Username'
                  onChange={(e) => setMongoUsername(e.target.value)}
                  defaultValue={mongoUsername}
                />
                <div className='h-2' />
                <span className='text-sm font-light'>
                  The username which is used to access the database.
                </span>
              </InputGroup>
              <InputGroup label='Password'>
                <input
                  className='input input-bordered w-full'
                  readOnly={isProcessing}
                  type='password'
                  placeholder='Your Password'
                  onChange={(e) => setPassword(e.target.value)}
                  defaultValue={password}
                />
                <div className='h-2' />
                <span className='text-sm font-light'>
                  The password associated with this username.
                </span>
              </InputGroup>
              <InputGroup label='Authentication Source'>
                <input
                  className='input input-bordered w-full'
                  readOnly={isProcessing}
                  type='text'
                  placeholder='Your authentication source'
                  onChange={(e) => setAuthSource(e.target.value)}
                  defaultValue={authSource}
                />
                <div className='h-2' />
                <span className='text-sm font-light'>
                  The authentication source where the user information is
                  stored. (e.g. admin)
                </span>
              </InputGroup>
              <div className='flex space-x-4'>
                <span className='font-bold w-1/5 flex-shrink-0 opacity-0'></span>
                <RequiredIcon className='opacity-0' />
                <button
                  className={classNames('btn btn-primary', {
                    loading: isProcessing,
                  })}
                  onClick={() => submit()}
                >
                  Submit
                </button>
              </div>
              {status?.job_id && (
                <div className='flex space-x-4'>
                  <span className='font-bold w-1/5 flex-shrink-0 opacity-0'></span>
                  <RequiredIcon className='opacity-0' />
                  <div>Status: {capitalize(status?.status)}</div>
                </div>
              )}
            </div>
          </div>
          <div className='flex-1 bg-base-200 max-h-[80vh] overflow-y-auto'>
            <div className='p-5 space-y-3 flex flex-col'>
              <span>
                The MongoDB source allows to sync data from MongoDB Atlas.
                Source supports Full Refresh Sync. We suggest creating a
                “read-only” user specifically for ZettaBlock with access to only
                the data you need in ZettaBlock.
              </span>
              <HorizontalLine />
              <span className='text-xl font-bold'>Resulting Schema</span>
              <span>
                MongoDB does not have anything like table definition, thus we
                have to define column types from actual attributes and their
                values. Discover phase have two steps:
              </span>
              <div className='flex items-center space-x-3'>
                <span className='badge badge-primary badge-lg'>Step 1</span>{' '}
                <span className='text-lg font-semibold'>
                  Find all unique properties
                </span>
              </div>
              <span>
                Connector select 10k documents to collect all distinct fields.
              </span>
              <div className='flex items-center space-x-3'>
                <span className='badge badge-primary badge-lg'>Step 2</span>{' '}
                <span className='text-lg font-semibold'>
                  Determine property types
                </span>
              </div>
              <span>
                For each property found, the connector determines its type, if
                all the selected values have the same type - the connector will
                set the appropriate type to the property. In all other cases,
                the connector will fall back to
                <code className='bg-base-300 text-error mx-2'>string</code>{' '}
                type.
              </span>
              <HorizontalLine />
              <span className='text-xl font-bold'>Getting Started</span>
              <span>
                This guide describes in detail how you can configure MongoDB for
                integration with ZettaBlock.
              </span>
              <span className='text-lg font-semibold'>Create users</span>
              <span>
                Run <code className='bg-base-300 text-error mx-2'>mongo</code>
                shell, switch to
                <code className='bg-base-300 text-error mx-2'>admin</code>
                database, and create a
                <code className='bg-base-300 text-error mx-2'>
                  READ_ONLY_USER
                </code>
                used for ZettaBlock integration. Please make sure that the user
                has read-only privileges.
                <p className='bg-white p-3 rounded text-sm my-3 w-full overflow-auto'>
                  <code>
                    {`mongo\nuse admin;\ndb.createUser({user: "READ_ONLY_USER", pwd: "READ_ONLY_PASSWORD", roles: [{role: "read", db: "TARGET_DATABASE"}]})`}
                  </code>
                </p>
              </span>
              <span className='text-lg font-semibold'>
                Enable MongoDB Authentication
              </span>
              <span>
                Open
                <code className='bg-base-300 text-error mx-2'>
                  /etc/mongo.conf
                </code>
                and add/replace specific keys
                <p className='bg-white p-3 rounded text-sm my-3 w-full overflow-auto'>
                  <code>
                    {`net:\n\tbindIp: 0.0.0.0\n\nsecurity:\n\tauthorization: enabled`}
                  </code>
                </p>
              </span>
              <span>
                Binding to
                <code className='bg-base-300 text-error mx-2'>0.0.0.0</code>
                will allow connecting to the database from any IP address.
              </span>
              <span>
                The last line will enable MongoDB security. Now only
                authenticated users will be able to access the database.
              </span>
              <span className='text-lg font-semibold'>Configure firewall</span>
              <span>
                Make sure that MongoDB is accessible from external servers.
                Specific commands will depend on the firewall you are using
                (UFW/iptables/AWS/etc). Please refer to appropriate
                documentation.
              </span>
              <span className='text-lg font-semibold'>
                TLS/SSL on a Connection
              </span>
              <span>
                It is recommended to use an encrypted connection. Connection
                with TLS/SSL security protocol for MongoDb Atlas Cluster is
                enabled by default.
              </span>
              <span className='text-lg font-semibold'>
                Сonfiguration Parameters
              </span>
              <ol className='list-disc ml-5'>
                <li>Database: database name</li>
                <li>
                  Authentication Source: specifies the database that the
                  supplied credentials should be validated against. Defaults to{' '}
                  <code className='bg-base-300 text-error mx-2'>admin</code>.
                </li>
                <li>User: username to use when connecting</li>
                <li>Password: used to authenticate the user</li>
                <li>
                  Cluster URL: URL of a MongoDb Atlas Cluster to connect to
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
