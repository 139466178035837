/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
import classNames from "classnames";
import styles from './Billing.module.scss'
import Icon from "../components/Icon";
import useAuth from "../../hooks/auth";
import { useEffect, useState } from "react";
import { PaymentApi } from "../../api/client";
import { ModelGetPaymentSubscriptionDetailsResponse, ModelListPaymentMethodsResponse, ModelPaymentMethod } from "../../api/__gen__/data-contracts";
import { addPaymentMethod, getBillingHistory, getPaymentMethods, getProducts, removePaymentMethod, setDefaultPaymentMethod, subscriptionDowngrade, subscriptionUpgrade } from "../onboarding/api";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import CreditCardInfo from "../onboarding/CreditCardInfo";
import { DatePicker, Modal, Popconfirm, Table, Tag } from "antd";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import { IoCheckmark } from "react-icons/io5";
import envs from "../../config/envs";
import { UserAuth } from "../../modules/auth/AuthState";
import LoadingIndicator from "../../components/LoadingIndicator";

const INFO_TITLE = {
  free: "Free access to: ",
  builder: "Everything in Free, plus:",
  enterprise: "Everything in Build, plus:"
}
const INFO = {
  free: [
    "50 Million compute units",
    "Build your own GraphQL API (non-realtime)",
    "1 request per second",
    "Access to 12+ chains data",
    "Access to data/ APIs/ queries/ dashboards",
    "Unlimited queries/ charts/ dashboards",
    "Discord support",
    "10MB data download per day",
  ],
  builder: [
    "100 Million compute units",
    "1 real-time GraphQL APIs built",
    "5 requests per second",
    "CSV downloads 15 times",
    "Auto-scaling at $6 / additional million CUs",
    "Discord support",
    "1 GB data download per day",
  ],
  enterprise: [
    "Custom compute units",
    "Custom GraphQL APls built",
    "Custom data ETL pipelines",
    "CSV data upload",
    "Unlimited CSV data Downloads",
    "Committed use discounts",
    "Custom SLAs",
    "400+ requests per second",
    "Custom data limit for Data Lake APIs",
    "Custom ETL pipeline sync frequency",
    "Premium Onboarding and Support",
  ]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PlanCard = ({ type, onUpgrade, currentPlan, loading, onDowngrade }: { currentPlan: boolean; type: string, onUpgrade: () => void; loading: boolean, onDowngrade: () => void }) => {

  if (type === 'free') {
    return <div className="p-[15px] min-w-[305px] w-[305px] h-[437px] rounded-[12px] border ">
      <div className="font-semibold text-[20px] text-[#000] leading-[24px] ">
        {
          'Free Plan'
        }
      </div>
      <div className="text-[12px] text-[#777A80] leading-[15px] mt-[5px] mb-[20px]">
        Great for developers, traders, or anyone getting started.
      </div>

      <div className="cursor-pointer text-[#263DFF] text-[22px] font-bold mb-[15px]">
        Free
      </div>

      <div className={classNames("cursor-pointer text-center rounded-[4px] bg-[#263DFF] text-[#fff] leading-[16px] text-[14px] py-[14px]", {
        'bg-[#333]': currentPlan
      })}
        onClick={() => {
          if (currentPlan) return
          // window.open('mailto:sales@zettablock.com')
          onDowngrade()
        }}
      >
        {
          currentPlan ? 'Current Plan' : 'Downgrade'
        }
      </div>

      <div className="mt-[20px]">
        <div className="text-[12px] text-[#777A80] leading-[18px]">
          {
            INFO_TITLE[type]
          }
        </div>
        <ul>
          {
            INFO[type].map((info: string, index: number) => {
              return <li key={index} className="flex gap-[5px] items-center pl-[5px]">
                <div className="w-[4px] h-[4px] bg-[#777A80] rounded-full"></div>
                <div className="text-[12px] text-[#777A80] leading-[18px]">
                  {info}
                </div>
              </li>
            })
          }
        </ul>
      </div>


    </div>
  }

  if (type === 'enterprise') {
    return <div className="p-[15px] min-w-[305px] w-[305px] h-[437px] rounded-[12px] border ">
      <div className="font-semibold text-[20px] text-[#000] leading-[24px] ">
        {
          'Enterprise'
        }
      </div>
      <div className="text-[12px] text-[#777A80] leading-[15px] mt-[5px] mb-[20px]">
        {
          "Volume pricing and enterprise support for institutional use cases."
        }
      </div>

      <div className="cursor-pointer text-[#263DFF] text-[22px] font-bold mb-[15px]">
        Contact Support
      </div>

      <div className="cursor-pointer text-center rounded-[4px] bg-[#263DFF] text-[#fff] leading-[16px] text-[14px] py-[14px]"
        onClick={() => {
          window.open('mailto:sales@zettablock.com')
        }}
      >
        {
          currentPlan ? 'Current Plan' : "Contact Us"
        }
      </div>

      <div className="mt-[20px]">
        <div className="text-[12px] text-[#777A80] leading-[18px]">
          {
            INFO_TITLE[type]
          }
        </div>
        <ul>
          {
            INFO[type].map((info: string, index: number) => {
              return <li key={index} className="flex gap-[5px] items-center pl-[5px]">
                <div className="w-[4px] h-[4px] bg-[#777A80] rounded-full"></div>
                <div className="text-[12px] text-[#777A80] leading-[18px]">
                  {info}
                </div>
              </li>
            })
          }
        </ul>
      </div>


    </div>
  }

  return <div className="p-[15px] min-w-[305px] w-[305px] h-[437px] rounded-[12px] border ">
    <div className="font-semibold text-[20px] text-[#000] leading-[24px] ">
      {
        type === 'builder' ? 'Builder' : 'Enterprise'
      }
    </div>
    <div className="text-[12px] text-[#777A80] leading-[15px] mt-[5px] mb-[20px]">
      {
        type === 'builder' ? "Uitimpte scalabjlity for professional individuals working in web3." : "Volume pricing and enterprise support for institutional use cases."
      }
    </div>

    <div className="cursor-pointer text-[#263DFF] text-[22px] font-bold mb-[15px]">
      {type === "enterprise" ? "Contact Support" : "$49 / Month"}
    </div>

    <div className={classNames("cursor-pointer text-center rounded-[4px] bg-[#263DFF] text-[#fff] leading-[16px] text-[14px] py-[14px]", {
      'bg-[#333]': currentPlan,
      'cursor-not-allowed bg-[#666]': loading
    })}
      onClick={() => {
        if (currentPlan) return
        if (type === "enterprise") {
          window.open('mailto:sales@zettablock.com')
        } else {
          onUpgrade()
          // upgrade to builder
        }
      }}
    >

      {currentPlan ? 'Current Plan' : "Upgrade"}
    </div>

    <div className="mt-[20px]">
      <div className="text-[12px] text-[#777A80] leading-[18px]">
        {
          INFO_TITLE[type]
        }
      </div>
      <ul>
        {
          INFO[type].map((info: string, index: number) => {
            return <li key={index} className="flex gap-[5px] items-center pl-[5px]">
              <div className="w-[4px] h-[4px] bg-[#777A80] rounded-full"></div>
              <div className="text-[12px] text-[#777A80] leading-[18px]">
                {info}
              </div>
            </li>
          })
        }
      </ul>
    </div>


  </div>
}

const AddCardModal = ({
  open,
  onCancel,
  onSubmit,
  hasDefault
}: {
  open: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  hasDefault?: boolean;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [{ auth }] = useAuth();
  const [isDefault, setIsDefault] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [cardholderName, setCardholderName] = useState('')

  const handleSubmit = async () => {
    if (!elements) return
    if (isLoading) return
    setIsLoading(true)
    const token = await stripe?.createToken(elements.getElement('cardNumber')!);

    if (token?.token?.id) {
      try {
        setIsLoading(true)
        await addPaymentMethod(auth?.id || "", token?.token?.id || "", 'card', hasDefault ? isDefault : true)
        toast.success('Payment method added', {
          autoClose: 2000,
          position: 'top-center',
        })
        onSubmit()
        setIsLoading(false)

        // clear form
        elements?.getElement('cardNumber')?.clear()
        elements?.getElement('cardExpiry')?.clear()
        elements?.getElement('cardCvc')?.clear()
        setCardholderName('')

        onCancel()
      } catch (error: any) {
        toast.error(`Error adding payment method - ${error?.response?.data?.message || 'Unknown'}`, {
          autoClose: 2000,
          position: 'top-center',
        })
      }
      setIsLoading(false)
    } else {
      toast.error('Error creating token', {
        autoClose: 2000,
        position: 'top-center',
      })
    }
    setIsLoading(false)

  }

  return <Modal
    open={open}
    onCancel={onCancel}
    maskClosable={false}
    onOk={handleSubmit}
    okButtonProps={{
      disabled: isLoading
    }}
    cancelButtonProps={{
      disabled: isLoading
    }}
  >
    <CreditCardInfo cardholderName={cardholderName} setCardholderName={setCardholderName} />
    {
      hasDefault && <div className='px-8 flex items-center justify-start'>
        <input type="checkbox" width={20} onChange={(e) => { setIsDefault(e.target.checked) }} />

        <div className='ml-2'>
          <label htmlFor="card-holder-name" className="text-[#777A80]" >
            Set as default
          </label>
        </div>
      </div>
    }

  </Modal>
}

const UpgradeModal = ({
  open,
  onCancel,
  onUpgrade
}: {
  open: boolean;
  onCancel: () => void;
  onUpgrade: () => void;
}) => {

  const [isFailCheckAgree, setIsFailCheckAgree] = useState(false)
  const [isAgree, setIsAgree] = useState(false)

  const setCheckAgree = (checked: boolean) => {
    setIsAgree(checked)
    if(!checked)
      setIsFailCheckAgree(!checked)
  }

  const handleSubmit = async() => {
    if (!isAgree) {
      setIsFailCheckAgree(true)
      return
    }
    onCancel()
    await onUpgrade()
  }

  return <Modal
    open={open}
    onCancel={onCancel}
    maskClosable={false}
    onOk={handleSubmit}
    width={384}
    footer={null}
  >

    <div className={
      classNames(' rounded-[5px] h-[193px]', styles['upgrade-modal'])
    }/>
    <div className="w-full justify-center grid">
      <div className="p-[15px] min-w-[305px] w-[305px] h-auto rounded-[12px] border">
        <div className="font-semibold text-[20px] text-[#000] leading-[24px] ">
          Upgrade to Builder Plan
        </div>
        <div className="text-[12px] text-[#777A80] leading-[15px] mt-[22px] mb-[20px]">
          Ultimate scalability for professional individuals working in web3.
        </div>
        <div className="cursor-pointer text-center text-[#263DFF] text-[22px] font-bold mb-[20px]">
          $49 / Month
        </div>
        <div className='px-2 flex leading-[16px] items-center justify-start'>
          <input type="checkbox" id="upgrade_agree_terms" width={20} onChange={(e) => { setCheckAgree(e.target.checked) }} />

          <div className='ml-2'>
            <label htmlFor="upgrade_agree_terms" className={classNames("text-[#777A80] text-[12px]", {
              'text-[#c00]': isFailCheckAgree
            })}>
              I have read and agree to the <strong><a
                className='text-[#777A80]' href='https://www.zettablock.com/terms'
                target='_blank' rel='noreferrer' >Terms and Conditions</a></strong>.
            </label>
          </div>
        </div>

        <div className="cursor-pointer text-center mt-8 mb-2 rounded-[4px] bg-[#263DFF] text-[#fff] leading-[16px] text-[14px] py-[14px]"
          onClick={() => {handleSubmit()}}
        >
          Upgrade Now
        </div>

        <div className="mt-[16px]">
          <div className="pl-2 text-[10px] text-[#777A80] leading-[16px]">
            Once you upgrade, you will be charged $49 immediately. This plan auto-renews every 30 days. Additional usage fees apply if you exceed plan limits.
          </div>
        </div>

      </div>
    </div>

  </Modal>
}

const BillingV2 = () => {
  const [{ auth }, { setAuth }] = useAuth();
  const [subscriptionDetail, setSubscriptionDetail] = useState<ModelGetPaymentSubscriptionDetailsResponse | null>(null)
  const [methods, setMethods] = useState<ModelPaymentMethod[]>([])
  const stripePromise = loadStripe(envs.REACT_APP_STRIPE_SECRET_KEY);

  const [isLoading, setIsLoading] = useState(false)

  const options = {
    mode: 'payment',
    amount: 1099,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {
    },
  };
  const [showModal, setShowModal] = useState(false)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)

  const [billingRange, setBillingRange] = useState<any>(null)
  const [billingHistory, setBillingHistory] = useState<any>([])

  const fetchAndSetBillingHistory = async () => {
    if (auth && billingRange) {
      const resp = await getBillingHistory(auth.id, {
        start_time: `${billingRange[0].toISOString().slice(0, 10)}T00:00:00`,
        end_time: `${billingRange[1].toISOString().slice(0, 10)}T23:59:59`
      })
      setBillingHistory(resp?.transaction_histories || [])
    }
  }
  useEffect(() => {
    fetchAndSetBillingHistory()
  }, [auth, billingRange])

  const fetchAndSetPaymentMethods = async () => {
    if (auth) {
      const res: ModelListPaymentMethodsResponse = await getPaymentMethods(auth.id)

      if (res && res && res.payment_methods) {
        setMethods(res.payment_methods)
      }
    }
  }
  const onRemove = async (id: string) => {
    await removePaymentMethod(auth?.id || "", id, auth?.tenant || "")
    toast.success('Payment method removed', {
      autoClose: 2000,
      position: 'top-center',
    })
    await fetchAndSetPaymentMethods()
  }
  const onSubmit = async () => {
    await fetchAndSetPaymentMethods()
  }
  const fetchAndSetSubscriptionDetail = async (userId?: string) => {
    if (!userId) return;
    setIsLoading(true)

    const client = PaymentApi();
    const res = await client.subscriptionGetUserIdList(userId);
    setSubscriptionDetail(res.data)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchAndSetSubscriptionDetail(auth?.id || undefined)
  }, [auth?.id])

  useEffect(() => {
    fetchAndSetPaymentMethods()
    // fetchAndSetProducts()
  }, [auth])

  const defaultPaymentMethod = methods.find(e => e.is_default)

  const [showChangePlanModal, setShowChangePlanModal] = useState(false)

  const onSetDefault = async (id: string) => {
    try {
      await setDefaultPaymentMethod(auth?.id || "", id)
      toast.success('Default payment method updated', {
        autoClose: 2000,
        position: 'top-center',
      })
      await fetchAndSetPaymentMethods()
    } catch (error) {
      toast.error('Error setting default payment method', {
        autoClose: 2000,
        position: 'top-center',
      })
    }
  }

  const [loading, setLoading] = useState(false)
  const displayUpgradeModal = () => {
    if (loading) return
    setShowUpgradeModal(true)
  }
  const onUpgrade = async () => {
    try {
      if (loading) return
      setLoading(true)
      const defaultPayment = methods.find((method) => {
        return method.is_default
      }) || methods[0]

      const products = await getProducts(auth?.id || "")
      const buildProduct = products.products?.find((product) => {
        return product.name === 'build'
      })

      if (!defaultPayment) {
        toast.error('Please add a payment method first', {
          autoClose: 2000,
          position: 'top-center',
        })
        setLoading(false)
        return
      }

      await subscriptionUpgrade(auth?.id || "", buildProduct?.id || '', defaultPayment?.id || '', 'build')
      setAuth((prev: UserAuth | null | undefined) => {
        if (prev) return {
          ...prev,
          subscription: {
            ...prev.subscription,
            plan: 'build'
          }
        }
        return null
      })

      await fetchAndSetSubscriptionDetail(auth?.id || undefined)
      toast.success('Subscription upgraded to Builder', {
        autoClose: 2000,
        position: 'top-center',
      })

      setLoading(false)
    } catch (error) {
      toast.error('Error upgrading subscription', {
        autoClose: 2000,
        position: 'top-center',
      })
    }
  }
  const onDowngrade = async () => {
    try {
      if (loading) return
      setLoading(true)
      const defaultPayment = methods.find((method) => {
        return method.is_default
      }) || methods[0]

      const products = await getProducts(auth?.id || "")
      const freeProduct = products.products?.find((product) => {
        return product.name === 'free'
      })

      if (!defaultPayment) {
        toast.error('Please add a payment method first', {
          autoClose: 2000,
          position: 'top-center',
        })
        setLoading(false)
        return
      }

      await subscriptionDowngrade(auth?.id || "", freeProduct?.id || '', defaultPayment?.id || '', 'build')
      setAuth((prev: UserAuth | null | undefined) => {
        if (prev) return {
          ...prev,
          subscription: {
            ...prev.subscription,
            plan: 'build'
          }
        }
        return null
      })

      await fetchAndSetSubscriptionDetail(auth?.id || undefined)
      toast.success('Subscription upgraded to Free', {
        autoClose: 2000,
        position: 'top-center',
      })

      setLoading(false)
    } catch (error) {
      toast.error('Error downgrading subscription', {
        autoClose: 2000,
        position: 'top-center',
      })
    }
  }

  const MAPPING = {
    build: 'Builder',
    enterprise: 'Enterprise',
    free: 'Free Plan'
  }

  const DESC_MAPPING = {
    build: 'Great for developers, traders, or anyone getting started.',
    enterprise: 'Ultimate scalability for professional individuals working in web3.',
    free: 'Volume pricing and enterprise support for institutional use cases.'
  }


  if (isLoading) return <div className="h-full w-full flex justify-center items-center">
    <LoadingIndicator />
  </div>

  return <div>
    <div className="min-w-[1240px] w-[1240px] mx-auto">
      {/* Title */}
      <div className="px-[30px] pt-[26px] pb-[18px] border-b">
        <div className="text-[14px] text-[#1A1A1A] font-bold leading-[14px]">
          Billing
        </div>
      </div>

      {/* Content */}
      <div className="mx-[80px] my-[30px]">
        <div className="flex gap-[30px]">
          <div className="w-[630px]">
            <div className="text-[20px] font-bold leading-[20px] text-[#1A1A1A] mb-[20px]">
              Subscription Summary
            </div>
            <div className={
              classNames('p-[30px] pb-[40px] border rounded-[5px] h-[350px]', styles['info-card'])
            }>
              <div className="flex justify-between">
                <div>
                  <div className="text-[#263DFF] text-[20px] leading-[24px] font-bold mb-[5px]">
                    {MAPPING[subscriptionDetail?.plan_type || ''] || 'Unknown'}
                  </div>
                  <div className="text-[12px] text-[#777A80] leading-[12px]">
                    {DESC_MAPPING[subscriptionDetail?.plan_type || ''] || 'Unknown'}
                  </div>
                </div>
                {
                  subscriptionDetail?.plan_type !== 'enterprise' && <div
                    onClick={() => {
                      setShowChangePlanModal(true)
                    }}
                    className="border-[#A6B8F7] border rounded-[4px] text-[12px] h-[30px] flex items-center px-[12px] text-[#2915F7] cursor-pointer">
                    Change Plan
                  </div>
                }
              </div>

              <div className="mt-[40px] flex gap-[60px]">
                <div className="">
                  <div className="mb-[20px]">
                    <div className="text-[#777A80] text-[14px] leading-[21px] mb-[5px]">
                      Plan Type
                    </div>
                    <div className="flex items-center gap-[10px]">
                      <div className="text-[14px] leading-[21px] font-semibold">
                        {MAPPING[subscriptionDetail?.plan_type || ''] || 'Unknown'}
                      </div>
                    </div>
                  </div>
                  <div className="mb-[20px]">
                    <div className="text-[#777A80] text-[14px] leading-[21px] mb-[5px]">
                      Monthly Price
                    </div>
                    <div className="text-[14px] leading-[21px] font-semibold">
                      {subscriptionDetail?.base_price !== undefined ? `$${(subscriptionDetail?.base_price).toFixed(2)}` : 'Unknown'}
                    </div>
                  </div>
                  <div className="">
                    <div className="text-[#777A80] text-[14px] leading-[21px] mb-[5px]">
                      Base CU Limit
                    </div>
                    <div className="text-[14px] leading-[21px] font-semibold">
                      {
                        subscriptionDetail?.base_cu_limit !== undefined ? `${subscriptionDetail?.base_cu_limit} CU` : 'Unknown'
                      }
                    </div>
                  </div>

                </div>
                <div className="">
                  <div className="mb-[20px]">
                    <div className="text-[#777A80] text-[14px] leading-[21px] mb-[5px]">
                      Payment Frequency
                    </div>
                    <div className="text-[14px] leading-[21px] font-semibold">
                      {
                        subscriptionDetail?.payment_frequency === 'month' ? 'Monthly' : 'Yearly'
                      }
                    </div>
                  </div>
                  <div className="mb-[20px]">
                    <div className="text-[#777A80] text-[14px] leading-[21px] mb-[5px]">
                      Start Date
                    </div>
                    <div className="text-[14px] leading-[21px] font-semibold">
                      {subscriptionDetail?.start_date || 'Unknown'}
                    </div>
                  </div>
                  {/* <div className="">
                    <div className="text-[#777A80] text-[14px] leading-[21px] mb-[5px]">
                      End Date
                    </div>
                    <div className="text-[14px] leading-[21px] font-semibold">
                      June 30,2024
                    </div>
                  </div> */}

                </div>
              </div>
            </div>
          </div>
          {
            subscriptionDetail?.plan_type !== 'enterprise' && <div className="w-[420px]">
              <div className="text-[20px] font-bold leading-[20px] text-[#1A1A1A] mb-[20px]">
                Payment Method
              </div>
              <div className="w-full p-[30px] border rounded-[4px] overflow-hidden h-[350px]">
                <div className={classNames("w-[360px] h-[200px] border rounded flex flex-col justify-between mb-[20px]", styles['credit-card'])}>
                  <div className="p-[10px] flex justify-end">
                    <Icon className="w-[50px]" iconName={defaultPaymentMethod?.details?.brand || 'default'}
                      iconType="png"
                    />
                  </div>
                  <div className="pl-[30px] pr-[10px] pb-[10px]">
                    <div className="text-[16px] leading-[16px] font-semibold text-[#fff] mb-[10px]">
                      xxxx xxxx xxxx {defaultPaymentMethod?.details?.last4 || 'xxxx'}
                    </div>
                    <div className="text-[16px] leading-[16px] font-semibold text-[#fff]">
                      {/* John Doe */}
                    </div>
                    <div className="flex justify-end">
                      <div className="border text rounded bg-[#6054e4] py-[4px] px-[8px] text-[10px] text-[#fff]">
                        Default
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-[390px] overflow-x-auto flex gap-[10px] pr-[30px]">
                  <div className="flex justify-center items-center cursor-pointer py-[27px] px-[10px] border rounded min-w-[100px]">
                    <div className="text-[12px] text-[#263DFF] leading-[15px] " onClick={() => { setShowModal(true) }}>
                      + Add Card
                    </div>
                  </div>

                  {
                    methods.filter(e => !e.is_default).map((method, index) => {
                      return <div key={index} className="min-w-[170px] p-[10px] border rounded flex flex-col justify-between">
                        <div className="flex gap-[5px] items-center">
                          <Icon className="w-[28.5px]" iconName={method.details?.brand || 'default'} iconType="png" />
                          <div className="text-[10px] text-[#777A80] font-semibold">
                            xxxx xxxx xxxx {method.details?.last4 || 'xxxx'}
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <Popconfirm title={'Are you sure to set this one to be the default payment method?'}
                            onConfirm={() => {
                              if (method.id) {
                                onSetDefault(method.id)
                              } else {
                                toast.error('Error setting default payment method')
                              }
                            }}
                            onCancel={() => { }}
                          >
                            <div className="text-[10px] leading-[10px] p-[4px] bg-[#F6F7F9] text-[#1A1A1A] rounded px-[5px] cursor-pointer">
                              Set as default
                            </div>
                          </Popconfirm>
                          <Popconfirm title={'Are you sure to remove this payment method?'}
                            onConfirm={() => {
                              onRemove(method.id || '')
                            }}
                          >
                            <div className="cursor-pointer">
                              <Icon iconName="remove" className="w-[18px]" />
                            </div>
                          </Popconfirm>

                        </div>
                      </div>
                    })
                  }
                </div>
              </div>
            </div>
          }
        </div>
        {
          subscriptionDetail?.plan_type === 'build' && <>
            <div className="font-semibold mt-[55px] flex justify-between items-center">
              <div className="text-[20px] font-bold leading-[20px] ">
                Billing History
              </div>
              <div>
                <DatePicker.RangePicker
                  value={billingRange}
                  onChange={(e) => {
                    setBillingRange(e)
                  }}
                />
              </div>
            </div>
            <div className="mt-[45px]">
              <Table
                pagination={false}
                dataSource={billingHistory}
                columns={[
                  {
                    title: 'Date',
                    dataIndex: 'date',
                    key: 'date',
                  },
                  {
                    title: 'Amount',
                    dataIndex: 'amount',
                    key: 'amount',
                    render: (text: string, row: any) => {
                      return <div className="flex items-center gap-2">
                        <div className="">
                          {text}
                        </div>
                        <div>
                          {
                            row.status === 'succeeded' ? <Tag color="success">
                              <div className="flex items-center gap-1">Succeeded <IoCheckmark /></div>
                            </Tag> : <Tag color="error">
                              {row.status}
                            </Tag>
                          }

                        </div>
                      </div>
                    }
                  },
                  {
                    title: 'Payment Method',
                    dataIndex: 'payment_method',
                    key: 'payment_method',
                    render: (record: {
                      brand: string;
                      last4: string;
                    }) => {
                      return <div className="flex items-center gap-2">
                        <div className="w-10">
                          <Icon iconName={record.brand} className="w-[12px]" />
                        </div>
                        <div>
                          {record.brand}
                        </div>
                      </div>
                    }
                  },
                  {
                    title: 'Description',
                    dataIndex: 'description',
                    key: 'description',
                  }
                ]} />
            </div>
          </>
        }
      </div>
      <Elements stripe={stripePromise} options={options as any}>
        <AddCardModal hasDefault={methods.length > 0} open={showModal} onCancel={() => setShowModal(false)} onSubmit={onSubmit} />
      </Elements>
    </div>

    <Modal
      className="billing-modal"
      width={1055}
      style={{ minWidth: '1055px' }}
      open={showChangePlanModal}
      onCancel={() => { setShowChangePlanModal(false) }}
      onOk={() => { setShowChangePlanModal(false) }}
      closeIcon={null}
      footer={null}
    >
      <div className="">
        <div
          className="border-b  px-[19.5px] py-[17px] flex items-center justify-between">
          <div className="text-[#1A1A1A] text-[16px] leading-[16px] font-semibold">
            Change Plan
          </div>

          <div className="cursor-pointer" onClick={() => { setShowChangePlanModal(false) }}>
            <Icon iconName="close" className="w-[16px] w-[16px]" />
          </div>
        </div>
        <div className="p-[40px] flex items-center justify-between gap-[30px]">
          <PlanCard type="free" loading={loading} onUpgrade={onUpgrade} onDowngrade={onDowngrade} currentPlan={subscriptionDetail?.plan_type === 'free'} />
          <PlanCard type="builder" loading={loading} onUpgrade={ () => displayUpgradeModal()} onDowngrade={onDowngrade} currentPlan={subscriptionDetail?.plan_type === 'build'} />
          <PlanCard type="enterprise" loading={loading} onUpgrade={onUpgrade} onDowngrade={onDowngrade} currentPlan={subscriptionDetail?.plan_type === 'enterprise'} />
        </div>

        <UpgradeModal open={showUpgradeModal} onCancel={() => setShowUpgradeModal(false)} onUpgrade={onUpgrade} />
      </div>

    </Modal>
  </div>
}

export default BillingV2;