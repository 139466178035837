import LanguagePicker from "./LanguagePicker"
import { useContext, useState, useEffect } from "react"
import { useParams } from "react-router"
import { fetchOpenapi, generateHttpSnippet } from "../../utils"
import { ApiProjectContext } from "../api-project-context"
import CodeEditor from "./CodeEditor"
import CopyToClipboard from "react-copy-to-clipboard"
import copyIcon from './copy.svg'
import { toast } from "react-toastify"

const RequestSnippet = ({ apiDetail, method = '', path = '' }: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apiDetail?: any
  method: string
  path: string
}) => {
  const params = useParams()
  const { oasObj, formData, setResponseData, apiProject } = useContext(ApiProjectContext)

  const [currentLang, setCurrentLang] = useState('shell') // shell, nodejs, python, go, java, csharp, php, ruby

  const [codeSnippet, setCodeSnippet] = useState('')

  useEffect(() => {
    if (apiDetail === null) return;

    const getAndSetCodeSnippet = async () => {
      const snippet = await generateHttpSnippet(apiDetail, path, method, formData || {}, currentLang)

      if (typeof snippet === 'string') {
        setCodeSnippet(snippet)
      }
    }

    getAndSetCodeSnippet()


  }, [currentLang, apiDetail, method, path, formData])



  if (!apiDetail) return null;

  return <div>
    <div className="font-semibold mb-2 text-[14px]">
      Language
    </div>
    <div className="mt-4">
      <LanguagePicker onClick={(e) => {
        setCurrentLang(e)
      }}
        currentLang={currentLang}
      />
    </div>
    <div className="mt-4 border rounded overflow-hidden">
      <CodeEditor codeSnippet={codeSnippet as string} method={apiProject[params.apiId || '']?.method} path={apiProject[params.apiId || '']?.path} />
      <div className="bg-[#fafafa] flex justify-between items-center p-[5px] border-t">
        <div className="pl-[10px] cursor-pointer">
          <CopyToClipboard text={codeSnippet || ''} onCopy={() => {
            toast.success('Copied to clipboard', {
              autoClose: 2000,
              position: 'top-center',
            })
          }}>
            <div>
              <img src={copyIcon} width={14} />
            </div>
          </CopyToClipboard>
        </div>
        <div>
          <button className="btn bg-[#333] text-[12px] px-[12px] py-[9px]" onClick={async () => {

            const url = 'https://qugate-dev-ztbl.api.zettablock.dev/api/v2' || oasObj.url()
            const res = await fetchOpenapi(url + path, formData.header, method, formData.query, formData.body, formData.path)
            setResponseData(res)
          }}>
            Try it
          </button>
        </div>
      </div>
    </div>
  </div>
}

export default RequestSnippet;