import { useEffect, useState } from "react";
import { AiOutlineTable } from "react-icons/ai";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { getRelatedTables } from "../../apis";
import { PoTableMetadata } from "../../../../api/__gen__/data-contracts";

interface RelatedTablesProps {
  apiId?: string

}

const RelatedTables = ({
  apiId
}: RelatedTablesProps) => {

  // get related tables and maintain a local state => no need to store in context
  const [relatedTables, setRelatedTables] = useState<PoTableMetadata[]>([])

  const fetchAndSetRelatedTables = async () => {

    if (!apiId) return
    // fetch related tables
    const relatedTablesData = await getRelatedTables(apiId || '')
    // setRelatedTables
    setRelatedTables(relatedTablesData)
    // editApiRelatedTable(apiId, ['AwsDataCatalog'], 'add')

  }

  useEffect(() => {
    fetchAndSetRelatedTables()
  }, [apiId])

  const [limit, setLimit] = useState(3)

  return <div>
    <div className="font-semibold text-[14px]">
      Related Tables
    </div>

    <div>
      {
        relatedTables.slice(0, limit).map((table) => {
          return <div className="flex items-center gap-1 my-3 text-[12px]">
            <AiOutlineTable /> {
              table.name || 'Unknown'
            }
          </div>
        })
      }
      {
        relatedTables.length === 0 && <div className="text-sm text-[#969ca1]">
          No related tables
        </div>
      }
    </div>
    {
      relatedTables.length > 3 && <div className="flex text-sm items-center text-[#969ca1] gap-1 cursor-pointer" onClick={() => {
        setLimit(limit === 3 ? relatedTables.length : 3)
      }}>
        <span className="text-[12px]">More</span>
        {
          limit === 3 ? <BiChevronDown /> : <BiChevronUp />
        }
      </div>
    }
  </div>
}


export default RelatedTables;