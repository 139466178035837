import { toast } from 'react-toastify';
import { atom, useRecoilState } from 'recoil';

import { ModelListApiKeys } from '../../api/__gen__/data-contracts';
import { useRpcStatusV3 } from '../../hooks/network';
import { getFetchApiKeys } from './api-keys-rpc';

export const ApiKeysState = atom<ModelListApiKeys>({
  key: 'ApiKeysState',
  default: {
    keys: [],
    dateRange: {
      endDate: '',
      startDate: '',
    },
  },
});

export const useApiKeys = () => {
  const [rpcStatus, setRpcStatus] = useRpcStatusV3('api-keys');
  const [apiKeys, setApiLogs] = useRecoilState(ApiKeysState);

  async function fetchApiKeys(billingCycle = 0) {
    if (rpcStatus?.isLoading) {
      return;
    }

    toast.dismiss();

    setRpcStatus({
      isLoading: true,
    });

    const resp = await getFetchApiKeys(billingCycle);

    // @ts-ignore
    const error = resp?.data.message || resp?.message;

    if (error) {
      // error
      setRpcStatus({
        isLoading: false,
        error,
      });

      toast.error(error);
    } else {
      setRpcStatus({
        isLoading: false,
      });

      // success
      const data = resp?.data;
      setApiLogs(data);
    }
    setRpcStatus({
      isLoading: false,
      error,
    });
  }

  return [
    { apiKeys, rpcStatus },
    { setRpcStatus, fetchApiKeys },
  ] as const;
};
