/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelListDatabaseResponse,
  ModelListSchemaResponse,
  ModelListTableResponse,
  ModelSubmitQueryRequest,
  ModelSubmitQueryResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Databases<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description list database for current user, enterprise user will have private space contains private data.
   *
   * @tags Data Lake APIs
   * @name DatabasesList
   * @summary List databases
   * @request GET:/databases
   */
  databasesList = (params: RequestParams = {}) =>
    this.request<ModelListDatabaseResponse, any>({
      path: `/databases`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description execute query with given sql statement, returning result in response as a data stream. If the query takes long time, this endpoint will return HTTP 302 code with a queryrun id, e.g. {"queryrunId": "xxx"}. Then the client can poll status and get result using that queryrun id.
   *
   * @tags example
   * @name ExecuteDebugQueryCreate
   * @summary execute query with given sql statement, buffer query result in memory before sending to client
   * @request POST:/databases/{database}/executeDebugQuery
   */
  executeDebugQueryCreate = (
    database: string,
    request: ModelSubmitQueryRequest,
    params: RequestParams = {}
  ) =>
    this.request<string, any>({
      path: `/databases/${database}/executeDebugQuery`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description execute query with given sql statement, returning result in response as a data stream. If the query takes long time, this endpoint will return HTTP 302 code with a queryrun id, e.g. {"queryrunId": "xxx"}. Then the client can poll status and get result using that queryrun id.
   *
   * @tags example
   * @name ExecuteQueryCreate
   * @summary execute query with given sql statement
   * @request POST:/databases/{database}/executeQuery
   */
  executeQueryCreate = (
    database: string,
    request: ModelSubmitQueryRequest,
    params: RequestParams = {}
  ) =>
    this.request<string, any>({
      path: `/databases/${database}/executeQuery`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description create a query, but not execute or trigger the query.
   *
   * @tags Data Lake APIs
   * @name QueriesCreate
   * @summary Create a query
   * @request POST:/databases/{database}/queries
   */
  queriesCreate = (
    database: string,
    request: ModelSubmitQueryRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelSubmitQueryResponse, any>({
      path: `/databases/${database}/queries`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description list schema
   *
   * @tags example
   * @name SchemasDetail
   * @summary list schema
   * @request GET:/databases/{database}/schemas
   */
  schemasDetail = (database: string, params: RequestParams = {}) =>
    this.request<ModelListSchemaResponse, any>({
      path: `/databases/${database}/schemas`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description list table
   *
   * @tags example
   * @name SchemasTablesDetail
   * @summary list table
   * @request GET:/databases/{database}/schemas/{schema}/tables
   */
  schemasTablesDetail = (
    database: string,
    schema: string,
    params: RequestParams = {}
  ) =>
    this.request<ModelListTableResponse, any>({
      path: `/databases/${database}/schemas/${schema}/tables`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
