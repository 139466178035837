import { HTMLAttributes } from 'react';
import { IoIosAdd, IoIosSearch, IoIosTimer } from 'react-icons/io';
import { Link } from 'react-router-dom';

export default function AddEntityButton({
  title,
  subtitle,
  to,
  iconType,
  ...rest
}: {
  iconType?: string;
  title: string;
  subtitle?: string;
  to?: string;
} & HTMLAttributes<HTMLDivElement>) {
  const renderIcon = () => {
    switch (iconType) {
      case 'history':
        return <IoIosTimer size='1.5rem' />;
      case 'search':
        return <IoIosSearch size='1.5rem' />;
      default:
        return <IoIosAdd size='1.5rem' />;
    }
  }
  const label = (
    <>
      <div className='text-[0.9rem] min-w-[12rem] font-semibold inline-flex items-center justify-center space-x-2'>
        {renderIcon()}
        <span>{title}</span>
      </div>
      {subtitle && <span className='text-sm opacity-50'>{subtitle}</span>}
    </>
  );

  const cls =
    'bg-base-200 border-2 border-base-300 uppercase h-full pt-7 pb-0 px-10 rounded text-base-content text-opacity-50 flex flex-col items-center justify-start cursor-pointer min-h-[5.3rem]';

  if (to) {
    return (
      <Link to={to} className={cls}>
        {label}
      </Link>
    );
  }

  return (
    <div className={cls} {...rest}>
      {label}
    </div>
  );
}
