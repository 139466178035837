/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useForm } from "react-hook-form";
import { FcGoogle } from "react-icons/fc";
import { AuthApiV2 } from "../../api/client";
import { HorizontalLine } from "../../components/HorizontalLine";
import { FormValues } from "./Auth";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { track } from "../tracking";

const passwordCheck = (str: string) => {
  const re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
  return re.test(str);
}

const Signup = ({
  googleLogin
}: any) => {
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: {},
  });

  // Navigation
  const navigate = useNavigate();
  const navigateToVerify = (emailAddress: string) => {
    navigate(`/v2/auth/verify/${emailAddress}`)
  }

  // login Endpoint
  // Signup
  const onSubmit = async (data: FormValues) => {
    const client = AuthApiV2();

    if (!data?.password?.length || data?.password?.length < 8 || passwordCheck(data?.password) === false) {
      toast.warn('Password should be at least 8 characters, max 16, with at least a number, upper and lower case letters', {
        autoClose: 2000,
        position: 'top-center',
      })
      return;
    }

    try {
      const res = await client.signupCreate({
        email: data.username,
        password: data.password
      })

      track('Auth - Signup', {
        email: data.username
      })

      navigateToVerify(data.username || '')

      toast.success('Signup successful. Please check your email to verify your account.', {
        autoClose: 2000,
        position: 'top-center',
      })
    } catch (error: any) {
      if (error?.response?.data?.message) {
        switch (error?.response?.data?.message) {
          case 'email was already signed up':
            toast.warn('Email is already registered. Please login to continue.', {
              autoClose: 2000,
              position: 'top-center',
            })
            return;
          case 'the user email was signed up but waiting for activation':
            // redirect to verify page
            toast.warn('Email is waiting for activation. Please check your email to verify your account.', {
              autoClose: 2000,
              position: 'top-center',
            })
            navigateToVerify(data.username || '')
            return;
        }
      }
      toast.warn((error as any).message || 'Something went wrong during signup. Please try again.', {
        autoClose: 2000,
        position: 'top-center',
      })
    }


  }

  return <div className='bg-[#fff] mt-8 pb-10'>
    <div className='bg-[#FBFCFD] px-8 py-6 flex justify-between items-center'>
      <span className=' font-bold'>
        Sign Up
      </span>
    </div>
    <form
      onSubmit={(e) => { e.preventDefault() }}
      className='w-full px-8'
    >
      <div className='form-control w-full mt-6'>
        <label className='label'>
          <span className='label-text'>Email</span>
        </label>
        <input
          placeholder='email@example.com'
          type='email'
          className='input input-bordered w-full border-transparent bg-[#F8F9FA]'
          {...register('username')}
        />
      </div>
      <div className='form-control w-full mt-4'>
        <label className='label'>
          <span className='label-text'>Password</span>
        </label>
        <input
          placeholder='**********'
          type='password'
          className='input input-bordered w-full border-transparent bg-[#F8F9FA]'
          {...register('password')}
        />
      </div>

      <button className='btn btn-primary bg-[#263DFF] mt-10 w-full' onClick={handleSubmit(onSubmit)}>
        Register
      </button>
    </form>
    {/* <div className='text-center mt-5 text-sm'>
    {"Don't have an account? "}
    <Link to='/auth/register' className='text-[#263DFF]'>
      Sign Up
    </Link>
  </div> */}
    <div className='text-center mt-5 text-sm px-8 font-semibold'>
      <span className='text-sm'>
        <Link to="/v2/auth/login" className='text-[#263DFF] hover:cursor-pointer font-bold'>Return to Login</Link>
      </span>
    </div>
    <div className='px-8 mt-5'>
      <HorizontalLine className='h-0 mb-5' />
      <button
        className='btn btn-outline gap-5 w-full normal-case text-xl'
        onClick={() => googleLogin()}
      >
        <FcGoogle size='1.5rem' />
        Continue with Google
      </button>

    </div>
  </div>
}

export default Signup;