/* eslint-disable @typescript-eslint/no-explicit-any */
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import { HorizontalLine } from '../../components/HorizontalLine';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { ApiKeyApiV2 } from '../../api/client';
import { AxiosError } from 'axios';
import { useParams, useNavigate } from 'react-router';
import {
  ModelUsageSummary,
  ModelApiUsage,
  ModelUserApiSummary,
} from '../../api/__gen__/data-contracts';
// import { ApiUsageBarChart } from './ApiUsageBarChart';
// import { ApiUsageLineChart } from './ApiUsageLineChart';
import { formatNum } from '../../utils/format';
import LoadingIndicator from '../../components/LoadingIndicator';
// import ProfileAvatar from '../auth/ProfileAvatar';
import { IoIosCopy, IoMdKey } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { IoInformationCircleSharp } from 'react-icons/io5';
// import Table from './components/Table';
import { CellProps } from 'react-table';
import DatePicker from 'react-datepicker';
import { addDays, addMonths, format } from 'date-fns';
import { toast } from 'react-toastify';
import { AiFillCaretDown } from 'react-icons/ai';
import useAuth from '../../hooks/auth';
// import { Table } from 'antd';
import { ApiUsageBarChart } from '../../modules/api/ApiUsageBarChart';
import { ApiUsageLineChart } from '../../modules/api/ApiUsageLineChart';
import ProfileAvatar from '../../modules/auth/ProfileAvatar';
import Table from '../../modules/api/components/Table';

const maskApiKey = (apiKey: string): string => {
  return `${apiKey.substring(0, 5)}*****${apiKey.substring(
    apiKey.length - 5,
    apiKey.length
  )}`;
};



const ApiCell = (prop: CellProps<ModelUserApiSummary>) => {
  const {
    value: { id, displayName, state },
  } = prop;

  return (
    <>
      <Link to={`/my-workspace/apis/${id}`}>{displayName}</Link>
      {state === 'Deleted' ? (
        <span
          className='badge-sm badge badge-outline cursor-pointer tooltip ml-2 inline-flex'
          data-tip='API cannot be queried once deleted. Also you can still view the deleted API in the API history under Usage portal.'
        >
          Deleted
        </span>
      ) : null}
    </>
  );
};

const CreatorCell = (prop: CellProps<ModelUserApiSummary>) => {
  const {
    value: { creator },
  } = prop;

  return (
    <div className='flex-1 flex justify-center align-center text-sm text-center font-semibold'>
      <ProfileAvatar
        className='flex-none w-5 bg-base-content bg-opacity-30 mr-2'
        creator={creator}
      />
      {creator?.name || 'Unknown'}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ApiUsageV2 = ({ isV2 }: {
  isV2?: boolean
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);

  const [{ auth }] = useAuth();

  const initialStartDate = useMemo(() => {

    if (!auth) return addDays(Date.now(), -30)

    const { subscription } = auth
    const offsetInMs = new Date().getTimezoneOffset() * 60 * 1000

    let currentDate

    if (subscription?.billDate) {
      const curDate = (subscription.billDate?.split('-')?.[2]) || '01'

      if (new Date().getUTCDate() >= parseInt(curDate, 10)) {
        currentDate = format(Date.now(), 'yyyy-MM-') + curDate
      } else {
        const month = new Date().getUTCMonth() > 0 ? new Date().getUTCMonth() : 12
        currentDate = `${format(Date.now(), 'yyyy-') + month}-${curDate}`
      }
    } else {
      currentDate = `${format(Date.now(), 'yyyy-MM-')}01`
    }

    return new Date(Date.parse(currentDate) + offsetInMs)

  }, [auth])


  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(new Date());

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (dates: any[]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const [copied, setCopied] = useState(false);
  const [currentBarChart, setCurrentBarChart] = useState<string>('numOfCalls');
  const [currentLineChart, setCurrentLineChart] =
    useState<string>('numOfCalls');
  const [currentCUBarChart, setCurrentCUBarChart] =
    useState<string>('dataRefreshCU');

  const { apiKeyId } = useParams();
  const navigate = useNavigate();

  const [usageDetail, setUsageDetail] = useState<ModelUsageSummary>({
    key: {},
    analyticsUsageSummary: {},
    apiUsageSummary: {},
    month: 0,
    year: 0,
  });

  const tableData = useMemo(() => {
    if (usageDetail.apiUsageSummary && usageDetail.apiUsageSummary.apiUsages) {
      return usageDetail.apiUsageSummary.apiUsages.map((e) => {
        const { apiTransformationCU, apiIndexingCU, totalComputeUnitsPerApi } =
          e;

        return {
          ...e,
          apiTransformationCU: formatNum(apiTransformationCU ?? 0),
          apiIndexingCU: formatNum(apiIndexingCU ?? 0),
          totalComputeUnitsPerApi: formatNum(totalComputeUnitsPerApi ?? 0),
        };
      });
    }

    return [];
  }, [usageDetail]);

  useEffect(() => {
    if (!auth) return

    const fetchApiUsageByRange = async (apiKey: string, start_date: string, end_date: string) => {
      try {
        setIsLoading(true);
        const client = ApiKeyApiV2()

        const res = await client.usageDaterangeDetail(apiKey, {
          start_date,
          end_date
        })

        return res
      } catch (error) {
        // eslint-disable-next-line
        console.error('query error: fetching usage', error);

        if (error instanceof AxiosError) {
          return {
            ...error.response,
            error: `${error?.response?.data?.message}`,
          };
        }
        toast.error('something went wrong when fetching api usage', {
          autoClose: 3000,
          position: 'top-center',
        })
      } finally {
        setIsLoading(false);
      }

      return null;
    }

    if (!apiKeyId) {
      navigate('/my-workspace');
    } else {
      if (!startDate || !endDate) return

      fetchApiUsageByRange(apiKeyId, format(startDate.getTime(), 'yyyy-MM-dd'), format(new Date(new Date(endDate).getTime() + 24 * 60 * 60 * 1000), 'yyyy-MM-dd')).then((res) => {
        if (res && res.data) {
          const { data } = res;

          if (data.apiUsageSummary?.apiUsages?.length > 0) {
            data.apiUsageSummary.apiUsages.sort(
              (a: ModelApiUsage, b: ModelApiUsage) => {
                return b.totalComputeUnitsPerApi! - a.totalComputeUnitsPerApi!;
              }
            );
          }

          setUsageDetail(data);
        }
      });
    }
  }, [startDate, endDate]);

  return (
    <LayoutBodyCard>
      {isLoading ? (
        <div className='flex items-center justify-center py-10'>
          <LoadingIndicator />
        </div>
      ) : (
        <div className=' w-full '>
          {/* Tabs */}
          <div className='flex items-center justify-between px-4 py-3'>
            <div className='flex items-center space-x-3'>
              <IoMdKey className='inline text-primary' size='1.4rem' />

              <span className='text-sm'>{usageDetail.key?.name}</span>
              <span className='badge badge-ghost font-bold opacity-50 bg-base-content bg-opacity-30 p-2'>
                default
              </span>
            </div>
            <div className='flex space-x-2 items-center'>
              <span className='text-blue-600 text-sm font-semibold'>Key</span>
              <span
                title='asdfasfggfvjdfhgjki3mwfhj'
                className='text-sm font-semibold'
              >
                {maskApiKey(usageDetail.key?.key || '')}
              </span>

              <CopyToClipboard
                text={usageDetail.key?.key || ''}
                onCopy={() => {
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 1200);
                }}
              >
                <button className='relative'>
                  <IoIosCopy size='0.8rem' className='opacity-60' />
                  <span
                    className={classNames(
                      'absolute left-full bottom-2/3 p-1 bg-gray-100 rounded-md',
                      {
                        hidden: !copied,
                      }
                    )}
                  >
                    Copied
                  </span>
                </button>
              </CopyToClipboard>
            </div>
          </div>
          <HorizontalLine />

          {/* SUMMARY */}
          <div className='p-4'>
            <div className='flex justify-between'>
              <div className='w-1/2 text-2xl mb-4'>SUMMARY</div>
              <div className='w-[220px] cursor-pointer justify-center flex items-center'>
                <DatePicker
                  className='font-bold w-[200px] text-center cursor-pointer'
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  minDate={addMonths(new Date(), -3)}
                  selectsRange
                />
                <AiFillCaretDown
                  size='1rem'
                  className={classNames('opacity-30 flex-none', {
                    'rotate-180': false,
                  })}
                />
              </div>

            </div>
            <div className='flex gap-4'>
              <div className='flex-[2] p-4 rounded-md border-x border-y'>
                <div className='text-2xl pb-3'>API</div>
                <div className='flex justify-between'>
                  <div className='flex-1'>
                    <div className='pb-2 opacity-50 text-xs'>
                      NUMBER OF CALLS
                    </div>
                    <div className='text-3xl'>
                      {usageDetail.apiUsageSummary?.totalNumberOfCalls}
                    </div>
                  </div>
                  <div className='flex-1'>
                    <div className='pb-2 opacity-50 text-xs'>
                      DATA REFRESH CU
                    </div>
                    <div className='text-3xl'>
                      {formatNum(
                        usageDetail.apiUsageSummary
                          ?.totalTransformationComputeUnits || 0
                      )}
                    </div>
                  </div>
                  <div className='flex-1'>
                    <div className='pb-2 opacity-50 text-xs'>INDEXING CU</div>
                    <div className='text-3xl'>
                      {formatNum(
                        usageDetail.apiUsageSummary
                          ?.totalIndexingComputeUnits || 0
                      )}
                    </div>
                  </div>

                  <div className='flex-1'>
                    <div className='pb-2 opacity-50 text-xs'>
                      TOTAL COMPUTE UNITS
                      <div
                        className='tooltip ml-1'
                        data-tip='API compute units are the sum of API calls, data refresh and data indexing'
                      >
                        <IoInformationCircleSharp />
                      </div>
                    </div>
                    <div className='text-3xl'>
                      {formatNum(
                        (usageDetail.apiUsageSummary
                          ?.totalTransformationComputeUnits || 0) +
                        (usageDetail.apiUsageSummary
                          ?.totalIndexingComputeUnits || 0)
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex-[1] p-4 rounded-md border-x border-y'>
                <div className='text-2xl pb-3'>ANALYTICS</div>
                <div className='flex justify-between'>
                  <div className='flex-1'>
                    <div className='pb-2 opacity-50 text-xs'>
                      NUMBER OF QUERIES
                    </div>
                    <div className='text-3xl'>
                      {usageDetail.analyticsUsageSummary?.totalNumOfQueryRuns}
                    </div>
                  </div>
                  {/* <div className='flex-1'>
                    <div className='pb-2 opacity-50 text-xs'>DATA SCANNED</div>
                    <div className='text-3xl'>
                      {usageDetail.analyticsUsageSummary?.totalDataScannedInMB
                        ? formatDataUsage(
                            usageDetail.analyticsUsageSummary
                              .totalDataScannedInMB
                          )
                        : ''}
                    </div>
                  </div> */}
                  <div className='flex-1'>
                    <div className='pb-2 opacity-50 text-xs'>COMPUTE UNITS</div>
                    <div className='text-3xl'>
                      {formatNum(
                        usageDetail.analyticsUsageSummary?.totalComputeUnits ??
                        0
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <HorizontalLine className='mt-2' />

          <div className='p-4 mt-2'>
            <div className='flex justify-between'>
              <div className='text-2xl'>API CALLS OVER TIME</div>
              <div>
                <div className='tabs tabs-boxed'>
                  <div
                    className={classNames('tab', {
                      'tab-active': currentBarChart === 'numOfCalls',
                    })}
                    onClick={() => setCurrentBarChart('numOfCalls')}
                  >
                    API CALLS
                  </div>
                  <div
                    className={classNames('tab relative', {
                      'tab-active': currentBarChart === 'computeUnits',
                    })}
                    onClick={() => setCurrentBarChart('computeUnits')}
                  >
                    COMPUTE UNITS
                  </div>
                </div>
              </div>
            </div>
            <ApiUsageBarChart
              propertyName={currentBarChart}
              data={usageDetail.apiUsageSummary?.apiUsages}
            />
          </div>

          <HorizontalLine className='mt-2' />

          <div className='p-4 mt-2'>
            <div className='flex justify-between'>
              <div className='text-2xl'>DATA REFRESH CU & INDEXING CU OVER TIME</div>
              <div>
                <div className='tabs tabs-boxed'>
                  <div
                    className={classNames('tab', {
                      'tab-active': currentCUBarChart === 'dataRefreshCU',
                    })}
                    onClick={() => setCurrentCUBarChart('dataRefreshCU')}
                  >
                    DATA REFRESH CU
                  </div>
                  <div
                    className={classNames('tab relative', {
                      'tab-active': currentCUBarChart === 'indexingCU',
                    })}
                    onClick={() => setCurrentCUBarChart('indexingCU')}
                  >
                    INDEXING CU
                  </div>
                </div>
              </div>
            </div>
            <ApiUsageBarChart
              propertyName={currentCUBarChart}
              data={usageDetail.apiUsageSummary?.apiUsages}
            />
          </div>

          <HorizontalLine className='mt-2' />

          <div className='p-4 mt-2'>
            <div className='flex justify-between'>
              <div className='text-2xl'>ANALYTICS QUERIES OVER TIME</div>
              <div>
                <div className='tabs tabs-boxed'>
                  <div
                    className={classNames('tab', {
                      'tab-active': currentLineChart === 'numOfCalls',
                    })}
                    onClick={() => setCurrentLineChart('numOfCalls')}
                  >
                    ANALYTICS QUERIES
                  </div>
                  <div
                    className={classNames('tab', {
                      'tab-active': currentLineChart === 'computeUnits',
                    })}
                    onClick={() => setCurrentLineChart('computeUnits')}
                  >
                    COMPUTE UNITS
                  </div>
                </div>
              </div>
            </div>
            <ApiUsageLineChart
              propertyName={currentLineChart}
              data={usageDetail.analyticsUsageSummary?.usageSegments}
            />
          </div>

          <HorizontalLine className='mt-2' />

          {/* API CALLS BREAKDOWN */}
          <div className='api-table p-4'>
            <div className='text-2xl'>API LIST</div>
            {usageDetail.apiUsageSummary?.apiUsages && (
              <Table
                data={tableData}
                columns={[
                  {
                    Header: 'API NAME',
                    accessor: 'api',
                    Cell: ApiCell,
                  },
                  {
                    Header: 'CREATOR',
                    accessor: 'api',
                    id: 'api2',
                    Cell: CreatorCell,
                  },
                  {
                    Header: 'API CALLS',
                    accessor: 'totalNumOfCallsPerApi',
                  },
                  {
                    Header: 'DATA REFRESH CUs',
                    accessor: 'apiTransformationCU',
                  },
                  {
                    Header: 'INDEXING CUs',
                    accessor: 'apiIndexingCU',
                  },
                  {
                    Header: 'COMPUTE UNITS',
                    accessor: 'totalComputeUnitsPerApi',
                  },
                ]}
              ></Table>
            )}
          </div>
        </div>
      )}
    </LayoutBodyCard>
  );
};
