/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelChangeStatusRequest,
  ModelCommonResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class ChangeApiStatus<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description change api status
   *
   * @tags api
   * @name ChangeApiStatusCreate
   * @summary change api status
   * @request POST:/changeAPIStatus
   */
  changeApiStatusCreate = (
    request: ModelChangeStatusRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelCommonResponse, any>({
      path: `/changeAPIStatus`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
