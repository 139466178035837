/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelReactionSuccessResponse,
  ModelUserReactionsResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Reaction<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description executes a sql query on user api dataset
   *
   * @tags reaction list
   * @name ActionDetail
   * @summary Get list of Reactions for authenitcated user
   * @request GET:/reaction/{resourceType}/action/{reaction}
   */
  actionDetail = (
    resourceType: string,
    reaction: string,
    params: RequestParams = {}
  ) =>
    this.request<ModelUserReactionsResponse, any>({
      path: `/reaction/${resourceType}/action/${reaction}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description executes a sql query on user api dataset
   *
   * @tags reaction
   * @name ActionCreate
   * @summary executes a sql query on user api dataset
   * @request POST:/reaction/{resourceType}/{id}/action/{reaction}
   */
  actionCreate = (
    resourceType: string,
    id: string,
    reaction: string,
    params: RequestParams = {}
  ) =>
    this.request<ModelReactionSuccessResponse, any>({
      path: `/reaction/${resourceType}/${id}/action/${reaction}`,
      method: 'POST',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
