import produce from 'immer';
import { useState } from 'react';
import { RiSaveLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import LoadingIndicator from '../../components/LoadingIndicator';

import Modal from '../../components/modals/Modal';
import { QueryStateV3, useQueryRunV3, useUpdateQuery } from './QueryBuilderHooksV3';
import { useQueryIdV3 } from './QueryPadStateV3';
import useAuth from '../../hooks/auth';
import { COMMUNITY } from '../auth/AuthState';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import envs from '../../config/envs';
import { analytics } from '../../App';
import classNames from 'classnames';
import { useNavigate } from 'react-router';

export default function QueryBuilderSettingModal({
  openSetting,
  setOpenSetting,
  isV2,
}: {
  openSetting?: boolean;
  setOpenSetting: (v: boolean) => void;
  isV2?: boolean;
}) {
  const queryId = useQueryIdV3();
  const [{ rpcStatus }, { updateQuery }] = useUpdateQuery();
  const [values] = useRecoilState(QueryStateV3(queryId));

  const [formValues, setFormValues] = useState(values);
  const [
    {
      rpcStatusDel,
    },
    { deleteQuery },
  ] = useQueryRunV3({ queryPath: '' });
  const navigate = useNavigate();
  const [{ auth }] = useAuth();

  return (
    <Modal open={openSetting}>
      <div className='text-center text-xl font-bold'>Setting</div>
      <div className='flex flex-col gap-3'>
        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Title</span>
          </label>
          <input
            type='text'
            className='input bg-base-300'
            placeholder='Please enter title'
            value={formValues.displayName}
            maxLength={125}
            onChange={(e) => {
              setFormValues(
                produce(formValues, (draft) => {
                  draft.displayName = e.target.value;
                })
              );
            }}
          />
        </div>
        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Description</span>
          </label>
          <textarea
            className='textarea bg-base-300'
            placeholder='Please enter description'
            value={formValues.description}
            onChange={(e) => {
              setFormValues(
                produce(formValues, (draft) => {
                  draft.description = e.target.value;
                })
              );
            }}
          />
        </div>
        {
          !envs.isCircleIntegrationEnabled && <div className='form-control'>
            <label className='label'>
              <span className='label-text flex'>Privacy setting</span>
            </label>
            <div className='form-control'>
              <div className='form-control'>
                <label className='label cursor-pointer'>
                  <span className='label-text text-xs flex items-center'>
                    Private (Only visible to me){' '}
                    {auth?.tenant === COMMUNITY && (
                      <span
                        className='tooltip ml-1 tooltip-warning tooltip-top flex items-center justify-center opacity-90 cursor-pointer'
                        data-tip='Please upgrade the plan to make query private'
                      >
                        <BsFillInfoCircleFill
                          size='0.7rem'
                          className='mx-1 text-blue-600'
                        />
                      </span>
                    )}
                  </span>
                  <input
                    checked={formValues.access === 'private'}
                    disabled={auth?.tenant === COMMUNITY} // NOTE: will also need to check the plan once available
                    onChange={() => {
                      setFormValues(
                        produce(formValues, (draft) => {
                          // @ts-ignore
                          draft.access = 'private';
                        })
                      );
                    }}
                    type='radio'
                    name='access_level'
                    className='radio checked:bg-blue-500'
                  />
                </label>
              </div>
              {auth?.tenant !== COMMUNITY && (
                <div className='form-control'>
                  <label className='label cursor-pointer'>
                    <span className='label-text text-xs'>
                      Org Internal (Visible to my org)
                    </span>

                    <input
                      checked={formValues.access === 'org_internal'}
                      onChange={() => {
                        setFormValues(
                          produce(formValues, (draft) => {
                            // @ts-ignore
                            draft.access = 'org_internal';
                          })
                        );
                      }}
                      type='radio'
                      name='access_level'
                      className='radio checked:bg-blue-500'
                    />
                  </label>
                </div>
              )}
              <div className='form-control'>
                <label className='label cursor-pointer'>
                  <span className='label-text text-xs'>
                    Public (Visible to everyone)
                  </span>
                  <input
                    checked={formValues.access === 'public'}
                    onChange={() => {
                      setFormValues(
                        produce(formValues, (draft) => {
                          // @ts-ignore
                          draft.access = 'public';
                        })
                      );
                    }}
                    type='radio'
                    name='access_level'
                    className='radio checked:bg-blue-500'
                  />
                </label>
              </div>
            </div>
          </div>
        }

        <div className='flex gap-2 items-center'>
          <button
            className='btn btn-primary gap-4'
            onClick={async () => {
              analytics.track('Query - Update query settings', {
                updated: true,
                values: formValues,
                fromV2: isV2,
              })
              const success = await updateQuery(formValues);
              if (success) {
                setOpenSetting(false);
                toast.success('Query had been saved!', {
                  autoClose: 2000,
                });
              }
            }}
          >
            <RiSaveLine />
            Save
          </button>
          {
            isV2 && <button
              type='button'
              className={classNames('btn btn-warning', {
                loading: rpcStatusDel.isLoading,
              })}
              onClick={async () => {
                const success = await deleteQuery();
                if (success) {
                  // track('Query - Delete query', {
                  //   from: 'query-builder',
                  //   queryId,
                  // })
                  navigate('/v2/workspace/queries');
                }
              }}
            >
              Delete
            </button>
          }
          <button
            className='btn btn-ghost'
            style={{ border: '1px solid #aaaaaa' }}
            onClick={() => {
              setOpenSetting(false);
            }}
          >
            Cancel
          </button>
          {rpcStatus.isLoading && <LoadingIndicator />}
        </div>
      </div>
    </Modal>
  );
}
