/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useState, useEffect, useMemo } from "react"
import { getAllCategories, getAllTables } from "./api"
import { getTableDetailById } from "../../workspace/apis"

interface CatalogContextProps {
  categories: any,
  category: string,
  tableKeyword: string,
  setCataegory: (category: string) => void
  setTableKeyword: (keyword: string) => void
  isLoading: boolean,
  setIsLoading: (loading: boolean) => void
  table: any,
  setTable: (table: string) => void,
  tables: any
  tableDetail: any,
  chain: string,
  setChain: (chain: string) => void,
  // keywords
  categoryKeyword: string,
  setCategoryKeyword: (keyword: string) => void
  columnKeyword: string,
  setColumnKeyword: (keyword: string) => void
  fetchAndSetTableDetail: (tableId: string) => Promise<any>
}

export const CatalogContext = createContext<CatalogContextProps>({
  categories: [],
  category: '',
  setCataegory: () => { },
  tableKeyword: '',
  setTableKeyword: () => { },
  isLoading: false,
  setIsLoading: () => { },
  table: '',
  setTable: () => { },
  tables: {},
  tableDetail: [],
  setChain: () => { },
  chain: '',
  // keywords
  categoryKeyword: '',
  setCategoryKeyword: () => { },
  columnKeyword: '',
  setColumnKeyword: () => { },
  fetchAndSetTableDetail: () => Promise.resolve()
})


export const CatalogProvider = ({ children }: { children: React.ReactNode }) => {

  const [categories, setCategories] = useState<any>([])
  const [category, setCataegory] = useState<string>('')
  const [table, setTable] = useState<any>({})
  const [tables, setTables] = useState<any>({})
  const [tableKeyword, setTableKeyword] = useState<string>('')
  const [categoryKeyword, setCategoryKeyword] = useState<string>('')
  const [columnKeyword, setColumnKeyword] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tableDetail, setTableDetail] = useState<any>([])
  const [chain, setChain] = useState<string>('')

  const fetchAndSetCategories = async () => {
    setIsLoading(true)
    const allCategories = await getAllCategories()
    setCategories(allCategories)
    setIsLoading(false)
  }

  const fetchAndSetTableDetail = async (tableId?: string) => {
    setIsLoading(true)
    const resp = await getTableDetailById(tableId || table.id)
    setTableDetail(resp.column || [])
    setIsLoading(false)
    
    return resp.column
  }

  const fetchAndSetTables = async () => {
    setIsLoading(true)
    const allTables = await getAllTables()
    setTables(allTables)
    setIsLoading(false)
  }

  useEffect(() => {
    if (!table.id) return
    fetchAndSetTableDetail()
  }, [table])

  useEffect(() => {
    fetchAndSetCategories()
    fetchAndSetTables()
  }, [])



  const value = useMemo(() => ({
    categories,
    category,
    setCataegory,
    tableKeyword,
    setTableKeyword,
    isLoading,
    setIsLoading,
    table,
    setTable,
    tables,
    tableDetail,
    setChain,
    chain,
    categoryKeyword,
    setCategoryKeyword,
    columnKeyword,
    setColumnKeyword,
    fetchAndSetTableDetail,
  }), [
    categories,
    category,
    setCataegory,
    tableKeyword,
    setTableKeyword,
    isLoading,
    setIsLoading,
    table,
    tables,
    setTable,
    tableDetail,
    chain,
    setChain,
    categoryKeyword,
    setCategoryKeyword,
    columnKeyword,
    setColumnKeyword,
    fetchAndSetTableDetail,
  ])

  return (
    <CatalogContext.Provider value={value}>
      {children}
    </CatalogContext.Provider>
  )
}
