/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import classNames from "classnames"
import { debounce } from "lodash"
import { useCallback, useMemo, useRef, useState } from "react"
import { AiOutlineTable } from "react-icons/ai"
import { BiCloud } from "react-icons/bi"
import { BsArrowRight } from "react-icons/bs"
import { IoIosSearch } from "react-icons/io"
import { RiDashboardFill } from "react-icons/ri"
import { getSearchResult } from "./api"
import { ModelSearchResponse } from "../../../api/__gen__/data-contracts"
import { Link, useNavigate } from "react-router-dom"
import useOutsideAlerter from "../../../modules/queryBuilder/utils/useOutsideAlerter"
import Icon from "../Icon"
import { track } from "../../tracking"


interface ResultRowProps {
  type: 'graphql_res' | 'tables' | 'dashboards' | 'queries'
  description?: string
  name: string
  path?: string
}
const defaultResult: ModelSearchResponse = {
  graphql_res: [],
  tables: [],
  dashboards: [],
  queries: []
}

const ResultRow = ({
  type,
  description,
  name,
  path = '/v2/explore'
}: ResultRowProps) => {
  const renderIcon = () => {
    const commonClassName = 'text-[#767d8b] mr-2'
    switch (type) {
      case 'graphql_res':
        // <AiOutlineTable
        return <Icon className="min-w-[16px] h-[16px]" iconName="cloud-" />
      case 'tables':
        return <Icon className="w-[16px] h-[16px]" iconName="docs" />
      case 'dashboards':
        return <Icon className="w-[16px] h-[16px]" iconName="window" />
      case 'queries':
        return <Icon className="w-[16px] h-[16px]" iconName="top" />
      default:
        return null
    }
  }

  const link = type === 'queries' ? path : type === 'graphql_res' ? `/v2/explore/graphql-apis/${path}` : `/v2/explore/${type}/${path}`
  return <Link to={link}>
    <div className="flex items-center py-2 hover:bg-[#f6f7f9] px-3 hover:cursor-pointer gap-[10px]">
      <div className="w-[16px] h-[16px]">
        {renderIcon()}
      </div>
      <span className="h-[20px] text-clip overflow-hidden">
        {name}
      </span>
    </div>
  </Link>
}


const SearchBar = ({
  width = 'w-1/3',
  showBorder = true
}: {
  showBorder?: boolean
  width?: string
}) => {

  const navigate = useNavigate()
  const ref = useRef(null)



  const [searchResult, setSearchResult] = useState<ModelSearchResponse>(defaultResult)
  const [isLoading, setIsLoading] = useState(false)

  // TODO: make this as a provider and use it in other components
  const fetchAndSetResult = useCallback(debounce((keyword: string) => {
    setIsLoading(true)
    getSearchResult(keyword).then(res => {
      setSearchResult(res.data)

      setIsLoading(false)
    })
  }, 600), [])

  const [keyword, setKeyword] = useState('')
  useOutsideAlerter(ref, () => {
    setKeyword('')
    setSearchResult(defaultResult)
  })



  const showResult = useMemo(() => {
    return keyword.length > 1 && !isLoading
  }, [keyword, isLoading])

  const debounceSetKeyword = (value: string) => {
    setKeyword(value)
    if (value.length > 1) {
      fetchAndSetResult(value)
    }
  }


  const navigateToSearchResult = (type: string) => {
    track('Search Result Clicked', {
      type,
      keyword
    })

    navigate(`/v2/search-results/?keyword=${keyword}&type=${type}`)
  }

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter' && keyword.length > 1) {
      setKeyword('')
      setSearchResult(defaultResult)
      track('Search Result Clicked', {
        type: 'all',
        keyword
      })
      navigate(`/v2/search-results/?keyword=${keyword}`)
    }
  }

  return <div className="text-center h-[30px] bg-[#E3E6ED]" ref={ref} >
    <div className={classNames("min-w-96 relative mx-auto bg-[#fff]", width)}>
      <div className={classNames("flex items-center px-3 pl-[10px] bg-[#f4f4f4] rounded-[4px] ml-[5px]", {
        // 'rounded-b': !showResult,
        'border': showBorder,
      })}>
        <IoIosSearch size={16} />
        <input
          value={keyword}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            debounceSetKeyword(e.target.value)
          }} type="text" placeholder="Type Whole Word to Search for APIs, Tables and Queries" className="h30 h-[30px] focus:outline-none input w-full bg-transparent text-[12px] pl-[10px] py-0 outline-none	" />
      </div>
      {
        showResult && <div className="absolute bg-[#fff] z-10 w-full top-[41px] border border-t-0 left-0" onClick={() => {
          setKeyword('')
          setSearchResult(defaultResult)
        }}>
          <div>
            {/* API */}
            <div className="border-b py-2">
              <div className="flex justify-between items-center py-1 font-semibold px-3 cursor-pointer" onClick={() => {
                navigateToSearchResult('graphql_res')
              }}>
                <span className="text-[#767d8b]">API</span>
                <BsArrowRight />
              </div>
              {
                searchResult?.graphql_res?.map((api, index) => {
                  if (index < 3)
                    return <ResultRow key={index} name={api.display_name || ''} type="graphql_res" path={`${api.id}/overview`} />

                  return null
                })
              }
            </div>
            {/* Table */}
            <div className="border-b py-2">
              <div className="flex justify-between items-center py-1 font-semibold px-3 cursor-pointer" onClick={() => {
                navigateToSearchResult('tables')
              }}>
                <span className="text-[#767d8b]">Table</span>
                <BsArrowRight />
              </div>
              {
                searchResult?.tables?.map((table, index) => {
                  if (index < 3)
                    return <ResultRow key={index} name={table.name || ''} type="tables" path={`${table.project_id || 'default'}/${table.id}`} />

                  return null
                })
              }
            </div>
            { /* Query */}
            <div className="border-b py-2">
              <div className="flex justify-between items-center py-1 font-semibold px-3 cursor-pointer" onClick={() => {
                navigateToSearchResult('queries')
              }}>
                <span className="text-[#767d8b]">Query</span>
                <BsArrowRight />
              </div>
              {

                searchResult?.queries?.map((query, index) => {
                  if (index < 3)
                    return <ResultRow key={index} name={query.display_name || ''} type="queries" path={`/v2/build/${query.id}`} />
                  return null
                })

              }
            </div>
            {/* Dashboard */}
            <div className="border-b py-2">
              <div className="flex justify-between items-center py-1 font-semibold px-3 cursor-pointer" onClick={() => {
                navigateToSearchResult('dashboards')
              }}>
                <span className="text-[#767d8b]">Dashboard</span>
                <BsArrowRight />
              </div>
              {
                searchResult?.dashboards?.map((dashboard, index) => {
                  if (index < 3)
                    return <ResultRow key={index} name={dashboard.display_name || ''} type="dashboards" path={`${dashboard.id}`} />

                  return null
                })
              }
            </div>
          </div>
        </div>
      }
    </div>
  </div >
}

export default SearchBar