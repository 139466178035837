import { AxiosError } from 'axios';
import { orderBy } from 'lodash';
import { useState, useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import { MyApi } from '../../api/client';
import { ModelListChartItem } from '../../api/__gen__/data-contracts';
import { ChartEntity } from '../chart/types';
import { convertDBChartToUIChart } from '../chart/utils';

export const AMyCharts = atom<ChartEntity[]>({
  key: 'AMyCharts',
  default: [],
});

export async function fetchMyCharts() {
  try {
    const client = MyApi();
    const resp = await client.chartsList();

    return resp;
  } catch (error) {
    // eslint-disable-next-line
    console.error('query error: fetching charts', error);

    if (error instanceof AxiosError) {
      return {
        ...error.response,
        error: `${error?.response?.data?.message}`,
      };
    }
  }

  return null;
}

export function useMyCharts(open: boolean) {
  const [myCharts, setMyCharts] = useRecoilState(AMyCharts);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function init() {
      setLoading(true);

      const resp = await fetchMyCharts();
      // TODO move sorting in backend later
      const charts = resp?.data?.items as ModelListChartItem[];
      const uiCharts = charts.map(convertDBChartToUIChart);
      setMyCharts(orderBy(uiCharts, 'createdTime', 'desc'));

      setLoading(false);
    }

    if (open) init();
  }, [open]);

  return [myCharts, { loading }] as const;
}
