/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react"
import { Link, NavLink } from "react-router-dom"

// import mail from './m.svg'
// import slack from './sk.svg'
// import telegram from './tg.svg'
// import okc from './download.png'
import moment from "moment"
import classNames from "classnames"
import Modal from "../../components/modals/Modal"
import Logs from "./components/Logs"
import { UserApiV2 } from "../../api/client"

const processURL = (url: string) => {
  const decodedURL = decodeURIComponent(url)
  const { hostname } = new URL(decodedURL)
  return hostname.length > 18 ? `${hostname.slice(0, 10)}...${hostname.slice(hostname.length - 10)}` : hostname
}


const GRANULARITY_MAP = {
  '': 'N/A',
  PerBatch: 'Per Batch',
  PerRecord: 'Per Record',
}

export const Triggers = ({ data, baseUrl, apiId }: { apiId: string, data: any[], baseUrl: string }) => {

  const [list, setList] = useState([...data])
  const [logs, setLogs] = useState<any[]>([])
  const [showLogs, setShowLogs] = useState(false)

  const fetchLogs = async (id: string, triggerId: string) => {
    const client = UserApiV2();
    const res = await client.triggerEventsDetail(id, triggerId)
    setShowLogs(true)
    setLogs(res.data.items || [])
  }

  useEffect(() => {
    setList([...data])
  }, [data])

  // const renderIcon = (name: string): JSX.Element | null => {
  //   switch (name) {
  //     case 'slack':
  //       return <img src={slack} />
  //     case 'telegram':
  //       return <img src={telegram} />
  //     case 'mail':
  //       return <img src={mail} />
  //     case 'OKX':
  //       return <img src={okc} width={20} />
  //     default:
  //       return null;
  //   }

  // }

  return <div className='flex flex-col w-full p-3 overflow-x-auto'>
    <div className='text-right m-4'>
      <NavLink
        to={`${baseUrl}create-trigger`}
      >
        <button className='btn btn-primary'>
          Create a Trigger
        </button>
      </NavLink>
    </div>
    <table className='table w-full'>
      <thead className='rounded-full w-full'>
        <tr className='w-full font-semibold opacity-30'>
          <th className='p-2 w-40'>Name</th>
          <th className='p-2 w-16 text-center'>Destination</th>
          <th className='p-2 w-16 text-center'>Status</th>
          <th className='p-2 text-left'>Destination URL</th>
          <th className='p-2 text-left'>Granularity</th>
          <th className='p-2 text-left'>Created Time</th>
          <th className='p-2 text-left'>Updated Time</th>
          <th className='p-2 text-left'>Deleted Time</th>
          <th className='p-2 text-left'>Logs</th>
        </tr>
      </thead>
      <tbody className='rounded-full w-full'>
        {list.map(
          ({
            id,
            destinationURL,
            updatedTime,
            displayName,
            granularity,
            createdTime,
            deletedTime,
          }) => (
            <tr
              key={id}
              className='font-semibold items-center overflow-hidden'
            >
              <td className={classNames('text-sm font-normal p-2 overflow-hidden', !deletedTime && 'text-primary')}>
                {
                  deletedTime ? displayName : <Link to={`${baseUrl}edit-trigger/${id}`}>{displayName}</Link>
                }
              </td>

              <td className='text-sm font-normal p-2 overflow-hidden text-center flex justify-center'>
                {/* {renderIcon('webhook')} */}
                Webhook
              </td>
              <td className='text-sm font-normal p-2 overflow-hidden text-center'>
                {
                  deletedTime ? <div className='bg-[#f5e3df] text-white text-[10px] text-[#b24d37] font-bold border-transparent badge'>DELETED</div> : <div className='bg-[#e9fac8] text-white text-[10px] text-[#5c940d] font-bold border-transparent badge'>ACTIVE</div>
                }
              </td>
              <td className='text-sm font-normal p-2 overflow-hidden text-left overflow-auto'>
                <a title={decodeURIComponent(destinationURL)} href={decodeURIComponent(destinationURL)} className="text-primary" target="_blank" rel="noreferrer">
                  {
                    processURL(destinationURL)
                  }
                </a>
              </td>
              <td className='text-sm font-normal p-2 overflow-hidden text-left'>
                {granularity && GRANULARITY_MAP[granularity as keyof typeof GRANULARITY_MAP]}
              </td>
              <td className='text-sm font-normal p-2 text-left'>
                {moment(createdTime).format('DD MMM YYYY hh:mm')}
              </td>
              <td className='text-sm font-normal p-2 text-left'>
                {moment(updatedTime).format('DD MMM YYYY hh:mm')}
              </td>
              <td className='text-sm font-normal p-2 text-left'>
                {!!deletedTime && moment(deletedTime).format('DD MMM YYYY hh:mm')}
              </td>
              <td className='text-sm p-2 text-right text-primary font-semibold cursor-pointer'>
                <span onClick={() => {
                  fetchLogs(apiId, id)
                }}>View</span>
              </td>
            </tr>
          )
        )}
        {
          !list.length && <tr className='font-semibold items-center overflow-hidden'>
            <td className='text-sm font-normal p-2 pt-6 overflow-hidden text-center' colSpan={8}>
              No triggers found
            </td>
          </tr>
        }
      </tbody>
    </table>
    <Modal className="max-w-[1200px]" open={showLogs} onClickBackdrop={() => {
      setShowLogs(false)
    }}>
      <Logs logs={logs} />
    </Modal>
  </div>
}