import classNames from 'classnames';
import { HTMLAttributes, memo, useMemo, useState } from 'react';
// import { BsFillPersonFill } from 'react-icons/bs';
import useAuth from '../../hooks/auth';
import { ModelUser } from '../../api/__gen__/data-contracts';

function ProfileAvatar({
  className = 'bg-neutral-focus w-10',
  creator
}: {
  creator?: ModelUser | undefined;
} & HTMLAttributes<HTMLDivElement>) {
  const [showInitial, setShowInitial] = useState(false);
  const [{ auth }] = useAuth()

  const initial = useMemo(() => {
    if (creator && creator.name) {
      return <div>
        {creator?.name?.split(' ').map((e: string) => e.charAt(0))}
      </div>
    }
    return '';
  }, [creator, auth])

  if (!creator?.profileImage) {
    return (
      <div className='avatar placeholder'>
        <div
          className={classNames('text-neutral-content rounded-full', className)}
        >
          {/* <BsFillPersonFill size='1rem' /> */}
          {initial}
        </div>
      </div>
    );
  }

  return (
    <div className='avatar placeholder'>
      <div
        className={classNames('text-neutral-content rounded-full', className)}
      >
        {showInitial ? initial : <img onError={() => { setShowInitial(true) }} src={creator?.profileImage} referrerPolicy='no-referrer' />}
      </div>
    </div>
  );
}

export default memo(ProfileAvatar);
