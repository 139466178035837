/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { useNavigate, useParams } from 'react-router-dom';

import backBtnIcon from '../components/back.svg';
import { GRAPHQL_APIS } from '../../../Explore';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { AuthState } from '../../../../modules/auth/AuthState';
import classNames from 'classnames';
import envs from '../../../../config/envs';
import {
  createQueryPrompt,
  deleteQueryPrompt,
  getAllQueryPrompts,
  searchQueryPrompts,
  updateQueryPrompt,
} from '../../../../modules/realtime-api/apis';
import {
  ModelCreateQueryPromptRequest,
  ModelQueryPrompt,
} from '../../../../api/__gen__/data-contracts';
import { Button, Form, Input, Modal, Popconfirm, Select } from 'antd';
// import { GraphiQL } from "../../../../modules/dataset/GraphqlV4";
import ReactCodeMirror from '@uiw/react-codemirror';
import { useRecoilValue } from 'recoil';
import { codemirrorTheme } from '../../../../config/theme';
import { GraphiQLEditor } from '../../../../components/graphiql-editor';

const CHAINS = {
  ethereum: {
    desc: 'Real-time data from the Ethereum blockchain',
    title: 'Ethereum',
    short_desc: 'short desc',
    img: GRAPHQL_APIS[0].image,
  },
  sui: {
    desc: 'Real-time data from the Sui Chain',
    title: 'Sui',
    short_desc: 'short desc',
    img: GRAPHQL_APIS[1].image,
  },
  stellar: {
    desc: 'Real-time data from the Stellar Chain',
    title: 'Stellar',
    short_desc: 'short desc',
    img: GRAPHQL_APIS[2].image,
  },
  defi: {
    desc: 'Real-time data from the DeFi Chain',
    title: 'DeFi',
    short_desc: 'short desc',
    img: GRAPHQL_APIS[3].image,
  },
};
function getChainApiPrefixs(id: string) {
  return {
    [id]: id,
    stellar: 'table/realtime/stellar_mainnet',
    defi: 'table/realtime/defi',
  };
}

const EXAMPLES = {
  ethereum: [
    {
      title: 'Get the last 10 block details',
      query: `query MyQuery {
  blocks(orderBy: number, orderDirection: desc, first: 10) {
    block_date
    difficulty
    extra_data
    extra_data_raw
    gas_limit
    gas_used
    hash
    logs_bloom
    miner
    mix_hash
    nonce
    num_of_transactions
    number
    parent_hash
    receipts_root
    sha3_uncles
    size
    state_root
    timestamp
    total_difficulty
    transactions_root
  }
}
    `,
    },
    {
      title: 'Get single transaction by hash',
      query: `query MyQuery {
  transaction(hash: "0x3522d45fb0a74affbe8282116ff6d5b062e57c08ac1b7cdd86a012c056dbf714") {
    hash
    block_hash
    block_number
    block_time
    decoded_from_abi
    from_address
    func_name
    func_signature
    gas_limit
    gas_price
    gas_used
    input
    nonce
    status
    to_address
    transaction_index
    type
    value
    argument_names
    argument_types
    argument_values
  }
}`,
    },
    {
      title: 'Get ERC20 token balance for a wallet',
      query: `query MyQuery {
  token_balance(contract_address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599", address: "0xdbe38d1d3631a04875d3fad945d0dfcb2c20f91c")
}
    `,
    },
    {
      title: 'Get latest token balance records',
      query: `query MyQuery {
  token_balances(first: 20) {
    address
    contract_address
    value
    token_metadata {
      decimals
      symbol
      name
    }
  }
}`,
    },
  ],
  sui: [
    {
      title: 'Get a checkpoint by sequence number',
      query: `query {
  checkpoint(sequence_number: 18932442) {
    sequence_number
    digest
    previous_digest
    epoch
    total_gas_cost
    total_computation_cost
    total_storage_cost
    total_storage_rebate
    non_refundable_storage_fee
    num_of_transaction_blocks
    network_total_transactions
    timestamp
    checkpoint_commitments
    validator_signature
    end_of_epoch
    end_of_epoch_data
    block_date                         
  }
}`,
    },

    {
      title: 'Get all transactions by block digest',
      query: `query {
  transactions(
      where: {
          transaction_block_digest: {eq:"4zHR441o8mASWJQhH3KSLfHJNjJguKjt8v73HjDWC8g"}}, 
      first:10) {
      transaction_block_digest
      checkpoint_sequence_number
      checkpoint_digest
      block_time
      index
      type
      data
      package
      module
      function
      arguments
      type_arguments
      block_date
  }
}`,
    },
  ],
};

const URLDisplay = ({ id }: { id: string }) => {
  const apiPrefixs = getChainApiPrefixs(id);
  id = apiPrefixs[id];
  return (
    <CopyToClipboard text={`${envs.ZettaBackendApiPrivate}/${id}/graphql`}>
      <div
        className='min-w-[600px] w-[600px] p-[6px] border rounded bg-[#fafafa] flex items-center gap-[10px] cursor-pointer'
        onClick={() => {
          toast.success('Copied to clipboard', {
            autoClose: 2000,
            position: 'top-center',
          });
        }}
      >
        <div className='bg-[#e5e7fb] text-[#263dff] text-[10px] py-[4px] px-[10px] border-[#263dff] border rounded w-[80px] h-[20px] leading-[10px] font-bold'>
          Query URL
        </div>
        <div className='text-[12px] text-[#1A1A1A] font-normal'>
          {`${envs.ZettaBackendApiPrivate}/${id}/graphql`}
        </div>
      </div>
    </CopyToClipboard>
  );
};

export const GQLProjectHeader = ({
  id,
  extra,
}: {
  id?: string;
  extra?: ReactNode;
}) => {
  const navigate = useNavigate();
  if (!id) return null;

  return (
    <div className='min-w-[1240px] py-[18px]  px-[30px] border-b flex justify-between'>
      <div className='flex gap-[30px]'>
        <div
          className='cursor-pointer w-[30px] h-[30px]'
          onClick={() => {
            navigate('/v2/explore/projects');
          }}
        >
          <img src={backBtnIcon} />
        </div>
        <div className='flex gap-[15px] w-[570px] min-w-[570px]'>
          <img className='w-[60px] h-[60px]' src={CHAINS[id].img} />
          <div className='h-full'>
            <div className='items-center flex mb-[10px]'>
              <div className='text-[16px] font-semibold'>
                {CHAINS[id].title}
              </div>
              <div className='w-[1px] bg-[#F2F2F2] h-[20px] mx-[10px]'></div>
              <div className='text-[12px] text-[#757D8D]'>
                {CHAINS[id].desc}
              </div>
            </div>
            <URLDisplay id={id || ''} />
            {/* <div className="text-[14px] text-[#777A80]">
            {
              CHAINS[id].desc
            }
          </div> */}
          </div>
        </div>
      </div>
      <div>{extra}</div>
    </div>
  );
};

const GraphQlProject = () => {
  const { id } = useParams();

  const [currentUseTab, setCurrentUseTab] = useState('example');
  const [showModal, setShowModal] = useState(false);

  const [queryPromptValues, setQueryPromptValues] =
    useState<ModelCreateQueryPromptRequest>({
      chain: '',
      description: '',
      name: '',
      query: '',
      tags: [],
      type: '',
    });

  const [allQueryPrompts, setAllQueryPrompts] = useState<{
    ethereum: ModelQueryPrompt[];
    sui: ModelQueryPrompt[];
    stellar: ModelQueryPrompt[];
    defi: ModelQueryPrompt[];
  }>({
    ethereum: [],
    sui: [],
    stellar: [],
    defi: [],
  });
  const getAndSetAllQueryPrompts = async () => {
    const data = await getAllQueryPrompts();
    setAllQueryPrompts(data);
  };

  const auth = useRecoilValue(AuthState);
  useEffect(() => {
    if (auth?.tenant === 'zettablock.com') {
      getAndSetAllQueryPrompts();
    }
  }, [auth]);
  const [active, setActive] = useState(false);

  const [currentQueryPrompt, setCurrentQueryPrompt] = useState('');

  const renderTabContent = useCallback(() => {
    if (currentUseTab === 'example') {
      return (
        <div className='py-[20px] px-[15px]'>
          <Button
            onClick={() => {
              setShowModal(true);
              setCurrentQueryPrompt('');
              setQueryPromptValues({
                chain: '',
                description: '',
                name: '',
                query: '',
                tags: [],
                type: '',
              });
            }}
          >
            Add Prompt
          </Button>

          {allQueryPrompts[id || 'sui'].map((e) => {
            return (
              <div className='mb-[30px]' key={e.id}>
                <div className='text-semibold mb-[10px] flex items-center justify-between'>
                  {e.name}
                  <div className='flex items-center gap-[10px]'>
                    <Button
                      onClick={() => {
                        setCurrentQueryPrompt(e.id);
                        setShowModal(true);
                        setQueryPromptValues({
                          chain: e.chain,
                          description: e.description,
                          name: e.name,
                          query: e.query,
                          tags: e.tags,
                          type: e.type,
                        });
                      }}
                    >
                      Edit
                    </Button>
                    <Popconfirm
                      title='Are you sure to delete this prompt?'
                      onConfirm={async () => {
                        await deleteQueryPrompt(e.id);
                        await getAndSetAllQueryPrompts();
                      }}
                      okText='Yes'
                      cancelText='No'
                    >
                      <Button>Delete</Button>
                    </Popconfirm>
                  </div>
                </div>
                <div className='min-h-[150px] w-[350px] bg-[#fafafa] border-[#E3E6ED] border rounded'>
                  <ReactCodeMirror theme={codemirrorTheme} value={e.query} />
                </div>
              </div>
            );
          })}

          {false &&
            EXAMPLES[id || 'sui'].map((e) => {
              return (
                <div className='mb-[30px]'>
                  <div className='text-semibold mb-[10px]'>{e.title}</div>
                  <div className='min-h-[150px] w-[350px] bg-[#fafafa] border-[#E3E6ED] border rounded'>
                    <ReactCodeMirror theme={codemirrorTheme} value={e.query} />
                  </div>
                </div>
              );
            })}
        </div>
      );
    }

    return null;
  }, [currentUseTab, allQueryPrompts]);

  const apiPrefixs = getChainApiPrefixs(id!);
  const apiId = apiPrefixs[id!];
  return (
    <div className=''>
      <GQLProjectHeader
        id={id}
        extra={
          <button
            className={classNames('btn btn-outline', {
              'btn-active': active,
            })}
            onClick={() => {
              setActive(!active);
            }}
          >
            Example
          </button>
        }
      />
      {/* <button className="btn btn-primary ml-2 mt-2" onClick={() => {
      setShowModal(true)
    }}>
      Add Prompt
    </button> */}
      {/* GQL Content */}
      <div className='min-w-[1240px] flex'>
        <div className='min-w-[860px] w-[100%] min-h-[700px] h-[calc(100vh-160px)] border-r'>
          {/* /v2/explore/graphql/:id */}
          <GraphiQLEditor
            apiUrl={`${envs.ZettaBackendApiPrivate}/${apiId}/graphql`}
          />
        </div>
        {auth?.tenant === 'zettablock.com' && active && (
          <div className='min-w-[380px] w-[380px] border-r  h-[calc(100vh-160px)] overflow-auto'>
            {/* TAB HEADER */}
            <div className='border-b '>
              <div
                role='tablist'
                className='tabs tabs-bordered gap-[25px] px-[10px] border-b'
              >
                <a
                  role='tab'
                  className={classNames(
                    'text-lg font-semibold tab py-3 px-1 mb-[-2px] h-auto border-b border-transparent',
                    {
                      'tab-active border-[#263dff]':
                        currentUseTab === 'example',
                    }
                  )}
                  onClick={() => {
                    setCurrentUseTab('example');
                  }}
                >
                  Example
                </a>
                {/* <a role="tab" className={
              classNames('text-lg font-semibold tab py-3 px-1 mb-[-2px] h-auto border-b border-transparent', {
                'tab-active border-[#263dff]': currentUseTab === 'code'
              })}
              onClick={() => {
                setCurrentUseTab('code')
              }}
            >
              Code
            </a> */}
              </div>
            </div>
            {/* TAB CONTENT */}
            {renderTabContent()}
          </div>
        )}
      </div>

      <Modal
        title='Add Prompt'
        open={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        onOk={async () => {
          if (currentQueryPrompt) {
            // Update
            await updateQueryPrompt(currentQueryPrompt, queryPromptValues);
          } else {
            await createQueryPrompt(queryPromptValues);
          }
          await getAndSetAllQueryPrompts();
          setShowModal(false);
        }}
      >
        <Form.Item label='Chain'>
          <Select
            value={queryPromptValues.chain}
            onChange={(e) => {
              setQueryPromptValues({
                ...queryPromptValues,
                chain: e,
              });
            }}
          >
            <Select.Option value='ethereum'>Ethereum</Select.Option>
            <Select.Option value='sui'>Sui</Select.Option>
            <Select.Option value='stellar'>Stellar</Select.Option>
            <Select.Option value='defi'>DeFi</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label='Name'>
          <Input
            value={queryPromptValues.name}
            onChange={(e) => {
              setQueryPromptValues({
                ...queryPromptValues,
                name: e.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item label='Description'>
          <Input.TextArea
            value={queryPromptValues.description}
            onChange={(e) => {
              setQueryPromptValues({
                ...queryPromptValues,
                description: e.target.value,
              });
            }}
          />
        </Form.Item>
        <Form.Item label='Query'>
          <div className='gql-editor-wrapper h-[200px]'>
            <ReactCodeMirror
              theme={codemirrorTheme}
              value={queryPromptValues.query}
              onChange={(e) => {
                setQueryPromptValues({
                  ...queryPromptValues,
                  query: e,
                });
              }}
            />
          </div>
        </Form.Item>
        <Form.Item label='Tags'>
          <Input
            value={queryPromptValues.tags?.join(',')}
            onChange={(e) => {
              setQueryPromptValues({
                ...queryPromptValues,
                tags: e.target.value.split(','),
              });
            }}
          />
        </Form.Item>
      </Modal>
    </div>
  );
};

export default GraphQlProject;
