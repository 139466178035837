import { atom, useRecoilState } from 'recoil';
import { ModelListQueryResponse } from '../../api/__gen__/data-contracts';
import { useRpcStatus } from '../../hooks/network';
import { fetchCommunityQueries, fetchMyQueries } from './QueriesRpc';

export const MyQueriesState = atom<ModelListQueryResponse>({
  key: 'MyQueriesState',
  default: { items: [] },
});

export const CommunityQueriesState = atom<ModelListQueryResponse>({
  key: 'CommunityQueriesState',
  default: { items: [] },
});

export const useMyQueries = () => {
  const [rpcStatus, setRpcStatus] = useRpcStatus();
  const [myQueries, setMyQueries] = useRecoilState(MyQueriesState);

  async function getMyQueries() {
    if (rpcStatus?.isLoading) {
      return;
    }

    setRpcStatus({
      isLoading: true,
      error: '',
    });

    const resp = await fetchMyQueries();
    const error = resp?.data.error;

    if (error) {
      // error
      setRpcStatus({
        isLoading: false,
        error,
      });
    } else {
      setRpcStatus({
        isLoading: false,
      });

      // success
      const data = resp?.data as ModelListQueryResponse;
      setMyQueries(data);
    }
    setRpcStatus({
      isLoading: false,
      error,
    });
  }

  return [
    { myQueries, rpcStatus },
    { setRpcStatus, getMyQueries },
  ] as const;
};

export const useCommunityQueries = () => {
  const [rpcStatus, setRpcStatus] = useRpcStatus();
  const [communityQueries, setCommunityQueries] = useRecoilState(
    CommunityQueriesState
  );

  async function getCommunityQueries() {
    if (rpcStatus?.isLoading) {
      return;
    }

    setRpcStatus({
      isLoading: true,
      error: '',
    });

    const resp = await fetchCommunityQueries();
    const error = resp?.data.error;

    if (error) {
      // error
      setRpcStatus({
        isLoading: false,
        error,
      });
    } else {
      setRpcStatus({
        isLoading: false,
      });

      // success
      const data = resp?.data as ModelListQueryResponse;
      setCommunityQueries(data);
    }
    setRpcStatus({
      isLoading: false,
      error,
    });
  }

  return [
    { communityQueries, rpcStatus },
    { setRpcStatus, getCommunityQueries },
  ] as const;
};
