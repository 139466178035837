import { atom, useRecoilValue } from 'recoil';
import Cookies from 'universal-cookie';
import { ModelSubscription } from '../../api/__gen__/data-contracts';

export const COMMUNITY = 'community';

export type UserAuth = {
  accessToken: string;
  id: string;
  email?: string;
  name?: string;
  profileImage?: string;
  tenant?: string;
  defaultApiKey?: string;
  subscription?: ModelSubscription;
  whitelisted?: boolean
};

export const AuthState = atom<UserAuth | undefined | null>({
  key: 'AuthState',
  default: undefined,
});

export function useAuthHeaders() {
  const cookie = new Cookies();
  const cookieAuth = cookie.get('auth');

  const auth = useRecoilValue(AuthState);
  if (auth) {
    const { accessToken } = auth;
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  if (cookieAuth && cookieAuth.username) {
    const token = `${window.btoa(
      `${cookieAuth.username}:${cookieAuth.password}`
    )}`;
    return {
      Authorization: `Basic ${token}`,
    };
  }

  return {};
}
