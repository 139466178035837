import { cloneDeep } from 'lodash';
import { HTMLAttributes, lazy, memo, Suspense } from 'react';
import LogoBlinkerCenter from '../../../components/app/LogoBlinkerCenter';
import { removeKeyRecursive } from '../../../utils/object';
import { QueryResultResult } from '../../queryBuilder/QueryState';
import { LineChartType } from '../types-v2';
import { connectEChartData } from '../utils/echart';

const EChartV2 = lazy(() => import('./EChartV2'));

interface Props {
  queryResults?: QueryResultResult;
  chart: LineChartType;
  loading?: boolean;
}

function LineChart({
  queryResults,
  chart,
  loading,
}: HTMLAttributes<HTMLTableElement> & Props) {
  if (loading) {
    return <LogoBlinkerCenter />;
  }

  interface RowData {
    [n: string]: string | number;
  }
  const fillEmptyDataDates = (
    rows: Record<string, string | number>[] | undefined,
    xCol?: string,
    groupBy?: string
  ) => {
    if (!rows || rows.length === 0 || !groupBy || !xCol) return [];

    const groupByMap = new Map();
    const allValueSet = new Set();

    rows.forEach((row) => {
      const groupByKey = row[groupBy];
      const xColVal = row[xCol];
      allValueSet.add(xColVal);
      if (!groupByMap.has(groupByKey)) {
        groupByMap.set(groupByKey, new Map());
      }

      groupByMap.get(groupByKey).set(xColVal, row);
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = [];
    groupByMap.forEach((curValSet, groupByVal) => {
      const arr = Array.from(allValueSet).map((e) => {
        // console.log({ e });
        if (curValSet.get(e)) {
          return curValSet.get(e);
        }

        const obj: RowData = {};
        const keys = Object.keys(rows[0]);

        keys.forEach((key) => {
          if (key === groupBy) {
            obj[key] = groupByVal;
          } else {
            obj[key] = typeof rows[0][key] === 'string' ? '' : 0;
          }
        });
        return {
          ...obj,
          [xCol]: e,
        };
      });
      data.push(...arr);
    });

    // console.log(data);
    return data;
  };

  let rows = queryResults?.rows;
  if (chart.uiOptions.dataOptions?.groupByCol) {
    // remove bad data
    const xCol = chart.uiOptions.dataOptions.xCol?.colName;
    rows = rows?.filter((e) => {
      return e[xCol!] !== '';
    });
  }
  // if xCol.colName includes date + groupByCol is not empty string
  // we will create series for the missing data

  const res = fillEmptyDataDates(
    rows,
    chart.uiOptions.dataOptions?.xCol?.colName,
    chart.uiOptions.dataOptions?.groupByCol
  );
  // =======

  // calculate bar chart configs
  const option = connectEChartData({
    chart,
    queryResults: chart.uiOptions.dataOptions?.groupByCol
      ? ({
          ...queryResults,
          rows: res,
        } as QueryResultResult)
      : queryResults,
  });

  // TODO delete after
  // eslint-disable-next-line
  // console.log('Barchart re-render with option', option);

  // TODO memo hash obj
  const hashObj = cloneDeep(option);
  // Mutating
  removeKeyRecursive(hashObj, 'data');

  return (
    <Suspense fallback={<LogoBlinkerCenter />}>
      <EChartV2
        loading={loading}
        option={option}
        key={JSON.stringify(hashObj)}
        style={{ height: '100%' }}
      />
    </Suspense>
  );
}

export default memo(LineChart);
