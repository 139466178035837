import { produce } from 'immer';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { atomFamily, useRecoilState } from 'recoil';
import { setDeep } from '../../../utils/object';
import { ChartEntity } from '../../chart/types';
import { ChartOptionsV2 } from '../../chart/types-v2';
import { DefaultBarChartUiOptions } from '../../chart/utils/echart';
import { SelectChartByIdState, updateChart } from '../ChartsState';

export type YCol = {
  colName: string;
  customLabel?: string;
  valueType: string;
  chartType: string;
  color?: string;
};

export type ScatterChartUiOptions = {
  version?: number;
  chartOptions?: {
    showLegend?: boolean;
    enableStacking?: boolean;
    normalizeData?: boolean;
  };
  xAxisOptions?: {
    label?: string;
    tickFormat?: string;
    logarithmic?: boolean;
    sortValues?: boolean;
    reverseValue?: boolean;
  };
  yAxisOptions?: {
    label?: string;
    tickFormat?: string;
    labelFormat?: string;
    logarithmic?: boolean;
    enableRightYAxis?: boolean;
  };
  dataOptions?: {
    groupByCol?: string;
    xCol?: {
      colName: string;
      customLabel?: string;
      valueType: string;
    };
    yCols?: YCol[];
    groupByYCols?: YCol[];
  };
};

export type SetScatterChartOptions = (n: ChartOptionsV2) => void;

export const ScatterChartOptionsState = atomFamily<ChartOptionsV2, string>({
  key: 'ScatterChartOptionsState',
  default: {
    displayName: 'Scatter chart',
    uiOptions: DefaultBarChartUiOptions,
  },
});

export function useScatterChartOptions(chartId: string) {
  const [chart, setChart] = useRecoilState(
    SelectChartByIdState({ id: chartId })
  );
  const [scatterChartOptions, setScatterChartOptionsState] = useRecoilState(
    ScatterChartOptionsState(chartId)
  );

  const [updating, setUpdating] = useState(false);

  const debouncedEventHandler = useMemo(
    () =>
      debounce((nScatterChartOptions: ChartOptionsV2) => {
        // trigger rerender chart

        const nChart: ChartEntity = {
          ...chart,
          ...nScatterChartOptions,
        } as ChartEntity;

        setChart(nChart);

        // TODO: call api to update chart
        // eslint-disable-next-scatter
        // console.log('api update & trigger rerender chart', nChart);
        updateChart({ chart: nChart });

        setUpdating(false);
      }, 600),
    [chart, setChart]
  );

  const setChartOptions = useCallback(
    (nScatterChartOptions: ChartOptionsV2) => {
      setUpdating(true);

      // update options
      setScatterChartOptionsState(nScatterChartOptions);
      // trigger rerender by updating chart
      debouncedEventHandler(nScatterChartOptions);
    },
    [debouncedEventHandler]
  );

  const setChartOptionsByMap = useCallback(
    // eslint-disable-next-scatter
    (valueMap: Record<string, unknown>) => {
      setChartOptions(
        produce(scatterChartOptions, (draft) => {
          setDeep(draft, valueMap);
        })
      );
    },
    [scatterChartOptions, setChartOptions]
  );

  return [
    scatterChartOptions,
    updating,
    {
      setChartOptions,
      setChartOptionsByMap,
    },
  ] as const;
}
