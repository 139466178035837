/* eslint-disable no-console */
import { isEmpty } from 'lodash';
import { useRecoilState } from 'recoil';
import LoadingIndicator from '../../components/LoadingIndicator';
import { useRpcStatusV3 } from '../../hooks/network';
import { SQueryResults } from '../queryBuilder/QueryState';
import { ApiCreateValues, useQueryIdSearchParams } from './NewApiHook';
import { useApiId } from './ViewApiHooks';
import { useMemo } from 'react';
import { toast } from 'react-toastify';
import { IoInformationCircleSharp } from 'react-icons/io5';

const IncrementalSelect = ({
  resetIncrementalRelatedFields,
  setSelectedTab,
}: {
  resetIncrementalRelatedFields: () => void;
  setSelectedTab: (tab: string) => void;
}) => {
  const queryId = useQueryIdSearchParams();
  const [queryResults] = useRecoilState(SQueryResults(queryId));
  const apiId = useApiId();
  const [values, setValues] = useRecoilState(ApiCreateValues(apiId));
  const [rpcStatusResults] = useRpcStatusV3('new-api-step2-query');
  const error = queryResults?.error || rpcStatusResults.error;
  const columns = queryResults?.results?.metadata?.columns || [];

  const isAppendMode = useMemo(() => {
    return values.resultWriteMode === 'Append';
  }, [values]);

  return (
    <div>
      <div className='flex justify-between items-center px-3 py-2'>
        <label className='font-semibold text-[10px] flex items-center'>
          <span className='badge badge-secondary text-[10px] px-2 py-1 mr-1'>
            Alpha
          </span>
          <span className='opacity-50'>ENABLE INCREMENTAL DATA REFRESH</span>
          <div
            className='tooltip ml-1'
            data-tip='With incremental refresh, you can refresh only data that needs to be processed, either data that has changed or arrived fresh after the last time the API was refreshed. This significantly decreases the API cost and processing time. For example, we use "data_creation_date" or latest timestamp to refresh only data within the last 24 hours. 
            Warn: not recommended for dataset with more than 1 million records for now. The limit will be removed later on.'
          >
            <IoInformationCircleSharp className='opacity-50' />
          </div>
        </label>
        {values && (
          <input
            onChange={() => {
              if (isAppendMode) {
                resetIncrementalRelatedFields();
              } else {
                // setSelectedTab('incrementalApiQuery  ');
                console.log(setSelectedTab);
                setValues({
                  ...values,
                  resultWriteMode: isAppendMode ? undefined : 'Append',
                  resultDatabase: 'zedb1',
                });
              }
            }}
            checked={isAppendMode}
            type='checkbox'
            className='toggle toggle-sm toggle-primary'
          />
        )}
      </div>

      <div className='flex flex-col px-3 pb-2 gap-y-2'>
        {!error && isEmpty(columns) && (
          <span className='opacity-50 flex items-center justify-center'>
            <LoadingIndicator />
          </span>
        )}
        {!error && !isEmpty(columns) && isAppendMode && (
          <>
            <div className='label font-semibold  flex items-center justify-between p-0'>
              <span className=' text-[10px] font-semibold flex items-center'>
                <span className='opacity-50'>SET PRIMARY KEY</span>
                <div
                  className='tooltip ml-1'
                  data-tip='A unique and not null key in this API table. A table can have only one primary key, which may consist of single or multiple fields. Incremental refresh will use this key to overwrite stale partitioned data.
Note, you can choose primary key from the list of index fields below. '
                >
                  <IoInformationCircleSharp className='opacity-50' />
                </div>
              </span>
              <input
                onBlur={(e) => {
                  const primaryKeyArr = e.target.value.split(',');
                  if (
                    primaryKeyArr.length > 0 &&
                    primaryKeyArr[primaryKeyArr.length - 1] === ''
                  ) {
                    primaryKeyArr.pop();
                  }

                  // validate
                  const isValidKeyArr = primaryKeyArr.every((key) => {
                    return columns.some((col) => col.name === key);
                  });
                  if (!isValidKeyArr) {
                    toast.warn(
                      'Please make sure that keys are listed in the list provided'
                    );
                  }

                  setValues({
                    ...values,
                    resultPrimaryKey: primaryKeyArr,
                  });
                }}
                onChange={(e) => {
                  setValues({
                    ...values,
                    resultPrimaryKey: e.target.value.split(','),
                  });
                }}
                value={values.resultPrimaryKey?.join(',') || ''}
                placeholder='key1, key2'
                className='placeholder:opacity-50 opacity-100 p-1'
              />
            </div>
            {/* <div className='max-h-[400] overflow-auto'>
              {columns.map((col) => (
                <div className='flex justify-between' key={col.name}>
                  <span className='font-semibold opacity-80'>{col.name}</span>
                </div>
              ))}
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};
export default IncrementalSelect;
