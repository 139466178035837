import { Ref, useEffect } from 'react';

// eslint-disable-next-line
export default function useClickOutside(ref: Ref<any>, handler: () => void) {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      // @ts-ignore
      if (!ref?.current || ref?.current?.contains(event.target)) {
        return;
      }
      handler();
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
}
