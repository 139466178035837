/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { IoInformationCircleSharp } from 'react-icons/io5';
import LogoBlinkerCenter from '../../components/app/LogoBlinkerCenter';

import LoadingIndicator from '../../components/LoadingIndicator';
import { RPCStatus } from '../../hooks/network';
import { convertNumberToFormatted, DateFormat } from '../../utils/date';
import { formatDataUsageInBytes, numberWithCommas } from '../../utils/format';
import ProfileAvatar from '../auth/ProfileAvatar';
import LineChart from '../chart/components/LineChart';
import { TIME_MAPPING } from './const';
import { useApiStats, useEditApi, useIndexingProgress, useUpdateApi } from './ViewApiHooks';
import ViewApiIndexingProgress from './ViewApiIndexingProgress';
import ViewApiOverviewEditor from './ViewApiOverviewEditor';
import ViewIncrementalApiOverviewEditor from './ViewIncrementalApiOverviewEditor';
import EditApiQuery from './EditApiQuery';
import { DatabaseApi } from '../../api/client';
import { toast } from 'react-toastify';
import useFeatures from '../../hooks/useFeatures';
import useAuth from '../../hooks/auth';

function ViewApiOverview({ rpcStatus }: { rpcStatus: RPCStatus }) {
  const [{ rpcStatus: apiRpcStatus, values }, { getDetails }] = useEditApi();

  const [{ values: idxValues, setTriggerPoll }] = useIndexingProgress();
  const [{ rpcStatus: statsApiRpcStatus, values: statsValues }] = useApiStats();
  const [groupByFieldType, setGroupByFieldType] = useState('string');

  const showUnderDevFeatures = useFeatures()

  const [isEditMode, setIsEditMode] = useState(false);

  const [isVerified, setIsVerified] = useState(false);
  const [, { getIsOwner }] = useAuth();

  const isAuthor = getIsOwner(values);



  useEffect(() => {
    switch (statsValues.groupByFieldType) {
      case 'VARCHAR':
        setGroupByFieldType('string');
        break;
      case 'BOOLEAN':
        setGroupByFieldType('boolean');
        break;
      case 'SMALLINT':
        setGroupByFieldType('int16');
        break;
      case 'INTEGER':
        setGroupByFieldType('int32');
        break;
      case 'BIGINT':
        setGroupByFieldType('int64');
        break;
      case 'REAL':
        setGroupByFieldType('float24');
        break;
      case 'DOUBLE':
        setGroupByFieldType('float64');
        break;
      default:
        setGroupByFieldType('string');
    }
  }, [statsValues]);

  const [updatedQuery, setUpdatedQuery] = useState('');

  const handleUpdateQuery = useCallback((query: string) => {
    setUpdatedQuery(query);
    if (isVerified) {
      setIsVerified(false);
    }
  }, [isVerified, setUpdatedQuery, setIsVerified]);
  const handleVerify = async () => {
    // no-op if no database
    if (!values.database) {
      return
    }


    const client = DatabaseApi();

    try {
      await client.executeQueryCreate(
        values.database,
        {
          query: updatedQuery,
        },
        {
          // TODO fix type when API updates
          // @ts-ignore
          query: {
            includeMetadata: true,
            includeColumnName: true,
          },
        }
      );
      toast.success('Query verified successfully.');
      setIsVerified(true);
    } catch (error) {
      toast.error('Query run failed. Please check your query.');
    }
  }

  const indices = useMemo(() => {
    const indexMap = new Map();
    values.indexes?.forEach((index) => {
      if (index.columns?.[0]) {
        indexMap.set(index.columns?.[0], true);
      }
    });
    return indexMap;
  }, [values]);

  useEffect(() => {
    if (idxValues && idxValues.progress === 100) {
      getDetails();
    }
  }, [idxValues]);

  const isAppendMode = useMemo(() => {
    return values.writeMode === 'Append';
  }, [values]);

  const [selectedTab, setSelectedTab] = useState('apiQuery');

  useEffect(() => {
    // setSelectedTab(isAppendMode ? 'incrementalApiQuery' : 'apiQuery');
    setSelectedTab('apiQuery');
  }, [isAppendMode]);

  const [{ rpcStatus: updateApiRpcStatus }, { updateApi }] = useUpdateApi();

  const handleUpdate = () => {
    updateApi({
      query: updatedQuery
    })
  }

  if (isEmpty(values) && apiRpcStatus.isLoading) {
    return (
      <div className='flex w-full items-center py-20 justify-center'>
        <LogoBlinkerCenter />
      </div>
    );
  }

  const indexingPercent = idxValues.progress || 0;



  return (
    <div className='flex'>
      <div className='flex flex-col w-[20rem] flex-shrink-0 flex-grow-0 p-4 gap-4 text-sm'>
        <div className='flex font-semibold opacity-80'>
          {values.description}
        </div>
        <div className='flex'>
          <div className='flex flex-1 flex-col gap-2 pr-1'>
            <div className='opacity-30 font-semibold'>CREATED DATE</div>
            <div className='font-semibold opacity-80'>
              {convertNumberToFormatted(values.createdTime, {
                formatStr: DateFormat,
                fallbackStr: '-',
              })}
            </div>
          </div>
          <div className='flex flex-1 flex-col gap-2'>
            <div className='opacity-30 font-semibold'>PUBLISH DATE</div>
            <div className='font-semibold opacity-80'>
              {convertNumberToFormatted(values.publishedTime, {
                formatStr: DateFormat,
                fallbackStr: '-',
              })}
            </div>
          </div>
        </div>
        <div className='flex'>
          <div className='flex flex-1 flex-col gap-2 pr-1'>
            <div className='opacity-30 font-semibold'>LAST UPDATED</div>
            <div className='font-semibold opacity-80'>
              {convertNumberToFormatted(values.updatedTime, {
                formatStr: DateFormat,
                fallbackStr: '-',
              })}
            </div>
          </div>
          {values.creator?.name && (
            <div className='flex flex-1 flex-col gap-2'>
              <div className='opacity-30 font-semibold'>PUBLISHED BY</div>
              <div className='flex items-center gap-2'>
                <ProfileAvatar
                  className='flex-none w-5 bg-base-content bg-opacity-30'
                  creator={values.creator}
                />
                <div className='font-semibold opacity-80'>
                  {values.creator?.name}
                </div>
              </div>
            </div>
          )}
        </div>
        {
          values.database?.startsWith('realtime') && <>
            <div className='flex'>
              <div className='flex flex-1 flex-col gap-2 pr-1'>
                <div className='opacity-30 font-semibold'>
                  DATA REFRESH INTERVAL
                </div>
                <div className='font-semibold opacity-80'>
                  {values.dataIngestIntervalSeconds
                    ? TIME_MAPPING[values.dataIngestIntervalSeconds]
                    : 'no refresh'}
                </div>
              </div>
            </div>

            <div className='flex'>
              <div className='flex flex-1 flex-col gap-2 pr-1'>
                <div className='font-semibold flex items-center'>
                  <span className='opacity-30 '>INCREMENTAL DATA REFRESH</span>
                  <div
                    className='tooltip ml-1 tooltip-left'
                    data-tip='With incremental refresh, you can refresh only data that needs to be processed, either data that has changed or arrived fresh after the last time the API was refreshed. This significantly decreases the API cost and processing time. For example, we use "data_creation_date" or latest timestamp to refresh only data within the last 24 hours. 
                Warn: not recommended for dataset with more than 1 million records for now. The limit will be removed later on.'
                  >
                    <IoInformationCircleSharp className='opacity-30' />
                  </div>
                </div>
                <div className='font-semibold opacity-80'>
                  {isAppendMode ? 'enabled' : 'not enabled'}
                </div>
              </div>
            </div>

            {isAppendMode && (
              <div className='flex'>
                <div className='flex flex-1 flex-col gap-2 pr-1'>
                  <div className=' font-semibold  flex items-center'>
                    <span className='opacity-30 '> PRIMARY KEY</span>
                    <div
                      className='tooltip ml-1'
                      data-tip='A unique and not null key in this API table. A table can have only one primary key, which may consist of single or multiple fields. Incremental refresh will use this key to overwrite stale partitioned data.
                  Note, you can choose primary key from the list of index fields below. '
                    >
                      <IoInformationCircleSharp className='opacity-30' />
                    </div>
                  </div>
                  <div className='font-semibold opacity-80'>
                    {values.primaryKey?.join(', ')}
                  </div>
                </div>
              </div>
            )}</>
        }
        {
          values.database && values.database?.startsWith('realtime') ? <div className='flex'>
            <div className='flex flex-1 flex-col gap-2 pr-1'>
              <div className='opacity-30 font-semibold'>
                REALTIME DATA FRESHNESS
              </div>
              <div className='font-semibold opacity-80'>
                {values.dataFreshnessSeconds && values.dataFreshnessSeconds > 120 && values.dataFreshnessSeconds < 300 ? `${values.dataFreshnessSeconds} seconds` : 'Not available'}
              </div>
            </div>
          </div> : <>
            <div className='flex'>
              <div className='flex flex-1 flex-col gap-2 pr-1'>
                <div className='opacity-30 font-semibold'>
                  COMPUTE UNITS PER 100 RECORDS
                </div>
                <div className='font-semibold opacity-80'>
                  {values.computeUnitPer100Records}
                </div>
              </div>
            </div>
            <div className='flex'>
              <div className='flex flex-1 flex-col gap-2 pr-1'>
                <div className='opacity-30 font-semibold'>
                  LAST REFRESH COMPUTE UNITS
                </div>
                <div className='font-semibold opacity-80'>
                  {values.lastRefreshCU}
                </div>
              </div>
            </div>
          </>
        }
        <div className='flex'>
          <div className='flex flex-1 flex-col gap-2 pr-1'>
            <div className='opacity-30 font-semibold'>CURRENT DATA SIZE</div>
            <div className='font-semibold opacity-80'>
              {values.dataTotalSize
                ? formatDataUsageInBytes(values.dataTotalSize)
                : 'Unknown'}
            </div>
          </div>
        </div>
        {
          values.database && values.database?.startsWith('realtime') ? <div className='flex'>
            <div className='flex flex-1 flex-col gap-2 pr-1'>
              <div className=' font-semibold  flex items-center'>
                <span className='opacity-30 '> PRIMARY KEY</span>
                <div
                  className='tooltip ml-1'
                  data-tip='A unique and not null key in this API table. '
                >
                  <IoInformationCircleSharp className='opacity-30' />
                </div>
              </div>
              <div className='font-semibold opacity-80'>
                {values.primaryKey?.join(', ')}
              </div>
            </div>
          </div> : null
        }
        <div className='flex'>
          <div className='flex flex-1 flex-col gap-2 pr-1'>
            <div className='opacity-30 font-semibold'>
              CURRENT NUMBER OF RECORDS
            </div>
            <div className='font-semibold opacity-80'>
              {numberWithCommas(values.numberOfRecords)}
            </div>
          </div>
        </div>
        <div className='flex flex-col'>
          <div className='opacity-30 font-semibold pb-2'>
            COLUMNS AND INDEXES
          </div>
          {!isEmpty(values.columns) &&
            values?.columns?.map((col) => {
              return (
                <div className='w-ful text-md flex flex-wrap opacity-80 hover:opacity-100 items-center gap-2 pb-2 justify-between	'>
                  <div className='flex items-center'>
                    <input
                      type='checkbox'
                      className='toggle toggle-sm toggle-primary'
                      checked={indices.has(col.name)}
                    />
                    <span
                      title={col.name}
                      className='max-w-[150px] min-w-0 overflow-hidden text-ellipsis whitespace-nowrap font-semibold pl-2'
                    >
                      {col.name}
                    </span>
                  </div>
                  <span title={col.type} className='justify-self-end table-column-item break-words cursor-pointer font-semibold max-w-[100px] text-right opacity-60 whitespace-nowrap ml-auto overflow-hidden text-ellipsis'>
                    {col.type}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
      <div className='divider-y' />
      <div className='flex flex-1'>
        {
          <div className='flex flex-1 flex-col w-0'>
            {/* indexing progress */}
            {values.database?.startsWith('realtime') && <ViewApiIndexingProgress
              apiDetail={values}
              idxValues={idxValues}
              indexingPercent={indexingPercent}
              setTriggerPoll={setTriggerPoll}
            />}

            {/* api transform sql */}
            <div className='flex flex-1 flex-col p-4 gap-2'>
              <div className='tabs'>
                <a
                  onClick={() => {
                    setSelectedTab('apiQuery');
                  }}
                  className={classNames(
                    'tab',
                    values.database?.startsWith('realtime') && 'w-full',
                    selectedTab === 'apiQuery' ? 'tab-active' : ''
                  )}
                >
                  <div className='w-full flex font-semibold text-sm items-center'>
                    <div className='flex justify-between items-center w-full'>
                      <span className='opacity-50'>API TRANSFORMATION CODE</span>
                      {
                        isAuthor && showUnderDevFeatures && values.database?.startsWith('realtime') && <span className='opacity-50 font-semibold ml-6  ' onClick={() => {
                          setIsEditMode(prev => !prev)
                        }}>{
                            isEditMode ? 'View' : 'Edit'
                          }
                        </span>
                      }
                    </div>
                    {isAppendMode && (
                      <div
                        className='tooltip ml-1 '
                        data-tip='This SQL code is used to materialize and backfill a table in the database for the API. With incremental refresh, the code will be executed only once in the beginning of API creation, and the future update will rely on the Incremental SQL code. '
                      >
                        <IoInformationCircleSharp className='opacity-50' />
                      </div>
                    )}
                    {rpcStatus.isLoading && <LoadingIndicator />}
                  </div>
                </a>
                {isAppendMode && (
                  <a
                    onClick={() => {
                      setSelectedTab('incrementalApiQuery');
                    }}
                    className={classNames(
                      'tab',
                      selectedTab === 'apiQuery' ? '' : 'tab-active'
                    )}
                  >
                    <div className='flex font-semibold text-sm  items-center'>
                      <span className='opacity-50'>INCREMENTAL SQL CODE</span>
                      <div
                        className='tooltip ml-1 '
                        data-tip='This SQL code is used to partially refresh the data, which could greatly reduce the cost by controlling how much data to scan and how to scan. The incremental SQL schema must be  compatible with the API transformation SQL schema. .
                      '
                      >
                        <IoInformationCircleSharp className='opacity-50' />
                      </div>
                      {rpcStatus.isLoading && <LoadingIndicator />}
                    </div>
                  </a>
                )}
              </div>

              <div className='flex flex-col w-full min-h-[209px] overflow-y-auto '>
                {selectedTab === 'apiQuery' ? (
                  isEditMode ? <EditApiQuery onChange={handleUpdateQuery} defaultQuery={values.query} /> : <ViewApiOverviewEditor />
                ) : (
                  <ViewIncrementalApiOverviewEditor />
                )}
              </div>
              {
                isEditMode && <div className='w-full text-right mt-2'>
                  {
                    isVerified ? <button className='btn btn-primary' onClick={handleUpdate} disabled={updateApiRpcStatus.isLoading} >
                      Update Query
                    </button> : <button className='btn btn-primary' onClick={handleVerify}>
                      Verify Query
                    </button>
                  }

                </div>
              }
            </div>

            {/* events per day */}
            {values.groupByField &&
              isEmpty(statsValues.eventsDistribution) &&
              (indexingPercent < 100 || statsApiRpcStatus.isLoading) && (
                <div className='flex w-full items-center py-20 justify-center'>
                  <LogoBlinkerCenter />
                </div>
              )}
            {!isEmpty(statsValues.eventsDistribution) && (
              <div className='px-4 pb-4'>
                <div className='border p-4 rounded-xl'>
                  <div className='font-bold'>DATASET OVERVIEW</div>
                  <div className='h-[20rem]'>
                    <LineChart
                      chart={{
                        queryId: 'qu12-60c59159-13e6-49d7-a467-80f991a2fcf8',
                        type: 'line',
                        displayName: 'Line chart',
                        uiOptions: {
                          version: 2,
                          chartOptions: {
                            showLegend: false,
                          },
                          dataOptions: {
                            xCol: {
                              colName: 'groupBy',
                              valueType: groupByFieldType,
                              sortValues: false,
                            },
                            yCols: [
                              {
                                colName: 'numOfEvents',
                                chartType: 'line',
                                color: '#0057FF',
                                customLabel: '',
                                valueType: groupByFieldType,
                              },
                            ],
                          },
                          xAxisOptions: {
                            label: statsValues.groupByField || '',
                          },
                          yAxisOptions: {
                            label: 'number of records',
                          },
                        },
                        id: 'ch-20110408-7195-40a9-aa15-1d5b62b9d353',
                        createdTime: 1663016949935,
                      }}
                      queryResults={{
                        metadata: {
                          columns: [],
                        },
                        rows:
                          [...(statsValues.eventsDistribution || [])]?.map(
                            (event) => ({
                              ...event,
                            })
                          ) || [],
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        }
      </div>
    </div>
  );
}

export default memo(ViewApiOverview);
