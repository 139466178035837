import axios, { AxiosError } from 'axios';
import { CSSProperties, useMemo, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { HorizontalLine } from '../../../components/HorizontalLine';
import PaleButton from '../../../components/interactive/PaleButton';
import csvfile from '../../../assets/icons/csv.svg';
import formatBytes from '../utils/formatBytes';
import { MdCloudUpload } from 'react-icons/md';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import useAuth from '../../../hooks/auth';

interface IUploadDetailsProps {
  selectedFile?: File;
  s3Url?: string;
  onPrev: () => void;
  onNext: (s3Url: string, file: File) => void;
}

export default function UploadDetails({
  selectedFile,
  s3Url,
  onPrev,
  onNext,
}: IUploadDetailsProps) {
  const [file, setFile] = useState<File | undefined>(selectedFile);
  const [s3, setS3] = useState<string | undefined>(s3Url);
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const [bytesLoaded, setBytesLoaded] = useState<string>('');
  const [{auth}] = useAuth()
  
  const authHeader: {
    Authorization?: string;
  } = useMemo(() => {
    if (auth && auth?.accessToken) {
      return {
        Authorization: `Bearer ${auth.accessToken}`,
      };
    }
    return {}
  }, [auth])
  
  const handleFileSubmit = async (files: FileList | null) => {
    if (files) {
      const uploadFile = files[0];
      setTimeout(() => setFile(uploadFile), 500);
      const formData = new FormData();
      formData.append('file', uploadFile);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_ZETTA_BACKEND_API}/qugate/v1/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              ...authHeader,
            },
            onUploadProgress: (progressEvent: ProgressEvent) => {
              setProgress(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
              setBytesLoaded(
                `${formatBytes(progressEvent.loaded)} / ${formatBytes(
                  progressEvent.total
                )}`
              );
            },
          }
        );
        setTimeout(() => {
          setProgress(undefined);
          setBytesLoaded('');
          setS3(res.data.url);
          onNext(res.data.url, uploadFile);
        }, 1000);
      } catch (err: unknown) {
        
        toast.error((err as AxiosError).message);
        
        setProgress(undefined);
        setBytesLoaded('');
        // eslint-disable-next-line
        console.error(err);
      }
    }
  };

  return (
    <>
      <div className='flex px-6 py-3 items-center'>
        <PaleButton
          onClick={() => {
            onPrev();
          }}
        >
          <IoIosArrowBack size='1.8rem' />
          <div className='w-3' />
          Back
        </PaleButton>
        {file && s3 && (
          <>
            <div className='w-full' />
            <PaleButton
              onClick={() => {
                onNext(s3, file);
              }}
            >
              Next
              <div className='w-3' />
              <IoIosArrowForward size='1.8rem' />
            </PaleButton>
          </>
        )}
      </div>
      <HorizontalLine />
      <div className='flex flex-col w-full h-full'>
        <div className='inline-flex w-full items-center h-16'>
          <div className='w-6 h-full flex-shrink-0' />
          <div className='h-full w-full inline-flex items-center'>
            <img src={csvfile} className='h-3/4 w-auto' />
            <div className='w-3' />
            <span className='text-xl'>CSV File</span>
          </div>
          <div className='h-full w-full inline-flex items-center bg-base-200 text-xl'>
            <div className='w-3' />
            Setup Guide
          </div>
        </div>
        <HorizontalLine />
        <div className='w-full h-[60vh] inline-flex'>
          <div className='w-full h-full p-6 flex-col'>
            <span className='w-auto flex-shrink-0 text-lg font-bold'>
              Upload Data
            </span>
            <div className='h-5' />
            <div className='flex flex-col h-3/4 w-full'>
              {progress ? (
                <div className='card w-full h-full shadow-xl flex items-center justify-center space-y-5'>
                  <h3 className='text-xl font-bold flex'>
                    Uploading file {file?.name}
                  </h3>
                  <div
                    className='radial-progress bg-primary text-primary-content border-4 border-primary'
                    style={
                      {
                        '--value': progress,
                        '--size': '12rem',
                        '--thickness': ' 1rem',
                      } as CSSProperties
                    }
                  >
                    {progress === 100 ? 'Done!' : `${progress}%`}
                  </div>
                  <h3 className='text-xl font-bold flex'>{bytesLoaded}</h3>
                </div>
              ) : (
                <div className='rounded-xl h-full bg-base-300 hover:scale-[1.01]'>
                  <label
                    htmlFor='csv-file-upload'
                    className='h-[12rem] w-full'
                    onFocus={() => {}}
                    onBlur={() => {}}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDragLeave={() => {}}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleFileSubmit(e.dataTransfer.files);
                    }}
                  >
                    {file ? (
                      <div className='h-full w-full flex justify-center items-center flex-col'>
                        <div className='h-5' />
                        <h4 className='text-lg flex font-bold'>{file.name}</h4>
                        <h4 className='text-lg flex'>File Type: {file.type}</h4>
                        <h4 className='text-lg flex'>
                          File Size: {formatBytes(file.size)}
                        </h4>
                        <h4 className='text-lg flex'>
                          Last Modified:{' '}
                          {format(
                            new Date(file.lastModified),
                            'yyyy-MM-dd HH:mm'
                          )}
                        </h4>
                      </div>
                    ) : (
                      <div className='flex flex-col justify-center items-center h-full w-full rounded-xl cursor-pointer'>
                        <MdCloudUpload size='6rem' />
                        <div className='h-5' />
                        <span>Drag file to upload or Click to browse</span>
                      </div>
                    )}
                  </label>
                  <input
                    id='csv-file-upload'
                    type='file'
                    hidden
                    onChange={(e) => {
                      handleFileSubmit(e.target.files);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className='w-full h-full flex flex-col py-6 bg-base-200'>
            <ul className='px-9 list-disc h-auto'>
              <li>
                Data size should be less than 500MB and the number of rows
                should be less than 1 Million
              </li>
              <li>
                Field and file name may only contain lowercase letters, numbers,
                and underscores
              </li>
              <li>The data will only be visible to you/your organization.</li>
              <li>
                Warn: the header row will be truncated if all columns are the
                string type
              </li>
            </ul>
            <div className='h-3' />
            <div className='px-6 w-full h-full overflow-scroll'>
              <table className='table w-full border-solid'>
                <thead className='bg-base-300'>
                  <tr>
                    <th className='w-1/3 border-2 border-gray-300'>data</th>
                    <th className='w-1/3 border-2 border-gray-300'>chain</th>
                    <th className='w-1/3 border-2 border-gray-300'>volume</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='w-1/3 border-2 border-gray-300'>
                      2022-07-15
                    </td>
                    <td className='w-1/3 border-2 border-gray-300'>Ethereum</td>
                    <td className='w-1/3 border-2 border-gray-300'>
                      29,506,112
                    </td>
                  </tr>
                  <tr>
                    <td className='w-1/3 border-2 border-gray-300'>
                      2022-07-15
                    </td>
                    <td className='w-1/3 border-2 border-gray-300'>Polygon</td>
                    <td className='w-1/3 border-2 border-gray-300'>
                      43,154,890
                    </td>
                  </tr>
                  <tr>
                    <td className='w-1/3 border-2 border-gray-300'>
                      2022-07-15
                    </td>
                    <td className='w-1/3 border-2 border-gray-300'>Ethereum</td>
                    <td className='w-1/3 border-2 border-gray-300'>
                      59,105,232
                    </td>
                  </tr>
                  <tr>
                    <td className='w-1/3 border-2 border-gray-300'>
                      2022-07-15
                    </td>
                    <td className='w-1/3 border-2 border-gray-300'>Polygon</td>
                    <td className='w-1/3 border-2 border-gray-300'>23,231</td>
                  </tr>
                  <tr>
                    <td className='w-1/3 border-2 border-gray-300'>
                      2022-07-15
                    </td>
                    <td className='w-1/3 border-2 border-gray-300'>Ethereum</td>
                    <td className='w-1/3 border-2 border-gray-300'>16</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
