/* eslint-disable @typescript-eslint/no-unused-vars */
import { toast } from 'react-toastify';
import { OnboardingApi, PaymentApi } from '../../api/client';
import { ModelWorkflowStateDetail } from '../../api/__gen__/data-contracts';
import envs from '../../config/envs';

export const addPaymentMethod = async (
  userId: string,
  tokenId: string,
  paymenMethodType = 'card',
  isDefault = false
) => {
  const client = PaymentApi();

  const result = await client.methodAddCreate({
    is_default: isDefault,
    payment_method_type: paymenMethodType,
    token_id: tokenId,
    user_id: userId,
  });

  return result;
};

export const removePaymentMethod = async (
  userId: string,
  paymentMethodId: string,
  tenant: string
) => {
  const client = PaymentApi();

  const result = await client.methodDeleteCreate({
    payment_method_id: paymentMethodId,
    user_id: userId,
    tenant,
  });

  return result;
};

export const setDefaultPaymentMethod = async (
  userId: string,
  paymentMethodId: string
) => {
  const client = PaymentApi();

  const result = await client.methodDefaultCreate({
    payment_method_id: paymentMethodId,
    user_id: userId,
  });

  return result;
};

export const getProducts = async (userId: string) => {
  const client = PaymentApi();

  const result = await client.productListUserIdList(userId);

  return result.data;
};

export const getPaymentMethods = async (userId: string) => {
  const client = PaymentApi();

  const result = await client.methodListUserIdList(userId);

  return result.data;
};

export const subscriptionUpgrade = async (
  userId: string,
  productId: string,
  paymentMethodId: string | undefined,
  productName: string
) => {
  const client = PaymentApi();

  const result = await client.subscriptionUpgradeCreate({
    // payment_method_id: paymentMethodId,
    product_id: envs.STRIPE_BUILD_PLAN_ID,
    // product_name: productName,
    user_id: userId,
  });

  return result;
};

export const subscriptionDowngrade = async (
  userId: string,
  productId: string,
  paymentMethodId: string | undefined,
  productName: string
) => {
  const client = PaymentApi();
  
  await getProducts(userId)

  const result = await client.subscriptionDowngradeCreate({
    // payment_method_id: paymentMethodId,
    product_id: envs.STRIPE_FREE_PLAN_ID,
    // product_name: 'free',
    user_id: userId,
  });

  return result;
};

export const getBillingHistory = async (
  userId: string,
  query: {
    start_time: string;
    end_time: string;
  }
) => {
  const client = PaymentApi();

  const result = await client.historyListUserIdList(userId, query);

  return result.data;
};

export const startOnboardingWorkflow = async (
  tenant?: string,
  user_email?: string,
  user_id?: string
) => {
  try {
    const client = OnboardingApi();
    return await client.workflowStartCreate({
      tenant,
      user_email,
      user_id,
    });
  } catch (error) {
    toast.error('Error Occured', {
      autoClose: 2000,
      position: 'top-center',
    });
    return null;
  }
};

export const moveOnboardingWorkflowForward = async (
  state_id: string,
  state_detail?: ModelWorkflowStateDetail,
  tenant?: string,
  user_email?: string,
  user_id?: string
) => {
  try {
    const client = OnboardingApi();
    const data = await client.workflowMoveCreate({
      state_id,
      direction: 'Next',
      state_detail,
      tenant,
      user_email,
      user_id,
    });
    return data;
  } catch (error) {
    toast.error('Error Occured During Move Forward', {
      autoClose: 2000,
      position: 'top-center',
    });
    return null;
  }
};

export const moveOnboardingWorkflowBackward = async (
  state_id: string,
  state_detail?: ModelWorkflowStateDetail,
  tenant?: string,
  user_email?: string,
  user_id?: string
) => {
  try {
    const client = OnboardingApi();
    const res = await client.workflowMoveCreate({
      state_id,
      direction: 'Previous',
      state_detail,
      tenant,
      user_email,
      user_id,
    });
    
    return res;
  } catch (error) {    
    toast.error('Error Occured During Move Backward', {
      autoClose: 2000,
      position: 'top-center',
    });
    return null;
  }
};
