/* eslint-disable @typescript-eslint/no-explicit-any */
import Popconfirm from "antd/es/popconfirm";
import { PublishButton } from "../../../components/PublishButton";
import EditButton from "../../../components/EditButton";

import backBtnIcon from './back.svg'
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useCallback } from "react";

export const ProjectTopbar = ({
  text1,
  text2,
  isWorkspaceView,
  projectDetail,
  onPublish,
  onUnpublish,
  isEditMode,
  setIsEditMode,
  type = 'projects'
}: any) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const onBackButtonClick = useCallback(() => {
    switch (searchParams.get('from')) {
      case 'home':
        navigate('/v2/explore')
        break;
      case 'explore':
        navigate(`/v2/explore/${type}`)
        break;
      case 'workspace':
        navigate(`/v2/workspace/${type}`)
        break;
      case 'search':
        navigate(`/v2/search-results?keyword=${searchParams.get('keyword')}`)
        break;

      default:
        navigate('/v2/explore')
        break;
    }


  }, [searchParams, type, navigate])

  return <div className="h-[60px] border-b flex items-center justify-between">
    <div className="h-[60px] flex items-center">
      <div className="cursor-pointer" onClick={onBackButtonClick}>
        <img src={backBtnIcon} className="w-[30px] h-[30px] ml-[30px] mr-[20px] mt-[18px] mb-[12px]" />
      </div>
      <div className="flex items-center mt-[26px] mb-[20px]">
        <div className="text-[14px] font-semibold">
          {text1}
        </div>
        <div className="w-[1px] bg-[#F2F2F2] h-[20px] mx-[10px]"></div>
        {/* <div className="mx-[10px]">/</div> */}
        <div className="text-[12px] text-[#757D8D]">
          {text2}
        </div>
      </div>
    </div>
    <div className="flex gap-[15px] mr-[30px]">
      {
        isWorkspaceView &&
        (projectDetail?.status !== 1 ? <Popconfirm
          title="Publish Table Project"
          description="Are you sure to publish this project?"
          okText="Yes"
          cancelText="No"
          onConfirm={onPublish}
        >
          < PublishButton />
        </Popconfirm> :
          <Popconfirm
            key={'unpublish'}
            title={`Unpublish ${type === 'apis' ? 'API' : 'Table'} Project`}
            description="Are you sure to unpublish this project?"
            okText="Yes"
            cancelText="No"
            onConfirm={onUnpublish}
          >
            <PublishButton text="Unpublish" />
          </Popconfirm>
        )
      }
      {
        !isEditMode && isWorkspaceView && <EditButton onClick={
          () => setIsEditMode(true)
        } showText />
      }
    </div>
  </div>
}

const ApiProjectHeader = ({
  isOverview,
  isWorkspaceView,
  title,
  projectDetail,
  isEditMode,
  setIsEditMode,
  publishTableProject,
  unpublishTableProject,
  type
}: {
  isOverview?: boolean
  isWorkspaceView?: boolean
  title?: string
  projectDetail?: any
  setIsEditMode?: any
  isEditMode?: boolean
  publishTableProject?: any
  unpublishTableProject?: any
  type: string
}) => {
  return <ProjectTopbar
    isEditMode={isEditMode}
    setIsEditMode={setIsEditMode}
    onPublish={publishTableProject}
    onUnpublish={unpublishTableProject}
    text1={title || 'API Project'}
    text2={projectDetail?.owner?.name || 'Unknown Owner'}
    projectDetail={projectDetail}
    isWorkspaceView={isWorkspaceView}
    isOverview={isOverview}
    type={type}
  />
}

export default ApiProjectHeader;