import classNames from "classnames";
import { useContext, useEffect, useMemo, useState, } from "react";
import { NavLink, Navigate, Route, Routes, useParams } from "react-router-dom";
import { useScrollToLocation } from "../../../hooks/useScrollToLocation";
import SqlCodeExample from "./components/SqlCodeExample";
import PathParams from "./components/PathParams";
import Response from "./components/Response";
import BodyParams from "./components/BodyParams";
import { ApiProjectContext, ApiProjectContextProvider } from "./api-project-context";
import HeaderParams from "./components/HeaderParams";
import QueryParams from "./components/QueryParams";
import RequestSnippet from "./components/RequestSnippets";
import RelatedTables from "./components/RelatedTables";
import EndpointDisplay from "./components/EndpointDisplay";
import ApiDetailHeader from "./components/ApiDetailHeader";
import ApiProjectHeader from "./components/ApiProjectHeader";
import ResponseSnippet from "./components/ResponseSnippet";
import ProjectOverview from "./components/ProjectOverview";
import { getApisByProjectId, getOpenApiSpec, getProjectDetailById, publishProject, unpublishProject, updateApiMetadata, updateProjectMetadata } from "../apis";
import { toast } from "react-toastify";
import LoadingIndicator from "../../../components/LoadingIndicator";
import { PoProject } from "../../../api/__gen__/data-contracts";
import { getAllDefaultProjectApis } from "../utils";
import ImagePicker from "../../image-upload/ImagePicker";

export const ApiProjectLayout = ({
  type,
  isOverview
}: {
  type: string
  isOverview?: boolean
}) => {
  const isWorkspaceView = useMemo(() => type === 'workspace', [type])



  const {
    apiProject,
    setData,
    oasObj,
    resetFormData,
    formData,
    response
  } = useContext(ApiProjectContext)

  const params = useParams()

  const { projectId, apiId } = params
  const [isLoading, setIsLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isApiEditMode, setIsApiEditMode] = useState(false)
  const [projectDetail, setProjectDetail] = useState<PoProject>({})
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [projectApis, setProjectApis] = useState<any[]>([])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [apiMetadata, setApiMetadata] = useState<any>({
    image: '',
    display_name: ''
  })

  const getProjectDetail = async (id: string) => {
    setIsLoading(true)
    getOpenApiSpec()

    if (id === 'default') {
      setProjectDetail({
        name: 'Default Project',
        overview: 'This is the default project'
      })
      const apis = await getAllDefaultProjectApis()
      setProjectApis(apis)
    } else {
      const res = await getProjectDetailById(id)
      const apis = await getApisByProjectId(id)
      setProjectApis(apis)
      setProjectDetail(res)

    }

    setIsLoading(false)
  }

  useEffect(() => {
    if (projectId) {
      getProjectDetail(projectId)
    }

  }, [projectId])

  useEffect(() => {
    resetFormData()
  }, [params.apiId])

  const currentApi = useMemo(() => {
    return projectApis.find(e => e.id === apiId)
  }, [apiId, projectApis])

  const decodedAppId = useMemo(() => {
    return currentApi?.path
  }, [currentApi])


  useEffect(() => {
  }, [apiProject, decodedAppId])

  const enabledParams = useMemo(() => {
    return {
      path: apiProject[decodedAppId || '']?.parameters?.filter(e => {
        return e.in === 'path'
      }).length > 0,
      query: apiProject[decodedAppId || '']?.parameters?.filter(e => {
        return e.in === 'query'
      }).length > 0,
      body: apiProject[decodedAppId || '']?.requestBody,
      header: apiProject[decodedAppId || '']?.parameters?.filter(e => {
        return e.in === 'header'
      }).length > 0
    }
  }, [apiProject, decodedAppId])


  useScrollToLocation()

  const publishTableProject = async () => {
    if (params.projectId) {

      await publishProject(params.projectId,)
      toast.success('Project published successfully', {
        autoClose: 2000,
        position: 'top-center',
      })
      getProjectDetail(params.projectId)
    }
  }

  const unpublishTableProject = async () => {
    if (params.projectId) {

      await unpublishProject(params.projectId,)
      toast.success('Project unpublished successfully', {
        autoClose: 2000,
        position: 'top-center',
      })
      getProjectDetail(params.projectId)
    }
  }

  const onSave = async (projectMetadata: PoProject) => {
    if (params.projectId) {
      await updateProjectMetadata(params.projectId, projectMetadata)
      toast.success('Project updated successfully', {
        autoClose: 2000,
        position: 'top-center',
      })
      getProjectDetail(params.projectId)
      setIsEditMode(false)
    }
  }

  const onSaveApiMetadata = async () => {
    if (params.projectId) {
      await updateApiMetadata({
        ...apiMetadata,
        id: currentApi.id
      })

      toast.success('API Metadata updated successfully', {
        autoClose: 2000,
        position: 'top-center',
      })
      getProjectDetail(params.projectId)
      setIsApiEditMode(false)
    }
  }

  const getDynamicClassNames = () => {
    return ({ isActive }) => {
      const active = isActive;
      return classNames(
        'w-full block py-2 px-[10px] rounded-md break-words text-[12px]',
        active
          ? 'bg-base-content bg-opacity-5 font-bold'
          : 'opacity-80'
      )
    }
  }

  if (isLoading || !apiProject) return <div className="h-[300px] w-full flex justify-center items-center">
    <LoadingIndicator />
  </div>

  return <div className="h-full min-w-[1240px]">
    {/* Title and Action */}
    <ApiProjectHeader type="apis" isWorkspaceView={isWorkspaceView} title={''}

      projectDetail={projectDetail}
      isEditMode={isEditMode}
      isOverview={isOverview}
      setIsEditMode={setIsEditMode}
      publishTableProject={publishTableProject}
      unpublishTableProject={unpublishTableProject}

    />

    {/* Content */}
    <div className="flex h-[calc(100%-62px)] min-w-[1240px]">
      <div className="border-r w-[300px] max-w-[300px] flex-1 overflow-y-auto min-w-[300px]">
        {/* list of overview */}
        <div className="p-[20px]">
          <NavLink
            className={getDynamicClassNames()}
            to={`/v2/${isWorkspaceView ? 'workspace' : 'explore'}/apis/${projectId}/overview`}>
            Overview
          </NavLink>
        </div>

        <div className="divider my-0 h-[1px]"></div>

        {/* list of entities */}
        <div className="px-[20px] py-[20px]">
          {
            projectApis.map((item, index) => {
              return <div key={index}>
                <NavLink
                  className={({ isActive }) => {
                    return classNames(
                      'w-full block py-2 px-[10px] rounded-md mb-1 break-words text-[12px]',
                      isActive
                        ? 'bg-base-content bg-opacity-5 font-bold'
                        : 'opacity-80'
                    )
                  }}
                  to={`/v2/${type}/apis/${projectId}/${item.id}`}>
                  {item.path}
                </NavLink>
              </div>
            })
          }
          {/* Note: Will add this back after GA - JAN 2024 */}
          {/* <button className="flex gap-2 items-center mt-6 text-primary font-semibold px-3">
            <FaPlus />
            Add API
          </button> */}
        </div>
      </div>
      {!isOverview ? <>
        {/* API details */}
        <div className="border-r flex-1 overflow-y-auto min-w-[480px] w-[480px] pl-[30px] pr-[25px] py-[30px] box-content">
          {/* Title and Actions */}
          <div className="mb-6">
            <ApiDetailHeader onSave={onSaveApiMetadata} title={apiProject[decodedAppId || '']?.operationId} onEdit={() => { setIsApiEditMode(!isApiEditMode) }} currentApi={currentApi} isEditMode={isApiEditMode} />
          </div>
          <div className="mb-6">
            <EndpointDisplay method={apiProject[decodedAppId || '']?.method} path={apiProject[decodedAppId || '']?.path} />
          </div>
          {
            isApiEditMode && <div className="mb-6">
              <ImagePicker onChange={(e) => {
                setApiMetadata({
                  ...apiMetadata,
                  image: e
                })
              }} value={apiMetadata.image || currentApi.image} />
            </div>
          }
          <div className="mb-6">
            <SqlCodeExample />
          </div>

          {enabledParams.header && <div className="mb-6">
            <HeaderParams setData={setData}
              values={formData.header || {}}
              headerParams={apiProject[decodedAppId || '']?.parameters?.filter(e => {
                return e.in === 'header'
              })}
            />
          </div>}

          {enabledParams.path && <div className="mb-6">
            <PathParams values={formData.path || {}} setData={setData} pathParams={apiProject[decodedAppId || '']?.parameters?.filter(e => {
              return e.in === 'path'
            })} />
          </div>}

          {
            enabledParams.query && <div className="mb-6">
              <QueryParams values={formData.query || {}} setData={setData} queryParams={apiProject[decodedAppId || '']?.parameters?.filter(e => {
                return e.in === 'query'
              })} />
            </div>
          }

          {
            enabledParams.body && <div className="mb-6">
              <BodyParams values={formData.body || {}} setData={setData} requestBody={apiProject[decodedAppId || '']?.requestBody?.content?.['application/json']?.schema?.properties} />
            </div>
          }

          <div className="mb-6">
            <Response />
          </div>
        </div>

        {/* Related */}
        <div className="flex-1 min-w-[405px] w-[405px] overflow-y-auto box-border" >
          <div className="pt-6 px-6">
            {currentApi?.id && <RelatedTables apiId={currentApi?.id} />}
          </div>
          <div className="py-6 px-6">
            <RequestSnippet apiDetail={oasObj || null} method={apiProject[decodedAppId || '']?.method} path={apiProject[decodedAppId || '']?.path} />
          </div>
          <div className="p-6">
            <ResponseSnippet response={JSON.stringify(response, null, 2)} />
          </div>

        </div>
      </> : <ProjectOverview projectDetail={projectDetail} isEditMode={isEditMode} onSave={onSave} onCancel={() => {
        setIsEditMode(false)
      }} />}
    </div>
  </div >
}

const ApiProject = ({
  type
}: {
  type: string
}) => {
  return <ApiProjectContextProvider>
    <Routes>
      <Route path="/overview" element={<ApiProjectLayout type={type} isOverview />}></Route>
      <Route path="/:apiId" element={<ApiProjectLayout type={type} />}></Route>
      <Route path="/*" element={<Navigate to="/v2/workspace/apis" />} />
    </Routes>
  </ApiProjectContextProvider>

}

export default ApiProject;