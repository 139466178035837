import { Component, ReactNode } from 'react';

type Props = { message?: string; children: ReactNode };

export default class ErrorBoundary extends Component<
  Props,
  { hasError?: boolean }
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line
  static getDerivedStateFromError(error: unknown) {
    return { hasError: true };
  }

  // eslint-disable-next-line
  componentDidCatch(error: any, errorInfo: any) {
    // console.log('error', error, errorInfo);
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { children, message } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <h1>
          {message || 'Something went wrong. Please report to engineering team'}
        </h1>
      );
    }

    return children;
  }
}
