import CodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { codemirrorTheme } from '../../../../config/theme';

const SQL_CODE = `SELECT 
  * 
FROM 
  table_name;`;
const SqlCodeExample = () => {
  return (
    <div className=''>
      <div className='font-semibold mb-1 text-[14px]'>SQL Code</div>
      <div className='border bg-[#e3e3e3]'>
        <CodeMirror
          readOnly
          theme={codemirrorTheme}
          extensions={[sql()]}
          value={SQL_CODE}
        />
      </div>
    </div>
  );
};

export default SqlCodeExample;
