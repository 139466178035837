import classNames from 'classnames';
import { HTMLAttributes } from 'react';

export default function ChartDisplayName({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classNames('font-bold', className)} {...props}>
      {children}
    </div>
  );
}
