import { useState } from 'react'; 
import { GptApi } from '../../api/client';

const useGqlGenerator = () => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState('');
  
  
  const fetchGql = async (id: string, message: string) => {
    if(!id || !message) return
    
    setLoading(true)
    
    const client = GptApi();
    const { data } = await client.graphqlGeneratorCreate(id, {
      message
    })
    
    setLoading(false)
    
    if(data) {
      setContent(data.content || '')
    }
  }
    
  return {
    fetchGql,
    content,
    loading
  }
}

export default useGqlGenerator;