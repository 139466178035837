/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelCloneChartResponse,
  ModelCreateChartRequest,
  ModelCreateChartResponse,
  ModelDeleteChartResponse,
  ModelGetChartDashboardResponse,
  ModelGetChartResponse,
  ModelUpdateChartRequest,
  ModelUpdateChartResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Charts<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description create chart
   *
   * @tags example
   * @name ChartsCreate
   * @summary create chart
   * @request POST:/charts
   */
  chartsCreate = (
    request: ModelCreateChartRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelCreateChartResponse, any>({
      path: `/charts`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get chart
   *
   * @tags example
   * @name ChartsDetail
   * @summary get chart
   * @request GET:/charts/{id}
   */
  chartsDetail = (id: string, params: RequestParams = {}) =>
    this.request<ModelGetChartResponse, any>({
      path: `/charts/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description update chart
   *
   * @tags example
   * @name ChartsCreate2
   * @summary update chart
   * @request POST:/charts/{id}
   * @originalName chartsCreate
   * @duplicate
   */
  chartsCreate2 = (
    id: string,
    request: ModelUpdateChartRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelUpdateChartResponse, any>({
      path: `/charts/${id}`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description delete chart
   *
   * @tags example
   * @name ChartsDelete
   * @summary delete chart
   * @request DELETE:/charts/{id}
   */
  chartsDelete = (id: string, params: RequestParams = {}) =>
    this.request<ModelDeleteChartResponse, any>({
      path: `/charts/${id}`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description clone chart
   *
   * @tags example
   * @name CloneCreate
   * @summary clone chart
   * @request POST:/charts/{id}/clone
   */
  cloneCreate = (id: string, params: RequestParams = {}) =>
    this.request<ModelCloneChartResponse, any>({
      path: `/charts/${id}/clone`,
      method: 'POST',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get chart dashboard relationship, for adding chart to dashboard or remove chart from dashboard
   *
   * @tags example
   * @name DashboardsDetail
   * @summary get chart dashboard relationship
   * @request GET:/charts/{id}/dashboards
   */
  dashboardsDetail = (id: string, params: RequestParams = {}) =>
    this.request<ModelGetChartDashboardResponse, any>({
      path: `/charts/${id}/dashboards`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
