import { useEffect, useMemo, useState } from "react";
import { ChainsApi } from "../../../api/client";
import Modal from "../../../components/modals/Modal"
import LoadingIndicator from "../../../components/LoadingIndicator";
import { AxiosError } from "axios";
import classNames from "classnames";
import CopyToClipboard from "react-copy-to-clipboard";
import { IoIosCopy } from "react-icons/io";
import { toast } from "react-toastify";


const chainsClient = ChainsApi();

const fetchLogs = async (
  chain: string,
  contract: string,
  eventName: string,
) => {
  const logs = (await chainsClient.contractsEventsLogsDetail(chain, contract, eventName)).data

  return logs
}

const DISPLAY_CELL_CLASS = "flex-1 max-w-[180px] p-1 box-border text-ellipsis overflow-hidden text-left relative group opacity-50"
export const DisplayCell = ({ content }: {
  content: string
}) => {
  return <div className={classNames(
    DISPLAY_CELL_CLASS
  )} title={content}>{content}
    <div className="absolute right-0 top-0 bg-[#fff] hidden group-hover:block cursor-pointer">
      <CopyToClipboard
        text={content}
      >
        <IoIosCopy
          size='0.75rem'
          onClick={() => {
            toast.success('Value copied', {
              autoClose: 1000,
            });
          }}
        />
      </CopyToClipboard>
    </div>
  </div>
}


const EventLogPopup = ({
  showPopup,
  setShowPopup,
  // title,
  contract,
  eventName,
  chain,
  inputsByEventName
}: {
  showPopup: boolean
  setShowPopup: (show: boolean) => void
  // title: string
  contract: string
  eventName: string
  chain: string
  inputsByEventName: Map<string, string[]>
}) => {

  const [logs, setLogs] = useState<{
    [key: string]: string
  }[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | AxiosError | null>(null)

  const columns = useMemo(() => {
    return inputsByEventName.get(eventName) || []
  }, [eventName, inputsByEventName])

  useEffect(() => {
    if (!chain || !contract || !eventName) return

    // reset
    setLogs([])
    setLoading(true)
    setError(null)

    fetchLogs(chain, contract, eventName).then((logsData) => {
      const convertedLogs: ({
        [key: string]: string
      })[] = logsData.map((log) => {
        const {
          argument_values,
          argument_names
        } = log

        const newLog: {
          [key: string]: string
        } = {}

        if (!argument_names || !argument_values) return {}

        for (let i = 0; i < argument_values.length; i++) {
          newLog[argument_names[i]] = argument_values[i]
        }

        return {
          ...newLog,
          transaction_hash: log.transaction_hash,
          log_index: log.log_index,
          contract_address: log.contract_address
        }
      })

      setLogs(convertedLogs.splice(0, 100))
      setLoading(false)
    }).catch(err => {
      setError(err?.response?.data ?? err)
      setLoading(false)
    })


  }, [chain, eventName, contract, showPopup])

  const renderResult = () => {
    if (loading) return <div className="w-full flex justify-center p-16">
      <LoadingIndicator />
    </div>

    if (error) return <div className="text-center text-red-500 p-16">{error.message}</div>

    return logs.length === 0 ? <div className="text-center text-gray-500">No data found</div> : <div className="overflow-auto h-[60vh]">
      {
        logs.map((log) => {
          return <div key={log.contract_address + Math.random()} className="flex justify-between text-sm mb-1 py-3 gap-3">
            {
              columns.map((name) => {
                return <DisplayCell content={(log)[name]} />
              })
            }
            {/* <div className="opacity-50 flex-1 max-w-[180px] box-border text-ellipsis overflow-hidden text-left" title={log.contract_address}>{log.contract_address}</div>
            <div className="opacity-50 flex-1 max-w-[180px] box-border text-ellipsis overflow-hidden text-left" title={log.transaction_hash}>{log.transaction_hash}</div>
            <div className="opacity-50 flex-1 max-w-[180px] box-border text-ellipsis overflow-hidden text-left" title={log.log_index}>{log.log_index}</div> */}

            <DisplayCell content={log.contract_address} />
            <DisplayCell content={log.transaction_hash} />
            <DisplayCell content={log.log_index} />
          </div>
        })
      }
    </div>
  }

  return showPopup ? <Modal
    key={Math.random()}
    open={showPopup} onClickBackdrop={() => { setShowPopup(false) }}
    className="p-0 max-w-screen-2xl"
  >
    <div className="title px-6 py-4 bg-[#F1F3F5]">
      Recent {eventName} Data
    </div>
    <div className="px-6 py-4 min-h-[400px]">
      <div className="flex gap-3 text-sm mb-1 font-semibold">
        {
          inputsByEventName.get(eventName)?.map((inputName) => {
            return <div key={inputName} className="flex-1 py-1 px-3 bg-[#f1f3f5] w-[200px] text-ellipsis overflow-hidden box-border">{inputName}</div>
          })
        }
        <div className="flex-1 py-1 px-3 bg-[#f1f3f5] max-w-[180px] box-border">Contract Address</div>
        <div className="flex-1 py-1 px-3 bg-[#f1f3f5] max-w-[180px] box-border">Transaction Hash</div>
        <div className="flex-1 py-1 px-3 bg-[#f1f3f5] max-w-[180px] box-border">Log Index</div>
      </div>
      {
        renderResult()
      }
    </div>
  </Modal> : null

}

export default EventLogPopup