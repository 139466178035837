import { BsFillPlayFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { H3 } from '../../../components/typographic/Headers';
import { useState } from 'react';
import { analytics } from '../../../App';
import { AuthApi } from '../../../api/client';
import {
  GraphiQLEditor,
  GraphiQLExplorerType,
} from '../../../components/graphiql-editor';
import { useApiId } from '../../api/ViewApiHooks';
import './GraphqlEditor.css';
import envs from '../../../config/envs';

const refreshAccessToken = async () => {
  const client = AuthApi();
  const resp = await client.postAuth();
  return resp.data.accessToken;
};

// Creating this component because we might have some customization later
// Will probably revisit this in the future
function GraphqlV3() {
  const apiId = useApiId();

  const [showSchema, setShowShema] = useState(true);

  const [accessToken, setAccessToken] = useState('');

  return (
    <div className='flex'>
      <div className='flex flex-col flex-grow h-full w-2/3 px-8 py-6'>
        <div className='inline-flex items-center justify-between w-full pb-2'>
          <H3>GraphQL endpoint</H3>
          <div className='flex'>
            {!showSchema && (
              <button
                className='mr-8 btn-ghost px-4 border border-gray-300 rounded-md'
                onClick={() => {
                  (
                    document.querySelector('.docExplorerShow') as HTMLElement
                  )?.click();
                  setShowShema(true);
                }}
              >
                Show Schema
              </button>
            )}
            <button
              className='btn-primary btn-circle flex justify-center items-center'
              onClick={async () => {
                toast.dismiss();

                analytics.track('API - Run GraphQL Query', {});

                const token = await refreshAccessToken();
                setAccessToken(token || '');
                setTimeout(() => {
                  (
                    document.querySelector(
                      '.graphiql-execute-button'
                    ) as HTMLElement
                  )?.click();
                }, 200);
              }}
            >
              <BsFillPlayFill color='#fff' size='1.5rem' />
            </button>
          </div>
        </div>
        <div className='flex-1'>
          <div className='gql-editor h-[60vh] min-h-[60vh]'>
            {/* /community/apis/:apiId/playground */}
            <GraphiQLEditor
              apiUrl={`${envs.ZettaBackendApiPrivate}/userapis/${apiId}/graphql`}
              accessToken={accessToken}
              explorerType={GraphiQLExplorerType.Docs}
              noCache
              direction='vertical'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GraphqlV3;
