/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelGetProjectListRequest,
  ModelGetProjectListResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class ProjectList<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description get my project list
   *
   * @tags project
   * @name ProjectListCreate
   * @summary get my project list
   * @request POST:/projectList
   */
  projectListCreate = (
    request: ModelGetProjectListRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelGetProjectListResponse, any>({
      path: `/projectList`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
