const useTitle = (moduleName?: string, title?: string | undefined) => {
  if (!moduleName || !title) {
    window.document.title =
      'Zettablock - Blockchain Data APIs and Infrastructure';
    return;
  }

  window.document.title = `${moduleName} - ${title}`;
};

export default useTitle;
