/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import { useState, createContext, useEffect, useMemo, useCallback } from "react";
import { convertToOas, convertToObject, parseOpenapi } from "../utils";
import dummy from "../dummy.json";
import Oas from 'oas';
import oasToSnippet from '@readme/oas-to-snippet';
import { useParams } from "react-router";
import { getOpenApiSpec } from "../apis";
import useAuth from "../../../hooks/auth";

export const ApiProjectContext = createContext<any>({});

export const ApiProjectContextProvider = ({ children }) => {
  const params = useParams()
  const [{auth}] = useAuth()
  


  const [apiProject, setApiProject] = useState({});
  const [oasObj, setOasObj] = useState<any>();
  const [response, setResponse] = useState<any>({});
  const [formData, setFormData] = useState<any>({
    query: {},
    path: {},
    header: {},
    body: {},
  });


  const setResponseData = (value: any) => {
    setResponse(value)
  }

  const setData = (type: string, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        ...value,
      },
    }));
  }

  useEffect(() => {
    
    
    const parseAndSet = async () => {
      const json = await getOpenApiSpec()
      
      parseOpenapi({ spec: json }).then((res) => {
        setApiProject(convertToObject(res));
        setOasObj(convertToOas(JSON.stringify(json)))
      });
    }
    
    if(auth) {
      parseAndSet()
    }
    // convertToOas(JSON.stringify(dummy))

    // parseOpenapi({ spec: dummy }).then((res) => {
    //   setApiProject(convertToObject(res));
    //   setOasObj(convertToOas(JSON.stringify(dummy)))
    // });

  }, [params, auth])

  const value = useMemo(() => ({
    apiProject,
    setApiProject,
    oasObj,
    setData,
    resetFormData: () => {
      setFormData({
        query: {},
        path: {},
        header: {},
        body: {},
      })
    },
    setResponseData,
    response,
    formData,
  }), [apiProject, setApiProject, oasObj, setFormData, formData, response, setResponseData])


  return <ApiProjectContext.Provider value={value}>
    {children}
  </ApiProjectContext.Provider>;
};