/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from "react-router";
import Icon from "../components/Icon";
import { useEffect, useState } from "react";
import { getHistoricalFreshnessList, getRealtimeFreshnessList, getTableFreshnessList } from "./api";
import { ModelTableFreshness, ModelTableFreshnessHistory } from "../../api/__gen__/data-contracts";
import LoadingIndicator from "../../components/LoadingIndicator";
import moment from "moment";
import classNames from "classnames";
import { Button } from "antd";
import { RiRefreshLine } from "react-icons/ri";


const twelveHoursInSeconds = 60 * 60 * 12

const FreshnessBars = ({ records }: any) => {

  return <div className="flex gap-[1px] justify-between">
    {
      records.map(record => {
        return <div
          title={`timestamp: ${record.last_refreshed_at} 
latency: ${moment().add(moment(record.last_refreshed_at).diff(moment(record.latest_timestamp), 'seconds'), 'seconds').fromNow(true)}
latest block: ${record.latest_block_number}`}
          className={
            classNames("w-[3px] h-[20px] min-w-[3px] min-h-[20px] cursor-pointer", {
              'bg-[#10BE59]': moment(record.last_refreshed_at).diff(moment(record.latest_timestamp), 'seconds') < twelveHoursInSeconds,
              'bg-[#F2F2F2]': moment(record.last_refreshed_at).diff(moment(record.latest_timestamp), 'seconds') >= twelveHoursInSeconds,

            })
          }></div>
      })
    }
  </div>
}

const FreshnessToolbar = () => {
  const navigate = useNavigate();

  return <div className="h-[60px] border-b flex items-center justify-between">
    <div className="h-[60px] flex items-center">
      <div className="cursor-pointer" onClick={() => { navigate('/v2/explore/tables') }}>
        <Icon className="w-[30px] h-[30px] ml-[30px] mr-[20px] mt-[18px] mb-[12px]" iconName="back" />
      </div>
      <div className="flex items-center mt-[26px] mb-[20px]">
        <div className="text-[14px] font-semibold">
          {'Freshness'}
        </div>
        <div className="w-[1px] bg-[#F2F2F2] h-[20px] mx-[10px]"></div>
      </div>
    </div>

  </div>
}

export const RealtimeFreshnessRow = ({ item }: any) => {
  return <div className="mb-[20px]">
    <div className="p-[20px] border rounded-t-[5px]">
      <div className="flex">
        <div className="flex-1 flex justify-between items-center">
          <div className="">
            <div className="flex gap-[5px] items-center">
              <Icon className="w-[20px] h-[20px]" iconName={item.chain || ''} />
              <div className="text-[12px] leading-[12px] font-semibold">
                {item.chain} - {item.name}
              </div>
            </div>
            <div className="mt-[5px] italic text-[10px] text-[#10BE59] leading-[10px]">
              All Data Checked
            </div>
          </div>

        </div>
        <div className="flex items-center gap-[20px] min-w-[300px] flex-1">
          <div className="">
            <div className="text-[16px] leading-[16px] text-[#1A1A1A] mb-[7px]">
              {item.latest_block_number}
            </div>
            <div className="text-[12px] text-[#777A80] leading-[12px]">
              Lastest Block
            </div>
          </div>
          <div className="h-[30px] w-[1px] bg-[#F2F2F2]" />
          <div>
            <div className="text-[16px] leading-[16px] text-[#1A1A1A] mb-[7px]">
              {moment(item.latest_timestamp || '').format('YYYY-MM-DD HH:mm:ss')}
            </div>
            <div className="text-[12px] text-[#777A80] leading-[12px]">
              Latest Timestamp
            </div>
          </div>

        </div>
      </div>
      <div>

      </div>
    </div>
  </div>
}

const FreshnessRow = ({
  item,
  index,
  setExpandRow,
  expandRow,
}: {
  item?: ModelTableFreshnessHistory
  index?: number
  setExpandRow?: (index: number | null) => void,
  expandRow?: number | null
}) => {
  const blockTable = item?.tables?.find(table => table.name?.includes('blocks')) || null

  if (!item) {
    return null
  }

  return <div className="mb-[20px]">
    <div className="p-[20px] border rounded-t-[5px]">
      <div className="flex">
        <div className="flex-1 flex justify-between items-center">
          <div className="">
            <div className="flex gap-[5px] items-center">
              <Icon className="w-[20px] h-[20px]" iconName={item.chain || ''} />
              <div className="text-[12px] leading-[12px] font-semibold">
                {item.chain}
              </div>
            </div>
            <div className="mt-[5px] italic text-[10px] text-[#10BE59] leading-[10px]">
              All Data Checked
            </div>
          </div>

        </div>
        <div className="flex items-center gap-[20px] min-w-[300px] flex-1">
          <div className="min-w-[86px]">
            <div className="text-[16px] leading-[16px] text-[#1A1A1A] mb-[7px]">
              {blockTable ? blockTable.records?.[(blockTable.records?.length || 0) - 1].latest_block_number : ''}
            </div>
            <div className="text-[12px] text-[#777A80] leading-[12px]">
              Lastest Block
            </div>
          </div>
          <div className="h-[30px] w-[1px] bg-[#F2F2F2]" />
          <div>
            <div className="text-[16px] leading-[16px] text-[#1A1A1A] mb-[7px]">
              {
                moment().add(moment(blockTable ? blockTable.records?.[0].last_refreshed_at : '').diff(moment(blockTable ? blockTable.records?.[0].latest_timestamp : ''), 'seconds'), 'seconds').fromNow(true)
              }
              {/* {(new Date(latestTimestamp).getTime()) - (new Date(timestamp || 0)).getTime()}ms */}
            </div>
            <div className="text-[12px] text-[#777A80] leading-[12px]">
              Latency
            </div>
          </div>

        </div>
        <div className="ml-[35px] cursor-pointer" onClick={() => {
          if (expandRow === index) {
            setExpandRow?.(null)
          } else {
            setExpandRow?.(index || 0)
          }
        }}>
          <Icon iconName={
            index === expandRow ? 'shrink' : "expand"
          } className="w-[16px] h-[16px]" />
        </div>
      </div>
      <div>

      </div>
    </div>
    {
      index === expandRow && item.tables?.map((table, i) => {
        const greenSticks = table.records?.reduce((acc, record) => {
          return moment(record.last_refreshed_at).diff(moment(record.latest_timestamp), 'seconds') < twelveHoursInSeconds ? acc + 1 : acc
        }, 0) || 0
        const freshnessPercentage = (greenSticks / (table.records?.length || 1) * 100).toFixed(2)

        return <div className="p-[20px] border border-t-0 pb-[25px] rounded-b-[5px]" key={i}>
          <div className="flex justify-between items-center mb-[20px]">
            <div className="flex gap-[8px]">
              <div className="font-semibold text-[14px] leading-[14px] text-[#1a1a1a]">
                {table.name}
              </div>
              <Icon iconName="check" className="w-[12px] h-[12px]" />
            </div>
            <div className="flex items-center gap-[8px]">
              <div className="text-[10px] text-[#10BE59] font-semibold">
                {`${freshnessPercentage}`}% Verified
              </div>
              <div className="h-full w-[1px] bg-[#F2F2F2]" />
              <div className="text-[10px] text-[#777A80] font-semibold">
                {
                  moment().add(moment(table.records?.[0].last_refreshed_at || 0).diff(moment(table.records?.[0].latest_timestamp || 0), 'seconds'), 'seconds').fromNow(true)
                }
              </div>
            </div>
          </div>
          <div className="">
            <FreshnessBars records={table.records} />
            {/* <Progress percent={100} /> */}
          </div>
        </div>
      })
    }
  </div>
}

const Freshness = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [realtime, setRealtime] = useState<ModelTableFreshness[]>([])
  const [historical, setHistorical] = useState<ModelTableFreshness[]>([])

  const getAndSetData = async () => {
    setIsLoading(true)
    const list = await getTableFreshnessList()

    setRealtime(list.realtime)
    setHistorical(list.historical)
    setIsLoading(false)
  }
  const [isRealtimeLoading, setIsRealtimeLoading] = useState(false)
  const getAndSetRealtimeData = async () => {
    setIsRealtimeLoading(true)
    const list = await getRealtimeFreshnessList()

    setRealtime(list)
    setIsRealtimeLoading(false)
  }
  const [isHistoricalLoading, setIsHistoricalLoading] = useState(false)
  const getAndSetHistoricalData = async () => {
    setIsHistoricalLoading(true)
    const list = await getHistoricalFreshnessList()

    setHistorical(list)
    setIsHistoricalLoading(false)
  }
  useEffect(() => {
    getAndSetData()
  }, [])

  const [expandRow, setExpandRow] = useState<number | null>(null)
  const [expandRow1, setExpandRow1] = useState<number | null>(null)

  if (isLoading) {
    return <div className="w-full h-full flex justify-center items-center">
      <LoadingIndicator />
    </div>
  }



  return <div>
    <FreshnessToolbar />

    <div className="flex">
      <div className="min-w-[620px] flex-1 border-r">
        <div className="flex items-center gap-[50px] border-b bg-[#FAFAFA] px-[30px] py-[12px] justify-between">
          <div className="text-[14px] leading-[14px] font-bold ">
            Realtime Data
          </div>
          <div className="flex gap-[10px]">
            <Button type="text" onClick={() => {
              getAndSetRealtimeData()
            }}>
              <RiRefreshLine />
            </Button>
          </div>
        </div>
        <div className="p-[30px] h-[calc(100vh-140px)] overflow-y-auto">
          {
            isRealtimeLoading ? <div className="w-full h-full flex justify-center items-center"><LoadingIndicator /></div> :
              realtime.map((item, index) => {
                return <FreshnessRow key={index} item={item} index={index} setExpandRow={setExpandRow1} expandRow={expandRow1} />
              })
          }
        </div>
      </div>
      <div className="min-w-[620px] flex-1">
        <div className="flex items-center gap-[50px] border-b bg-[#FAFAFA] px-[30px] py-[12px] justify-between">
          <div className="text-[14px] leading-[14px] font-bold ">
            Historical Data
          </div>
          <div className="flex gap-[10px]">
            <Button type="text" onClick={() => {
              getAndSetHistoricalData()
            }}>
              <RiRefreshLine />
            </Button>
          </div>
        </div>
        <div className="p-[30px] h-[calc(100vh-140px)] overflow-y-auto">
          {
            isHistoricalLoading ? <div className="w-full h-full flex justify-center items-center"><LoadingIndicator /></div> :
              historical.map((item, index) => {
                return <FreshnessRow key={index} item={item} index={index} setExpandRow={setExpandRow} expandRow={expandRow} />
              })
          }
        </div>
      </div>
    </div>

  </div>
}

export default Freshness;