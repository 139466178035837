import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { getEnvs } from '../config/envs';

const usePageTracking = () => {
  const location = useLocation();
  const envs = getEnvs();

  useEffect(() => {
    ReactGA.initialize(envs.ga4Id, {
      gaOptions: {
        debug_mode: !envs.isProd,
      },
    });
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
};

export default usePageTracking;
