import classNames from "classnames"
import { IoRocketOutline } from "react-icons/io5"


interface BaseButtonProps {
  onClick?: () => void
  className?: string
  disabled?: boolean
  text?: string
}

interface PublishButtonProps extends BaseButtonProps {
  type?: "button" | "submit" | "reset" | undefined
}

export const PublishButton = ({
  onClick,
  className,
  disabled,
  type = 'button',
  text
}: PublishButtonProps) => {

  return <button type={type} disabled={disabled} className={classNames(
    "flex items-center gap-2 border rounded px-[12px] py-[9px]  text-[12px] h-[30px]",
    !disabled && 'hover:bg-[#263dff] hover:text-[#fff]',
    className
  )} onClick={onClick}>
    {/* icon */}
    <IoRocketOutline />
    {/* text */}
    {text || 'Publish'}
  </button>
}