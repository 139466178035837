/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useGoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { toast } from 'react-toastify';
import { AuthApi, DatabaseApi } from '../../api/client';
import { useRecoilState } from 'recoil';
// import { AuthState } from './AuthState';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Cookies, useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { isEmpty, keys } from 'lodash';
import { HorizontalLine } from '../../components/HorizontalLine';
import { AxiosError } from 'axios';
// import { defaultErrorMessage } from '../error/const';
import Modal from '../../components/modals/Modal';
import Logo from '../../components/app/Logo';
import { analytics } from '../../App';

import { AuthState } from '../../modules/auth/AuthState';
import { defaultErrorMessage } from '../../modules/error/const';
import Signup from './Signup';
import Signin from './Signin';
import EmailVerification from './EmailVerification';

// wallet c

// TODO temporary login check machanism

export interface FormValues {
  username?: string;
  password?: string;
}

export default function AuthenticationPage({
  type
}: any) {
  const [cookies, setCookie] = useCookies(['auth']);
  const [auth, setAuth] = useRecoilState(AuthState);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const back = searchParams.get('back');
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: typeof cookies.auth === 'object' ? cookies.auth : {},
  });

  const [showModal, setShowModal] = useState(false);

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      const client = AuthApi();
      try {
        const resp = await client.loginGoogleCreate({
          authorizationCode: code,
        });
        if (resp.data.accessToken && resp.data.user?.id) {
          setAuth({
            accessToken: resp.data.accessToken,
            id: resp.data.user.id,
            name: resp.data.user.name,
            email: resp.data.user.email,
            profileImage: resp.data.user.profileImage,
          });

          const analyticsId = resp.data.user.email?.includes('zettablock') ? resp.data.user.id : `${resp.data.user.id} - ${resp.data.user.email}`;

          analytics.identify(analyticsId, {
            ...auth,
            tenant: auth?.tenant || 'community',
          });
          analytics.track('Auth - Login', {
            type: 'Google',
            isZettaBlockUser: resp.data.user.email?.includes('zettablock'),
            ...auth,
            tenant: auth?.tenant || 'community',
            isV2: true
          })

          if ((resp.data as any)?.newUser) {
            navigate('/v2/onboarding/choose-plan');
          }
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          // Note(jjin): return 406 means not in our whitelist
          if (error.response?.status === 406) {
            setShowModal(true);
            return;
          }

          const msg = error.response?.data.message;
          toast.error(msg || defaultErrorMessage, {
            autoClose: 2000,
            position: 'top-center',
          });
        }
        setAuth(null);
      }
    },
    onError: () => {
      toast.error('Login Failed. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
    flow: 'auth-code',
  });


  useEffect(() => {
    if (auth || !isEmpty(cookies.auth)) {
      if (back && back !== '/') {
        navigate(back);
      } else {
        navigate('/v2/explore');
      }
    }
  }, [auth, cookies.auth]);

  const renderContent = () => {
    switch (type) {
      case 'login':
        return <Signin googleLogin={googleLogin} />
      case 'register':
        return <Signup googleLogin={googleLogin} />
      case 'verify':
        return <EmailVerification />
      default:
        return <Signin googleLogin={googleLogin} />
    }
  }

  return (
    <div className='min-h-[calc(100vh-380px)] pt-16'>
      <div className='max-w-[460px] mx-[auto]'>
        <div className='flex justify-center'>
          <Logo />
        </div>
        {
          renderContent()
        }

      </div>
      <Modal
        open={showModal}
        className='flex flex-col space-y-4'
        onClickBackdrop={() => { }}
      >
        <h3 className='flex items-center space-x-2 font-bold text-lg'>
          <span>Unauthorized</span>
        </h3>
        <div className='flex-1 pb-2 overflow-y-auto max-h-[50vh] space-y-1'>
          <div>
            <p className='mb-3'>Hi there!</p>
            <p className='mb-3'>
              We&apos;re glad you&apos;re trying to log in to our service. It
              looks like you&apos;re not currently on our email whitelist.
            </p>
            <p className='mb-3'>
              Don&apos;t worry! Just click the button below to sign up on our
              website, and we&apos;ll add you to the whitelist soon.
            </p>
            <p className='mb-3'>
              If you have any issues or questions, please don&apos;t hesitate to
              contact our friendly support team at{' '}
              <a className='text-primary' href='mailto:team@zettablock.com'>
                team@zettablock.com
              </a>
              !
            </p>
            <p className='mb-3'>
              Happy Building, <br /> ZettaBlock Support Team
            </p>
          </div>
        </div>
        <div className='modal-action items-center'>
          <a
            href='https://www.zettablock.com/?signup'
            target='_blank'
            rel='noreferrer'
          >
            <button className='btn btn-primary' type='button' onClick={() => {
              analytics.track('Auth - Signup - from unauthorized modal')
            }}>
              SIGN UP
            </button>
          </a>
        </div>
      </Modal>
    </div>
  );
}
