import { useState } from 'react';
import { atomFamily, useRecoilState } from 'recoil';

export type RPCStatus = {
  isLoading?: boolean;
  error?: string | null;
};

export function useRpcStatus() {
  const [rpcStatus, setRpcStatus] = useState<RPCStatus>({ isLoading: false });

  return [rpcStatus, setRpcStatus] as const;
}

// recoil version
export const RPCState = atomFamily<RPCStatus, string | undefined | null>({
  key: 'RPCState',
  default: { isLoading: false, error: null },
});

export function useRpcStatusV3(key?: string | null) {
  const [rpcStatus, setRpcStatus] = useRecoilState(RPCState(key));

  return [rpcStatus, setRpcStatus] as const;
}

export const PageSize = 20;

// client pagination (temp solution)
export const PageSizeState = atomFamily<number, string | undefined | null>({
  key: 'PageSizeState',
  default: PageSize,
});

export function useClientPageSize(key?: string | null) {
  const [pageSize, setPageSize] = useRecoilState(PageSizeState(key));

  function showMore() {
    setPageSize(pageSize + PageSize);
  }
  return [pageSize, { showMore, setPageSize }] as const;
}
