import classNames from "classnames";
import { HTMLAttributes } from "react";
import { BsAsterisk } from "react-icons/bs";

function RequiredIcon({ className }: { className?: string }) {
  return (
    <BsAsterisk
      size='0.7rem'
      className={classNames('text-error', className)}
    />
  );
}


const InputGroup = ({
  label,
  children,
  className
}: HTMLAttributes<HTMLDivElement> & { label?: string }) => {

  return (
    <div className={classNames('flex space-x-4 items-center', className)}>
      <span className='w-1/5 flex-shrink-0 text-sm'>{label}</span>
      <RequiredIcon />
      <div className='w-full'>{children}</div>
    </div>
  );

}

export default InputGroup