import produce from 'immer';
import { useState } from 'react';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { RiSaveLine } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import LoadingIndicator from '../../components/LoadingIndicator';

import Modal from '../../components/modals/Modal';
import useAuth from '../../hooks/auth';
import { COMMUNITY } from '../auth/AuthState';
import { QueryStateV3, useUpdateQuery } from './QueryBuilderHooksV3';
import { useQueryIdV3 } from './QueryPadStateV3';
import envs from '../../config/envs';
import { analytics } from '../../App';

export default function SaveQueryModal({
  openSaveQueryModal,
  setOpenSaveQueryModal,
}: {
  openSaveQueryModal?: boolean;
  setOpenSaveQueryModal: (v: boolean) => void;
}) {
  const queryId = useQueryIdV3();
  const [{ rpcStatus }, { updateQuery }] = useUpdateQuery();
  const [values] = useRecoilState(QueryStateV3(queryId));

  const [{ auth }] = useAuth();

  const [formValues, setFormValues] = useState({
    ...values,
    access: auth?.tenant === COMMUNITY ? 'public' : 'org_internal',
    displayName: values.displayName === 'New Query' ? '' : values.displayName, // promote users to rename the query
  });
  return (
    <Modal open={openSaveQueryModal}>
      <div className='text-center text-xl font-bold'>Save Query</div>
      <div className='flex flex-col gap-3'>
        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Title</span>
          </label>
          <input
            type='text'
            className='input bg-base-300'
            placeholder='Please enter title'
            value={formValues.displayName}
            maxLength={125}
            onChange={(e) => {
              setFormValues(
                produce(formValues, (draft) => {
                  draft.displayName = e.target.value;
                })
              );
            }}
          />
        </div>
        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Description</span>
          </label>
          <textarea
            className='textarea bg-base-300'
            placeholder='Please enter description'
            value={formValues.description}
            onChange={(e) => {
              setFormValues(
                produce(formValues, (draft) => {
                  draft.description = e.target.value;
                })
              );
            }}
          />
        </div>
        {
          !envs.isCircleIntegrationEnabled && <div className='form-control'>
            <label className='label'>
              <span className='label-text flex'>Privacy setting</span>
            </label>
            <div className='form-control'>
              <label className='label cursor-pointer'>
                <span className='label-text flex items-center'>
                  Private (Only visible to me){' '}
                  {auth?.tenant === COMMUNITY && (
                    <span
                      className='tooltip ml-1 tooltip-warning tooltip-top flex items-center justify-center opacity-90 cursor-pointer'
                      data-tip='Please upgrade the plan to make query private'
                    >
                      <BsFillInfoCircleFill
                        size='0.7rem'
                        className='mx-1 text-blue-600'
                      />
                    </span>
                  )}
                </span>
                <input
                  checked={formValues.access === 'private'}
                  disabled={auth?.tenant === COMMUNITY} // NOTE: will also need to check the plan once available
                  onChange={() => {
                    setFormValues(
                      produce(formValues, (draft) => {
                        // @ts-ignore
                        draft.access = 'private';
                      })
                    );
                  }}
                  type='radio'
                  name='access_level'
                  className='radio checked:bg-blue-500'
                />
              </label>
            </div>
            {auth?.tenant !== COMMUNITY && (
              <div className='form-control'>
                <label className='label cursor-pointer'>
                  <span className='label-text'>
                    Org Internal (Visible to my org)
                  </span>

                  <input
                    checked={formValues.access === 'org_internal'}
                    onChange={() => {
                      setFormValues(
                        produce(formValues, (draft) => {
                          // @ts-ignore
                          draft.access = 'org_internal';
                        })
                      );
                    }}
                    type='radio'
                    name='access_level'
                    className='radio checked:bg-blue-500'
                  />
                </label>
              </div>
            )}
            <div className='form-control'>
              <label className='label cursor-pointer'>
                <span className='label-text'>Public (Visible to everyone)</span>
                <input
                  checked={formValues.access === 'public'}
                  onChange={() => {
                    setFormValues(
                      produce(formValues, (draft) => {
                        // @ts-ignore
                        draft.access = 'public';
                      })
                    );
                  }}
                  type='radio'
                  name='access_level'
                  className='radio checked:bg-blue-500'
                />
              </label>
            </div>
          </div>
        }

        <div className='flex gap-2 items-center'>
          <button
            className='btn btn-primary gap-4'
            onClick={async () => {
              if (!formValues.displayName) {
                toast.error('Please enter title');
                return;
              }
              const success = await updateQuery(formValues);

              if (success) {
                analytics.track('Query - Query Saved', {
                  updated: true,
                  values: formValues,
                })
                setOpenSaveQueryModal(false);
                toast.success('Query had been saved!', {
                  autoClose: 2000,
                });
              }
            }}
          >
            <RiSaveLine />
            Save
          </button>
          <button
            className='btn btn-ghost'
            style={{ border: '1px solid #aaaaaa' }}
            onClick={() => {
              setOpenSaveQueryModal(false);
            }}
          >
            Cancel
          </button>
          {rpcStatus.isLoading && <LoadingIndicator />}
        </div>
      </div>
    </Modal>
  );
}
