import {
  useElements,
} from '@stripe/react-stripe-js';
import { useEffect } from 'react';



const PaymentForm = ({ cardholderName, setCardholderName }: { cardholderName?: string, setCardholderName?: (name: string) => void }) => {
  const elements = useElements();


  useEffect(() => {
    if (elements === null) return

    // Add the card number, expiry date, and CVC fields to the form
    const cardNumber = elements?.create('cardNumber', {
      placeholder: 'Please Enter Your Card Number',
      style: {
        base: {
          padding: `10px 12px`,
          color: '#32325d',
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#a0aec0',
            fontSize: '14px',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      }
    });
    cardNumber.mount('#card-number');

    const cardExpiry = elements?.create('cardExpiry', {
      style: {
        base: {
          padding: `10px 12px`,
          color: '#32325d',
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#a0aec0',
            fontSize: '14px',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      }
    });
    cardExpiry.mount('#card-expiry');

    const cardCvc = elements?.create('cardCvc', {
      style: {
        base: {
          padding: `10px 12px`,
          color: '#32325d',
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#a0aec0',
            fontSize: '14px',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      }
    });
    cardCvc.mount('#card-cvc');

  }, [elements])

  if (!elements) return <div>Loading</div>

  return <div className='p-8 pb-2 text-[#808288]'>
    <div className='mb-3'>
      <div className='pb-2'>
        <label htmlFor="card-holder-name" >
          Cardholder Name
        </label>
      </div>
      <div id="card-holder-name" className="p-2 border border-[#e7e9ef] rounded">
        <input className='w-full outline-none' placeholder='Cardholder Name' value={cardholderName} onChange={(e) => {
          setCardholderName?.(e.target.value)
        }} />
      </div>
    </div>
    <div className='mb-3'>
      <label htmlFor="card-number">
        Card number
      </label>
      <div id="card-number" className='p-3 px-2 border border-[#e7e9ef] rounded'></div>
    </div>
    <div className='flex justify-between gap-3 mb-3'>
      <div className='flex-1'>
        <label htmlFor="card-expiry">
          Expiration date
        </label>
        <div id="card-expiry" className='p-3 px-2 border border-[#e7e9ef] rounded'></div>
      </div>

      <div className='flex-1'>
        <label htmlFor="card-cvc">
          CVC
        </label>
        <div id="card-cvc" className='p-3 px-2 border border-[#e7e9ef] rounded'></div>
      </div>
    </div>
    {/* <div className='mb-3'>
      <div className='pb-2'>
        <label htmlFor="card-holder-name" >
          Billing Address
        </label>
      </div>
      <div id="billing address" className="p-2 border border-[#e7e9ef] rounded">
        <input className='w-full outline-none' placeholder='Address' />
      </div>
    </div> */}
  </div>
}

const CreditCardInfo = ({ cardholderName, setCardholderName }: {
  cardholderName?: string
  setCardholderName?: (value: string) => void
}) => {
  return <PaymentForm cardholderName={cardholderName} setCardholderName={setCardholderName} />
}


export default CreditCardInfo