export interface IDataset {
  id: number;
  contractAddress: string;
  name: string;
  chain: string;
  schedule: string;
  tags: string[];
  labels?: string[];
  img?: string;
  imgUrl: string;
  overview: string;
  pathKey: string;
  link: string;
  github: string;
  creators: string[];
  tokenAddr: string;
  created: string;
}

export interface ITable {
  id: number;
  name: string;
}

type DatasetFilterType = 'schedule' | 'chain';

export interface IDatasetFilter {
  type: DatasetFilterType;
  name: string;
  value: string;
}

export const DATASET_FILTER_OPTS: IDatasetFilter[] = [
  {
    type: 'schedule',
    name: 'All',
    value: 'all',
  },
  {
    type: 'schedule',
    name: 'Realtime',
    value: 'realtime',
  },
  {
    type: 'schedule',
    name: 'Batch',
    value: 'batch',
  },
  {
    type: 'schedule',
    name: 'Scheduled',
    value: 'scheduled',
  },
  {
    type: 'chain',
    name: 'All',
    value: 'all',
  },
  {
    type: 'chain',
    name: 'Ethereum',
    value: 'ethereum',
  },
  {
    type: 'chain',
    name: 'Solana',
    value: 'solana',
  },
  {
    type: 'chain',
    name: 'Terra',
    value: 'terra',
  },
  {
    type: 'chain',
    name: 'Near',
    value: 'near',
  },
];
