import { ModelQuerySummary } from "../../api/__gen__/data-contracts";
import { ExploreDataEnum, useExploreData } from "../useExploreData";
import LoadingIndicator from "../../components/LoadingIndicator";
import { Link } from "react-router-dom";
import Icon from "../components/Icon";
import moment from "moment";
import { useEffect, useState } from "react";
import AuthorFilter, { AuthorFilterEnum } from "./components/AuthorFilter";
import { getAuthors } from "./components/apis";
import useAuth from "../../hooks/auth";


const Separator = ({ text }: {
  text: string
}) => {

  return <div className="flex items-center gap-[14px]">
    <div className="text-[#777A80] text-[14px]">
      {text}
    </div>
    <div className="flex-1 h-[1px] bg-[#E3E6ED]"></div>
  </div>
}

interface TableCardProps {
  dashboard: ModelQuerySummary
}
const QueryCard = ({
  dashboard
}: TableCardProps) => {
  return <Link to={`/v2/explore/dashboards/${dashboard?.id}?from=explore`}>
    <div className="rounded bg-[#fff] w-[520px] h-[72px] flex gap-[15px] p-[10px] hover:bg-[#fafafa] cursor-pointer">
      <Icon iconName="dashboardIcon" className="w-[50px] h-[50px] object-contain" />
      <div className="flex flex-col justify-between flex-1">
        <div>
          <div className="mb-[8px] font-semibold text-[16px]">
            {dashboard.displayName}
          </div>
          {/* <div className="text-[14px] text-[#777A80] line-clamp-1">
            {dashboard.description}
          </div> */}
        </div>
        <div className="flex gap-[10px] items-center text-[#B7BAC2] text-[14px]">
          {
            `Updated ${moment(dashboard.updatedTime || 0).fromNow()}`
          }
        </div>
      </div>
    </div>
  </Link>
}

const ExploreDashboardsV2 = () => {
  const {
    publicDashboards,
    dashboardAutors,
    // refecth,
    isLoading
  } = useExploreData([ExploreDataEnum.DASHBOARDS])
  const [{auth}] = useAuth()
  
  useEffect(() => {
    if(auth) {
      getAuthors('query')
    }
    
  }, [auth])

  const [selectedAuthor, setSelectedAuthor] = useState<string>(AuthorFilterEnum.ALL)

  const filteredDashboards = publicDashboards.filter(e => {
    if (selectedAuthor && selectedAuthor !== AuthorFilterEnum.ALL) {
      return e.tenant === selectedAuthor
    }
    return true
  })

  const renderQueries = () => {
    if (isLoading) {
      return <div className="h-[300px] w-full flex justify-center items-center">
        <LoadingIndicator />
      </div>
    }


    return <div className="flex flex-wrap gap-[60px] gap-y-[30px]">
      {
        filteredDashboards.map((dashboard, index) => {
          return <QueryCard key={index} dashboard={dashboard} />
        })
      }
    </div>
  }


  return <div className="min-w-[1240px] w-[1240px] px-[70px] py-[40px] mx-auto">
    {/* Title */}
    <div className="text-left mb-[24px] font-semibold flex justify-between items-center">
      <div className="text-[24px] text-[#2B2F33]leading-[24px]">
        Dashboards
      </div>
      <AuthorFilter authors={dashboardAutors} selectedAuthor={selectedAuthor || ''} onChange={(e) => { setSelectedAuthor(e) }} />

    </div>



    {/* Separator */}
    <div className="mb-[20px]">
      <Separator text={`${filteredDashboards.length} Dashboards`} />
    </div>

    {/* Query Cards */}
    {
      renderQueries()
    }


  </div>
}

export default ExploreDashboardsV2;