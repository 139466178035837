import 'react-toastify/dist/ReactToastify.css';
import { enableMapSet } from 'immer';
import { RecoilRoot } from 'recoil';
import { Helmet } from 'react-helmet';

import {
  getRecoilState,
  RecoilExternalStatePortal,
} from './modules/recoil/utils';
import ToastContainerConfig from './modules/toast/ToastContainerConfig';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useCookies } from 'react-cookie';
import envs from './config/envs';
import { AuthState } from './modules/auth/AuthState';
import AppRoutes from './Routes';
import { useHotJar } from './hooks/hotjoar';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { GlobalSettingsContextProvider } from './v2/context/global-settings';
import { ConfigProvider } from 'antd';

enableMapSet();

export const analytics = AnalyticsBrowser.load({ writeKey: 'HJEnWuExbCcf3aNq1nUiPqLORRua3r8z' })


export default function App() {
  useHotJar();
  const auth = getRecoilState(AuthState);
  const [cookies] = useCookies(['auth']);

  return (
    <RecoilRoot key={auth?.id || cookies.auth?.username || ''}>
      <GoogleOAuthProvider clientId={envs.GoogleClientID || ''}>
        <GlobalSettingsContextProvider>
          <Helmet>
            <title>
              {'Zettablock - Blockchain Data APIs and Infrastructure'}
            </title>
          </Helmet>

          <ConfigProvider
            theme={{
              token: {
                // Seed Token
                colorPrimary: '#263dff',
              },
            }}
          >
            <AppRoutes />

          </ConfigProvider>


          <ToastContainerConfig />
          <RecoilExternalStatePortal />
        </GlobalSettingsContextProvider>
      </GoogleOAuthProvider>
    </RecoilRoot>
  );
}
