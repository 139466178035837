/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelListDashboardResponse,
  ModelListQueryResponse,
  ModelListUserApiResponse,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Community<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description list pubilc dashboards
   *
   * @tags community dashboard
   * @name DashboardList
   * @summary list public dashboards
   * @request GET:/community/dashboard
   */
  dashboardList = (
    query?: {
      limit?: number;
      offset?: number;
      days?: number;
      sortBy?: string;
      sortOrder?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<ModelListDashboardResponse, any>({
      path: `/community/dashboard`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description list pubilc queries
   *
   * @tags community query
   * @name QueryList
   * @summary list public queries
   * @request GET:/community/query
   */
  queryList = (
    query?: {
      limit?: number;
      offset?: number;
      days?: number;
      sortBy?: string;
      sortOrder?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<ModelListQueryResponse, any>({
      path: `/community/query`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description list pubilc userapis
   *
   * @tags community userapis
   * @name UserapisList
   * @summary list public userapis
   * @request GET:/community/userapis
   */
  userapisList = (
    query?: {
      limit?: number;
      offset?: number;
      days?: number;
      sortBy?: string;
      sortOrder?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<ModelListUserApiResponse, any>({
      path: `/community/userapis`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
}
