function parseJson<T>(str: string, defaultValue: T): T {
  try {
    return JSON.parse(str);
  } catch (error) {
    // eslint-disable-next-line
    console.log('error', error);
  }
  return defaultValue;
}

export const localStorageEffect =
  <T>(suffix: string, defaultValue: T) =>
  // @ts-ignore
  ({ setSelf, onSet }) => {
    const key = window.location.pathname + suffix;
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(parseJson<T>(savedValue, defaultValue));
    }

    onSet((newValue: T, _: never, isReset: boolean) => {
      return isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };
