import { History, Transition } from 'history';
import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import { toast } from 'react-toastify';

// https://stackoverflow.com/questions/71572678/react-router-v-6-useprompt-typescript/71587163#71587163
type ExtendNavigator = Navigator & Pick<History, 'block'>;
export function useBlocker(
  blocker: (tx: Transition, noPrompt: boolean) => void,
  when?: boolean,
  prefix?: string
) {
  if (when === undefined) when = true;
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;
    // eslint-disable-next-line
    const location = (navigator as any as { location: Location }).location;
    // eslint-disable-next-line
    const unblock = (navigator as any as ExtendNavigator).block((tx) => {
      const noPrompt = prefix
        ? location.pathname.startsWith(prefix) &&
          tx.location.pathname.startsWith(prefix)
        : false;
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx, noPrompt);
    });

    // eslint-disable-next-line
    return unblock;
  }, [navigator, blocker, when]);
}

export default function usePrompt(
  message: string,
  when?: boolean,
  prefix?: string
) {
  const blocker = useCallback(
    (tx: Transition, noPrompt = false) => {
      // eslint-disable-next-line
      if (noPrompt || window.confirm(message)) {
        toast.dismiss();
        tx.retry();
      }
    },
    [message]
  );

  useBlocker(blocker, when, prefix);
}
