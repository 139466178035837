/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { orderBy, values } from 'lodash';
import { useParams } from 'react-router';

import NewVizSelector from './NewVizSelector';
import { AChartsList } from './ChartsState';
import {
  AQueryDetails,
  AQueryRunErrorMessage,
  AStaticTabType,
  SQueryResults,
} from './QueryState';
import {
  ChartEntity,
  isAreaChart,
  isBarChart,
  isLineChart,
  isNumberChart,
  isScatterChart,
  isTableChartV2,
} from '../chart/types';
import {
  useDeleteChart,
  useDeletePreviewTab,
} from './QueryBuilderResultsHooks';
import Results from './Results';
import {
  PreviewQueryResultId,
  TemporaryQueryResultId,
} from './QueryBuilderHooks';
import BarChartComposeV2 from './components/BarChartComposeV2';
import NumberChartCompose from './components/NumberChartCompose';
import LineChartCompose from './components/LineChartCompose';
import ScatterChartCompose from './components/ScatterChartCompose';
import AreaChartCompose from './components/AreaChartCompose';
import useAuth from '../../hooks/auth';
import TabButtonV3, { StaticTabType } from './components/TabButtonV3';
import TableChartComposeV3, {
  DownloadCsv,
} from './components/TableChartComposeV3';
import QueryHistoryV3 from './QueryHistoryV3';
import classNames from 'classnames';
import { useSearchParam } from './QueryPadState';
import { getApiDetail } from './query-rpc';
import { QueryStateV3 } from './QueryBuilderHooksV3';
import PreviewData from './PreviewData';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import { MdWarning, MdLightbulb } from 'react-icons/md';
import { useMyDashboards } from '../dashboard/DashboardListState';
import Modal from '../../components/modals/Modal';
import { DashboardFormData } from '../dashboard/dashboard-models';
import { ChartApi, DashboardApi, GptApi } from '../../api/client';
import { fetchMyDashboard } from '../dashboard/dashboard-api';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import LoadingIndicator from '../../components/LoadingIndicator';

import './QueryBuilderResultsV3.css';
import NewDashboardV3 from '../dashboard/NewDashboardV3';
import { AiOutlineCopy } from 'react-icons/ai';
import SaturatedButton from './components/interactives/SaturatedButton';
import { RiShareBoxFill } from 'react-icons/ri';
import CopyToClipboard from 'react-copy-to-clipboard';
import { analytics } from '../../App';
import { toast } from 'react-toastify';

export const AddToDashboardModal = ({
  isOpen,
  setIsOpen,
  dashboards,
  chartId,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  dashboards: DashboardFormData[];
  chartId: string | undefined;
}) => {
  const [, { getPath }] = useWorkspace();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [usedDashboards, setUsedDashboards] = useState<Set<string>>(new Set());

  const fetchDashboards = useCallback(async () => {
    if (!chartId) return;
    const client = ChartApi();
    const { data } = await client.dashboardsDetail(chartId);
    const dashboardsIncludeChart = data.dashboardsIncludeChart || [];
    const dashboardIds: Set<string> = new Set();
    dashboardsIncludeChart.forEach((dashboard) => {
      dashboardIds.add(dashboard.id as string);
    });
    setUsedDashboards(dashboardIds);
  }, [chartId]);
  useEffect(() => {
    fetchDashboards();
  }, [chartId]);

  const addToDashboard = async (id: string) => {
    const resp = await fetchMyDashboard({ id, privateApi: true });
    const charts = resp?.data.charts || [];
    const uiOptions = JSON.parse(resp?.data.uiOptionsStr);

    // If there's no layout, create one
    if (!uiOptions.layouts) {
      uiOptions.layouts = [];
    }

    const newY = uiOptions.layouts.reduce(
      (
        acc: number,
        cur: {
          y: number;
          h: number;
        }
      ) => {
        if (cur.y + cur.h > acc) {
          return cur.y + cur.h;
        }
        return acc;
      },
      0
    );

    const newLayout = {
      h: 4,
      i: chartId,
      moved: false,
      static: false,
      w: 4,
      x: 0,
      y: newY,
    };

    uiOptions.layouts?.push(newLayout);
    const client = DashboardApi();
    await client.dashboardsCreate2(id, {
      ...resp?.data,
      chartsIds: [...charts.map((e: ChartEntity) => e.id), chartId],
      uiOptionsStr: JSON.stringify(uiOptions),
    });

    setUsedDashboards(new Set([...Array.from(usedDashboards), id]));
  };

  const removeFromDashboard = async (id: string) => {
    const resp = await fetchMyDashboard({ id, privateApi: true });
    const charts = resp?.data.charts || [];
    const uiOptions = JSON.parse(resp?.data.uiOptionsStr);

    uiOptions.layouts = uiOptions.layouts?.filter(
      (e: { i: string }) => e.i !== chartId
    );
    const client = DashboardApi();

    await client.dashboardsCreate2(id, {
      ...resp?.data,
      chartsIds: [
        ...charts
          .map((e: ChartEntity) => e.id)
          .filter((e: string) => e !== chartId),
      ],
      uiOptionsStr: JSON.stringify(uiOptions),
    });

    setUsedDashboards(
      new Set(Array.from(usedDashboards).filter((e: string) => e !== id))
    );
  };

  return (
    <Modal
      open={isOpen}
      className='max-w-[24rem] p-8'
      onClickBackdrop={() => {
        setIsOpen(false);
      }}
    >
      <div className='text-center'>
        <h2 className='font-bold mb-2 text-xl'>Add to Dashboard</h2>
      </div>
      <div>
        <div className='text-sm font-semibold mb-2'>
          Create a new dashboard or selected from existing ones
        </div>
        <div className='text-right mb-2'>
          <NewDashboardV3
            onSuccess={() => {
              fetchDashboards();
            }}
          />
        </div>
      </div>
      <div className=' py-2'>
        {dashboards?.map((dashboard) => {
          return (
            <div
              className=' mb-2 border-white	p-3 bg-[#f2f2f2] flex justify-between items-center rounded'
              key={dashboard.id}
            >
              <div className='text-sm font-semibold pointer hover:underline'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href={`${getPath('dashboards')}/${dashboard?.id}`}
                >
                  {dashboard.displayName}
                </a>
              </div>
              <div>
                {usedDashboards.has(dashboard.id || '') ? (
                  <button className='group btn btn-secondary hover:bg-[#efb43f]'>
                    <span className='group-hover:hidden'>In use</span>
                    <span
                      className='hidden group-hover:inline'
                      onClick={() => {
                        removeFromDashboard(dashboard.id || '');
                      }}
                    >
                      Remove
                    </span>
                  </button>
                ) : (
                  <button
                    className='btn btn-primary'
                    onClick={() => {
                      addToDashboard(dashboard.id || '');
                    }}
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className='flex justify-end mt-3'>
        <button
          className='btn btn-ghost'
          style={{ border: '1px solid #aaaaaa' }}
          onClick={() => {
            setIsOpen(false);
          }}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

function QueryBuilderResultsV3({ runQuery }: { runQuery: () => void }) {
  const queryRunId = useSearchParam('runId');
  const [myDashboards] = useMyDashboards();
  const [{ isMyWorkspace }] = useWorkspace();
  const params = useParams();
  const queryId = params?.id;
  const queryResults = useRecoilValue(SQueryResults(params?.id));
  const [currentTab, setCurrentTab] = useRecoilState(AStaticTabType);
  const tempResult = useRecoilValue(SQueryResults(TemporaryQueryResultId));
  const previewResult = useRecoilValue(SQueryResults(PreviewQueryResultId));

  const [queryDetail] = useRecoilState(AQueryDetails(queryId));
  const [{ auth }, { isOwner }] = useAuth(queryDetail);
  const isEditable = isMyWorkspace || isOwner; //  || true;

  // TODO better chart management
  // it seems that chart cache map is caching all charts and
  // in here trying to render charts related to a speicifc query
  // NEED to manage by query id
  // charts state
  const chartsMap = useRecoilValue(AChartsList);
  const [deleteChart] = useDeleteChart();
  const charts = values(chartsMap).filter((chart) => chart.queryId === queryId);

  const handleRemove = useCallback(
    (chart: ChartEntity) => {
      deleteChart(chart);
    },
    [deleteChart]
  );

  // get all dashboards belongs to current user
  const dashboards = useMemo(() => {
    if (!myDashboards || !auth) return [];
    return myDashboards.filter((dashboard) => {
      return dashboard?.creator?.id === auth.id;
    });
  }, [myDashboards]);
  const [isOpen, setIsOpen] = useState(false);

  // delete preview tab
  const [deletePreviewTab] = useDeletePreviewTab();

  const selectedChart =
    charts.find((chart) => chart.id === currentTab.key) || charts[0];

  const [queryValues, setQueryValues] = useRecoilState(QueryStateV3(queryId));

  const [queryRunErrorMessage] = useRecoilState(AQueryRunErrorMessage);

  const [gptSolution, setGptSolution] = useState<string>('');

  const fetchAutoDebugSql = async (errorMessage: string, sql: string) => {
    const client = GptApi();
    const { data } = await client.autoDebugSqlCreate({
      errorMessage,
      sql,
    });
    return data;
  };

  useEffect(() => {
    if (!queryRunErrorMessage) {
      setGptSolution('');
      return;
    }
    const fetchAndSetGptSolution = async () => {
      const { content } = await fetchAutoDebugSql(
        queryRunErrorMessage,
        queryValues.text || ''
      );
      setGptSolution(content || '');
    };

    fetchAndSetGptSolution();
  }, [queryRunErrorMessage]);

  useEffect(() => {
    async function run(queryRunIdT: string) {
      // load fetched query text on to query pad
      const resp = await getApiDetail(queryRunIdT);
      const text = resp?.data?.text;

      if (text) {
        setQueryValues({
          ...queryValues,
          text,
        });
      }
    }

    if (queryRunId && queryDetail.id) {
      // select query history
      setCurrentTab({ label: 'query history' });
      run(queryRunId);
    }
  }, [queryDetail]);

  if (!tempResult && !queryId && currentTab.label !== 'preview') {
    return (
      <div className=' flex h-32 justify-center items-center'>
        <div className='opacity-40'>
          {"Let's get started by running a query"}
        </div>
      </div>
    );
  }

  if (queryRunErrorMessage) {
    return (
      <div className='flex-1 flex flex-col'>
        <div className='border-b-2 border-t-2 bg-[#f3f4f7]'>
          <span className='p-3 text-red-600 font-bold '>ERROR</span>
        </div>
        <div className='flex justify-between flex-1'>
          <div className='flex-1 flex flex-col items-center p-4 text-xs leading-relax'>
            <p className='mb-2 font-bold flex items-center'>
              <MdWarning size={'1.1rem'} fill='#e74c3c' className='mr-1' />
              Error Message
            </p>
            <code className='whitespace-pre-line text-center'>
              {queryRunErrorMessage}
            </code>
          </div>
          <div className='max-w-[50%] bg-[#f8f9fa] flex-1 flex flex-col items-center p-4 text-xs leading-relax box-border'>
            <p className='mb-2 font-bold flex items-center'>
              <MdLightbulb size={'1.1rem'} fill='#f19b4a' className='mr-1' />
              ChatGPT Suggestion
            </p>
            {gptSolution ? (
              <ReactMarkdown
                className='w-full md-display'
                remarkPlugins={[remarkGfm]}
              >
                {gptSolution}
              </ReactMarkdown>
            ) : (
              <LoadingIndicator />
            )}
          </div>
        </div>
      </div>
    );
  }

  const resultChart = charts.find((chart) => {
    return isTableChartV2(chart) && chart.uiOptions?.isResult;
  });
  const isResultChartTableChart = isTableChartV2(resultChart);
  const isResult = currentTab.label === 'results';
  return (
    <div>
      <div
        className={classNames(
          'tabs z-[3] relative bg-gray-50 justify-between items-center h-[50px]',
          isEditable || previewResult ? 'space-x-1' : 'space-x-4'
        )}
      >
        <div className='flex h-full'>
          {/* {previewResult && (isEditable || !queryId) && (
          <TabButtonV3
            tab={{ label: 'preview' }}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            isEditable={isEditable}
            onDeleteTab={() => {
              let nextTab: StaticTabType = { label: '' };
              if (isResultChartTableChart) {
                nextTab = { label: 'results', key: resultChart?.id };
              } else if (tempResult) {
                nextTab = { label: 'selected query' };
              } else {
                nextTab = { label: '' };
              }
              deletePreviewTab(nextTab);
            }}
          />
        )} */}

          {isResultChartTableChart && resultChart?.uiOptions?.isResult && (
            <TabButtonV3
              tab={{ label: 'results', key: resultChart?.id }}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              isEditable={isEditable}
            />
          )}

          {tempResult && (
            <TabButtonV3
              tab={{ label: 'selected query' }}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              isEditable={isEditable}
            />
          )}

          {isEditable && (
            <TabButtonV3
              tab={{ label: 'query history' }}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              isEditable={isEditable}
            />
          )}

          {orderBy(
            charts,
            // isResultChartTableChart && currentTab.label === 'results'
            //   ? charts.filter((e) => e.id !== resultChart.id)
            //   : charts,
            'createdTime',
            'asc'
          ).map((chart) => {
            return (
              <Fragment key={chart.id}>
                <TabButtonV3
                  tab={{
                    label: chart.displayName || `${chart.type} chart`,
                    key: chart.id,
                  }}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                  isEditable={isEditable}
                  onRemove={
                    isEditable
                      ? () => {
                          handleRemove(chart);
                        }
                      : null
                  }
                />
              </Fragment>
            );
          })}

          {isEditable && (
            <TabButtonV3
              tab={{ label: '+ new visualization' }}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              isEditable={isEditable}
              activeClassName='text-black'
            />
          )}
        </div>

        <div className='flex gap-2 px-3'>
          {!isResult && selectedChart && (
            <SaturatedButton
              onClick={() => {
                // Adding tracking code here
                analytics.track(
                  `Query - Add to dashboard button clicked - Table Chart`,
                  {
                    chartId: selectedChart.id,
                    location: 'from table chart compose header',
                  }
                );
                setIsOpen(true);
              }}
            >
              Add to dashboard
            </SaturatedButton>
          )}
          {isResult && queryResults?.results?.rows && (
            <SaturatedButton>
              {/* <CSVLink
                  className='flex items-center gap-1'
                  data={rows}
                  filename={`query-result-${format(new Date(), 'Pp')}.csv`}
                >
                  <HiOutlineDocumentDownload size='0.8rem' /> Download
                </CSVLink> */}

              <DownloadCsv rows={queryResults?.results?.rows} />
            </SaturatedButton>
          )}
          {isResult && (
            <CopyToClipboard text={queryResults?.results?.csvString || ''}>
              <SaturatedButton
                onClick={() => {
                  toast.success('CSV copied', {
                    autoClose: 2000,
                  });
                  // Adding tracking code here
                  analytics.track(
                    `Query - Copy csv button clicked - Table Chart`,
                    {
                      chartId: selectedChart.id,
                      location: 'from table chart compose header',
                    }
                  );
                }}
              >
                <AiOutlineCopy size='0.8rem' />
                Copy
              </SaturatedButton>
            </CopyToClipboard>
          )}
          {isResult && selectedChart && (
            <CopyToClipboard
              text={`${window.origin}/embed/chart/${selectedChart.id}`}
            >
              <SaturatedButton
                onClick={() => {
                  toast.success('Link copied', {
                    autoClose: 2000,
                  });
                  // Adding tracking code here
                  analytics.track(
                    `Query - Copy embed link button clicked - Table Chart`,
                    {
                      chartId: selectedChart.id,
                      location: 'from table chart compose header',
                    }
                  );
                }}
              >
                <RiShareBoxFill size='0.8rem' />
                Share
              </SaturatedButton>
            </CopyToClipboard>
          )}
        </div>
      </div>
      <div className='border-b border-b-gray-300 relative top-[-1px] z-[5]' />
      <div>
        {currentTab.label === 'selected query' && <Results />}
        {currentTab.label === 'query history' && <QueryHistoryV3 />}
        {currentTab.label === '+ new visualization' && <NewVizSelector />}
        {/* {currentTab.label === 'preview' && (isEditable || !queryId) && (
          <PreviewData />
        )} */}
        {/* {currentTab.label !== '+ new visualization' && <div className='mt-4 m-2 text-right'>

          <button className='btn btn-primary' onClick={() => {
            setIsOpen(true);
          }}>
            Add to dashboard
          </button></div>} */}
        {isBarChart(selectedChart) && (
          <BarChartComposeV2
            key={currentTab.key}
            chart={selectedChart}
            readOnly={!isEditable}
          />
        )}
        {isTableChartV2(selectedChart) && (
          <TableChartComposeV3
            key={currentTab.key}
            chart={selectedChart}
            readOnly={!isEditable}
            runQuery={runQuery}
          />
        )}
        {isNumberChart(selectedChart) && (
          <NumberChartCompose
            key={currentTab.key}
            chart={selectedChart}
            readOnly={!isEditable}
          />
        )}
        {isLineChart(selectedChart) && (
          <LineChartCompose
            key={currentTab.key}
            chart={selectedChart}
            readOnly={!isEditable}
          />
        )}
        {isScatterChart(selectedChart) && (
          <ScatterChartCompose
            key={currentTab.key}
            chart={selectedChart}
            readOnly={!isEditable}
          />
        )}
        {isAreaChart(selectedChart) && (
          <AreaChartCompose
            key={currentTab.key}
            chart={selectedChart}
            readOnly={!isEditable}
          />
        )}
      </div>
      <AddToDashboardModal
        chartId={selectedChart?.id}
        dashboards={dashboards || []}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
}

export default memo(QueryBuilderResultsV3);
