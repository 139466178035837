import { useEffect, useState } from "react";
import MarkdownEditor from '@uiw/react-markdown-editor';
import MarkdownPreview from "@uiw/react-markdown-preview";
import { PoProject } from "../../../../api/__gen__/data-contracts";
import { ImInfo } from "react-icons/im";
import ImagePicker from "../../../image-upload/ImagePicker";
import ChainDisplay from "./ChainDisplay";
import ChainSelect from "./ChainSelect";
import LabelsDisplay from "./LabelsDisplay";
import LabelSelect from "./LabelSelect";

const ProjectOverview = ({ projectDetail, isEditMode, onSave, onCancel }: {
  projectDetail: PoProject,
  isEditMode: boolean,
  onSave?: (data: PoProject) => void
  onCancel?: () => void
}) => {
  const [projectMetadata, setProjectMetadata] = useState<PoProject>({})

  useEffect(() => {
    setProjectMetadata(projectDetail)

  }, [projectDetail])

  const handleSave = () => {
    if (onSave) {
      onSave(projectMetadata)
    }
  }

  return <div className="py-[50px] px-[80px] overflow-auto w-full max-w-[1200px] mx-auto">
    <div id="overview" className="max-w-[780px] min-w-[780px] mx-auto">
      <div className="mb-[20px]">
        <img src={projectMetadata?.image || "https://picsum.photos/seed/picsum/80/80"} alt="" className="w-[80px] h-[80px] object-cover rounded" />
      </div>
      <div className="mb-[20px]">
        <h2 className="text-[20px] font-semibold mb-[10px]">
          {isEditMode ? <input type="text" className="w-2/3 border p-2 w-[490px] rounded text-[12px]" value={projectMetadata.name} onChange={(e) => {
            setProjectMetadata({
              ...projectMetadata,
              name: e.target.value
            })
          }} /> : projectDetail?.name || 'API Project Name'}
        </h2>
        <div className="w-[490px]">
          {
            !isEditMode ? <ChainDisplay
              chains={projectDetail?.chains || []}
              className="mb-[10px]"
            /> :
              <ChainSelect
                onChange={(e) => {
                  setProjectMetadata({
                    ...projectMetadata,
                    chains: e
                  })
                }}
                value={projectMetadata.chains || []}
              />
          }

        </div>
        <div className="w-[490px]">
          {
            !isEditMode ? <LabelsDisplay
              labels={projectDetail?.labels || []}
            /> : <LabelSelect
              options={projectMetadata?.labels?.map(e => ({ label: e, value: e })) || []}
              value={projectMetadata?.labels || []}
              onChange={(e) => {
                setProjectMetadata({
                  ...projectMetadata,
                  labels: e
                })
              }}
            />
          }

        </div>
      </div>
      <div className="flex justiy-between gap-[60px] margin-[auto]">
        <div className="mb-6 opacity-80 max-w-[490px] w-[490px] min-w-[490px]" data-color-mode="light">
          <div>
            {
              isEditMode ? <input placeholder="Excerpt" className="p-2 border outline-none w-full mb-3 rounded text-[12px]" value={projectMetadata.detail} onChange={(e) => {
                setProjectMetadata({
                  ...projectMetadata,
                  detail: e.target.value
                })
              }
              } /> : <div className="text-[#999ca0] mb-[20px] text-[14px]">
                {projectMetadata.detail}
              </div>
            }
          </div>
          <div className="mb-3">
            {
              isEditMode ?
                <ImagePicker
                  value={projectMetadata.image || ""}
                  onChange={(e) => {
                    setProjectMetadata({
                      ...projectMetadata,
                      image: e
                    })
                  }}
                /> : null
            }
          </div>
          {
            isEditMode ?

              <MarkdownEditor
                basicSetup={{
                  lineNumbers: false,
                }}
                minHeight="300px"
                maxHeight="600px"
                height="100%"
                theme='light'
                maxWidth="100%"
                value={projectMetadata.overview || ''} onChange={(e) => setProjectMetadata({
                  ...projectMetadata,
                  overview: e
                })} />
              : <MarkdownPreview
                wrapperElement={{
                  "data-color-mode": "light"
                }}
                source={projectMetadata.overview || 'API Project Overview'}
                // className={styles.setupMarkdown}
                className="markdown-body text-[#999ca0] mb-[20px] text-[14px]"
              />
          }
          {
            isEditMode && <div className="mt-6 flex gap-2 justify-end">
              <button className="bg-[#fafafa] text-[#333] px-3 py-2 rounded border-[#DDDEE0] border" onClick={() => {
                onCancel?.()
                setProjectMetadata(projectDetail)
              }}>Cancel</button>
              <button className="bg-[#263dff] text-[#fff] px-3 py-2 rounded" onClick={handleSave}>Save</button>
            </div>
          }
        </div>
        <div className="max-w-[230px] min-w-[230px]">
          {/* help */}
          {/* <div className="mb-6 text-[#999ca0] text-[12px]">
            <div className="flex gap-2 items-center">
              <BiHelpCircle />
              <div>Help</div>
            </div>
            <div className="mt-3 pl-6">
              <div className="text-[12px] text-[#999ca0] mb-2">
                <a href="https://docs.zettablock.com/" target="_blank" rel="noreferrer">
                  Document
                </a>
              </div>
              <div className="text-[12px] text-[#999ca0] mb-2">
                <a href="https://discord.com/invite/zettablock" target="_blank" rel="noreferrer">
                  Contact Us
                </a>
              </div>
            </div>
          </div> */}
          {/* Refreshes */}
          {/* <div className="mb-6 text-[#999ca0]">
            <div className="flex gap-2 items-center text-[12px]">
              <BiSync />
              <div>Refreshes</div>
            </div>
            <div className="mt-3 pl-6">
              <div className="text-[12px] text-[#999ca0] mb-2">
                Daily
              </div>
            </div>
          </div>

          <div className="mb-6 text-[#999ca0]">
            <div className="flex gap-2 items-center text-[12px]">
              <BiCloud />
              <div>Cloud Region Availability</div>
            </div>
            <div className="mt-3 pl-6">
              <div className="text-[12px] text-[#999ca0] mb-2">
                AWS US West (Oregon)
              </div>
            </div>
          </div> */}

          <div className="mb-6 text-[#999ca0]">
            <div className="flex gap-2 items-center text-[12px]">
              <ImInfo />
              <div>About Us</div>
            </div>
            <div className="mt-3 pl-6">
              <div className="text-[12px] text-[#999ca0] mb-2">
                ZettaBlock is a full-stack, flexible, and scalable data platform that joins on-chain and off-chain data, accelerating Web3 application development.
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
}

export default ProjectOverview;