import { useCallback, useState } from 'react';
import { RiProfileFill } from 'react-icons/ri';
import { v4 } from 'uuid';
import PaleButton from '../../components/interactive/PaleButton';

import { MdWidgetType } from './dashboard-models';
import DashboardWidgetConfigModal from './DashboardWidgetConfigModal';

export const createDefaultWidget = () =>
  ({
    id: v4(),
    type: 'md-text',
    displayName: 'Text widget',
    mdContent: '',
  } as MdWidgetType);

export default function NewMdWidgetButtonV3({
  onCreate,
}: {
  onCreate: (charts: MdWidgetType) => void;
}) {
  const [open, setOpen] = useState(false);
  const [mdWidget, setMdWidget] = useState<MdWidgetType>(createDefaultWidget());

  const handleClose = useCallback(() => {
    setMdWidget(createDefaultWidget());
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <PaleButton
        className='gap-3'
        onClick={() => {
          setOpen(true);
        }}
      >
        <RiProfileFill size='1.1rem' />
        <span>Add Text widget</span>
      </PaleButton>
      {open && (
        <DashboardWidgetConfigModal
          widget={mdWidget}
          onClose={handleClose}
          onApply={(widget) => {
            onCreate(widget);
            handleClose();
          }}
        />
      )}
    </>
  );
}
