import { useState, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { zToast } from '../../toast/toast';
import { deleteMyDashboard } from '../dashboard-api';
import { MyDashboardListState } from '../DashboardListState';

export function useDashboardDelete(dashboardId?: string) {
  const [loading, setLoading] = useState(false);
  const [myDashboards, setMyDashboards] = useRecoilState(MyDashboardListState);

  const deleteDashboard = useCallback(async () => {
    if (dashboardId) {
      setLoading(true);

      const resp = await deleteMyDashboard(dashboardId);

      setLoading(false);

      // TODO type guard
      // @ts-ignore
      const errorMsg = resp?.error || '';
      if (errorMsg) {
        zToast.error(errorMsg);
      } else if (resp?.data) {
        setMyDashboards(
          myDashboards?.filter((dashboard) => {
            return dashboard.id !== dashboardId;
          }) || []
        );
        return true;
      }
    }

    return false;
  }, [dashboardId]);

  return [deleteDashboard, { loading }] as const;
}
