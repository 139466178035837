const Icon = ({
  iconName,
  className = '',
  iconType = 'svg'
}: {
  iconName: string
  className?: string
  iconType?: string
}) => {

  return <img
    className={className}
    src={`/assets/icons/${iconName}.${iconType}`}
  />
}

export default Icon;