/* eslint-disable @typescript-eslint/no-explicit-any */
import { useParams } from 'react-router';
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import Beta from './Beta';
import CSV from './csv/CSV';
import Mongodb from './mongodb/Mongodb';
import Postgresql from './postgresql/Postgresql';

export default function Upload() {
  const { type } = useParams();

  return (
    <LayoutBodyCard className='lg:basis-2/5 basis-full overflow-hidden'>
      {type === 'mongodb' && <Mongodb />}
      {type === 'beta' && <Beta />}
      {type === 'PostgreSQL' && <Postgresql />}
      {type === 'csv' && <CSV />}
    </LayoutBodyCard>
  );
}
