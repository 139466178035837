import { useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { IoIosCopy } from "react-icons/io"
import { toast } from "react-toastify"
import Logo from "./components/app/Logo"
import { useSearchParams } from "react-router-dom"

const WAITLIST_ID = 10428

const Waitlist = () => {
  const [waitlistData, setWaitlistData] = useState<{
    priority: number,
    email: string
    referral_link: string
    referral_token: string
  } | null>(null)
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const [searchParams] = useSearchParams();
  const [accessToken, setAccessToken] = useState('')
  const [waitlist, setWaitlist] = useState([])
  const [offboardUser, setOffboardUser] = useState('')
  const [isSignup, setIsSignup] = useState(true)


  const submitWaitlist = async () => {
    setLoading(true);
    try {
      const res = await fetch("https://api.getwaitlist.com/api/v1/waiter", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          waitlist_id: WAITLIST_ID,
          email,
          referral_link: `https://www.getwaitlist.com/waitlist/10428?ref_id=${searchParams.get('ref')}`
        })
      })

      const data = await res.json()
      setWaitlistData(data);
      setLoading(false);
    } catch (error) {
      toast.error('something went wrong')
      setLoading(false);
    }
  }

  const checkStatus = async () => {
    const res = await fetch(`https://api.getwaitlist.com/api/v1/waiter?email=${email}&waitlist_id=10428`)

    const data = await res.json()
    setWaitlistData(data)
  }

  const authenticate = async () => {
    const res = await fetch("https://api.getwaitlist.com/api/v1/auth/create_tokens", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: 'joseph.jin@zettablock.com',
        password: 'jinyuhui1994'
      })
    })
    const data = await res.json()
    setAccessToken(data.access_token)
  }


  const offboard = async () => {
    const res = await fetch("https://api.getwaitlist.com/api/v1/waiter", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        offboard_request: true,
        waiters: [{
          uuid: offboardUser
        }],
        waitlist_id: 10428
      })
    })
    const data = await res.json()
    alert(`successfully offboarded user ${data[0].email}`)
  }

  const getWaitlist = async () => {
    const res = await fetch("https://api.getwaitlist.com/api/v2/signup/10428", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
    })
    const data = await res.json()

    setWaitlist(data.signups)
  }

  return <div id="waitlist-page">
    <div className="flex justify-center py-20">
      <Logo />
    </div>
    <div className="w-[520px] bg-[#fff] min-h-[40vh] m-auto shadow-gray-300 rounded-md overflow-hidden shadow-md">
      <h1 className="text-[#51565c] border-b border-gray-100 p-8 px-16 mb-6 font-bold text-lg bg-[#fbfcfd]">{
        waitlistData ? 'Successfully signed up ZettaBlock Waitlist' : <span>
          {
            isSignup ? 'Signup for ZettaBlock Waitlist' : 'Check your status'
          }
        </span>
      }</h1>

      {waitlistData ?
        <div className="signup-status px-16 pb-12">
          {/* status */}
          <div className="flex">
            <div className="flex-1 mr-1 bg-[#f8f9fa] p-3 text-center rounded-md">
              {/* position */}
              <h2 className="font-bold text-[#575e63]">
                Your Position
              </h2>
              <div className="text-4xl font-bold text-primary mt-1">
                {waitlistData.priority}
              </div>
            </div>
            <div className="flex-1 ml-2 bg-[#f8f9fa] p-3 text-center rounded-md">
              {/* position */}
              <h2 className="font-bold text-[#575e63]">
                People on Waitlist
              </h2>
              <div className="text-4xl font-bold text-primary mt-1">
                333
              </div>
            </div>
          </div>

          <div className=" bg-[#f8f9fa] relative py-4 text-center mt-5 rounded-md">
            <div className="absolute right-0 top-0 ">
              <CopyToClipboard text={
                `http://localhost:3000/waitlist?ref=${waitlistData.referral_token}`
              }>
                <button
                  className="bg-[#e0e5eb] flex items-center font-semibold px-3 py-1 rounded-md text-md"
                  onClick={() => {
                    toast.success('Referral link copied', {
                      autoClose: 3000,
                    });
                  }}
                >
                  <IoIosCopy size='.75rem' className='opacity-60 mr-1 ' /> <span className="text-xs">COPY</span>
                </button>
              </CopyToClipboard>
            </div>
            <div>Referral Link</div>
            <div className="text-primary mt-2">
              {
                // waitlistData.referral_link
                `http://localhost:3000/waitlist?ref=${waitlistData.referral_token}`
              }
            </div>
          </div>

          <div>
            <h3 className="font-semibold text-center mt-8 mb-3 text-[#52565b]">
              Share and refer your friends to move up in line!
            </h3>
            <div className="flex justify-around m-auto">
              <div className="flex-1 mr-3">
                <button className="w-full btn btn-primary">Twitter</button>
              </div>
              <div className="flex-1">
                <button className="w-full btn btn-primary">Linkedin</button>
              </div>
            </div>
          </div>
        </div>
        : <div className="signup-form px-16">
          <div className="w-full m-auto">
            <label htmlFor="email" className="font-semibold text-[#51565c]">Email</label>
            <input
              placeholder="email@email.com"
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              className="text-[#51565c] outline-none bg-[#f8f9fa] w-full border mt-1 p-2 px-3 rounded" type="email" id="email" />
            <button
              disabled={loading}
              onClick={
                isSignup ? submitWaitlist : checkStatus
              }
              className="btn btn-primary mt-10 w-full">{
                loading ? 'Loading...' : <span>
                  {
                    isSignup ? 'Sign up' : 'Check Status'
                  }
                </span>
              }
            </button>
            {isSignup ? <div className="text-[#51565c] mt-8 text-center font-semibold">
              Signed up before? <span onClick={() => {
                setIsSignup(false)
              }} className="text-blue-500 cursor-pointer underline underline-offset-2" >Check your status</span>
            </div> : <div className="text-[#51565c] mt-8 text-center font-semibold">
              Haven&lsquo;t signed up yet?  <span onClick={() => {
                setIsSignup(true)
              }} className="text-blue-500 cursor-pointer underline underline-offset-2" >Signup</span>
            </div>}
          </div>
        </div>}
    </div>
  </div>


  return <div className="bg-[#fff]">
    <div className="mb-6 p-4">
      <h1 className="mb-1">Access Token</h1>
      {
        accessToken || 'No access token yet'
      }
      <br />
      <button onClick={authenticate} className="btn btn-primary mt-2" >Get Access Token</button>
    </div>

    <div className="mb-6 p-4">
      <h1 className="mb-2">Waitlist</h1>
      {
        waitlist.length ? waitlist.map((item: {
          email: string,
          uuid: string,
          priority: number
        }) => {
          return <div key={item.uuid} className="border p-2">
            <div>User Email: {item.email}</div>
            <div>UUID: {item.uuid}</div>
            <div>Rank: {item.priority}</div>
          </div>
        }) : 'No waitlist'
      }
      <br />
      <button onClick={getWaitlist} className="btn btn-primary mt-2" >Get Waitlist</button>
    </div>





    <div className="mb-6 p-4">

      <h1 className="mb-2">Signup for waitlist: What is your email address?</h1>
      <input onChange={() => {
        // setWaitlistData({
        //   email: e.target.value
        // })
      }} placeholder="email address" /> <br />
      <button className="btn btn-primary mt-2" onClick={submitWaitlist}>Submit</button>
    </div>

    <div className="mb-6 p-4">

      <h1 className="mb-2">Offboard a User</h1>
      <input onChange={(e) => {
        setOffboardUser(e.target.value)
      }} placeholder="user uuid" /> <br />
      <button className="btn btn-primary mt-2" onClick={offboard}>Offboard User</button>
    </div>

    <br />
    <button onClick={offboard}>OFFBOARD</button>
    <button onClick={authenticate}>Auth</button>
    <br />
    <button onClick={getWaitlist}>Get Waitlist</button>
  </div>
}

export default Waitlist