import './table-columns.css';
import { isEmpty } from 'lodash';
import { useRecoilState } from 'recoil';

import { SelectedTableSchemaState } from '../database/TableSchemaState';
import { memo } from 'react';
import { formatColumn } from './utils/sql';
import { useTables } from '../database/schema-hooks';
import { SelectedSchemaState } from '../database/SchemaState';
import { ModelColumn } from '../../api/__gen__/data-contracts';

const MAPPING = {
  "blocks": [
    "data_creation_date",
    "etl_process_time"
  ],

  "transactions": [
    "data_creation_date",
    "etl_process_time",
  ],

  "traces": [
    "type",
    "data_creation_date",
    "etl_process_time",
  ],

  "logs": [
    "data_creation_date",
    "etl_process_time",
  ],


  "erc20_evt_transfer": [
    "value_hex",
    "symbol",
    "decimals",
    "data_creation_date",
    "etl_process_time",
  ],

  "erc721_evt_transfer": [
    "token_id_hex",
    "symbol",
    "name",
    "data_creation_date",
    "etl_process_time",
  ],

  "erc1155_evt_transfer_batch": [
    "symbol",
    "name",
    "data_creation_date",
    "etl_process_time",
  ],

  "erc1155_evt_transfer_single": [
    "id_hex",
    "value_hex",
    "symbol",
    "name",
    "data_creation_date",
    "etl_process_time",
  ],

  "contract_creations": [
    "data_creation_date",
    "etl_process_time",
  ],

  "native_token_transfers": [
    "data_creation_date",
    "etl_process_time",
  ],
}
export const tempFilterFn = (table: string | undefined, schema: string | undefined, columns: ModelColumn[], isRealtimeDB: boolean | undefined) => {
  if (!table || !schema || schema !== 'ethereum_mainnet' || isRealtimeDB) {
    return columns
  }

  return columns.filter((col) => {
    return !MAPPING[table]?.includes(col.name)
  })

}

function TableColumnsV3({
  onInsertClick,
  isRealtimeDB
}: {
  onInsertClick: (value: string) => void;
  isRealtimeDB?: boolean;
}) {
  const [selectedSchema] = useRecoilState(SelectedSchemaState);
  const [{ tables }] = useTables(selectedSchema);
  const [selectedTable] = useRecoilState(SelectedTableSchemaState);


  const columns =
    tempFilterFn(selectedTable, selectedSchema?.schema, tables?.items?.find((t) => t.name === selectedTable)?.columns || [], isRealtimeDB);

  return (
    <div className='overflow-y-auto flex-1'>
      <div className='px-3 pb-2 max-h-[100%] overflow-auto'>
        {isEmpty(columns) ? (
          <span className='opacity-50'>{'No columns'}</span>
        ) : (
          columns.map((item) => {
            return (
              <div
                key={item.name}
                className='text-md flex flex-wrap opacity-80 hover:opacity-100 cursor-pointer'
                onClick={() => {
                  if (item.name) {
                    onInsertClick(formatColumn(item.name));
                  }
                }}
              >
                <span className='table-column-item break-words min-w-0 flex items-center flex-nowrap'>
                  {item.name}
                  <span className='w-1' />
                  <span
                    className='badge badge-sm badge-outline opacity-60'
                    onClick={(e) => {
                      if (item.name) {
                        onInsertClick(
                          formatColumn(item.name, {
                            withComma: true,
                          })
                        );
                      }

                      e.stopPropagation();
                    }}
                  >
                    + comma
                  </span>
                </span>
                <div title={item.type} className='max-w-[150px] text-right opacity-60 whitespace-nowrap ml-auto overflow-hidden text-ellipsis'>{item.type}</div>
                <div className='mb-2' />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default memo(TableColumnsV3);
