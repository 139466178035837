/* eslint-disable @typescript-eslint/no-explicit-any */
// import BreadCrumb from "../components/BreadCrumb"
// import FilterGroup from "./components/FilterGroup"
import { ExploreItem, ExploreItemType } from "./components/ExploreItem"
import { useExploreData } from "../useExploreData"
import LoadingIndicator from "../../components/LoadingIndicator"

import backBtnIcon from './back.svg';
import { useNavigate } from "react-router";

export enum ExploreEntityType {
  API = 'api',
  QUERY = 'query',
  DASHBOARD = 'dashboard',
  DATA_SOURCE = 'data-source',
  CONTRACT = 'contract',
  PROJECT = 'project',

}

export const ExploreTopBar = ({
  text1,
  text2
}: any) => {

  const navigate = useNavigate()
  const navigateBack = () => {
    navigate('/v2/explore')
  }

  return <div className="h-[60px] border-b flex items-center">
    <div onClick={navigateBack} className="cursor-pointer">
      <img src={backBtnIcon} className="w-[30px] h-[30px] ml-[30px] mr-[20px] mt-[18px] mb-[12px]" />
    </div>
    <div className="flex items-center mt-[26px] mb-[20px]">
      <div className="text-[14px] font-semibold">
        {text1}
      </div>
      {/* <div className="w-[1px] bg-[#F2F2F2] h-[20px] mx-[10px]"></div> */}
      <div className="mx-[10px]">/</div>
      {/* <div className="text-[12px] text-[#757D8D]">
        {text2}
      </div> */}
      <div className="text-[14px] font-semibold">
        {text2}
      </div>
    </div>
  </div>
}


const ExploreApis = () => {
  const {
    publicApis,
    isLoading
  } = useExploreData()


  if (isLoading) return <div className="h-[300px] w-full flex justify-center items-center">
    <LoadingIndicator />
  </div>

  return <div className="min-w-[1240px]">
    <ExploreTopBar
      text1="Explore"
      text2={
        `Browse APIs (${publicApis.length || 0})`
      }
    />

    <div className="min-w-[1080px] w-[1080px] 2xl:min-w-[1600px] 2xl:w-[1600px] 3xl:mx-auto mx-[80px] 2xl:mx-[auto] mt-[50px]">
      {/* List of Items */}
      <div id="section-content" className=" flex justify-between flex-wrap  ">
        {
          publicApis.map((item, index) => {
            return <ExploreItem type={ExploreItemType.API} key={index}
              title={item?.name}
              author={item?.owner?.name}
              imgUrl={item?.image}
              description={item?.detail}
              item={item}
              from="explore"
            />
          })
        }
      </div>
    </div>
  </div>
}

export default ExploreApis;