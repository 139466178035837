import { useQueryId } from './QueryPadState';
import {
  useQueryPadInjectV3,
  useTextToSql,
  useTextToSqlTranslate,
} from './QueryPadStateV3';
import { AlphaBadge } from '../../components/badges/AlphaBadge';
import ModalV3 from '../../components/modals/ModalV3';
import LoadingIndicator from '../../components/LoadingIndicator';

export default function TextToSearchModal() {
  const [queryId] = useQueryId();
  const [{ isOpenTTS, ttsInputText }, { setTtsInputText, toggleIsOpenTTS }] =
    useTextToSql(queryId);

  const [{ isLoading, error, ttsTranslatedSql }, { getTranslatedSql }] =
    useTextToSqlTranslate(queryId);

  const [{ formatQuery }] = useQueryPadInjectV3();

  return (
    <ModalV3
      open={isOpenTTS}
      onClickBackdrop={() => {
        toggleIsOpenTTS();
      }}
    >
      <div className='flex flex-col h-full w-full bg-base-200 p-4 border-l'>
        <div className='flex items-center gap-2'>
          <div className='font-lg font-bold'>Text to SQL</div>
          <AlphaBadge className='badge-sm' />
          <div className='text-xs'>
            Please select the database and table first.
          </div>
        </div>
        <div className='h-2' />

        <div className='flex h-[2.4rem]'>
          <input
            type='text'
            className='border-base-content border border-opacity-60 h-full flex-1 active:outline-none focus:outline-none p-2 text-sm'
            placeholder='Enter text...'
            value={ttsInputText}
            disabled={isLoading}
            onChange={(e) => {
              setTtsInputText(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                getTranslatedSql();
              }
            }}
            onFocus={(e) => {
              e.target.select();
            }}
          />
          <button
            className='bg-opacity-60 text-white bg-base-content h-full px-3 font-semibold'
            onClick={() => {
              getTranslatedSql();
            }}
          >
            Translate
          </button>
        </div>
        <div className='flex flex-col flex-1 p-2 bg-white overflow-auto min-h-[11rem] max-h-[80vh]'>
          {isLoading && (
            <div className='flex-1 flex flex-col items-center justify-center'>
              <LoadingIndicator />
            </div>
          )}
          {!isLoading && error && (
            <div className='flex flex-col gap-3 text-xs whitespace-pre-wrap text-error'>
              {error}
            </div>
          )}
          {!isLoading && !error && ttsTranslatedSql && (
            <div className='flex flex-col gap-3 text-xs whitespace-pre-wrap'>
              {ttsTranslatedSql}
            </div>
          )}
          <div className='flex flex-col gap-3 opacity-40 text-xs'>
            <span className='font-bold text-lg'>Example</span>
            <div>How many unique transaction types are there?</div>
            <div>
              Show the transaction hashes and fees with the highest fees
            </div>
            <div>Transaction hashs with the largest delivered amounts</div>
          </div>
        </div>
        <div className='h-2' />
        <div className=''>
          <button
            className='btn btn-sm btn-primary'
            style={{ borderRadius: 0 }}
            onClick={() => {
              formatQuery({ text: ttsTranslatedSql });
              toggleIsOpenTTS();
            }}
          >
            Insert
          </button>
        </div>
      </div>
    </ModalV3>
  );
}
