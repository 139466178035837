/* eslint-disable @typescript-eslint/no-explicit-any */
// import { sql } from "@codemirror/lang-sql"
// import ReactCodeMirror from "@uiw/react-codemirror"
import { useEffect, useState } from "react"
import { TriggersApi } from "../../api/client"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router"

const formatURL = (url: string) => {
  if (url.startsWith('http')) return url
  if (url.startsWith('https')) return url
  return `https://${url}`
}

interface TriggerModalProps {
  onSave: (name: string) => void
  apiId?: string
  baseUrl?: string
}

const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  }
  catch (e) {
    return false;
  }
}

const TriggerModal = ({
  onSave,
  apiId,
  baseUrl = '',
}: TriggerModalProps) => {
  const params = useParams()
  const triggerId = params.triggerId || ''

  const [formValue, setFormValue] = useState({
    description: '',
    destinationURL: '',
    displayName: '',
    granularity: 'PerBatch',
  })

  const navigate = useNavigate();
  useEffect(() => {
    if (!triggerId) return

    const client = TriggersApi()
    const fetchTrigger = async () => {
      try {
        const res = (await client.triggersDetail(triggerId)).data
        if (res.deletedTime) {
          navigate(`${baseUrl}triggers`)
        }
        setFormValue({
          description: res.description || '',
          destinationURL: res.destinationURL || '',
          displayName: res.displayName || '',
          granularity: res.granularity || '',
        })
      } catch (error) {
        toast.error('Fetching Trigger failed...')
      }
    }

    fetchTrigger()
  }, [triggerId])


  const updateTrigger = async () => {
    if (!apiId || !triggerId) return
    const destinationURL = formatURL(formValue.destinationURL)

    if (!isValidUrl(destinationURL)) {
      toast.warn('Please enter a valid URL')
      return
    }
    if(!formValue.displayName) {
      toast.warn('Please enter a name')
      return
    }
    const client = TriggersApi()
    try {
      await client.triggersCreate2(triggerId, {
        description: formValue.description,
        destinationURL: formatURL(formValue.destinationURL),
        displayName: formValue.displayName,
        granularity: formValue.granularity,
      })
      toast.info('Updating Trigger...', {
        autoClose: 1000
      })
      onSave('update')
    } catch (error: any) {
      if (error.response?.data?.message) {
        toast.error(error.response?.data?.message)
        return
      }
      toast.error('Updating Trigger failed...')
    }
  }

  const deleteTrigger = async () => {
    if (!apiId || !triggerId) return
    const client = TriggersApi()
    try {
      await client.triggersDelete(triggerId)
      toast.info('Deleting Trigger...', {
        autoClose: 1000
      })
      onSave('delete')
    } catch (error: any) {
      if (error.response?.data?.message) {
        toast.error(error.response?.data?.message)
        return
      }
      toast.error('Deleting Trigger failed...')
    }
  }

  const saveTrigger = async () => {
    if (!apiId) return

    const destinationURL = formatURL(formValue.destinationURL)

    if (!isValidUrl(destinationURL)) {
      toast.warn('Please enter a valid URL')
      return
    }
    if(!formValue.displayName) {
      toast.warn('Please enter a name')
      return
    }
    const client = TriggersApi()
    try {
      await client.triggersCreate({
        userApiId: apiId,
        description: formValue.description,
        destinationURL: formatURL(formValue.destinationURL),
        displayName: formValue.displayName,
        granularity: formValue.granularity,
      })
      toast.info('Creating Trigger...', {
        autoClose: 1000
      })
      onSave('save')
    } catch (error: any) {
      if (error.response?.data?.message) {
        toast.error(error.response?.data?.message)
        return
      }
      toast.error('Creating Trigger failed...')
    }
  }



  return <div className="py-12 mx-[auto] w-[600px]">
    <div className="mb-4 flex items-center">
      <h2 className="w-[160px] font-semibold text-lg mr-2">Name</h2>
      <input className="border border-slate-200 px-2 py-[5px] rounded w-[320px]" onChange={(e) => {
        setFormValue({
          ...formValue,
          displayName: e.target.value
        })
      }}
        value={formValue.displayName}
      />
    </div>
    <div className="mb-4 flex items-center">
      <h2 className="w-[160px] font-semibold text-lg mr-2">Destination URL</h2>
      <input className="border border-slate-200 px-2 py-[5px] rounded w-[320px]" onChange={(e) => {
        setFormValue({
          ...formValue,
          destinationURL: e.target.value
        })
      }} value={formValue.destinationURL} />
    </div>
    <div className="mb-4 flex items-center">
      <h2 className="w-[160px] font-semibold text-lg mr-2">Destination</h2>
      <select value="webhook" className="select w-full max-w-xs border-1 border-slate-200	">
        <option disabled selected>Select Destination</option>
        <option value="webhook">Webhook</option>
      </select>
    </div>
    <div className="mb-4 flex items-center">
      <h2 className="w-[160px] font-semibold text-lg mr-2">Granularity</h2>
      <select onChange={(e) => {
        setFormValue({
          ...formValue,
          granularity: (e.target).value
        })
      }} value={formValue.granularity} className="select w-full max-w-xs border-1 border-slate-200	">
        <option disabled selected>Select Granularity</option>
        <option value="PerRecord">PerRecord</option>
        <option value="PerBatch">PerBatch</option>
      </select>
    </div>
    <div className="mb-4 flex items-center">
      <h2 className="w-[160px] font-semibold text-lg mr-2">Condition</h2>
      <select value="new_data" className="select w-full max-w-xs border-1 border-slate-200	">
        <option disabled selected>Choose Condition</option>
        <option value="new_data">All new data</option>
      </select>
    </div>
    <div className="mb-4 flex items-center">
      <h2 className="w-[160px] font-semibold text-lg mr-2">Format</h2>
      <select value="json" className="select w-full max-w-xs border-1 border-slate-200	">
        <option disabled selected>Choose Format</option>
        <option value="json">JSON</option>
      </select>
    </div>

    {/* <div className="">
      <h2 className="font-semibold text-lg mb-2">SQL</h2>
      <ReactCodeMirror style={{ height: 400, border: '1px solid #e3e3e3' }} extensions={[sql()]} height="400px" />
    </div> */}
    <div className="mt-4 text-right">
      {!triggerId && <button className="btn btn-primary" onClick={saveTrigger}>Save Trigger</button>}

      {triggerId && <>
        <button className="btn btn-secondary ml-2" onClick={updateTrigger}>
          Update Trigger
        </button>
        <button className="btn btn-warn ml-2" onClick={deleteTrigger}>
          Delete Trigger
        </button>
      </>}
    </div>
  </div>
}

export default TriggerModal