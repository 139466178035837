import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableSeriesOptionV2 from './DraggableSeriesOptionV2';
import { H6 } from '../../../components/typographic/Headers';
import { SetBarChartOptions } from './BarChartComposeV2Hooks';
import { ChartOptionsV2 } from '../../chart/types-v2';

export default function SeriesCustomizerV2({
  barChartOptions,
  setBarChartOptions,
}: {
  setBarChartOptions: SetBarChartOptions;
  barChartOptions: ChartOptionsV2;
}) {
  const yCols = barChartOptions?.uiOptions?.dataOptions?.yCols || [];

  const groupByCol = barChartOptions?.uiOptions?.dataOptions?.groupByCol;
  const groupByYCols =
    barChartOptions?.uiOptions?.dataOptions?.groupByYCols || [];

  return (
    <div className='rounded-lg border flex-1 w-full'>
      <div className='flex flex-row items-center space-x-2 w-full p-2'>
        <div className='w-8' />
        <H6 className='flex-1 font-bold w-full'>Series</H6>
        <H6 className='flex-1 font-bold w-full'>Chart Type</H6>
        <H6 className='flex-1 font-bold w-full'>Color</H6>
      </div>
      <DndProvider backend={HTML5Backend}>
        {(groupByCol ? groupByYCols : yCols).map((yCol, idx) => (
          <DraggableSeriesOptionV2
            key={`${yCol.colName}-${idx}-${groupByCol}`}
            idx={idx}
            yColsPath={
              groupByCol
                ? 'uiOptions.dataOptions.groupByYCols'
                : 'uiOptions.dataOptions.yCols'
            }
            yCol={yCol}
            barChartOptions={barChartOptions}
            setBarChartOptions={setBarChartOptions}
          />
        ))}
      </DndProvider>
    </div>
  );
}
