import classNames from 'classnames';
import { HTMLAttributes, memo, useEffect } from 'react';
import { BsPlayFill } from 'react-icons/bs';
import { useRecoilState } from 'recoil';
import { AQueryRunErrorMessage, QueryRunStopper } from '../QueryState';
import { toast } from 'react-toastify';
import { analytics } from '../../../App';

function QueryBuilderRunButtonV3({
  startedAt,
  isQueryRunning,
  needQueryUpdate,
  hasHighlightedText,
  onClick,
  disabled,
  ...props
}: {
  needQueryUpdate?: boolean;
  isQueryRunning: boolean;
  hasHighlightedText: boolean;
  startedAt?: Date | null;
  disabled?: boolean;
} & HTMLAttributes<HTMLButtonElement>) {
  let label;

  const [queryRunStopper] = useRecoilState(QueryRunStopper);
  const [, setQueryRunErrorMessage] = useRecoilState(AQueryRunErrorMessage);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (startedAt && isQueryRunning) {
      timeout = setTimeout(() => {
        queryRunStopper?.cancelQuery();
        toast.warn('Query run timeout');
      }, 30 * 60 * 1000); // Note(jjin): temp solution, revisit this later
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [startedAt, isQueryRunning, queryRunStopper]);

  if (isQueryRunning) {
    label = <span>{'Cancel'}</span>;
  } else if (hasHighlightedText) {
    label = <span>{'RUN SELECTED'}</span>;
  } else if (needQueryUpdate) {
    label = <span>{'SAVE & RUN'}</span>;
  } else {
    label = (
      <span className='flex gap-3 pr-2'>
        <BsPlayFill size='0.8rem' /> {'RUN'}
      </span>
    );
  }

  const handleButtonClick = ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isQueryRunning) {
      // Adding tracking code here
      analytics.track(`Query - Query run cancel button clicked`)
      queryRunStopper?.cancelQuery();
    } else {
      onClick?.(e);
      setQueryRunErrorMessage('');
    }
  })

  return (
    <button
      disabled={disabled}
      className={classNames(
        'btn btn-primary btn-sm min-w-[7rem]',
        isQueryRunning && 'btn-error'
      )}
      {...props}
      onClick={handleButtonClick}
    >
      {label}
    </button >
  );
}

export default memo(QueryBuilderRunButtonV3);
