/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import classNames from "classnames";
import { useEffect, useMemo, useState, } from "react";
import MarkdownEditor from '@uiw/react-markdown-editor';

import { NavLink, Navigate, Route, Routes, useParams, useSearchParams } from "react-router-dom";
import { useScrollToLocation } from "../../hooks/useScrollToLocation";
import { ImInfo } from "react-icons/im";
import { TableDisplay } from "./TableDisplay";
import { getProjectDetailById, getTablesByProjectId, publishProject, unpublishProject, updateProjectMetadata, updateTableMetadata } from "../workspace/apis";
import MarkdownPreview from "@uiw/react-markdown-preview";
import moment from "moment";
import { toast } from "react-toastify";
import LoadingIndicator from "../../components/LoadingIndicator";
import { getAllDefaultProjectTables } from "../workspace/utils";
import ImagePicker from "../image-upload/ImagePicker";
import { ProjectTopbar } from "../workspace/api-project/components/ApiProjectHeader";
import ChainSelect from "../workspace/api-project/components/ChainSelect";
import ChainDisplay from "../workspace/api-project/components/ChainDisplay";
import LabelsDisplay from "../workspace/api-project/components/LabelsDisplay";
import LabelSelect from "../workspace/api-project/components/LabelSelect";

export enum TabName {
  OVERVIEW = 'Overview',
  COLUMNS = 'Columns',
  PREVIEW = 'Preview',
  EXAMPLE = 'Example',
  PLAYGROUND = 'Playground',
}

interface OverviewProps {
  projectDetail: any;
  isEditMode?: boolean
  onSave?: (projectDetail: any) => void
}
const Overview = ({
  projectDetail,
  isEditMode = false,
  onSave
}: OverviewProps): JSX.Element => {
  const [projectMetadata, setProjectMetadata] = useState<any>(projectDetail)

  useEffect(() => {
    setProjectMetadata(projectDetail)
  }, [projectDetail])

  const handleSave = () => {
    if (onSave) {
      onSave(projectMetadata)
    }
  }

  if (!projectMetadata) return <div className="h-[300px] w-full flex justify-center items-center">
    <LoadingIndicator />
  </div>

  return <div className="py-[50px] px-[80px] overflow-auto w-full max-w-[1200px]">
    <div id="overview" className="flex-1">
      <div className="mb-[20px]">
        <img src={projectMetadata.image || "https://picsum.photos/seed/picsum/80/80"} alt="" className="w-[80px] h-[80px] object-cover rounded" />
      </div>
      <div className="mb-[20px]">
        <h2 className="text-[20px] font-semibold">
          {
            isEditMode ? <input type="text" className="w-2/3 border p-2 w-[490px] rounded text-[12px]" value={projectMetadata.name} onChange={(e) => {
              setProjectMetadata({
                ...projectMetadata,
                name: e.target.value
              })
            }} /> : (projectMetadata.name || '')
          }
        </h2>
      </div>
      <div className="mt-2 w-1/2">
        {
          !isEditMode ? <div className="flex gap-2">
            <ChainDisplay
              className="mb-[15px]"
              chains={projectDetail?.chains || []}
            />
          </div> :
            <ChainSelect
              value={projectMetadata.chains || []}
              onChange={(e) => {
                setProjectMetadata({
                  ...projectMetadata,
                  chains: e
                })
              }}
            />
        }
      </div>
      <div className="mt-2 w-1/2">
        {
          !isEditMode ? <LabelsDisplay labels={projectDetail?.labels || []} /> :
            <LabelSelect
              value={projectMetadata.labels || []}
              onChange={(e) => {
                setProjectMetadata({
                  ...projectMetadata,
                  labels: e
                })
              }}
              options={projectMetadata.labels?.map(e => {
                return {
                  label: e,
                  value: e
                }
              })}
            />
        }

      </div>
      <div className="flex justiy-between gap-[60px] margin-[auto]">
        <div className="mb-6 opacity-80 max-w-[490px] w-[490px] min-w-[490px]" data-color-mode="light">
          <div className="my-3">
            {
              isEditMode ? <input placeholder="Excerpt" className="p-2 border outline-none w-full h-12 rounded text-[12px]" value={projectMetadata.detail} onChange={(e) => {
                setProjectMetadata({
                  ...projectMetadata,
                  detail: e.target.value
                })
              }
              } /> : <div className="text-[#999ca0] mb-[20px] text-[14px]">
                {projectMetadata.detail}
              </div>
            }
          </div>
          <div className="my-3">
            {
              isEditMode ?
                <ImagePicker
                  value={projectMetadata.image}
                  onChange={(e) => {
                    setProjectMetadata({
                      ...projectMetadata,
                      image: e
                    })
                  }}
                /> : null
            }
          </div>
          {
            isEditMode ? <MarkdownEditor
              basicSetup={{
                lineNumbers: false,
              }}
              minHeight="600px"
              maxHeight="600px"
              height="100%"
              theme='light'
              maxWidth="100%"
              value={projectMetadata.overview || ''} onChange={(e) => setProjectMetadata({
                ...projectMetadata,
                overview: e
              })} /> : <MarkdownPreview
              wrapperElement={{
                "data-color-mode": "light"
              }}
              // className={styles.setupMarkdown}
              className="markdown-body text-[#999ca0] mb-[20px] text-[14px]"
              source={projectMetadata.overview || ''} />
          }

          {
            isEditMode && <div className="mt-6">
              <button className="bg-[#263dff] text-[#fff] px-3 py-2 rounded" onClick={handleSave}>Save</button>
            </div>
          }

        </div>
        <div className="max-w-[230px] min-w-[230px]">
          {/* help */}
          {/* <div className="mb-6 text-[#999ca0] text-[12px]">
            <div className="flex gap-2 items-center">
              <BiHelpCircle />
              <div>Help</div>
            </div>
            <div className="mt-3 pl-6">
              <div className="text-[12px] text-[#999ca0] mb-2">
                Contact Support
              </div>
              <div className="text-[12px] text-[#999ca0] mb-2">
                Document
              </div>
              <div className="text-[12px] text-[#999ca0] mb-2">
                Contact Us
              </div>
            </div>
          </div> */}
          {/* Refreshes */}
          {/* <div className="mb-6 text-[#999ca0]">
            <div className="flex gap-2 items-center text-[12px]">
              <BiSync />
              <div>Refreshes</div>
            </div>
            <div className="mt-3 pl-6">
              <div className="text-[12px] text-[#999ca0] mb-2">
                Daily
              </div>
            </div>
          </div> */}
          {/* 
          <div className="mb-6 text-[#999ca0]">
            <div className="flex gap-2 items-center text-[12px]">
              <BiCloud />
              <div>Cloud Region Availability</div>
            </div>
            <div className="mt-3 pl-6">
              <div className="text-[12px] text-[#999ca0] mb-2">
                AWS US West (Oregon)
              </div>
            </div>
          </div> */}

          <div className="mb-6 text-[#999ca0]">
            <div className="flex gap-2 items-center text-[12px]">
              <ImInfo />
              <div>About Us</div>
            </div>
            <div className="mt-3 pl-6">
              <div className="text-[12px] text-[#999ca0] mb-2">
                ZettaBlock is a full-stack, flexible, and scalable data platform that joins on-chain and off-chain data, accelerating Web3 application development.
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
}

export const TableProjectLayout = ({
  type,
  isOverview
}: {
  type: string
  isOverview?: boolean
}) => {

  const isWorkspaceView = useMemo(() => type === 'workspace', [type])

  const params = useParams()
  const [projectDetail, setProjectDetail] = useState<any>({
    name: '',
    overview: ''
  })
  const [tables, setTables] = useState<any[]>([])

  const [isLoading, setIsLoading] = useState(false)

  const getProjectDetail = async (id: string) => {
    setIsLoading(true)

    if (id === 'default') {
      setProjectDetail({
        name: 'Default Project',
        overview: 'This is the default project'
      })
      const res = await getAllDefaultProjectTables()
      setTables(res)
    } else {
      const res = await getProjectDetailById(id)
      const resTables = await getTablesByProjectId(id)
      setProjectDetail(res)
      setTables(resTables)
    }

    setIsLoading(false)
  }

  const publishTableProject = async () => {
    if (params.projectId) {
      await publishProject(params.projectId,)
      toast.success('Project published successfully', {
        autoClose: 2000,
        position: 'top-center',
      })
      getProjectDetail(params.projectId)
    }
  }

  const unpublishTableProject = async () => {
    if (params.projectId) {

      await unpublishProject(params.projectId,)
      toast.success('Project unpublished successfully', {
        autoClose: 2000,
        position: 'top-center',
      })
      getProjectDetail(params.projectId)
    }
  }

  const [isEditMode, setIsEditMode] = useState(false)

  const onSave = async (projectMetadata: any) => {
    if (params.projectId) {
      await updateProjectMetadata(params.projectId, projectMetadata)
      toast.success('Project updated successfully', {
        autoClose: 2000,
        position: 'top-center',
      })
      getProjectDetail(params.projectId)
      setIsEditMode(false)
    }
  }
  const onUpdateTableSummary = async (tableId: string, tableMetadata: {
    desc: string;
    example: string;
    summary: string;
  }) => {
    if (params.projectId) {
      await updateTableMetadata(tableId, tableMetadata)
      getProjectDetail(params.projectId)

      toast.success('Table updated successfully', {
        autoClose: 2000,
        position: 'top-center',
      })
      setIsEditMode(false)
    }
  }



  useEffect(() => {
    if (params.projectId) {
      getProjectDetail(params.projectId)
    }

  }, [params])

  useScrollToLocation()

  const [searchParams] = useSearchParams()

  if (isLoading) {
    return <div className="h-[300px] w-full flex justify-center items-center">
      <LoadingIndicator />
    </div>
  }

  if (!projectDetail) {
    return <Navigate to="/v2/workspace/tables" />
  }

  return <div className="h-full">
    {/* Title and Action */}
    <ProjectTopbar
      text1={projectDetail.name}
      text2={moment(projectDetail.updated_at).fromNow()}
      isWorkspaceView={isWorkspaceView}
      projectDetail={projectDetail}
      onPublish={publishTableProject}
      onUnpublish={unpublishTableProject}
      isEditMode={isEditMode}
      setIsEditMode={setIsEditMode}
      type={'projects'}
    />



    {/* Content */}
    <div className="flex h-[calc(100%-60px)]">
      <div className="border-r w-[300px] max-w-[300px] flex-1 overflow-y-auto min-w-[300px]">
        {/* list of overview */}
        <div className="p-[20px]">
          <NavLink
            className={({ isActive }) => {
              const active = isActive && (window.location.hash === '#overview' || window.location.hash === '');
              return classNames(
                'w-full block py-2 px-[10px] rounded-md break-words text-[12px]',
                active
                  ? 'bg-base-content bg-opacity-5 font-bold'
                  : 'opacity-80'
              )
            }}
            to={`/v2/${type}/tables/${params.projectId}/overview`}>
            Overview
          </NavLink>

        </div>

        <div className="divider my-0 h-[1px]"></div>

        {/* list of entities */}
        <div className="px-6 py-4 ">
          {
            tables.map((item, index) => {
              return <div key={index}>
                <NavLink
                  className={({ isActive }) => {
                    return classNames(
                      'w-full block py-2 px-[10px] rounded-md mb-1 break-words text-[12px]',
                      isActive
                        ? 'bg-base-content bg-opacity-5 font-bold'
                        : 'opacity-80'
                    )
                  }}
                  to={`/v2/${type}/tables/${params.projectId}/${item.id}?tab=Columns&from=${searchParams.get('from')}`}>
                  {item.database} - {item.name}
                </NavLink>
              </div>
            })
          }

        </div>
      </div>
      {!isOverview ? <>
        {/* Table details */}
        <TableDisplay isEditMode={isEditMode} onUpdate={onUpdateTableSummary} />
      </> : <Overview projectDetail={projectDetail} onSave={onSave} isEditMode={isEditMode} />}
    </div>
  </div >
}

const TableProject = ({
  type
}: any) => {
  return <Routes>
    <Route path="/overview" element={<TableProjectLayout type={type} isOverview />}></Route>
    <Route path="/:tableId/*" element={<TableProjectLayout type={type} />}></Route>
    <Route path="/*" element={<Navigate to="/v2/workspace/apis" />} />
  </Routes>

}

export default TableProject;
