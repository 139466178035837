import {  DictionaryApi, TableApi } from '../../../api/client';

export const getAllCategories = async () => {
  const client = DictionaryApi();

  const resp = await client.dictionaryCreate({
    type: 'label',
  });

  return Object.values(resp.data.dic || {});
};

export const getAllTables = async () => {
  const client = TableApi();

  const resp = await client.catalogCreate({
    chain: 'all',
    label: 'all',
  });

  return resp.data.catalog || [];
};
