import { useCallback } from 'react';
import useCookies from 'react-cookie/cjs/useCookies';

export type AppConfigType = {
  version?: number;
};

export function useAppConfig() {
  const [cookies, setCookie] = useCookies<
    'zAppConfig',
    { zAppConfig?: AppConfigType }
  >(['zAppConfig']);

  const setVersion = useCallback(
    (version: number) =>
      setCookie(
        'zAppConfig',
        {
          ...cookies.zAppConfig,
          version,
        },
        {
          path: '/',
        }
      ),
    [cookies]
  );

  const isV3 = true;

  return [
    { isV3, appConfig: cookies.zAppConfig },
    {
      setVersion,
    },
  ] as const;
}
