import { FaUserAlt } from "react-icons/fa"
import FilterSelect from "./FilterSelect"
import { useMemo } from "react"
import { ModelListQueryResponse } from "../../../api/__gen__/data-contracts"
import { UserAuth } from "../../auth/AuthState"

interface ListFiltersProps {
  onKeywordChange: (keyword: string) => void
  onApiTypeChange?: (type: string) => void
  onCreatorChange: (createdBy: string) => void
  creator?: string
  apiType?: string
  workspaceList: ModelListQueryResponse
  communityList: ModelListQueryResponse
  isMyWorkspace: boolean
  auth: UserAuth | null | undefined
}

const ListFilters = ({
  onKeywordChange,
  onCreatorChange,
  onApiTypeChange,
  creator,
  workspaceList,
  communityList,
  auth,
  isMyWorkspace,
  apiType
}: ListFiltersProps) => {

  const creatrorOptions = useMemo(() => {
    const options = [{
      value: 'all',
      label: 'All'
    },];
    const authorsMap = new Map();
    if (isMyWorkspace) {
      workspaceList?.items?.forEach(query => {
        if (query.creator?.id) {
          authorsMap.set(query.creator?.id, query.creator?.name);
        }
      })
      options.push({
        value: auth?.id || '',
        label: '@Me'
      })
    } else {
      communityList?.items?.forEach(query => {
        if (query.creator?.id) {
          authorsMap.set(query.creator?.id, query.creator?.name || query.creator?.id);
        }
      })
    }

    authorsMap.forEach((value, key) => {
      options.push({
        value: key,
        label: value
      })
    })

    options.sort((a, b) => {
      if (a.label === 'All') {
        return -1;
      }
      if (b.label === 'All') {
        return 1;
      }
      if (a.label === '@Me') {
        return -1;
      }
      if (b.label === '@Me') {
        return 1;
      }
      return a.label.localeCompare(b.label);
    })

    return options;

  }, [workspaceList, communityList, isMyWorkspace, auth])



  return <div className='w-full flex justify-end mb-3'>

    {/* Filter by Keyword */}
    {
      onKeywordChange && <input type="text" placeholder="Search By Keyword" className="input input-bordered w-full max-w-xs"
        onChange={(e) => {
          onKeywordChange(e.target.value)
        }}
      />
    }

    {/* Filter by API type */}
    {
      onApiTypeChange && <FilterSelect
        onChange={(e) => {
          onApiTypeChange(e.target.value);
        }}
        placeholder='API Type'
        options={[
          {
            value: '',
            label: 'All'
          }, {
            value: 'realtime',
            label: 'Realtime'
          },
          {
            value: 'non-realtime',
            label: 'Normal'
          },

        ]}
        value={apiType || ''}
      />
    }

    {/* Filter by Creator */}
    {
      onCreatorChange && <FilterSelect
        onChange={(e) => {
          onCreatorChange(e.target.value);
        }}
        icon={<FaUserAlt size={12} color="#878e95" />}
        placeholder='Created By'
        options={creatrorOptions}
        value={creator || ''}
      />
    }
  </div>
}

export default ListFilters