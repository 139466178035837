import { AxiosError } from 'axios';
import { TextToSQL } from '../../api/client';

export async function fetchTranslatedSql({
  text,
  database,
  table,
  queryId,
}: {
  text: string;
  database?: string;
  table?: string;
  queryId?: string | null;
}) {
  if (!database || !table) {
    return null;
  }

  try {
    const client = TextToSQL();

    const resp = await client.texttosqlList({
      question: text,
      db: database,
      table: table || '',
      queryId: queryId || '',
    });

    return resp;
  } catch (error) {
    // eslint-disable-next-line
    console.error('running query error', error);

    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}
