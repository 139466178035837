import produce from 'immer';
import { useState } from 'react';
import { BiRocket } from 'react-icons/bi';
import LoadingIndicator from '../../components/LoadingIndicator';

import Modal from '../../components/modals/Modal';
import {
  useSaveDashboard,
  useDashboardFormData,
} from './DashboardViewPageState';
import { analytics } from '../../App';

export default function DashPublishModal({
  openPublish,
  setOpenPublish,
}: {
  openPublish?: boolean;
  setOpenPublish: (v: boolean) => void;
}) {
  // NOTE: outdated architecture, please refer [API api] folder structure
  const [saving, { saveDashboard }] = useSaveDashboard();

  const [values, setValues] = useDashboardFormData();
  const [formValues, setFormValues] = useState(values);

  return (
    <Modal open={openPublish}>
      <div className='flex flex-col gap-2'>
        <div className='text-xl font-bold'>
          Publish to the <br />
          ZettaBlock Community
        </div>
        <div className='text-sm opacity-50'>
          Publish this dashboard to the community for the public to duplicate
          and remix. Note that the communtiy will always see the latest update.
          <br />
          <br />
        </div>
      </div>
      <div className='flex flex-col gap-3'>
        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Title</span>
          </label>
          <input
            type='text'
            className='input bg-base-300'
            placeholder='Please enter title'
            maxLength={125}
            value={formValues.displayName}
            onChange={(e) => {
              setFormValues(
                produce(formValues, (draft) => {
                  draft.displayName = e.target.value;
                })
              );
            }}
          />
        </div>
        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Description</span>
          </label>
          <textarea
            className='textarea bg-base-300'
            placeholder='Please enter description'
            value={formValues.description}
            onChange={(e) => {
              setFormValues(
                produce(formValues, (draft) => {
                  draft.description = e.target.value;
                })
              );
            }}
          />
        </div>
        <br />

        <div className='flex gap-2 items-center'>
          <button
            className='btn btn-primary gap-4'
            onClick={async () => {
              const nValues = {
                ...formValues,
                access: 'public',
              };
              const success = await saveDashboard(nValues);

              if (success) {
                analytics.track('Dashboard - Publish dashboard settings', {
                  updated: true,
                  values: formValues,
                })
                
                setValues(nValues);
                setOpenPublish(false);
              }
            }}
          >
            <BiRocket size='1.1rem' />
            Publish
          </button>
          <button
            className='btn btn-ghost'
            style={{ border: '1px solid #aaaaaa' }}
            onClick={() => {
              setOpenPublish(false);
            }}
          >
            Cancel
          </button>
          {saving && <LoadingIndicator />}
        </div>
      </div>
    </Modal>
  );
}
