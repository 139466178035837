// https://ramsdune.com/embeds/883529/1543162/e7c4c5e2-9cb7-4ea7-b064-1a873afa3cac

import { TParamState } from '../queryBuilder/types';
import { ChartOptionType, TEChartTypeType } from './components/EChart';
import { NumberChartType } from './number-type';
import { TableChartUiOptions } from './table-type';
import {
  AreaChartType,
  BarChartV2,
  LineChartType,
  ScatterChartType,
} from './types-v2';

export type ChartAxis = {
  title?: string;
  sort?: boolean;
  reverse?: boolean;
  logarithmic?: boolean;
  tickFormat?: string;
  labelFormat?: string;
};

export interface ClientChartBase {
  id: string;
  displayName?: string;
  queryParams?: TParamState;
  queryId?: string;
  type?: string;
  createdTime?: number;
  creator?: {
    id: string;
    tenant?: string;
  };
  queryrunId?: string;
  queryrunFinishTime?: number;
  resultCacheExpireMillis?: number;
}

export interface CommonChartEntity extends ClientChartBase {
  queryParamsStr?: string;
}

export type CommonChartEntityKeysType = keyof CommonChartEntity;

export const CommonChartEntityKeys: CommonChartEntityKeysType[] = [
  'id',
  'displayName',
  'queryParams',
  'queryParamsStr',
  'queryId',
  'createdTime',
  'type',
];

export interface TableChartEntity extends CommonChartEntity {
  type: 'table';
  uiOptions?: TableChartUiOptions;
}

export interface EChartEntity extends CommonChartEntity {
  type: TEChartTypeType;
  options: ChartOptionType;
  config: {
    xCol?: string;
    yCol?: string;
    yCols?: string[];
    stacking?: boolean;
    normalizeData?: boolean;
    showDataLabels?: boolean;
    xAxisLabelInterval?: number;
  };
}

export type ChartEntity =
  | EChartEntity
  | TableChartEntity
  | BarChartV2
  | NumberChartType
  | ScatterChartType
  | LineChartType
  | AreaChartType;

export function isNumberChart(chart?: ChartEntity): chart is NumberChartType {
  return chart?.type === 'number';
}

export function isTableChartV2(chart?: ChartEntity): chart is TableChartEntity {
  return chart?.type === 'table';
}

export function isEChartV2(chart?: ChartEntity): chart is BarChartV2 {
  return (chart as BarChartV2).uiOptions !== undefined;
}

export function isBarChart(chart?: ChartEntity): chart is BarChartV2 {
  return chart?.type === 'bar';
}

export function isLineChart(chart?: ChartEntity): chart is LineChartType {
  return chart?.type === 'line';
}

export function isScatterChart(chart?: ChartEntity): chart is ScatterChartType {
  return chart?.type === 'scatter';
}

export function isAreaChart(chart?: ChartEntity): chart is AreaChartType {
  return chart?.type === 'area';
}

export type QueryParamType = {
  name: string;
  type: 'text' | 'number' | 'date' | 'list';
  defaultValue: string;
};

export type QueryEntity = {
  id: string;
  title: string;
  queryString: string;
  queryParams?: QueryParamType[];
  author: {
    userId: string;
  };
  charts: ChartEntity[];
};
