/* eslint-disable func-names */
// @ts-nocheck
import { useEffect } from 'react';
import envs from '../config/envs';

export function useHotJar() {
  useEffect(() => {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          // eslint-disable-next-line
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: envs.hotjarId, hjsv: 6 };
      // eslint-disable-next-line
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }, []);
}
