import { produce } from 'immer';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { atomFamily, useRecoilState } from 'recoil';
import { setDeep } from '../../../utils/object';
import { NumberChartConfig } from '../../chart/number-type';
import { isNumberChart } from '../../chart/types';
import { SelectChartByIdState, updateChart } from '../ChartsState';

export const NumberChartOptionsState = atomFamily<NumberChartConfig, string>({
  key: 'NumberChartOptionsState',
  default: {
    displayName: 'Number chart',
    uiOptions: {},
  },
});

export function useNumberChartOptions(chartId: string) {
  const [chart, setChart] = useRecoilState(
    SelectChartByIdState({ id: chartId })
  );
  const [chartOptions, setChartOptionsState] = useRecoilState(
    NumberChartOptionsState(chartId)
  );

  const [updating, setUpdating] = useState(false);

  const debouncedEventHandler = useMemo(
    () =>
      debounce((nChartOptions: NumberChartConfig) => {
        // trigger rerender chart
        const nChart = {
          ...chart,
          ...nChartOptions,
          type: 'number',
          id: chart?.id || '',
        } as const;

        setChart(nChart);

        // update chart api
        updateChart({ chart: nChart });

        setUpdating(false);
      }, 600),
    [chart, setChart]
  );

  const setChartOptions = useCallback(
    (nChartOptions: NumberChartConfig) => {
      setUpdating(true);

      // update options
      setChartOptionsState(nChartOptions);
      // trigger rerender by updating chart
      debouncedEventHandler(nChartOptions);
    },
    [debouncedEventHandler]
  );

  const setChartOptionsByMap = useCallback(
    // eslint-disable-next-line
    (valueMap: Record<string, any>) => {
      setChartOptions(
        produce(chartOptions, (draft) => {
          setDeep(draft, valueMap);
        })
      );
    },
    [chartOptions, setChartOptions]
  );

  const [init, setInit] = useState(false);
  // initial setup and update chart
  useEffect(() => {
    if (!init && isNumberChart(chart) && chart.uiOptions) {
      setInit(true);
      setChartOptions({
        displayName: chart.displayName,
        uiOptions: chart.uiOptions,
      });
    }
  }, [chart, init]);

  return [
    { chartOptions, updating },
    { setChartOptions, setChartOptionsByMap },
  ] as const;
}
