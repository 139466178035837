/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ExploreItem, ExploreItemType } from "./components/ExploreItem"
import { useExploreData } from "../useExploreData"
import LoadingIndicator from "../../components/LoadingIndicator"
import { ExploreTopBar } from "./ExploreApis"
import { GRAPHQL_APIS } from "../Explore"



const ExploreGqlApis = () => {
  const {
    publicGraphQlApis,
    isLoading
  } = useExploreData()

  if (isLoading) return <div className="h-[300px] w-full flex justify-center items-center">
    <LoadingIndicator />
  </div>

  return <div className="min-w-[1240px]">
    <ExploreTopBar
      text1="Explore"
      text2={
        `Browse GraphQL APIs (${publicGraphQlApis.length || 0})`
      }
    />

    {/* List of Items */}
    <div id="section-content" className=" flex justify-between flex-wrap  min-w-[1080px] w-[1080px] 2xl:min-w-[1600px] 2xl:w-[1600px] 3xl:mx-auto mx-[80px] 2xl:mx-[auto] mt-[50px]">
      {
        publicGraphQlApis.map((item, index) => {
          return <ExploreItem type={ExploreItemType.GRAPHQL_API} key={index}
            title={item?.displayName}
            author={item?.creator?.name}
            imgUrl={item?.image}
            description={item?.description}
            item={item}
            from="explore"
          />
        })
      }
    </div>
  </div>
}

export default ExploreGqlApis;