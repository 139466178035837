import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { atom, atomFamily, useRecoilState } from 'recoil';
import {
  ModelListDatabaseResponse,
  ModelListSchemaResponse,
  ModelListTableResponse,
} from '../../api/__gen__/data-contracts';
import { useRpcStatusV3 } from '../../hooks/network';
import { getCategories, getDatabases, getTables } from './schema-api';
import { TSchemaState } from './SchemaState';

export const CategoriesState = atom<ModelListDatabaseResponse | null>({
  key: 'CategoriesState',
  default: null,
});

export const DatabasesState = atomFamily<
  ModelListSchemaResponse | null,
  string | undefined | null
>({
  key: 'DatabasesState',
  default: null,
});

export const TablesState = atomFamily<
  ModelListTableResponse | null,
  string | undefined | null
>({
  key: 'TablesState',
  default: null,
});

export const useCategories = () => {
  const [rpcStatus, setRpcStatus] = useRpcStatusV3();
  const [categories, setCategories] = useRecoilState(CategoriesState);

  async function fetchCategories() {
    if (rpcStatus?.isLoading) {
      return;
    }

    setRpcStatus({
      isLoading: true,
    });

    const resp = await getCategories();

    // @ts-ignore
    const error = resp?.data.message || resp?.message;

    if (error) {
      // error
      setRpcStatus({
        isLoading: false,
        error,
      });

      toast.error(error);
    } else {
      setRpcStatus({
        isLoading: false,
      });

      // success
      const data = resp?.data;
      setCategories(data);
    }
    setRpcStatus({
      isLoading: false,
      error,
    });
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  return [
    { categories, rpcStatus },
    { setRpcStatus, fetchCategories },
  ] as const;
};

export const useDatabases = (selectedDB?: string) => {
  const [rpcStatus, setRpcStatus] = useRpcStatusV3();
  const [databases, setDatabases] = useRecoilState(DatabasesState(selectedDB));

  async function fetchDatabases(db?: string) {
    // if (rpcStatus?.isLoading) {
    //   return;
    // }

    setRpcStatus({
      isLoading: true,
    });

    const resp = await getDatabases(db);

    // @ts-ignore
    const error = resp?.data.message || resp?.message;

    if (error) {
      // error
      setRpcStatus({
        isLoading: false,
        error,
      });

      // TODO don't toast but it can be
      // toast.error(error);
    } else {
      setRpcStatus({
        isLoading: false,
      });

      // success
      const data = resp?.data;
      setDatabases(data);
    }
    setRpcStatus({
      isLoading: false,
      error,
    });
  }

  useEffect(() => {
    if (selectedDB) {
      fetchDatabases(selectedDB);
    }
  }, [selectedDB]);

  return [
    { databases, rpcStatus },
    { setRpcStatus, fetchDatabases },
  ] as const;
};

export const useTables = (
  schemaState?: TSchemaState,
  { autoRefetch }: { autoRefetch?: boolean } = {}
) => {
  const [rpcStatus, setRpcStatus] = useRpcStatusV3();
  const [tables, setTables] = useRecoilState(
    TablesState(`${schemaState?.database}==${schemaState?.schema}`)
  );

  async function fetchTables() {
    if (
      rpcStatus?.isLoading ||
      !schemaState?.database ||
      !schemaState?.schema
    ) {
      return;
    }

    setRpcStatus({
      isLoading: true,
    });

    const resp = await getTables(schemaState);

    // @ts-ignore
    const error = resp?.data.message || resp?.message;
    const data = resp?.data;

    if (error) {
      // error
      setRpcStatus({
        isLoading: false,
        error,
      });

      toast.error(error);
    } else {
      setRpcStatus({
        isLoading: false,
      });

      // success
      setTables(data);
    }
    setRpcStatus({
      isLoading: false,
      error,
    });
  }

  useEffect(() => {
    if (autoRefetch && schemaState?.database && schemaState?.schema) {
      fetchTables();
    }
  }, [schemaState]);

  return [
    { tables, rpcStatus },
    { setRpcStatus, fetchTables },
  ] as const;
};
