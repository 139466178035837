/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames";
// import { BsPersonFill } from "react-icons/bs";
import ProfileAvatar from "../modules/auth/ProfileAvatar";
import { Link, NavLink, } from 'react-router-dom';
import './layout-v2.scss';
import { useContext } from "react";
import { GlobalSettingsContext } from "../v2/context/global-settings";
import useAuth from "../hooks/auth";
import { Dropdown } from "antd";
import { AuthApi } from "../api/client";
import UsageDisplay from "./UsageDisplay";

import logoutIcon from './logout.svg'
import billingIcon from './billing.svg';
import billingIcon1 from './billing1.svg';
import docIcon from './doc.svg';
import docIcon1 from './doc1.svg';
import apikeyIcon1 from './apikey1.svg';
import apikeyIcon from './apikey.svg';
import Icon from "../v2/components/Icon";

import datahubIcon from './datahub.svg';
import devstudioIcon from './devstudio.svg'
import communityIcon from './community.svg';
import limitIcon from "./limit.svg";

import qsIcon from './qs.svg';
import supportIcon from './support.svg';
import { track } from "../v2/tracking";
import Logo from "../components/app/Logo";

export const NavbarItem = ({
  children,
  to,
  icon,
  // iconSelect,
  isMini,
  href,
  id
}: any) => {
  const { toggleMiniSidebar } = useContext(GlobalSettingsContext);

  if (href) {
    return <li onClick={() => {
      if (isMini) {
        toggleMiniSidebar?.()
      }
    }}>
      <a
        onClick={() => {
          track('Sidebar - Click', {
            type: 'external link',
            to: href || to
          })
        }}
        href={href || ''}
        target="_blank"
        rel="noreferrer"
        className={classNames(
          'rounded mb-[5px] text-[12px] flex gap-[8px]',
          {
            'py-[10px] px-[6px]': !isMini,
            'px-[10px] py-[8px]': isMini,
            'font-semibold': !!icon
          }
        )}>
        {
          icon ? <img className="w-[16px] h-[16px]" src={icon} /> : <div className="min-w-[16px] h-[1px]"></div>
        }
        {
          children
        }

      </a>
    </li >
  }

  if (!to) {
    return <li onClick={() => {
      if (isMini) {
        toggleMiniSidebar?.()
      }
    }}>
      <div
        onClick={(e) => {
          if (!to || to === '') {
            e.preventDefault();
          }
          track('Sidebar - Click', {
            type: 'div',
            to: href || to
          })
        }}
        className={classNames(
          'rounded mb-[5px] text-[12px] flex gap-[8px]',
          {
            'py-[10px] px-[6px]': !isMini,
            'px-[10px] py-[8px]': isMini,
            'font-semibold': !!icon
          }
        )}>

        {
          icon ? <img src={icon} /> : <div className="min-w-[16px] h-[1px]"></div>
        }
        {
          children
        }

      </div>
    </li >
  }

  return <li onClick={() => {
    if (isMini) {
      toggleMiniSidebar?.()
    }
  }}>
    <NavLink
      id={id}
      onClick={(e) => {
        if (!to || to === '') {
          e.preventDefault();
        }
        track('Sidebar - Click', {
          type: 'nav-link',
          to: href || to
        })
      }}
      to={to || ''}
      className={({ isActive }) => {
        return classNames(
          'rounded mb-[5px] text-[12px] flex gap-[8px]',
          {
            'active': isActive,
            'bg-[#dae0ff] text-[#263dff] ': isActive && !icon,
            'bg-transparent text-[#333c4d]': isActive && icon,
            'py-[10px] px-[6px]': !isMini,
            'px-[10px] py-[8px]': isMini,
            'font-semibold': !!icon
          }
        );
      }}>
      {({ isActive }) => {
        return <>
          {
            icon ? <img src={isActive ? icon : icon} /> : <div className="min-w-[16px] h-[1px]"></div>
          }
          {
            children
          }
        </>
      }}

    </NavLink>
  </li >
}

export const LeftNavBar = ({
  isMini
}: any) => {

  return <ul className={classNames("menu", {
    'w-full': !isMini,
    'px-[12px]': !isMini,
  })}>
    <NavbarItem
      id="datahub"
      to={'/v2/explore'} className="text-[12px]"
      icon={datahubIcon}
      iconSelect={datahubIcon}
      isMini={isMini}
    >
      {
        isMini ? "" : 'DataHub'
      }
    </NavbarItem>
    {
      !isMini && <>
        <NavbarItem to={'/v2/explore/projects'} className="text-[12px]"
          isMini={isMini}
        >
          {
            isMini ? "" : 'Projects'
          }
        </NavbarItem>
        <NavbarItem to={'/v2/explore/tables'} className="text-[12px]"
          isMini={isMini}
        >
          {
            isMini ? "" : 'Data Catalog'
          }
        </NavbarItem>
        <NavbarItem to={'/v2/explore/dashboards'} className="text-[12px]"
          isMini={isMini}
        >
          {
            isMini ? "" : 'Dashboards'
          }
        </NavbarItem>
        <NavbarItem to={'/v2/explore/queries'} className="text-[12px]"
          isMini={isMini}
        >
          {
            isMini ? "" : 'Queries'
          }
        </NavbarItem>
        <NavbarItem to={'/v2/freshness'} className="text-[12px]"
          isMini={isMini}
        >
          {
            isMini ? "" : 'Status'
          }
        </NavbarItem>
      </>
    }
    <NavbarItem
      id="devstudio"
      to={'/v2/workspace'} className="text-[12px]"
      icon={devstudioIcon}
      iconSelect={devstudioIcon}
      isMini={isMini}
    >
      {
        isMini ? "" : 'DevStudio'
      }
    </NavbarItem>
    {
      !isMini && <>
        <NavbarItem to={'/v2/workspace'} className="text-[12px]"
          isMini={isMini}
        >
          {
            isMini ? "" : 'Workspace'
          }
        </NavbarItem>
        <NavbarItem className="text-[12px]"
          isMini={isMini}
          to={'/v2/data-sources'}
        >
          {
            isMini ? "" : "Integrations"
          }
        </NavbarItem>
        <NavbarItem className="text-[12px]"
          isMini={isMini}
          href="https://docs.zettablock.com/docs/triggers"
        >
          {
            isMini ? "" : <div className="flex justify-between items-center w-full">
              Trigger
            </div>
          }
        </NavbarItem>
      </>
    }

    <NavbarItem className="text-[12px]"
      icon={communityIcon}
      iconSelect={communityIcon}
      isMini={isMini}
    >
      {
        isMini ? "" : <div className="flex justify-between items-center w-full">
          Community <div className="font-normal bg-[#E5E5E5] rounded-[4px] text-[8px] text-[#777A80] leading-[10px] py-[4px] px-[3px] font-semibold">COMING SOON</div>
        </div>
      }
    </NavbarItem>
  </ul>
}

export const LeftMenuBar = ({
  isMini
}: any) => {
  return <ul className={classNames("menu", {
    'w-48': !isMini,
    'px-[12px]': !isMini,
  })}>
    <NavbarItem
      isMini={isMini}
      href={'https://docs.zettablock.com/docs/zettablock-overview'}
      icon={qsIcon}
      iconSelect={qsIcon}
    >
      {
        isMini ? '' : 'Quick Start'
      }
    </NavbarItem>
    <NavbarItem
      isMini={isMini}
      href={'https://discord.gg/zettablock'}
      icon={supportIcon}
      iconSelect={supportIcon}
    >
      {
        isMini ? '' : 'Support'
      }
    </NavbarItem>
    <NavbarItem
      isMini={isMini}
      href={'https://docs.zettablock.com/'}
      icon={docIcon}
      iconSelect={docIcon1}
    >
      {
        isMini ? '' : 'Docs'
      }
    </NavbarItem>
    <NavbarItem
      isMini={isMini}
      to={'/v2/api-key'}
      icon={apikeyIcon}
      iconSelect={apikeyIcon1}
    >
      {
        isMini ? '' : 'API Keys'
      }      
    </NavbarItem>
    <NavbarItem
      // to={'/v2/freshness'}
      isMini={isMini}
      icon={limitIcon}
      iconSelect={limitIcon}
    >
      {
        isMini ? "" : <div className="flex justify-between items-center w-full">
          Status <div className="bg-[#E5E5E5] rounded-[4px] text-[8px] text-[#777A80] leading-[10px] py-[4px] px-[3px] font-semibold">COMING SOON</div>
        </div>
      }
    </NavbarItem>

    <NavbarItem
      isMini={isMini}
      to={'/v2/billing'}
      icon={billingIcon}
      iconSelect={billingIcon1}
    >
      {
        isMini ? '' : 'Billing'
      }
    </NavbarItem>
  </ul>
}


export const UserProfileMenu = ({
  isMini,
  toggleMiniSidebar
}: any) => {
  const [{ auth }, { setAuth }] = useAuth();


  return <div className="flex justify-between items-center flex-1">
    <Dropdown
      overlayClassName="logout-dropdown"
      trigger={['click']}
      menu={{
        items: [
          {
            key: '4',
            label: <button className="p-[5px] w-full text-left flex gap-2 items-center text-[12px]" onClick={async () => {
              const authApi = AuthApi();
              try {
                const resp = await authApi.logoutCreate();
                if (resp.data.success) {
                  setAuth(null);
                }
              } catch (error) {
                setAuth(null);
              }
            }}>
              <img src={logoutIcon} className="w-[14px]" /> Logout
            </button>
          },
        ]
      }}>
      <div className="flex justify-between items-center flex-1">
        <div className="flex gap-2 items-center">
          <ProfileAvatar className="w-6 bg-[#d3d3d3]" creator={{
            profileImage: auth?.profileImage,
          }} />
          {
            !isMini && <span className="font-semibold max-w-[100px]">
              {
                auth?.name || auth?.email?.split('@')[0]
              }
            </span>
          }
        </div>
      </div>
    </Dropdown >
    {
      !isMini && <div onClick={toggleMiniSidebar}>
        <Icon
          iconName="menu"
          className="w-[16px] h-[16px] cursor-pointer"
        />
      </div>
    }
  </div>
}

const MainSidebar = () => {
  const { isMiniSidebar } = useContext(GlobalSettingsContext);

  return <div className={
    classNames("bg-[#f8f7fa]  h-screen border-r flex",
      {
        'max-w-[280px] min-w-[200px]': !isMiniSidebar,
      }
    )
  }>
    {
      <div className={classNames(
        "h-full flex justify-between flex-col py-0 w-full",
      )}>
        {/* top section */}
        <div >
          <div className={
            classNames("pl-[22px] pr-[15px]  text-[12px] flex justify-center box-content", {
              'py-[8px]': isMiniSidebar,
              'py-[1px]': !isMiniSidebar,
            })
          }>
            {
              !isMiniSidebar ? <Link to="/v2/explore">
                <Logo className="w-[123px]" /></Link> : <Icon iconName="favicon" iconType="png" />
            }
            {/* <div className="flex justify-between w-full items-center cursor-pointer">
              <UserProfileMenu isMini={isMiniSidebar} toggleMiniSidebar={toggleMiniSidebar} />
            </div> */}
          </div>
          <div className="border-b">
          </div>
          <div className="flex justify-center mt-4">
            <LeftNavBar isMini={isMiniSidebar} />
          </div>

        </div>

        {/* bottom section */}
        <div className="pb-4">
          {/* <div className="flex justify-center">
            <LeftMenuBar isMini={isMiniSidebar} />
          </div> */}
          {
            !isMiniSidebar && <>
              <div className="divider my-2"></div>
              <div className="px-2">
                <UsageDisplay />
              </div>
            </>
          }
          {/* <div className="flex justify-between items-center gap-2 px-6">
            <div><ProfileAvatar /> <span>User Name</span></div> <FaRegBell size={20} />
          </div> */}
        </div>
      </div>
    }
    {/* <div className="group relative p-[3px] ease-out duration-500 cursor-pointer hover:bg-[#3e21ea]" onClick={toggleMiniSidebar}>
      <div className="group-hover:block hidden absolute top-[50%] right-[-20px] text-[#ddd]">
        {
          isMiniSidebar ? <IoIosArrowForward size={20} /> : <IoIosArrowBack size={20} />
        }
      </div>
    </div> */}
  </div >
}

export default MainSidebar;