import { FiEdit } from "react-icons/fi"

const EditButton = ({
  showText = true,
  onClick
}: {
  onClick?: () => void,
  showText?: boolean
}) => {
  return <button onClick={onClick} className="flex items-center gap-2 border rounded px-[12px] py-[9px] hover:bg-[#263dff] hover:text-[#fff] text-[12px] h-[30px]">
    {/* icon */}
    <FiEdit />
    {/* text */}
    {
      showText && <span>Edit</span>
    }
  </button>
}

export default EditButton