import classNames from 'classnames';
import { HTMLAttributes, memo } from 'react';

export const H1 = memo(
  ({ className, children, ...props }: HTMLAttributes<HTMLHeadElement>) => {
    return (
      <h1 className={classNames('text-3xl font-bold', className)} {...props}>
        {children}
      </h1>
    );
  }
);

export const H2 = memo(
  ({ className, children, ...props }: HTMLAttributes<HTMLHeadElement>) => {
    return (
      <h2 className={classNames('text-2xl font-bold', className)} {...props}>
        {children}
      </h2>
    );
  }
);

export const H3 = memo(
  ({ className, children, ...props }: HTMLAttributes<HTMLHeadElement>) => {
    return (
      <h3 className={classNames('text-xl font-bold', className)} {...props}>
        {children}
      </h3>
    );
  }
);

export const H6 = memo(
  ({ className, children, ...props }: HTMLAttributes<HTMLHeadElement>) => {
    return (
      <h6 className={classNames('font-bold', className)} {...props}>
        {children}
      </h6>
    );
  }
);
