import { HorizontalLine } from '../../../components/HorizontalLine';
import BodyHeader3 from '../../../components/typographic/BodyHeader3';

export default function UserDashboard() {
  return (
    <>
      <div className='flex px-6 py-3 items-center'>
        <BodyHeader3 className='flex-1'>27 Dashboards</BodyHeader3>
        <select className='select max-w-xs select-sm select-bordered opacity-70'>
          <option>All Dashboards</option>
          <option>Favorates</option>
        </select>
      </div>
      <HorizontalLine />
      <div className='p-6'>
        {
          // todo: render some dashboards
        }
      </div>
    </>
  );
}
