import { AxiosResponse } from 'axios';
import { startsWith, toNumber } from 'lodash';
import { createConverter } from '../../utils/csv';
import { Metadata, Column } from './QueryState';

// TODO move this file to src/utils

/**
 * parse columns as number based on metadata
 * https://github.com/Zettablock/qugate/blob/4087ed04451d63f4a2fdaa688d5552287a5c6b88/pkg/util/sql.go
 */
export const NumTypes = [
  'TINYINT',
  'SMALLINT',
  'INTEGER',
  'BIGINT',
  'REAL',
  'DOUBLE',
];
export const StringTypes = ['VARCHAR', 'CHAR', 'VARBINARY'];
export const AllColTypes = [
  'VARCHAR',
  'BOOLEAN',
  'SMALLINT',
  'INTEGER',
  'BIGINT',
  'REAL',
  'DOUBLE',
];

const parseMetadata = (firstLineStr: string): Metadata => {
  try {
    const metadata = JSON.parse(firstLineStr) as Metadata;
    const hash: {
      [key: string]: number;
    } = {};
    metadata.columns = metadata.columns.map((e: Column) => {
      const { name } = e;
      if (hash[name]) {
        return {
          ...e,
          name: `${name}_${hash[name]++}`,
        };
      }
      hash[name] = 1;
      return e;
    });

    return metadata;
  } catch (error) {
    // eslint-disable-next-line
    console.error('error parse metadata', error);
  }

  return { columns: [] };
};

const parseString = (str: string) => {
  try {
    const parsedError = JSON.parse(str);

    return parsedError;
  } catch (error) {
    // eslint-disable-next-line
    console.error('ignore parsing error', error);
  }

  return null;
};

export async function queryResultFormat(
  queryId: string,
  // eslint-disable-next-line
  resultResp?: AxiosResponse<string, any>
) {
  const pollingDoneAt = new Date();
  const firstLineStr = resultResp?.data.split('\n', 1)?.[0] || '{}';

  // detect error
  if (startsWith(firstLineStr, '[,ERROR,]')) {
    const parsedError = parseString(firstLineStr.replace('[,ERROR,]', ''));

    return {
      queryId,
      error: parsedError.message || resultResp?.data,
      results: {
        resultReceivedAt: pollingDoneAt,
        metadata: { columns: [] },
        rows: [],
      },
    };
  }

  // paywall
  if (resultResp?.status === 402) {
    return {
      queryId,
      error: `You have reached your current plan limit, and will need to upgrade in order to continue using our service.\n\nIf you have any questions or concerns about upgrading, please don't hesitate to reach out to our customer support team. Thank you for choosing our service.`,
      results: {
        resultReceivedAt: pollingDoneAt,
        metadata: { columns: [] },
        rows: [],
      },
    };
  }

  const metadata = parseMetadata(firstLineStr);

  const columnTypeMap = metadata.columns.reduce((p, col) => {
    p[col.name] = col.type;
    return p;
  }, {} as Record<string, string>);
  const rowsInOneString = resultResp?.data.replace(/[^\n]+\n/, '') || '';
  const hash: {
    [key: string]: number;
  } = {};
  const csvHeaderString = `${rowsInOneString
    .substr(0, rowsInOneString.indexOf('\n'))
    .split(',')
    .map((e: string) => {
      if (hash[e]) {
        return `${e}_${hash[e]++}`;
      }
      hash[e] = 1;
      return e;
    })
    .join(',')}\n`;

  const csvString = rowsInOneString.replace(/[^\n]+\n/, csvHeaderString);

  const rawRows = await createConverter().fromString(
    rowsInOneString.replace(/[^\n]+\n/, csvHeaderString)
  );

  const rows = rawRows.map((row: Record<string, string>) => {
    const nRow: Record<string, string | number> = {};
    Object.keys(row).forEach((column: string) => {
      const columnType = columnTypeMap[column];
      const value = row[column];

      if (columnType && NumTypes.includes(columnType)) {
        if (!value) {
          nRow[column] = value;
        } else {
          nRow[column] = toNumber(value);
        }
      } else {
        nRow[column] = value;
      }
    });

    return nRow;
  });

  return {
    queryId,
    results: {
      resultReceivedAt: pollingDoneAt,
      metadata,
      rows,
      csvString,
    },
  };
}
