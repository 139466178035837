import Icon from './Icon';
import './OverlapImages.scss'

const OverlapImages = ({ images, className }: {
  images: string[];
  className?: string
}) => {

  return <ul className="overlap-avatars">
    {
      images.slice(0, 5).map((imgUrl, index: number) => {
        return <li key={index}><Icon className={className} iconName={imgUrl || `https://ui-avatars.com/api/?name=${index}`} /></li>
      })
    }
    {images.length > 5 && <li
      title='More Chains'
      className="w-[20px] h-[20px] flex justify-center items-center bg-[#F1F1F1] rounded-full border-[#fff] text-[10px] text-[#767676] font-semibold">
      +{images.length - 5}
    </li>}
  </ul>
}


export default OverlapImages