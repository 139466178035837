/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelGPTRequest,
  ModelGPTResponse,
  ModelGraphqlInfoRequest,
  ModelSQLIssueRequest,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Gpt<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description Get a response from GPT
   *
   * @tags example
   * @name PostGpt
   * @summary GPT API
   * @request POST:/gpt
   */
  postGpt = (request: ModelGPTRequest, params: RequestParams = {}) =>
    this.request<ModelGPTResponse, any>({
      path: `/gpt`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Get a response from GPT
   *
   * @tags example
   * @name AutoDebugSqlCreate
   * @summary Find issue in SQL query
   * @request POST:/gpt/autoDebugSql
   */
  autoDebugSqlCreate = (
    request: ModelSQLIssueRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelGPTResponse, any>({
      path: `/gpt/autoDebugSql`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description Get a response from GPT
   *
   * @tags example
   * @name GraphqlGeneratorCreate
   * @summary Generate graphql query from natural language
   * @request POST:/gpt/graphqlGenerator/{id}
   */
  graphqlGeneratorCreate = (
    id: string,
    request: ModelGraphqlInfoRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelGPTResponse, any>({
      path: `/gpt/graphqlGenerator/${id}`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
