import produce from 'immer';

import { TableChartEntity } from '../../chart/types';
import { useRecoilValue } from 'recoil';
import { SQueryResults } from '../QueryState';
import { useParams } from 'react-router';
import { set } from 'lodash';

import LoadingIndicator from '../../../components/LoadingIndicator';
import { H6 } from '../../../components/typographic/Headers';
import {
  OptionCard,
  SelectInputOption,
  TextInputOption,
} from './ChartComposeComponents';
import { useTableChartOptions } from './TableChartComposeHooks';
import ErrorBoundary from '../../../components/error/ErrorBoundary';
import { CommonChartComposeProps } from '../../chart/types-v2';
import TableChartV3 from '../../chart/components/TableChartV3';
import { HiOutlineDocumentDownload } from 'react-icons/hi';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';
import React from 'react';
import { analytics } from '../../../App';
import ChartDisplayName from '../../chart/components/ChartDisplayName';
import ChartComposeHeader from './ChartComposeHeader';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DownloadCsv = React.memo(({ rows }: { rows: any[] }) => {
  return (
    <CSVLink
      className='flex items-center gap-1'
      data={rows}
      filename={`query-result-${format(new Date(), 'Pp')}.csv`}
      onClick={() => {
        analytics.track('download csv button clicked');
      }}
    >
      <HiOutlineDocumentDownload size='0.8rem' /> Download
    </CSVLink>
  );
});

export default function TableChartComposeV3({
  chart,
  readOnly,
  runQuery,
  openAddToDashboardModal,
}: {
  chart: TableChartEntity;
  runQuery?: () => void;
  openAddToDashboardModal?: () => void;
} & CommonChartComposeProps) {
  const params = useParams();
  const queryResults = useRecoilValue(SQueryResults(params?.id));

  const [{ chartOptions, updating }, { setChartOptions }] =
    useTableChartOptions(chart.id, readOnly);

  const results = queryResults?.results;
  const rows = results?.rows;
  const columns = results?.metadata?.columns || [];

  if (!rows || !chart) {
    return (
      <div className='p-4 flex flex-col items-center justify-center'>
        <div>Please run query to see the chart.</div>
        <br />
        {runQuery && (
          <button
            className='btn btn-sm btn-primary'
            onClick={() => {
              runQuery();
            }}
          >
            RUN
          </button>
        )}
      </div>
    );
  }

  const yCols = chartOptions?.uiOptions?.dataOptions?.yCols || [];
  const databaseId = queryResults?.results?.metadata.databaseId;
  return (
    <ErrorBoundary>
      <div className='flex flex-col bg-gray-50'>
        {openAddToDashboardModal ? (
          <ChartComposeHeader
            chart={chart}
            openAddToDashboardModal={openAddToDashboardModal}
          />
        ) : (
          <div className='flex items-center justify-between gap-3 bg-gray-50'>
            <div className='w-full overflow-hidden flex-1 flex items-center p-3 gap-3'>
              <div className='max-w-[50%]'>
                <ChartDisplayName className='overflow-hidden overflow-ellipsis whitespace-nowrap'>
                  Query Results
                </ChartDisplayName>
              </div>
              {databaseId && (
                <div className='flex-auto'>
                  <div className='overflow-hidden overflow-ellipsis whitespace-nowrap'>
                    Run in Auto({databaseId})
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <TableChartV3 chart={chart} queryResults={results} />
        {!readOnly && (
          <>
            <div className='h-4' />
            <div className='grid grid-cols-1 gap-2'>
              <OptionCard>
                <H6 className='flex items-center'>
                  Chart options <span className='w-2' />
                  {updating && (
                    <span>
                      <LoadingIndicator />
                    </span>
                  )}
                </H6>
                <div className='h-2' />
                <TextInputOption
                  label='Name'
                  value={chartOptions.displayName}
                  onChange={(e) => {
                    setChartOptions({
                      ...chartOptions,
                      displayName: e.target.value,
                    });
                  }}
                />
              </OptionCard>
            </div>
            <div className='h-2' />
            <div className='grid grid-cols-3 gap-2'>
              {columns.map((column, colIdx) => (
                <OptionCard key={colIdx}>
                  <H6 className='flex items-center justify-between'>
                    {column.name}
                    <span className='opacity-30'>{` #${colIdx + 1}`}</span>
                  </H6>
                  <div className='h-2' />
                  <TextInputOption
                    label='Name'
                    value={yCols?.[colIdx]?.customName}
                    onChange={(e) => {
                      setChartOptions(
                        produce(chartOptions, (draft) => {
                          set(
                            draft,
                            `uiOptions.dataOptions.yCols[${colIdx}].customName`,
                            e.target.value
                          );
                        })
                      );
                    }}
                  />
                  <SelectInputOption
                    label={'Align'}
                    options={['left', 'right', 'center'].map((o) => ({
                      label: o,
                      value: o,
                    }))}
                    value={yCols?.[colIdx]?.align}
                    onChange={(e) => {
                      setChartOptions(
                        produce(chartOptions, (draft) => {
                          set(
                            draft,
                            `uiOptions.dataOptions.yCols[${colIdx}].align`,
                            e.target.value
                          );
                        })
                      );
                    }}
                  />
                </OptionCard>
              ))}
            </div>
          </>
        )}
      </div>
    </ErrorBoundary>
  );
}
