import { useEffect, useRef } from 'react';
import { VscWand } from 'react-icons/vsc';
import useGqlGenerator from '../../modules/dataset/useGqlGenerator';
import LoadingIndicator from '../LoadingIndicator';
import classNames from 'classnames';

interface InputFieldProps {
  apiId?: string;
  onSearch?: (value: string) => void;
  onChange?: (query: string) => void;
  focus?: boolean;
  onFocus?: (focus: boolean) => void;
}

const InputField = ({
  apiId,
  onSearch,
  onChange,
  focus,
  onFocus,
}: InputFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { fetchGql, content, loading } = useGqlGenerator();

  useEffect(() => {
    if (content) {
      onChange?.(content);
    }
  }, [content]);
  useEffect(() => {
    if (inputRef.current) {
      if (focus) {
        inputRef.current.focus();
      } else {
        inputRef.current.blur();
      }
    }
  }, [focus]);

  const handleSubmit: React.KeyboardEventHandler<HTMLInputElement> = async (
    event
  ) => {
    if (event.key === 'Enter' && !loading) {
      const value = inputRef.current?.value || '';
      if (apiId) {
        fetchGql(apiId, value);
      } else {
        onSearch?.(value);
      }
    }
  };

  return (
    <div
      className='flex flex-row m-2 mt-0'
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <button
        className={classNames('btn mr-2 btn-info', {
          'btn-primary': focus,
        })}
        onClick={(e) => {
          e.stopPropagation();
          onFocus?.(!focus);
        }}
      >
        <VscWand color='#fff' className='mr-2 ' />
        <span className='text-white'>Examples</span>
      </button>

      {focus && (
        <input
          ref={inputRef}
          type='text'
          className='w-full p-2 border rounded outline-0'
          placeholder={
            apiId
              ? 'Ask AI to write here'
              : 'Please input tags, separated by comma. e.g. blocks,transactions,logs'
          }
          onKeyDown={handleSubmit}
        />
      )}
      {loading && <LoadingIndicator />}
    </div>
  );
};
export default InputField;
