import { xcodeLightInit } from '@uiw/codemirror-theme-xcode';
import { tags } from '@lezer/highlight';

export const codemirrorTheme = xcodeLightInit({
  settings: {
    foreground: '#868E96',
    fontFamily:
      'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  },
  styles: [
    {
      tag: [tags.name],
      color: '#868E96',
    },
    {
      tag: [tags.keyword],
      color: '#2B2F33',
      fontWeight: 'bold',
      fontSize: '14px',
    },
    {
      tag: [tags.string, tags.meta],
      color: '#E91B1B',
    },
    {
      tag: [tags.quote],
      color: '#E3E6ED',
    },
    {
      tag: [tags.number],
      color: '#1BB8E9',
    },
  ],
});

export const theme = {
  //   contentGutter: "12px",
};
