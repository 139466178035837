/* eslint-disable @typescript-eslint/no-explicit-any */
import produce from 'immer';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router';
import { parseInt, set, values } from 'lodash';

import { SQueryResults } from '../QueryState';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { H6 } from '../../../components/typographic/Headers';
import {
  OptionCard,
  SelectInputOption,
  SliderInputOption,
  SwitchInputOption,
  TextInputOption,
} from './ChartComposeComponents';
import { NumberChartType, TransformTypes } from '../../chart/number-type';
import { useNumberChartOptions } from './NumberChartComposeHooks';
import NumberChart from '../../chart/components/NumberChart';
import { EmptyOption, strsToOptions } from '../../../utils/selector';
import { BsQuestionCircle } from 'react-icons/bs';
import classNames from 'classnames';
import { CommonChartComposeProps } from '../../chart/types-v2';
import ChartComposeHeader from './ChartComposeHeader';

export default function NumberChartCompose({
  chart,
  readOnly,
  openAddToDashboardModal,
}: {
  chart: NumberChartType;
  openAddToDashboardModal?: () => void;
} & CommonChartComposeProps) {
  const params = useParams();
  const queryResults = useRecoilValue(SQueryResults(params?.id));

  const [
    { chartOptions, updating },
    { setChartOptions, setChartOptionsByMap },
  ] = useNumberChartOptions(chart.id);

  const results = queryResults?.results;
  const rows = results?.rows;

  // data
  const columns = results?.metadata?.columns || [];

  // derived
  const columnSelectOptions = useMemo(() => {
    const colOptions = columns.map((column) => ({
      value: column.name,
      label: column.name,
    }));

    return [EmptyOption, ...colOptions];
  }, [columns]);

  if (!rows || !chart) {
    return <div>Please run query to see the chart.</div>;
  }

  return (
    <div className='flex flex-col space-y-2'>
      <ChartComposeHeader chart={chart} openAddToDashboardModal={openAddToDashboardModal} />

      <div className='border rounded-lg h-[200px]'>
        <NumberChart chart={chart} queryResults={results} />
      </div>
      {!readOnly && (
        <>
          <div className='grid grid-cols-1 gap-2'>
            <OptionCard>
              <H6 className='flex items-center'>
                Chart options <span className='w-2' />
                {updating && (
                  <span>
                    <LoadingIndicator />
                  </span>
                )}
              </H6>
              <div className='h-2' />
              <TextInputOption
                label='Name'
                value={chartOptions.displayName}
                onChange={(e) => {
                  setChartOptions({
                    ...chartOptions,
                    displayName: e.target.value,
                  });
                }}
              />
            </OptionCard>
          </div>
          <div className='grid grid-cols-2 gap-2'>
            <OptionCard>
              <H6 className='flex items-center'>Data options</H6>
              <div className='h-2' />
              <SelectInputOption
                label='Data column'
                options={columnSelectOptions}
                value={chartOptions?.uiOptions?.dataOptions?.xCol?.colName}
                onChange={(e) => {
                  const newColName = e.target.value;

                  setChartOptionsByMap({
                    'uiOptions.dataOptions.xCol.colName': newColName,
                  });
                }}
              />

              <TextInputOption
                label={
                  <div
                    className='flex items-center'
                    onClick={() => {
                      window.open('http://numeraljs.com/#format');
                    }}
                  >
                    Data format <span className='w-1' />
                    <BsQuestionCircle
                      size='0.8rem'
                      className='cursor-pointer'
                    />
                  </div>
                }
                placeholder='0.0a %'
                value={chartOptions.uiOptions.dataOptions?.formatStr}
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.dataOptions.formatStr': e.target.value,
                  });
                }}
              />

              <SelectInputOption
                label='Transform'
                options={strsToOptions(values(TransformTypes))}
                value={chartOptions?.uiOptions?.dataOptions?.transformType}
                onChange={(e) => {
                  const tsType = e.target.value;

                  setChartOptionsByMap({
                    'uiOptions.dataOptions.transformType': tsType,
                  });
                }}
              />

              <SwitchInputOption
                label='Show percentage caption'
                className={classNames({
                  hidden: !(
                    chartOptions?.uiOptions?.dataOptions?.transformType ===
                    'first - last' ||
                    chartOptions?.uiOptions?.dataOptions?.transformType ===
                    'last - first'
                  ),
                })}
                checked={
                  chartOptions?.uiOptions?.dataOptions?.showPercent || false
                }
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.dataOptions.showPercent': e.target.checked,
                  });
                }}
              />
            </OptionCard>
            <OptionCard>
              <H6 className='flex items-center'>Value style</H6>
              <div className='h-2' />
              <SliderInputOption
                label='Font size'
                min={5}
                max={200}
                defaultValue={
                  chartOptions?.uiOptions?.labelOptions?.style?.fontSize || 50
                }
                onChange={(e) => {
                  setChartOptions(
                    produce(chartOptions, (draft) => {
                      set(
                        draft,
                        'uiOptions.labelOptions.style.fontSize',
                        parseInt(e.target.value)
                      );
                    })
                  );
                }}
              />
              <TextInputOption
                label={'Font size (px)'}
                placeholder='Font size'
                value={chartOptions?.uiOptions?.labelOptions?.style?.fontSize || 50}
                onChange={(e) => {
                  let newFontSize = parseInt(e.target.value)
                  if (newFontSize < 5) {
                    newFontSize = 5
                  }
                  if (newFontSize > 200) {
                    newFontSize = 200
                  }
                  setChartOptions(
                    produce(chartOptions, (draft) => {
                      set(
                        draft,
                        'uiOptions.labelOptions.style.fontSize',
                        newFontSize
                      );
                    })
                  );
                }}
              />
              <TextInputOption
                label={'Prefix'}
                placeholder='-'
                value={chartOptions.uiOptions.labelOptions?.prefix}
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.labelOptions.prefix': e.target.value,
                  });
                }}
              />
              <TextInputOption
                label={'Postfix'}
                placeholder='%'
                value={chartOptions.uiOptions.labelOptions?.postfix}
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.labelOptions.postfix': e.target.value,
                  });
                }}
              />
              <SelectInputOption
                label='Align'
                options={strsToOptions(['center', 'left', 'right'])}
                value={chartOptions?.uiOptions?.labelOptions?.align}
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.labelOptions.align': e.target.value,
                  });
                }}
              />
              <SliderInputOption
                label='Side margins'
                min={0}
                max={300}
                defaultValue={
                  chartOptions?.uiOptions?.labelOptions?.style?.padding || 0
                }
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.labelOptions.style.padding': parseInt(
                      e.target.value
                    ),
                  });
                }}
              />
            </OptionCard>
            <OptionCard>
              <H6 className='flex items-center'>Label above</H6>
              <div className='h-2' />
              <TextInputOption
                label={'Label'}
                placeholder='label above ...'
                value={chartOptions.uiOptions.labelOptions?.labelAbove?.label}
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.labelOptions.labelAbove.label': e.target.value,
                  });
                }}
              />
              <SliderInputOption
                label='Font size'
                min={5}
                max={200}
                defaultValue={
                  chartOptions?.uiOptions?.labelOptions?.labelAbove?.style
                    ?.fontSize || 50
                }
                onChange={(e) => {
                  setChartOptions(
                    produce(chartOptions, (draft) => {
                      set(
                        draft,
                        'uiOptions.labelOptions.labelAbove.style.fontSize',
                        parseInt(e.target.value)
                      );
                    })
                  );
                }}
              />
            </OptionCard>
          </div>
        </>
      )}
    </div>
  );
}
