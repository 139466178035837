import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import '../../css/blinker.css';
import Logo from './Logo';
import envs from '../../config/envs';

export default function FullPageLoading({
  className,
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames(
        'flex items-center justify-center w-full h-screen',
        className
      )}
    >
      {
        envs.isCircleIntegrationEnabled ? <progress className="progress w-56"></progress>
          : <Logo className='blink-s1' />
      }
    </div>
  );
}
