/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ModelRecentRequest, ModelRecentResponse } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Recent<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description recent api,table,query
   *
   * @tags search
   * @name RecentCreate
   * @summary recent api,table,query
   * @request POST:/recent
   */
  recentCreate = (request: ModelRecentRequest, params: RequestParams = {}) =>
    this.request<ModelRecentResponse, any>({
      path: `/recent`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
