import { analytics } from '../App';

export const track = (
  eventName: string,
  params: {
    [key: string]: string | boolean | number | object | undefined | null;
  }
) => {
  analytics.track(eventName, {
    fromV2: true,
    ...params,
  });
};
