import { AxiosError } from 'axios';
import { DatabaseApi } from '../../api/client';
import { TSchemaState } from './SchemaState';

export async function getCategories() {
  try {
    const client = DatabaseApi();
    const resp = await client.databasesList();

    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function getDatabases(selectedDB?: string) {
  try {
    const client = DatabaseApi();
    const resp = await client.schemasDetail(selectedDB || '');

    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function getTables(schemaState: TSchemaState) {
  try {
    const client = DatabaseApi();
    const resp = await client.schemasTablesDetail(
      schemaState.database || '',
      schemaState.schema || ''
    );

    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}
