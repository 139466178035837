/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ModelGetContractEventsResponse, ModelLog } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Chains<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description get contract events
   *
   * @tags example
   * @name ContractsEventsDetail
   * @summary get contract events
   * @request GET:/chains/{chain}/contracts/{contract}/events
   */
  contractsEventsDetail = (
    chain: string,
    contract: string,
    params: RequestParams = {}
  ) =>
    this.request<ModelGetContractEventsResponse, any>({
      path: `/chains/${chain}/contracts/${contract}/events`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get contract event logs
   *
   * @name ContractsEventsLogsDetail
   * @summary get contract event logs
   * @request GET:/chains/{chain}/contracts/{contract}/events/{event_name}/logs
   */
  contractsEventsLogsDetail = (
    chain: string,
    contract: string,
    eventName: string,
    params: RequestParams = {}
  ) =>
    this.request<ModelLog[], any>({
      path: `/chains/${chain}/contracts/${contract}/events/${eventName}/logs`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
