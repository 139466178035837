import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { SQLIconV2 } from '../../components/icons/Icons';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import { useMyQueries, useCommunityQueries } from './QueriesHooks';
import { convertNumberToFormatted, DateFormat } from '../../utils/date';
import ProfileAvatar from '../auth/ProfileAvatar';
import LogoBlinkerCenter from '../../components/app/LogoBlinkerCenter';
import { useClientPageSize } from '../../hooks/network';
import OnVisible from './components/OnVisible';
import { isEmpty } from 'lodash';
import { queryStateDefaultValue } from '../queryBuilder/QueryBuilderHooksV3';
import StarButton from '../api/components/StarButton';
import { ACCESS_MAPPING } from '../api/const';
// import { SiPresto, SiPostgresql } from "react-icons/si";

import Postgres from '../../assets/images/icon-postgres.svg'
import Presto from '../../assets/images/icon-presto.svg'
import useAuth from '../../hooks/auth';
import ListFilters from './components/ListFilters';

export default function Queries() {
  const [{ menu, isMyWorkspace }, { getPath }] = useWorkspace();

  // my-workspace queries
  const [{ myQueries, rpcStatus: myQueriesRpcStatus }, { getMyQueries }] =
    useMyQueries();
  const [myQueriesPageSize, { showMore: myQueriesShowMore }] =
    useClientPageSize('query-list');

  // community queries
  const [
    { communityQueries, rpcStatus: communityQueriesRpcStatus },
    { getCommunityQueries },
  ] = useCommunityQueries();

  const [communityQueriesPageSize, { showMore: communityQueriesShowMore }] =
    useClientPageSize('community-query-list');

  useEffect(() => {
    if (isMyWorkspace) {
      getMyQueries();
    } else {
      getCommunityQueries();
    }
  }, [isMyWorkspace]);


  // filter by keyword and creator
  const [keyword, setKeyword] = useState('');
  const [createdBy, setCreatedBy] = useState('all');

  const filteredItems = useMemo(() => {
    return (isMyWorkspace ? myQueries : communityQueries).items
      ?.filter(e => {
        if (createdBy && createdBy !== 'all') {
          return e.creator?.id === createdBy
        }
        return e
      })
      .filter(e => {
        if (e.displayName) {
          return e.displayName?.toLowerCase().includes(keyword.toLowerCase())
        }
        return e.id?.toLowerCase().includes(keyword.toLowerCase())
      }).slice(
        0,
        isMyWorkspace ? myQueriesPageSize : communityQueriesPageSize
      )
  }, [keyword, createdBy, isMyWorkspace, myQueries, communityQueries, communityQueriesPageSize, myQueriesPageSize])

  const [{ auth }] = useAuth();

  if (
    (isMyWorkspace &&
      !myQueries?.items?.length &&
      myQueriesRpcStatus.isLoading) ||
    (!isMyWorkspace &&
      !communityQueries?.items?.length &&
      communityQueriesRpcStatus.isLoading)
  ) {
    return (
      <div className='flex w-full items-center py-20 justify-center'>
        <LogoBlinkerCenter />
      </div>
    );
  }


  return (
    <div className='explorer flex flex-col w-full p-3 overflow-x-auto'>
      <ListFilters
        workspaceList={myQueries}
        communityList={communityQueries}
        isMyWorkspace={isMyWorkspace}
        auth={auth}
        onKeywordChange={setKeyword}
        onCreatorChange={setCreatedBy}
        creator={createdBy}
      />


      <table className='table w-full'>
        <thead className='rounded-full w-full'>
          <tr className='w-full font-semibold opacity-30'>
            <th className='px-4 py-2 w-full'>NAME</th>
            <th className='px-4 py-2'>
              {isMyWorkspace ? 'CREATED BY' : 'PUBLISHED BY'}
            </th>
            <th className='px-4 py-2'>
              <div className='inline items-center text-center'>
                {isMyWorkspace ? 'CREATED AT' : 'PUBLISH DATE'}
              </div>
            </th>
            {isMyWorkspace && (
              <th className='px-4 py-2'>
                <span className='flex items-center text-center'>
                  LAST UPDATED
                </span>
              </th>
            )}
            <th className='px-4 py-2'>STARS</th>
          </tr>
        </thead>
        <tbody className='rounded-full w-full'>
          {(isMyWorkspace && isEmpty(myQueries.items)) ||
            (!isMyWorkspace && isEmpty(communityQueries.items) && (
              <td colSpan={1000} className='text-center opacity-30 p-20'>
                No queries
              </td>
            ))}
          {
            filteredItems?.map(
              ({
                id,
                // @ts-ignore
                displayName,
                creator,
                createdTime,
                updatedTime,
                publishedTime,
                starCount,
                access,
                database
              }) => (
                <tr
                  key={id}
                  className='font-semibold hover:bg-primary hover:bg-opacity-10 hover:rounded-lg items-center overflow-hidden'
                >
                  <td className='p-2 overflow-hidden'>
                    <Link key={id} to={`${getPath(menu)}/${id}`}>
                      <div className='flex flex-auto items-center text-lg space-x-2 pr-2 overflow-hidden w-[35rem]'>
                        <SQLIconV2
                          style={{ width: '1.5rem' }}
                          className='flex-none hidden'
                        />
                        {
                          database?.startsWith('realtime') ? <div className='min-w-[42px]'>
                            <img src={Postgres} width={42} />
                          </div> :
                            <div className='min-w-[42px]'><img src={Presto} width={42} /></div>
                        }

                        <span
                          title={displayName || id}
                          className='flex-grow-0 overflow-hidden whitespace-nowrap text-ellipsis'
                        >
                          {displayName || queryStateDefaultValue.displayName}
                        </span>
                        {isMyWorkspace && access && (
                          <span className='self-center bg-base-content bg-opacity-10 py-[0.15rem] px-2 text-xs rounded-md opacity-50 font-semibold top-[0.4rem]'>
                            {ACCESS_MAPPING[access]}
                          </span>
                        )}
                        {
                          database?.startsWith('realtime') ? <span className='self-center bg-base-content bg-opacity-10 py-[0.15rem] px-2 text-xs rounded-md opacity-50 font-semibold top-[0.4rem]'>
                            Realtime
                          </span> : ""
                        }
                      </div>
                    </Link>
                  </td>
                  <td className='p-2 overflow-hidden'>
                    <Link key={id} to={`${getPath(menu)}/${id}`}>
                      <div className='flex flex-auto w-[12rem] justify-start items-center gap-3'>
                        <ProfileAvatar
                          creator={creator}
                          className='flex-none w-6 bg-base-content bg-opacity-30'
                        />
                        <span
                          title={creator?.name}
                          className='flex-auto overflow-hidden whitespace-nowrap text-ellipsis'
                        >
                          {creator?.name}
                        </span>
                      </div>
                    </Link>
                  </td>
                  <td className='p-2 w-8 opacity-50 text-center'>
                    <Link key={id} to={`${getPath(menu)}/${id}`}>
                      <div>
                        {convertNumberToFormatted(
                          isMyWorkspace ? createdTime : publishedTime,
                          {
                            formatStr: DateFormat,
                          }
                        )}
                      </div>
                    </Link>
                  </td>
                  {isMyWorkspace && (
                    <td className='p-2 w-8 opacity-50 text-center'>
                      <Link key={id} to={`${getPath(menu)}/${id}`}>
                        <div>
                          {convertNumberToFormatted(updatedTime, {
                            formatStr: DateFormat,
                          })}
                        </div>
                      </Link>
                    </td>
                  )}
                  <td className='p-2 w-8 opacity-50 text-center'>
                    <StarButton
                      id={id}
                      isStarred={false} // TODO : replace after getting star value from API
                      starCount={starCount}
                      page={'query'}
                    />
                  </td>
                </tr>
              )
            )}
          <OnVisible
            onVisible={() => {
              if (isMyWorkspace) {
                myQueriesShowMore();
              } else {
                communityQueriesShowMore();
              }
            }}
          />
        </tbody>
      </table>
    </div>
  );
}
