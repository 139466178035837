import DashboardViewV2 from "../../components/DashboadViewV2";
import Navbar from "../components/Navbar";

const PublicDashboard = () => {

  return (
    <div>
      <Navbar />
      <div className="">
        <DashboardViewV2 isPublic />
      </div>
    </div>
  );
}

export default PublicDashboard;