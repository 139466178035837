import gqlIcon from './graphql.svg'


interface QueryBuilderSwitchButtonProps {
  onClick: () => void;
  // selected: boolean;
  label: string;
}

const QueryBuilderSwitchButton = ({ onClick, label }: QueryBuilderSwitchButtonProps) => {
  return (
    <button
      className='hidden text-sm font-semibold flex items-center bg-[#F1F3F5] py-1 px-3 rounded-3xl mr-3' 
      onClick={onClick}
    > {/* Note(jjin) hide for now */}
      <img src={gqlIcon} alt='GraphQL' className='mr-2' />
      {label}
    </button>
  );
};


export default QueryBuilderSwitchButton