import QueryBuilderSwitchButton from "./QueryBuilderSwitchButton"
import { createGraphiQLFetcher } from "@graphiql/toolkit";
import { useAuthHeaders } from "../auth/AuthState";
import { useEffect, useMemo, useState } from "react";
import {
  QueryEditor, GraphiQLProvider, DocExplorer, ResponseEditor, ExecuteButton, useExecutionContext, History, VariableEditor, HeaderEditor, usePluginContext, Tooltip,
  UnStyledButton,
  useTheme
} from '@graphiql/react';


import './gqlBuilder.scss'
import CopyToClipboard from "react-copy-to-clipboard";
import envs from "../../config/envs";
import { BiCopy } from "react-icons/bi";
import { toast } from "react-toastify";

const EndpointBox = () => {


  return <div className='flex min-w-[40rem] ml-2'>
    <div className='rounded-lg bg-primary-content text-sm ml-2'>
      <div className='flex gap-1 py-1 px-3 items-center mr-1'>
        <div className='text-xs font-bold opacity-30'>GraphQL URL</div >
        <CopyToClipboard
          text={`${envs.ZettaOpenApi}/dataset/${'apiId'}/graphql`}
        >
          <div
            className='flex gap-2 items-center cursor-pointer'
            onClick={() => {
              // setOpenInfoModal(true);
              toast.success('Copied to clipboard',)
            }}
          >
            <div className='flex-1'>{`${envs.ZettaOpenApi}/dataset/${'apiId'}/graphql`}</div>
            <BiCopy size='.8rem' className='opacity-40' />
          </div>
        </CopyToClipboard>


      </div>
    </div>
  </div>
}
export const Execute = () => {
  const { isFetching, run, stop } =
    useExecutionContext({
      nonNull: true,
      caller: ExecuteButton,
    });

  return <button className="btn-primary btn rounded-none	" onClick={() => {
    if (isFetching) {
      stop();
    } else {
      run();
    }
  }}>
    Execute
  </button>

}
export const Sidebar = () => {
  // plugin Context
  const pluginContext = usePluginContext();

  return <div className="plugin-button-container" >
    {pluginContext?.plugins.map(plugin => {
      const isVisible = plugin === pluginContext.visiblePlugin;
      const label = `${isVisible ? 'Hide' : 'Show'} ${plugin.title}`;
      const Icon = plugin.icon;
      return (
        <Tooltip key={plugin.title} label={label}>
          <UnStyledButton
            type="button"
            className={isVisible ? 'active' : ''}
            onClick={() => {
              if (isVisible) {
                pluginContext.setVisiblePlugin(null);
                // pluginResize.setHiddenElement('first');
              } else {
                pluginContext.setVisiblePlugin(plugin);
                // pluginResize.setHiddenElement(null);
              }
            }}
            aria-label={label}
          >
            <Icon aria-hidden="true" />
          </UnStyledButton>
        </Tooltip>
      );
    })}
  </div>
}

export const GraphQLBuilderInterface = ({ onClick }: { onClick: () => void }) => {
  const { setTheme } = useTheme();
  useEffect(() => {
    setTheme('light')
  }, [])

  const [currentSecondaryEditor, setCurrentSecondaryEditor] = useState('variables')

  const changeSecondaryEditor = (editor: string) => {
    setCurrentSecondaryEditor(editor)
  }
  const pluginContext = usePluginContext();

  return < div >

    <div className='flex bg-base-content bg-opacity-20 items-center px-2 py-2 justify-between'>
      <div className="flex items-center">
        <div className='px-2 font-bold opacity-70'>GRAPHQL BUILDER</div>
        <EndpointBox />
      </div>
      <div className='flex items-center'>

        {/* Switcher */}
        <QueryBuilderSwitchButton
          onClick={onClick}
          label='SWITCH TO QUERY BUILDER'
        />
      </div>
    </div>

    {/* <ExecuteButton /> */}
    <div className="h-[75vh]">


      <div className="graphiql-container">
        <Sidebar />
        <div className={pluginContext?.visiblePlugin?.title === 'Documentation Explorer' ? '' : 'hidden'}>
          <DocExplorer />
        </div>
        <div className={pluginContext?.visiblePlugin?.title === 'History' ? '' : 'hidden'}>
          <History />
        </div>


        <div className="w-full relative" >
          <div className="flex justify-end btns">
            {/* <button className="btn-primary btn rounded-none	" onClick={run}>
          Execute
        </button> */}
            <Execute />
          </div>
          <div className="container-gql flex justify-between w-full h-full">
            <div className="w-full h-full middle-container">
              <div style={{ height: "70%", width: '100%' }}>
                <QueryEditor />
              </div>
              <div className="flex items-center justify-evenly button-container">
                <button className={currentSecondaryEditor === 'variables' ? 'active' : ''} onClick={() => {
                  changeSecondaryEditor('variables')
                }}>Variables</button>
                <button className={currentSecondaryEditor === 'variables' ? '' : 'active'} onClick={() => {
                  changeSecondaryEditor('headers')
                }}>Headers</button>
              </div>
              <div style={{ height: '30%', width: '100%' }}>
                <div className={currentSecondaryEditor === 'variables' ? 'h-full w-full' : 'hidden'}>
                  <VariableEditor />
                </div>
                <div className={currentSecondaryEditor === 'headers' ? 'h-full w-full' : 'hidden'}>
                  <HeaderEditor />
                </div>
              </div>
            </div>
            <ResponseEditor />
          </div>
        </div>

      </div>

    </div>
  </div >
}

const GraphQLBuilder = ({ onClick }: { onClick: () => void }) => {
  const headers = useAuthHeaders();
  const fetcher = useMemo(() => {
    return createGraphiQLFetcher({
      url: `https://qugate-dev-ztbl.api.zettablock.dev/qugate/v1/userapis/sq_dd4c229744ad44fabaa2644bfe7afb15/graphql`,
      // @ts-ignore
      headers,
    });
  }, []);

  return <GraphiQLProvider fetcher={fetcher} >
    <GraphQLBuilderInterface onClick={onClick} />
  </GraphiQLProvider>
}

export default GraphQLBuilder





