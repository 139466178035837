import { FiGlobe } from 'react-icons/fi';
import { FaGithub } from 'react-icons/fa';

export default function UserInfoPanel() {
  return (
    <>
      <div className='flex items-center'>
        <div className='flex-0 pr-5 self-start'>
          <div className='avatar'>
            <div className='w-16 rounded-xl'>
              <img src='https://cdn.lorem.space/images/face/.cache/100x0/nico-marks-25gAg2MOXxQ-unsplash.jpg' />
            </div>
          </div>
        </div>
        <div className='flex-1 font-bold text-lg opacity-80'>
          Artem Kyznetsov 👋
        </div>
      </div>

      <div className='h-8' />

      <div className='font-medium opacity-80'>
        Aave is an open source and non-custodial liquidity protocol for earning
        interest on deposits and borrowing assets.
      </div>

      <div className='h-6' />

      <div>
        <div>
          <FiGlobe className='inline mr-2 opacity-40' />
          @artem_kyznetsov
        </div>
        <div>
          <FaGithub className='inline mr-2 opacity-40' />
          0xe8939...2899
        </div>
      </div>

      <div className='h-6' />

      <div className='flex flex-wrap'>
        {[
          {
            title: 'DATASETS',
            content: '4',
          },
          {
            title: 'TABLES',
            content: '102',
          },
          {
            title: 'STARS',
            content: '4',
          },
          {
            title: 'QUERIES',
            content: '102',
          },
          {
            title: 'DASHBOARDS',
            content: '27',
          },
          {
            title: 'WIZARD SINCE',
            content: 'May 2020',
          },
        ].map((stat) => (
          <div
            key={stat.title}
            className='basis-1/2 stats rounded-none pr-2 pb-5'
          >
            <div className=''>
              <div className='stat-title uppercase pb-2 opacity-40'>
                {stat.title}
              </div>
              <div className='stat-value whitespace-normal text-base font-semibold opacity-70'>
                {stat.content}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
