import js from '../assets/js.svg';
import python from '../assets/python.svg';
import php from '../assets/php.svg';
import shell from '../assets/shell.svg';
import classNames from 'classnames';

/* eslint-disable @typescript-eslint/no-explicit-any */
const LanguagePicker = ({
  onClick,
  currentLang
}: any) => {
  const languages = [
    {
      name: 'Shell',
      value: 'shell',
      img: shell
    },
    {
      name: 'JS',
      value: 'javascript',
      img: js
    },
    {
      name: 'Python',
      value: 'python',
      img: python
    },
    {
      name: 'Go',
      value: 'go',
      img: php
    },
    {
      name: 'Java',
      value: 'java'
    },
    {
      name: 'C#',
      value: 'csharp'
    },
    {
      name: 'PHP',
      value: 'php'
    },
    {
      name: 'Ruby',
      value: 'ruby'
    },
  ]

  return <div>
    <div className="flex items-center min-w-[100px] w-full gap-2">
      {
        languages.slice(0, 4).map((lang, index) => {
          return <div key={index} className={classNames("pt-2 pb-2 border rounded w-[50px] h-[45px] flex justify-center flex-col items-center cursor-pointer", {
            'border-transparent': currentLang !== lang.value,
          })} onClick={() => {
            onClick(lang.value)
          }}>
            <div className="w-[18px] h-[18px] text-center">
              <img src={lang.img} alt="" className="" />
            </div>
            <div className="text-center text-xs mt-[2px]">
              {lang.name}
            </div>
          </div>
        })
      }
    </div>
  </div>
}

export default LanguagePicker;