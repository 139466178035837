import { NavLink, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { useState, useMemo, useEffect } from 'react';
import { HiMenuAlt2 } from 'react-icons/hi';
import ProfileMenu from './components/ProfileMenu';
import Logo from '../components/app/Logo';
import { useAppConfig } from '../hooks/app-config';
import LogoIcon from '../components/app/LogoIcon';
import WorkspaceSelector from './components/WorkspaceSelector';
import CreateMenu from './components/CreateMenu';
import { useWorkspace } from './components/workspace-hooks';
import ChangelogNotification from './components/ChangelogNotification';
import UsageBar from '../components/UsageBar';
import { AuthState } from '../modules/auth/AuthState';
import { useRecoilState } from 'recoil';
import { formatNum } from '../utils/format';
import envs from '../config/envs';
import { analytics } from '../App';
import useTitle from '../hooks/useTitle';

export const RoutePaths = {
  projects: 'projects',
};

type NavItemType = {
  name: string;
  path?: string;
  badgeLabel?: string;
  extPath?: string;
};
const NAV_ITEMS: NavItemType[] = [
  { name: 'Dashboards', path: '/dashboards' },
  { name: 'Projects', path: `/${RoutePaths.projects}`, badgeLabel: 'alpha' },
  { name: 'New Query', path: '/query-builder' },
  { name: 'Connectors', path: '/connectors' },
  {
    name: 'Docs',
    extPath: 'https://docs.zettablock.com',
  },
  {
    name: 'Quick Start',
    extPath: 'https://docs.zettablock.com/docs/zettablock-quickstart',
  },
];

const NAV_ITEMS_V3: NavItemType[] = [
  { name: 'Explorer', path: '/apis' },
  // { name: 'API Playground', path: 'api-playground' },
  { name: 'Usage', path: '/api-key' },
  {
    name: 'DataDict',
    extPath: 'https://docs.zettablock.com/page/data-catalog',
  },
  {
    name: 'Docs',
    extPath: 'https://docs.zettablock.com',
  },
  {
    name: 'Quick Start',
    extPath: 'https://docs.zettablock.com/docs/zettablock-quickstart',
  },
];

export default function Navbar() {
  const [openMenu, setOpenMenu] = useState(false);
  const [{ isV3 }] = useAppConfig();
  const [{ isMyWorkspace }] = useWorkspace();
  const [auth] = useRecoilState(AuthState);

  useTitle();


  const isTrialUser = useMemo(() => {
    if (!auth) return false;
    if (!auth.subscription) return false;

    if (auth.tenant !== 'community' && auth.subscription?.isTrial) {
      return true;
    }

    return false;
  }, [auth]);

  const isFreePlanUser = useMemo(() => {
    if (!auth || !auth.subscription) return false;
    if (auth.tenant === 'community' && auth.subscription?.plan === 'free') {
      return true;
    }

    return false;
  }, [auth]);

  const isBuildPlanUser = useMemo(() => {
    if (!auth || !auth.subscription) return false;
    if (auth.tenant === 'community' && auth.subscription?.plan === 'build') {
      return true;
    }

    return false;
  }, [auth]);

  const isEnterpriseUser = useMemo(() => {
    if (!auth || !auth.subscription) return false;
    if (auth.tenant !== 'community' && !auth.subscription?.isTrial) {
      return true;
    }

    return false;
  }, [auth]);

  const usagePercentage = useMemo(() => {
    const res = {
      percentage: 0,
      details: '',
    };
    if (!auth || !auth.subscription) return res;
    if (
      auth.subscription.cuLimit === undefined ||
      auth.subscription.cuUsed === undefined
    )
      return res;

    const percentage = Math.trunc(
      (100 * auth.subscription.cuUsed) / auth.subscription.cuLimit
    );

    res.percentage = percentage > 100 ? 100 : percentage;
    res.details = `${formatNum(auth.subscription.cuUsed)}/${formatNum(
      auth.subscription.cuLimit
    )}`;

    return res;
  }, [auth]);

  const progress = useMemo(() => {
    const res = {
      percentage: 0,
      daysLeft: '',
    };
    if (!auth || !auth.subscription) return res;

    const { trialPeriod, createdTime } = auth.subscription;

    if (trialPeriod === undefined || createdTime === undefined) return res;

    const currentTime = Date.now();

    res.percentage = Math.trunc(
      (100 * (currentTime - createdTime)) / trialPeriod
    );
    const daysLeft = Math.floor(
      (createdTime + trialPeriod - currentTime) / (1000 * 24 * 3600)
    );
    res.daysLeft = `${daysLeft < 0 ? 0 : daysLeft} Day${daysLeft !== 1 ? 's' : ''
      } Left`;

    return res;
  }, [auth]);

  const reachLimit = useMemo(() => {
    if (isEnterpriseUser) return '';

    if (isTrialUser && progress.percentage >= 100) {
      return 'your trial period for this has been expired';
    }

    return usagePercentage.percentage >= 100
      ? "you've reached your CU usage limit"
      : '';
  }, [isTrialUser, isEnterpriseUser, progress, usagePercentage]);

  const [searchParams] = useSearchParams();

  const isCircleIntegration = envs.isCircleIntegrationEnabled
  useEffect(() => {
    if (isCircleIntegration) {
      window.document.title = 'Circle Integration'
    }
    if (!localStorage.getItem('isCircleIntegrationEnabled')) {
      localStorage.setItem('isCircleIntegrationEnabled', searchParams.has('circle') ? 'true' : 'false')
      if (searchParams.has('circle')) {
        window.location.href = '/'
      }
    }
  }, [])

  useEffect(() => {
    analytics.identify(auth?.id, {
      ...auth,
      ...auth && {
        isTrialUser,
        isCommunityUser: !auth || auth?.tenant === 'community',
        accessToken: '',
        defaultApiKey: '',
      }
    })
  }, [auth, isTrialUser])

  const NavItems = isV3
    ? NAV_ITEMS_V3.map((i) => ({
      ...i,
      path: `/${isMyWorkspace ? 'my-workspace' : 'community'}${i.path}`,
    })).filter((navItem) => {
      // don't show api key for community
      if (!isMyWorkspace) {
        return navItem.name !== 'Usage';
      }
      if (isCircleIntegration && navItem.name === 'Usage') { return false }
      return true;
    })
    : NAV_ITEMS;

  return (
    // Lock the width [responsive]
    // https://datalego.atlassian.net/browse/DL-353
    <div className={classNames('mx-auto navbar px-0 py-7', isCircleIntegration && 'justify-between w-full')}>
      <div className='hidden pr-2'>
        <div className='dropdown'>
          <button
            className='btn btn-ghost'
            onClick={() => {
              setOpenMenu(true);
            }}
          >
            <HiMenuAlt2 size='1.4rem' />
          </button>
          <ul
            className={classNames(
              'menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52',
              openMenu ? 'block' : 'hidden'
            )}
          >
            {NavItems.map((nav) => (
              <li key={nav.name}>
                {nav.extPath ? (
                  <a
                    href={nav.extPath}
                    {...(nav.extPath && {
                      target: '_blank',
                      rel: 'noopener noreferrer',
                    })}
                    onClick={() => {
                      analytics.track(`Navbar - Click - ${nav.name}`, {})
                    }}
                    className='text-[1.0rem] font-semibold btn-sm mr-1 text-gray-600 px-4 py-5'
                  >
                    {nav.name}
                  </a>
                ) : (
                  <NavLink
                    onClick={() => {
                      analytics.track(`Navbar - Click - ${nav.name}`, {})
                      setOpenMenu(false);
                    }}
                    to={`${nav.path}`}
                    className={({ isActive }) => {
                      return classNames(
                        'text-[1.0rem] font-semibold btn-sm mr-1 text-gray-600 px-4 py-5',
                        {
                          'bg-white text-blue-600': isActive,
                        }
                      );
                    }}
                  >
                    {nav.name}
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {
        !isCircleIntegration ?
          <div className='flex-none'>
            <button type='button' className='text-3xl font-bold text-primary'>
              {!isV3 ? (
                <NavLink to={'/dashboards'}>
                  <Logo />
                </NavLink>
              ) : (
                <NavLink to={'/my-workspace'}>
                  <LogoIcon />
                </NavLink>
              )}
            </button>
          </div> : <div className='text-[#263DFF] font-bold text-2xl'>DATA</div>
      }

      <div className={classNames('flex-none flex ', isCircleIntegration ? '' : 'px-7')}>
        {isV3 && !isCircleIntegration && <WorkspaceSelector />}
        <ul className='menu menu-horizontal p-0'>
          {NavItems.map((nav) => {
            return (
              <li key={nav.name}>
                {nav.extPath ? (
                  <a
                    href={nav.extPath}
                    {...(nav.extPath && {
                      target: '_blank',
                      rel: 'noopener noreferrer',
                    })}
                    className='text-[1.0rem] font-semibold btn-sm mr-1 text-gray-600 px-4 py-5'
                  >
                    {nav.name}
                  </a>
                ) : (
                  <NavLink
                    to={
                      nav.path || `/${nav.name.toLowerCase().replace(' ', '-')}`
                    }
                    className={({ isActive }) => {
                      return classNames(
                        'text-[1.0rem] font-semibold btn-sm mr-1 text-gray-600 px-4 py-5',
                        {
                          'bg-white text-blue-600': isActive,
                        }
                      );
                    }}
                  >
                    {nav.name}

                    {nav.badgeLabel && (
                      <span className='absolute indicator-item badge badge-sm badge-secondary top-[-0.3rem] right-[-0.3rem]'>
                        {nav.badgeLabel}
                      </span>
                    )}
                  </NavLink>
                )}
              </li>
            );
          })}
        </ul>
        {isV3 && isCircleIntegration && <CreateMenu />}
      </div>

      {!isCircleIntegration &&
        <div className='flex flex-1 space-x-3'>
          <div className='flex-1' />
          {
            // TODO: enable theme when it's ready
            // <div className='flex-none h-7 px-4'>
            //   <ThemeSwitch />
            // </div>
          }

          {isTrialUser && (
            <UsageBar
              warning={!!reachLimit}
              progress={progress.percentage}
              title={'Trial Enterprise'}
              details={progress.daysLeft}
            />
          )}

          {isFreePlanUser && (
            <UsageBar
              warning={!!reachLimit}
              progress={usagePercentage.percentage}
              title='Free Plan'
              details={usagePercentage.details}
            />
          )}

          {isEnterpriseUser && (
            <UsageBar
              warning={!!reachLimit}
              progress={usagePercentage.percentage}
              title='Enterprise'
              details={usagePercentage.details}
            />
          )}

          {isBuildPlanUser && (
            <UsageBar
              warning={false}
              progress={usagePercentage.percentage}
              title='Build'
              details={usagePercentage.details}
            />
          )}
          {!!reachLimit && (
            <>
              <input
                type='checkbox'
                id='my-modal'
                className='modal-toggle'
                defaultChecked
              />
              <div className='modal' style={{ marginLeft: 0 }}>
                <div className='modal-box'>
                  <h3 className='font-bold text-lg'>{reachLimit}</h3>
                  <p className='py-4'>
                    Hi there! It looks like {reachLimit}. If you have any issues
                    or questions, please don’t hesitate to contact our friendly
                    support team at{' '}
                    <a className='text-primary' href='mailto:team@zettablock.com'>
                      team@zettablock.com
                    </a>
                    !
                    <br />
                    <br />
                    Happy Building, <br />
                    ZettaBlock Support Team
                  </p>
                  <div className='modal-action'>
                    <label htmlFor='my-modal' className='btn'>
                      OK
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}


          {isV3 && <CreateMenu />}
          <ChangelogNotification />
          <div className='flex justify-center'>
            <ProfileMenu />
          </div>

        </div>}
    </div>
  );
}
