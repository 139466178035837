import MarkdownEditor from '@uiw/react-markdown-editor';
import MarkdownPreview from "@uiw/react-markdown-preview";


const MarkdownBlock = ({
  isEditMode,
  value,
  onChange
}: {
  isEditMode: boolean,
  value: string,
  onChange: (value: string) => void
}) => {

  if (isEditMode) {
    return <MarkdownEditor
      className='border'
      basicSetup={{
        lineNumbers: false,
      }}
      minHeight="300px"
      maxHeight="600px"
      height="100%"
      theme='light'
      maxWidth="100%"
      value={value || ''}
      onChange={onChange} />
  }

  return <MarkdownPreview
    wrapperElement={{
      "data-color-mode": "light"
    }}
    source={value || ''}
  />
}

export default MarkdownBlock;