import { useNavigate } from "react-router-dom";
import { SubscriptionApi } from "../../api/client";
import useAuth from "../../hooks/auth";
import { toast } from "react-toastify";
import { UserAuth } from "../auth/AuthState";
import { useMemo } from "react";
import envs from "../../config/envs";

const Plans = () => {
  // https://checkout.zettablock.com/b/28o4iffVh8VR1S8002
  const [{ auth }, { setAuth }] = useAuth();
  const navigate = useNavigate();
  const isFreePlan = useMemo(() => {
    return auth?.subscription?.plan === 'free' && auth?.subscription?.planChosen === true
  }, [
    auth
  ]);
  const isEnterprisePlan = useMemo(() => {
    return auth?.subscription?.plan === 'enterprise'
  }, [auth])
  const isBuilderPlan = useMemo(() => {
    return auth?.subscription?.plan === 'build'
  }, [auth])

  // test - https://checkout.zettablock.com/b/test_4gwbM50hW9IbbTy4gm 
  // prod https://checkout.zettablock.com/b/28o4iffVh8VR1S8002

  const checkoutUrl = `https://checkout.zettablock.com/b/28o4iffVh8VR1S8002?prefilled_email=${auth?.email}`;

  const onFreePlanSubscribe = async () => {
    const client = SubscriptionApi()
    await client.freePlanChosenCreate({
      planChosen: true
    })
    toast.success('Free plan subscribed', {
      autoClose: 1000,
    });
    setAuth((prev: UserAuth | null | undefined) => {
      if (prev) return {
        ...prev,
        subscription: {
          ...prev.subscription,
          planChosen: true
        }
      }
      return null
    })
    setTimeout(() => {
      navigate('/my-workspace');
    }, (1000));
  }

  return <div className="flex justify-center p-8 bg-[#fff]">
    <div className="p-12  flex-1 px-6">
      <h4 className="font-bold text-xl mb-1">
        Free
      </h4>
      <p className="text-sm text-[#a3a3a3] mb-8">
        Great for developers, traders, or anyone getting started.
      </p>
      <div className="flex mb-4">
        <span className="font-semibold text-3xl pr-1">
          $0
        </span>
        <div className="text-xs text-[#a3a3a3]">
          <p>per</p>
          <p>month</p>
        </div>
      </div>
      <div className="flex mb-4">
        {
          isFreePlan ? <button disabled className="btn btn-primary w-full">
            Subscribed
          </button> : <button className="btn btn-primary w-full" onClick={onFreePlanSubscribe}>
            Subscribe
          </button>
        }

      </div>

      <div className="">
        <p>
          It includes:
        </p>
        <ul className="text-sm list-disc list-inside text-[#1a1a1a]">
          <li>3 Million compute units</li>
          <li>1 GraphQL APIs built</li>
          <li>CSV upload 5 times</li>
          <li>CSV download 0 times</li>
          <li>Build your own real-time GraphQL API</li>
          <li>Access to 8+ chains data</li>
          <li>Access to Community data/ APIs/ queries/ dashboards</li>
          <li>Unlimited queries/ charts/ dashboards</li>
          <li>Discord support</li>
          <li>0 private API / query / Dashboard</li>
        </ul>
      </div>

    </div>
    <div className="p-12 bg-[#fff] flex-1 px-6">
      <h4 className="font-bold text-xl mb-1">
        Builder
      </h4>
      <p className="text-sm text-[#a3a3a3] mb-8">
        Ultimate scalability for professional individuals working in web3.
      </p>
      <div className="flex mb-4">
        <span className="font-semibold text-3xl pr-1">
          $49
        </span>
        <div className="text-xs text-[#a3a3a3]">
          <p>per</p>
          <p>month</p>
        </div>
      </div>
      <div className="flex mb-4">
        {
          isBuilderPlan ? <a
            className="block w-full"
            href={`${envs.StripeCustomerPortal}?prefilled_email=${auth?.email}`}
          >
            <button className="btn btn-primary w-full">
              Modify Subscription
            </button>
          </a> : <a href={checkoutUrl} className="block w-full">
            <button className="btn btn-primary w-full">
              Subscribe
            </button>
          </a>
        }

      </div>

      <div className="">
        <p>
          It includes:
        </p>
        <ul className="text-sm list-disc list-inside text-[#1a1a1a]">
          <li>10 Million compute units</li>
          <li>3 GraphQL APIs built</li>
          <li>CSV uploads 15 times</li>
          <li>CSV downloads 15 times</li>
          <li>Auto-scaling at $20 / additional million</li>
          <li>20 requests /sec for GraphQL APIs</li>
          <li>50MB data limit for Data Lake APIs</li>
          <li>Discord support</li>
          <li>0 private API / query / Dashboard</li>
        </ul>
      </div>

    </div><div className="p-12 bg-[#fff] flex-1 px-6">
      <h4 className="font-bold text-xl mb-1">
        Enterprise
      </h4>
      <p className="text-sm text-[#a3a3a3] mb-8">
        Volume pricing and enterprise support for institutional use cases.
      </p>
      <div className="flex mb-4">
        <span className="font-semibold text-3xl pr-1">
          Contact Support
        </span>
        {/* <div className="text-xs text-[#a3a3a3]">
          <p>per</p>
          <p>month</p>
        </div> */}
      </div>
      <div className="flex mb-4 flex-wrap">
        <a href="mailto:sales@zettablock.com" className="w-full">
          <button className="btn btn-primary w-full">
            {
              isEnterprisePlan ? 'Subscribed - Contact Us' : 'Contact Us'
            }
          </button>
        </a>
      </div>

      <div className="">
        <p>
          It includes:
        </p>
        <ul className="text-sm list-disc list-inside text-[#1a1a1a]">
          <li>Custom compute units</li>
          <li>Custom GraphQL APls built</li>
          <li>Custom data ETL pipelines</li>
          <li>Custom CSV Downloads</li>
          <li>Committed use discounts</li>
          <li>Custom SLAs</li>
          <li>Custom requests / sec for GraphQL API</li>
          <li>Custom data limit for Data Lake APIs</li>
          <li>Custom EL pipeline sync frequency</li>
          <li>Premium Onboarding and Support</li>
          <li>Private API / Queries</li>
        </ul>
      </div>

    </div>
  </div>
}

export default Plans