import ReactECharts from 'echarts-for-react';
import { HTMLAttributes } from 'react';
import { EChartEntity } from '../types';
import finalizeOptions from '../utils/finalizeOptions';

// https://echarts.apache.org/v4/en/option.html#series-line.type
export const TEChartTypeTypeValues = [
  'bar',
  'line',
  'scatter',
  'area',
] as const;

export type TEChartTypeType = typeof TEChartTypeTypeValues[number];

export type ItemStyleType = {
  color?: string;
};

type PositionType =
  | 'left'
  | 'right'
  | 'bottom'
  | 'inside'
  | 'insideLeft'
  | 'insideRight'
  | 'insideTop'
  | 'insideBottom'
  | 'insideTopLeft'
  | 'insideBottomLeft'
  | 'insideTopRight'
  | 'insideBottomRight'
  | [string | number, string | number];

type LabelType = {
  show?: boolean;
  position?: PositionType;
  formatter?: string | ((params: unknown) => string);
};

type Tooltip = {
  valueFormatter?: string | ((value: number | string) => string);
};

export type TSeriesType = {
  name?: string;
  stack?: string | null;
  areaStyle?: object | null;
  radius?: string;
  center?: string[];
  data: unknown[];
  type: TEChartTypeType;
  smooth?: boolean;
  itemStyle?: ItemStyleType;
  label?: LabelType;
  tooltip?: Tooltip;
};

type AxisLabelType = {
  formatter?: string | ((value: string, index: number) => string);
  interval?: number;
};

export type ChartOptionType = {
  title?: {
    text?: string;
  };
  legend: {
    data: string[];
    show?: boolean;
    bottom?: number | string;
  };
  series: TSeriesType[];
  grid?: {
    bottom?: number | string;
    top?: number | string;
    right?: number | string;
    left?: number | string;
  };
  xAxis: {
    type: string;
    data: string[];
    column?: string;
    axisLabel?: AxisLabelType;
  };
  yAxis?: {
    name?: string;
    type?: string;
    data?: string[];
    column?: string;
    axisLabel?: AxisLabelType;
  };
  tooltip: {
    trigger?: string;
  };
};

export type EChartType = {
  loading?: boolean;
  options: ChartOptionType;
};

export type EChartProps = EChartType & {
  config: EChartEntity['config'];
} & HTMLAttributes<HTMLDivElement>;

export default function EChart({
  loading,
  options,
  config,
  style,
  ...rest
}: EChartProps) {
  const finalOptions = finalizeOptions(config, options);
  return (
    <ReactECharts
      showLoading={loading}
      option={finalOptions}
      style={{ width: '100%', height: '100%', ...style }}
      {...rest}
    />
  );
}
