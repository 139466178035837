import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard';
import { BiCopy } from 'react-icons/bi';
import RTAPIIcon from '../explorer/components/RealtimeApiIcon';
import envs from '../../config/envs';

import eth from './assets/ethereum.svg';
import sui from './assets/Sui.svg';

import { GraphiQLEditor } from '../../components/graphiql-editor';

const RealtimeAPIGQL = () => {
  const { id } = useParams();

  return (
    <div>
      <div className='flex'>
        <div className='flex-auto flex items-center space-x-3 overflow-hidden'>
          <div>
            <div className='flex items-center bg-[#261be6] rounded pr-3 pl-1 py-1'>
              <RTAPIIcon fill={'#fff'} height={21} />
              <span className='text-sm font-semibold text-[#fff]'>
                REAL-TIME
              </span>{' '}
            </div>
          </div>
          <div className='flex items-center space-x-2 overflow-hidden pr-2'>
            <img src={id !== 'sui' ? eth : sui} width={21} />
            <span
              title={'values.displayName'}
              className='flex-auto font-bold text-lg overflow-hidden text-ellipsis whitespace-nowrap'
            >
              {id !== 'sui' ? 'Ethereum Mainnet' : 'Sui'}
            </span>
          </div>
        </div>
      </div>
      <div className='flex min-w-[40rem] my-6'>
        <div className='rounded-l-lg inline-block border-r bg-primary-content'>
          <span
            className='inline-block text-xs opacity-30 font-semibold px-1 pb-3 pt-1 text-center justify-center'
            style={{ writingMode: 'vertical-lr', transform: 'rotate(180deg)' }}
          >
            INFO
          </span>
        </div>
        <div className='rounded-r-lg bg-primary-content text-sm'>
          <div className='flex flex-col gap-1 py-2 px-3'>
            <div className='text-xs font-bold opacity-30'>GraphQL URL</div>
            <CopyToClipboard
              text={`${envs.ZettaBackendApiPrivate}/${id}/graphql`}
            >
              <div
                className='flex gap-2 items-center cursor-pointer'
                onClick={() => {
                  toast.success('Copied to clipboard', {
                    autoClose: 2000,
                  });
                }}
              >
                <div className='flex-1'>{`${envs.ZettaBackendApiPrivate}/${id}/graphql`}</div>
                <BiCopy size='.8rem' className='opacity-40' />
              </div>
            </CopyToClipboard>

            {/* {openInfoModal && (
              <ApiInfoModal open={openInfoModal} setOpen={setOpenInfoModal} />
            )} */}
          </div>
        </div>
      </div>

      <div className='h-[calc(100vh-200px)]'>
        {/* /my-workspace/realtime-api/:id */}
        <GraphiQLEditor
          defaultQuery='# Welcome to Realtime API\n'
          noCache
          direction='vertical'
        />
      </div>
    </div>
  );
};

export default RealtimeAPIGQL;
