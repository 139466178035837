/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelCreateTableFreshnessRequest,
  ModelCreateTableFreshnessResponse,
  ModelGetTableFreshnessResponse,
  ModelListTableFreshnessHistoryResponse,
  ModelListTableFreshnessResponse,
  ModelPruneTableFreshnessRequest,
  ModelPruneTableFreshnessResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class TableFreshness<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description create table freshness
   *
   * @tags tableFreshness
   * @name TableFreshnessCreate
   * @summary create table freshness objects, only available for zettablock user
   * @request POST:/tableFreshness
   */
  tableFreshnessCreate = (
    request: ModelCreateTableFreshnessRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelCreateTableFreshnessResponse, any>({
      path: `/tableFreshness`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get table freshness
   *
   * @tags tableFreshness
   * @name HistoryList
   * @summary get table freshness by database and schema
   * @request GET:/tableFreshness/history
   */
  historyList = (
    query: { source: string; limit?: string },
    params: RequestParams = {}
  ) =>
    this.request<ModelListTableFreshnessHistoryResponse, any>({
      path: `/tableFreshness/history`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description prune table freshness
   *
   * @tags tableFreshness
   * @name PruneDelete
   * @summary prune stale table freshness records, only available for zettablock user
   * @request DELETE:/tableFreshness/prune
   */
  pruneDelete = (
    request: ModelPruneTableFreshnessRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelPruneTableFreshnessResponse, any>({
      path: `/tableFreshness/prune`,
      method: 'DELETE',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get table freshness
   *
   * @tags tableFreshness
   * @name RealtimeList
   * @summary get table freshness by database and schema
   * @request GET:/tableFreshness/realtime
   */
  realtimeList = (params: RequestParams = {}) =>
    this.request<ModelListTableFreshnessResponse, any>({
      path: `/tableFreshness/realtime`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get table freshness
   *
   * @tags tableFreshness
   * @name RealtimeDetail
   * @summary get table freshness by table id
   * @request GET:/tableFreshness/realtime/{tableId}
   */
  realtimeDetail = (tableId: string, params: RequestParams = {}) =>
    this.request<ModelGetTableFreshnessResponse, any>({
      path: `/tableFreshness/realtime/${tableId}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
