/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelCommonResponse,
  ModelImportTableRequest,
  ModelTableCatalogRequest,
  ModelTableCatalogResponse,
  ModelTableTreeCatalogResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Table<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description get table catalog
   *
   * @tags table
   * @name CatalogCreate
   * @summary get table catalog
   * @request POST:/table/catalog
   */
  catalogCreate = (
    request: ModelTableCatalogRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelTableCatalogResponse, any>({
      path: `/table/catalog`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get table catalog tree
   *
   * @tags table
   * @name CatalogTreeList
   * @summary get table catalog tree
   * @request GET:/table/catalog/tree
   */
  catalogTreeList = (params: RequestParams = {}) =>
    this.request<ModelTableTreeCatalogResponse, any>({
      path: `/table/catalog/tree`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description add user imported table
   *
   * @tags table
   * @name ImportCreate
   * @summary add user imported table
   * @request POST:/table/import
   */
  importCreate = (
    request: ModelImportTableRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelCommonResponse, any>({
      path: `/table/import`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
