/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelCreateQueryPromptRequest,
  ModelCreateQueryPromptResponse,
  ModelDeleteQueryPromptResponse,
  ModelGetQueryPromptResponse,
  ModelListQueryPromptRequest,
  ModelListQueryPromptResponse,
  ModelUpdateQueryPromptRequest,
  ModelUpdateQueryPromptResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Queryprompt<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description create query prompt for users
   *
   * @tags queryPrompt
   * @name QuerypromptCreate
   * @summary create query prompt, only available for zettablock user
   * @request POST:/queryprompt
   */
  querypromptCreate = (
    request: ModelCreateQueryPromptRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelCreateQueryPromptResponse, any>({
      path: `/queryprompt`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get query prompt for users
   *
   * @tags queryPrompt
   * @name SearchCreate
   * @summary search query prompt by chains, types and tags
   * @request POST:/queryprompt/search
   */
  searchCreate = (
    request: ModelListQueryPromptRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelListQueryPromptResponse, any>({
      path: `/queryprompt/search`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get query prompt by id
   *
   * @tags queryPrompt
   * @name QuerypromptDetail
   * @summary get query prompt by id
   * @request GET:/queryprompt/{id}
   */
  querypromptDetail = (id: string, params: RequestParams = {}) =>
    this.request<ModelGetQueryPromptResponse, any>({
      path: `/queryprompt/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description update query prompt for users
   *
   * @tags queryPrompt
   * @name QuerypromptCreate2
   * @summary update query prompt, only available for zettablock user
   * @request POST:/queryprompt/{id}
   * @originalName querypromptCreate
   * @duplicate
   */
  querypromptCreate2 = (
    id: string,
    request: ModelUpdateQueryPromptRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelUpdateQueryPromptResponse, any>({
      path: `/queryprompt/${id}`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description delete query prompt for users
   *
   * @tags queryPrompt
   * @name QuerypromptDelete
   * @summary delete query prompt, only available for zettablock user
   * @request DELETE:/queryprompt/{id}
   */
  querypromptDelete = (id: string, params: RequestParams = {}) =>
    this.request<ModelDeleteQueryPromptResponse, any>({
      path: `/queryprompt/${id}`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
