import { Select } from "antd";

interface LabelSelectProps {
  options: { label: string, value: string }[];
  onChange: (e: string[]) => void;
  value: string[];
}

const LabelSelect = ({
  onChange,
  value,
  options
}: LabelSelectProps) => {

  return <Select
    mode="tags"
    style={{ width: '100%' }}
    placeholder="Labels"
    onChange={onChange}
    value={value || []}
    options={options}
  />
}

export default LabelSelect;