import { useEffect, useMemo, useState } from 'react';
import { convertNumberToDatetime } from '../../utils/date';
import ProfileAvatar from '../auth/ProfileAvatar';
import LogoBlinkerCenter from '../../components/app/LogoBlinkerCenter';
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import BodyHeader3 from '../../components/typographic/BodyHeader3';
import { HorizontalLine } from '../../components/HorizontalLine';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { AiFillFileAdd } from 'react-icons/ai';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { BiCopy } from 'react-icons/bi';
import { DefaultAutoDismissMs } from '../../config/toast';
import { useSearchParam } from '../queryBuilder/QueryPadState';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import { isEmpty } from 'lodash';
import './queryHistoryList.scss';
import { MyApi } from '../../api/client';
import { ModelQueryrunSummary } from '../../api/__gen__/data-contracts';
import { formatDataUsageInBytes } from '../../utils/format';

export const STATUS: {
  [key: string]: {
    className: string;
    label: string
  }
} = {
  CANCELLED: {
    className: 'bg-[#f3de5b] text-white text-[10px] font-bold border-transparent',
    label: 'CANCELLED'
  },
  FAILED: {
    className: 'bg-[#ff6060] text-white text-[10px] font-bold border-transparent',
    label: 'FAILED'
  },
  SUCCEEDED: {
    className: 'bg-[#00e266] text-white text-[10px] font-bold border-transparent',
    label: 'SUCCEEDED'
  },
  '': {
    className: 'bg-[#b3b3b3] text-white text-[10px] font-bold border-transparent',
    label: 'UNKNOWN'
  },
  UNKNOWN: {
    className: 'bg-[#b3b3b3] text-white text-[10px] font-bold border-transparent',
    label: 'UNKNOWN'
  }
}

export default function QueryHistoryListPage() {
  const [, { getPath }] = useWorkspace();

  const queryRunId = useSearchParam('runId');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [queryHistoryList, setQueryHistoryList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(0)

  const historyRecords = useMemo(() => {
    return queryHistoryList.slice(currentPage * 20, currentPage * 20 + 19) || []
  }, [currentPage, queryHistoryList])



  useEffect(() => {
    const getQueryHistoryList = async () => {
      setIsLoading(true);
      const client = MyApi();
      const res = await client.queryrunsList();
      const items: ModelQueryrunSummary[] = res.data?.items || [];
      setQueryHistoryList(items);
      setIsLoading(false);
    };
    getQueryHistoryList();

    // getQueryHistory(); // TODO: this will be updated with query history API when available
  }, []);

  // const list = queryHistoryList || [];

  if (!historyRecords.length && isLoading) {
    return (
      <div className='flex w-full items-center py-20 justify-center'>
        <LogoBlinkerCenter />
      </div>
    );
  }

  return (
    <LayoutBodyCard>
      <div className='flex px-6 py-3 items-center justify-between'>
        <BodyHeader3>Query History</BodyHeader3>
      </div>
      <HorizontalLine />
      <div className='flex flex-col w-full p-3 overflow-x-hidden query-history-list'>
        <table className='w-full table'>
          <thead className='rounded-full w-full'>
            <tr>
              <th className='w-1 font-bold min-w-[30px]'>TIME</th>
              <th className='w-1 font-bold'>STATE</th>
              <th className='w-1 font-bold'>QUERY</th>
              <th className='w-1 font-bold'>USER</th>
              <th className='w-1 font-bold'>CU</th>
              <th className='w-1 font-bold'>READ</th>
              <th className='w-1 font-bold'>OUTPUT</th>
              <th className='w-1 font-bold'>DURATION</th>
              {/* <th className='w-1'>
                ROWS{' '}
                <span
                  className='tooltip tooltip-bottom'
                  data-tip='Number of records returned'
                >
                  <IoInformationCircleSharp className='opacity-50' />
                </span>
              </th> */}
              <th className='w-full'>SQL</th>
              <th className='w-1'>ACTIONS</th>
            </tr>
          </thead>
          <tbody className='rounded-full w-full'>
            {isEmpty(historyRecords) && (
              <td colSpan={1000} className='text-center opacity-30 p-40'>
                No query history
              </td>
            )}
            {historyRecords?.map((item, idx: number) => {
              const link = `${document.location.origin}${getPath(
                'query-history/'
              )}${item.id}`;
              return (
                <tr
                  key={idx}
                  className={classNames({
                    'bg-primary bg-opacity-5': item.id === queryRunId,
                  })}
                >
                  <td>
                    <div className='w-[9rem] whitespace-normal'>
                      {convertNumberToDatetime(item.createdTime)}
                    </div>
                  </td>
                  <td>
                    <div className={classNames("badge", 'w-full', STATUS[item.state]?.className) || STATUS.UNKNOWN.className} >{STATUS[item.state]?.label || STATUS.UNKNOWN.label}</div>
                  </td>
                  <td className='p-2 overflow-hidden'>
                    <Link to={`#`}>
                      <div className='flex flex-auto w-[8rem] justify-start items-center gap-3'>
                        <span
                          title={'Query display name'}
                          className='flex-auto overflow-hidden whitespace-nowrap text-ellipsis'
                        >
                          {item.queryName}
                        </span>
                      </div>
                    </Link>
                  </td>
                  <td className='p-2 overflow-hidden'>
                    <Link to={`#`}>
                      <div className='flex flex-auto w-[8rem] justify-start items-center gap-3'>
                        <ProfileAvatar
                          creator={item.creator}
                          className='flex-none w-6 bg-base-content bg-opacity-30'
                        />
                        <span
                          title={item.creator.displayName}
                          className='flex-auto overflow-hidden whitespace-nowrap text-ellipsis'
                        >
                          {item.creator.displayName}
                        </span>
                      </div>
                    </Link>
                  </td>
                  <td className='text-center'>{item.computeUnit}</td>
                  <td className='text-center'>
                    {formatDataUsageInBytes(item.readBytes)}
                  </td>
                  <td className='text-center'>
                    {formatDataUsageInBytes(item.writeBytes)}
                  </td>
                  <td className='text-center'>{item.executionTime}ms</td>
                  {/* <td className='text-center'>100</td> */}
                  <td className='w-full whitespace-pre-wrap'>
                    <div className='relative rounded-md w-full whitespace-pre-wrap break-all bg-base-content bg-opacity-[0.06] px-2 pt-0 pb-2 max-h-[150px] '>
                      <div className='pt-2 flex justify-end'><CopyToClipboard text={item.text} >
                        <span
                          className='p-1 cursor-pointer'
                          onClick={() => {
                            toast.success('query copied', {
                              autoClose: DefaultAutoDismissMs,
                            });
                          }}
                        >
                          <BiCopy color='#4b7cf7' />
                        </span>
                      </CopyToClipboard></div>

                      <div className='overflow-auto h-[114px]'>{item.text || ''}</div>
                    </div>
                  </td>
                  <td>
                    <div className='flex items-center justify-end'>
                      <div
                        className='tooltip tooltip-left px-1 opacity-90'
                        data-tip='Open query history detail'
                      >
                        <Link
                          state={item}
                          to={`/my-workspace/query-history/${item.id}`}
                        >
                          <AiFillFileAdd color='#4b7cf7' />
                        </Link>
                      </div>

                      <div
                        className='tooltip tooltip-left px-1 opacity-90'
                        data-tip='Copy query history link'
                      >
                        <CopyToClipboard text={link} >
                          <span
                            className='p-1 cursor-pointer'
                            onClick={() => {
                              toast.success('Link copied', {
                                autoClose: DefaultAutoDismissMs,
                              });
                            }}
                          >
                            <BiCopy color='#4b7cf7' />
                          </span>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
            {/* <OnVisible
              onVisible={() => {
                queryHistoryShowMore();
              }}
            /> */}

          </tbody>
        </table>
        <div className="btn-group mx-auto mt-6">
          <button className="btn btn-primary" onClick={() => {
            if (currentPage < 1) return
            setCurrentPage(currentPage - 1)
            window.scrollTo(0, 0)
          }}>Prev</button>
          <button className="btn btn-primary">Page {currentPage + 1}</button>
          <button className="btn btn-primary" onClick={() => {
            const totalPages = Math.ceil(queryHistoryList.length / 20)
            if (currentPage >= totalPages) return
            setCurrentPage(currentPage + 1)
            window.scrollTo(0, 0)
          }}>Next</button>
        </div>
      </div>
    </LayoutBodyCard>
  );
}
