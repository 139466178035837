import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { MdAddBox } from 'react-icons/md';
// import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useWorkspace } from './workspace-hooks';
import envs from '../../config/envs';
import { analytics } from '../../App';

type MenuItemType = {
  label: string;
  path: string;
};

const AppMenus = [
  {
    label: 'Query Builder',
    path: 'queries/new',
  },
  {
    label: 'API Builder',
    path: 'queries/api',
  },
  {
    label: 'Dashboard',
    path: 'dashboards',
  },
  // {
  //   label: 'Project',
  //   path: '/projects',
  // },
];

const TableMenus = [
  {
    label: 'Data Source',
    path: 'data-sources',
  },
  // {
  //   label: 'Contract',
  //   path: '',
  // },
];

export default function CreateMenu() {
  const [open, setOpen] = useState(false);
  // const navigate = useNavigate();
  const [{ workspace }] = useWorkspace();

  const handleMenuClick = useCallback(
    (menu: MenuItemType) => {
      if (menu.path) {
        // TODO unlock later
        // navigate(`/my-workspace/${menu.path}`); // TODO: remove this line if we decided the open in new tab is working fine
        window.open(`/my-workspace/${menu.path}`, '_blank');
      } else {
        toast.dismiss();
        toast.warning('Coming soon');
      }
      setOpen(false);

      // Adding tracking code here
      analytics.track(`Create Menu - Create ${menu.label} clicked`)

    },
    [workspace]
  );
  return (
    <div
      className='dropdown dropdown-end dropdown-hover'
      onBlur={() => {
        setOpen(false);
      }}
    >
      <button
        className='btn btn-primary gap-2'
        onClick={() => {
          setOpen(!open);
        }}
      >
        Create
        <MdAddBox size='1rem' />
      </button>
      <div className='dropdown-content pt-2 menu menu-compact '>
        <ul
          className={classNames(
            ' p-2 shadow bg-base-100 rounded-box w-44 text-right',
            // open ? 'menu menu-compact dropdown-content ' : 'hidden',
            ' opacity-100 right-[-5px] '
          )}
        >
          <li className='pointer-events-none'>
            <div className='py-1 px-4 text-xs opacity-30 font-bold text-right block'>
              Application
            </div>
          </li>
          {AppMenus.map((menu) => (
            <li key={menu.label}>
              <div
                className='justify-end font-semibold hover:text-primary hover:bg-transparent'
                onMouseDown={() => {
                  handleMenuClick(menu);
                }}
              >
                {menu.label}
              </div>
            </li>
          ))}
          {
            !envs.isCircleIntegrationEnabled && (<>
              <li className='pointer-events-none'>
                <hr className='py-1' />
                <div className='py-1 px-4 text-xs opacity-30 font-bold text-right block'>
                  Table
                </div>
              </li>
              {TableMenus.map((menu) => (
                <li key={menu.label}>
                  <div
                    className='justify-end font-semibold hover:text-primary hover:bg-transparent'
                    onMouseDown={() => {
                      handleMenuClick(menu);
                    }}
                  >
                    {menu.label}
                  </div>
                </li>
              ))}</>)
          }
        </ul>
      </div>

    </div>
  );
}
