import { HtmlHTMLAttributes, useCallback, useEffect, useMemo, useState } from 'react';

import {
  SelectedTableSchemaState,
  useQueryPadInject,
} from '../database/TableSchemaState';
import { AiFillCaretDown, AiFillCheckCircle } from 'react-icons/ai';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { SelectedDBState } from '../database/DatabaseState';
import { SelectedSchemaState } from '../database/SchemaState';
import classNames from 'classnames';
import {
  useCategories,
  useDatabases,
  useTables,
} from '../database/schema-hooks';
import { useQueryIdV3 } from './QueryPadStateV3';
import { isEmpty } from 'lodash';
import TableColumnsV3, { tempFilterFn } from './TableColumnsV3';
import { FiArrowRightCircle, FiPlusCircle, FiSearch } from 'react-icons/fi';
import './querybuilder-right-panel.scss';
import { formatSql } from './QueryPadState';
import { ModelTable } from '../../api/__gen__/data-contracts';
import { formatColumn } from './utils/sql';
import { clientSqlDefaultLimit } from '../dashboard/config';
import { SqlLanguage } from 'sql-formatter';
import { dbGroup1, dbGroup2 } from './const';
import { AFetchingPreview, AQueryRunErrorMessage } from './QueryState';
import { PreviewQueryResultId, useRunPreviewData } from './QueryBuilderHooks';
import useAuth from '../../hooks/auth';
import { DatabaseApi } from '../../api/client';
import { useLocation } from 'react-router';
import { analytics } from '../../App';
import { AlphaBadge } from '../../components/badges/AlphaBadge';
import { toast } from 'react-toastify';

const DB_ITEM_ICON_SIZE = 20;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasCorrespondingRealtimeSchema = (schema: string, realtimeSchema: any = [{
  name: 'etherum'
}, {
  name: 'polygon'
}]) => {
  const sanitizedSchemaName = schema.replace('_mainnet', '');

  return (new Set(realtimeSchema.map((e: { name: string }) => e.name))).has(sanitizedSchemaName) || (new Set(realtimeSchema.map((e: { name: string }) => e.name))).has(schema)
}

function SelectorLineItem<T>({
  selectedValue,
  item,
  getKey,
  getLabel,
  onSelectItem,
  onInjectSql,
  onInsertName,
  onPreview,
}: {
  item: T;
  selectedValue?: string;
  getKey: (value: T) => string;
  getLabel: (value?: T) => string;
  onSelectItem: (value: T) => void;
  onInjectSql?: (value: T) => void;
  onInsertName?: (value: T) => void;
  onPreview?: (value: T) => void;
} & HtmlHTMLAttributes<HTMLDivElement>) {
  return (
    <div
      key={getKey(item)}
      className='db-lineitem cursor-pointer py-3 pl-5 pr-2 flex items-center gap-1 text-sm flex-nowrap w-full hover:bg-base-300'
      onClick={() => {
        onSelectItem(item);
      }}
    >
      {selectedValue === getKey(item) && (
        <AiFillCheckCircle size='1.1rem' className='text-primary flex-none' />
      )}
      <span className='flex-auto'>
        <div className='flex items-center justify-between'>
          <span
            title={getLabel(item)}
            className='overflow-hidden w-[250px] text-ellipsis whitespace-nowrap'
          >
            {getLabel(item)}
          </span>
          {onInsertName && onInjectSql && onPreview && (
            <span
              className='flex gap-1 db-item-actions'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <FiSearch
                size={DB_ITEM_ICON_SIZE}
                className='opacity-40'
                onClick={() => {
                  onPreview(item);

                  // tracking 
                  analytics.track('Query - Preview table clicked', {
                    table: item
                  })
                }}
              />
              <FiArrowRightCircle
                size={DB_ITEM_ICON_SIZE}
                className='opacity-40'
                onClick={() => {
                  onInjectSql(item);
                  // tracking 
                  analytics.track('Query - Insert full sql clicked', {
                    table: item
                  })
                }}
              />
              <FiPlusCircle
                size={DB_ITEM_ICON_SIZE}
                className='opacity-40'
                onClick={() => {
                  onInsertName(item);
                  // tracking 
                  analytics.track('Query - Insert table name clicked', {
                    table: item
                  })
                }}
              />
            </span>
          )}
        </div>
      </span>
    </div>
  );
}

function SelectorSectionItem<T>({
  isOpen,
  label,
  selectedValue,
  onClick,
  items,
  getKey,
  getLabel,
  onSelectItem,
  onInjectSql,
  onInsertName,
  onPreview,
  group1,
  group2,
  showRealtime,
  onSelectRealtime,
  selectedDB,
  isRealtimeDB,
}: {
  items: T[];
  label: string;
  isOpen?: boolean;
  selectedValue?: string;
  getKey: (value: T) => string;
  getLabel: (value?: T) => string;
  onSelectItem: (value: T) => void;
  onInjectSql?: (value: T) => void;
  onInsertName?: (value: T) => void;
  onPreview?: (value: T) => void;
  group1?: string[];
  group2?: string[];
  showRealtime?: boolean;
  onSelectRealtime?: (value: boolean) => void;
  selectedDB?: string | undefined;
  isRealtimeDB?: boolean
} & HtmlHTMLAttributes<HTMLDivElement>) {
  const location = useLocation();
  const selectedItem = items?.find((c) => getKey(c) === selectedValue);
  const valueLabel = getLabel(selectedItem) || '';

  const group1Items =
    (group1 && items.filter((i) => group1.includes(getKey(i))))?.filter(
      (item) => !getKey(item).endsWith('_holding')
    ) || [];
  const group2Items =
    (group2 && items.filter((i) => group2.includes(getKey(i))))?.filter(
      (item) => !getKey(item).endsWith('_holding')
    ) || [];

  const groupHasItems = !isEmpty(group2Items) || !isEmpty(group1Items);

  const restItems = (
    groupHasItems
      ? items.filter(
        (i) =>
          ![
            ...group1Items.map((i2) => getKey(i2)),
            ...group2Items.map((i2) => getKey(i2)),
          ].includes(getKey(i))
      )
      : items
  ).filter((item) => !getKey(item).endsWith('_holding'));

  const defaultChecked = useMemo(() => {
    return isRealtimeDB
  }, [isRealtimeDB])

  return (
    <div className='w-full flex-none'>
      <div
        className='w-full flex items-center pl-2 py-4 cursor-pointer text-xs'
        onClick={onClick}
      >
        <span className='w-[43%] font-semibold flex-none flex items-center'>
          <span className='opacity-40 '>
            {
              // eslint-disable-next-line no-nested-ternary
              label === 'DATABASE' ?
                selectedDB?.startsWith('realtime') ?
                  'REALTIME' : 'DATABASE'
                : label
            }
          </span>
          {
            label === 'DATABASE' && showRealtime && selectedDB && <div className="flex items-center opacity-[1] tooltip tooltip-right text-left " data-tip={
              selectedDB?.startsWith('realtime') ? 'Realtime Database: Real-time data fetching, low latency. Perfect for filtering queries to include the latest data.' : 'Data Lake: Fast historical analytics, with higher latency. Ideal for complex analyses.'
            }>
              <input type="checkbox" className='toggle toggle-sm toggle-primary ml-1'
                disabled={!location.pathname.includes('new')}
                defaultChecked={defaultChecked}
                {
                ...(!location.pathname.includes('new') && { checked: defaultChecked })
                }
                onClick={(e) => {
                  onSelectRealtime?.((e.target as HTMLInputElement).checked)
                  e.stopPropagation();

                  analytics.track('Query - Toggle realtime database', {
                    realtime: (e.target as HTMLInputElement).checked
                  })
                }} />
            </div>
          }
          {
            label === 'DATABASE' && selectedDB?.startsWith('realtime') && <AlphaBadge className='ml-1 p-1 text-[10px]' />
          }
        </span>

        <div
          className='flex-auto font-semibold overflow-hidden whitespace-nowrap text-ellipsis'
          title={valueLabel}
        >
          {valueLabel}
        </div>
        <div className='px-2'>
          <AiFillCaretDown
            size='1rem'
            className={classNames('opacity-30 flex-none', {
              'rotate-180': isOpen,
            })}
          />
        </div>
        {label === 'TABLES' &&
          selectedItem &&
          onInjectSql &&
          onInsertName &&
          onPreview && (
            <div className='px-2'>
              <span
                className='flex gap-1 db-item-actions'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <div className='tooltip z-30' data-tip='Preview Data'>
                  <FiSearch
                    size={DB_ITEM_ICON_SIZE}
                    className='opacity-40'
                    onClick={() => {
                      // tracking 
                      analytics.track('Query - Preview table clicked - full', {
                        table: selectedItem
                      })
                      onPreview(selectedItem);
                    }}
                  />
                </div>
                <div className='tooltip z-30' data-tip='Insert Full Basic SQL'>
                  <FiArrowRightCircle
                    size={DB_ITEM_ICON_SIZE}
                    className='opacity-40'
                    onClick={() => {
                      // tracking 
                      analytics.track('Query - Insert full sql clicked - full', {
                        table: selectedItem
                      })
                      onInjectSql(selectedItem);
                    }}
                  />
                </div>
                <div className='tooltip z-30' data-tip='Insert Name in SQL'>
                  <FiPlusCircle
                    size={DB_ITEM_ICON_SIZE}
                    className='opacity-40'
                    onClick={() => {
                      // tracking 
                      analytics.track('Query - Insert table name clicked -full', {
                        table: selectedItem
                      })
                      onInsertName(selectedItem);
                    }}
                  />
                </div>
              </span>
            </div>
          )}
      </div>
      <div className='border-b' />
      <div
        className={classNames(
          'overflow-auto transition-max-height duration-400 ease-in-out',
          isOpen && !isEmpty(items) ? 'max-h-[60vh]' : 'max-h-0'
        )}
      >
        <div className='w-full flex flex-col items-center text-xs bg-base-200'>
          {!isEmpty(group1Items) && (
            <div className='flex px-5 py-1 bg-gray-200 text-[10px] font-bold w-full'>
              <span className='opacity-40'>CHAIN DATA</span>
            </div>
          )}

          {group1Items?.map((item) => (
            <SelectorLineItem
              key={getKey(item)}
              item={item}
              getKey={getKey}
              getLabel={getLabel}
              selectedValue={selectedValue}
              onSelectItem={onSelectItem}
              onInjectSql={onInjectSql}
              onInsertName={onInsertName}
              onPreview={onPreview}
            />
          ))}

          {!isEmpty(group2Items) && (
            <div className='flex px-5 py-1 bg-gray-200 text-[10px] font-bold w-full'>
              <span className='opacity-40'>ABSTRACTION DATA</span>
            </div>
          )}

          {group2Items?.map((item) => (
            <SelectorLineItem
              key={getKey(item)}
              item={item}
              getKey={getKey}
              getLabel={getLabel}
              selectedValue={selectedValue}
              onSelectItem={onSelectItem}
              onInjectSql={onInjectSql}
              onInsertName={onInsertName}
              onPreview={onPreview}
            />
          ))}

          {groupHasItems && (
            <div className='flex px-5 py-1 bg-gray-200 text-[10px] font-bold w-full'>
              <span className='opacity-40'>PROJECT DATA</span>
            </div>
          )}

          {restItems?.map((item) => (
            <SelectorLineItem
              key={getKey(item)}
              item={item}
              getKey={getKey}
              getLabel={getLabel}
              selectedValue={selectedValue}
              onSelectItem={onSelectItem}
              onInjectSql={onInjectSql}
              onInsertName={onInsertName}
              onPreview={onPreview}
            />
          ))}
        </div>
        <div className='border-b' />
      </div>
    </div>
  );
}

export default function QueryBuilderLeftPanel({
  isRealtimeDB
}: {
  isRealtimeDB?: boolean
}) {
  // selector states
  const [selectedDB, setDB] = useRecoilState(SelectedDBState);
  const [selectedSchema, setSchema] = useRecoilState(SelectedSchemaState);
  const [selectedTable, setTableSchema] = useRecoilState(
    SelectedTableSchemaState
  );
  const [openSection, setOpenSection] = useState<
    'category' | 'database' | 'table' | null
  >(null);

  const handleDatabaseSelect = useCallback(
    (value?: string) => {
      setDB(value);
      setOpenSection(null);
    },
    [setDB]
  );

  // realtime schema
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [realtimeSchema, setRealtimeSchema] = useState<any>(null);
  // const [, setUseRealtime] = useState<boolean>(false);
  const [showRealtime, setShowRealtime] = useState<boolean>(false);
  // get realtime schema and store it locally(local state)
  useEffect(() => {
    const fetchRealtimeSchema = async () => {
      const client = DatabaseApi();
      const resp = await client.schemasDetail('realtimeDB'); // TODO: realtimeDB as constant

      setRealtimeSchema(resp.data.items || [])
      // check if selected schema is in realtimeDB as well
      // current logic is weird => _mainnet might be the surfix
      if (hasCorrespondingRealtimeSchema(selectedSchema?.schema || '', resp.data.items)) {
        setShowRealtime(true)
      } else {
        setShowRealtime(false)
      }
    }
    if (!realtimeSchema && selectedSchema?.schema) {
      fetchRealtimeSchema();
    } else if (selectedSchema?.schema) {
      if (hasCorrespondingRealtimeSchema(selectedSchema?.schema || '', realtimeSchema)) {
        setShowRealtime(true)
      } else {
        setShowRealtime(false)
      }
    }
  }, [selectedSchema?.schema, realtimeSchema])

  // userInfo
  const [{ auth }] = useAuth();

  const isCategoryVisible = useMemo(() => {
    if (!auth?.tenant) {
      return false;
    }

    return auth?.tenant === 'zettablock.com' || auth?.tenant === 'storyprotocol.xyz' // only display this for us
  }, [auth]);

  const handleSchemaSelect = useCallback(
    (value?: string) => {
      if (selectedDB) {
        setSchema({ database: selectedDB, schema: value });
        setOpenSection(null);
      }
    },
    [selectedDB, setSchema]
  );

  const handleTableSelect = useCallback(
    (value?: string) => {
      setTableSchema(value);
      setOpenSection(null);
    },
    [setTableSchema]
  );

  const [{ categories }] = useCategories();
  const [{ databases }] = useDatabases(selectedDB);
  const [{ tables }] = useTables(selectedSchema, { autoRefetch: true });

  const queryId = useQueryIdV3();
  const isFetchingPreview = useRecoilValue(AFetchingPreview);
  const [runPreviewData] = useRunPreviewData();

  const setQueryRunErrorMessage = useSetRecoilState(AQueryRunErrorMessage);

  // select category
  useEffect(() => {
    const firstCategory = categories?.items?.[0]?.id;
    const secondCategory = categories?.items?.[1]?.id;

    if (selectedDB || !firstCategory) return;

    if (firstCategory?.startsWith('realtime')) {
      if (secondCategory) {
        handleDatabaseSelect(secondCategory);
      } else {
        toast.error('No database found. Please contact support')
      }
    } else {
      handleDatabaseSelect(firstCategory);
    }
  }, [queryId, categories, selectedDB]);

  useEffect(() => {
    // set default to ethereum_mainnet
    const firstItem =
      (databases?.items?.find((item) => item.name === 'ethereum_mainnet'))
        ?.name || databases?.items?.[0].name;
    // default database
    if (!selectedSchema?.schema && firstItem && selectedDB) {
      handleSchemaSelect(firstItem);
      return;
    }

    // set first item when selected is not found in the new list
    if (
      firstItem &&
      selectedSchema?.schema &&
      !databases?.items?.find((d) => d.name === selectedSchema.schema) &&
      selectedDB
    ) {
      handleSchemaSelect(firstItem);
    }
  }, [queryId, selectedSchema, databases, selectedDB]);

  useEffect(() => {
    const firstItem = tables?.items?.[0]?.name;
    // set default table
    if (!selectedTable && firstItem) {
      handleTableSelect(firstItem);
      return;
    }

    // set first item when selected is not found in the new list
    if (
      firstItem &&
      selectedTable &&
      !tables?.items?.find((t) => t.name === selectedTable)
    ) {
      handleTableSelect(firstItem);
    }
  }, [queryId, selectedTable, tables]);

  // query inject
  const injectKeyword = useQueryPadInject();

  const handleInject = useCallback(
    (value: string) => {
      injectKeyword(value);
    },
    [injectKeyword]
  );

  const selectedCategoryObj = categories?.items?.find(
    (i) => i.id === selectedDB
  );

  const handleToggle = (val: boolean) => {
    if (val) {
      setDB('realtimeDB')
      setSchema({
        database: 'realtimeDB',
        schema: selectedSchema?.schema
      })
    } else {
      const firstCategory = categories?.items?.[0]?.id;
      if (firstCategory) {
        handleDatabaseSelect(firstCategory);
      }
    }
  }

  const handleFormat = useCallback(
    (table: ModelTable) => {
      if (selectedSchema?.schema) {
        injectKeyword(
          formatSql(
            `SELECT ${tempFilterFn(table?.name, selectedSchema.schema, table.columns || [], isRealtimeDB)
              ?.map((item) => formatColumn(item.name))
              .join(', ')}\n  FROM ${selectedSchema.schema}.${table?.name
            } limit ${clientSqlDefaultLimit}`,
            {
              language: selectedCategoryObj?.sqlDialect as SqlLanguage,
            }
          )
        );
      }
    },
    [injectKeyword, selectedSchema, selectedCategoryObj?.sqlDialect]
  );

  const isCategoryOpen = openSection === 'category';
  const isDatabaseOpen = openSection === 'database';
  const isTableOpen = openSection === 'table';



  // Side bar
  return (
    <div className='flex flex-col w-full flex-none h-[calc(100vh-42px)] overflow-visible'>
      {categories && categories.items && categories.items.length > 1 && isCategoryVisible && (
        <SelectorSectionItem
          label='CATEGORY'
          selectedValue={selectedDB}
          getKey={(item) => item.id ?? ''}
          getLabel={(item) => item?.displayName ?? ''}
          items={categories?.items || []}
          isOpen={isCategoryOpen}
          onClick={() => {
            if (isCategoryOpen) {
              setOpenSection(null);
            } else {
              setOpenSection('category');
            }
          }}
          onSelectItem={(item) => {
            handleDatabaseSelect(item.id);
            // tracking here
            analytics.track('Query - Select CATEGORY', {
              category: item.displayName
            })
          }}
        />
      )}

      <div className='w-full'>
        <SelectorSectionItem
          isRealtimeDB={isRealtimeDB}
          selectedDB={selectedDB}
          showRealtime={showRealtime}
          label='DATABASE'
          selectedValue={selectedSchema?.schema || ''}
          getKey={(item) => item.name ?? ''}
          getLabel={(item) => item?.name ?? ''}
          items={databases?.items || []}
          group1={dbGroup1}
          group2={dbGroup2}
          isOpen={isDatabaseOpen}
          onClick={() => {
            if (isDatabaseOpen) {
              setOpenSection(null);
            } else {
              setOpenSection('database');
            }
          }}
          onSelectRealtime={handleToggle}
          onSelectItem={(item) => {
            handleSchemaSelect(item.name || '');
            // tracking here
            analytics.track('Query - Select DATABASE', {
              category: item.name
            })
          }}
        />
      </div>

      <SelectorSectionItem
        label='TABLES'
        selectedValue={selectedTable || ''}
        getKey={(item) => item.name ?? ''}
        getLabel={(item) => item?.name ?? ''}
        items={tables?.items || []}
        isOpen={isTableOpen}
        onClick={() => {
          if (isTableOpen) {
            setOpenSection(null);
          } else {
            setOpenSection('table');
          }
        }}
        onSelectItem={(item) => {
          handleTableSelect(item.name || '');
          // tracking here
          analytics.track('Query - Select TABLE', {
            category: item.name
          })
        }}
        onInjectSql={(item) => {
          handleFormat(item);
        }}
        onInsertName={(item) => {
          if (item.name) {
            handleInject(`${selectedSchema?.schema}.${item?.name}`);
          }
        }}
        onPreview={(item) => {
          if (item.name && !isFetchingPreview) {
            // reset the error message
            setQueryRunErrorMessage('');

            runPreviewData({
              queryId: PreviewQueryResultId,
              selectedDB,
              schema: selectedSchema?.schema,
              table: item?.name,
            });
          }
        }}
      />

      <div className='label font-semibold opacity-50 flex items-center justify-between'>
        <span className='label-text font-semibold p-2 text-xs'>COLUMNS</span>
      </div>
      <TableColumnsV3 onInsertClick={handleInject} isRealtimeDB={isRealtimeDB} />
    </div>
  );
}
