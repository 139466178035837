import { memo, useEffect, useMemo, useState } from 'react';
import { BiCopy } from 'react-icons/bi';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

import './query-history.scss';
import { useQueryHistoryV3 } from './QueryHistoryHookV3';
import { useQueryId, useSearchParam } from './QueryPadState';
import { convertNumberToDatetime } from '../../utils/date';
import { DefaultAutoDismissMs } from '../../config/toast';
import classNames from 'classnames';
import LoadingIndicator from '../../components/LoadingIndicator';
import { formatDataUsageInBytes } from '../../utils/format';
import ProfileAvatar from '../auth/ProfileAvatar';
import { Link } from 'react-router-dom';
import { STATUS } from '../explorer/QueryHistoryListPage';
import { isEmpty } from 'lodash';
import './QueryHistoryV3.scss';

const pageSize = 20;

function QueryHistoryV3() {
  const [queryId] = useQueryId();
  const queryRunId = useSearchParam('runId');
  const [{ queryHistory, rpcStatus }, { getQueryHistory }] = useQueryHistoryV3({
    queryId,
  });
  const [currentPage, setCurrentPage] = useState(0);

  const historyRecords = useMemo(() => {
    if (!queryHistory?.items) return [];
    return (
      queryHistory?.items.slice(
        currentPage * pageSize,
        currentPage * pageSize + pageSize - 1
      ) || []
    );
  }, [currentPage, queryHistory?.items]);

  useEffect(() => {
    getQueryHistory();
  }, []);

  if (rpcStatus?.isLoading) {
    return (
      <div className='mt-4'>
        <div className='flex items-center justify-center p-20'>
          <LoadingIndicator />
        </div>
      </div>
    );
  }

  if (rpcStatus?.error) {
    return (
      <div className='mt-4'>
        <div>{rpcStatus?.error}</div>
      </div>
    );
  }

  return (
    <div className='p-3 query-history query-history-list'>
      <div className='text-lg font-bold'>Query History</div>
      <table className='w-full table table-compact table-zebra'>
        <thead className='rounded-full w-full'>
          <tr>
            <th className='w-1 font-bold min-w-[30px]'>TIME</th>
            <th className='w-1 font-bold'>STATE</th>
            <th className='w-1 font-bold'>QUERY NAME</th>
            <th className='w-1 font-bold'>USER</th>
            <th className='w-1 font-bold'>CU</th>
            <th className='w-1 font-bold'>READ</th>
            <th className='w-1 font-bold'>OUTPUT</th>
            <th className='w-1 font-bold'>DURATION</th>
            {/* <th className='w-1'>
                ROWS{' '}
                <span
                  className='tooltip tooltip-bottom'
                  data-tip='Number of records returned'
                >
                  <IoInformationCircleSharp className='opacity-50' />
                </span>
              </th> */}
            <th className='w-full'>SQL</th>
          </tr>
        </thead>

        <tbody>
          {isEmpty(historyRecords) && (
            <td colSpan={1000} className='text-center opacity-30 p-40'>
              No query history
            </td>
          )}
          {historyRecords.map((item, idx: number) => {
            if (!item) return null;

            return (
              <tr
                key={idx}
                className={classNames({
                  'bg-primary bg-opacity-5': item.id === queryRunId,
                })}
              >
                <td>
                  <div className='w-[9rem] whitespace-normal'>
                    {convertNumberToDatetime(item.createdTime)}
                  </div>
                </td>
                <td>
                  <div
                    className={
                      classNames(
                        'badge',
                        'w-full',
                        STATUS[item.state || '']?.className
                      ) || STATUS.UNKNOWN.className
                    }
                  >
                    {STATUS[item.state || '']?.label || STATUS.UNKNOWN.label}
                  </div>
                </td>
                <td className='p-2 overflow-hidden'>
                  <Link to={`#`}>
                    <div className='flex flex-auto w-[8rem] justify-start items-center gap-3'>
                      <span
                        title={'Query display name'}
                        className='flex-auto overflow-hidden whitespace-nowrap text-ellipsis'
                      >
                        {item.queryName}
                      </span>
                    </div>
                  </Link>
                </td>
                <td className='p-2 overflow-hidden'>
                  <Link to={`#`}>
                    <div className='flex flex-auto w-[8rem] justify-start items-center gap-3'>
                      <ProfileAvatar
                        creator={item.creator}
                        className='flex-none w-6 bg-base-content bg-opacity-30'
                      />
                      <span
                        title={item.creator?.displayName}
                        className='flex-auto overflow-hidden whitespace-nowrap text-ellipsis'
                      >
                        {item.creator?.displayName}
                      </span>
                    </div>
                  </Link>
                </td>
                <td className='text-center'>{item.computeUnit}</td>
                <td className='text-center'>
                  {formatDataUsageInBytes(item.readBytes ?? 0)}
                </td>
                <td className='text-center'>
                  {formatDataUsageInBytes(item.writeBytes ?? 0)}
                </td>
                <td className='text-center'>{item.executionTime}ms</td>
                {/* <td className='text-center'>100</td> */}
                <td className='w-full whitespace-pre-wrap'>
                  <div className='relative rounded-md w-full whitespace-pre-wrap break-all bg-base-content bg-opacity-[0.06] px-2 pt-0 pb-2 max-h-[150px] '>
                    <div className='pt-2 flex justify-end'>
                      <CopyToClipboard text={item.text || ''}>
                        <span
                          className='p-1 cursor-pointer'
                          onClick={() => {
                            toast.success('query copied', {
                              autoClose: DefaultAutoDismissMs,
                            });
                          }}
                        >
                          <BiCopy color='#4b7cf7' />
                        </span>
                      </CopyToClipboard>
                    </div>

                    <div className='overflow-auto h-[114px]'>
                      {item.text || ''}
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='btn-group mx-auto mt-6'>
        <button
          className='btn btn-primary'
          onClick={() => {
            if (currentPage < 1) return;
            setCurrentPage(currentPage - 1);
            window.scrollTo(0, 0);
          }}
        >
          Prev
        </button>
        <button className='btn btn-primary'>Page {currentPage + 1}</button>
        <button
          className='btn btn-primary'
          onClick={() => {
            const totalPages = Math.ceil(
              (queryHistory.items?.length || 0) / 20
            );
            if (currentPage >= totalPages) return;
            setCurrentPage(currentPage + 1);
            window.scrollTo(0, 0);
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default memo(QueryHistoryV3);
