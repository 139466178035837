import { memo, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IoIosCopy } from 'react-icons/io';
import { toast } from 'react-toastify';
import { DefaultAutoDismissMs } from '../../config/toast';

import './api-query-pad.scss';
import { useApiQueryPad } from './ApiQueryPadHooks';

function ApiQueryPad() {
  const [{ editor, value }, { setContainer, view }] = useApiQueryPad({
    readOnly: true,
  });

  useEffect(() => {
    if (editor.current) {
      setContainer(editor.current);
    }
  }, [editor.current]);

  useEffect(() => {
    if (view) {
      window.view = view;
    }
    const interval = setInterval(() => {
      if (view) {
        window.view = view;
      }
    }, 500);
    return () => clearInterval(interval);
  }, [view]);

  return (
    <div className='overflow-auto flex flex-col relative readonly h-full group'>
      <div
        key='editor'
        className='flex-auto overflow-auto'
        ref={editor as React.RefObject<HTMLDivElement>}
      />
      <div className='absolute right-3 top-3 group-hover:block hidden cursor-pointer'>
        <CopyToClipboard text={value || ''}>
          <IoIosCopy
            size='1.2rem'
            className='opacity-60'
            onClick={() => {
              toast.success('SQL query copied', {
                autoClose: DefaultAutoDismissMs,
              });
            }}
          />
        </CopyToClipboard>
      </div>
    </div>
  );
}

export default memo(ApiQueryPad);
