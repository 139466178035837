const ranges = [
  { divider: 1e18, suffix: 'E' },
  { divider: 1e15, suffix: 'P' },
  { divider: 1e12, suffix: 'T' },
  { divider: 1e9, suffix: 'G' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'k' },
];

export function formatNumber(n: number) {
  for (let i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      return (
        // eslint-disable-next-line
        (n / ranges[i].divider).toFixed(2).toString() + ` ${ranges[i].suffix}`
      );
    }
  }
  return n.toString();
}

export const formatNum = (n: number) => {
  const numRanges = [
    { divider: 1e9, suffix: 'B' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'K' },
  ];
  for (let i = 0; i < numRanges.length; i++) {
    if (n >= numRanges[i].divider) {
      return (
        // eslint-disable-next-line
        (n / numRanges[i].divider).toFixed(2).toString() +
        ` ${numRanges[i].suffix}`
      );
    }
  }
  return n.toString();
};

export const formatDataUsage = (usageInMb: number) => {
  if (usageInMb < 1024) {
    return `${usageInMb} MB`;
  }
  if (usageInMb < 1024 * 1024) {
    return `${(usageInMb / 1024).toFixed(2)} GB`;
  }
  if (usageInMb < 1024 * 1024 * 1024) {
    return `${(usageInMb / 1024 / 1024).toFixed(2)} TB`;
  }

  return `${(usageInMb / 1024 / 1024 / 1024).toFixed(2)} PB`;
};

export const numberWithCommas = (x: string | number | undefined) => {
  if (x === 0 || x === '0') return '0';
  
  if (!x) return '';

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatDataUsageInBytes = (usageInBytes: number) => {
  if (usageInBytes < 1024) {
    return `${usageInBytes} B`;
  }
  if (usageInBytes < 1024 * 1024) {
    return `${(usageInBytes / 1024).toFixed(2)} KB`;
  }
  if (usageInBytes < 1024 * 1024 * 1024) {
    return `${(usageInBytes / 1024 / 1024).toFixed(2)} MB`;
  }
  if (usageInBytes < 1024 * 1024 * 1024 * 1024) {
    return `${(usageInBytes / 1024 / 1024 / 1024).toFixed(2)} GB`;
  }

  return `${(usageInBytes / 1024 / 1024 / 1024 / 1024).toFixed(2)} TB`;
};
