/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-lone-blocks */
import { useCallback, useMemo, useRef, useState } from "react";
import { PoTableMetadata } from "../../api/__gen__/data-contracts";
import Icon from "../components/Icon";
import useCatalogService from "./useCatalogService";
import classNames from "classnames";
import { getTableDetailById } from "../workspace/apis";
import LoadingIndicator from "../../components/LoadingIndicator";
import { PreviewQueryResultId, useRunPreviewData } from "../../modules/queryBuilder/QueryBuilderHooks";
import { useQueryPadInject } from "../../modules/database/TableSchemaState";
import { formatSql } from "../../modules/queryBuilder/QueryPadState";
import { tempFilterFn } from "../../modules/queryBuilder/TableColumnsV3";
import { formatColumn } from "../../modules/queryBuilder/utils/sql";
import { clientSqlDefaultLimit } from "../../modules/dashboard/config";
import Toggle from "./Toggle";
import { useNavigate } from "react-router";
import { AQueryRunErrorMessage } from "../../modules/queryBuilder/QueryState";
import { useSetRecoilState } from "recoil";
import { track } from "../tracking";
import useDragY from "../../hooks/use-drag-y";


const CatalogV2 = ({
  viewMode,
  publicVisible
}: {
  viewMode?: boolean
  publicVisible?: boolean
}) => {
  const initialHeight = window.innerHeight * 0.4;
  const maxHeight = 1200;
  const minHeight = 200;

  const [panelHeight, setPanelHeight] = useState(initialHeight);
  const [dragHeight, setDragHeight] = useState(0);
  const [dragHeightValidated, setDragHeightValidated] = useState(0);

  const refPanelHandle = useRef<HTMLDivElement>(null);
  useDragY(refPanelHandle, {
    onDraging: (moved) => {
      const nValue = panelHeight + dragHeight;

      setDragHeight(-moved);

      if (nValue > minHeight && nValue < maxHeight) {
        setDragHeightValidated(-moved);
      }
    },
    onDragEnd: () => {
      setPanelHeight(panelHeight + dragHeightValidated);
      setDragHeight(0);
      setDragHeightValidated(0);
    },
  });

  const {
    isLoading,
    catalog,
    myTables,
    priceTables
  } = useCatalogService(!!publicVisible)

  const [keyword, setKeyword] = useState<string>('')
  const [isRealtime, setIsRealtime] = useState<boolean>(false)
  const filteredCatalog = useMemo(() => {
    return catalog.map(cat => {
      return {
        ...cat,
        children: cat.children.map(label => {
          return {
            ...label,
            children:
              label.children.filter(table => {
                if (isRealtime && table.type !== 'realtime') {
                  return false
                }
                return table?.name?.toLowerCase().includes(keyword.toLowerCase())
              }).filter(table => table.status !== 0)
          }
        }).filter(label => label.children.length > 0)
      }
    }).filter(cat => cat.children.length > 0)
  }, [
    keyword,
    catalog,
    isRealtime
  ])

  const [selectedChain, setSelectedChain] = useState<any>(null)
  const [selectedLabel, setSelectedLabel] = useState<any>(null)
  const [selectedTable, setSelectedTable] = useState<any>(null)
  const [tableDetail, setTableDetail] = useState<any>([])
  const setQueryRunErrorMessage = useSetRecoilState(AQueryRunErrorMessage);

  const [isTableDetailLoading, setIsTableDetailLoading] = useState<boolean>(false)

  const navigate = useNavigate();

  const fetchAndSetTableDetail = async (tableId?: string) => {
    setIsTableDetailLoading(true)
    const resp = await getTableDetailById(tableId || '')
    setTableDetail(resp || {
      table: '',
      column: []
    })
    setIsTableDetailLoading(false)

    return resp.column
  }

  const [runPreviewData] = useRunPreviewData(true);
  const injectKeyword = useQueryPadInject();
  const handleInject = useCallback(
    (value: string) => {
      injectKeyword(value);
    },
    [injectKeyword]
  );

  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [isLoadingPreview, setIsLoadingPreview] = useState(false)
  const handleFormat = useCallback(
    async (item: any) => {
      setIsLoadingTable(true)
      const tableDetails = await fetchAndSetTableDetail(item.id)
      if (item?.database) {
        injectKeyword(
          formatSql(
            `SELECT ${tempFilterFn(item?.name, item.database, tableDetails || [], item?.database?.startsWith('realtime'))
              ?.map((e) => formatColumn(e.name))
              .join(', ')}\n  FROM ${item.database}.${item?.name
            } limit ${clientSqlDefaultLimit}`,
            {
              language: item?.sqlDialect,
            }
          )
        );
      }
      setIsLoadingTable(false)
    },
    [injectKeyword, fetchAndSetTableDetail]
  );

  if (isLoading) return <div className={classNames(
    "w-[300px] min-w-[300px] flex justify-center items-center",
    {
      "h-[calc(100vh-41px)]": !publicVisible,
      "h-[calc(100vh)]": publicVisible
    }
  )}>
    <LoadingIndicator />
  </div>

  return <div className={classNames("w-[300px] min-w-[300px]", {
    "h-[calc(100vh-41px)]": !publicVisible,
    "h-[calc(100vh)]": publicVisible
  })}>
    {/* Title */}
    {
      viewMode && !publicVisible && <div className="text-[#2D2F34] px-[40px] py-[20px] text-[12px] leading-[12px] border-b cursor-pointer" onClick={() => {
        navigate('/v2/explore/tables')
      }}>
        Overview
      </div>
    }
    {/* Search */}
    <div className="flex items-center pl-[15px]  border-b">
      <div className="text-[12px] leading-[12px] flex items-center gap-[10px] flex-1 py-[10px]">
        <Icon iconName="magnify" className="w-[14px] h-[14px]" />
        <input
          onChange={(e) => {
            setKeyword(e.target.value)
            track('Table Search', {})
          }}
          className="placeholder:text-[#C2C6CC] outline-none" placeholder="Search Tables" />
      </div>
      <div className="w-[1px] h-[34px] bg-[#e5e7eb]"> </div>
      <div className="w-[60px] h-full flex justify-center items-center">
        <Toggle checked={isRealtime} onToggle={(e) => {
          track('Realtime Table Toggle Clicked', {
            isRealtime: e
          })
          setIsRealtime(e)
        }} />
      </div>
    </div>

    {/* Table Tree */}
    <div className={
      classNames("relative mx-[5px] mt-[10px] overflow-auto", {
        "h-[calc(100%-98px)]": viewMode,
        "h-[calc(100%-58px)]": !viewMode
      })
    }>
      {/* my tables */}
      {
        !viewMode && !publicVisible && <>
          <div
            onClick={() => {
              if (selectedChain === -1) {
                setSelectedChain(null)
              } else {
                setSelectedChain(-1)
              }
              setSelectedTable(null)
            }}
            className={
              classNames("cursor-pointer rounded-[4px] px-[10px] py-[8px] flex items-center gap-[5px] mb-[5px] justify-between", {
                // "bg-[#F2F2F2]": selectedChain === index
              })
            }
          >
            <div className="flex items-center gap-[5px]">
              <Icon
                iconName="mytable"
                className="w-[18px] h-[18px]"
              />
              <div className="text-[#2D2F34] text-[12px] leading-[12px] font-[600]">
                My Tables
              </div>
            </div>
            <div className="p-[5px] border rounded-[4px] text-[10px] leading-[10px] text-[#263DFF] bg-[#EDF0FF]" >
              {
                `${myTables.filter(e => {
                  if (isRealtime && e.type !== 'realtime') {
                    return false
                  }
                  return e?.name?.toLowerCase().includes(keyword.toLowerCase())
                }).length} ${myTables.filter(e => {
                  if (isRealtime && e.type !== 'realtime') {
                    return false
                  }
                  return e?.name?.toLowerCase().includes(keyword.toLowerCase())
                }).length > 1 ? 'tables' : 'table'} `
              }


            </div>
          </div>
          {
            selectedChain === -1 && (myTables.length > 0 ? myTables.filter(e => {
              if (isRealtime) {
                return e.type === 'realtime' && e.name?.toLowerCase().includes(keyword.toLowerCase())
              }
              return e?.name?.toLowerCase().includes(keyword.toLowerCase())
            }).map((table, index) => {
              return <div
                onClick={async () => {
                  if (selectedTable === index) {
                    setSelectedTable(null)
                  } else {
                    track('Catalog - Table Clicked', {
                      table: table?.name,
                      database: table?.database,
                      chains: table?.chains
                    })
                    setSelectedTable(index)
                    if (!viewMode) {
                      await fetchAndSetTableDetail(table.id)
                    }
                  }

                  if (publicVisible) {
                    navigate(`/v2/public/tables/${table.id}`)
                    return
                  }
                  if (viewMode) {
                    navigate(`/v2/explore/tables/${table.id}`)
                  }
                }}
                className={
                  classNames("group relative pl-[40px] pr-[10px] py-[10px] flex items-center justify-between cursor-pointer rounded-[4px] hover:bg-[#F2F2F2]", {
                    "bg-[#F2F2F2]": selectedTable === index
                  })
                }
                key={`${index}-}`}
              >
                <div className="flex items-center gap-[5px]">
                  <Icon iconName="table" className="w-[14px] h-[14px]" />
                  <div
                    title={table.name}
                    className="text-ellipsis overflow-hidden 
            max-w-[200px] group-hover:max-w-[160px] text-[#2D2F34] text-[12px] leading-[14px] font-[500]">
                    {table.name}
                  </div>
                </div>
                <div>
                  {
                    table.type === 'realtime' ? <Icon iconName="rti" className="w-[14px] h-[14px] group-hover:hidden" /> : null
                  }
                </div>

                {!viewMode && <div className='absolute right-0 px-[4px] w-[70px] h-[14px] hidden group-hover:flex gap-[10px] items-center'>
                  <div onClick={async (e) => {
                    e.stopPropagation()
                    setQueryRunErrorMessage('')

                    setIsLoadingPreview(true)
                    await runPreviewData({
                      queryId: PreviewQueryResultId,
                      selectedDB: table.database,
                      schema: table?.database,
                      table: table?.name,
                    });
                    setIsLoadingPreview(false)
                  }}>
                    {isLoadingPreview ? <LoadingIndicator radius={12} /> : <Icon
                      iconName='preview'
                      className='min-w-[12px] w-[12px] h-[12px] cursor-pointer'
                    />}
                  </div>
                  <div onClick={(e) => {
                    e.stopPropagation()
                    handleInject(`${table?.database}.${table?.name}`);
                  }}>
                    <Icon
                      iconName='arrow-right'
                      className='min-w-[12px] w-[12px] h-[12px] cursor-pointer'
                    />
                  </div>
                  <div onClick={(e) => {
                    e.stopPropagation()
                    handleFormat(table)
                  }} >

                    {isLoadingTable ? <LoadingIndicator radius={12} /> : <Icon
                      iconName='plus'
                      className='min-w-[12px] w-[12px] h-[12px] cursor-pointer'
                    />}
                  </div>

                </div>}

              </div>
            }) : <div className="px-[40px] py-[10px] text-[12px] leading-[12px] text-[#2D2F34]">No tables found</div>)
          }
        </>
      }

      {
        priceTables.filter(e => {
          if (isRealtime && e.type !== 'realtime') {
            return false
          }
          return e?.name?.toLowerCase().includes(keyword.toLowerCase())
        }).length > 0 && <div
          onClick={() => {
            if (selectedChain === -2) {
              setSelectedChain(null)
            } else {
              setSelectedChain(-2)
            }
            setSelectedTable(null)
          }}
          className={
            classNames("cursor-pointer rounded-[4px] px-[10px] py-[8px] flex items-center gap-[5px] mb-[5px] justify-between", {
              // "bg-[#F2F2F2]": selectedChain === index
            })
          }
        >
          <div className="flex items-center gap-[5px]">
            <Icon
              iconName="dollar2"
              className="w-[18px] h-[18px]"
            />
            <div className="text-[#2D2F34] text-[12px] leading-[12px] font-[600]">
              Price
            </div>
          </div>
          <div className="p-[5px] border rounded-[4px] text-[10px] leading-[10px] text-[#263DFF] bg-[#EDF0FF]" >
            {
              `${priceTables.filter(e => {
                if (isRealtime && e.type !== 'realtime') {
                  return false
                }
                return e?.name?.toLowerCase().includes(keyword.toLowerCase())
              }).length} ${priceTables.filter(e => {
                if (isRealtime && e.type !== 'realtime') {
                  return false
                }
                return e?.name?.toLowerCase().includes(keyword.toLowerCase())
              }).length > 1 ? 'tables' : 'table'} `
            }
          </div>
        </div>}
      {
        selectedChain === -2 && priceTables.filter(e => {
          if (isRealtime && e.type !== 'realtime') {
            return false
          }
          return e?.name?.toLowerCase().includes(keyword.toLowerCase())
        }).map((table, index) => {
          return <div
            onClick={async () => {
              if (selectedTable === index) {
                setSelectedTable(null)
              } else {
                track('Catalog - Table Clicked', {
                  table: table?.name,
                  database: table?.database,
                  chains: table?.chains
                })
                setSelectedTable(index)
                if (!viewMode) {
                  await fetchAndSetTableDetail(table.id)
                }
              }

              if (publicVisible) {
                navigate(`/v2/public/tables/${table.id}`)
                return
              }
              if (viewMode) {
                navigate(`/v2/explore/tables/${table.id}`)
              }
            }}
            className={
              classNames("group relative pl-[40px] pr-[10px] py-[10px] flex items-center justify-between cursor-pointer rounded-[4px] hover:bg-[#F2F2F2]", {
                "bg-[#F2F2F2]": selectedTable === index
              })
            }
            key={`${index}-}`}
          >
            <div className="flex items-center gap-[5px]">
              <Icon iconName="table" className="w-[14px] h-[14px]" />
              <div
                title={table.name}
                className="text-ellipsis overflow-hidden 
            max-w-[200px] group-hover:max-w-[160px] text-[#2D2F34] text-[12px] leading-[14px] font-[500]">
                {table.name}
              </div>
            </div>
            <div>
              {
                table.type === 'realtime' ? <Icon iconName="rti" className="w-[14px] h-[14px] group-hover:hidden" /> : null
              }
            </div>

            {!viewMode && <div className='absolute right-0 px-[4px] w-[70px] h-[14px] hidden group-hover:flex gap-[10px] items-center'>
              <div onClick={async (e) => {
                e.stopPropagation()
                setQueryRunErrorMessage('')

                setIsLoadingPreview(true)
                await runPreviewData({
                  queryId: PreviewQueryResultId,
                  selectedDB: table.database,
                  schema: table?.database,
                  table: table?.name,
                });
                setIsLoadingPreview(false)
              }}>
                {isLoadingPreview ? <LoadingIndicator radius={12} /> : <Icon
                  iconName='preview'
                  className='min-w-[12px] w-[12px] h-[12px] cursor-pointer'
                />}
              </div>
              <div onClick={(e) => {
                e.stopPropagation()
                handleInject(`${table?.database}.${table?.name}`);
              }}>
                <Icon
                  iconName='arrow-right'
                  className='w-[12px] h-[12px] cursor-pointer'
                />
              </div>
              <div onClick={(e) => {
                e.stopPropagation()

                handleFormat(table)
              }} >
                {isLoadingTable ? <LoadingIndicator radius={12} /> : <Icon
                  iconName='plus'
                  className='min-w-[12px] w-[12px] h-[12px] cursor-pointer'
                />}
              </div>

            </div>}

          </div>
        })
      }

      {
        filteredCatalog?.map((item: {
          name: string,
          children: {
            name: string,
            children: PoTableMetadata[]
          }[]

        }, index: number) => {
          const getTableCount = (children: any) => {
            return children.reduce((acc: number, curr: any) => {
              return acc + curr.children.length
            }, 0)
          }
          const tableCount = getTableCount(item.children)
          return <>
            <div
              onClick={() => {
                if (selectedChain === index) {
                  setSelectedChain(null)
                } else {
                  setSelectedChain(index)
                }
                setSelectedLabel(null)
                setSelectedTable(null)
              }}
              className={
                classNames("cursor-pointer rounded-[4px] px-[10px] py-[8px] flex items-center justify-between mb-[5px]", {
                  // "bg-[#F2F2F2]": selectedChain === index
                })
              }
              key={index}
            >
              <div className="flex items-center gap-[5px]">
                <Icon
                  iconName={item.name.endsWith('Data') ? 'ztblicon' : item.name}
                  className="w-[18px] h-[18px]"
                />
                <div className="text-[#2D2F34] text-[12px] leading-[12px] font-[600]">
                  {item.name}
                </div>
              </div>
              <div className="p-[5px] border rounded-[4px] text-[10px] leading-[10px] text-[#263DFF] bg-[#EDF0FF]" >
                {
                  `${tableCount} ${tableCount > 1 ? 'tables' : 'table'} `
                }
              </div>
            </div>
            {
              selectedChain === index && item.children.map((child, i) => {
                const tableCountSecond = child.children.length > 1 ? `${child.children.length} tables` : `${child.children.length} table`
                return <>
                  <div
                    onClick={() => {
                      if (selectedLabel === i) {
                        setSelectedLabel(null)
                      } else {
                        setSelectedLabel(i)
                      }
                      setSelectedTable(null)
                    }}
                    className={classNames("mb-[5px] px-[20px] pr-[10px] py-[10px] flex justify-between items-center gap-[5px] cursor-pointer rounded-[4px]", {
                      // "bg-[#F2F2F2]": selectedLabel === i
                    })}
                    key={`${index}-${i}`}
                  >
                    <div className="flex items-center gap-[5px]">
                      <Icon
                        iconName={
                          selectedLabel === i ? "down" : "right"
                        }
                        className="w-[14px] h-[14px]"
                      />
                      <div className="text-[#2D2F34] text-[12px] leading-[12px] font-[500]">
                        {child.name}
                      </div>
                    </div>
                    <div className="p-[5px] border rounded-[4px] text-[10px] leading-[10px] text-[#263DFF] bg-[#EDF0FF]" >
                      {tableCountSecond}
                    </div>
                  </div>
                  {
                    selectedChain === index && selectedLabel === i && <div className="">
                      {
                        item.children[selectedLabel].children.map((table, j) => {
                          return <div
                            onClick={async () => {
                              if (selectedTable === j) {
                                setSelectedTable(null)
                              } else {
                                track('Catalog - Table Clicked', {
                                  table: table?.name,
                                  database: table?.database,
                                  chains: table?.chains
                                })
                                setSelectedTable(j)
                                if (!viewMode) {
                                  await fetchAndSetTableDetail(table.id)
                                }
                              }
                              if (publicVisible) {
                                navigate(`/v2/public/tables/${table.id}`)
                                return
                              }
                              if (viewMode) {
                                navigate(`/v2/explore/tables/${table.id}`)
                              }
                            }}
                            className={
                              classNames("group relative pl-[40px] pr-[10px] py-[10px] flex items-center justify-between cursor-pointer rounded-[4px] hover:bg-[#F2F2F2]", {
                                "bg-[#F2F2F2]": selectedTable === j
                              })
                            }
                            key={`${index}-${i}-${j}`}
                          >
                            <div className="flex items-center gap-[5px]">
                              <Icon iconName="table" className="w-[14px] h-[14px]" />
                              <div
                                title={table.name}
                                className="text-ellipsis overflow-hidden 
                              max-w-[200px] group-hover:max-w-[160px] text-[#2D2F34] text-[12px] leading-[14px] font-[500]">
                                {table.name}
                              </div>
                            </div>
                            <div>
                              {
                                table.type === 'realtime' ? <Icon iconName="rti" className="w-[14px] h-[14px] group-hover:hidden" /> : null
                              }
                            </div>

                            {!viewMode && <div className='absolute right-0 px-[4px] w-[70px] h-[14px] hidden group-hover:flex gap-[10px] items-center'>
                              <div onClick={async (e) => {
                                e.stopPropagation()
                                setQueryRunErrorMessage('')

                                setIsLoadingPreview(true)
                                await runPreviewData({
                                  queryId: PreviewQueryResultId,
                                  selectedDB: table.database,
                                  schema: table?.database,
                                  table: table?.name,
                                });
                                setIsLoadingPreview(false)
                                track('Table Preview Clicked', {
                                  table: table?.name,
                                  schema: table?.database
                                })
                              }}
                                className='min-w-[12px] w-[12px] h-[12px] cursor-pointer'
                              >
                                {isLoadingPreview ? <LoadingIndicator radius={12} /> : <Icon
                                  iconName='preview'
                                  className='min-w-[12px] w-[12px] h-[12px] cursor-pointer'
                                />}
                              </div>
                              <div onClick={(e) => {
                                e.stopPropagation()
                                track('Table Name Inserted', {
                                  table: table?.name,
                                  schema: table?.database
                                })
                                handleInject(`${table?.database}.${table?.name}`);
                              }}
                                className='min-w-[12px] w-[12px] h-[12px] cursor-pointer'
                              >
                                <Icon
                                  iconName='arrow-right'
                                  className='min-w-[12px] w-[12px] h-[12px] cursor-pointer'
                                />
                              </div>
                              <div onClick={(e) => {
                                e.stopPropagation()
                                track('Table Full SQL Inserted', {
                                  table: table?.name,
                                  schema: table?.database
                                })
                                handleFormat(table)
                              }}
                                className='min-w-[12px] w-[12px] h-[12px] cursor-pointer' >
                                {isLoadingTable ? <LoadingIndicator radius={12} /> : <Icon
                                  iconName='plus'
                                  className='min-w-[12px] w-[12px] h-[12px] cursor-pointer'
                                />}
                              </div>

                            </div>}

                          </div>
                        })
                      }
                    </div>
                  }
                </>
              })
            }

          </>
        })
      }
    </div>

    {
      !viewMode && selectedTable !== null && <div
        style={{
          height: panelHeight + dragHeightValidated,
        }}
        className="sticky py-[10px] w-[300px] left-[0] h-[300px] border-t bottom-0 bg-[#fff] overflow-hidden">
        <div className="border-b flex items-center justify-between px-[10px] pb-[10px] mb-[10px] cursor-grab"
          ref={refPanelHandle}

        >
          <div className="text-[12px] px-[10px] font-semibold">
            {tableDetail.table?.name}
          </div>
          <div className="cursor-pointer" onClick={() => {
            setSelectedTable(null)
          }}>
            <Icon iconName="close" className="w-[14px] h-[14px]" />
          </div>
        </div>
        {
          isTableDetailLoading ? <div className="flex items-center h-[264px] justify-center">
            <LoadingIndicator />
          </div> : <div className="h-[264px] overflow-auto">
            {
              tableDetail.column?.map((item: any, index: number) => {
                return <div key={index} className='mx-[10px] hover:bg-[#f2f2f2] flex items-center justify-between mb-[5px] px-[10px] py-[7px] rounded cursor-pointer' onClick={() => {
                  track('Table Column Inserted', {
                    table: tableDetail.table?.name,
                    schema: tableDetail.table?.database,
                    column: item.name
                  })
                  handleInject(item.name)
                }}>
                  <div className='text-[12px] text-[#2D2F34]'>
                    {item.name}
                  </div>
                  <div className='text-[10px] text-[777A80] p-[5px] rounded bg-[#f2f2f2] h-[20px] leading-[10px]'>
                    {item.type}
                  </div>
                </div>
              })
            }
            <div className="pb-[20px]"></div>
          </div>
        }

      </div>
    }
  </div>
}

export default CatalogV2;