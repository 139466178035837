import { Route, Routes, useLocation } from 'react-router';

import './row.css';
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import ExplorerNavbar from './components/ExplorerNavbar';
import Apis from './ApiListPage';
import DashboardListPage from './DashboardListPage';
import QueryListPage from './QueryListPage';
import CreateSection from './components/CreateSection';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import DataSourcesListPageV3 from './DataSourcesListPageV3';
import RealtimeAPI from '../realtime-api/RealtimeAPI';
import { useMemo } from 'react';

export default function Explorer() {
  const [{ isMyWorkspace }] = useWorkspace();
  const path = useLocation();
  const { pathname } = path;

  const displayCreateSection = useMemo(() => {
    return isMyWorkspace && pathname !== '/my-workspace/realtime-api'
  }, [isMyWorkspace, pathname])

  return (
    <LayoutBodyCard className='lg:basis-2/5 basis-full'>
      <ExplorerNavbar />
      {displayCreateSection && <CreateSection />}
      <Routes>
        <Route path='queries' element={<QueryListPage />} />
        <Route path='apis' element={<Apis />} />
        <Route path='dashboards' element={<DashboardListPage />} />
        <Route path='data-sources' element={<DataSourcesListPageV3 />} />
        <Route path='realtime-api' element={<RealtimeAPI />}></Route>
      </Routes>
    </LayoutBodyCard>
  );
}
