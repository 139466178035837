/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelDeleteImageResponse,
  ModelGetImageResponse,
  ModelGetImageTransformationsResponse,
  ModelListImageProjectsResponse,
  ModelListImagesResponse,
  ModelUpdateImageRequest,
  ModelUpdateImageResponse,
  ModelUploadImageResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Image<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description upload image in the staging folder.
   *
   * @tags image
   * @name ImageCreate
   * @summary upload image in the staging folder
   * @request POST:/image
   */
  imageCreate = (data: { file: File }, params: RequestParams = {}) =>
    this.request<ModelUploadImageResponse, any>({
      path: `/image`,
      method: 'POST',
      body: data,
      type: ContentType.FormData,
      format: 'json',
      ...params,
    });
  /**
   * @description list images in the project
   *
   * @tags image
   * @name ProjectDetail
   * @summary list images in the project
   * @request GET:/image/project/{project}
   */
  projectDetail = (project: string, params: RequestParams = {}) =>
    this.request<ModelListImagesResponse, any>({
      path: `/image/project/${project}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description list image projects
   *
   * @tags image
   * @name ProjectsList
   * @summary list image projects
   * @request GET:/image/projects
   */
  projectsList = (params: RequestParams = {}) =>
    this.request<ModelListImageProjectsResponse, any>({
      path: `/image/projects`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get image transformations
   *
   * @tags image
   * @name TransformationsList
   * @summary get image transformations
   * @request GET:/image/transformations
   */
  transformationsList = (params: RequestParams = {}) =>
    this.request<ModelGetImageTransformationsResponse, any>({
      path: `/image/transformations`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get image
   *
   * @tags image
   * @name ImageDetail
   * @summary get image
   * @request GET:/image/{id}
   */
  imageDetail = (id: string, params: RequestParams = {}) =>
    this.request<ModelGetImageResponse, any>({
      path: `/image/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description update image
   *
   * @tags image
   * @name ImageCreate2
   * @summary update image
   * @request POST:/image/{id}
   * @originalName imageCreate
   * @duplicate
   */
  imageCreate2 = (
    id: string,
    request: ModelUpdateImageRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelUpdateImageResponse, any>({
      path: `/image/${id}`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description delete image
   *
   * @tags image
   * @name ImageDelete
   * @summary delete image
   * @request DELETE:/image/{id}
   */
  imageDelete = (id: string, params: RequestParams = {}) =>
    this.request<ModelDeleteImageResponse, any>({
      path: `/image/${id}`,
      method: 'DELETE',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
