import classNames from 'classnames';
import { InputHTMLAttributes, memo } from 'react';

function Input({
  noOutline,
  className,
  ...props
}: { noOutline?: boolean } & InputHTMLAttributes<HTMLInputElement>) {
  return (
    <input
      type='text'
      placeholder='Search...'
      className={classNames(
        'input',
        {
          'active:outline-none focus:outline-none': noOutline,
        },
        className
      )}
      {...props}
    />
  );
}

export default memo(Input);
