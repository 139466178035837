/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { getApisByProjectId } from '../workspace/apis'
import './OverlapAvatar.scss'

const OverlapAvatar = ({
  apiId,
}: any) => {
  const [avatarList, setAvatarList] = useState<string[]>([])
  const getAndSetAvatarList = async () => {
    if (!apiId) return

    const data = await getApisByProjectId(apiId)
    if (data) {
      setAvatarList(data?.map(e => e?.image || '') || [])
    }
  }

  useEffect(() => {
    getAndSetAvatarList()
  }, [apiId])

  return <ul className="overlap-avatar">
    {
      avatarList.map((avatar: any, index: number) => {
        return <li><img width={40} height={40} style={{
          objectFit: 'cover',
          width: '40px',
          height: '40px',
        }} src={avatar || `https://ui-avatars.com/api/?name=${index}`} /></li>

      })
    }
  </ul>
}

export default OverlapAvatar