/* eslint-disable @typescript-eslint/no-explicit-any */
import { Input } from "antd"
import { typeConvert } from "./BodyParams"

const HeaderParams = ({
  headerParams,
  setData,
  values
}: {
  headerParams: any,
  setData: any,
  values: any
}) => {
  return <div className="">
    <div className="font-semibold mb-1 text-[14px]">
      Header Params
    </div>
    <div className="border bg-[#fafafa] p-3 rounded ">
      {
        headerParams.map((param, index) => {
          return <div className="flex justify-between mb-3 gap-3" key={index}>
            <div >
              <div className="flex justify-between">
                <div className="flex gap-2 items-center text-sm">
                  <span className="font-semibold text-[16px]  max-w-[140px]  2xl:max-w-[400px] text-ellipsis overflow-hidden" title={param.name}>{param.name}</span>
                  <span className="text-[#B8BABD] text-[14px]">{param.type}</span>
                  <span className="text-[#FF5050] text-[16px]">{param.required ? 'required' : ''}</span>
                </div>
              </div>
              <div>
                <div className="text-[14px] text-[#777A80] 2xl:max-w-[400px] max-w-[250px]">
                  {
                    param.description
                  }
                </div>
              </div>
            </div>

            <div>
              <Input
                value={values?.[param.name] || ''}
                type={param.type === 'boolean' ? 'checkbox' : 'text'}
                onChange={(e) => {
                  if (param.type === 'boolean') {
                    setData('header', {
                      [param.name]: e.target.checked
                    })
                    return
                  }
                  setData('header', {
                    [param.name]: typeConvert(param.type, e.target.value)
                  })
                }} />
            </div>
          </div>
        })
      }
    </div>
  </div>
}

export default HeaderParams