import { Link } from 'react-router-dom';
import { IoMdKey, IoIosCopy } from 'react-icons/io';
import CopyToClipboard from 'react-copy-to-clipboard';

import { useWorkspace } from '../../layouts/components/workspace-hooks';
import { convertNumberToFormatted, DateFormat } from '../../utils/date';
import ProfileAvatar from '../auth/ProfileAvatar';
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import BodyHeader3 from '../../components/typographic/BodyHeader3';
import { HorizontalLine } from '../../components/HorizontalLine';
import { isEmpty } from 'lodash';
import { useApiKeys } from './api-keys-hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import LogoBlinkerCenter from '../../components/app/LogoBlinkerCenter';
import { toast } from 'react-toastify';
import { DefaultAutoDismissMs } from '../../config/toast';
import { formatNum } from '../../utils/format';
import { AuthState } from '../auth/AuthState';
import { useRecoilState } from 'recoil';
import { AiFillQuestionCircle } from 'react-icons/ai';

const MONTHS = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"]

export default function ApiKeysPage() {
  const [{ menu }, { getPath }] = useWorkspace();
  const initialLoadRef = useRef(['Past1', 'Past2', "Past3"])

  const [{ apiKeys, rpcStatus }, { fetchApiKeys }] = useApiKeys();

  const [auth] = useRecoilState(AuthState);

  const [billingCycle, setBillingCycle] = useState<number>(0)

  useEffect(() => {
    fetchApiKeys(billingCycle);
  }, [billingCycle]);

  const onBillingCycleChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    setBillingCycle(parseInt(event.target.value, 10))
  }

  const computeUsed =
    apiKeys.keys?.reduce((p, i) => p + (i.apiComputeUnitsLastMonth || 0), 0) ||
    0;

  const computeUnits =
    apiKeys.keys?.reduce(
      (p, i) => p + (i.analyticsComputeUnitsLastMonth || 0),
      0
    ) || 0;

  const dateRangeString = useMemo(() => {
    if (apiKeys) {
      return <span>Usage during <span className='font-semibold text-md'>{`${apiKeys.dateRange?.startDate} - ${apiKeys.dateRange?.endDate}`}</span></span>
    }
    return <span>Usage during <span className='font-semibold text-md'>Current Billing Cycle</span></span>
  }, [apiKeys])

  const optionGroups = useMemo(() => {
    if (apiKeys.dateRange?.startDate && initialLoadRef.current[0] === 'Past1') {
      const curMonth = parseInt(apiKeys.dateRange.startDate.split('-')[1], 10)
      const past1 = (curMonth - 2 + 12) % 12
      const past2 = (curMonth - 3 + 12) % 12
      const past3 = (curMonth - 4 + 12) % 12
      const past4 = (curMonth - 5 + 12) % 12
      const past5 = (curMonth - 6 + 12) % 12
      const past6 = (curMonth - 7 + 12) % 12

      initialLoadRef.current = [MONTHS[past1], MONTHS[past2], MONTHS[past3], MONTHS[past4], MONTHS[past5], MONTHS[past6]]
    }
    return initialLoadRef.current
  }, [apiKeys])

  const totalComputeUnits = useMemo(() => {
    // const cuUsed = auth?.subscription?.cuUsed || 0;
    // if (computeUsed + computeUnits >= 0) {
    return formatNum(computeUsed + computeUnits);
    // }

    // return formatNum(cuUsed);
  }, [computeUsed, computeUnits, auth]);

  if (rpcStatus.isLoading) {
    return (
      <LayoutBodyCard className='p-40'>
        <LogoBlinkerCenter />
      </LayoutBodyCard>
    );
  }

  return (
    <LayoutBodyCard>
      <div className='flex px-6 py-3 items-center justify-between'>
        <BodyHeader3>
          <div className='flex gap-2 items-center'>
            <IoMdKey className='inline' size='1.4rem' /> Compute Units Usage
            <div className="tooltip" data-tip="The visibility of usage data changes might require a span of up to 1 day to become apparent."><AiFillQuestionCircle className='inline' size='1.4rem' /></div>
          </div>
        </BodyHeader3>
        <div>
          <select disabled={!apiKeys} value={billingCycle} onChange={onBillingCycleChange} className="select select-bordered w-full max-w-xs">
            <option value={0}>Current Billing Cycle</option>
            <option value={-1}>{optionGroups[0]} Billing Cycle</option>
            <option value={-2}>{optionGroups[1]} Billing Cycle</option>
            <option value={-3}>{optionGroups[2]} Billing Cycle</option>
            <option value={-4}>{optionGroups[3]} Billing Cycle</option>
            <option value={-5}>{optionGroups[4]} Billing Cycle</option>
            <option value={-6}>{optionGroups[5]} Billing Cycle</option>
          </select>
        </div>
      </div>
      <HorizontalLine />
      <div className='flex px-6 py-3 items-start justify-between h-full'>
        <div className='flex flex-col gap-3'>
          <div className='text-2xl font-light uppercase'>
            {
              dateRangeString
            }
          </div>
          <div className='flex gap-7'>
            <div className='flex flex-col gap-3'>
              <div className='text-5xl font-light'>{totalComputeUnits || 0}</div>
              <div className='text-xs opacity-50'>TOTAL COMPUTE UNITS USED</div>
            </div>
            <div className='divider-y' />
            <div className='flex flex-col gap-3'>
              <div className='text-5xl font-light'>
                {formatNum(auth?.subscription?.cuLimit || 0)}
              </div>
              <div className='text-xs opacity-50'>PREPAID COMPUTE UNITS</div>
            </div>
          </div>
          <div className='h-1' />
        </div>
        <div className='flex justify-start py-3'>
          {/* <Link to='new-api'>
            <button className='btn btn-primary'>Create API key</button>
          </Link> */}
        </div>
      </div>
      <HorizontalLine />
      <div className='flex flex-col w-full p-3 overflow-x-auto'>
        <table className='table w-full'>
          <thead className='rounded-full w-full'>
            <tr className='w-full font-semibold opacity-30'>
              <th className='p-2 w-40'>OWNER</th>
              <th className='p-2 w-40 text-center'>QUERIES</th>
              <th className='p-2 w-40 text-center'>API CALLS</th>
              <th className='p-2'>KEY NAME</th>
              <th className='p-2'>API KEY</th>
              <th className='p-2 w-40 text-center'>CREATED TIME</th>
            </tr>
          </thead>
          <tbody className='rounded-full w-full'>
            {(!apiKeys || isEmpty(apiKeys.keys)) && (
              <td colSpan={1000} className='text-center opacity-30 p-20'>
                No API keys
              </td>
            )}
            {apiKeys.keys?.map(
              ({
                key,
                owner,
                name,
                analyticsQueriesLastMonth,
                apiCallsLastMonth,
                createdTime,
              }) => (
                <tr
                  key={key}
                  className='font-semibold items-center overflow-hidden'
                >
                  <td className='p-2 overflow-hidden'>
                    <Link key={key} to={`${getPath(menu)}/${key}`}>
                      <div className='flex flex-auto w-[9rem] justify-start items-center gap-3'>
                        <ProfileAvatar
                          creator={owner}
                          className='flex-none w-6 bg-base-content bg-opacity-30'
                        />
                        <span
                          title={owner?.name || 'API Owner'}
                          className='flex-auto overflow-hidden whitespace-nowrap text-ellipsis opacity-60'
                        >
                          {owner?.name || 'API Owner'}
                        </span>
                      </div>
                    </Link>
                  </td>

                  <td className='p-2 overflow-hidden'>
                    <Link key={key} to={`${getPath(menu)}/${key}`}>
                      <div className='text-center w-full opacity-60'>
                        {analyticsQueriesLastMonth}
                      </div>
                    </Link>
                  </td>
                  <td className='p-2 overflow-hidden'>
                    <Link key={key} to={`${getPath(menu)}/${key}`}>
                      <div className='text-center w-full opacity-60'>
                        {apiCallsLastMonth?.toLocaleString()}
                      </div>
                    </Link>
                  </td>
                  <td className='p-2 overflow-hidden'>
                    <Link key={key} to={`${getPath(menu)}/${key}`}>
                      <div className='flex text-lg space-x-2 pr-2 items-center'>
                        <IoMdKey
                          className='inline text-primary'
                          size='1.4rem'
                        />
                        <span
                          title={name}
                          className='overflow-hidden whitespace-nowrap text-ellipsis'
                        >
                          {name || key}
                        </span>

                        <div className='bg-base-300 rounded-full flex items-center justify-center text-xs px-2 py-0'>
                          <span className='opacity-60'>default</span>
                        </div>
                        <div className='flex-auto' />
                      </div>
                    </Link>
                  </td>
                  <td className='p-2'>
                    <div className='flex flex-auto items-center text-lg space-x-2 pr-2'>
                      <Link key={key} to={`${getPath(menu)}/${key}`}>
                        <span title={key} className='opacity-60'>
                          {`${key?.slice(0, 6)}...${key?.slice(-6)}`}
                        </span>
                      </Link>
                      <CopyToClipboard text={`${key}`}>
                        <div
                          className='bg-base-300 rounded-full w-6 h-6 flex items-center justify-center cursor-pointer'
                          onClick={() => {
                            toast.success('Link copied', {
                              autoClose: DefaultAutoDismissMs,
                            });
                          }}
                        >
                          <IoIosCopy size='0.8rem' className='opacity-60' />
                        </div>
                      </CopyToClipboard>
                    </div>
                  </td>
                  <td className='p-2 w-8 opacity-50'>
                    <Link key={key} to={`${getPath(menu)}/${key}`}>
                      <div className='text-center w-full'>
                        {convertNumberToFormatted(createdTime, {
                          formatStr: DateFormat,
                        })}
                      </div>
                    </Link>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </LayoutBodyCard>
  );
}