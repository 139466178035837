/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import fileIcon from './cloud.svg';
import folderIcon from './folder-1.svg';
import { IoIosMore, IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { Button, Dropdown, Form, Input, Modal, Select, Table } from 'antd';
import { BiEdit, BiMove } from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import {
  createProject,
  deleteApi,
  deleteProject,
  getProjects,
  moveApi,
  updateProjectMetadata,
} from './apis';
import { composeApiDataSource, composeGqlApis } from './utils';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import addProjectIcon from './addp.svg';
import useAuth from '../../hooks/auth';
import Icon from '../components/Icon';
import { postDeleteApi } from '../../modules/api/api-rpc';
import { track } from '../tracking';
import LoadingIndicator from '../../components/LoadingIndicator';
import { AxiosError } from 'axios';

const STATE_COLORS = {
  Ready: '#18AA00',
  Paused: '#FF9A16',
  Error: '#FF5050',
};
const ApiWorkspace = () => {
  const [dataSource, setDataSource] = useState<any>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showAddRow, setShowAddRow] = useState(false);

  const [newName, setNewName] = useState('');

  const [appProjects, setAllProjects] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchAndSetDataSource = async () => {
    setIsLoading(true);
    const apis = await composeApiDataSource();

    const apiProjects = (await getProjects('api')).data.projects || [];
    setAllProjects(apiProjects.map((e) => ({ label: e.name, value: e.id })));

    setDataSource(apis);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAndSetDataSource();
  }, []);

  const onAddRow = () => {
    setShowAddRow(true);
  };
  const [projectName, setProjectName] = useState('');
  const [selectedApi, setSelectedApi] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [apiType, setApiType] = useState('');
  const [editField, setEditField] = useState('');
  const [{ auth }] = useAuth();

  const renderFooter = () => {
    return (
      <div>
        {showAddRow && (
          <div>
            <input
              className='input w-full my-[10px]'
              placeholder='Enter Project Name'
              onChange={(e) => setProjectName(e.target.value)}
            />
            <div className='flex justify-end mt-2 gap-2'>
              <button
                className='btn btn-primary'
                onClick={async () => {
                  const res = await createProject('api', {
                    name: projectName,
                  });
                  track('API - Create Project', {});

                  toast.success('created a new API Project!', {
                    autoClose: 2000,
                    position: 'top-center',
                  });
                  setDataSource((prev: any) => [
                    prev[0],
                    {
                      ...(res.data as any).project,
                      key: (res.data as any).project.id,
                      type: 'folder',
                      children: [],
                    },
                    ...prev.slice(1),
                  ]);
                  setShowAddRow(false);
                }}
              >
                Save
              </button>
              <button
                className='btn btn-ghost'
                onClick={() => {
                  setShowAddRow(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {!showAddRow && (
          <div className='w-full text-left  border-b py-[8px]'>
            <button
              onClick={() => {
                onAddRow();
              }}
              className='btn btn-ghost text-left w-full justify-start text-[14px] text-[#777A80] font-normal'
            >
              <img
                width={14}
                src={addProjectIcon}
                className='mr-[10px] ml-[52px]'
              />
              Add API Project
            </button>
          </div>
        )}
      </div>
    );
  };
  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'name',
      width: '350px',
      key: 'name',
      sorter: (a: any, b: any) => a.name?.localeCompare(b.name),
      render: (text: any, record: any) => {
        const isProject = record.children;
        return (
          <div
            className={classNames('flex gap-2 items-center', {
              'pl-7': !isProject,
            })}
          >
            {isProject ? (
              <Link
                onClick={() => {
                  track('API - Clicked Project', {
                    projectId: record.id,
                    fromV2: true,
                  });
                }}
                className='flex gap-2 items-center'
                to={`/v2/workspace/apis/${record.id}/overview?from=workspace`}
              >
                <img src={folderIcon} width={14} />
                {text}
              </Link>
            ) : (
              <Link
                onClick={() => {
                  track('API - Clicked API', {
                    apiId: record.id,
                    fromV2: true,
                    type: record.type === 'graphql' ? 'GraphQL' : 'REST',
                  });
                }}
                className='flex gap-2 items-center'
                to={
                  record.type === 'graphql'
                    ? `/v2/workspace/graphql-apis/${record.id}/overview?from=workspace`
                    : `/v2/workspace/apis/${record.project_id}/${record.id}?from=workspace`
                }
              >
                {record.type === 'graphql' ? (
                  <Icon iconName='download 1' />
                ) : (
                  <img src={fileIcon} width={14} />
                )}
                <div className='flex items-center gap-[10px]'>
                  <div className='line-clamp-1'>{record.display_name}</div>
                  <div className='min-w-[14px]'>
                    {record.result_db?.startsWith('realtime') ? (
                      <Icon className='w-[14px] h-[14px]' iconName='toggle' />
                    ) : null}
                  </div>
                </div>
              </Link>
            )}
          </div>
        );
      },
    },

    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      width: '200px',
    },
    {
      title: 'CREATED',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text: any) => {
        if (!text) return '';
        return new Date(text).toLocaleDateString();
      },
    },
    {
      title: 'STATE',
      dataIndex: 'state',
      key: 'state',
      width: 100,
      render: (text: any) => {
        if (!text) return '';
        if (text === 'KilledBySweeper') text = 'Error';
        return <b style={{ color: STATE_COLORS[text] }}>{text}</b>;
      },
    },
    {
      title: 'LAST REFRESH TIME',
      dataIndex: 'data_ingested_time',
      key: 'data_ingested_time',
      width: '250px',
      render: (text: any) => {
        if (!text) return '';
        return new Date(text).toLocaleString();
      },
    },
    {
      title: 'UPDATED',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: '250px',
      render: (text: any) => {
        if (!text) return '';
        return new Date(text).toLocaleDateString();
      },
    },
    {
      title: 'AUTHOR',
      dataIndex: ['owner', 'displayName'],
      key: 'tenant',
      width: '250px',
      render: (text: string, record: any) => {
        if (record.type === 'graphql') {
          return record.creator?.name ? record.creator?.name : 'Unknown';
        }
        return text;
      },
    },
    {
      title: 'ACTION',
      dataIndex: '',
      key: 'x',
      width: '70px',
      render: (text: string, record: any, dataIndex: number) => {
        if (record.name !== 'Default' && record.name !== 'GraphQL APIs') {
          return (
            <div className='cursor-pointer flex items-center justify-center'>
              <Dropdown
                trigger={['click']}
                menu={{
                  items: [
                    record.children
                      ? null
                      : {
                          key: '2',
                          label: (
                            <div
                              className='flex gap-2 items-center'
                              onClick={(e) => {
                                setIsEditModalOpen(true);
                                setSelectedApi(record.id);
                                setEditField('move');
                                setApiType(
                                  record.type === 'graphql' ? 'GraphQL' : 'REST'
                                );
                              }}
                            >
                              <BiMove /> Move to
                            </div>
                          ),
                        },
                    record.type !== 'graphql'
                      ? {
                          key: '1',
                          label: (
                            <div
                              className='flex gap-2 items-center'
                              onClick={(e) => {
                                setIsEditModalOpen(true);
                                if (record.type === 'folder') {
                                  setSelectedProject(record.id);
                                } else {
                                  setSelectedApi(record.id);
                                }
                                setEditField('name');
                              }}
                            >
                              <BiEdit /> Rename
                            </div>
                          ),
                        }
                      : null,
                    {
                      key: '3',
                      label: (
                        <div
                          className='flex gap-2 items-center text-[#ec5e57]'
                          onClick={async (e) => {
                            let action_result = false;
                            let resp;
                            let error_msg = `You can't delete this ${
                              record.type === 'folder' ? 'Project' : 'API'
                            }`;
                            const error_msg_403 = `You are not the owner to delete this ${
                              record.type === 'folder' ? 'Project' : 'API'
                            }`;
                            try {
                              if (record.type === 'folder') {
                                resp = await deleteProject(record.id);
                                setDataSource((prev) => {
                                  return prev.filter(
                                    (proj: any) => proj.id !== record.id
                                  );
                                });
                              } else if (record.type === 'graphql') {
                                resp = await postDeleteApi(record.id);
                              } else {
                                resp = await deleteApi(record.id);
                              }
                            } catch (error) {
                              // request error, like 400 error
                              action_result = false;
                              if (error instanceof AxiosError) {
                                if (
                                  error.response &&
                                  error.response.status === 403
                                ) {
                                  error_msg = error_msg_403;
                                }
                              }
                            }
                            if (resp && resp.status === 200) {
                              action_result = true;
                            }
                            if (resp) {
                              if (resp.status === 200) {
                                action_result = true;
                              } else if (resp.status === 403) {
                                error_msg = error_msg_403;
                              }
                            }

                            track(
                              `API - Delete ${
                                record.type === 'folder' ? 'Project' : 'API'
                              }`,
                              {
                                apiId: record.id,
                                fromV2: true,
                              }
                            );

                            if (action_result) {
                              await fetchAndSetDataSource();
                              toast.success(
                                `Successfuly deleted ${
                                  record.type === 'folder' ? 'Project' : 'API'
                                }`,
                                {
                                  autoClose: 2000,
                                  position: 'top-center',
                                }
                              );
                            } else {
                              toast.error(error_msg, {
                                autoClose: 2000,
                                position: 'top-center',
                              });
                            }
                          }}
                        >
                          <MdDelete /> Delete
                        </div>
                      ),
                    },
                  ],
                }}
              >
                <IoIosMore />
              </Dropdown>
            </div>
          );
        }
        return null;
      },
    },
  ];

  const [checkedRows, setCheckedRows] = useState<string[]>([]);

  return (
    <>
      <div
        className={classNames({
          hidden: checkedRows.length === 0,
        })}
      >
        <Button
          onClick={() => {
            setEditField('bulk');
            setIsEditModalOpen(true);
          }}
        >
          Bulk Move
        </Button>
      </div>

      <Table
        rowSelection={{
          hideSelectAll: true,
          type: 'checkbox',
          renderCell: (
            checked: any,
            record: any,
            index: any,
            originNode: any
          ) => {
            if (record.type !== 'folder') {
              return (
                <div className='flex justify-center'>
                  <input
                    type='checkbox'
                    checked={new Set(checkedRows).has(record.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setCheckedRows((prev) => [...prev, record.id]);
                      } else {
                        setCheckedRows((prev) => {
                          return prev.filter((a) => a !== record.id);
                        });
                      }
                    }}
                  />
                </div>
              );
            }
            return <input type='checkbox' disabled />;
          },
        }}
        expandable={{
          // eslint-disable-next-line react/no-unstable-nested-components
          expandIcon: ({ expanded, onExpand, record }: any) => {
            if (record.children) {
              return (
                <span
                  className='float-left mr-2 mt-1 cursor-pointer'
                  onClick={(e) => onExpand(record, e)}
                >
                  {expanded ? <IoIosArrowDown /> : <IoIosArrowForward />}
                </span>
              );
            }
            return null;
          },
        }}
        dataSource={
          auth?.tenant !== 'zettablock.com'
            ? dataSource.filter((e: any) => e.id !== 'default')
            : dataSource
        }
        columns={columns}
        footer={renderFooter}
        loading={isLoading}
        pagination={false}
        className='project-table'
      />
      <Modal
        open={isEditModalOpen}
        onOk={() => {
          setIsEditModalOpen(false);
        }}
        onCancel={() => {
          setIsEditModalOpen(false);
        }}
        footer={null}
      >
        <div className='px-4 pt-5 pb-2'>
          {editField === 'name' ? (
            <>
              <Form.Item label='Name'>
                <Input
                  onChange={(e) => {
                    setNewName(e.target.value);
                  }}
                />
              </Form.Item>
              <div className='mt-4 text-right'>
                <button
                  className='btn btn-primary'
                  onClick={async () => {
                    await updateProjectMetadata(selectedProject, {
                      name: newName,
                    });
                    setNewName('');
                    setIsEditModalOpen(false);
                    fetchAndSetDataSource();
                    toast.success('Successfuly updated project name', {
                      autoClose: 2000,
                      position: 'top-center',
                    });
                    track('API - Update Project Name', {
                      projectId: selectedProject,
                      fromV2: true,
                    });
                  }}
                >
                  Update Name
                </button>
              </div>
            </>
          ) : (
            <>
              <Select
                style={{ minWidth: '300px' }}
                placeholder='move to project'
                options={appProjects}
                onChange={(e) => {
                  setSelectedProject(e);
                }}
              />
              <div className='mt-4 text-right'>
                <button
                  className='btn btn-primary'
                  disabled={isLoading}
                  onClick={async () => {
                    setIsLoading(true);
                    if (editField === 'bulk') {
                      await moveApi(checkedRows, selectedProject, apiType);
                      setCheckedRows([]);
                    } else {
                      await moveApi([selectedApi], selectedProject, apiType);
                      setSelectedApi('');
                    }
                    setSelectedProject('');
                    setApiType('');

                    track('API - Move API', {
                      apiId: selectedApi,
                      projectId: selectedProject,
                      fromV2: true,
                    });

                    toast.success(
                      `Successfully moved API to project ${
                        appProjects.find((e) => e.value === selectedProject)
                          .label
                      }`,
                      {
                        autoClose: 2000,
                        position: 'top-center',
                      }
                    );
                    setIsLoading(false);
                    setIsEditModalOpen(false);
                    fetchAndSetDataSource();
                  }}
                >
                  Move {isLoading && <LoadingIndicator />}
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ApiWorkspace;
