/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
// import moment from 'moment';
import { HTMLAttributes, lazy, memo, Suspense } from 'react';
import LogoBlinkerCenter from '../../../components/app/LogoBlinkerCenter';
import { removeKeyRecursive } from '../../../utils/object';
import { QueryResultResult } from '../../queryBuilder/QueryState';
import { BarChartV2 } from '../types-v2';
import { connectEChartData } from '../utils/echart';

const EChartV2 = lazy(() => import('./EChartV2'));

interface Props {
  queryResults?: QueryResultResult;
  chart: BarChartV2;
  loading?: boolean;
}

function BarChart({
  queryResults,
  chart,
  loading,
}: HTMLAttributes<HTMLTableElement> & Props) {
  if (loading) {
    return <LogoBlinkerCenter />;
  }

  // =======
  // const containDate = (name?: string) => {
  //   if (!name) return false;

  //   return (
  //     name.toLowerCase().includes('date') || name.toLowerCase().includes('day')
  //   );
  // };

  interface RowData {
    [n: string]: string | number;
  }

  // const createEmptyData = (
  //   date: string,
  //   groupByVal: string,
  //   groupBy: string,
  //   row: RowData
  // ) => {
  //   const obj: RowData = {};
  //   const keys = Object.keys(row);

  //   keys.forEach((key) => {
  //     if (key === groupBy) {
  //       obj[key] = groupByVal;
  //     } else if (containDate(key)) {
  //       obj[key] = date;
  //     } else {
  //       obj[key] = typeof row[key] === 'string' ? '' : 0;
  //     }
  //   });

  //   // console.log({ obj });

  //   if (!obj) {
  //     console.log('undeasdasda');
  //   }
  //   return obj;
  // };

  const fillEmptyDataDates = (
    rows: Record<string, string | number>[] | undefined,
    xCol?: string,
    groupBy?: string
  ) => {
    if (!rows || rows.length === 0 || !groupBy || !xCol) return [];

    // const rowKeys = Object.keys(rows[0]);
    // const dateKey = rowKeys.find((e) => containDate(e));
    const groupByMap = new Map();
    const allValueSet = new Set();
    // rows.forEach((row) => {
    //   allValueSet.add(row[xCol]);
    //   return row[xCol];
    // });

    // console.log({ allXColValues, allValueSet });

    rows.forEach((row) => {
      const groupByKey = row[groupBy];
      const xColVal = row[xCol];
      // console.log({ xColVal });
      allValueSet.add(xColVal);
      if (!groupByMap.has(groupByKey)) {
        groupByMap.set(groupByKey, new Map());
      }

      groupByMap.get(groupByKey).set(xColVal, row);
    });

    const data: any = [];
    groupByMap.forEach((curValSet, groupByVal) => {
      // console.log({ groupByVal });
      // const curValSet = groupByMap.get(groupByVal);
      // console.log({ curValSet });
      const arr = Array.from(allValueSet).map((e) => {
        // console.log({ e });
        if (curValSet.get(e)) {
          return curValSet.get(e);
        }

        const obj: RowData = {};
        const keys = Object.keys(rows[0]);

        keys.forEach((key) => {
          if (key === groupBy) {
            obj[key] = groupByVal;
          } else {
            obj[key] = typeof rows[0][key] === 'string' ? '' : 0;
          }
        });
        return {
          ...obj,
          [xCol]: e,
        };
      });
      // console.log({ arr });
      // console.log(arr);
      data.push(...arr);
    });

    // console.log(data);
    return data;

    // if (!dateKey) {
    //   throw new Error('Error happened when converting data to series');
    // }

    // const DAY_IN_MS = 86400000;
    // const startDate = new Date(rows[0][dateKey]).getTime();
    // const endDate = new Date(rows[rows.length - 1][dateKey]).getTime();
    // const totalDaysInBetween = (endDate - startDate) / DAY_IN_MS + 1;

    // rows.forEach((row) => {
    //   const date = row[dateKey];
    //   const groupByKey = row[groupBy];

    //   if (!groupByMap.has(groupByKey)) {
    //     groupByMap.set(groupByKey, Array(totalDaysInBetween));
    //   }

    //   const curIndex = (new Date(date).getTime() - startDate) / DAY_IN_MS;
    //   const newTenantArr = [...groupByMap.get(groupByKey)];
    //   newTenantArr[curIndex] = row;
    //   groupByMap.set(groupByKey, newTenantArr);
    // });

    // const res: Record<string, string | number>[] = [];

    // groupByMap.forEach((row, groupByVal) => {
    //   const filledTenant = row.map(
    //     (e: Record<string, string | number>[], index: number) => {
    //       if (!e) {
    //         // console.log('++++');
    //         return createEmptyData(
    //           moment(startDate + index * DAY_IN_MS)
    //             .utc()
    //             .format('YYYY-MM-DD'),
    //           groupByVal,
    //           groupBy,
    //           rows[0]
    //         );
    //       }
    //       // console.log({ e });
    //       return e;
    //     }
    //   );
    //   // console.log({ filledTenant });

    //   res.push(...filledTenant);
    // });

    // // console.log({ res });
    // return res;
  };

  let rows = queryResults?.rows;
  if (chart.uiOptions.dataOptions?.groupByCol) {
    // remove bad data
    const xCol = chart.uiOptions.dataOptions.xCol?.colName;
    rows = rows?.filter((e) => {
      return e[xCol!] !== '';
    });
  }
  // if xCol.colName includes date + groupByCol is not empty string
  // we will create series for the missing data

  const res = fillEmptyDataDates(
    rows,
    chart.uiOptions.dataOptions?.xCol?.colName,
    chart.uiOptions.dataOptions?.groupByCol
  );
  // =======

  // calculate bar chart configs
  const option = connectEChartData({
    chart,
    queryResults: chart.uiOptions.dataOptions?.groupByCol
      ? ({
          ...queryResults,
          rows: res,
        } as QueryResultResult)
      : queryResults,
  });

  // TODO delete after
  // eslint-disable-next-line
  // console.log('Barchart re-render with option', option);

  // TODO memo hash obj
  const hashObj = cloneDeep(option);
  // Mutating
  removeKeyRecursive(hashObj, 'data');

  return (
    <Suspense fallback={<LogoBlinkerCenter />}>
      <EChartV2
        loading={loading}
        option={option}
        key={JSON.stringify(hashObj)}
        style={{ height: '100%' }}
      />
    </Suspense>
  );
}

export default memo(BarChart);
