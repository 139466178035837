import Icon from '../../../components/Icon'



interface ChainDisplayProps {
  chains: string[]
  className?: string
}

const ChainDisplay = ({
  chains,
  className
}: ChainDisplayProps) => {
  if (chains.length === 0) return null

  return <div className={` ${className}`}>
    <ul className="overlap-avatar">
      {
        chains.map((chain: string, index: number) => {
          return <li key={index}>
            <Icon
              iconName={chain}
              className='w-[40px] h-[40px] object-cover'
            />
          </li>
        })
      }
    </ul>
  </div>
}

export default ChainDisplay;