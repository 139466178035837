import classNames from 'classnames';
import { HTMLAttributes } from 'react';

export function SideButton({
  className,
  active,
  ...props
}: HTMLAttributes<HTMLButtonElement> & {
  active?: boolean;
}) {
  return (
    <button
      className={classNames(
        'font-semibold p-3 rounded-lg w-full text-left hover:bg-opacity-100 hover:bg-base-200 bg-opacity-100 text-base-content',
        active ? 'bg-base-300' : 'bg-base-100',
        className
      )}
      {...props}
    >
      {props.children}
    </button>
  );
}
