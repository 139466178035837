import 'react-toastify/dist/ReactToastify.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { lazy, Suspense } from 'react';

import Layout from './layouts/Layout';
import FourOFour from './modules/fourofour/FourOFour';
import DatasetList from './modules/dataset/DatasetList';
import Profile from './modules/profile/Profile';
import NewConnector from './modules/connectors/NewConnector';
import ScrollToTop from './components/app/ScrollToTop';
import ConnectorView from './modules/connectors/ConnectorView';
import Transformations from './modules/transformations/Transformations';
import Login from './modules/auth/Login';
import PageLoading from './components/app/PageLoading';
import FullPageLoading from './components/app/FullPageLoading';
import { RoutePaths } from './layouts/Navbar';
import Upload from './modules/connectors/Upload';
import { GoogleAnalytics } from './components/google/Analytics';
import { CommunityRoutes, MyWorkspaceRoutes } from './RoutesV2';
import DashboardViewPageV3 from './modules/dashboard/DashboardViewPageV3';
import LayoutV3 from './layouts/LayoutV3';
import Docs from './Docs';
import Waitlist from './Waitlist';
import Plans from './modules/plan/Plan';
import { ProductV2Routes } from './RoutesProductV2';

const DatasetDetail = lazy(() => import('./modules/dataset/DatasetDetail'));
const QueryBuilder = lazy(() => import('./modules/queryBuilder/QueryBuilder'));
const GEMExampleIframe = lazy(() => import('./modules/chart/GEMExampleIframe'));
const StandaloneChart = lazy(
  () => import('./modules/chart/StandaloneChartPage')
);
const DashboardViewPage = lazy(
  () => import('./modules/dashboard/DashboardViewPage')
);
const DashboardList = lazy(() => import('./modules/dashboard/DashboardList'));

export default function AppRoutes() {
  const defaultRedirect = '/my-workspace';

  return (
    <Router>
      <GoogleAnalytics />
      <ScrollToTop />
      <Routes>
        {/* Product V2 */}
        <Route path='/v2/*' element={<ProductV2Routes />} />
        <Route path='/' element={<Navigate to='/v2/explore' replace />} />
        <Route path='/*' element={<Navigate to='/v2/explore' replace />} />
        <Route
          path='/docs'
          element={
            <div
              style={{
                height: '100vh',
                width: '100vw',
              }}
            >
              <iframe
                title='docs'
                style={{ width: '100vw', height: '100vh' }}
                src='https://docs.zettablock.com/'
              />
            </div>
          }
        ></Route>

        <Route path='waitlist' element={<Waitlist />}></Route>
        <Route path='/community/*' element={<CommunityRoutes />} />
        <Route path='/my-workspace/*' element={<MyWorkspaceRoutes />} />
        <Route path='/embed/' element={<LayoutV3 privatePage={false} />}>
          <Route
            path='/embed/chart/:chartId'
            element={
              <Suspense fallback={<FullPageLoading />}>
                <StandaloneChart privateApi={false} />
              </Suspense>
            }
          />
          <Route
            path='/embed/dashboard/:dashboardId'
            element={
              <Suspense fallback={<FullPageLoading />}>
                <DashboardViewPageV3 />
              </Suspense>
            }
          />
        </Route>
        <Route path='/' element={<Navigate to={defaultRedirect} replace />} />
        <Route path='/' element={<Layout />}>
          <Route path='/plans' element={<Plans />}></Route>
        </Route>
        <Route path='/' element={<Layout />}>
          <Route
            path='/embed/:chartId'
            element={
              <Suspense fallback={<FullPageLoading />}>
                <StandaloneChart />
              </Suspense>
            }
          />
          <Route path='/' element={<Navigate to={defaultRedirect} replace />} />
          <Route
            path='gem'
            element={
              <Suspense fallback={<PageLoading />}>
                <GEMExampleIframe />
              </Suspense>
            }
          />
          <Route path='auth'>
            <Route path='login' element={<Login />} />
          </Route>
          <Route path='embed-docs' element={<Docs />}></Route>
          <Route path='dashboards'>
            <Route
              index
              element={
                <Suspense fallback={<PageLoading />}>
                  <DashboardList />
                </Suspense>
              }
            />
            <Route
              path=':dashboardId'
              element={
                <Suspense fallback={<PageLoading className='bg-transparent' />}>
                  <DashboardViewPage />
                </Suspense>
              }
            />
          </Route>
          <Route path='profile'>
            <Route index element={<Profile />} />
          </Route>
          <Route
            path='query-builder/:id'
            element={
              <Suspense fallback={<PageLoading />}>
                <QueryBuilder />
              </Suspense>
            }
          />
          <Route path='profile'>
            <Route index element={<Profile />} />
          </Route>
          <Route
            path='query-builder'
            element={<Navigate to={'/my-workspace/queries/new'} replace />}
          />
          <Route path='connectors'>
            <Route index element={<NewConnector />} />
            <Route path='type/:type' element={<Upload />} />
            <Route path=':id/*' element={<ConnectorView />} />
          </Route>
          <Route path='transformations' element={<Transformations />} />
          <Route path={RoutePaths.projects}>
            <Route index element={<DatasetList />} />
            <Route
              path=':datasetId/*'
              element={
                <Suspense fallback={<PageLoading />}>
                  <DatasetDetail />
                </Suspense>
              }
            />
            <Route path='*' element={<DatasetList />} />
          </Route>
          <Route path='*' element={<FourOFour />} />
        </Route>
      </Routes>
    </Router>
  );
}
