import { memo } from 'react';

import { CommonChartComposeProps } from '../../chart/types-v2';
import CopyToClipboard from 'react-copy-to-clipboard';
import { RiShareBoxFill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import ChartDisplayName from '../../chart/components/ChartDisplayName';
import SaturatedButton from './interactives/SaturatedButton';
import { ChartEntity } from '../../chart/types';
import { analytics } from '../../../App';

function ChartComposeHeader({
  chart,
  openAddToDashboardModal,
}: {
  chart: ChartEntity;
  openAddToDashboardModal?: () => void;
} & CommonChartComposeProps) {
  return (
    <div className='flex items-center justify-between gap-3'>
      <div className='w-full overflow-hidden flex-1 flex items-center p-3 gap-3'>
        <div className='flex-auto'>
          <ChartDisplayName className='overflow-hidden overflow-ellipsis whitespace-nowrap'>
            {chart.displayName}
          </ChartDisplayName>
        </div>
      </div>
      <div className='flex'>
        {openAddToDashboardModal && (
          <div className='btn-group pr-3 opacity-60'>
            <SaturatedButton
              onClick={() => {
                // Adding tracking code here
                analytics.track(`Query - Add to dashboard button clicked`, {
                  chartId: chart.id,
                  location: 'from chart compose header',
                });

                openAddToDashboardModal();
              }}
            >
              Add to dashboard
            </SaturatedButton>

            <CopyToClipboard text={`${window.origin}/embed/chart/${chart.id}`}>
              <SaturatedButton
                onClick={() => {
                  toast.success('Link copied', {
                    autoClose: 2000,
                  });
                  analytics.track(`Query - Copy embed link button clicked`, {
                    chartId: chart.id,
                    location: 'from chart compose header',
                  });
                }}
              >
                <RiShareBoxFill size='0.8rem' />
                Share
              </SaturatedButton>
            </CopyToClipboard>
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(ChartComposeHeader);
