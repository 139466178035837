import { memo } from 'react';
import { GraphiQLEditor } from '../../components/graphiql-editor';

function Playground({
  url,
  direction,
}: {
  url?: string;
  direction?: 'vertical';
}): JSX.Element {
  return (
    <div className='p-[30px]'>
      <div className='gql-editor h-[calc(100vh-322px)] border rounded'>
        {/* /v2/explore/tables/:projectId/:tableId, /v2/workspace/graphql-apis/:apiId/playground */}
        <GraphiQLEditor apiUrl={url} noCache direction={direction} />
      </div>
    </div>
  );
}

export default memo(Playground);
