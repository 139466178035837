import { useParams } from 'react-router';
import { atom, useRecoilState } from 'recoil';

import { ModelListDashboardResponse } from '../../api/__gen__/data-contracts';
import { useRpcStatus } from '../../hooks/network';
import {
  fetchCommunityDashboards,
  fetchMyDashboards,
} from './DashboardListState';

export const MyDashboardsState = atom<ModelListDashboardResponse>({
  key: 'MyDashboardsState',
  default: {},
});

export const CommunityDashboardsState = atom<ModelListDashboardResponse>({
  key: 'CommunityDashboardsState',
  default: {},
});

export function useDashboardId() {
  const params = useParams();
  const dashboardId = params?.dashboardId;

  return dashboardId;
}

export const useMyDashboards = () => {
  const [rpcStatus, setRpcStatus] = useRpcStatus();
  const [myDashboards, setMyDashboards] = useRecoilState(MyDashboardsState);

  async function getMyDashboards() {
    if (rpcStatus?.isLoading) {
      return;
    }

    setRpcStatus({
      isLoading: true,
      error: '',
    });

    const resp = await fetchMyDashboards();
    const error = resp?.data.error;

    if (error) {
      // error
      setRpcStatus({
        isLoading: false,
        error,
      });
    } else {
      setRpcStatus({
        isLoading: false,
      });

      // success
      const data = resp?.data;
      setMyDashboards(data);
    }
    setRpcStatus({
      isLoading: false,
      error,
    });
  }

  return [
    { myDashboards, rpcStatus },
    { setRpcStatus, getMyDashboards },
  ] as const;
};

export const useCommunityDashboards = () => {
  const [rpcStatus, setRpcStatus] = useRpcStatus();
  const [communityDashboards, setCommunityDashboards] = useRecoilState(
    CommunityDashboardsState
  );

  async function getCommunityDashboards() {
    if (rpcStatus?.isLoading) {
      return;
    }

    setRpcStatus({
      isLoading: true,
      error: '',
    });

    const resp = await fetchCommunityDashboards();
    const error = resp?.data.error;

    if (error) {
      // error
      setRpcStatus({
        isLoading: false,
        error,
      });
    } else {
      setRpcStatus({
        isLoading: false,
      });

      // success
      const data = resp?.data;
      setCommunityDashboards(data);
    }
    setRpcStatus({
      isLoading: false,
      error,
    });
  }

  return [
    { communityDashboards, rpcStatus },
    { setRpcStatus, getCommunityDashboards },
  ] as const;
};