import { parseInt } from 'lodash';
import { toast } from 'react-toastify';
import { atom, useRecoilState } from 'recoil';
import ReadMore from '../../components/typographic/ReadMore';

import { getRecoilState } from '../recoil/utils';

const localStorageEffect =
  (key: string) =>
  // @ts-ignore
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(parseInt(savedValue));
    }

    onSet((newValue: number, _: never, isReset: boolean) => {
      return isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, `${newValue}`);
    });
  };

export const AToastAutoDismiss = atom<number>({
  key: 'AToastAutoDismiss',
  default: 0,
  effects: [localStorageEffect('AUTO_DISMISS')],
});

const DismissAllOption = () => {
  return (
    <div className='flex items-center'>
      <button
        className='hover:text-primary text-xs'
        onClick={() => {
          toast.dismiss();
        }}
      >
        Dismiss all
      </button>
    </div>
  );
};

const ToastAutoDismissOption = () => {
  const [autoDismiss, setAutoDismiss] = useRecoilState(AToastAutoDismiss);

  const enabled = autoDismiss !== 0;
  return (
    <div className='flex items-center'>
      <input
        type='checkbox'
        className='toggle toggle-primary toggle-xs'
        checked={enabled}
        onChange={() => {
          setAutoDismiss(!enabled ? 5000 : 0);
          if (!enabled) {
            toast.dismiss();
          }
        }}
      />
      <div className='w-2' />
      <span className='text-xs'>Auto dismiss</span>
    </div>
  );
};
const lastMsgMap = new Map();
export const zToast = {
  ...toast,
  error: (msg: string, options) => {
    if (lastMsgMap.has(msg)) {
      toast.update(lastMsgMap.get(msg));
      return;
    }
    const toastId = toast.error(
      <>
        {msg.length > 600 ? <ReadMore>{msg}</ReadMore> : <div>{msg}</div>}
        <div>
          <div className='h-1' />
          <div className='flex'>
            <DismissAllOption />
            <span className='px-2'> | </span>
            <ToastAutoDismissOption />
          </div>
        </div>
      </>,
      {
        ...options,
        onClose: () => {
          lastMsgMap.delete(msg);
        },
        autoClose: getRecoilState(AToastAutoDismiss),
      }
    );
    lastMsgMap.set(msg, toastId);
  },
  info: (msg: string, options) => {
    if (lastMsgMap.has(msg)) {
      toast.update(lastMsgMap.get(msg));
      return;
    }
    const toastId = toast.info(
      <>
        {msg.length > 600 ? <ReadMore>{msg}</ReadMore> : <div>{msg}</div>}
        <div>
          <ToastAutoDismissOption />
        </div>
      </>,
      {
        ...options,
        onClose: () => {
          lastMsgMap.delete(msg);
        },
        autoClose: getRecoilState(AToastAutoDismiss),
      }
    );
    lastMsgMap.set(msg, toastId);
  },
} as typeof toast;
