export type TParam = {
  name: string;
  defaultValue?: string;
  value?: string;
  type: TParamType;
  idx?: number;
};

export const ParamTypeValues = [
  'date',
  'datetime',
  'string',
  'number',
] as const;
export type TParamType = typeof ParamTypeValues[number];
export const DefaultTParamType: TParamType = 'string' as const;

export type TParamMap = Record<string, TParam>;
export type TParamState = TParam[];

export type TQueryRunParams = {
  displayName?: string;
  queryId?: string;
  query?: string;
  selectedDB?: string;
  queryParams?: TParamState;
};

export type TQueryRunParamsV3 = {
  displayName?: string;
  id?: string;
  text?: string;
  database?: string;
  queryParams?: TParamState;
};

export type TPreviewParams = {
  queryId?: string;
  selectedDB?: string;
  schema?: string;
  table?: string;
};