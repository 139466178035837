import { atom, selector } from 'recoil';
import { toast } from 'react-toastify';
import { DatabaseApi } from '../../api/client';
import { SelectedDBState } from './DatabaseState';
import { ModelSchema } from '../../api/__gen__/data-contracts';
import { DefaultAutoDismissMs } from '../../config/toast';

// eslint-disable-next-line
export const SchemaCache = new Map<string, ModelSchema[]>();

export const FetchSchema = selector({
  key: 'FetchSchema',
  get: async ({ get }) => {
    try {
      const selectedDB = get(SelectedDBState);

      if (!selectedDB) {
        return [];
      }

      const client = DatabaseApi();
      const resp = await client.schemasDetail(selectedDB || '');

      if (Array.isArray(resp.data.items)) {
        SchemaCache.set('cache', resp.data.items);
        return resp.data.items;
      }
    } catch (error) {
      // @ts-ignore
      const msg = error?.response?.data?.message;

      toast.error(msg || 'Error loading schemas.', {
        autoClose: DefaultAutoDismissMs,
      });
    }

    return [];
  },
});

export const SchemasQueryState = atom({
  key: 'SchemasQueryState',
  default: FetchSchema,
});

export type TSchemaState = {
  database?: string;
  schema?: string;
};

export const SelectedSchemaState = atom<TSchemaState | undefined>({
  key: 'SelectedSchemaState',
  default: undefined,
});
