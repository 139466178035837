import { useGoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { toast } from 'react-toastify';
import { AuthApi, DatabaseApi } from '../../api/client';
import { useRecoilState } from 'recoil';
import { AuthState } from './AuthState';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Cookies, useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { isEmpty, keys } from 'lodash';
import { HorizontalLine } from '../../components/HorizontalLine';
import { AxiosError } from 'axios';
import { defaultErrorMessage } from '../error/const';
import Modal from '../../components/modals/Modal';
import Logo from '../../components/app/Logo';
import { analytics } from '../../App';

// TODO temporary login check machanism
const checkAuthorized = async ({ token }: { token: string }) => {
  try {
    const client = DatabaseApi();

    const headers = {
      Authorization: `Basic ${token}`,
    };
    const resp = await client.databasesList({
      // @ts-ignore
      headers,
    });
    return resp.data;
  } catch (error) {
    // eslint-disable-next-line
    console.error('error', error);
  }

  return null;
};

export interface FormValues {
  username?: string;
  password?: string;
}

export default function Login() {
  const [cookies, setCookie] = useCookies(['auth']);
  const [auth, setAuth] = useRecoilState(AuthState);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const back = searchParams.get('back');
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: typeof cookies.auth === 'object' ? cookies.auth : {},
  });

  const [showModal, setShowModal] = useState(false);

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      const client = AuthApi();
      try {
        const resp = await client.loginGoogleCreate({
          authorizationCode: code,
        });
        if (resp.data.accessToken && resp.data.user?.id) {
          setAuth({
            accessToken: resp.data.accessToken,
            id: resp.data.user.id,
            name: resp.data.user.name,
            email: resp.data.user.email,
            profileImage: resp.data.user.profileImage,
          });

          const analyticsId = resp.data.user.email?.includes('zettablock') ? resp.data.user.id : `${resp.data.user.id} - ${resp.data.user.email}`;

          analytics.identify(analyticsId, {
            ...auth
          });
          analytics.track('Auth - Login', {
            type: 'Google',
            isZettaBlockUser: resp.data.user.email?.includes('zettablock'),
            ...auth
          })
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          // Note(jjin): return 406 means not in our whitelist
          if (error.response?.status === 406) {
            setShowModal(true);
            return;
          }

          const msg = error.response?.data.message;
          toast.error(msg || defaultErrorMessage);
        }
        setAuth(null);
      }
    },
    onError: () => {
      toast.error('Login Failed. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
    flow: 'auth-code',
  });

  useEffect(() => {
    if (auth || !isEmpty(cookies.auth)) {
      if (back && back !== '/') {
        navigate(back);
      } else {
        navigate('/my-workspace/apis');
      }
    }
  }, [auth, cookies.auth]);

  return (
    <div className='min-h-[calc(100vh-380px)] pt-16'>
      <div className='max-w-[460px] mx-[auto]'>
        <div className='flex justify-center'>
          <Logo />
        </div>
        <div className='bg-[#fff] mt-8 pb-10'>
          <div className='bg-[#FBFCFD] px-8 py-6 font-bold'>Login</div>
          <form
            onSubmit={handleSubmit(async (values) => {
              /**
               * This is to fix the cookie created without path params.
               * That seems to cause some issue for auth
               * TODO: delete it when we build new auth handling mechanism
               */
              const uCookies = new Cookies();
              const allCookie = uCookies.getAll();

              keys(allCookie).forEach((element) => {
                uCookies.remove(element);
              });

              const token = `${window.btoa(
                `${values.username}:${values.password}`
              )}`;

              // check if user authed successfully
              const databases = await checkAuthorized({ token });
              const items = databases?.items;

              if (items) {
                const analyticsId = values.username;

                analytics.identify(analyticsId, {
                  ...auth
                });
                analytics.track('Auth - Login', {
                  type: 'Email',
                  isZettaBlockUser: analyticsId?.includes('zettablock')
                })
                // success
                toast.info('Login successful');

                // store auth values
                setCookie('auth', values, {
                  path: '/',
                });
                if (back && back !== '/') {
                  navigate(back);
                } else {
                  navigate('/my-workspace/apis');
                }
              } else {
                // failed
                toast.warning('Please check username or password');
              }
            })}
            className='w-full px-8'
          >
            <div className='form-control w-full mt-6'>
              <label className='label'>
                <span className='label-text'>Email</span>
              </label>
              <input
                placeholder='email@example.com'
                type='text'
                className='input input-bordered w-full border-transparent bg-[#F8F9FA]'
                {...register('username')}
              />
            </div>
            <div className='form-control w-full mt-4'>
              <label className='label'>
                <span className='label-text'>Password</span>
              </label>
              <input
                placeholder='**********'
                type='password'
                className='input input-bordered w-full border-transparent bg-[#F8F9FA]'
                {...register('password')}
              />
            </div>

            <button className='btn btn-primary bg-[#263DFF] mt-10 w-full'>
              Login
            </button>
          </form>
          {/* <div className='text-center mt-5 text-sm'>
            {"Don't have an account? "}
            <Link to='/auth/register' className='text-[#263DFF]'>
              Sign Up
            </Link>
          </div> */}
          <div className='text-center mt-5 text-sm px-8 font-semibold'>
            By logging in, you are agreeing to the{' '}
            <a
              className='text-[#263DFF]'
              href='https://www.zettablock.com/terms'
              target='_blank'
              rel='noreferrer'
            >
              Terms of Service
            </a>{' '}
            and confirm that you have read the{' '}
            <a
              className='text-[#263DFF]'
              href='https://www.zettablock.com/privacy'
              target='_blank'
              rel='noreferrer'
            >
              Privacy Policy
            </a>
            .
          </div>
          <div className='px-8 mt-5'>
            <HorizontalLine className='h-0 mb-5' />
            <button
              className='btn btn-outline gap-5 w-full normal-case text-xl'
              onClick={() => googleLogin()}
            >
              <FcGoogle size='1.5rem' />
              Continue with Google
            </button>
          </div>
        </div>
      </div>
      <Modal
        open={showModal}
        className='flex flex-col space-y-4'
        onClickBackdrop={() => { }}
      >
        <h3 className='flex items-center space-x-2 font-bold text-lg'>
          <span>Unauthorized</span>
        </h3>
        <div className='flex-1 pb-2 overflow-y-auto max-h-[50vh] space-y-1'>
          <div>
            <p className='mb-3'>Hi there!</p>
            <p className='mb-3'>
              We&apos;re glad you&apos;re trying to log in to our service. It
              looks like you&apos;re not currently on our email whitelist.
            </p>
            <p className='mb-3'>
              Don&apos;t worry! Just click the button below to sign up on our
              website, and we&apos;ll add you to the whitelist soon.
            </p>
            <p className='mb-3'>
              If you have any issues or questions, please don&apos;t hesitate to
              contact our friendly support team at{' '}
              <a className='text-primary' href='mailto:team@zettablock.com'>
                team@zettablock.com
              </a>
              !
            </p>
            <p className='mb-3'>
              Happy Building, <br /> ZettaBlock Support Team
            </p>
          </div>
        </div>
        <div className='modal-action items-center'>
          <a
            href='https://www.zettablock.com/?signup'
            target='_blank'
            rel='noreferrer'
          >
            <button className='btn btn-primary' type='button' onClick={() => {
              analytics.track('Auth - Signup - from unauthorized modal')
            }}>
              SIGN UP
            </button>
          </a>
        </div>
      </Modal>
    </div>
  );
}
