import classNames from "classnames"
import { useMemo } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { toast } from "react-toastify"

const EndpointDisplay = ({ method = 'get', path = '' }: {
  method: string
  path: string
}) => {
  const methodBgColor = useMemo(() => {
    switch (method) {
      case 'get':
        return 'border-[#adb4f7] border bg-[#e5e7f9] text-[#263DFF]'

      default:
        return 'border-[#adb4f7] border bg-[#e5e7f9] text-[#263DFF]'
    }
  }, [
    method
  ])
  return <CopyToClipboard text={path} onCopy={() => {
    toast.success('Copied to clipboard', {
      autoClose: 2000,
      position: 'top-center',
    })
  }}>
    <div className="p-1 bg-[#fafafa] border rounded flex items-center cursor-copy">
      <span className={
        classNames(methodBgColor, 'px-1 py-[2px] text-[10px] rounded mr-2')
      }>{method}</span>

      <span className="text-[12px]">{path}</span>
    </div>
  </CopyToClipboard>
}

export default EndpointDisplay;