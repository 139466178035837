import { isEmpty } from 'lodash';
import { useRecoilValue } from 'recoil';

import { TDatabaseColumn } from '../../api/types';
import TableChart from '../chart/components/TableChart';
import { TemporaryQueryResultId } from './QueryBuilderHooks';
import { SQueryResults } from './QueryState';

function Results() {
  const queryResults = useRecoilValue(SQueryResults(TemporaryQueryResultId));

  const columns = queryResults?.results?.metadata?.columns || [];
  const rows = queryResults?.results?.rows;

  if (!rows) {
    return <div>Please run query to see the result.</div>;
  }

  return (
    <div>
      <div className='mb-4'>{rows.length} rows returned</div>
      {!isEmpty(columns) && (
        <TableChart
          columns={columns.map((c: TDatabaseColumn) => c.name)}
          data={rows}
        />
      )}
    </div>
  );
}
export default Results;
