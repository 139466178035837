import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { GraphiQL } from 'graphiql';
import { explorerPlugin } from '@graphiql/plugin-explorer';
import {
  createGraphiQLFetcher,
  Fetcher,
  FetcherOpts,
  FetcherParams,
  Storage,
} from '@graphiql/toolkit';
import envs from '../../config/envs';
import { AuthApi } from '../../api/client';
import { useAuthHeaders } from '../../modules/auth/AuthState';

import '@graphiql/plugin-explorer/dist/style.css';
import 'graphiql/graphiql.min.css';
import { atom, useRecoilState } from 'recoil';
import { ModelQueryPrompt } from '../../api/__gen__/data-contracts';
import { searchQueryPrompts } from '../../modules/realtime-api/apis';
import classNames from 'classnames';
import InputField from './InputField';
import { localStorageEffect } from '../../utils/localStorage';

const refreshAccessToken = async () => {
  const client = AuthApi();
  const resp = await client.postAuth();
  return resp.data.accessToken;
};

export type GraphqlApiStateType = {
  query: string;
  variables: string;
  operationName: string;
};

const defaultAiState = {
  query: '# Welcome to Query Explorer\n',
  variables: '',
  operationName: '',
};

export const GraphqlApiState = atom<GraphqlApiStateType>({
  key: 'GraphqlApiState',
  default: defaultAiState,
  effects: [
    localStorageEffect<GraphqlApiStateType>(
      'graphql-query-cache',
      defaultAiState
    ),
  ],
});

function QueryExamples({
  id,
  apiId,
  query,
  onChange,
}: {
  id: string;
  apiId?: string;
  query?: string;
  onChange?: (query: string) => void;
}) {
  const [focus, setFocurs] = useState(false);
  const [queryPrompts, setQueryPrompts] = useState<ModelQueryPrompt[]>([]);
  const fetchAndSetQueryPrompts = async (keywords?: string) => {
    const data = await searchQueryPrompts(
      id,
      keywords ? keywords.split(',').map((e) => e.trim()) : [],
      'graphql'
    );
    setQueryPrompts(data);
  };

  useEffect(() => {
    fetchAndSetQueryPrompts();
  }, []);

  return (
    <div>
      <InputField
        apiId={apiId}
        onChange={onChange}
        onSearch={fetchAndSetQueryPrompts}
        focus={focus}
        onFocus={setFocurs}
      />
      <div className='px-2 pb-2 border-b flex flex-wrap gap-2'>
        {focus &&
          queryPrompts.map((prompt, index) => {
            return (
              <div
                key={index}
                className={classNames(
                  'bg-[#f1f1f1] hover:bg-[#f0f2f4] py-1 px-3 rounded flex items-center cursor-pointer',
                  {
                    'bg-[#f0f2f4]': prompt.query === query,
                  }
                )}
                onClick={() => {
                  // console.log(prompt.query)
                  onChange?.(prompt.query || '');
                }}
                title={prompt.description}
              >
                {prompt.name}
              </div>
            );
          })}
      </div>
    </div>
  );
}

class MemoryStorage implements Storage {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _data = new Map<string, any>();

  public length = 0;

  public getItem(key: string) {
    return this._data.get(key);
  }

  public setItem(key: string, value: string) {
    if (!this._data.has(key)) {
      this.length++;
    }
    this._data.set(key, value);
  }

  public removeItem(key: string) {
    if (this._data.has(key)) {
      this.length--;
      this._data.delete(key);
    }
  }

  public clear() {
    this.length = 0;
    this._data.clear();
  }
}

export enum GraphiQLExplorerType {
  Docs = 'Documentation Explorer',
  History = 'History',
  Query = 'GraphiQL Explorer',
}

const plugin = explorerPlugin({
  title: GraphiQLExplorerType.Query,
  showAttribution: false,
});

interface GraphiQLEditorProps {
  defaultQuery?: string;
  explorerType?: GraphiQLExplorerType;
  apiUrl?: string;
  response?: string;
  accessToken?: string;
  apiId?: string;
  noCache?: boolean;
  direction?: 'horizontal' | 'vertical';
}

export const GraphiQLEditor = forwardRef<
  GraphqlApiStateType,
  GraphiQLEditorProps
>(
  (
    {
      defaultQuery = '# Welcome to Query Explorer\n',
      explorerType = GraphiQLExplorerType.Query,
      apiUrl,
      response,
      accessToken = '',
      apiId,
      noCache,
      direction,
    },
    ref
  ) => {
    const [graphqlState, setGraphqlState] = useRecoilState(GraphqlApiState);
    const { id } = useParams();

    const [iaccessToken, setAccessToken] = useState(accessToken);
    const headers = useAuthHeaders();

    useImperativeHandle(ref, () => graphqlState);

    useEffect(() => {
      let interval: number;
      if (!accessToken) {
        interval = Number(
          setInterval(async () => {
            const token = await refreshAccessToken();
            setAccessToken(token || '');
          }, 60000)
        );
      } else {
        setAccessToken(accessToken);
      }

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }, [accessToken]);

    useEffect(() => {
      if (direction === 'vertical') {
        const gplEditorLayout = document.querySelector('.graphiql-session')!;
        gplEditorLayout.className = 'graphiql-session flex-col';
      }

      if (noCache) {
        return;
      }
      setTimeout(() => {
        const gplEditorTools = document.querySelector(
          '.graphiql-editor-tools'
        )!;
        if (gplEditorTools) {
          const div = document.createElement('div');
          gplEditorTools.insertBefore(div, gplEditorTools.firstChild);
          div.style.width = '100%';
          div.parentElement!.className = 'graphiql-editor-tools flex-wrap';
          ReactDOM.render(
            <QueryExamples
              id={id!}
              apiId={apiId}
              query={graphqlState.query}
              onChange={(query) => {
                setGraphqlState({
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ...(ref as any)?.current,
                  query,
                });
              }}
            />,
            div
          );
        }
      }, 500);
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fetchRef = useRef({} as any);
    fetchRef.current = {
      id,
      headers,
      accessToken: iaccessToken,
    };

    const fetcher = useMemo(() => {
      const fetchHandler = (
        graphQLParams: FetcherParams,
        opts?: FetcherOpts
      ) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { id, headers, accessToken } = fetchRef.current;
        const fetch = createGraphiQLFetcher({
          //
          // https://qugate-dev-ztbl.api.zettablock.dev/pubgate/v1/ethereum/graphql
          // url: `${envs.ZettaBackendApiPrivate}/${id}/graphql`,
          url: apiUrl || `${envs.ZettaBackendApiPrivate}/${id}/graphql`,
          // @ts-ignore
          headers: accessToken
            ? {
                Authorization: `Bearer ${accessToken}`,
              }
            : headers,
        });
        if (headers.Authorization) {
          return fetch(graphQLParams, opts);
        }
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            const promise = fetchHandler(graphQLParams, opts);
            if (promise instanceof Promise) {
              promise.then(resolve, reject);
            } else {
              resolve(promise);
            }
          }, 200);
        });
      };
      return fetchHandler as Fetcher;
    }, []);
    const props = noCache ? { storage: new MemoryStorage() } : {};
    return (
      <GraphiQL
        {...props}
        fetcher={fetcher}
        plugins={[plugin]}
        visiblePlugin={explorerType}
        response={response}
        defaultQuery={defaultQuery}
        query={graphqlState.query || defaultQuery}
        onEditQuery={(newQuery: string) => {
          setGraphqlState({
            ...graphqlState,
            query: newQuery,
          });
        }}
        variables={graphqlState.variables}
        onEditVariables={(newVariables: string) => {
          setGraphqlState({
            ...graphqlState,
            variables: newVariables,
          });
        }}
        operationName={graphqlState.operationName}
        onEditOperationName={(newOperationName: string) => {
          setGraphqlState({
            ...graphqlState,
            operationName: newOperationName,
          });
        }}
      />
    );
  }
);
