import { memo, useState } from 'react';
import TrackVisibility from 'react-on-screen';

function OnVisible({ onVisible }: { onVisible: () => void }) {
  const [v, setV] = useState(false);
  return (
    <TrackVisibility>
      {({ isVisible }) => {
        if (isVisible !== v) {
          setV(!v);

          if (isVisible) {
            onVisible();
          }
        }

        return <div />;
      }}
    </TrackVisibility>
  );
}
export default memo(OnVisible);
