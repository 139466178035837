import { analytics } from '../../../App';
import { useWorkspace } from '../../../layouts/components/workspace-hooks';
import NewDashboardV3 from '../../dashboard/NewDashboardV3';
import AddEntityButton from './AddEntityButton';

export default function CreateSection() {
  const [{ menu }, { getPath }] = useWorkspace();

  if (menu === 'data-sources') {
    return null;
  }

  return (
    <div className='flex h-[8rem] w-full space-x-5 items-center py-5 px-5'>
      {menu === 'queries' && (
        <>
          <AddEntityButton title='Create Query' to={getPath('queries/new')} />
          <AddEntityButton
            onClick={() => {
              analytics.track('Query - Query history clicked');
            }}
            title='Query History'
            iconType='history'
            to={getPath('query-history')}
          />
        </>
      )}
      {menu === 'apis' && (
        <AddEntityButton
          title='Create API With SQL'
          to={getPath('queries/api')}
        />
      )}
      {/* NOTE(jjin): hide this menu for now, according to Chi's request */}
      {false && menu === 'apis' && (
        <AddEntityButton
          title='Create API With PYSPARK'
          subtitle='COMING SOON'
        />
      )}
      {menu === 'dashboards' && <NewDashboardV3 />}
      {menu === 'projects' && <AddEntityButton title='Create Project' />}
      {menu === 'data-sources' && (
        <AddEntityButton title='Create Data Source' />
      )}
      {menu === 'contracts' && <AddEntityButton iconType='search' title='Get Data from Chain'
        to={getPath('contracts/new')}
      />}
    </div>
  );
}
