/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ModelListTriggerResponse } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Api<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description list triggers
   *
   * @tags example
   * @name TriggersDetail
   * @summary list triggers
   * @request GET:/api/{id}/triggers
   */
  triggersDetail = (id: string, params: RequestParams = {}) =>
    this.request<ModelListTriggerResponse, any>({
      path: `/api/${id}/triggers`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
