/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../components/app/Logo"
import CreditCardInfo from "./CreditCardInfo";
import useAuth from "../../hooks/auth";
import { addPaymentMethod, getProducts, subscriptionUpgrade } from "./api";
import { useMemo, useState } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements, useElements, useStripe,

} from '@stripe/react-stripe-js';
import { AuthApi, SubscriptionApi } from "../../api/client";
import { UserAuth } from "../../modules/auth/AuthState";
import { toast } from "react-toastify";
import envs from "../../config/envs";

const AddPaymentMethod = () => {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false)

  const isFree = useMemo(() => {
    return searchParams.get('plan') === 'free'
  }, [searchParams])
  const navigate = useNavigate();
  const [{ auth }, { setAuth }] = useAuth();

  const stripe = useStripe();
  const elements = useElements();
  const [cardholderName, setCardholderName] = useState('')

  const onClick = async () => {
    if (!elements) return

    const token = await stripe?.createToken(elements.getElement('cardNumber')!);

    if (token?.token?.id) {
      if (!auth?.id) {
        toast.error('Error adding payment method - Unable to get User ID', {
          autoClose: 2000,
          position: 'top-center',
        })
        return
      }


      try {
        setIsLoading(true)

        const res = await addPaymentMethod(auth.id, token?.token?.id, 'card', true)
        const products = await getProducts(auth?.id || "")
        const buildProduct = products.products?.find((product) => {
          return product.name === 'build'
        })
        await subscriptionUpgrade(
          auth.id,
          buildProduct?.id || '',
          res.data?.payment_method?.id || '',
          'build'
        )


        // clear form
        elements?.getElement('cardNumber')?.clear()
        elements?.getElement('cardExpiry')?.clear()
        elements?.getElement('cardCvc')?.clear()
        setCardholderName('')

        toast.success('Subscription subscribed successfully', {
          autoClose: 2000,
          position: 'top-center',
        })

        setIsLoading(false)
        const refreshAccessToken = async () => {
          const client = AuthApi();
          try {
            const resp = await client.postAuth();
            if (resp && resp.data.accessToken && resp.data.user?.id) {
              setAuth({
                accessToken: resp.data.accessToken,
                id: resp.data.user.id,
                name: resp.data.user.name,
                email: resp.data.user.email,
                profileImage: resp.data.user.profileImage,
                tenant: resp.data.user.tenant,
                defaultApiKey: resp.data.user.defaultApiKey,
                subscription: resp.data.subscription,
                whitelisted: resp.data.user.isInAllowList
              });
            } else {
              setAuth(null);
            }

            setTimeout(() => {

            }, 1);
          } catch (error) {
            setAuth(null);
          }
        };
        await refreshAccessToken();
        navigate('/v2/explore')
      } catch (error: any) {
        toast.error(`Error adding payment method - ${error?.response?.data?.message || 'Unknown'}`, {
          autoClose: 2000,
          position: 'top-center',
        })
        // eslint-disable-next-line no-console
        console.error(error)
      }
      setIsLoading(false)

    } else {
      toast.error(`Error adding payment method - Unable to get Stripe token ID`, {
        autoClose: 2000,
        position: 'top-center',
      })
    }
    setIsLoading(false)

  }

  const onSkip = async () => {
    try {
      const client = SubscriptionApi()
      await client.freePlanChosenCreate({
        planChosen: true
      })
      setAuth((prev: UserAuth | null | undefined) => {
        if (prev) return {
          ...prev,
          subscription: {
            ...prev.subscription,
            planChosen: true
          }
        }
        return null
      })

      toast.success('Free plan subscribed successfully', {
        autoClose: 2000,
        position: 'top-center',
      })

      navigate('/v2/explore')
    } catch (err) {
      toast.error('Error subscribing free plan', {
        autoClose: 2000,
        position: 'top-center',
      })
    }
  }



  return <div className='min-h-[calc(100vh-380px)] pt-16'>
    <div className='max-w-[480px] mx-[auto]'>
      <div className='flex justify-center'>
        <Logo />
      </div>

      <div className='bg-[#fff] mt-8 pb-10'>
        <div className='bg-[#fff] px-8 py-4 flex justify-between items-center border-b'>
          <span className=' font-bold'>
            Add Payment Info
          </span>
        </div>

        <CreditCardInfo cardholderName={cardholderName} setCardholderName={setCardholderName} />

        <div className="px-8 pb-2 flex justify-between mt-4">
          <button className="btn bg-[#fafafa] border-[#e2e2e3] text-[#333] w-[200px] hover:text-[#fff]" onClick={() => {

            navigate('/v2/onboarding/choose-plan')
          }}>
            Back
          </button>
          <button className="btn btn-primary bg-[#263DFF] w-[200px]" onClick={onClick} disabled={isLoading}>
            Subscribe
          </button>
        </div>

        {
          isFree && <div className="mt-6 text-center text-[#263dff] cursor-pointer font-normal" onClick={() => {
            onSkip()
          }}>
            Skip
          </div>
        }
      </div>
    </div>
  </div>
}

const StripeWrapAddPaymentMethod = () => {
  const stripePromise = loadStripe(envs.REACT_APP_STRIPE_SECRET_KEY);
  const options = {
    mode: 'payment',
    amount: 1099,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {
    },
  };

  return <Elements stripe={stripePromise} options={options as any}><AddPaymentMethod /> </Elements>
}

export default StripeWrapAddPaymentMethod;