import classNames from 'classnames';
import { HTMLAttributes } from 'react';

export function AlphaBadge({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <span className={classNames('badge badge-secondary', className)} {...props}>
      alpha
    </span>
  );
}
