import { sql } from '@codemirror/lang-sql';
import { useCodeMirror } from '@uiw/react-codemirror';
import { memo, useEffect, useRef } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { IoIosCopy } from 'react-icons/io';
import { toast } from 'react-toastify';
// import { useRecoilState } from 'recoil';
import { DefaultAutoDismissMs } from '../../config/toast';

import './api-query-pad.scss';
// import { ApiQueryTextState } from './ApiQueryPadHooks';
import { useEditApi } from './ViewApiHooks';
import { codemirrorTheme } from '../../config/theme';

function ViewIncrementalApiOverviewEditor() {
  const editor = useRef<HTMLDivElement>();
  // const apiId = useApiId();
  // const [query] = useRecoilState(ApiQueryTextState(apiId));
  const [{ values }] = useEditApi();

  const { setContainer } = useCodeMirror({
    theme: codemirrorTheme,
    readOnly: true,
    container: editor.current,
    extensions: [sql()],
    value: values.query,
  });

  useEffect(() => {
    if (editor.current) {
      setContainer(editor.current);
    }
  }, [editor]);

  return (
    <div className='flex readonly h-full w-full relative group'>
      <div
        key='editor'
        className='flex-1 overflow-y-auto border'
        ref={editor as React.RefObject<HTMLDivElement>}
      />
      <div className='absolute right-3 top-3 group-hover:block hidden cursor-pointer'>
        <CopyToClipboard text={values.query || ''}>
          <IoIosCopy
            size='1.2rem'
            className='opacity-60'
            onClick={() => {
              toast.success('SQL query copied', {
                autoClose: DefaultAutoDismissMs,
              });
            }}
          />
        </CopyToClipboard>
      </div>
    </div>
  );
}

export default memo(ViewIncrementalApiOverviewEditor);
