import { memo } from 'react';
import classNames from 'classnames';

import Selector from '../../components/inputs/Selector';

const VersionNames = ['Community Public Data'];

function VersionNameSelector({
  title,
  value,
  onSelect,
  disabled,
  showLabel,
}: {
  title: string;
  value?: string;
  onSelect: (value?: string) => void;
  disabled?: boolean;
  showLabel?: boolean;
}) {
  return (
    <div className={classNames('w-full flex items-center pl-2 py-2')}>
      {showLabel && (
        <label className='label flex opacity-50 max-w-[33%] w-full'>
          <span className='font-semibold text-[10px]'>{title}</span>
          {/* {loading && <LoadingIndicator />} */}
        </label>
      )}
      <Selector
        disabled={disabled}
        value={value}
        className='w-2/3 border-0 active:outline-none focus:outline-none text-xs'
        options={VersionNames.map((i) => ({
          label: i,
          value: i,
        }))}
        onSelect={(e) => {
          const selected = VersionNames.find((i) => i === e.target.value);
          onSelect(selected);
        }}
      />
    </div>
  );
}

export default memo(VersionNameSelector);
