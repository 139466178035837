/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import moment from 'moment';
import { useState, useMemo, useEffect } from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';
import {
  ModelGetUserApiProgressResponse,
  ModelGetUserApiResponse,
} from '../../api/__gen__/data-contracts';
import ViewApiProgressPopup from './ViewApiProgressPopup';

interface Props {
  indexingPercent: number;
  idxValues: ModelGetUserApiProgressResponse;
  apiDetail: ModelGetUserApiResponse;
  setTriggerPoll: React.Dispatch<React.SetStateAction<boolean>>;
}

enum IndexingStatus {
  indexing = 'Indexing',
  lastSync = 'Last Sync',
  Syncing = 'Syncing',
}

const ViewApiIndexingProgress = ({
  indexingPercent,
  idxValues,
  apiDetail,
  setTriggerPoll,
}: Props) => {
  const [openProgressPopup, setOpenProgressPopup] = useState(false);

  const isIndexing = useMemo(() => {
    const progress = idxValues.progress ?? 100;
    return progress < 100;
  }, [idxValues]);

  const indexingStatus = useMemo(() => {
    const progress = idxValues.progress ?? 100;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return progress < 100
      ? IndexingStatus.indexing
      : (idxValues as any).refreshInProgress
      ? IndexingStatus.Syncing
      : IndexingStatus.lastSync;
  }, [idxValues]);

  const { dataIngestedTime, dataIngestIntervalSeconds } = apiDetail;

  const [lastRefresh, setLastRefresh] = useState('Unknown');
  const [nextRefresh, setNextRefresh] = useState('Unknown');

  useEffect(() => {
    const i = setInterval(() => {
      const progress = idxValues.progress ?? 100;
      setLastRefresh(
        progress < 100
          ? ''
          : dataIngestedTime === 0
          ? 'Unknown'
          : moment(dataIngestedTime).fromNow()
      );

      if (
        dataIngestedTime !== undefined &&
        dataIngestIntervalSeconds !== undefined
      ) {
        const nextSyncTime =
          dataIngestedTime + dataIngestIntervalSeconds * 1000;
        const nextSync = (idxValues as any).refreshInProgress
          ? ''
          : new Date().getTime() - nextSyncTime < 0
          ? moment(nextSyncTime).fromNow()
          : 'Very soon';
        setNextRefresh(nextSync);

        if (
          nextSyncTime - new Date().getTime() < 60000 &&
          nextSyncTime - new Date().getTime() > -60000
        ) {
          setTriggerPoll(true);
        } else {
          setTriggerPoll(false);
        }
      }
    }, 1000);

    return () => {
      if (i) clearInterval(i);
    };
  }, [apiDetail]);

  const renderIndexingInfo = () => {
    if (!idxValues.progress) return null;

    const ongoing = idxValues.progress !== 100;
    const oneTimeSync = !dataIngestIntervalSeconds;

    const displayText = ongoing
      ? 'Progress'
      : oneTimeSync
      ? 'Never'
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (idxValues as any).refreshInProgress
      ? 'Sync In Progress'
      : 'Next Sync';

    return (
      <>
        <div
          className='text-xs flex items-center gap-1 cursor-pointer'
          onClick={() => {
            setOpenProgressPopup(!openProgressPopup);
          }}
        >
          {displayText}
          <RiErrorWarningLine size='0.8rem' className='opacity-70' />
          <ViewApiProgressPopup
            open={openProgressPopup}
            idxValues={idxValues}
            setOpen={setOpenProgressPopup}
          />
        </div>
        {!oneTimeSync && ongoing && (
          <div className='text-xs'>{indexingPercent}%</div>
        )}
        {!oneTimeSync && !ongoing && (
          <div className='text-xs'>{nextRefresh}</div>
        )}
      </>
    );
  };

  return (
    <div className='flex gap-4 bg-base-200 px-5 py-3 items-center'>
      <div className='flex-none'>
        <div className='flex flex-col'>
          <span className='text-primary font-semibold text-xs'>
            {indexingStatus}
          </span>
          <span className='text-xs opacity-80'>{lastRefresh}</span>
        </div>
      </div>
      <div className='flex-auto'>
        <div className='relative'>
          <div className='w-full h-1 bg-base-content bg-opacity-10'></div>
          <div
            className={classNames(
              'h-1 absolute top-0',
              isIndexing || dataIngestIntervalSeconds
                ? 'bg-primary'
                : 'bg-[#a1a4a9]'
            )}
            style={{ width: `${indexingPercent}%` }}
          ></div>
          <div className='absolute bottom-[-10px]'>
            <span
              className={classNames(
                'inline-block bg-primary rounded-full text-[6px] text-white p-[2px] w-[13px] h-[13px]',
                isIndexing || dataIngestIntervalSeconds
                  ? 'bg-primary'
                  : 'bg-[#a1a4a9]'
              )}
            ></span>
          </div>
          <div
            className={classNames('absolute bottom-[-10px]')}
            style={{ left: `${indexingPercent}%` }}
          >
            <span
              className={classNames(
                'inline-block bg-primary rounded-full text-[6px] text-white p-[2px] w-[13px] h-[13px]',
                isIndexing || dataIngestIntervalSeconds
                  ? 'bg-primary'
                  : 'bg-[#a1a4a9]'
              )}
            ></span>
          </div>
        </div>
      </div>
      <div className='flex-none flex flex-col pl-3'>{renderIndexingInfo()}</div>
    </div>
  );
};

export default ViewApiIndexingProgress;
