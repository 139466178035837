import { sum, mean, last, first, max, min, uniq } from 'lodash';
import { CommonChartEntity } from './types';

export const TransformTypes = [
  'sum',
  'average',
  'last - first',
  'first - last',
  'count',
  'unique count',
  'max',
  'min',
  'date'
] as const;
export type TransformType = typeof TransformTypes[number];

type LabelOption = {
  label?: string;
  style?: {
    fontSize?: number;
  };
};

export type NumberChartUiOptions = {
  dataOptions?: {
    formatStr?: string;
    showPercent?: boolean;
    xCol?: {
      colName?: string;
    };
    transformType?: TransformType;
  };
  labelOptions?: {
    labelAbove?: LabelOption;
    labelBelow?: LabelOption;
    prefix?: string;
    postfix?: string;
    align?: string;
    style: {
      padding?: number;
      fontSize?: number;
    };
  };
};

export interface NumberChartType extends CommonChartEntity {
  type: 'number';
  uiOptions: NumberChartUiOptions;
}

export type NumberChartConfig = Pick<
  NumberChartType,
  'uiOptions' | 'displayName'
>;

export const DefaultNumberChart: Omit<NumberChartType, 'id'> = {
  type: 'number',
  displayName: 'Big Number Chart',
  uiOptions: {
    dataOptions: {
      formatStr: '0.0a',
      transformType: 'sum',
    },
    labelOptions: {
      align: 'center',
      style: {
        fontSize: 50,
      },
    },
  },
};

export function transformData(values: number[], tfType?: TransformType) {
  switch (tfType) {
    case 'sum':
      return sum(values);
    case 'average':
      return mean(values);
    case 'last - first': {
      const v1 = last(values) || 0;
      const v2 = first(values) || 0;

      return v1 - v2;
    }
    case 'first - last': {
      const v1 = last(values) || 0;
      const v2 = first(values) || 0;

      return v2 - v1;
    }
    case 'count': {
      return values.length || 0;
    }
    case 'unique count': {
      return uniq(values).length || 0;
    }
    case 'max': {
      return max(values) || 0;
    }
    case 'min': {
      return min(values) || 0;
    }
    case 'date': {
      return new Date(values[0]).toISOString().slice(0, 10) || 0;
    }
  }

  return 0;
}

export function getCaption(values: number[], tfType?: TransformType) {
  switch (tfType) {
    case 'last - first': {
      const lastV = last(values) || 0;
      const firstV = first(values) || 0;

      const outcome = lastV - firstV;

      if (outcome === 0) return null;
      if (!firstV || firstV === 0) return null;

      return outcome / firstV;
    }
    case 'first - last': {
      const lastV = last(values) || 0;
      const firstV = first(values) || 0;

      const outcome = firstV - lastV;
      if (outcome === 0) return null;
      if (!lastV || lastV === 0) return null;

      return outcome / lastV;
    }
  }

  return null;
}
