/* eslint-disable @typescript-eslint/no-explicit-any */
import SwaggerClient from 'swagger-client';
// import { HTTPSnippet } from 'httpsnippet';
import Oas from 'oas';
import oasToSnippet from '@readme/oas-to-snippet';
import {
  getAllZettaApis,
  getAllZettaTables,
  getGraphQlApis,
  getProjectsWithChildren,
} from './apis';
import { getAuthHeader } from '../../api/client';

export const parseOpenapi = SwaggerClient.resolve;

export const getPaths = (openapi: any) => {
  if (!openapi) return [];
  const { paths } = openapi.spec;

  return Object.keys(paths);
};

export const convertToObject = (openapi: any) => {
  if (!openapi) return {};
  const paths = Object.keys(openapi.spec.paths);

  const res = paths.reduce((acc, cur) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(openapi.spec.paths[cur])) {
      // TODO: method + path - unique
      acc[cur] = {
        ...(value as any),
        method: key,
        path: cur,
      };
    }
    return acc;
  }, {});

  return res;
};

export const generateHttpSnippet = async (
  oasObj,
  path,
  method,
  data,
  lang: any = 'node'
) => {
  const { code } = await oasToSnippet(
    oasObj,
    oasObj.operation(path, method),
    data,
    null,
    lang
  );

  return code;
};

export const convertToOas = (openapiJsonString: string) => {
  const oasObj = new Oas(openapiJsonString);

  return oasObj;
};

export const fetchOpenapi = async (
  url: string,
  header,
  method,
  query,
  body,
  path
) => {
  const options: any = {
    headers: {
      ...header,
      ...getAuthHeader(),
    },
    method,
  };

  if (Object.keys(body).length > 0) {
    options.body = JSON.stringify(body);
  }
  let composedUrl = url;
  const queryStr = Object.keys(query)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`;
    })
    .join('&');

  Object.keys(path).forEach((key) => {
    composedUrl = composedUrl.replace(`{${key}}`, path[key]);
  });

  const urlWithQuery = `${composedUrl}?${queryStr || ''}`;

  try {
    const res = await fetch(urlWithQuery, options);

    if (!res.ok)
      return {
        error: res.statusText,
        status: res.status,
      };

    return res.json();
  } catch (error) {
    return error;
  }
};

export const getAllDefaultProjectTables = async () => {
  const tables = await getAllZettaTables();

  const tablesWithoutProject = tables
    .filter((table) => table.project_id === '')
    .filter((table) => table.status !== 2)
    .map((e) => ({
      ...e,
      type: 'table',
      key: e.id,
    }));

  return tablesWithoutProject;
};

export const composeDefaultProjectTables = async () => {
  const tablesWithoutProject = await getAllDefaultProjectTables();

  return {
    name: 'Default',
    id: 'default',
    type: 'folder',
    key: 'default',
    children: tablesWithoutProject,
  };
};

export const composeTableDataSource = async () => {
  // get all projects
  // get all tables by projects IDs
  const projectWithTables = await getProjectsWithChildren('table');
  const defaultProjectWithTables = await composeDefaultProjectTables();

  return [defaultProjectWithTables, ...projectWithTables];
};

export const getAllDefaultProjectApis = async () => {
  const apis = await getAllZettaApis();

  const apisWithoutProject = apis
    .filter((table) => table.project_id === '')
    .filter((table) => table.status !== 2)
    .map((e) => ({
      ...e,
      type: 'api',
      key: e.id,
    }));

  return apisWithoutProject;
};

export const composeDefaultProjectApis = async () => {
  const apisWithoutProject = await getAllDefaultProjectApis();

  return {
    id: 'default',
    name: 'Default',
    type: 'folder',
    key: 'default',
    children: apisWithoutProject,
  };
};

export const composeGqlApis = async () => {
  const apis = await getGraphQlApis();

  return apis.map((e) => ({
    ...e,
    created_at: e.created_time,
    updated_at: e.updated_time,
    owner: e.creator,
    type: 'graphql',
  }));

  // return {
  //   id: 'graphqlApis',
  //   name: 'GraphQL APIs',
  //   type: 'folder',
  //   key: 'graphqlApis',
  //   children: apis.map((e) => ({
  //     ...e,
  //     type: 'graphql',
  //   })),
  // };
};

export const composeApiDataSource = async () => {
  const projectWithApis = await getProjectsWithChildren('api');
  const defaultProjectWithApis = await composeDefaultProjectApis();
  const graphQlApis = await composeGqlApis();

  return [defaultProjectWithApis, ...projectWithApis, ...graphQlApis];
};
