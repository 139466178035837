import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { BsBell, BsBellFill } from 'react-icons/bs';
import { useLocation } from 'react-router';
import { atom, useRecoilState } from 'recoil';

const sessionStorageEffect =
  (key: string) =>
    // @ts-ignore
    ({ setSelf, onSet }) => {
      const savedValue = sessionStorage.getItem(key);
      if (savedValue != null) {
        setSelf(savedValue === 'true');
      }

      onSet((newValue: boolean, _: never, isReset: boolean) => {
        return isReset
          ? sessionStorage.removeItem(key)
          : sessionStorage.setItem(key, `${newValue}`);
      });
    };

export const AChangelogIndicator = atom<boolean>({
  key: 'AChangelogIndicator',
  default: true,
  effects: [sessionStorageEffect('changelog_indicator')],
});

export default function ChangelogNotification() {
  const [open, setOpen] = useState(false);
  const [indicator, setIndicator] = useRecoilState(AChangelogIndicator);
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <div
      className={classNames(
        'dropdown dropdown-end',
        open ? 'dropdown-open' : ''
      )}
    >
      <div
        className='indicator'
        onClick={() => {
          setOpen(!open);
          if (indicator) {
            setIndicator(false);
          }
        }}
      >
        <span
          className={classNames(
            'indicator-item indicator-middle indicator-center badge badge-error w-2 h-2 p-0 left-[34px] top-[12px] cursor-pointer',
            indicator ? 'visible' : 'hidden'
          )}
        ></span>

        <button
          className={classNames(
            'btn btn-ghost opacity-80 items-center hover:!bg-transparent'
            // open ? 'opacity-40' : 'opacity-80'
          )}
        >
          {open ? <BsBellFill size='1.3rem' /> : <BsBell size='1.3rem' />}
        </button>
      </div>
      <div
        className={classNames(
          'mt-3 shadow rounded-md text-right',
          'dropdown-content opacity-0 right-[-5px] bg-[#fff]',
          open ? 'visible' : 'hidden'
        )}
      >
        <div
          className='absolute z-1 w-[20rem] h-[57px] cursor-pointer opacity-0'
          onClick={() => {
            window.open('https://zettablock.readme.io/changelog', '_blank');
            setOpen(false);
          }}
        />
        <iframe
          id='zettablock-changelog'
          src='https://zettablock.readme.io/changelog.widget'
          title='cl'
          className='min-h-[15rem] min-h-[700px] changelog block border-0 overflow-hidden w-[20rem]  max-h-[80vh]'
          scrolling='no'
        ></iframe>
      </div>
    </div>
  );
}
