import Draggable from 'react-draggable'; // The default
import Icon from "../components/Icon"
import { useRef, useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { PreviewDataState } from './usePreviewData';
import LoadingIndicator from '../../components/LoadingIndicator';
import classNames from 'classnames';
import useOutsideAlerter from '../../modules/queryBuilder/utils/useOutsideAlerter';
import TableChart from '../../modules/chart/components/TableChart';
import { TDatabaseColumn } from '../../api/types';


// const PreviewTable = () => {
//   return <div>

//   </div>
// }

const DragabblePreview = ({
  previewData
}: {
  previewData: {
    key: string,
    rows: { [key: string]: string }[],
    metadata: {
      columns: TDatabaseColumn[],
    },
  }
}) => {
  const commandCardRef = useRef(null);
  const [zIndex, setZIndex] = useState(20)

  useOutsideAlerter(commandCardRef, () => {
    setZIndex(20)
  })

  const removePreviewData = useRecoilCallback(({ set }) => (key: string) => {
    set(PreviewDataState, (prev) => {
      const newPreviewData = { ...prev };
      delete newPreviewData[key];
      return newPreviewData;
    });
  })

  const [open, setOpen] = useState(true)
  const onToggle = () => {
    setOpen(!open)
  }

  return <Draggable
    handle=".cursor-grab"
    bounds="parent"
    defaultPosition={{ x: -0, y: -500 }}
  >
    <div
      ref={commandCardRef}
      onClick={() => { setZIndex(30) }}
      className={
        classNames("w-[600px] border rounded-[4px] bg-[#fff] absolute shadow-xl", `z-${zIndex}`,)
      } >
      <div className="flex items-center justify-between px-[15px] py-[10px] cursor-grab">
        <div className="flex items-center gap-[5px] ">
          <Icon iconName="previewtable" className="" />
          <div className="text-[14px] font-bold">
            Preview - {
              previewData.key
            }
          </div>
        </div>
        <div className="flex items-center gap-[20px] ">
          <div className="cursor-pointer" onClick={onToggle}>
            <Icon iconName="collap" />
          </div>
          <div className="cursor-pointer" onClick={() => {
            removePreviewData(previewData.key)
          }}>
            <Icon iconName="close" />
          </div>
        </div>
      </div>
      {
        open && <div className="p-[15px] h-[360px] overflow-auto">
          {
            previewData ? <div className='overflow-scroll'>
              <TableChart
                className='max-h-[90vh]'
                columns={previewData.metadata.columns.map((c: TDatabaseColumn) => c.name)}
                data={previewData.rows}
              />
            </div> : <LoadingIndicator />
          }
        </div>
      }
    </div>
  </Draggable>
}

export default DragabblePreview