import { sql } from '@codemirror/lang-sql';
import { useCodeMirror } from '@uiw/react-codemirror';
import { memo, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';

import './api-query-pad.scss';
import { ApiCreateValues } from './NewApiHook';
import { useApiId } from './ViewApiHooks';
import { codemirrorTheme } from '../../config/theme';

function IncrementalApiQueryPad() {
  const apiId = useApiId();
  const [values, setValues] = useRecoilState(ApiCreateValues(apiId));

  const editor = useRef<HTMLDivElement>();

  const props = useCodeMirror({
    theme: codemirrorTheme,
    readOnly: false,
    container: editor.current,
    extensions: [sql()],
    value: values.init?.[0] || '',
    onChange: (value) => {
      setValues({
        ...values,
        init: [value],
      });
    },
  });
  useEffect(() => {
    if (editor.current) {
      // eslint-disable-next-line react/prop-types
      props.setContainer(editor.current);
    }
  }, [editor.current]);

  useEffect(() => {
    if (view) {
      window.view = view;
    }
    const interval = setInterval(() => {
      if (view) {
        window.view = view;
      }
    }, 500);
    return () => clearInterval(interval);
  }, [view]);

  return (
    <div className='overflow-auto flex flex-col relative  h-full'>
      <div
        key='editor'
        className='flex-auto overflow-auto'
        ref={editor as React.RefObject<HTMLDivElement>}
      />
    </div>
  );
}

export default memo(IncrementalApiQueryPad);
