import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { BsFillGrid3X3GapFill, BsFillPersonFill } from 'react-icons/bs';
import { ImDatabase } from 'react-icons/im';
import { VscFiles } from 'react-icons/vsc';
import { toast } from 'react-toastify';

import csvfile from '../../assets/icons/csv.svg';
import postg from '../../assets/icons/postgres 1.svg';
import s3 from '../../assets/icons/Amazon-S3-Logo.svg';
import mongo from '../../assets/icons/mongo.svg';
import mysql from '../../assets/icons/mysql 1.svg';
import googlesheet from '../../assets/icons/gsheets 2.svg';
import snowflake from '../../assets/icons/snowflake.svg';
import databricks from '../../assets/icons/databricks.svg';
import googleBigquery from '../../assets/icons/google-bigquery.svg';

import ConnectTypeButton from '../connectors/components/ConnectTypeButton';
import { TConnectType } from '../connectors/csv/types';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import { FaBook } from 'react-icons/fa';

enum SupportType {
  COMMUNITY = 'COMMUNITY',
  ENTERPRISE = 'ENTERPRISE',
}

const DataSourceList = [
  {
    name: 'CSV File',
    urlParam: 'csv',
    type: 'file',
    icon: <img src={csvfile} />,
    inProd: true,
    badgeLabel: 'alpha',
    supportType: SupportType.COMMUNITY,
  },
  {
    name: 'MongoDB',
    urlParam: 'mongodb',
    type: 'database',
    icon: <img src={mongo} />,
    inProd: true,
    badgeLabel: 'alpha',
    supportType: SupportType.ENTERPRISE,
  },
  {
    name: 'PostgreSQL',
    type: 'database',
    icon: <img src={postg} />,
    inProd: true,
    badgeLabel: 'alpha',
    supportType: SupportType.ENTERPRISE,
  },
  {
    name: 'Snowflake',
    type: 'data warehouse',
    icon: <img src={snowflake} style={{ height: 80 }} />,
    inProd: true,
    supportType: SupportType.ENTERPRISE,
    badgeLabel: 'alpha',
    beta: true,
  },
  {
    name: 'Google BigQuery',
    type: 'data warehouse',
    icon: <img src={googleBigquery} style={{ height: 80 }} />,
    inProd: true,
    supportType: SupportType.ENTERPRISE,
    badgeLabel: 'alpha',
    beta: true,
  },
  {
    name: 'Databricks',
    type: 'data warehouse',
    icon: <img src={databricks} style={{ height: 80 }} />,
    inProd: true,
    supportType: SupportType.ENTERPRISE,
    badgeLabel: 'alpha',
    beta: true,
  },
  {
    name: 'Amazon S3',
    type: 'engineering',
    icon: <img src={s3} style={{ height: 80 }} />,
    inProd: true,
    supportType: SupportType.ENTERPRISE,
    badgeLabel: 'alpha',
    beta: true,
  },
  {
    name: 'Google Sheets',
    type: 'file',
    icon: <img src={googlesheet} />,
    inProd: false,
    supportType: SupportType.COMMUNITY,
  },
  {
    name: 'MySQL',
    type: 'database',
    icon: <img src={mysql} />,
    inProd: false,
    supportType: SupportType.ENTERPRISE,
  },
];

export default function DataSourcesListPageV3() {
  const [, { getPath, navigate }] = useWorkspace();
  const [connectType, setConnectType] = useState<TConnectType>('all');

  const displayCommunityAndEnterprise = useMemo(() => {
    return {
      commity: DataSourceList.filter((t) => {
        if (connectType === 'all') {
          return t.supportType === SupportType.COMMUNITY;
        }

        return (
          t.type === connectType && t.supportType === SupportType.COMMUNITY
        );
      }),
      enterprise: DataSourceList.filter((t) => {
        if (connectType === 'all') {
          return t.supportType === SupportType.ENTERPRISE;
        }

        return (
          t.type === connectType && t.supportType === SupportType.ENTERPRISE
        );
      }),
    };
  }, [connectType]);

  return (
    <div className='explorer flex flex-col w-full p-3 overflow-x-auto'>
      {/* body */}
      <div className='pl-6 flex'>
        <div className='py-6 w-72'>
          <ConnectTypeButton
            className='mb-2'
            active={connectType === 'all'}
            onClick={() => {
              setConnectType('all');
            }}
          >
            <div className='flex flex-1 items-center'>
              <BsFillGrid3X3GapFill size='1.2rem' className='opacity-50' />
              <div className='inline w-3' />
              All data sources
            </div>
          </ConnectTypeButton>
          <ConnectTypeButton
            className='mb-2'
            active={connectType === 'database'}
            onClick={() => {
              setConnectType('database');
            }}
          >
            <div className='flex flex-1 items-center'>
              <ImDatabase size='1.2rem' className='opacity-50' />
              <div className='inline w-3' />
              Databases
            </div>
          </ConnectTypeButton>
          <ConnectTypeButton
            className='mb-2'
            active={connectType === 'data warehouse'}
            onClick={() => {
              setConnectType('data warehouse');
            }}
          >
            <div className='flex flex-1 items-center'>
              <FaBook size='1.2rem' className='opacity-50' />
              <div className='inline w-3' />
              Data Warehouse
            </div>
          </ConnectTypeButton>
          <ConnectTypeButton
            className='mb-2'
            active={connectType === 'engineering'}
            onClick={() => {
              setConnectType('engineering');
            }}
          >
            <div className='flex flex-1 items-center'>
              <BsFillPersonFill size='1.2rem' className='opacity-50' />
              <div className='inline w-3' />
              Engineering
            </div>
          </ConnectTypeButton>
          <ConnectTypeButton
            className='mb-2'
            active={connectType === 'file'}
            onClick={() => {
              setConnectType('file');
            }}
          >
            <div className='flex flex-1 items-center'>
              <VscFiles size='1.2rem' className='opacity-50' />
              <div className='inline w-3' />
              Files
            </div>
          </ConnectTypeButton>
        </div>
        {/* vertical line */}
        <div className='pl-6 flex'>
          <div className='border-l opacity-60' />
        </div>
        {/* list */}
        <div className='p-3 w-full'>
          <div className='flex flex-wrap'>
            {!!displayCommunityAndEnterprise.commity.length && (
              <>
                <div className='w-full p-3 flex items-center'>
                  <BsFillPersonFill size='1.5rem' className='opacity-50 mr-1' />
                  Community Support
                </div>
                {displayCommunityAndEnterprise.commity.map((t) => (
                  <div
                    key={t.name}
                    className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex'
                    onClick={() => {
                      if (t.inProd) {
                        navigate(
                          `${getPath('data-sources')}/type/${
                            t.urlParam || t.name
                          }`
                        );
                      } else {
                        toast.info('Coming Soon!');
                      }
                    }}
                  >
                    <div
                      className={classNames(
                        'relative m-3 w-full bg-base-200 rounded-xl hover:bg-primary hover:bg-opacity-10 cursor-pointer',
                        { 'opacity-30': !t.inProd }
                      )}
                    >
                      <div className='my-3 h-36 flex flex-col justify-center items-center'>
                        {t.icon && <div className='mb-2'>{t.icon}</div>}
                        <div className='font-bold'>{t.name}</div>
                        <div className='font-light text-xs opacity-60 capitalize'>
                          {t.type}
                        </div>
                        {!t.inProd && (
                          <div className='absolute bg-base-300 h-5 w-full bottom-0 rounded-b-xl flex items-center justify-center text-xs font-bold'>
                            Coming Soon
                          </div>
                        )}
                        {t.badgeLabel && (
                          <span className='absolute indicator-item badge badge-lg badge-secondary top-[-0.3rem] right-[-0.3rem]'>
                            {t.badgeLabel}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex'
                  onClick={() => {
                    toast.info('Coming Soon!');
                  }}
                >
                  <div className='m-3 w-full bg-base-200 rounded-xl hover:bg-primary hover:bg-opacity-10 cursor-pointer'>
                    <div className='my-3 h-36 flex flex-col justify-center items-center'>
                      <div className='font-normal text-md opacity-60'>MORE</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className='flex flex-wrap'>
            {!!displayCommunityAndEnterprise.enterprise.length && (
              <>
                <div className='w-full p-3 flex items-center'>
                  <ImDatabase size='1.2rem' className='opacity-50 mr-2' />
                  Enterprise Support
                </div>
                {displayCommunityAndEnterprise.enterprise.map((t) => (
                  <div
                    key={t.name}
                    className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex'
                    onClick={() => {
                      if (t.inProd) {
                        if (t.beta) {
                          navigate(`${getPath('data-sources')}/type/beta`);
                          return;
                        }
                        navigate(
                          `${getPath('data-sources')}/type/${
                            t.urlParam || t.name
                          }`
                        );
                      } else {
                        toast.info('Coming Soon!');
                      }
                    }}
                  >
                    <div
                      className={classNames(
                        'relative m-3 w-full bg-base-200 rounded-xl hover:bg-primary hover:bg-opacity-10 cursor-pointer',
                        { 'opacity-30': !t.inProd }
                      )}
                    >
                      <div className='my-3 h-36 flex flex-col justify-center items-center'>
                        {t.icon && <div className='mb-2'>{t.icon}</div>}
                        <div className='font-bold'>{t.name}</div>
                        <div className='font-light text-xs opacity-60 capitalize'>
                          {t.type}
                        </div>
                        {!t.inProd && (
                          <div className='absolute bg-base-300 h-5 w-full bottom-0 rounded-b-xl flex items-center justify-center text-xs font-bold'>
                            Coming Soon
                          </div>
                        )}
                        {t.badgeLabel && (
                          <span className='absolute indicator-item badge badge-lg badge-secondary top-[-0.3rem] right-[-0.3rem]'>
                            {t.badgeLabel}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  className='w-full sm:w-1/2 md:w-1/3 lg:w-1/4 flex'
                  onClick={() => {
                    toast.info('Coming Soon!');
                  }}
                >
                  <div className='m-3 w-full bg-base-200 rounded-xl hover:bg-primary hover:bg-opacity-10 cursor-pointer'>
                    <div className='my-3 h-36 flex flex-col justify-center items-center'>
                      <div className='font-normal text-md opacity-60'>MORE</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
