import { AxiosError } from 'axios';
import { MyApiV2 } from '../../api/client';

export async function getFetchApiKeys(billingCycle = 0) {
  try {
    const client = MyApiV2();
    const resp = await client.apikeysList({
      billingCycle,
    });
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}
