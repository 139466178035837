import { keys, set, unset } from 'lodash';

export function removeKeyRecursive(obj: object, key: string) {
  if (!obj) return;
  const ObjKeys = Object.keys(obj);

  ObjKeys.forEach((oKey) => {
    if (oKey === key) {
      // @ts-ignore
      delete obj[oKey];
    }
    // @ts-ignore
    else if (typeof obj[oKey] === 'object') {
      // @ts-ignore
      removeKeyRecursive(obj[oKey], key);
    }
  });
}

export function setDeep<InputObject>(
  obj: InputObject,
  // eslint-disable-next-line
  valueMap: Record<string, any>
): InputObject {
  keys(valueMap).forEach((key) => {
    const value = valueMap[key];

    if (value === null) {
      unset(obj, key);
    } else {
      // @ts-ignore
      set(obj, key, value);
    }
  });

  return obj;
}
