/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, HttpClient, RequestParams } from './http-client';

export class Texttosql<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description convert a natural language question to a formatted SQL query
   *
   * @name TexttosqlList
   * @summary convert a natural language question to a formatted SQL query
   * @request GET:/texttosql
   */
  texttosqlList = (
    query: { question: string; db: string; table: string; queryId: string },
    params: RequestParams = {}
  ) =>
    this.request<string, string>({
      path: `/texttosql`,
      method: 'GET',
      query: query,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
