/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, HttpClient, RequestParams } from './http-client';

export class Source<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description gets results from Athena and stores in qugate database as cache
   *
   * @tags example
   * @name DatabaseTablePreviewDetail
   * @summary gets results from Athena and stores in qugate database as cache
   * @request GET:/source/{source}/database/{database}/table/{table}/preview
   */
  databaseTablePreviewDetail = (
    source: string,
    database: string,
    table: string,
    params: RequestParams = {}
  ) =>
    this.request<string, any>({
      path: `/source/${source}/database/${database}/table/${table}/preview`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
