import { RiSkipForwardLine, RiSwapFill } from 'react-icons/ri';

import LoadingIndicator from '../../components/LoadingIndicator';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import { useNewApiStep2 } from './NewApiHook';
import ApiQueryPad from './ApiQueryPad';
import LogoBlinkerCenter from '../../components/app/LogoBlinkerCenter';
import TableColumns from './TableColumns';
import VersionNameSelector from './VersionNameSelector';
import classNames from 'classnames';
import produce from 'immer';
import { useRecoilState } from 'recoil';
import { useRpcStatusV3 } from '../../hooks/network';
import { SQueryResults } from '../queryBuilder/QueryState';
import { useQueryIdSearchParams } from './ViewApiHooks';
import { INTERVAL_SELECTIONS } from './const';
// import GroupByTableColumns from './GroupByTableColumns';
import { convertDBParamsStrToParams } from '../queryBuilder/QueryPadState';
import IncrementalSelect from './IncrementalSelect';
import { useMemo, useState, useEffect } from 'react';
import IncrementalApiQueryPad from './IncrementalApiQueryPad';
import { IoInformationCircleSharp } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { analytics } from '../../App';
import useTitle from '../../hooks/useTitle';

export default function NewApiStep2() {
  const [
    { rpcStatusQueryLoading, rpcStatusCreating, values, queryDetail },
    { createNewApi, setValues },
  ] = useNewApiStep2();

  useTitle('API', 'New API')

  const [{ isMyWorkspace }] = useWorkspace();
  const queryId = useQueryIdSearchParams();

  const [queryResults] = useRecoilState(SQueryResults(queryId));
  const [rpcStatusResults] = useRpcStatusV3('new-api-step2-query');

  const columns = queryResults?.results?.metadata?.columns || [];

  const error = queryResults?.error || rpcStatusResults.error;

  const hasParamsStr =
    queryDetail?.paramsStr &&
    convertDBParamsStrToParams(queryDetail?.paramsStr).length > 0;

  const [selectedTab, setSelectedTab] = useState('apiQuery');

  const resetIncrementalRelatedFields = () => {
    // resultPrimaryKey
    // reset resultWriteMode
    setValues({
      ...values,
      resultPrimaryKey: [],
      resultWriteMode: undefined,
      init: undefined,
      resultDatabase: 'zettadb1',
    });
    // reset selectedTab
    setSelectedTab('apiQuery');
  };

  useEffect(() => {
    resetIncrementalRelatedFields();
  }, []);

  const isAppendMode = useMemo(() => {
    return values.resultWriteMode === 'Append';
  }, [values]);

  return (
    <>
      <div className='flex'>
        <div className='flex-1 flex items-center space-x-3'>
          <div>
            <RiSwapFill size='2.2rem' className='text-primary' />
          </div>
          <div className='w-full min-w-[24rem] max-w-md relative items-center'>
            <input
              type='text'
              className='input w-full min-w-[24rem] pr-[4.4rem] font-bold text-lg'
              value={values.displayName || ''}
              onChange={(e) =>
                setValues(
                  produce(values, (draft) => {
                    draft.displayName = e.target.value;
                  })
                )
              }
            />
            {/* {NOTE: Commenting out as it is causing confusion - API by default is org_internal or public(community users)} */}
            {/* <span className='absolute right-[0.4rem] self-center bg-base-content bg-opacity-20 py-1 px-2 text-xs rounded opacity-50 font-bold top-[0.4rem]'>
              Private
            </span> */}
          </div>
          <div className='flex-1'>
            Step 2: Input API Information and check SQL code
          </div>
        </div>
        <div className='flex items-center'>
          <div
            className={classNames('flex space-x-3', {
              'tooltip opacity-90': !!hasParamsStr,
            })}
            data-tip='Query with parameters is not supported yet'
          >
            <button
              className={classNames('btn btn-primary gap-2', {
                loading: rpcStatusCreating.isLoading,
                'opacity-80': !!error,
              })}
              disabled={!!hasParamsStr}
              onClick={() => {
                if (error) {
                  return;
                }

                // adding analytics traking code
                analytics.track('API - Create api button clicked', {})

                createNewApi().then(() => {
                  analytics.track('API - API created', {})
                });
              }}
            >
              {!rpcStatusCreating.isLoading && (
                <RiSkipForwardLine size='1.1rem' />
              )}
              Create API
            </button>
          </div>
        </div>
      </div>
      <div className='h-6' />

      {isMyWorkspace && (
        <div className='flex bg-base-content bg-opacity-20 items-center'>
          <div className='flex-1 p-2 font-bold opacity-70'>
            STEP 2: INPUT API INFORMATION AND CHECK SQL CODE
          </div>
        </div>
      )}

      <div className='lg:basis-2/5 basis-full bg-base-100'>
        <div className='flex'>
          <div className='w-[20rem] flex-shrink-0 flex-grow-0'>
            {rpcStatusQueryLoading.isLoading ? (
              <div className='flex items-center justify-center py-10'>
                <LoadingIndicator />
              </div>
            ) : (
              <>
                <div className='hidden'>
                  <VersionNameSelector
                    title={'VERSION NAME'}
                    disabled
                    value={'Community Public Data'}
                    onSelect={() => { }}
                    showLabel
                  />
                  <div className='border-b' />
                </div>



                {
                  (queryDetail.database?.startsWith('realtime') || queryDetail.database === 'realtimeEvmDB') && <div className=' w-full flex item-start pl-2 py-2 items-center'> {/* Note(jjin) hide for now */}
                    <label className='label flex opacity-50 max-w-[60%] w-full'>
                      <span className='font-semibold text-[10px]'>
                        Real Time API
                      </span>
                    </label>
                    <div className='flex-1 text-right pr-3'><input
                      onChange={(e) => {
                        setValues(produce(values, (draft) => {
                          draft.isRealTimeApi = Boolean(e.target.checked);
                          draft.resultWriteMode = undefined;
                        }))
                      }}
                      checked={values.isRealTimeApi}
                      type='checkbox'
                      className='toggle toggle-sm toggle-primary'
                    /></div>
                  </div>
                }

                {/* interval selector */}

                {!values.isRealTimeApi && <div className='w-full flex item-start pl-2 py-2 items-center'>
                  <label className='label flex opacity-50 max-w-[60%] w-full'>
                    <span className='font-semibold text-[10px]'>
                      DATA REFRESH INTERVAL
                    </span>
                  </label>
                  <select
                    className='select select-xs w-full no-outline py-0 min-h-[5rem] max-w-[40%] bg-transparent font-normal active:outline-none focus:outline-none'
                    onChange={async (e) => {
                      setValues(
                        produce(values, (draft) => {
                          draft.dataIngestIntervalSeconds = parseInt(
                            e.target.value,
                            10
                          );
                        })
                      );
                    }}
                  >
                    {INTERVAL_SELECTIONS.filter(e => e.value !== -1).map((n) => (
                      <option key={n.value} value={n.value}>
                        {n.label}
                      </option>
                    ))}
                  </select>
                </div>}

                <div
                  className={classNames('w-full flex items-start pl-2 py-2')}
                >
                  <label className='label flex opacity-50 max-w-[33%] w-full'>
                    <span className='font-semibold text-[10px]'>
                      DESCRIPTION
                    </span>
                  </label>
                  <textarea
                    className='textarea w-full no-outline py-0 min-h-[5rem]'
                    placeholder='input description'
                    onChange={(e) => {
                      setValues(
                        produce(values, (draft) => {
                          draft.description = e.target.value;
                        })
                      );
                    }}
                  />
                </div>

                <div className='border-b' />

                <div className='hidden'>
                  <div
                    className={classNames('w-full flex items-start pl-2 py-2')}
                  >
                    <label className='label flex opacity-50 max-w-[33%] w-full'>
                      <span className='font-semibold text-[10px] whitespace-nowrap'>
                        RESULT DATABASE
                      </span>
                    </label>
                    <input
                      className='input w-full no-outline py-0 min-h-[5rem]'
                      placeholder='Database name'
                      value={values.resultDatabase}
                      onChange={(e) => {
                        setValues(
                          produce(values, (draft) => {
                            draft.resultDatabase = e.target.value;
                          })
                        );
                      }}
                    />
                  </div>
                  <div className='border-b' />
                </div>

                {!values.isRealTimeApi && <IncrementalSelect
                  setSelectedTab={setSelectedTab}
                  resetIncrementalRelatedFields={resetIncrementalRelatedFields}
                />}
                {
                  values.isRealTimeApi && <div className='flex flex-col px-3 pb-2 gap-y-2 mt-2'><div className='label font-semibold  flex items-center justify-between p-0'>
                    <span className=' text-[10px] font-semibold flex items-center'>
                      <span className='opacity-50'>SET PRIMARY KEY</span>
                      <div
                        className='tooltip ml-1'
                        data-tip='A unique and not null key in this API table.'
                      >
                        <IoInformationCircleSharp className='opacity-50' />
                      </div>
                    </span>
                    <input
                      onBlur={(e) => {
                        const primaryKeyArr = e.target.value.split(',');
                        if (
                          primaryKeyArr.length > 0 &&
                          primaryKeyArr[primaryKeyArr.length - 1] === ''
                        ) {
                          primaryKeyArr.pop();
                        }

                        // validate
                        const isValidKeyArr = primaryKeyArr.every((key) => {
                          return columns.some((col) => col.name === key);
                        });
                        if (!isValidKeyArr) {
                          toast.warn(
                            'Please make sure that keys are listed in the list provided'
                          );
                        }

                        setValues({
                          ...values,
                          resultPrimaryKey: primaryKeyArr,
                        });
                      }}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          resultPrimaryKey: e.target.value.split(','),
                        });
                      }}
                      value={values.resultPrimaryKey?.join(',') || ''}
                      placeholder='key1, key2'
                      className='placeholder:opacity-50 opacity-100 p-1'
                    />
                  </div>
                  </div>
                }

                {rpcStatusQueryLoading.isLoading ? (
                  <div className='flex-1 overflow-hidden'>
                    <LogoBlinkerCenter />
                  </div>
                ) : (
                  <TableColumns />
                )}

                {/* <div className='pt-4' />
// Temp removed as per Chi's request 
                <GroupByTableColumns /> */}

                <div className='h-10' />
              </>
            )}
          </div>
          <div className='divider-y' />
          {rpcStatusQueryLoading.isLoading ? (
            <div className='flex-1 overflow-hidden'>
              <LogoBlinkerCenter />
            </div>
          ) : (
            <div className='flex-1 overflow-hidden '>
              <div className='tabs'>
                <a
                  onClick={() => {
                    setSelectedTab('apiQuery');
                  }}
                  className={classNames(
                    'tab',
                    selectedTab === 'apiQuery' ? 'tab-active' : ''
                  )}
                >
                  API TRANSFORMATION CODE{' '}
                  {isAppendMode && (
                    <div
                      className='tooltip ml-1 tooltip-bottom'
                      data-tip='This SQL code is used to materialize and backfill a table in the database for the API. With incremental refresh, the code will be executed only once in the beginning of API creation, and the future update will rely on the Incremental SQL code. '
                    >
                      <IoInformationCircleSharp className='opacity-50' />
                    </div>
                  )}
                </a>
                {isAppendMode && (
                  <a
                    onClick={() => {
                      setSelectedTab('incrementalApiQuery');
                    }}
                    className={classNames(
                      'tab',
                      selectedTab === 'apiQuery' ? '' : 'tab-active'
                    )}
                  >
                    INCREMENTAL SQL CODE
                    <div
                      className='tooltip ml-1 tooltip-bottom'
                      data-tip='This SQL code is used to partially refresh the data, which could greatly reduce the cost by controlling how much data to scan and how to scan. The incremental SQL schema must be  compatible with the API transformation SQL schema. .'
                    >
                      <IoInformationCircleSharp className='opacity-50' />
                    </div>
                  </a>
                )}
              </div>
              {selectedTab === 'apiQuery' ? (
                <div className='bg-[#f3f3f3] h-[calc(100%-32px)]'>
                  <ApiQueryPad />
                </div>
              ) : (
                <div className=' h-[calc(100%-32px)]'>
                  <IncrementalApiQueryPad />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
