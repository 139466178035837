/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import BreadCrumb from "../components/BreadCrumb"
import { useEffect, useMemo, useState } from "react"
import classNames from "classnames"
import FilterGroup from "../explore/components/FilterGroup"
import { ModelSearchResponse } from "../../api/__gen__/data-contracts"
import { getSearchResult } from "../components/search-bar/api"
import LoadingIndicator from "../../components/LoadingIndicator"
import queryIcon from './queryicon.svg'
import moment from "moment"
import { RiDashboardFill } from "react-icons/ri"
import { AiOutlineTable } from "react-icons/ai"
import { FaTable } from "react-icons/fa"
import Icon from "../components/Icon"
import { Pagination } from "antd"

export const SearchResultsTabs = ({
  setCurrentTab,
  currentTab = 'graphql_res',
  searchResult
}: any) => {
  const TABS = [
    // {
    //   name: 'apis',
    //   label: 'APIs',
    // },
    {
      name: 'tables',
      label: 'Tables',
    },
    {
      name: 'queries',
      label: 'Queries',
    },
    {
      name: 'dashboards',
      label: 'Dashboards',
    },
    {
      name: 'graphql_res',
      label: 'APIs',
    }
  ]

  return <div role="tablist" className="tabs">
    {
      TABS.map((tab, index) => {
        return <a role="tab" className={classNames("tab mb-[-2px] px-0 mr-4 h-[2.5rem] font-semibold", {
          'tab-active border-b ': tab.name === currentTab
        })} key={index} onClick={() => {
          setCurrentTab(tab.name)
        }}>{tab.label} ({searchResult[tab.name]?.length || 0})</a>
      })
    }
  </div>
}

const SearchResultsItem = ({ item, name, description, type, keyword }: any) => {

  switch (type) {
    case 'tables':
      return <Link to={`/v2/explore/tables/${item.project_id || 'default'}/${item.id}?from=search&keyword=${keyword}`}>
        <div className="mt-10 flex items-center gap-6">
          <div>
            <div className="w-10 h-10 bg-[#f6f7f9] rounded-lg flex items-center justify-center">
              <Icon
                iconName="top"
              />
            </div>
          </div>
          <div>
            <div className="font-semibold">
              {
                name || 'No name'
              }
            </div>
            <div className="font-semibold text-[#777A80] mt-1 flex items-center text-[12px]">
              {
                item.database || 'Unknown'
              }
              {
                item.chains?.[0] && <Icon iconName={item.chains?.[0]} className="ml-1 w-[14px] h-[14px]" />
              }
            </div>
            <div className="text-sm text-[#777A80] mt-1">

            </div>
          </div>
        </div>
      </Link>
    case 'apis':
      return <Link to={`/v2/explore/apis/${item.project_id || 'default'}/overview?from=search&keyword=${keyword}`}>

        <div className="mt-10">
          <div className="font-bold text-lg flex gap-3 items-center">
            {
              name || 'No name'
            }
            <img className="object-cover h-[20px] w-[20px] rounded-full" src={item.image} />
          </div>
          <div className="mt-2 text-[#777A80] ">
            {
              item.tenant || 'Unknown'
            }
          </div>
          <div className="text-sm text-[#777A80] mt-1">
            {
              description || 'No description'
            }
          </div>
        </div>
      </Link>
    case 'queries':
      return <Link to={`/v2/build/${item.id}?from=search&keyword=${keyword}`}>
        <div className="mt-10 flex items-center justify-between cursor-pointer">
          <div>
            <div className="flex items-center gap-6 font-bold text-lg">
              <div className="w-10 h-10 bg-[#f6f7f9] rounded-lg flex items-center justify-center"><Icon
                iconName="docs"
              />
              </div>
              <div className=" text-[#777A80] font-normal">

                <div className="font-semibold text-[#333]">
                  {
                    name || 'No name'
                  }
                </div>
                <div className="text-[12px]">
                  {
                    item.creator?.name || 'Unknown'
                  }
                </div>

              </div>

            </div>


          </div>
          <div className="text-[#777A80] text-md">
            updated {" "}
            {
              moment(item.updated_time).fromNow()
            }
          </div>
        </div>
      </Link>
    case 'dashboards':
      return <Link to={`/v2/explore/dashboards/${item.id}?from=search&keyword=${keyword}`}>
        <div className="mt-10 flex items-center justify-between cursor-pointer">
          <div>
            <div className="flex items-center gap-6 text-lg">
              <div className="w-10 h-10 bg-[#f6f7f9] rounded-lg flex items-center justify-center"><Icon iconName="window" /></div>
              <div>
                <div className=" font-bold">
                  {name || 'No name'}
                </div>
                <div className="text-[12px]">
                  {
                    item.creator?.name || 'Unknown'
                  }
                </div>
              </div>
            </div>

          </div>
          <div className="text-[#777A80] text-md">
            updated {" "}
            {
              moment(item.updated_time).fromNow()
            }
          </div>
        </div>
      </Link>
    case 'graphql':
      return <Link to={`/v2/explore/graphql-apis/${item.id}/overview?from=search&keyword=${keyword}`}>
        <div className="mt-10 flex items-center gap-6">
          <div>
            <div className="w-10 h-10 bg-[#f6f7f9] rounded-lg flex items-center justify-center">
              <Icon
                iconName="cloud-"
              />
            </div>
          </div>
          <div className="flex-1">
            <div className="font-semibold">
              {
                name || 'No name'
              }
            </div>
            <div className="font-semibold text-[#777A80] mt-1 flex items-center text-[12px]">
              {
                item.creator?.name || 'Unknown'
              }
            </div>
            <div className="text-sm text-[#777A80] mt-1">

            </div>
          </div>
          <div className="text-[#777A80] text-md">
            updated {" "}
            {
              moment(item.updated_time).fromNow()
            }
          </div>
        </div>
      </Link>

    default:
      return <div className="mt-3">
        <div className="font-semibold">
          {
            name || 'No name'
          }
        </div>
        <div className="text-sm text-[#999ca0]">
          {
            description || 'No description'
          }
        </div>
      </div>
  }
}

const sliceResults = (results: any[], page: number) => {
  const start = page * 10
  const end = start + 10
  return results.slice(start, end)
}

const SearchResults = () => {
  const [pagination, setPagination] = useState(1)




  const [searchResult, setSearchResult] = useState<ModelSearchResponse>({
    apis: [],
    graphql_res: [],
    tables: [],
    dashboards: [],
    queries: []
  })

  const [isLoading, setIsLoading] = useState(false)

  // TODO: make this as a provider and use it in other components
  const fetchAndSetResult = async (keyword: string) => {
    setIsLoading(true)
    const res = await getSearchResult(keyword)

    setSearchResult(res.data)

    setIsLoading(false)

    // setData
  }

  // search params
  const [searchParams] = useSearchParams()
  const keyword = searchParams.get('keyword')
  const type = searchParams.get('type')

  useEffect(() => {
    if (keyword) {
      fetchAndSetResult(keyword)
    }
  }, [keyword])



  const [currentTab, setCurrentTab] = useState(type || 'tables')

  useEffect(() => {
    if (type) {
      setCurrentTab(type)
    }
  }, [type])

  const renderSearchResults = () => {
    if (isLoading) return <div
      className="flex justify-center items-center h-[300px] w-full"
    >
      <LoadingIndicator />
    </div>
    switch (currentTab) {
      // case 'apis':
      //   return <div>
      //     {searchResult.apis?.length && searchResult.apis?.length > 0 ? searchResult.apis?.map((item, index) => {
      //       return <SearchResultsItem
      //         type="apis"
      //         key={index}
      //         name={item.display_name}
      //         description={item.summary}
      //         item={item}
      //       />
      //     }) : <div className="text-[#999ca0] text-center mt-10">No results found</div>
      //     }
      //   </div>
      case 'graphql_res':
        return <div>
          {searchResult.graphql_res?.length && searchResult.graphql_res?.length > 0 ? sliceResults(searchResult.graphql_res ?? [], pagination - 1)?.map((item, index) => {
            return <SearchResultsItem
              type="graphql"
              key={index}
              name={item.display_name}
              description={item.description}
              item={item}
              keyword={keyword}
            />
          }) : <div className="text-[#999ca0] text-center mt-10">No results found</div>
          }
        </div>
      case 'queries':
        return <div>
          {searchResult.queries?.length && searchResult.queries?.length > 0 ? sliceResults(searchResult.queries ?? [], pagination - 1)?.map((item, index) => {
            return <SearchResultsItem
              keyword={keyword}
              type="queries"
              key={index}
              name={item.display_name}
              description={item.description}
              item={item}
            />
          }) : <div className="text-[#999ca0] text-center mt-10">No results found</div>
          }
        </div>
      case 'dashboards':
        return <div>
          {searchResult.dashboards?.length && searchResult.dashboards?.length > 0 ? sliceResults(searchResult.dashboards ?? [], pagination - 1)?.map((item, index) => {
            return <SearchResultsItem
              keyword={keyword}
              type="dashboards"
              item={item}
              key={index}
              name={item.display_name}
              description={item.description}
            />
          }) : <div className="text-[#999ca0] text-center mt-10">No results found</div>}
        </div>
      case 'tables':
        return <div>
          {searchResult.tables?.length && searchResult.tables?.length > 0 ? sliceResults(searchResult.tables ?? [], pagination - 1)?.map((item, index) => {
            return <SearchResultsItem
              type="tables"
              keyword={keyword}
              item={item}
              key={index}
              name={item.name}
              description={item.summary}
            />
          }) : <div className="text-[#999ca0] text-center mt-10">No results found</div>}
        </div>

      default:
        return null
    }
  }

  const numberOfResults = useMemo(() => {
    return (searchResult.graphql_res?.length || 0) + (searchResult.queries?.length || 0) + (searchResult.dashboards?.length || 0) + (searchResult.tables?.length || 0)
  }, [searchResult])
  const navigate = useNavigate()

  return <div>
    {/* Breadcrumb */}
    <div className="border-b px-[30px] py-[15px] flex justify-between items-center">
      <div className="flex gap-4 items-center text-[#282828] cursor-pointer" onClick={() => {
        navigate('/v2/explore')
      }}>
        <Icon iconName='back' className="w-[30px] h-[30px]" />
        <div className="font-semibold text-[14px]">
          Search Results for {`"${keyword}"`}
        </div>
      </div>
    </div>
    <div className="px-[80px] py-10">

      {/* Search Results Title */}
      <div>
        {/* Result */}
        <div className="text-3xl mb-1 font-semibold">
          Search Results for {`"${keyword}"`}
        </div>
        <div className="text-[#999ca0] font-semibold">
          {
            numberOfResults
          } results found
        </div>
      </div>

      {/* Search Result Tabs */}
      <div className="mt-6">

        <div className="border-b w-full flex justify-between items-center">
          <SearchResultsTabs setCurrentTab={setCurrentTab} currentTab={currentTab} searchResult={searchResult} />
          {/* disabled for now */}
          {/* <FilterGroup /> */}
        </div>
      </div>

      <div className="mt-6">
        {renderSearchResults()}
      </div>

      <div className="mt-[30px] w-full flex items-center justify-center">
        <Pagination
          current={pagination}
          total={searchResult[currentTab]?.length || 0}
          onChange={(page) => {
            setPagination(page)
          }}
        />
      </div>
    </div>



  </div>
}

export default SearchResults