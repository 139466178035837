import { startsWith } from 'lodash';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { atom, useRecoilState } from 'recoil';

export const CommWorkspace = {
  key: 'community',
  path: '/community',
  label: 'Community',
  desc: 'Explore queries, APIs, projects, and dashboards from the community',
};

export const MyWorkspace = {
  key: 'my-workspace',
  path: '/my-workspace',
  label: 'My Workspace',
  desc: 'Create, manage and publish queries, APIs, projects, and dashboards',
};

export const workspaces = [CommWorkspace, MyWorkspace] as const;

export type WorkspaceType = typeof workspaces[number] | null;

const AWorkspace = atom<WorkspaceType>({
  key: 'AWorkspace',
  default: null,
});

export function useWorkspace() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isMyWorkspacePath = startsWith(pathname, '/my-workspace');
  const [workspace, setWorkspace] = useRecoilState(AWorkspace);

  // init
  useEffect(() => {
    setWorkspace(isMyWorkspacePath ? MyWorkspace : CommWorkspace);
  }, [workspace, isMyWorkspacePath]);

  function switchToCommunity() {
    setWorkspace(CommWorkspace);
  }

  function switchToMyWorkspace() {
    setWorkspace(MyWorkspace);
  }

  const menu = pathname.split('/')[2];

  function getPath(nMenu: string) {
    if (isMyWorkspacePath) {
      return `/my-workspace/${nMenu}`;
    }

    return `/community/${nMenu}`;
  }

  return [
    {
      workspace,
      isCommunity: !isMyWorkspacePath,
      isMyWorkspace: isMyWorkspacePath,
      menu,
    },
    { navigate, getPath, switchToMyWorkspace, switchToCommunity, setWorkspace },
  ] as const;
}
