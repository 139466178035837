/* eslint-disable @typescript-eslint/no-explicit-any */
import EditButton from "../../../components/EditButton"
// import ForkButton from "../../../components/ForkButton"

const ApiDetailHeader = ({ title, onEdit, currentApi, isEditMode, onSave }: {
  title?: string,
  onEdit: () => void,
  currentApi: any,
  isEditMode?: boolean,
  onSave: () => void
}) => {
  if (!currentApi) return null

  return <div>
    <div className="flex justify-between items-center gap-2">
      <div>
        {/* Title */}
        <h2 className="text-[20px] font-semibold gap-3 flex justify-center items-center">
          <span>
            {title || 'api operationId'}
          </span>
          <img width={30} src={currentApi.image} />
        </h2>
        {/* Short Description */}
        <div className="text-sm text-[#999ca0]">
          {/* Use moment here to get relative time */}
        </div>
      </div>
      <div className="flex gap-2">
        {/* <ForkButton /> */}
        {
          isEditMode ? <>
            <button className="flex items-center gap-2 border rounded px-[12px] py-[9px] hover:bg-[#333] hover:text-[#fff] text-[12px] h-[30px] bg-[#f5f5f5] text-[#999ca0]" onClick={() => { onEdit() }}>Cancel</button>
            <button className="flex items-center gap-2 border rounded px-[12px] py-[9px] hover:bg-[#263dff] hover:text-[#fff] text-[12px] h-[30px] hover:bg-[#263dff] hover:text-[#fff] bg-[#263dff] text-[#fff]" onClick={onSave}>Save</button>
          </> : <EditButton onClick={() => {
            onEdit()
          }} />
        }
        {/* {!isEditMode && <ReleaseButton />} */}
      </div>
    </div>
  </div>
}

export default ApiDetailHeader;