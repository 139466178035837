import CopyToClipboard from 'react-copy-to-clipboard';
import { BiCopy } from 'react-icons/bi';
import { BsArrowRightShort } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';

import Modal from '../../components/modals/Modal';
import envs from '../../config/envs';
import { DefaultAutoDismissMs } from '../../config/toast';
import { useApiId, ApiEditFormState } from './ViewApiHooks';
import { useNavigate } from 'react-router';
import { analytics } from '../../App';

export default function ApiInfoModal({
  open,
  setOpen,
}: {
  open?: boolean;
  setOpen: (v: boolean) => void;
}) {
  const apiId = useApiId();
  // eslint-disable-next-line
  const [values, setValues] = useRecoilState(ApiEditFormState(apiId));

  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      className='max-w-[24rem]'
      onClickBackdrop={() => {
        setOpen(false);
      }}
    >
      <div className='flex flex-col gap-4'>
        <div className='font-bold opacity-30'>About</div>
        <div className='font-semibold'>
          API keys are required to query data from Projects. You can
          create/manage API keys in My Creations.
        </div>
        <div className='border-b' />
        <div className='font-bold opacity-30'>GraphQL URL</div>
        <div className='bg-base-200 p-2 break-all'>
          {`${envs.ZettaOpenApi}/dataset/${apiId}/graphql`}
        </div>
        <CopyToClipboard
          text={`${envs.ZettaOpenApi}/dataset/${apiId}/graphql`}
        >
          <button
            className='btn btn-primary w-full gap-2'
            onClick={() => {
              toast.dismiss();
              toast.success('Link copied', {
                autoClose: DefaultAutoDismissMs,
              });
              analytics.track(`API - Copy GraphQL URL - ${values.database?.startsWith('realtime') ? "Realtime" : "Normal"}`, {
                apiId,
              })
            }}
          >
            Copy GraphQL URL <BiCopy />
          </button>
        </CopyToClipboard>
        <div className='flex justify-between'>
          <a
            className='text-primary cursor-pointer flex font-bold'
            href='https://zettablock.readme.io/reference/post_dataset-id-graphql'
            target='_blank'
            rel='noreferrer'
          >
            API Reference
            <BsArrowRightShort size='1.5rem' />
          </a>
          <button
            className='text-primary cursor-pointer flex font-bold'
            onClick={() => {
              navigate('/my-workspace/api-key');
            }}
          >
            Manage API Key
            <BsArrowRightShort size='1.5rem' />
          </button>
        </div>
        {/* hide this section for now */}
        <div className='hidden'>
          <div className='border-b' />
          <div className='font-bold opacity-30'>DOCUMENTATION</div>
          <div className='font-semibold'>
            For examples on how to query projects using different programming
            languages, please visit documentation.
          </div>
          <button
            className='text-primary cursor-pointer flex font-bold'
            onClick={() => {
              toast.dismiss();
              toast.info('coming soon');
            }}
          >
            View Documentation <BsArrowRightShort size='1.5rem' />
          </button>
        </div>
      </div>
    </Modal>
  );
}
