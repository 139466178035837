import { SearchApi } from '../../../api/client';

export const getSearchResult = async (keywords: string) => {
  const client = SearchApi();

  const res = await client.searchCreate({
    keywords,
  });

  return res;
};
