import classNames from 'classnames';

interface UsageBarProps {
  progress: number;
  title: string;
  details: string;
  warning?: boolean;
  fullWidth?: boolean;
}

const UsageBar = ({
  progress = 30,
  title = '',
  details = '',
  warning = true,
  fullWidth = false,
}: UsageBarProps) => {
  return (
    <div className={classNames(
      'relative h-[36px] p-1 bg-white rounded-[6px] overflow-hidden border-white border-2 flex items-center',
      {
        'w-[200px]': !fullWidth,
        'w-full': fullWidth,
      }
    )}>
      <div
        className={classNames(
          'absolute h-full top-0 left-0 bg-[#eeedfe] rounded-[6px]',
          {
            'bg-[#fff0f0]': warning,
          }
        )}
        style={{
          width: `${progress}%`,
        }}
      ></div>
      <div
        className={classNames(
          'text-xs pl-2 text-[#263DFF] font-semibold relative flex-1',
          {
            'text-[#ff4a4a]': warning,
          }
        )}
      >
        {title}
      </div>
      <div
        className={classNames('text-[10px] pr-2 relative font-semibold', {
          'text-[#ff4a4a]': warning,
        })}
      >
        {details}
      </div>
    </div>
  );
};

export default UsageBar;
