import { BiExpand } from "react-icons/bi"

const Response = () => {

  return <div>
    <div className="font-semibold mb-1 text-[14px]">
      Response
    </div>
    <div className="border bg-[#fafafa] p-3 rounded ">
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <div className="rounded-full w-3 h-3 bg-[#65da98]"></div>
          <span className="ml-2 font-semibold">200</span>
        </div>
        <div className="cursor-pointer">
          <BiExpand />
        </div>
      </div>
      <div className="text-[#969ca1]">
        OK
      </div>
    </div>
  </div>
}

export default Response