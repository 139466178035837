import {
  MdOutlineArrowDropDown,
  MdOutlineArrowDropUp,
  MdSkipNext,
  MdSkipPrevious,
} from 'react-icons/md';
import classNames from 'classnames';
import {
  HTMLAttributes,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table';
import Tooltip from 'antd/es/tooltip';
import LogoBlinkerCenter from '../../../components/app/LogoBlinkerCenter';
import { useWidth } from '../../../hooks/layout';
import { TableChartEntity } from '../types';
import { QueryResultResult } from '../../queryBuilder/QueryState';
import pluralize from 'pluralize';
import { BiSearch } from 'react-icons/bi';
import Input from '../../../components/inputs/Input';
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai';

interface ITableChartProps {
  queryResults?: QueryResultResult;
  chart: TableChartEntity;
  loading?: boolean;
}

function TableChartV3({
  className,
  queryResults,
  chart,
  loading,
}: HTMLAttributes<HTMLTableElement> & ITableChartProps) {
  const [ref] = useWidth<HTMLDivElement>();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  // const rows = queryResults?.rows;
  const columns = queryResults?.metadata?.columns || [];
  const rows = queryResults?.rows || [];

  const yCols = chart?.uiOptions?.dataOptions?.yCols;

  const memoData = useMemo(
    () => rows as Record<string, string | number>[],
    [rows]
  );

  const memoColumns = useMemo(
    () =>
      columns.map((c, idx) => ({
        accessor: c.name,
        Header: yCols?.[idx]?.customName || c.name,
        headerClassName: 'text-right',
        width: c.width,
      })),
    [columns, yCols]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
    setGlobalFilter,
    state: { pageSize },
    setPageSize,
  } = useTable(
    {
      columns: memoColumns,
      data: memoData,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const [height, setHeight] = useState<number | 'auto'>();

  useEffect(() => {
    const offsetHeight = tableContainerRef.current?.offsetHeight;
    setHeight(offsetHeight);
  }, [pageSize, tableContainerRef.current]);

  const onSearchInputChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  if (loading) {
    return <LogoBlinkerCenter />;
  }

  return (
    <div
      ref={ref}
      className={classNames('w-full h-full relative', className)}
      style={{ height }}
    >
      <div
        ref={tableContainerRef}
        className={classNames('absolute top-0 w-full px-4')}
      >
        <div className='overflow-auto border'>
          <table
            className={classNames(
              'table table-compact border-collapse table-fixed min-w-full'
            )}
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => {
                return (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, colIndex) => {
                      const yCol = yCols?.[colIndex];
                      const itemAlignCls = {
                        'flex-row-reverse': yCol?.align === 'right',
                        'justify-left': yCol?.align === 'left',
                        'justify-center': yCol?.align === 'center',
                      };
                      return (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={classNames('border pl-2')}
                        >
                          <div
                            className={classNames(
                              'flex items-center justify-left',
                              itemAlignCls
                            )}
                          >
                            <div className='font-bold'>
                              {column.render('Header')}
                            </div>
                            <div
                              className={classNames('pl-3 w-10', {
                                hidden:
                                  !column.isSorted && !column.isSortedDesc,
                              })}
                            >
                              {
                                // eslint-disable-next-line
                                column.isSorted &&
                                  (column.isSortedDesc ? (
                                    <MdOutlineArrowDropDown size='0.9rem' />
                                  ) : (
                                    <MdOutlineArrowDropUp size='0.9rem' />
                                  ))
                              }
                            </div>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, cellIdx) => {
                      let isLink = false;
                      if (cell?.value?.trim?.().startsWith?.('http')) {
                        isLink = true;
                      }
                      const yCol = yCols?.[cellIdx];
                      const text = isLink ? cell.value : cell.render('Cell');
                      const textAlignCls = {
                        'text-right': yCol?.align === 'right',
                        'text-left': yCol?.align === 'left',
                        'text-center': yCol?.align === 'center',
                      };
                      const width = String(cell.value).length * 7.6;
                      const node = isLink ? (
                        <a
                          target='_blank'
                          rel='noreferrer'
                          className='overflow-hidden text-ellipsis break-all whitespace-nowrap max-w-[500px]'
                          href={text}
                        >
                          {text}
                        </a>
                      ) : (
                        <div className='overflow-hidden text-ellipsis break-all whitespace-nowrap max-w-[500px]'>
                          {text}
                        </div>
                      );
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={classNames('border pl-2', textAlignCls)}
                        >
                          {width > 516 ? (
                            <Tooltip
                              rootClassName='max-w-[600px] max-h-[500px]'
                              overlay={
                                <pre className='whitespace-pre-line overflow-auto'>
                                  {cell.value}
                                </pre>
                              }
                            >
                              {node}
                            </Tooltip>
                          ) : (
                            node
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className='flex pagination bg-gray-300 text-sm'>
          <div className='px-2 py-1'>
            {rows.length} {pluralize('row', rows.length)} returned
          </div>
          <div className='border-r border-r-black border-opacity-10' />
          <div className='flex-1 flex px-2 py-1 items-center'>
            <BiSearch size='1rem' />
            <Input
              noOutline
              type='text'
              placeholder='Search...'
              className='w-full input-xs min-w-36 bg-transparent'
              onChange={(e) => {
                onSearchInputChange(e.target.value);
              }}
            />
          </div>
          <div className='border-r border-r-black border-opacity-10' />
          <div className='flex'>
            <div
              className={
                'cursor-pointer flex items-center border-r border-black border-opacity-10'
              }
              onClick={() => gotoPage(0)}
            >
              <div
                className={classNames(
                  'px-2',
                  canPreviousPage ? '' : 'opacity-30'
                )}
              >
                <MdSkipPrevious size='1.3rem' />
              </div>
            </div>
            <div
              className={
                'cursor-pointer flex items-center border-r border-black border-opacity-10'
              }
              onClick={() => previousPage()}
            >
              <div
                className={classNames(
                  'px-2',
                  canPreviousPage ? '' : 'opacity-30'
                )}
              >
                <AiFillCaretLeft size='0.94rem' />
              </div>
            </div>
            <div className='flex-1 flex items-center border-r border-black border-opacity-10 min-w-[7rem]'>
              <div className='w-full text-center'>
                {pageIndex + 1} of {pageOptions.length}
              </div>
            </div>
            <div
              className={
                'cursor-pointer flex items-center border-r border-black border-opacity-10'
              }
              onClick={() => nextPage()}
            >
              <div
                className={classNames('px-2', canNextPage ? '' : 'opacity-30')}
              >
                <AiFillCaretRight size='0.94rem' />
              </div>
            </div>
            <div
              className={
                'cursor-pointer flex items-center border-r border-black border-opacity-10'
              }
              onClick={() => gotoPage(pageCount - 1)}
            >
              <div
                className={classNames('px-2', canNextPage ? '' : 'opacity-30')}
              >
                <MdSkipNext size='1.3rem' />
              </div>
            </div>

            <div className='flex items-center flex-none pl-2'>
              <span className='flex-none'>{'Go to '}</span>
              <input
                type='number'
                className='flex-auto input input-sm bg-transparent p-0 m-0 no-outline text-right max-w-[3rem]'
                size={1}
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const nPage = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(nPage);
                }}
              />
            </div>
            <div className='flex items-center'>
              <select
                className='font-normal select select-sm bg-transparent'
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                  setHeight('auto');
                  e.stopPropagation();
                  e.preventDefault();
                  e.target.blur();
                }}
              >
                {[10, 20, 50, 100].map((nPageSize) => (
                  <option key={nPageSize} value={nPageSize}>
                    Show {nPageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(TableChartV3);
