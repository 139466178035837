import produce from 'immer';
import { useState } from 'react';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { RiSaveLine } from 'react-icons/ri';
import LoadingIndicator from '../../components/LoadingIndicator';

import useAuth from '../../hooks/auth';
import { COMMUNITY } from '../auth/AuthState';

import Modal from '../../components/modals/Modal';
import {
  useDashboardFormData,
  useSaveDashboard,
} from './DashboardViewPageState';
import { analytics } from '../../App';

export default function DashSettingModal({
  openSetting,
  setOpenSetting,
}: {
  openSetting?: boolean;
  setOpenSetting: (v: boolean) => void;
}) {
  // NOTE: outdated architecture, please refer [API api] folder structure
  const [saving, { saveDashboard }] = useSaveDashboard();

  const [values, setValues] = useDashboardFormData();
  const [formValues, setFormValues] = useState(values);

  const [{ auth }] = useAuth();

  return (
    <Modal open={openSetting}>
      <div className='text-center text-xl font-bold'>Setting</div>
      <div className='flex flex-col gap-3'>
        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Title</span>
          </label>
          <input
            type='text'
            className='input bg-base-300'
            placeholder='Please enter title'
            maxLength={125}
            value={formValues.displayName}
            onChange={(e) => {
              setFormValues(
                produce(formValues, (draft) => {
                  draft.displayName = e.target.value;
                })
              );
            }}
          />
        </div>
        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Description</span>
          </label>
          <textarea
            className='textarea bg-base-300'
            placeholder='Please enter description'
            value={formValues.description}
            onChange={(e) => {
              setFormValues(
                produce(formValues, (draft) => {
                  draft.description = e.target.value;
                })
              );
            }}
          />
        </div>
        <div className='form-control'>
          <label className='label'>
            <span className='label-text flex'>Privacy setting</span>
          </label>
          <div className='form-control'>
            <label className='label cursor-pointer'>
              <span className='label-text text-xs flex items-center'>
                Private (Only visible to me){' '}
                {auth?.tenant === COMMUNITY && (
                  <span
                    className='tooltip ml-1 tooltip-warning tooltip-top flex items-center justify-center opacity-90 cursor-pointer'
                    data-tip='Please upgrade the plan to make dashboard private'
                  >
                    <BsFillInfoCircleFill
                      size='0.7rem'
                      className='mx-1 text-blue-600'
                    />
                  </span>
                )}
              </span>
              <input
                checked={formValues.access === 'private'}
                disabled={auth?.tenant === COMMUNITY} // NOTE: will also need to check the plan once available
                onChange={() => {
                  setFormValues(
                    produce(formValues, (draft) => {
                      // @ts-ignore
                      draft.access = 'private';
                    })
                  );
                }}
                type='radio'
                name='access_level'
                className='radio checked:bg-blue-500'
              />
            </label>
          </div>
          {auth?.tenant !== COMMUNITY && (
            <div className='form-control'>
              <label className='label cursor-pointer'>
                <span className='label-text text-xs'>
                  Org Internal (Visible to my org)
                </span>

                <input
                  checked={formValues.access === 'org_internal'}
                  onChange={() => {
                    setFormValues(
                      produce(formValues, (draft) => {
                        // @ts-ignore
                        draft.access = 'org_internal';
                      })
                    );
                  }}
                  type='radio'
                  name='access_level'
                  className='radio checked:bg-blue-500'
                />
              </label>
            </div>
          )}
          <div className='form-control'>
            <label className='label cursor-pointer'>
              <span className='label-text text-xs'>
                Public (Visible to everyone)
              </span>
              <input
                checked={formValues.access === 'public'}
                onChange={() => {
                  setFormValues(
                    produce(formValues, (draft) => {
                      // @ts-ignore
                      draft.access = 'public';
                    })
                  );
                }}
                type='radio'
                name='access_level'
                className='radio checked:bg-blue-500'
              />
            </label>
          </div>
        </div>

        <div className='flex gap-2 items-center'>
          <button
            className='btn btn-primary gap-4'
            onClick={async () => {
              const success = await saveDashboard(formValues);

              analytics.track('Dashboard - Update dashboard settings', {
                updated: true,
                values: formValues,
              })
              
              if(values.access !== formValues.access) {
                analytics.track('Dashboard - Update dashboard settings - Privacy Settings', {
                  updated: true,
                  values: formValues,
                })
              }
              
              if (success) {
                setValues(formValues);
                setOpenSetting(false);
              }
            }}
          >
            <RiSaveLine />
            Save
          </button>
          <button
            className='btn btn-ghost'
            style={{ border: '1px solid #aaaaaa' }}
            onClick={() => {
              setOpenSetting(false);
            }}
          >
            Cancel
          </button>
          {saving && <LoadingIndicator />}
        </div>
      </div>
    </Modal>
  );
}
