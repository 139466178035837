import { TableFreshnessApi } from '../../api/client';

export const getRealtimeFreshnessList = async () => {
  const client = TableFreshnessApi();

  const realtime = await client.historyList({
    source: 'realtimeDB',
  });

  return realtime?.data?.items || [];
};

export const getHistoricalFreshnessList = async () => {
  const client = TableFreshnessApi();

  const historical = await client.historyList({
    source: 'AwsDataCatalog',
  });

  return historical?.data?.items || [];
};

export const getTableFreshnessList = async () => {
  const realtime = await getRealtimeFreshnessList();
  const historical = await getHistoricalFreshnessList();

  return {
    realtime,
    historical,
  };
};
