import { AiFillEye } from "react-icons/ai"
import { HorizontalLine } from "../../../components/HorizontalLine"
import { ModelABI } from "../../../api/__gen__/data-contracts"

const EventCard = ({ event, onClick }: { event?: ModelABI, onClick: (eventId: string) => void }) => {

  return <div className="border flex flex-col w-[48%]">
    <h2 className="p-3 py-2 text-ellipsis overflow-hidden font-semibold">
      {event?.name}
    </h2>
    <HorizontalLine />
    <div className="p-3 h-[190px] flex flex-col">
      <div className="flex justify-between opacity-50 text-sm mb-1">
        <div>
          NAME
        </div>
        <div>
          TYPE
        </div>
      </div>
      <div className="overflow-auto flex1 no-scrollbar">
        {
          event?.inputs?.map((input) => {
            return <div className="flex justify-between mb-1" key={`${input.name}-${input.type}-${Math.random()}`}>
              <div className="opacity-50">
                {input.name}
              </div>
              <div>
                {input.type}
              </div>
            </div>
          })
        }

        {/* <div className="flex justify-between mb-1">
          <div className="opacity-50">
            token
          </div>
          <div>
            STRING
          </div>
        </div>

        <div className="flex justify-between mb-1">
          <div className="opacity-50">
            tokenId
          </div>
          <div>
            INT
          </div>
        </div>

        <div className="flex justify-between mb-1">
          <div className="opacity-50">
            contract_address
          </div>
          <div>
            STRING
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div className="opacity-50">
            contract_address
          </div>
          <div>
            STRING
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div className="opacity-50">
            contract_address
          </div>
          <div>
            STRING
          </div>
        </div>
        <div className="flex justify-between mb-1">
          <div className="opacity-50">
            contract_address
          </div>
          <div>
            STRING
          </div>
        </div> */}
      </div>



    </div>
    <div className="px-3 pb-3">

      <button onClick={() => {
        onClick(event?.name || "")
      }} className="bg-[#f1f3f5] w-full text-center py-1 rounded font-semibold flex justify-center items-center">
        <AiFillEye /> <span className="ml-2">Recent On-Chain Data</span>
      </button>
    </div>
  </div>
}

export default EventCard