import { BsAsterisk } from 'react-icons/bs';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router';
import { HorizontalLine } from '../../../components/HorizontalLine';
import PaleButton from '../../../components/interactive/PaleButton';
import postgresql from '../../../assets/icons/postgres 1.svg';
import { HTMLAttributes, useEffect, useState } from 'react';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import usePrompt from '../../../hooks/router';
import { createConnection, pollConnectionStatus } from './client';
import { capitalize } from 'lodash';
import useAuth from '../../../hooks/auth';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import styles from './markdown.module.scss';

import setupMarkdown from './setup.md';

function RequiredIcon({ className }: { className?: string }) {
  return (
    <BsAsterisk
      size='0.7rem'
      className={classNames('text-error mt-3', className)}
    />
  );
}

function InputGroup({
  label,
  children,
}: HTMLAttributes<HTMLDivElement> & { label?: string }) {
  return (
    <div className='flex space-x-4'>
      <span className='font-semibold w-1/5 flex-shrink-0'>{label}</span>
      <RequiredIcon />
      <div className='w-full'>{children}</div>
    </div>
  );
}

const defaultValues = {} as Record<string, string>;

interface JobStatus {
  job_id?: string;
  status?: string;
}

// TODO refactor useForm
export default function Postgresql() {
  const navigate = useNavigate();
  const [schema, setSchema] = useState(defaultValues?.auth_source || '');
  const [database, setDatabase] = useState(defaultValues?.database || '');
  const [host, setHost] = useState(defaultValues?.host || '');
  const [port, setPort] = useState(defaultValues?.port || '');
  const [user, setUser] = useState(defaultValues?.mongo_user || '');
  const [password, setPassword] = useState(defaultValues?.password || '');

  const [status, setStatus] = useState<JobStatus>();

  const [{ auth, basicAuth }] = useAuth();

  const [postMarkdown, setPostMarkdown] = useState('');

  // useEffect with an empty dependency array (`[]`) runs only once
  useEffect(() => {
    fetch(setupMarkdown)
      .then((response) => response.text())
      .then((text) => {
        // Logs a string of Markdown content.
        // Now you could use e.g. <rexxars/react-markdown> to render it.
        // console.log(text);
        setPostMarkdown(text);
      });
  }, []);

  const submit = async () => {
    toast.dismiss();

    const username = auth?.email || basicAuth?.username;

    if (!username) {
      toast.warn('Please login first');
      return;
    }

    if (!schema || !database || !host || !user || !password) {
      toast.warn('Please check input values (invalid values)');
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append('schema', schema);
    bodyFormData.append('database', database);
    bodyFormData.append('host', host);
    bodyFormData.append('postgres_db_username', user);
    bodyFormData.append('port', port);
    bodyFormData.append('password', password);

    // TODO: make sure connection api adapt to auth v2
    bodyFormData.append('username', username);

    const res = await createConnection({
      // mock: true,
      formData: bodyFormData,
    });

    const error = res?.data?.error;

    if (error) {
      toast.error(error);
    } else if (res?.data?.job_id) {
      setStatus(res.data);
    } else {
      toast.warning('request failed');
    }
  };

  useEffect(() => {
    async function run(jobId: string) {
      setStatus({
        ...status,
        status: 'processing',
      });

      const res = await pollConnectionStatus({ jobId });

      setStatus({
        ...status,
        status: res?.data?.status,
      });
    }

    if (status?.job_id && status?.status === 'submitted') {
      // start polling
      run(status.job_id);
    }
  }, [status?.status]);

  const isProcessing =
    !!status?.job_id &&
    status?.status !== 'finished' &&
    status?.status !== 'errored';

  usePrompt(
    'The connection is being created. Leaving this page would not cancel it. Still want to leave?',
    isProcessing
  );

  return (
    <>
      <div className='flex px-6 py-3 items-center'>
        <PaleButton onClick={() => navigate(-1)}>
          <IoIosArrowBack size='1.8rem' />
          <div className='w-3' />
          Back
        </PaleButton>
        <div className='flex-1' />
        <button className='btn btn-primary hidden'>Save and Test</button>
      </div>
      <HorizontalLine />
      <div className='flex flex-col'>
        <div className='flex'>
          <div className='flex-1'>
            <div className='py-3 px-6 flex items-center'>
              <img src={postgresql} className='w-10' />
              <div className='w-3' />
              <span className='text-xl'>Postgresql</span>
            </div>
          </div>
          <div className='flex-1 flex items-center text-xl bg-base-200'>
            <div className='p-5'>Setup Guide</div>
          </div>
        </div>
        <HorizontalLine />
        <div className='flex'>
          <div className='flex-1 flex'>
            <div className='flex-1 p-5 flex flex-col space-y-6'>
              <InputGroup label='Host'>
                <input
                  className='input input-bordered w-full'
                  readOnly={isProcessing}
                  type='text'
                  placeholder='Your Host'
                  onChange={(e) => setHost(e.target.value)}
                  defaultValue={host}
                />
                <div className='h-2' />
                <span className='text-sm font-light'>
                  The URL of a cluster to connect to (we support Postgresql
                  Altas)
                </span>
              </InputGroup>
              <InputGroup label='Port'>
                <input
                  className='input input-bordered w-full'
                  readOnly={isProcessing}
                  type='text'
                  placeholder='Your Port'
                  onChange={(e) => setPort(e.target.value)}
                  defaultValue={port}
                />
                <div className='h-2' />
                <span className='text-sm font-light'>
                  The URL of a cluster to connect to (we support Postgresql
                  Altas)
                </span>
              </InputGroup>
              <InputGroup label='Database Name'>
                <input
                  className='input input-bordered w-full'
                  readOnly={isProcessing}
                  type='text'
                  placeholder='Your database name'
                  onChange={(e) => setDatabase(e.target.value)}
                  defaultValue={database}
                />
                <div className='h-2' />
                <span className='text-sm font-light'>
                  The database you want to replicate.
                </span>
              </InputGroup>
              <InputGroup label='Schema'>
                <input
                  className='input input-bordered w-full'
                  readOnly={isProcessing}
                  type='text'
                  placeholder='Your Schema'
                  onChange={(e) => setSchema(e.target.value)}
                  defaultValue={schema}
                />
                <div className='h-2' />
                <span className='text-sm font-light'>
                  The list of schemas (case sensitive) to sync from. Defaults to
                  public.
                </span>
              </InputGroup>
              <InputGroup label='User'>
                <input
                  className='input input-bordered w-full'
                  readOnly={isProcessing}
                  type='text'
                  placeholder='Your Postgresql Username'
                  onChange={(e) => setUser(e.target.value)}
                  defaultValue={user}
                />
                <div className='h-2' />
                <span className='text-sm font-light'>
                  The username which is used to access the database.
                </span>
              </InputGroup>
              <InputGroup label='Password'>
                <input
                  className='input input-bordered w-full'
                  readOnly={isProcessing}
                  type='password'
                  placeholder='Your Password'
                  onChange={(e) => setPassword(e.target.value)}
                  defaultValue={password}
                />
                <div className='h-2' />
                <span className='text-sm font-light'>
                  The password associated with this username.
                </span>
              </InputGroup>

              <div className='flex space-x-4'>
                <span className='font-bold w-1/5 flex-shrink-0 opacity-0'></span>
                <RequiredIcon className='opacity-0' />
                <button
                  className={classNames('btn btn-primary', {
                    loading: isProcessing,
                  })}
                  onClick={() => submit()}
                >
                  Submit
                </button>
              </div>
              {status?.job_id && (
                <div className='flex space-x-4'>
                  <span className='font-bold w-1/5 flex-shrink-0 opacity-0'></span>
                  <RequiredIcon className='opacity-0' />
                  <div>Status: {capitalize(status?.status)}</div>
                </div>
              )}
            </div>
          </div>
          <div className='flex-1 bg-base-200 max-h-[80vh] overflow-y-auto'>
            <div className='p-5 space-y-3'>
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                className={styles.setupMarkdown}
              >
                {postMarkdown}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
