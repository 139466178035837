import classNames from 'classnames';
import { HTMLAttributes, memo, useEffect } from 'react';
import { BsPlayFill } from 'react-icons/bs';
import { useRecoilState } from 'recoil';
import { AQueryRunErrorMessage, QueryRunStopper } from '../QueryState';
import { toast } from 'react-toastify';
import { Button, Dropdown } from 'antd';
import Icon from '../../../v2/components/Icon';
import { track } from '../../../v2/tracking';

function QueryBuilderRunButtonV2({
  startedAt,
  isQueryRunning,
  needQueryUpdate,
  hasHighlightedText,
  onClick,
  disabled,
  databaseId,
  setDatabaseId,
  ...props
}: {
  databaseId: string;
  setDatabaseId: (databaseId: string) => void;
  needQueryUpdate?: boolean;
  isQueryRunning: boolean;
  hasHighlightedText: boolean;
  startedAt?: Date | null;
  disabled?: boolean;
} & HTMLAttributes<HTMLButtonElement>) {
  let label;

  const [queryRunStopper] = useRecoilState(QueryRunStopper);
  const [, setQueryRunErrorMessage] = useRecoilState(AQueryRunErrorMessage);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (startedAt && isQueryRunning) {
      timeout = setTimeout(() => {
        queryRunStopper?.cancelQuery();
        toast.warn('Query run timeout');
      }, 30 * 60 * 1000); // Note(jjin): temp solution, revisit this later
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [startedAt, isQueryRunning, queryRunStopper]);

  if (isQueryRunning) {
    label = <span>{'Cancel'}</span>;
  } else if (hasHighlightedText) {
    label = <span>{'RUN SELECTED'}</span>;
  } else if (needQueryUpdate) {
    label = <span>{'SAVE & RUN'}</span>;
  } else {
    label = (
      <span className='flex gap-3 pr-2'>
        <BsPlayFill size='0.8rem' />
        {databaseId === 'auto' && 'Auto'}
        {databaseId === 'datalake' && 'Data Lake'}
        {databaseId?.startsWith('realtime') && 'Database'}
      </span>
    );
  }

  const handleButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (isQueryRunning) {
      // Adding tracking code here
      track(`Query - Query run cancel button clicked`, {});
      queryRunStopper?.cancelQuery();
    } else {
      onClick?.(e);
      setQueryRunErrorMessage('');
    }
  };

  return (
    <div className='flex items-center mr-3'>
      <button
        disabled={disabled}
        className={classNames(
          'btn btn-sm min-w-[7rem]',
          isQueryRunning && 'btn-error',
          disabled ? 'btn-disabled' : ' btn-primary'
        )}
        {...props}
        onClick={handleButtonClick}
      >
        {label}
      </button>
      <div className='flex items-center bg-[#263dff] h-[28px] border-l'>
        <Dropdown
          trigger={['click']}
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <div
                    className='flex items-center justify-between w-[180px]'
                    onClick={() => {
                      track(`Query - Database dropdown clicked`, {
                        type: 'auto',
                      });
                      setDatabaseId('auto');
                    }}
                  >
                    <div>
                      <div className='text-[12px] text-[#1a1a1a] mb-[3px]'>
                        Auto
                      </div>
                      {/* <div className='text-[9px] text-[#777A80]'>
                      Postgres
                    </div> */}
                    </div>
                    {databaseId === 'auto' && (
                      <div>
                        <Icon
                          className='w-[14px] h-[14px]'
                          iconName='checkmark'
                        />
                      </div>
                    )}
                  </div>
                ),
              },
              {
                key: '2',
                label: (
                  <div
                    className='flex items-center justify-between w-[180px]'
                    onClick={() => {
                      track(`Query - Database dropdown clicked`, {
                        type: 'datalake',
                      });
                      setDatabaseId('datalake');
                    }}
                  >
                    <div>
                      <div className='text-[12px] text-[#1a1a1a] mb-[3px]'>
                        Data Lake
                      </div>
                      <div className='text-[9px] text-[#777A80]'>Presto</div>
                    </div>
                    {databaseId === 'datalake' && (
                      <div>
                        <Icon
                          className='w-[14px] h-[14px]'
                          iconName='checkmark'
                        />
                      </div>
                    )}
                  </div>
                ),
              },
              {
                key: '3',
                label: (
                  <div
                    className='flex items-center justify-between w-[180px]'
                    onClick={() => {
                      track(`Query - Database dropdown clicked`, {
                        type: 'realtimeDB',
                      });
                      setDatabaseId('realtimeDB');
                    }}
                  >
                    <div>
                      <div className='text-[12px] text-[#1a1a1a] mb-[3px]'>
                        Database (Realtime)
                      </div>
                      <div className='text-[9px] text-[#777A80]'>Postgres</div>
                    </div>
                    {databaseId?.startsWith('realtime') && (
                      <div>
                        <Icon
                          className='w-[14px] h-[14px]'
                          iconName='checkmark'
                        />
                      </div>
                    )}
                  </div>
                ),
              },
            ],
          }}
          placement='topRight'
        >
          <Button
            className='h-[28px] border-none bg-[#263dff] text-white'
            type='text'
            icon={
              <Icon className='w-[14px] h-[14px]' iconName='chevron-bottom' />
            }
          />
        </Dropdown>
      </div>
    </div>
  );
}

export default memo(QueryBuilderRunButtonV2);
