import Icon from '../components/Icon';
import  './Toggle.scss';

interface ToggleProps {
  checked: boolean
  onToggle?: (checked: boolean) => void
}
const Toggle = ({ checked, onToggle }: ToggleProps) => {


  return (<div className="toggle-switch" title='Realtime Table Toggle'>
    <input
      type="checkbox"
      className="toggle-switch-checkbox"
      name="toggleSwitch"
      id="toggleSwitch"
      checked={checked}
      onChange={(e) => { 
        onToggle?.(e.target.checked)
      }}
    />
    <label className="toggle-switch-label" htmlFor="toggleSwitch">
      <span className="toggle-switch-inner" />
      {/* <span className="toggle-switch-switch" /> */}
      <div className='toggle-switch-switch'>
        <Icon
          iconName='toggle'
          className='w-[18px] h-[18px] text-[#fff]'
        />
      </div>
    </label>
  </div>);
}

export default Toggle;
