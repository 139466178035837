import { runQueryById, TQueryResult } from '../queryBuilder/QueryState';
import {
  atomFamily,
  selectorFamily,
  DefaultValue,
  useRecoilState,
  useRecoilCallback,
} from 'recoil';

import '../../css/blinker.css';
import { PublicQueryApi, StreamApi } from '../../api/client';
import { isEqual } from 'lodash';
import { zToast } from '../toast/toast';
import { queryResultFormat } from '../queryBuilder/api-utils';
import { AxiosError } from 'axios';

export type FetchPolicy = 'cache-first' | 'network-only';

export type QueryRunParams = {
  queryId: string;
  queryParams: string;
};

type QueryIdType = string;

export type QueryCachePayload =
  | {
      response?: TQueryResult;
      loading?: boolean;
    }
  | null
  | undefined;

type QueryCacheKeyObject = {
  queryId?: string;
  queryParamsStr: string;
};

type QueryCacheItem = {
  keyObject: QueryCacheKeyObject;
  payload?: QueryCachePayload;
};

export const QueryResultsCache = atomFamily<QueryCacheItem[], QueryIdType>({
  key: 'QueryResultsCache',
  default: [],
});

/**
 * Model
{
  [queryId]: [
    { 
      result {
        keyObject: {
          queryId,
          queryParamsStr,
        },
        payload: QUERY_RESULT
      }
    }
  ]
}
 */
export const QueryResultsByKeyObject = selectorFamily<
  QueryCachePayload,
  QueryCacheKeyObject
>({
  key: 'QueryResultsByKeyObject',
  set:
    (keyObject) =>
    ({ set: setState, get }, nValue) => {
      const { queryId } = keyObject;

      if (queryId) {
        const resultsList = get(QueryResultsCache(queryId));

        const existingItem = resultsList.find((result) =>
          isEqual(result.keyObject, keyObject)
        );

        if (existingItem && !(nValue instanceof DefaultValue)) {
          // update existing item
          const nextResultList = resultsList.map((item) => {
            return isEqual(item.keyObject, keyObject)
              ? {
                  ...item,
                  payload: nValue,
                }
              : item;
          });

          setState(QueryResultsCache(queryId), nextResultList);
        } else if (!(nValue instanceof DefaultValue)) {
          // add the new result
          const newQueryResultItem: QueryCacheItem = {
            keyObject,
            payload: nValue,
          };

          setState(QueryResultsCache(queryId), [
            ...resultsList,
            newQueryResultItem,
          ]);
        }
      }
    },
  get:
    (keyObject) =>
    ({ get }) => {
      const { queryId } = keyObject;

      if (queryId) {
        const resultsList = get(QueryResultsCache(queryId));
        const resultItem = resultsList.find((result) =>
          isEqual(result.keyObject, keyObject)
        );

        return resultItem?.payload;
      }

      return null;
    },
});

export default function useLoadChartData({
  queryId,
  queryParamsStr,
  fetchPolicy = 'cache-first',
  privateApi,
  queryrunId,
  isCurrentUserChartOwner,
  queryrunFinishTime,
  resultCacheExpireMillis,
  isDashboardOwner,
}: {
  queryId?: string;
  queryParamsStr: string;
  fetchPolicy?: FetchPolicy;
  privateApi?: boolean;
  queryrunId?: string;
  isCurrentUserChartOwner: boolean;
  queryrunFinishTime?: number;
  resultCacheExpireMillis?: number;
  isDashboardOwner?: boolean;
}) {
  const [queryResultCache] = useRecoilState(
    QueryResultsByKeyObject({ queryId, queryParamsStr })
  );

  const loadChartData = useRecoilCallback(
    ({ set, snapshot }) =>
      async ({
        queryId: nQueryId,
        queryParamsStr: nQueryParamsStr,
        noCache = false,
      }: {
        queryId: string;
        queryParamsStr: string;
        noCache?: boolean;
      }) => {
        const nQueryResultCache = await snapshot.getPromise(
          QueryResultsByKeyObject({
            queryId: nQueryId,
            queryParamsStr: nQueryParamsStr,
          })
        );
        const loading = nQueryResultCache?.loading;
        const hasError = !!nQueryResultCache?.response?.error;
        const hasResults = !!nQueryResultCache?.response?.results;

        // cache first
        if (fetchPolicy === 'cache-first' && !noCache) {
          // loading: don't do anything
          // we notify components by setting the recoil state
          if (loading) {
            return;
          }

          // cache found with no error: not fetching
          if (!hasError && hasResults) {
            // if (hasResults) {
            return;
          }
        }

        if (nQueryId && nQueryParamsStr) {
          // start loading
          set(
            QueryResultsByKeyObject({
              queryId: nQueryId,
              queryParamsStr: nQueryParamsStr,
            }),
            {
              ...queryResultCache,
              loading: true,
            }
          );

          // Note(joseph): queryrunFinishTime + resultCacheExpireMillis   >   Date.now()  ==> stale
          const targetedRefreshTime =
            queryrunFinishTime && resultCacheExpireMillis
              ? (queryrunFinishTime + resultCacheExpireMillis) * 1
              : Infinity;
          const isStaleQueryResult = targetedRefreshTime < Date.now();

          if (
            ((isCurrentUserChartOwner || isDashboardOwner) &&
              isStaleQueryResult) ||
            noCache
          ) {
            const runResp = await runQueryById(
              {
                queryId: nQueryId,
                paramsStr: nQueryParamsStr,
                resultCacheExpireMillis,
              },
              privateApi ? undefined : PublicQueryApi()
            );

            const error = runResp?.error;
            if (error) {
              zToast.error(error);
            }

            // set response
            set(
              QueryResultsByKeyObject({
                queryId: nQueryId,
                queryParamsStr: nQueryParamsStr,
              }),
              {
                response: runResp,
                loading: false,
              }
            );
          } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const client: any = StreamApi(privateApi);

            const QueryParamsForGetResults = {
              includeMetadata: true,
              includeColumnName: true,
            };

            const resultResp = await client.queryrunsResultDetail(
              queryrunId,
              QueryParamsForGetResults
            );
            if (resultResp.error) {
              zToast.error(resultResp.error);
              return;
            }

            try {
              const runResp = await queryResultFormat(nQueryId, resultResp);

              // set response
              set(
                QueryResultsByKeyObject({
                  queryId: nQueryId,
                  queryParamsStr: nQueryParamsStr,
                }),
                {
                  response: runResp,
                  loading: false,
                }
              );
            } catch (error) {
              if (error instanceof AxiosError) {
                // eslint-disable-next-line
                console.error('running query by id error', error);

                const formattedResult = await queryResultFormat(
                  nQueryId,
                  error.response
                );

                // if error occurs
                if (formattedResult.error) {
                  zToast.error(formattedResult.error);
                }
              }
            }
          }
        }
      },
    [queryId, queryParamsStr]
  );

  return [queryResultCache, loadChartData] as const;
}
