import { isEmpty, upperCase } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { RiBarChartBoxFill } from 'react-icons/ri';
import PaleButton from '../../components/interactive/PaleButton';
import LoadingIndicator from '../../components/LoadingIndicator';
import Modal from '../../components/modals/Modal';
import { ChartEntity } from '../chart/types';
import { useMyCharts } from './NewVizSelectorState';

export default function NewVizSelectorV3({
  onChange,
  selectedViz = [],
}: {
  onChange: (charts: ChartEntity[]) => void;
  selectedViz?: ChartEntity[];
}) {
  const [open, setOpen] = useState(false);
  const [newViz, setNewViz] = useState(selectedViz);
  const [myCharts, { loading }] = useMyCharts(open);

  useEffect(() => {
    setNewViz(selectedViz);
  }, [open]);

  const handleClose = useCallback(() => {
    if (loading) return;

    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <PaleButton
        className='gap-3'
        onClick={() => {
          setOpen(true);
        }}
      >
        <RiBarChartBoxFill size='1.1rem' />
        <span>Add Visualization</span>
      </PaleButton>
      <Modal open={open} className='flex flex-col space-y-4'>
        <h3 className='flex items-center space-x-2 font-bold text-lg'>
          <span>Your visualizations</span>
          {!isEmpty(myCharts) && loading && (
            <span>
              <LoadingIndicator />
            </span>
          )}
        </h3>
        <div className='flex-1 pb-2 overflow-y-auto max-h-[50vh] space-y-1'>
          {isEmpty(myCharts) && loading ? (
            <div className='h-[50vh] flex items-center justify-center'>
              <LoadingIndicator radius={20} />
            </div>
          ) : (
            myCharts.map((chart) => {
              const added = newViz.find((viz) => viz.id === chart.id);

              return (
                <div key={chart?.id} className='flex items-center space-x-2'>
                  <div className='flex-1 flex items-center space-x-2 flex-nowrap'>
                    {chart?.type && (
                      <div className='badge badge-sm opacity-60'>
                        {upperCase(chart?.type)}
                      </div>
                    )}
                    <span className='overflow-ellipsis max-w-[17rem] whitespace-nowrap overflow-hidden'>
                      {chart?.displayName || chart?.id}
                    </span>
                  </div>
                  <div>
                    {!added ? (
                      <button
                        className='btn btn-primary btn-sm'
                        onClick={() => {
                          setNewViz([...newViz, chart]);
                        }}
                      >
                        Add
                      </button>
                    ) : (
                      <button
                        className='btn btn-secondary btn-sm'
                        onClick={() => {
                          setNewViz(
                            newViz.filter((viz) => chart.id !== viz.id)
                          );
                        }}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>
        <div className='modal-action items-center'>
          <button
            type='button'
            className='btn btn-outline'
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              onChange(newViz);
              handleClose();
            }}
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  );
}
