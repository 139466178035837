import { Select } from "antd";

const Options = [
  { label: 'Ethereum', value: 'ethereum' },
  { label: 'Sui', value: 'sui' },
  { label: 'Solana', value: 'solana' },
];

interface ChainSelectProps {
  onChange: (e: string[]) => void;
  value: string[];
}

const ChainSelect = ({
  onChange,
  value,
}: ChainSelectProps) => {

  return <Select
    className="mb-[10px]"
    mode="tags"
    style={{ width: '100%' }}
    placeholder="Chains"
    onChange={onChange}
    value={value || []}
    options={Options}
  />
}

export default ChainSelect;