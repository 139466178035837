import { useCallback, useLayoutEffect, useRef, useState } from 'react';

export function useWidth<T>() {
  const [width, setWidth] = useState<number>();
  const ref = useRef<T>(null);

  const updateSize = useCallback(() => {
    // @ts-ignore
    const rect = ref.current?.getBoundingClientRect();
    setWidth(rect?.width);
  }, [setWidth, ref]);

  useLayoutEffect(() => {
    window.addEventListener('mouseup', updateSize);
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return [ref, width] as const;
}
