/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelListDashboardResponse,
  ModelListQueryResponse,
  ModelListTenantResponse,
  ModelUser,
} from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Marketplace<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description list marketplace creator
   *
   * @tags marketplace creator
   * @name CreatorsList
   * @summary list marketplace dashboards
   * @request GET:/marketplace/creators
   */
  creatorsList = (
    query?: {
      limit?: number;
      offset?: number;
      marketplaceType?: string;
      tenant?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<ModelUser[], any>({
      path: `/marketplace/creators`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description list marketplace dashboards
   *
   * @tags marketplace dashboard
   * @name DashboardList
   * @summary list marketplace dashboards
   * @request GET:/marketplace/dashboard
   */
  dashboardList = (
    query?: {
      limit?: number;
      offset?: number;
      days?: number;
      sortBy?: string;
      sortOrder?: string;
      creatorId?: string;
      tenant?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<ModelListDashboardResponse, any>({
      path: `/marketplace/dashboard`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description list marketplace queries
   *
   * @tags marketplace query
   * @name QueryList
   * @summary list marketplace queries
   * @request GET:/marketplace/query
   */
  queryList = (
    query?: {
      limit?: number;
      offset?: number;
      days?: number;
      sortBy?: string;
      sortOrder?: string;
      creatorId?: string;
      tenant?: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<ModelListQueryResponse, any>({
      path: `/marketplace/query`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * @description list marketplace tenants
   *
   * @tags marketplace tenant
   * @name TenantList
   * @summary list marketplace tenants
   * @request GET:/marketplace/tenant
   */
  tenantList = (
    query?: { marketplaceType?: string },
    params: RequestParams = {}
  ) =>
    this.request<ModelListTenantResponse, any>({
      path: `/marketplace/tenant`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
}
