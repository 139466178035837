import { get, pick, set, uniq } from 'lodash';
import {
  memo,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useRecoilValue } from 'recoil';
import { Column, SQueryResults } from '../QueryState';
import { useParams } from 'react-router';
import LogoBlinkerCenter from '../../../components/app/LogoBlinkerCenter';
import { H6 } from '../../../components/typographic/Headers';
import {
  OptionCard,
  SelectInputOption,
  SwitchInputOption,
  TextInputOption,
} from './ChartComposeComponents';
import SeriesCustomizerV2 from './SeriesCustomizerV2';
import produce from 'immer';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { AreaChartType, CommonChartComposeProps } from '../../chart/types-v2';
import { defaultChartTheme } from '../../chart/utils/const';
import classNames from 'classnames';
import { useAreaChartOptions, YCol } from './AreaChartComposeV2Hooks';
import AreaChart from '../../chart/components/AreaChart';
import { EmptyOption } from '../../../utils/selector';
import ChartComposeHeader from './ChartComposeHeader';

function AreaChartCompose({
  chart,
  readOnly,
  openAddToDashboardModal,
}: {
  chart: AreaChartType;
  openAddToDashboardModal?: () => void;
} & CommonChartComposeProps) {
  const params = useParams();
  const queryResults = useRecoilValue(SQueryResults(params?.id));

  const [barChartOptions, updating, { setChartOptions, setChartOptionsByMap }] =
    useAreaChartOptions(chart.id);

  const results = queryResults?.results;
  const rows = results?.rows;
  const columns = results?.metadata?.columns || [];

  const mapColumnSelectOptions = useCallback(
    (pColumns: Column[]) => {
      const colOptions = pColumns.map((column) => ({
        value: column.name,
        label: column.name,
      }));

      return [EmptyOption, ...colOptions];
    },
    [columns]
  );

  const [init, setInit] = useState(false);
  // initial setup and update chart
  useEffect(() => {
    const firstColName = get(columns, [0, 'name']);
    const secondColName = get(columns, [1, 'name']);

    // TODO: migration here for v1 bar chart to v2 bar chart
    const options = pick(chart, ['uiOptions', 'displayName']);

    if (!init && options && firstColName) {
      setInit(true);
      // This is first load after creation.
      // set dynamic default values such as columns data
      if (!options.uiOptions?.dataOptions?.xCol) {
        setChartOptions(
          produce(options, (draft) => {
            set(draft, 'uiOptions.dataOptions.xCol.colName', firstColName);
            set(
              draft,
              'uiOptions.dataOptions.yCols.[0].colName',
              secondColName
            );
            set(draft, 'uiOptions.dataOptions.yCols.[0].chartType', chart.type);

            // set color
            const currentColor = get(
              draft,
              `uiOptions.dataOptions.yCols.[0].color`
            );

            if (!currentColor) {
              set(
                draft,
                `uiOptions.dataOptions.yCols.[0].color`,
                defaultChartTheme[0]
              );
            }
          })
        );
      } else {
        // use chart config from api
        setChartOptions(options);
      }
    }
  }, [columns]);

  // options cache
  const yCols = barChartOptions?.uiOptions?.dataOptions?.yCols || [];
  const columnSelectOptions = mapColumnSelectOptions(columns);
  const avaialbleColumOptions = useMemo(() => {
    const selectedYColsNames = yCols.map((yCol) => yCol.colName) || [];

    const selectedXColsName =
      barChartOptions?.uiOptions?.dataOptions?.xCol?.colName;

    return columnSelectOptions.filter(
      (option) =>
        ![...selectedYColsNames, selectedXColsName]
          .filter((colName) => colName)
          .includes(option.value)
    );
  }, [columnSelectOptions, barChartOptions]);

  const handleGroupByOption = useCallback(
    (newGroupByCol: string) => {
      const groupByValues = newGroupByCol
        ? uniq(rows?.map((row) => row[newGroupByCol])) || []
        : [];

      const groupByYCols: YCol[] = groupByValues.map((groupByValue, idx) => ({
        colName: `${groupByValue}`,
        valueType: `${groupByValue}`,
        chartType: chart.type || 'bar',
        color: defaultChartTheme[idx % defaultChartTheme.length],
      }));

      const nState = {
        'uiOptions.dataOptions.groupByCol': newGroupByCol,
        'uiOptions.dataOptions.groupByYCols': groupByYCols,
      };

      setChartOptionsByMap(nState);
    },
    [rows, barChartOptions]
  );

  if (!rows || !chart) {
    return <div>Please run query to see the chart.</div>;
  }

  return (
    <div className='flex flex-col space-y-2'>
      <ChartComposeHeader
        chart={chart}
        openAddToDashboardModal={openAddToDashboardModal}
      />

      <div className='border rounded-lg mx-4 h-[300px]'>
        <Suspense fallback={<LogoBlinkerCenter />}>
          <AreaChart chart={chart} queryResults={results} />
        </Suspense>
      </div>
      {!readOnly && (
        <>
          <div className='grid grid-cols-2 gap-2'>
            <OptionCard>
              <H6 className='flex items-center'>
                Chart options <span className='w-2' />
                {updating && (
                  <span>
                    <LoadingIndicator />
                  </span>
                )}
              </H6>
              <div className='h-2' />
              <TextInputOption
                label='Name'
                value={barChartOptions.displayName}
                onChange={(e) => {
                  setChartOptions({
                    ...barChartOptions,
                    displayName: e.target.value,
                  });
                }}
              />
              <SwitchInputOption
                label='Show chart legend'
                checked={
                  barChartOptions?.uiOptions?.chartOptions?.showLegend || false
                }
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.chartOptions.showLegend': e.target.checked,
                  });
                }}
              />
              <SwitchInputOption
                label='Enable stacking'
                checked={
                  barChartOptions?.uiOptions?.chartOptions?.enableStacking ||
                  false
                }
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.chartOptions.enableStacking': e.target.checked,
                  });
                }}
              />
              <SwitchInputOption
                label='Normalize data'
                checked={
                  barChartOptions?.uiOptions?.chartOptions?.normalizeData ||
                  false
                }
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.chartOptions.normalizeData': e.target.checked,
                  });
                }}
              />
            </OptionCard>
            <OptionCard>
              <H6>Data options</H6>
              <div className='h-2' />
              <SelectInputOption
                label='X column'
                options={columnSelectOptions}
                value={barChartOptions?.uiOptions?.dataOptions?.xCol?.colName}
                onChange={(e) => {
                  const newColName = e.target.value;

                  setChartOptionsByMap({
                    'uiOptions.dataOptions.xCol.colName': newColName,
                    'uiOptions.dataOptions.yCols': yCols.filter(
                      (yCol) => yCol.colName !== newColName
                    ),
                  });
                }}
              />
              {yCols.map((yCol, idx) => (
                <SelectInputOption
                  key={`${yCol.colName}-${idx}`}
                  label={`Y column ${idx + 1}`}
                  onRemove={() => {
                    // delete
                    setChartOptionsByMap({
                      'uiOptions.dataOptions.yCols': yCols.filter(
                        (_, colIdx) => colIdx !== idx
                      ),
                    });
                  }}
                  value={
                    barChartOptions?.uiOptions?.dataOptions?.yCols?.[idx]
                      ?.colName
                  }
                  options={columnSelectOptions}
                  onChange={(e) => {
                    const newColName = e.target.value;
                    if (newColName) {
                      // update
                      setChartOptions(
                        produce(barChartOptions, (draft) => {
                          const duplicatedYColsIdx = yCols
                            .map((curYCol, curYColIdx) =>
                              curYCol.colName === newColName ? curYColIdx : null
                            )
                            .filter((i): i is number => typeof i === 'number');

                          if (!draft.uiOptions?.dataOptions?.yCols) return;

                          // update current
                          draft.uiOptions.dataOptions.yCols[idx].colName =
                            newColName;
                          draft.uiOptions.dataOptions.yCols[idx].customLabel =
                            '';
                          draft.uiOptions.dataOptions.yCols[idx].color =
                            draft.uiOptions.dataOptions.yCols[idx].color ||
                            defaultChartTheme[idx];

                          // remove duplicated
                          duplicatedYColsIdx.forEach((idxToRemove) => {
                            if (draft.uiOptions?.dataOptions?.yCols?.splice) {
                              draft.uiOptions.dataOptions.yCols.splice(
                                idxToRemove,
                                1
                              );
                            }
                          });

                          // unset if xCol is duplicated
                          if (
                            draft.uiOptions.dataOptions?.xCol?.colName ===
                            newColName
                          ) {
                            draft.uiOptions.dataOptions.xCol.customLabel = '';
                            draft.uiOptions.dataOptions.xCol.colName = '';
                          }
                        })
                      );
                    } else {
                      // delete
                      setChartOptionsByMap({
                        'uiOptions.dataOptions.yCols': yCols.filter(
                          (_, colIdx) => colIdx !== idx
                        ),
                      });
                    }
                  }}
                />
              ))}
              {
                // adding new column
                avaialbleColumOptions.length > 1 && (
                  <SelectInputOption
                    label={`Y column ${yCols.length + 1}`}
                    options={avaialbleColumOptions}
                    value={''}
                    onChange={(e) => {
                      const currentColor = get(
                        barChartOptions,
                        `uiOptions.dataOptions.yCols.[${yCols.length}].color`
                      );

                      const nextState = {
                        [`uiOptions.dataOptions.yCols.[${yCols.length}].colName`]:
                          e.target.value,
                      };

                      nextState[
                        `uiOptions.dataOptions.yCols.[${yCols.length}].chartType`
                      ] = chart.type;

                      if (!currentColor) {
                        nextState[
                          `uiOptions.dataOptions.yCols.[${yCols.length}].color`
                        ] = defaultChartTheme[yCols.length];
                      }
                      setChartOptionsByMap(nextState);
                    }}
                  />
                )
              }
              <SelectInputOption
                label={'Group by'}
                className={classNames({
                  'select-disabled bordered': yCols.length < 1,
                })}
                options={avaialbleColumOptions}
                value={barChartOptions.uiOptions.dataOptions?.groupByCol}
                onChange={(e) => {
                  handleGroupByOption(e.target.value);
                }}
              />
            </OptionCard>
            <OptionCard>
              <H6>X-axis options</H6>
              <div className='h-2' />
              <TextInputOption
                label='Axis label'
                value={barChartOptions.uiOptions.xAxisOptions?.label}
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.xAxisOptions.label': e.target.value,
                  });
                }}
              />
              <TextInputOption
                label='Tick format'
                placeholder='{value} %'
                value={barChartOptions.uiOptions.xAxisOptions?.tickFormat}
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.xAxisOptions.tickFormat': e.target.value,
                  });
                }}
              />
              <SwitchInputOption
                className='hidden'
                label='Logarithmic'
                checked={
                  barChartOptions?.uiOptions?.xAxisOptions?.logarithmic || false
                }
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.xAxisOptions.logarithmic': e.target.checked,
                  });
                }}
              />
              <SwitchInputOption
                className='hidden'
                label='Sort values'
                checked={
                  barChartOptions?.uiOptions?.xAxisOptions?.sortValues || false
                }
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.xAxisOptions.sortValues': e.target.checked,
                  });
                }}
              />
              <SwitchInputOption
                className='hidden'
                label='Reverse values'
                checked={
                  barChartOptions?.uiOptions?.xAxisOptions?.reverseValue ||
                  false
                }
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.xAxisOptions.reverseValue': e.target.checked,
                  });
                }}
              />
            </OptionCard>
            <OptionCard>
              <H6>Y-axis options</H6>
              <div className='h-2' />
              <TextInputOption
                label='Axis label'
                value={barChartOptions.uiOptions.yAxisOptions?.label}
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.yAxisOptions.label': e.target.value,
                  });
                }}
              />
              <TextInputOption
                label='Tick format'
                placeholder='{value} %'
                value={barChartOptions.uiOptions.yAxisOptions?.tickFormat}
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.yAxisOptions.tickFormat': e.target.value,
                  });
                }}
              />
              <TextInputOption
                label='Label format'
                placeholder='0.0'
                value={barChartOptions.uiOptions.yAxisOptions?.labelFormat}
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.yAxisOptions.labelFormat': e.target.value,
                  });
                }}
              />
              <SwitchInputOption
                label='Logarithmic'
                id='y-log'
                checked={
                  barChartOptions?.uiOptions?.yAxisOptions?.logarithmic || false
                }
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.yAxisOptions.logarithmic': e.target.checked,
                  });
                }}
              />
              <SwitchInputOption
                className='hidden'
                label='Enable right y-axis'
                checked={
                  barChartOptions?.uiOptions?.yAxisOptions?.enableRightYAxis ||
                  false
                }
                onChange={(e) => {
                  setChartOptionsByMap({
                    'uiOptions.yAxisOptions.enableRightYAxis': e.target.checked,
                  });
                }}
              />
            </OptionCard>
          </div>
          <SeriesCustomizerV2
            barChartOptions={barChartOptions}
            setBarChartOptions={setChartOptions}
          />
        </>
      )}
    </div>
  );
}

export default memo(AreaChartCompose);
