import { ReactNode, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// import Footer from './components/Footer';
import Navbar from './Navbar';
import { isEmpty, startsWith } from 'lodash';
import { useRecoilState } from 'recoil';
import { AuthState } from '../modules/auth/AuthState';
import { AuthApi } from '../api/client';
import { useCookies } from 'react-cookie';
import FullPageLoading from '../components/app/FullPageLoading';

interface IProps {
  children?: ReactNode;
}

interface IAuthRouterProps {
  onComplete: () => void;
}

function AuthRouter({ onComplete }: IAuthRouterProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [auth, setAuth] = useRecoilState(AuthState);
  const [cookies] = useCookies(['auth']);

  useEffect(() => {
    const refreshAccessToken = async () => {
      const client = AuthApi();
      try {
        const resp = await client.postAuth();
        if (resp && resp.data.accessToken && resp.data.user?.id) {
          setAuth({
            accessToken: resp.data.accessToken,
            id: resp.data.user.id,
            name: resp.data.user.name,
            email: resp.data.user.email,
            profileImage: resp.data.user.profileImage,
            tenant: resp.data.user.tenant,
            subscription: resp.data.subscription,
          });
        } else {
          setAuth(null);
        }

        setTimeout(() => {
          onComplete();
        }, 1);
      } catch (error) {
        setAuth(null);
      }
    };
    refreshAccessToken();
  }, []);

  useEffect(() => {
    const isAuthRoute = startsWith(location.pathname, '/auth') || startsWith(location.pathname, '/embed-docs') || startsWith(location.pathname, '/plans');
    if (!isAuthRoute && auth === null && isEmpty(cookies.auth)) {
      const backParam = encodeURIComponent(location.pathname);
      navigate(`/auth/login${backParam ? `?back=${backParam}` : ''}`);
    }
    if (auth?.subscription && auth.subscription.plan === 'free' && auth.subscription.planChosen === false) {
      navigate('/plans');
    }
  }, [cookies.auth, auth, location.pathname]);

  return null;
}

function Layout({ children }: IProps) {
  const [isLoading, toggleLoading] = useState(true);

  const location = useLocation();

  if (startsWith(location.pathname, '/embed-docs')) {

    return <div className='container w-full mx-auto px-5'>
      <AuthRouter onComplete={() => toggleLoading(false)} />
      {isLoading ? <FullPageLoading /> : children || <Outlet />}
    </div>
  }

  return (
    // Lock the width [responsive]
    // https://datalego.atlassian.net/browse/DL-353
    <div className='container min-w-[1280px] w-[1280px] mx-auto px-5'>
      <AuthRouter onComplete={() => toggleLoading(false)} />
      {!isLoading && <Navbar />}
      {isLoading ? <FullPageLoading /> : children || <Outlet />}
      {/* {!location.pathname.includes('auth/login') && <Footer />} */}
    </div>
  );
}

export default Layout;
