import axios, { AxiosError } from 'axios';
import {
  ModelCreateProjectRequest,
  ModelEditAPIRequest,
} from '../../../api/__gen__/data-contracts';
import {
  ApiDetailApi,
  ApiListApi,
  ChangeApiStatusApi,
  ChangeProjectStatusApi,
  CreateProjectApi,
  EditApiRelatedTableApi,
  EditColumnApi,
  EditProjectApi,
  EditQueryApi,
  EditRestApiApi,
  EditTableApi,
  MarketplaceApi,
  MoveApiApi,
  MoveTableApi,
  MyApi,
  PreviewApi,
  ProjectApi,
  ProjectDetailApi,
  ProjectListApi,
  PublicProjectListApi,
  QueryApi,
  RecentApi,
  TableDetailApi,
  TableListApi,
  getAuthHeader,
} from '../../../api/client';
import { queryResultFormat } from '../../../modules/queryBuilder/api-utils';
import envs from '../../../config/envs';
import { toast } from 'react-toastify';

export const getProjects = async (projectType: string) => {
  const client = ProjectListApi();

  return client.projectListCreate({
    type: projectType,
  });
};

export const getProjectsWithChildren = async (projectType: string) => {
  const { data } = await getProjects(projectType);

  // get projects
  const projectIds: string[] =
    data.projects?.map((e) => {
      return e.id || '';
    }) || [];

  // get APIs
  if (projectType === 'api') {
    const client = ApiListApi();
    const rest = await client.apiListCreate({
      project_ids: projectIds,
      type: 'REST',
    });
    const gql = await client.apiListCreate({
      project_ids: projectIds,
      type: 'GraphQL',
    });

    const res = [...(rest.data.apis || []), ...(gql.data.apis || [])];

    return (
      data.projects?.map((project) => {
        return {
          ...project,
          type: 'folder',
          key: project.id,
          children:
            res
              ?.filter((e) => e.project_id === project.id)
              .filter((table) => table.status !== 2)
              .map((e) => ({
                ...e,
                key: e.id,
              })) || [],
        };
      }) || []
    );
  }

  // get Tables
  const client = TableListApi();
  const res = await client.tableListCreate({
    project_ids: projectIds,
  });

  return (
    data.projects?.map((project) => {
      return {
        ...project,
        type: 'folder',
        key: project.id,
        children:
          res.data.tables
            ?.filter((e) => e.project_id === project.id)
            .filter((table) => table.status !== 2)
            .map((e) => ({
              ...e,
              key: e.id,
            })) || [],
      };
    }) || []
  );
};

export const createProject = (
  type: string,
  projectDetails: ModelCreateProjectRequest
) => {
  const client = CreateProjectApi();

  return client.createProjectCreate({
    type,
    ...projectDetails,
  });
};

export const getAllZettaTables = async () => {
  const client = TableListApi();
  const res = await client.tableListCreate({
    project_ids: [''],
  });

  return res.data.tables || [];
};

export const getAllZettaApis = async () => {
  const client = ApiListApi();
  const res = await client.apiListCreate({
    project_ids: [''],
    type: 'REST',
  });

  return res.data.apis || [];
};

export const moveTable = async (tableIds: string[], projectId: string) => {
  const client = MoveTableApi();

  const res = await client.moveTableCreate({
    table_ids: tableIds,
    project_id: projectId,
  });

  return res;
};

export const addTablesToProject = async (
  tableIds: string[],
  projectId: string
) => {
  const client = ProjectApi();

  try {
    const res = await client.tableAddCreate({
      table_ids: tableIds,
      project_id: projectId,
    });

    return res;
  } catch (error) {
    toast.error('Failed to add tables to project', {
      autoClose: 2000,
      position: 'top-center',
    });
    return null;
  }
};

export const moveApi = async (
  apiIds: string[],
  projectId: string,
  type?: string
) => {
  const client = MoveApiApi();

  try {
    const res = await client.moveApiCreate({
      api_ids: apiIds,
      project_id: projectId,
      type: type || 'REST',
    });

    return res;
  } catch (error) {
    toast.error('Failed to move API', {
      autoClose: 2000,
      position: 'top-center',
    });
    return null;
  }
};

export const deleteProject = async (projectId: string) => {
  const client = ChangeProjectStatusApi();

  const res = await client.changeProjectStatusCreate({
    id: projectId,
    status: 2,
  });

  return res;
};

export const publishProject = async (projectId: string) => {
  const client = ChangeProjectStatusApi();

  const res = await client.changeProjectStatusCreate({
    id: projectId,
    status: 1,
  });

  return res;
};

export const unpublishProject = async (projectId: string) => {
  const client = ChangeProjectStatusApi();

  const res = await client.changeProjectStatusCreate({
    id: projectId,
    status: 0,
  });

  return res;
};

export const deleteTable = async (tableIds: string[], projectId: string) => {
  const client = ProjectApi();

  const res = await client.tableDelCreate({
    table_ids: tableIds,
    project_id: projectId,
  });

  return res;
};

export const deleteApi = async (tableId: string) => {
  const client = ChangeApiStatusApi();

  const res = await client.changeApiStatusCreate({
    status: 2,
    id: tableId,
  });

  return res;
};

export const updateTableMetadata = async (
  tableId: string,
  tableMetadata: {
    desc: string;
    example: string;
    summary: string;
  }
) => {
  const client = EditTableApi();

  const res = await client.editTableCreate({
    id: tableId,
    ...tableMetadata,
  });

  return res;
};

export const updateProjectMetadata = async (projectId, metadata) => {
  const client = EditProjectApi();

  const res = await client.editProjectCreate({
    id: projectId,
    ...metadata,
  });

  return res;
};

export const updateApiMetadata = async (metadata: ModelEditAPIRequest) => {
  const client = EditRestApiApi();

  const res = await client.editRestApiCreate(metadata);

  return res;
};

export const getProjectDetailById = async (id: string) => {
  const client = ProjectDetailApi();

  const res = await client.projectDetailCreate({
    id,
  });

  return res.data.project || {};
};

export const getTablesByProjectId = async (projectId: string) => {
  const client = TableListApi();

  const res = await client.tableListCreate({
    project_ids: [projectId],
  });

  return res.data.tables || [];
};

export const getApisByProjectId = async (projectId: string) => {
  const client = ApiListApi();

  const res = await client.apiListCreate({
    type: 'REST',
    project_ids: [projectId],
  });

  return res.data.apis || [];
};

export const getTableDetailById = async (tableId: string) => {
  const client = TableDetailApi();

  const res = await client.tableDetailCreate({
    table_id: tableId,
  });

  // temp solution to not display date_creation_date and block_date
  //  ============== ============== ============== ============== ==============
  if (res.data.table?.id?.toLowerCase().includes('sui')) {
    const columns = res.data?.column?.filter(
      (c) => c.name !== 'date_creation_date' && c.name !== 'block_date'
    );
    return {
      ...res.data,
      column: columns,
    };
  }
  //  ============== ============== ============== ============== ==============

  return res.data || {};
};

export const getTablePreview = async (
  source: string,
  database: string,
  table: string
) => {
  const client = PreviewApi();

  const resp = await client.databaseTablePreviewDetail(source, database, table);

  const formattedResult = await queryResultFormat('preview', resp);

  return formattedResult.results;
};

export const getPublicApiProjects = async () => {
  const client = PublicProjectListApi();

  const res = await client.publicProjectListCreate({
    type: 'API',
  });

  return res.data.projects || [];
};

export const getPublicTableProjects = async () => {
  const client = PublicProjectListApi();

  const res = await client.publicProjectListCreate({
    type: 'table',
  });

  return res.data.projects || [];
};

export const getOpenApiSpec = async () => {
  const resp = await axios.get(
    `${envs.ZettaBackendApiPrivate}/swagger/doc.json`,
    {
      headers: getAuthHeader(),
      withCredentials: true,
    }
  );

  return resp.data;
};

export const updateTableColumnsMetadata = (
  tableId: string,
  description?: string,
  name?: string
) => {
  const client = EditColumnApi();

  return client.editColumnCreate({
    desc: description,
    name,
    table_id: tableId,
  });
};

export const getRelatedTables = async (apiId: string) => {
  const client = ApiDetailApi();

  const res = await client.apiDetailCreate({
    api_id: apiId,
  });

  return res.data.related_table || [];
};

export const editApiRelatedTable = async (
  apiId: string,
  tableIds: string[],
  operation: string
) => {
  const client = EditApiRelatedTableApi();

  const res = await client.editApiRelatedTableCreate({
    api_id: apiId,
    related_tables: tableIds,
    operation,
  });

  return res;
};

export const getQueries = async () =>
  // userId: string | undefined,
  // tenant?: string
  {
    const client = MyApi();

    const resp = await client.queriesList();

    return resp.data.items || [];
    // return (
    //   resp.data.items?.filter(
    //     (e) => e.creator?.id === userId || e.creator?.tenant === tenant
    //   ) || []
    // );
  };

export const getRecents = async () => {
  const client = RecentApi();

  const resp = await client.recentCreate({
    type: 'all',
  });

  return resp.data?.recent_list || [];
};

export const deleteQuery = async (queryId: string) => {
  try {
    const client = QueryApi();
    // TODO use query delete
    const resp = await client.queriesDelete(queryId, {
      //
    });

    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error.response,
        data: error?.response?.data,
      };
    }
  }

  return null;
};

export const renameQuery = async (displayName: string, id: string) => {
  const client = EditQueryApi();

  await client.editQueryCreate({
    display_name: displayName,
    id,
  });
};

export const getGraphQlApis = async () => {
  const client = ApiListApi();
  const res = await client.apiListCreate({
    project_ids: [''],
    type: 'GraphQL',
  });

  return res.data.graph_ql || [];
};

export const getMarketplaceDashboards = async () => {
  const client = MarketplaceApi();
  const res = await client.dashboardList();

  return res.data.items || [];
};

export const getMarketplaceQueries = async () => {
  const client = MarketplaceApi();
  const res = await client.queryList();

  return res.data.items || [];
};
