import { PostgreSQL, sql } from '@codemirror/lang-sql';
import { useCodeMirror } from '@uiw/react-codemirror';
import produce from 'immer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import {
  atomFamily,
  useRecoilCallback,
  useRecoilState,
  useSetRecoilState,
} from 'recoil';
import { useTables } from '../database/schema-hooks';
import { SelectedSchemaState } from '../database/SchemaState';
import { SelectedTableSchemaState } from '../database/TableSchemaState';
import {
  // queryStateDefaultValue,
  QueryStateV3,
  SaveQueryFun,
} from './QueryBuilderHooksV3';
import { fetchTranslatedSql } from './QueryPadRpc';
import { useFormatSqlWithType } from './QueryPadState';
import { HighlightedTextState } from './QueryState';
import { content } from '@uiw/codemirror-extensions-events';
import { codemirrorTheme } from '../../config/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const eventExt = content({
  keydown: (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
      // if ctrl/cmd + enter
      // then we need to prevent default to allow run with highlighted text
      e.preventDefault();
    }
  },
});

export const AIsOpenTextToSql = atomFamily<boolean, string | null | undefined>({
  key: 'AIsOpenTextToSql',
  default: false,
  // default: true,
});

export const ATTSInputText = atomFamily<string, string | null | undefined>({
  key: 'ATTSInputText',
  // default: 'How many unique transaction types are there?',
  default: '',
});

export const ATTSTranslatedSql = atomFamily<string, string | null | undefined>({
  key: 'ATTSTranslatedSql',
  default: '',
});

export function useTextToSql(queryId?: string | null) {
  const [isOpenTTS, setIsOpenTTS] = useRecoilState(AIsOpenTextToSql(queryId));
  const [ttsInputText, setTtsInputText] = useRecoilState(
    ATTSInputText(queryId)
  );

  function toggleIsOpenTTS() {
    setIsOpenTTS(!isOpenTTS);
  }
  return [
    { isOpenTTS, ttsInputText },
    { setIsOpenTTS, toggleIsOpenTTS, setTtsInputText },
  ] as const;
}

export function useTextToSqlTranslate(queryId?: string | null) {
  const [selectedSchema] = useRecoilState(SelectedSchemaState);
  const [selectedTableSchema] = useRecoilState(SelectedTableSchemaState);
  const [ttsInputText] = useRecoilState(ATTSInputText(queryId));
  const [ttsTranslatedSql, setTtsTranslatedSql] = useRecoilState(
    ATTSTranslatedSql(queryId)
  );
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const formatSqlWithType = useFormatSqlWithType();

  async function getTranslatedSql() {
    if (isLoading) {
      return;
    }

    setError('');
    setLoading(true);

    const resp = await fetchTranslatedSql({
      text: ttsInputText,
      database: selectedSchema?.schema,
      table: selectedTableSchema,
      queryId,
    });

    setLoading(false);

    const sqlText = resp?.data;
    const message = resp?.data?.message;

    if (message) {
      setError(message);
    } else if (sqlText) {
      setTtsTranslatedSql(formatSqlWithType(sqlText));
    }
  }

  return [
    { error, isLoading, ttsTranslatedSql },
    { getTranslatedSql },
  ] as const;
}

export function useQueryPadInjectV3() {
  const handleInject = useCallback(
    (keyword: string) => {
      const { view } = window;

      if (!view) return;

      const from = view.state.selection.ranges[0].from || 0;
      const to = view.state.selection.ranges[0].to || 0;

      const tr = view.state.update(
        {
          changes: [
            { from, to, insert: '' },
            { from, insert: keyword },
          ],
          selection: { anchor: from + keyword.length },
        },
        {
          scrollIntoView: true,
        }
      );

      view.dispatch(tr);
      view.focus();
    },
    [window.view]
  );

  const formatSqlWithType = useFormatSqlWithType();

  const formatQuery = useCallback(
    ({ text }: { text?: string } = {}) => {
      const { view } = window;

      if (!view) return;

      const qtext = view.state.doc.toJSON().join('\n');

      const from = 0;
      const to = qtext.length;

      const tr = view.state.update(
        {
          changes: [
            { from, to, insert: '' },
            { from, insert: formatSqlWithType(text || qtext) },
          ],
        },
        {
          scrollIntoView: true,
        }
      );

      view.dispatch(tr);
      view.focus();
    },
    [window.view]
  );

  return [{ formatQuery, handleInject }] as const;
}

export function useQueryIdV3() {
  const params = useParams();
  const queryId = params?.id;

  return queryId;
}

export function useQueryPadV3({
  readOnly,
}: // saveQueryDeb,
{
  readOnly?: boolean;
  saveQueryDeb: SaveQueryFun;
}) {
  const queryId = useQueryIdV3();
  const [queryValues] = useRecoilState(QueryStateV3(queryId));

  const setHighlightedText = useSetRecoilState(HighlightedTextState);
  const editor = useRef<HTMLDivElement>();

  /**
   * Preventing soft navigate to trigger saving query
   which will eventually pollute "updatedTime"
   */
  const [init, setInit] = useState(false);

  /**
   * Get states needed for autocomplete sql
   */
  const [extension, setExtension] = useState([sql()]);
  const [selectedSchema] = useRecoilState(SelectedSchemaState);
  const [selectedTable] = useRecoilState(SelectedTableSchemaState);
  const [{ tables }] = useTables(selectedSchema);

  useEffect(() => {
    setInit(false);
  }, [queryId]);

  /**
   * Set custom autocomplete for sql - tables, columns in editor
   */
  useEffect(() => {
    const formattedTables =
      tables?.items?.map((t) => {
        const { name } = t;
        const cols = t?.columns?.map((c) => {
          const label = c.name;
          const type = 'constant';
          return { label, type };
        });
        return { name, cols };
      }) || [];
    const tableAutoCompleteList = Object.assign(
      {},
      ...formattedTables.map((item) => ({
        [`${selectedSchema?.schema}.${item?.name}`]: item.cols,
      })),
      ...formattedTables.map((item) => ({ [`${item?.name}`]: item.cols }))
    );

    const defaultTable: string =
      selectedTable || formattedTables?.[0]?.name || '';

    if (formattedTables?.length > 0 && tableAutoCompleteList) {
      setExtension([
        sql({
          dialect: PostgreSQL,
          schema: tableAutoCompleteList,
          defaultTable,
        }),
      ]);
    }
  }, [selectedSchema, selectedTable, tables]);

  const setQuery = useRecoilCallback(
    ({ set, snapshot }) =>
      async (text?: string) => {
        const queryValues2 = await snapshot.getPromise(QueryStateV3(queryId));
        const values = produce(queryValues2, (draft) => {
          draft.text = text;
        });
        set(QueryStateV3(queryId), values);

        // will revist this later, autosave feature
        // if (
        //   queryId &&
        //   queryStateDefaultValue.text !== text &&
        //   queryValues2.text !== text /* && init */
        // ) {
        //   saveQueryDeb(values);
        // }

        if (!init) {
          setInit(true);
        }
      }
  );

  const props = useCodeMirror({
    theme: codemirrorTheme,
    readOnly,
    container: editor.current,
    extensions: [...extension],
    value: queryValues.text,
    onChange: (value) => {
      setQuery(value);
    },
    onUpdate: (viewUpdate) => {
      if (readOnly) {
        return;
      }

      const range = viewUpdate.state.selection.ranges[0];
      if (range) {
        const selectedSql = viewUpdate.state.sliceDoc(range.from, range.to);
        setHighlightedText(selectedSql);
        return;
      }

      setHighlightedText('');
    },
  });

  return [{ query: queryValues.text, setQuery, editor }, props] as const;
}
