import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { AiTwotoneClockCircle } from 'react-icons/ai';
import { BsChevronDown } from 'react-icons/bs';
import { RiApps2Fill, RiAccountPinBoxFill } from 'react-icons/ri';
import { useNavigate } from 'react-router';
// import { toast } from 'react-toastify';
import { ActiveLink } from '../../components/ActiveLink';
import { HorizontalLine } from '../../components/HorizontalLine';
import { useWorkspace } from './workspace-hooks';
import useAuth from '../../hooks/auth';
import { COMMUNITY } from '../../modules/auth/AuthState';

export default function WorkspaceSelector() {
  const [{ workspace }] = useWorkspace();
  const [{ auth }] = useAuth();
  const [open, toggleOpen] = useState(false);
  const navigate = useNavigate();

  const parseWorkspaceName = (origin: string) => {
    const [workspaceName] = origin.split('.');

    // Note(jjin): we might need to remove the dash(-) in between if this is a valid use case - ask Scott
    // can remove this part if not needed
    // const decoratedWorkspaceName = workspaceName.replaceAll('-', ' ');

    return workspaceName;
  };

  const workspaceName = useMemo(() => {
    const defaultWorkspaceName = 'My Workspace';
    if (!auth?.tenant) {
      return defaultWorkspaceName;
    }

    return auth?.tenant === COMMUNITY
      ? defaultWorkspaceName
      : parseWorkspaceName(auth.tenant);
  }, [auth]);

  return (
    <div
      className='dropdown dropdown-start'
      onBlur={() => {
        toggleOpen(false);
      }}
    >
      <button
        className='inline-flex space-x-2 items-center bg-white text-[1.0rem] font-semibold btn-sm mr-5 text-gray-600 px-4 py-5 rounded'
        onClick={() => {
          toggleOpen(true);
        }}
      >
        {workspace?.key === 'community' ? (
          <>
            <RiApps2Fill size='1.2rem' className='text-primary' />
            <span>Community</span>
          </>
        ) : (
          <>
            <RiAccountPinBoxFill size='1.2rem' className='text-purple-500' />
            <span className='capitalize'>{workspaceName}</span>
          </>
        )}
        <BsChevronDown size='1rem' />
      </button>
      <ul
        className={classNames(
          'mt-3 p-2 shadow bg-base-100 rounded-box w-72 space-y-2',
          open ? 'menu menu-compact dropdown-content' : 'hidden'
        )}
      >
        <li>
          <ActiveLink
            to='/'
            onMouseDown={() => {
              navigate('/community');
              // toast.dismiss();
              // toast.info('Community version is coming soon');
            }}
            activeNames='active'
            className='flex flex-col'
          >
            <div className='inline-flex w-full items-center justify-between'>
              <div className='inline-flex space-x-2 items-center'>
                <RiApps2Fill size='1rem' className='text-primary' />
                <span>Community</span>
              </div>
              {workspace?.key === 'community' && (
                <AiTwotoneClockCircle size='0.5rem' className='text-primary' />
              )}
            </div>
            <span className='opacity-50'>
              Explore queries, APIs, projects, and dashboards from the community
            </span>
          </ActiveLink>
        </li>
        <HorizontalLine />
        <li>
          <ActiveLink
            to='/'
            onMouseDown={() => {
              navigate('/my-workspace');
            }}
            activeNames='active'
            className='flex flex-col'
          >
            <div className='inline-flex w-full items-center justify-between'>
              <div className='inline-flex space-x-2 items-center'>
                <RiAccountPinBoxFill size='1rem' className='text-purple-500' />
                <span className='capitalize'>{workspaceName}</span>
              </div>
              {workspace?.key === 'my-workspace' && (
                <AiTwotoneClockCircle size='0.5rem' className='text-primary' />
              )}
            </div>
            <span className='opacity-50'>
              Create, manage and publish queries, APIs, projects, and dashboards
            </span>
          </ActiveLink>
        </li>
      </ul>
    </div>
  );
}
