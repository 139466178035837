import { HTMLAttributes } from 'react';

import sql from '../../assets/icons/sql.svg';
import sqlV2 from '../../assets/icons/sql-v2.svg';
import contract from '../../assets/icons/contract.svg';
import datasource from '../../assets/icons/data-source.svg';
import gsheets from '../../assets/icons/gsheets.svg';
import exchange from '../../assets/icons/exchange.svg';
import classNames from 'classnames';

export function SQLIcon(props: HTMLAttributes<HTMLImageElement>) {
  return <img src={sql} className='w-12 h-12 inline' {...props} />;
}

export function SQLIconV2(props: HTMLAttributes<HTMLImageElement>) {
  const { className = 'w-12 h-12' } = props;
  return <img src={sqlV2} className={classNames(className)} {...props} />;
}

export function DataSourceIcon() {
  return <img src={datasource} className='w-8 h-8 inline' />;
}

export function GoogleSheetIcon() {
  return <img src={gsheets} className='w-8 h-8 inline' />;
}

export function ContractIcon() {
  return <img src={contract} className='w-12 h-12 inline' />;
}

export function APIIcon(props: HTMLAttributes<HTMLImageElement>) {
  const { className = 'w-12' } = props;
  return (
    <img src={exchange} className={classNames('', className)} {...props} />
  );
}
