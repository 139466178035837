import { useCallback, useRef, useState, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { BiGitRepoForked, BiRocket } from 'react-icons/bi';
import { RiSettings4Line, RiShareBoxFill } from 'react-icons/ri';
import { MdOutlineAddBox } from 'react-icons/md';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isEmpty, pick } from 'lodash';

import { SelectedDBState } from '../database/DatabaseState';
import {
  AQueryRunMetrics,
  // AQueryRunErrorMessage,
  AQueryRunStartAt,
  HighlightedTextState,
  SQueryResults,
} from './QueryState';
import LoadingIndicator from '../../components/LoadingIndicator';
import { AQueryParams } from './QueryPadState';
import {
  TemporaryQueryResultId,
  useRunHighlightedQuery,
} from './QueryBuilderHooks';
import usePrompt from '../../hooks/router';
import { useQueryClone } from './QueryBuilderCloneHooks';
import StopWatchV3 from './StopWatchV3';
import QueryPadV3 from './QueryPadV3';
import CommandsContainerV3 from './CommandsContainerV3';
import { APIIcon } from '../../components/icons/Icons';
import QueryBuilderResultsV3 from './QueryBuilderResultsV3';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import {
  useQueryIdV3,
  useQueryPadInjectV3,
  // useTextToSql,
} from './QueryPadStateV3';
import QueryBuilderSettingModal from './QueryBuilderSettingModal';
import QueryBuilderPublishModal from './QueryBuilderPublishModal';
import QueryBuilderCreateApiModal from './QueryBuilderCreateApiModal';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { DefaultAutoDismissMs } from '../../config/toast';
import useAuth from '../../hooks/auth';
import { useQueryRunV3 } from './QueryBuilderHooksV3';
import ProfileAvatar from '../auth/ProfileAvatar';
import produce from 'immer';
import QueryBuilderRunButtonV3 from './components/QueryBuilderRunButtonV3';
import { convertNumberToFormatted, DateTimeFormat } from '../../utils/date';
import { formatDistanceToNow } from 'date-fns';
import QueryBuilderUnpublishModal from './QueryBuilderUnpublishModal';
import LogoBlinkerCenter from '../../components/app/LogoBlinkerCenter';
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import QueryBuilderLeftPanel from './QueryBuilderLeftPanel';
import Modal from '../../components/modals/Modal';
import useDragX from '../../hooks/use-drag-x';
import { useLocation, useNavigate } from 'react-router';
import { FaSave } from 'react-icons/fa';
import SaveQueryModal from './SaveQueryModal';
import StarButton from '../api/components/StarButton';
import { ACCESS_MAPPING } from '../api/const';
import { COMMUNITY } from '../auth/AuthState';
import { formatDataUsageInBytes } from '../../utils/format';
import QueryBuilderSwitchButton from './QueryBuilderSwitchButton';
import GraphQLBuilder from './GraphQLBuilder';
import { useSearchParams } from 'react-router-dom';

import Postgres from '../../assets/images/icon-postgres.svg';
import Presto from '../../assets/images/icon-presto.svg';
import { analytics } from '../../App';
import { HorizontalLine } from '../../components/HorizontalLine';

import './QueryBuilderV3.scss';
import useTitle from '../../hooks/useTitle';

export default function QueryBuilderV3() {
  const queryId = useQueryIdV3();
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();

  const [isGraphQLBuilder, setIsGraphQLBuilder] = useState(false);

  const setQueryRunMetrics = useSetRecoilState(AQueryRunMetrics);
  // selector states
  const [selectedDB] = useRecoilState(SelectedDBState);

  const [{ menu, isMyWorkspace }, { getPath }] = useWorkspace();
  useEffect(() => {
    const dashboardId = params.get('dashboardId');
    const dashboardName = params.get('dashboardName');
    if (dashboardId) {
      toast.info(
        <span>
          Back to dashboard{' '}
          <a
            className='text-primary'
            href={`${getPath('dashboards')}/${dashboardId}`}
          >
            {dashboardName}
          </a>
        </span>,
        {
          position: 'bottom-left',
          className: 'toast-info-back-to-dashboard',
        }
      );
    }
  }, [params]);
  // query states
  const [queryStartAt] = useRecoilState(AQueryRunStartAt);
  const [queryRunMetrics] = useRecoilState(AQueryRunMetrics);
  const [highlightedText] = useRecoilState(HighlightedTextState);
  const tempResult = useRecoilValue(SQueryResults(TemporaryQueryResultId));
  const queryResults = useRecoilValue(SQueryResults(queryId)) || tempResult;

  // query params
  const [queryParams] = useRecoilState(AQueryParams);

  const runQueryRef = useRef(false);

  const [
    {
      updateRpcState,
      queryValues,
      forkedQuery,
      isQueryDataLoading,
      isQueryRunning: isQueryByIdRunning,
      rpcStatusDel,
    },
    { handleRun, saveQueryDeb, deleteQuery, resetQueryValues },
  ] = useQueryRunV3({ queryPath: `${getPath(menu)}` });

  useTitle('Query', queryValues.displayName || 'New Query');

  const isRealtimeDB = useMemo(() => {
    return (
      queryValues?.database?.startsWith('realtime') ||
      selectedDB?.startsWith('realtime')
    );
  }, [queryValues, selectedDB]);

  const queryRunParams = useMemo(() => {
    return {
      ...pick(queryValues, ['displayName', 'id', 'text']),
      database: selectedDB,
      queryParams,
    };
  }, [queryValues, selectedDB, queryParams]);

  useEffect(() => {
    // everytime user enters /queries/new we'll reset the queryValues state
    if (!queryId) {
      resetQueryValues();
    }
  }, []);

  const [shortCutTriggered, setShortCutTriggered] = useState(false);

  const [{ auth }, { getIsOwner }] = useAuth();
  const isAuthor =
    !queryId || location?.state === queryId || getIsOwner(queryValues); // detect queryId from state to fix UI layout glitch (on navigating from /new to /:id)

  const [runHighlightedQuery, { isQueryRunning: isHighlightedQueryRunning }] =
    useRunHighlightedQuery();

  useEffect(() => {
    const runQuery = async () => {
      runQueryRef.current = true;
      await handleRun(queryRunParams);
      runQueryRef.current = false;
    };

    if (!runQueryRef.current && shortCutTriggered) {
      if (highlightedText) {
        runHighlightedQuery({
          ...queryRunParams,
          queryId: queryRunParams.id || TemporaryQueryResultId,
          query: highlightedText,
          selectedDB: queryRunParams.database,
        });
      } else {
        runQuery();
      }
      setShortCutTriggered(false);
    }
  }, [shortCutTriggered, highlightedText, queryRunParams]);

  const isQueryRunning = isQueryByIdRunning || isHighlightedQueryRunning;

  usePrompt(
    'Are you sure to leave the page?',
    isQueryDataLoading || updateRpcState?.isLoading
  );

  // Note(jjin): this is for the case when the query is executed but not saved yet
  usePrompt(
    "Are you sure to leave the page? You haven't save your query yet",
    queryValues.access === 'unsaved'
  );

  const [isRunning, setIsRunning] = useState(false);
  const isQueryTextChanged = useMemo(() => {
    return !!queryId && queryValues.text !== queryValues.loadedText;
  }, [queryValues]);

  // Note(jjin): this is for the case => user creating new query and have modified the query text
  // if user types whitespaces only, we don't care about it
  usePrompt(
    'Are you sure to leave the page? You have unsaved changes',
    (!isRunning &&
      !queryId &&
      queryValues.text?.replaceAll(/\s+/g, '') !== '') ||
      isQueryTextChanged
  );

  const [clone, { isCloning }] = useQueryClone({
    queryPath: `/my-workspace/${menu}`,
  });

  const [{ formatQuery }] = useQueryPadInjectV3();

  const handleFormat = useCallback(() => {
    formatQuery();
  }, [queryValues.text]);

  const handleAddParam = useCallback(() => {
    const { view } = window;

    if (!view) return;

    const from = view.state.selection.ranges[0].from || 0;
    const to = view.state.selection.ranges[0].to || 0;

    const newStr = `{{param${queryParams.length + 1}}}`;
    const tr = view.state.update(
      {
        changes: [
          { from, to, insert: '' },
          { from, insert: newStr },
        ],
        selection: { anchor: from + newStr.length },
      },
      {
        scrollIntoView: true,
      }
    );

    view.dispatch(tr);
    view.focus();

    // adding traking event
    analytics.track('Query - Add Query Param click', {});
  }, [queryParams]);

  // const [, { toggleIsOpenTTS }] = useTextToSql(queryId);

  const [openSetting, setOpenSetting] = useState(false);
  const [openSaveQueryModal, setOpenSaveQueryModal] = useState(false);
  const [openPublish, setOpenPublish] = useState(false);
  const [openCreateApi, setOpenCreateApi] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const forkedQueryAuthorName = forkedQuery?.creator?.name;
  const forkedQueryName = forkedQuery?.displayName;
  const forkedQueryAuthorId = forkedQuery?.creator?.id;

  const [leftPanelWidth, setLeftPanelWidth] = useState(368);
  const [dragWidth, setDragWidth] = useState(0);
  const [dragWidthValidated, setDragWidthValidated] = useState(0);

  // const [queryRunErrorMessage] = useRecoilState(AQueryRunErrorMessage);

  const isUnsavedQuery = useMemo(() => {
    return queryValues?.access === 'unsaved' || !queryId;
  }, [queryValues, queryId]);

  const refLeftPanelHandle = useRef<HTMLDivElement>(null);
  useDragX(refLeftPanelHandle, {
    onDraging: (moved) => {
      const nWidth = leftPanelWidth + dragWidth;

      setDragWidth(moved);
      if (nWidth > 320 && nWidth < 450) {
        setDragWidthValidated(moved);
      }
    },
    onDragEnd: () => {
      setLeftPanelWidth(leftPanelWidth + dragWidthValidated);
      setDragWidth(0);
      setDragWidthValidated(0);
    },
  });

  // @ts-ignore
  const published = queryValues.access === 'public';

  if (isQueryDataLoading && isEmpty(queryValues)) {
    return (
      <LayoutBodyCard className='p-40'>
        <LogoBlinkerCenter />
      </LayoutBodyCard>
    );
  }

  const isCreateApiReady = queryId && isAuthor && isMyWorkspace;

  return (
    <>
      <div className='flex'>
        <div
          className={classNames('flex-auto flex items-center space-x-1', {
            'overflow-hidden': !forkedQueryAuthorName || published,
          })}
        >
          <div className='flex-none'>
            {isRealtimeDB ? (
              <div className='min-w-[48px]'>
                <img src={Postgres} width={48} />
              </div>
            ) : (
              <div className='min-w-[48px]'>
                <img src={Presto} width={48} />
              </div>
            )}
          </div>
          {!isAuthor ? (
            <div className='flex flex-col w-full items-start'>
              <div className='font-bold whitespace-nowrap'>
                {queryValues.displayName || queryValues.id}
                {isMyWorkspace && queryValues.access && (
                  <span className='self-center bg-base-content bg-opacity-10 py-[0.1rem] mx-2 px-1 text-[0.7rem] rounded-md opacity-50 font-semibold'>
                    {ACCESS_MAPPING[queryValues.access]}
                  </span>
                )}
              </div>
              {queryValues.creator?.name && (
                <div className='flex items-center text-sm font-semibold gap-1'>
                  <ProfileAvatar
                    className='bg-primary w-4'
                    creator={queryValues.creator}
                  />
                  <div className='opacity-50'>{queryValues.creator?.name}</div>
                  <div className='opacity-50'>
                    •{' '}
                    {convertNumberToFormatted(queryValues.createdTime, {
                      formatStr: DateTimeFormat,
                    })}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              className={classNames('flex-auto w-full relative items-center', {
                'max-w-[24rem]':
                  isAuthor || (forkedQueryAuthorName && !published),
                'overflow-hidden ': !forkedQueryAuthorName || published,
              })}
            >
              {!published ? (
                <div className='relative z-30 w-full'>
                  <input
                    disabled={isUnsavedQuery}
                    type='text'
                    className='input w-full max-w-[24rem] pr-[4.4rem] no-outline'
                    value={queryValues.displayName || 'New Query'}
                    maxLength={125}
                    onChange={(e) =>
                      saveQueryDeb(
                        produce(queryValues, (draft) => {
                          draft.displayName = e.target.value;
                        })
                      )
                    }
                  />
                  {queryValues.access && !isUnsavedQuery && (
                    <span className='absolute right-[0.4rem] self-center bg-base-content bg-opacity-20 py-1 px-2 text-xs rounded opacity-50 font-bold top-[0.4rem]'>
                      {ACCESS_MAPPING[queryValues.access]}
                    </span>
                  )}
                  {/* <select
                    className='select select-sm bg-transparent font-normal active:outline-none focus:outline-none absolute right-[0.25rem] self-center bg-base-content bg-opacity-20 text-xs rounded opacity-50 font-bold top-[0.25rem]'
                    onChange={async (val) => {
                      const access = val.target.value;
                      saveQueryDeb({
                        ...queryValues,
                        access,
                      });
                    }}
                    value={queryValues.access}
                  >
                    {[
                      { value: 'private', label: 'Only visible to me' },
                      { value: 'org_internal', label: 'Visible to my org' },
                    ].map((n) => (
                      <option key={n.value} value={n.value}>
                        {n.label}
                      </option>
                    ))}
                  </select> */}
                </div>
              ) : (
                <div className='flex-auto flex items-center space-x-2 overflow-hidden pr-2'>
                  <span
                    title={queryValues.displayName}
                    className='font-bold text-lg whitespace-nowrap text-ellipsis overflow-hidden'
                  >
                    {queryValues.displayName}
                  </span>
                  {queryValues.publishedTime !== 0 && (
                    <span className='flex-none text-sm opacity-50'>
                      published{' '}
                      {formatDistanceToNow(queryValues.publishedTime || 0, {
                        addSuffix: true,
                      })}
                    </span>
                  )}
                  {isMyWorkspace && queryValues.access && (
                    <span className='self-center bg-base-content bg-opacity-10 py-[0.1rem] mx-2 px-1 text-[0.7rem] rounded-md opacity-50 font-semibold'>
                      {ACCESS_MAPPING[queryValues.access]}
                    </span>
                  )}
                </div>
              )}
              {!published && forkedQueryAuthorName && (
                <div className='z-20 relative w-full h-0'>
                  <div className='z-20 absolute w-full h-full bottom-[1.1rem]'>
                    <div className='flex flex-nowrap pt-[1.2rem] px-2 text-xs rounded bg-base-content bg-opacity-10'>
                      <span className='opacity-60 flex-shrink-0 pr-1'>
                        forked from
                      </span>
                      <span className='text-primary whitespace-nowrap overflow-ellipsis overflow-hidden'>
                        @{forkedQueryAuthorName}
                        {forkedQueryName
                          ? `/${forkedQueryName}`
                          : `/${forkedQueryAuthorId}`}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className='flex items-center flex-none'>
          {updateRpcState.isLoading && (
            <div className='px-3'>
              <LoadingIndicator />
            </div>
          )}

          <div className='flex space-x-3'>
            {isUnsavedQuery && (
              <>
                <div
                  className={classNames({
                    'tooltip opacity-90': !queryId,
                  })}
                  data-tip='Please run query first'
                >
                  <button
                    disabled={!queryId}
                    className='btn btn-primary gap-2'
                    onClick={() => {
                      setOpenSaveQueryModal(!openSaveQueryModal);
                    }}
                  >
                    <FaSave size='1.1rem' />
                    Save
                  </button>
                </div>

                {openSaveQueryModal && (
                  <SaveQueryModal
                    openSaveQueryModal={openSaveQueryModal}
                    setOpenSaveQueryModal={setOpenSaveQueryModal}
                  />
                )}
              </>
            )}
            {queryId && queryValues.access !== 'unsaved' && (
              <>
                {isMyWorkspace && isAuthor && (
                  <>
                    <button
                      className='btn btn-primary gap-2'
                      onClick={() => {
                        setOpenSetting(!openSetting);
                      }}
                    >
                      <RiSettings4Line size='1.1rem' />
                      Setting
                    </button>
                    {openSetting && (
                      <QueryBuilderSettingModal
                        openSetting={openSetting}
                        setOpenSetting={setOpenSetting}
                      />
                    )}

                    {published ? (
                      <div
                        data-tip='Please upgrade the plan to make userapi private'
                        className={classNames({
                          'tooltip tooltip-warning opacity-90':
                            auth?.tenant === COMMUNITY,
                        })}
                      >
                        <button
                          className='published-button btn btn-primary gap-2 hover:btn-error font-bold'
                          onClick={() => {
                            setOpenPublish(!openPublish);
                          }}
                          disabled={auth?.tenant === COMMUNITY}
                        >
                          <BiRocket size='1.1rem' />
                          {auth?.tenant === COMMUNITY ? (
                            <span>Unpublish</span>
                          ) : (
                            <>
                              <span className='when-not-hover'>Published</span>
                              <span className='when-hover'>Unpublish</span>
                            </>
                          )}
                        </button>
                        {openPublish && (
                          <QueryBuilderUnpublishModal
                            openPublish={openPublish}
                            setOpenPublish={setOpenPublish}
                          />
                        )}
                      </div>
                    ) : (
                      <>
                        <button
                          className='btn btn-primary gap-2'
                          onClick={() => {
                            setOpenPublish(!openPublish);
                          }}
                        >
                          <BiRocket size='1.1rem' />
                          <span>Publish</span>
                        </button>
                        {openPublish && (
                          <QueryBuilderPublishModal
                            openPublish={openPublish}
                            setOpenPublish={setOpenPublish}
                          />
                        )}
                      </>
                    )}

                    {queryId && (
                      <button
                        className='btn btn-warning'
                        onClick={() => {
                          setOpenDelete(true);
                        }}
                      >
                        <span>Delete</span>
                      </button>
                    )}

                    <div className='border-r my-[0.2rem] border-gray-300' />
                  </>
                )}
                {!isMyWorkspace && isAuthor && (
                  <>
                    <button
                      className='btn btn-primary gap-2'
                      onClick={() => {
                        navigate(`/my-workspace/${menu}/${queryId}`);
                      }}
                    >
                      Edit
                    </button>
                    <div className='border-r my-[0.2rem] border-gray-300' />
                  </>
                )}
                <StarButton
                  id={queryId}
                  isStarred={queryValues?.star}
                  starCount={queryValues?.starCount}
                  isListView={false}
                  page={'query'}
                />
                <button
                  className={classNames(
                    'flex btn btn-primary items-center gap-2',
                    {
                      hidden: !queryId,
                      loading: isCloning,
                    }
                  )}
                  onClick={() => {
                    clone();
                    analytics.track('Query - Fork a SQL query', {
                      queryId,
                    });
                  }}
                >
                  <BiGitRepoForked size='1.1rem' />{' '}
                  {queryValues.forkCount ? queryValues.forkCount : ''}
                </button>
                <CopyToClipboard text={window.location.href}>
                  <button
                    className='flex btn btn-primary items-center gap-2'
                    onClick={() => {
                      toast.success('Share link copied', {
                        autoClose: DefaultAutoDismissMs,
                      });
                    }}
                  >
                    <RiShareBoxFill size='1.1rem' />
                  </button>
                </CopyToClipboard>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='h-6' />

      {isGraphQLBuilder ? (
        <GraphQLBuilder
          onClick={() => {
            setIsGraphQLBuilder(false);
          }}
        />
      ) : (
        <>
          {isMyWorkspace && isAuthor && (
            <div className='flex bg-base-content bg-opacity-20 items-center'>
              <div className='flex-1 px-2 font-bold opacity-70'>
                QUERY BUILDER
              </div>
              <div className='flex items-center'>
                {/* Switcher */}
                <QueryBuilderSwitchButton
                  label='SWITCH TO GRAPHQL BUILDER'
                  onClick={() => {
                    setIsGraphQLBuilder(true);
                  }}
                />
                <div
                  className={classNames({
                    'tooltip opacity-90': queryParams.length > 0,
                  })}
                  data-tip='Query with parameters is not supported yet'
                >
                  <button
                    className='btn flex items-center gap-2'
                    style={{ borderRadius: 0 }}
                    disabled={queryParams.length > 0}
                    onClick={() => {
                      // if(isRealtimeDB) {
                      //   toast.warn('Create API is not supported for Realtime DB yet');
                      //   return;
                      // }
                      if (!isCreateApiReady) {
                        toast.warn('Please run a query first');
                        return;
                      }
                      analytics.track('API - Create API With new SQL');
                      setOpenCreateApi(true);
                    }}
                  >
                    <APIIcon className='w-4' />
                    <span>CREATE API</span>
                  </button>
                  <QueryBuilderCreateApiModal
                    open={openCreateApi}
                    setOpen={setOpenCreateApi}
                  />
                </div>
              </div>
            </div>
          )}

          <div
            className={classNames('lg:basis-2/5 basis-full bg-base-100', {
              'p-5 rounded-xl': !isAuthor || !isMyWorkspace,
            })}
          >
            <div className='flex'>
              {/* Side bar */}
              {isMyWorkspace && isAuthor && (
                <>
                  <div
                    className='flex-none overflow-visible'
                    style={{
                      width: leftPanelWidth + dragWidthValidated,
                    }}
                  >
                    <QueryBuilderLeftPanel isRealtimeDB={isRealtimeDB} />
                  </div>
                  <div className='divider-y' />
                  <div className='w-0 overflow-visible z-40 select-none'>
                    <div
                      ref={refLeftPanelHandle}
                      className='w-[5px] h-full cursor-grab hover:bg-base-300'
                    ></div>
                  </div>
                </>
              )}

              <div className='flex-auto min-w-0 relative flex flex-col'>
                <div>
                  {(!isAuthor || !isMyWorkspace) && (
                    <div className='opacity-40 font-bold text-sm mb-2'>
                      QUERY
                    </div>
                  )}
                  <QueryPadV3
                    selectedDB={selectedDB}
                    readOnly={!isAuthor || !isMyWorkspace}
                    saveQueryDeb={saveQueryDeb}
                    isQueryRunning={isQueryRunning}
                    setShortCutTriggered={setShortCutTriggered}
                    runQuery={async () => {
                      await handleRun(queryRunParams);
                    }}
                  />
                  {/* {queryRunErrorMessage && (
                <div className='absolute z-1 p-4 text-xs top-0 left-0 w-full bg-gray-200 leading-relax'>
                  <code className='text-[#444]'>
                    <MdWarning
                      style={{ display: 'inline-block' }}
                      fill='#e74c3c'
                      className='mx-2'
                    />
                    {queryRunErrorMessage}
                  </code>
                </div>
              )} */}
                  {isMyWorkspace && isAuthor && (
                    <div className='border-b border-t flex relative'>
                      <CommandsContainerV3 />
                      <div className='overflow-x-auto w-full'>
                        <div className='flex justify-between min-w-[860px]'>
                          <div className='flex'>
                            <div className='flex  items-center gap-[5px]'>
                              <button
                                className='btn btn-sm btn-neutral'
                                onClick={handleFormat}
                              >
                                FORMAT
                              </button>
                              <button
                                className='btn btn-sm btn-neutral'
                                onClick={handleAddParam}
                              >
                                <MdOutlineAddBox
                                  size='0.9rem'
                                  className='mr-1'
                                />
                                PARAMETER
                              </button>
                              {/* <button
                        className='btn btn-sm btn-neutral'
                        onClick={() => {
                          toggleIsOpenTTS();
                        }}
                      >
                        <RiLoginCircleLine size='0.9rem' className='mr-1' />
                        TEXT TO SQL
                      </button> */}
                            </div>
                          </div>
                          <div className='flex items-center space-x-5'>
                            <div className='flex items-center opacity-80'>
                              <span className='mr-1 text-xs'>Cache TTL</span>
                              <select
                                className='select select-xs bg-transparent font-normal active:outline-none focus:outline-none'
                                value={queryValues.resultCacheExpireMillis}
                                onChange={async (e) => {
                                  const newTtl = parseInt(e.target.value, 10);
                                  saveQueryDeb({
                                    ...queryValues,
                                    resultCacheExpireMillis: newTtl,
                                  });
                                  // adding traking event
                                  analytics.track(
                                    'Query - Change cache TTL click',
                                    {}
                                  );
                                }}
                              >
                                {[
                                  { value: 1, label: 'no cache' },
                                  { value: 60 * 1000, label: '1 min' },
                                  { value: 3 * 60 * 1000, label: '3 mins' },
                                  { value: 10 * 60 * 1000, label: '10 mins' },
                                  { value: 30 * 60 * 1000, label: '30 mins' },
                                  { value: 60 * 60 * 1000, label: '1 hr' },
                                  { value: 3 * 60 * 60 * 1000, label: '3 hrs' },
                                  { value: 6 * 60 * 60 * 1000, label: '6 hrs' },
                                  {
                                    value: 12 * 60 * 60 * 1000,
                                    label: '12 hrs',
                                  },
                                  {
                                    value: 24 * 60 * 60 * 1000,
                                    label: '24 hrs',
                                  },
                                ].map((n) => (
                                  <option key={n.value} value={n.value}>
                                    {n.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <StopWatchV3
                              startedAt={queryStartAt}
                              endedAt={
                                isQueryRunning
                                  ? null
                                  : queryResults?.results?.resultReceivedAt
                              }
                              executionMillis={queryRunMetrics.executionMillis}
                            />
                            <div className='text-xs opacity-80'>
                              CU: {(!!queryStartAt && queryRunMetrics.cu) || 0}
                            </div>

                            <div className='text-xs opacity-80'>
                              Read:{' '}
                              {(!!queryStartAt &&
                                formatDataUsageInBytes(queryRunMetrics.read)) ||
                                0}
                            </div>
                            <div className='text-xs opacity-80'>
                              Output:{' '}
                              {(!!queryStartAt &&
                                formatDataUsageInBytes(
                                  queryRunMetrics.write
                                )) ||
                                0}
                            </div>
                            <QueryBuilderRunButtonV3
                              disabled={updateRpcState?.isLoading}
                              startedAt={queryStartAt}
                              needQueryUpdate={isQueryTextChanged}
                              style={{ borderRadius: 0 }}
                              isQueryRunning={isQueryRunning}
                              hasHighlightedText={!!highlightedText}
                              onClick={() => {
                                setQueryRunMetrics({
                                  cu: 0,
                                  write: 0,
                                  read: 0,
                                  executionMillis: 0,
                                });
                                setIsRunning(true);
                                if (highlightedText) {
                                  runHighlightedQuery({
                                    ...queryRunParams,
                                    queryId:
                                      queryRunParams.id ||
                                      TemporaryQueryResultId,
                                    query: highlightedText,
                                    selectedDB: queryRunParams.database,
                                  });

                                  // track event
                                  // Adding tracking code here
                                  analytics.track(
                                    'Query run - Highlighted text - start'
                                  );
                                } else {
                                  // track event
                                  // Adding tracking code here
                                  analytics.track('Query run - Full - start');
                                  handleRun(queryRunParams);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className='pb-3 hidden'>
                  {' '}
                  {/* hidden for now */}
                  {/* <HorizontalLine /> */}
                  <div className=' flex gap-8 items-center'>
                    <div className='font-bold  overflow-hidden overflow-ellipsis whitespace-nowrap'>
                      Query Snapshot
                    </div>
                    <div className='flex-1'>
                      <div className='flex w-full flex-col w-[20rem] flex-shrink-0 flex-grow-0 p-4 gap-4 text-sm'>
                        <div className='flex'>
                          <div className='flex flex-1 flex-col gap-2 pr-1'>
                            <div className='opacity-30 font-semibold'>
                              CU Units
                            </div>
                            <div className='font-semibold opacity-80'>
                              10000
                            </div>
                          </div>
                          <div className='flex flex-1 flex-col gap-2'>
                            <div className='opacity-30 font-semibold'>
                              Final Data Size
                            </div>
                            <div className='font-semibold opacity-80'>
                              180.3MB
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <HorizontalLine />
                </div>

                <QueryBuilderResultsV3
                  runQuery={() => {
                    handleRun(queryRunParams);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}

      <Modal
        open={openDelete}
        className='flex flex-col space-y-4'
        onClickBackdrop={() => setOpenDelete(false)}
      >
        <h3 className='flex items-center space-x-2 font-bold text-lg'>
          <span>Deleting the query</span>
        </h3>
        <div className='flex-1 pb-2 overflow-y-auto max-h-[50vh] space-y-1'>
          Are you sure?
        </div>
        <div className='modal-action items-center'>
          <button
            type='button'
            className='btn btn-outline'
            onClick={async () => {
              setOpenDelete(false);
            }}
          >
            CANCEL
          </button>
          <button
            type='button'
            className={classNames('btn btn-warning', {
              loading: rpcStatusDel.isLoading,
            })}
            onClick={async () => {
              const success = await deleteQuery();
              if (success) {
                setOpenDelete(false);
                navigate('/my-workspace/queries');
              }
            }}
          >
            DELETE
          </button>
        </div>
      </Modal>
    </>
  );
}
