import { useSearchParams } from 'react-router-dom';
import { TParamState } from '../../queryBuilder/types';
import { isEmpty, isEqual, keys, trim, values } from 'lodash';
import classNames from 'classnames';
import { memo, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import QueryParamInput from '../../queryBuilder/components/QueryParamInput';

function DashboardParams({ allParams }: { allParams: TParamState }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const paramDefaultValueMap = useMemo(
    () =>
      allParams.reduce((prev, param) => {
        prev[param.name] = param.defaultValue;
        return prev;
      }, {} as Record<string, string | undefined>),
    [allParams]
  );

  const searchParamsMap = useMemo(
    () =>
      allParams.reduce((prev, param) => {
        const value = searchParams.get(param.name);
        if (value) {
          prev[param.name] = value;
        }
        return prev;
      }, {} as Record<string, string | null>),
    [allParams, searchParams]
  );

  const currentConsolidatedParams = {
    ...paramDefaultValueMap,
    ...searchParamsMap,
  };

  const [params, setParams] = useState(currentConsolidatedParams);

  if (isEmpty(allParams)) {
    return null;
  }

  const formInvalid =
    values(params).find((value) => trim(value || '') === '') === '';

  const changed = !isEqual(params, currentConsolidatedParams);

  return (
    <div className='flex flex-wrap'>
      {allParams.map((param) => {
        return (
          <div
            key={params.name}
            className='flex p-2 bg-base-100 rounded-lg space-x-2 items-center mr-2 mb-2'
          >
            <div>{param.name}</div>
            <div>
              <QueryParamInput
                className='input input-sm input-bordered max-w-[10.2rem]'
                queryParam={{
                  ...param,
                  value: params[param.name] || '',
                }}
                onChange={(value) => {
                  setParams({
                    ...params,
                    [param.name]: value,
                  });
                }}
              />
            </div>
          </div>
        );
      })}
      <div className='mr-2'>
        <button
          className={classNames(
            'btn',
            !changed || formInvalid
              ? 'btn-disabled opacity-80 text-base-100'
              : 'btn-secondary'
          )}
          onClick={() => {
            toast.dismiss();

            // validate
            if (formInvalid) {
              toast.warning('Invalid param');
              return;
            }

            // apply
            keys(params).forEach((key) => {
              const value = params[key];
              if (value) {
                searchParams.set(key, value);
              }
            });

            setSearchParams(searchParams, { replace: true });
          }}
        >
          Apply params
        </button>
      </div>
      {changed && (
        <div className='mr-2'>
          <button
            className={classNames('btn btn-warning')}
            onClick={() => {
              setParams(currentConsolidatedParams);
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
}

export default memo(DashboardParams);
