import { Suspense, useEffect } from 'react';
import {
  Route,
  Navigate,
  Routes,
  useNavigate,
  useLocation,
  matchPath,
} from 'react-router';
import PageLoading from './components/app/PageLoading';
import LayoutV3 from './layouts/LayoutV3';
import QueryBuilderV3 from './modules/queryBuilder/QueryBuilderV3';
import Explorer from './modules/explorer/Explorer';
import NewApiStep2 from './modules/api/NewApiStep2';
import ViewApi from './modules/api/ViewApi';
import DashboardViewPageV3 from './modules/dashboard/DashboardViewPageV3';
import QueryListToApiPage from './modules/explorer/QueryListToApiPage';
import ApiKeysPage from './modules/api-key/ApiKeysPage';
import Upload from './modules/connectors/Upload';
import ConnectorView from './modules/connectors/ConnectorView';
import { ApiUsageView } from './modules/api/ApiUsageView';
import QueryHistoryListPage from './modules/explorer/QueryHistoryListPage';
import QueryRunHistory from './modules/explorer/QueryRunHistory';
import RealtimeAPIGQL from './modules/realtime-api/RealtimeAPIGQL';
import NewContractView from './modules/contracts/NewContractView';
import useAuth from './hooks/auth';

export function CommunityRoutes() {
  return (
    <Routes>
      <Route path='/' element={<LayoutV3 />}>
        <Route index element={<Navigate to='apis' replace />} />
        <Route path='api-key' element={<ApiKeysPage />} />
        <Route path='api-key/:id' element={<ApiUsageView />} />
        <Route
          path='queries/:id'
          element={
            <Suspense fallback={<PageLoading />}>
              <QueryBuilderV3 />
            </Suspense>
          }
        />
        <Route
          path='dashboards/:dashboardId'
          element={
            <Suspense fallback={<PageLoading />}>
              <DashboardViewPageV3 />
            </Suspense>
          }
        />
        <Route
          path='apis/:apiId'
          element={<Navigate to='overview' replace />}
        />
        <Route path='apis/:apiId/*' element={<ViewApi />} />
        <Route path='*' element={<Explorer />} />
      </Route>
    </Routes>
  );
}

const v1ToV2Links = [
  ['/my-workspace/api-key/:id', '/v2/api-key/:id'],
  ['/my-workspace/queries/:id', '/v2/build/:id'],
  ['/my-workspace/dashboards/:id', '/v2/public/dashboards/:id'],
  ['/my-workspace/apis/:id', '/v2/workspace/graphql-apis/:id'],
  ['/my-workspace/apis/:id/overview', '/v2/workspace/graphql-apis/:id'],
];
export function MyWorkspaceRoutes() {
  const [{ auth }] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (auth && auth.tenant !== 'zettablock.com') {
      const isValid = v1ToV2Links.some((tuple) => {
        const mt = matchPath(tuple[0], location.pathname);
        if (mt) {
          navigate(tuple[1].replace(':id', (mt.params as { id: string }).id));
          return true;
        }
        return false;
      });
      if (!isValid) {
        navigate('/v2/explore/tables');
      }
    }
  }, [auth, location]);
  return (
    <Routes>
      <Route path='/' element={<LayoutV3 />}>
        <Route index element={<Navigate to='apis' replace />} />
        <Route path='api-key' element={<ApiKeysPage />} />
        <Route path='api-key/:id' element={<ApiUsageView />} />
        <Route path='query-history' element={<QueryHistoryListPage />} />
        <Route path='query-history/:queryRunId' element={<QueryRunHistory />} />
        <Route
          path='queries/api'
          element={
            <Suspense fallback={<PageLoading />}>
              <QueryListToApiPage />
            </Suspense>
          }
        />
        <Route
          path='queries/new'
          element={
            <Suspense fallback={<PageLoading />}>
              <QueryBuilderV3 key='new' />
            </Suspense>
          }
        />
        <Route
          path='queries/:id'
          element={
            <Suspense fallback={<PageLoading />}>
              <QueryBuilderV3 key='id' />
            </Suspense>
          }
        />

        {/* contracts */}
        <Route
          path='contracts/new'
          element={
            <Suspense fallback={<PageLoading />}>
              <NewContractView />
            </Suspense>
          }
        />
        <Route
          path='dashboards/:dashboardId'
          element={
            <Suspense fallback={<PageLoading />}>
              <DashboardViewPageV3 />
            </Suspense>
          }
        />

        <Route path='data-sources/type/:type' element={<Upload />} />
        <Route path='data-sources/:id/*' element={<ConnectorView />} />

        <Route path='apis/new' element={<NewApiStep2 />} />
        <Route
          path='apis/:apiId'
          element={<Navigate to='overview' replace />}
        />
        <Route path='apis/:apiId/*' element={<ViewApi />} />

        <Route path='realtime-api/:id' element={<RealtimeAPIGQL />} />

        <Route path='*' element={<Explorer />} />
      </Route>
    </Routes>
  );
}
