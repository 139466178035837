import { useRecoilState } from 'recoil';
import { ModelListQueryrunResponse } from '../../api/__gen__/data-contracts';
import { useRpcStatus } from '../../hooks/network';
import { fetchQueryruns } from './QueryHistoryRpcV3';
import { AQueryHistoryState } from './QueryHistoryStateV3';

export const useQueryHistoryV3 = ({ queryId }: { queryId?: string | null }) => {
  const [rpcStatus, setRpcStatus] = useRpcStatus();
  const [queryHistory, setQueryHistory] = useRecoilState(AQueryHistoryState);

  async function getQueryHistory() {
    if (!queryId) {
      return;
    }

    if (rpcStatus?.isLoading) {
      return;
    }

    setRpcStatus({
      isLoading: true,
      error: '',
    });

    const resp = await fetchQueryruns({ queryId });
    const error = resp?.data.error;

    if (error) {
      // error
      setRpcStatus({
        isLoading: false,
        error,
      });
    } else {
      setRpcStatus({
        isLoading: false,
      });
      // success
      const data = resp?.data as ModelListQueryrunResponse;
      setQueryHistory(data);
    }
    setRpcStatus({
      isLoading: false,
      error,
    });
  }

  return [
    { queryHistory, rpcStatus },
    { setRpcStatus, getQueryHistory },
  ] as const;
};
