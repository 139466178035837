import { Link } from "react-router-dom";
import OverlapAvatar from "../../components/OverlapAvatar"
import { ModelListDashboardItem, PoProject } from "../../../api/__gen__/data-contracts";
import { MdOutlineDashboard } from "react-icons/md";
import queryIcon from './queryIcon.svg';

export enum ExploreItemType {
  API = 'apis',
  QUERY = 'queries',
  DASHBOARD = 'dashboards',
  TABLE = 'tables',
  GRAPHQL = 'graphql',
  GRAPHQL_API = 'graphql-apis'
}

interface ExploreItemProps {
  type?: ExploreItemType,
  title?: string,
  author?: string,
  description?: string,
  imgUrl?: string
  item?: PoProject | ModelListDashboardItem,
  from?: string
}
export const ExploreItem = ({
  title,
  author,
  description,
  type,
  imgUrl,
  item,
  from
}: ExploreItemProps) => {

  switch (type) {
    case ExploreItemType.API:
      return <div className="w-[500px]  mb-[50px]">
        <Link to={`/v2/explore/apis/${item?.id}/overview?from=${from ?? ''}`}>
          <div className="mb-[10px]">
            <OverlapAvatar apiId={item?.id} />
          </div>
          <div className="flex ">
            <div className="flex-1">
              <h3 className="font-bold text-[16px]">{title}</h3>
              <div className="my-[10px] text-[#777a80] text-[15px]">
                {author}
              </div>
              <p className="text-[#777a80] text-[14px] line-clamp-3">{description}
              </p>
            </div>
          </div>
        </Link>
      </div>
    case ExploreItemType.GRAPHQL:
      return <div className="w-[500px] my-8">
        <Link to={`/v2/explore/graphql/${item?.id}?from=${from ?? ''}`}>
          <div className="flex gap-[15px]">
            <div>
              <img src={imgUrl || "https://ui-avatars.com/api/?name=2"} className="w-[50px] h-[50px]" />
            </div>
            <div className="flex-1">
              <h3 className="text-lg font-bold text-[16px]">{title}</h3>
              <div className="my-[10px] text-[#777a80] text-[15px]">
                {author || 'unknown author'}
              </div>
              <p className="text-[#777a80] text-[14px]">{description}
              </p>
            </div>
          </div>
        </Link>
      </div>
    case ExploreItemType.GRAPHQL_API:
      return <div className="w-[500px] my-8">
        <Link to={`/v2/explore/graphql-apis/${item?.id}/overview?from=${from ?? ''}`}>
          <div className="flex gap-[15px]">
            <div>
              <img src={imgUrl || "https://ui-avatars.com/api/?name=2"} className="w-[50px] h-[50px]" />
            </div>
            <div className="flex-1">
              <h3 className="text-lg font-bold text-[16px]">{title}</h3>
              <div className="my-[10px] text-[#777a80] text-[15px]">
                {author || 'unknown author'}
              </div>
              <p className="text-[#777a80] text-[14px]">{description}
              </p>
            </div>
          </div>
        </Link>
      </div>
    case ExploreItemType.TABLE:
      return <div className="w-[500px] my-8">
        <Link to={`/v2/explore/tables/${item?.id}/overview?from=${from ?? ''}`}>
          <div className="flex gap-[15px]">
            <div>
              <img src={imgUrl || "https://ui-avatars.com/api/?name=2"} className="w-[50px] h-[50px]" />
            </div>
            <div className="flex-1">
              <h3 className="text-lg font-bold">{title}</h3>
              <div className="my-[10px] text-[#777a80]">
                {author || 'unknown author'}
              </div>
              <p className="text-[#777a80] text-sm">{description}
              </p>
            </div>
          </div>
        </Link>
      </div>

    case ExploreItemType.DASHBOARD:
      return <div className="w-[500px]  my-8">
        <Link to={`/v2/explore/dashboards/${item?.id}`}>
          <div className="flex gap-[15px]">
            <div>
              <MdOutlineDashboard size={50} color="#263dff" />
            </div>
            <div className="flex-1">
              <h3 className="text-lg font-bold">{title}</h3>
              <div className="my-[10px] text-[# 777a80]">
                {author}
              </div>
            </div>
          </div>
        </Link>
      </div>
    case ExploreItemType.QUERY:
      return <div className="w-[500px]  my-8">
        <Link to={`/v2/build/${item?.id}`}>
          <div className="flex gap-[15px]">
            <div>
              <img src={queryIcon} className="w-[40px] h-[40px]" />
            </div>
            <div className="flex-1">
              <h3 className="text-lg font-bold">{title}</h3>
              <div className="my-[10px] text-[# 777a80]">
                {author}
              </div>
            </div>
          </div>
        </Link>
      </div>
    default:
      break;
  }

  return <div className="w-[500px]  my-8">
    <div className="flex ">
      <div>
        <img src="https://ui-avatars.com/api/?name=2" className="w-[40px] h-[40px]" />
      </div>
      <div className="flex-1">
        <h3 className="text-lg font-bold">Item Title {title}</h3>
        <div>
          Author {author}
        </div>
        <p className="text-sm text-[#777a80]">Item description
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam corporis fuga perferendis, sunt repellendus veritatis quidem natus debitis! Nam neque perferendis vel aliquid earum temporibus cumque ducimus facilis quod dolore! {description}
        </p>
      </div>
    </div>
  </div>
}


/**
 * 
 * TODO LIST:
 * 1. navigate to table project
 * 2. navigate to table project overview
 * 3. data connection
 * 
 */