/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ModelSearchRequest, ModelSearchResponse } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Search<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description search api,table,query
   *
   * @tags search
   * @name SearchCreate
   * @summary search api,table,query
   * @request POST:/search
   */
  searchCreate = (request: ModelSearchRequest, params: RequestParams = {}) =>
    this.request<ModelSearchResponse, any>({
      path: `/search`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
