/* eslint-disable @typescript-eslint/no-explicit-any */

import { isEmpty } from "lodash";
import { BiSearch } from "react-icons/bi";
import { VscCircleOutline } from "react-icons/vsc";
import { ModelUserApiUserFacingEventLog } from "../../../api/__gen__/data-contracts"
import Input from "../../../components/inputs/Input";
import { convertNumberToFormatted, DateTimeFormat } from "../../../utils/date";
import { isJson } from "../../../utils/json";
import { useMemo, useState } from "react";
import { LogTypeTag } from "../ViewApiLogs";


const Logs = ({
  logs
}: {
  logs: ModelUserApiUserFacingEventLog[]
}) => {


  const [keyword, setKeyword] = useState('');
  const [lType, setType] = useState<string | null>(null);
  
  const nApiLogs = useMemo(
    () =>
      logs?.filter((i) => {
        const sameType = lType ? i.type?.toLowerCase() === lType : true;
        const keywordFound =
          i.message?.toLowerCase()?.includes(keyword.toLowerCase()) ||
          i.reason?.toLowerCase()?.includes(keyword.toLowerCase());

        return sameType && keywordFound;
      }) || [],
    [logs, keyword, lType]
  );
  
  return <div className='flex flex-col p-5 gap-4'>
    <div className='flex gap-5 items-center'>
      <div className='flex-1 flex px-2 py-1 items-center'>
        <div className='absolute w-0'>
          <BiSearch size='1rem' className='relative left-2 opacity-40' />
        </div>
        <Input
          noOutline
          type='text'
          placeholder='Search'
          className='w-full min-w-36 bg-base-200 pl-8'
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
        />
      </div>
      <div className='flex-auto' />
      <div className='bg-base-200 flex gap-5 py-3 px-5 self-start rounded-lg'>
        <div
          className='cursor-pointer hover:font-bold font-semibold flex text-error items-center gap-1'
          onClick={() => {
            setType('error');
          }}
        >
          <VscCircleOutline size='0.8rem' /> ERROR
        </div>
        <div
          className='cursor-pointer hover:font-bold font-semibold flex text-warning items-center gap-1'
          onClick={() => {
            setType('warn');
          }}
        >
          <VscCircleOutline size='0.8rem' /> WARN
        </div>
        <div
          className='cursor-pointer hover:font-bold font-semibold flex text-info items-center gap-1'
          onClick={() => {
            setType('info');
          }}
        >
          <VscCircleOutline size='0.8rem' /> INFO
        </div>
        <div
          className='cursor-pointer hover:font-bold font-semibold flex text-[#4C6EF5] items-center gap-1'
          onClick={() => {
            setType('debug');
          }}
        >
          <VscCircleOutline size='0.8rem' /> DEBUG
        </div>
      </div>
    </div>
    <ul className='flex flex-col gap-2'>
      {isEmpty(nApiLogs) && (
        <li className='text-center opacity-30 p-20'>No logs</li>
      )}
      {nApiLogs?.map(({ type, reason, message, eventTime }, idx) => (
        <li key={idx} className='flex gap-5'>
          <span className='flex-shrink-0'>
            <LogTypeTag type={type} />
          </span>
          <span className='flex-shrink-0'>
            <span className='text-sm'>
              {convertNumberToFormatted(eventTime, {
                formatStr: DateTimeFormat,
              })}
            </span>
          </span>
          <div className='font-semibold opacity-80 overhid overflow-auto'>
            {reason}
            <pre>
              {isJson(message || '')
                ? JSON.stringify(JSON.parse(message || ''), null, 2)
                : message}
            </pre>
          </div>
        </li>
      ))}
    </ul>
  </div>
}

export default Logs