import { useRecoilCallback } from 'recoil';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';

import { useRpcStatus } from '../../hooks/network';
import { postCloneApi } from './api-rpc';
import { useApiId } from './ViewApiHooks';

export function useApiClone(
  { queryPath }: { queryPath: string } = { queryPath: '/apis' }
) {
  const apiId = useApiId();
  const navigate = useNavigate();
  const [rpcStatus, setRpcStatus] = useRpcStatus();

  const cloneQuery = useRecoilCallback(
    () => async () => {
      if (rpcStatus.isLoading || !apiId) {
        return;
      }

      toast.dismiss();
      setRpcStatus({ isLoading: true });

      const result = await postCloneApi({ apiId });
      const data = result?.data;
      const error = result?.data?.error;

      if (error) {
        setRpcStatus({
          isLoading: false,
          error,
        });
      } else if (data?.id) {
        setRpcStatus({
          isLoading: false,
        });

        navigate(`${queryPath}/${data.id}`);
      }
    },
    [apiId, rpcStatus]
  );

  // TODO refactor order of props
  return [cloneQuery, { isCloning: rpcStatus?.isLoading }] as const;
}
