import LayoutBodyCard from "../../components/cards/LayoutBodyCard"
import BodyHeader3 from "../../components/typographic/BodyHeader3"
import { HorizontalLine } from "../../components/HorizontalLine"
import InputGroup from "./components/InputGroup"
import { useCallback, useMemo, useState } from "react"
import { IoIosArrowBack } from "react-icons/io"
import { VerticalLine } from "../../components/VerticalLine"
import EventCard from "./components/EventCard"
import EventLogPopup from "./components/EventLogPopup"
import { toast } from "react-toastify"
import { ChainsApi } from "../../api/client"
import { ModelABI } from "../../api/__gen__/data-contracts"
import { AxiosError } from "axios"


const parseAbi = (abi: string) => JSON.parse(abi)

const DEFAULT_ADDRESS = '0xd37bbe5744d730a1d98d8dc97c42f0ca46ad7146'
const chainsClient = ChainsApi();

const fetchEventsAndABI = async (chain: string, address: string) => {
  const { events, abi } = (await chainsClient.contractsEventsDetail(chain, address)).data
  return { events, abi }
}

const NewContractView = () => {

  const [step, setStep] = useState(1)
  const [chain, setChain] = useState('')
  const [address, setAddress] = useState(DEFAULT_ADDRESS)

  const [abiStr, setAbiStr] = useState('')
  const [eventsList, setEventsList] = useState<ModelABI[]>([])
  const [contractName, setContractName] = useState('')

  const [showPopup, setShowPopup] = useState(false)

  const [eventName, setEventName] = useState('')

  const inputsByEventName = useMemo(() => {
    const inputsByEventNameMap = new Map<string, string[]>()
    if (abiStr === '') return inputsByEventNameMap

    const parsedAbi = parseAbi(abiStr) || []
    parsedAbi.forEach((abi: ModelABI) => {
      if (abi.type === 'event') {
        if (!abi.inputs || !abi.name) return
        inputsByEventNameMap.set(abi.name, abi.inputs.map((input) => input.name || ''))
      }
    })

    return inputsByEventNameMap
  }, [abiStr])


  const resetInputFields = () => {
    // reset list and ABI string
    setEventsList([])
    setAbiStr('')
    setContractName('')
  }

  const handleClick = useCallback(async () => {
    resetInputFields()


    try {
      const { events, abi } = await fetchEventsAndABI(chain, address)
      setAbiStr(abi || '')
      setEventsList(events || [])
      setStep(2)
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        toast.error(`${error?.response?.data.message}. ` || 'something went wrong')
      }

      setStep(2)
      // toast.error('something went wrong')
    }
  }, [chain, address])



  return <LayoutBodyCard className='lg:basis-2/5 basis-full min-h-[calc(100vh-16rem)] flex flex-col'>
    <div className='flex px-6 py-3 items-center justify-between'>
      <BodyHeader3>
        {
          step === 1 ? "Get data from chain" : <span
            className="text-primary font-semibold flex items-center cursor-pointer"
            onClick={() => {
              setStep(prevStep => prevStep - 1);
            }}
          >
            <IoIosArrowBack size='1.8rem' />
            Back
          </span>
        }
      </BodyHeader3>
    </div>
    <HorizontalLine />
    <div className="w-full flex flex-1">
      <div className='px-6 py-6 items-start justify-between h-full w-1/2'>


        {/* Step 1 -  */}
        <div className="pb-4">
          <div className="font-bold pb-1">
            Submit specific smart contract addresses, then get the events and ABI.
          </div>
          <div className="text-xs opacity-50">
            A few steps to get the recent events of a smart contract.
          </div>
        </div>


        <div className="max-w-xl">
          <InputGroup label='Chain' className="mb-4">
            <select className="select select-bordered w-full" placeholder="Select Chain"
              value={chain}
              onChange={e => {
                setChain(e.target.value)
              }}
              disabled={step === 2}
            >
              <option value="">Select Chain</option>
              <option value="ethereum">ETHEREUM</option>
              <option value="avalanche">AVALANCHE</option>
              {/* <option value="zksync">zkSync</option> */}
              {/* <option value="polygon" disabled>POLYGON</option> */}
            </select>
          </InputGroup>
          <InputGroup label='Contract Address' className="mb-4">
            <input
              value={address}
              className='input input-bordered w-full'
              type='text'
              placeholder='Contract Address'
              onChange={(e) => {
                setAddress(e.target.value)
              }}
              disabled={step === 2}
            />
          </InputGroup>

          {
            step === 2 && <>

              {Math.random() > 1 && <InputGroup label='Contract Name' className="mb-4">
                <input
                  value={contractName}
                  className='input input-bordered w-full'
                  type='text'
                  placeholder='Contract Name'
                  onChange={(e) => {
                    setContractName(e.target.value)
                  }}
                />
              </InputGroup>}
              <InputGroup label='ABI' className="mb-4">
                <textarea
                  readOnly
                  rows={6}
                  value={abiStr}
                  className='textarea textarea-bordered w-full'
                  placeholder='Enter contract ABI'
                  onChange={(e) => {
                    setAddress(e.target.value)
                  }}
                />
              </InputGroup>
            </>
          }


          <div className="w-full flex">
            <div className="w-[9.75rem]"></div>
            {
              step === 1 && <button className="btn btn-primary mt-4 w-[6rem]" onClick={() => {
                if (chain && address) {
                  handleClick()
                } else {
                  toast.info('Please fill in all fields', {
                    autoClose: 1000
                  })
                }
              }}>
                Next
              </button>
            }
            {
              step === 2 && Math.random() > 1 && <button className="btn btn-primary mt-4 w-[6rem]" onClick={() => {
                if (chain && address && abiStr && contractName) {
                  toast.success(`${JSON.stringify({
                    chain,
                    address,
                    abi: abiStr,
                    name: contractName
                  }, null, 2)}`)
                } else {
                  toast.info('Please fill in all fields', {
                    autoClose: 1000
                  })
                }
              }}>
                Submit
              </button>
            }
          </div>
        </div>




      </div>
      <VerticalLine />
      <div className='px-6 py-6 items-start justify-between h-full w-1/2'>

        {
          eventsList?.length > 0 && <div className="pb-4">
            <div className="font-bold pb-3">
              Events
            </div>
            <div className="events flex justify-between flex-wrap gap-5">
              {
                eventsList && eventsList.map((event, index) => {
                  return <EventCard event={event} key={`${event.name || index}${Math.random()}`} onClick={(name: string) => {
                    setShowPopup(true)
                    // toast.success(name)
                    setEventName(name)
                  }} />
                })
              }
            </div>
          </div>
        }

      </div>
    </div>

    <EventLogPopup
      chain={chain}
      contract={address || DEFAULT_ADDRESS}
      eventName={eventName}
      showPopup={showPopup}
      setShowPopup={setShowPopup}
      inputsByEventName={inputsByEventName}
    />
  </LayoutBodyCard>
}

export default NewContractView