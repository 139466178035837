import { Select } from "antd"
import { ModelListTenantItem, } from "../../../api/__gen__/data-contracts"

export enum AuthorFilterEnum {
  ALL = 'all',
}

const AuthorFilter = ({ authors, selectedAuthor, onChange }: {
  selectedAuthor: string,
  onChange: (e: string) => void,
  authors: ModelListTenantItem[]
}) => {
  return <div className="w-full flex justify-end items-center">
    <Select
      defaultValue={AuthorFilterEnum.ALL}
      value={selectedAuthor}
      onChange={(e) => {
        onChange(e)
      }}>
      <Select.Option value={AuthorFilterEnum.ALL}>
        All Tenants
      </Select.Option>
      {
        authors.map((author, index) => {
          return <Select.Option key={index} value={author.workspace}>
            {author.workspace || author.domain || author.alternative_domain || author.created_time}
          </Select.Option>
        })
      }
    </Select>
  </div>
}

export default AuthorFilter