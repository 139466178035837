import { AxiosError } from 'axios';
import { ChartApi } from '../../api/client';
import { Charts } from '../../api/__gen__/Charts';

export async function loadChart(
  { chartId }: { chartId: string },
  nClient?: Charts<unknown>
) {
  try {
    const client = nClient || ChartApi();
    const resp = await client.chartsDetail(chartId);

    return resp;
  } catch (error) {
    // eslint-disable-next-line
    console.error('error updating a chart', error);

    if (error instanceof AxiosError) {
      return {
        ...error.response,
        error: `${error?.response?.data?.message}`,
      };
    }
  }

  return null;
}
