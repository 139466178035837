import { uniqBy } from 'lodash';
import { ChartEntity } from '../../chart/types';
import { TParamState } from '../../queryBuilder/types';

export function getParamsForAllCharts(charts: ChartEntity[]) {
  const allParams = uniqBy(
    charts.reduce((prev, chart) => {
      const params = chart.queryParams || [];
      return [...prev, ...params];
    }, [] as TParamState),
    'name'
  );

  return allParams;
}
