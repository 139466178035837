import axios from "axios"
import { useCallback, useEffect, useState } from "react"
import { dbGroup1, dbGroup2 } from "./modules/queryBuilder/const"
import Modal from "./components/modals/Modal";



interface ColumnProps {
  name: string;
  type: string;
}
interface TableProps {
  name: string;
  columns: ColumnProps[];
  partitionColumnNames: string[];
}
interface TableCollapseProps {
  table: TableProps
}

export const TableCollapse = ({ table }: TableCollapseProps) => {
  return <div tabIndex={0} className="collapse border border-base-300 bg-base-100 rounded-box mb-3">
    <div className="collapse-title text-xl font-medium">
      {table?.name}
    </div>
    <div className="collapse-content">
      <div>
        <div className="font-semibold">Columns</div>
        <div className="mt-2">
          {
            table?.columns?.map((column: ColumnProps) => {
              return <div key={column.name} className="flex justify-between py-1 text-[#777] hover:text-[#000] hover:font-semibold">
                <div>{column.name}</div>
                <div>{column.type}</div>
              </div>
            })}
        </div>
        <div className="font-semibold mt-4">Partition Columns</div>
        <div className="mt-2">
          {
            table?.partitionColumnNames?.map((column: string) => {
              return <div key={column} className="flex justify-between py-1 text-[#777] hover:text-[#000] hover:font-semibold">
                <div>{column}</div>
              </div>
            })}
        </div>
      </div>
    </div>
  </div>
}

interface DataCatalogProps {
  name: string;
  onClick: React.MouseEventHandler<HTMLDivElement>
}

export const DataCatalog = ({
  name,
  onClick
}: DataCatalogProps) => {
  return <div className="p-2 cursor-pointer hover:bg-[#f0f2f4]" onClick={onClick}>
    {name}
  </div>
}



const fetchSchema = async (db: string, table: string) => {
  const res = await axios.get(`https://api.zettablock.com/pubgate/v1/databases/${db}/schemas/${table}/tables`)
  return res.data
}

const Docs = () => {

  const [databases, setdatabases] = useState([])
  const [showPopup, setShowPopup] = useState(false)
  const [tables, setTables] = useState([])

  const [realtimeDB, setRealtimeDB] = useState([])

  const [isRealtimeDB, setIsRealtimeDB] = useState(false)

  useEffect(() => {

    const fetchDB = async () => {
      const res = await axios.get('https://api.zettablock.com/pubgate/v1/databases/AwsDataCatalog/schemas')
      setdatabases(res.data.items || [])

      const realtimeRes = await axios.get('https://api.zettablock.com/pubgate/v1/databases/realtimeDB/schemas')
      setRealtimeDB(realtimeRes.data.items || [])
    }

    fetchDB()
  }, [])

  const handleSelect: React.MouseEventHandler<HTMLElement> = useCallback(async (e) => {
    setShowPopup(true)
    const res = await fetchSchema(isRealtimeDB ? 'realtimeDB' : 'AwsDataCatalog', (e.target as HTMLElement).innerText)
    setTables(res.items)
  }, [
    isRealtimeDB
  ])


  return <div>
    <div className="flex justify-end">
      <div className="form-control py-6 mt-6">
        <label className="label cursor-pointer">
          <span className="label-text mr-3 font-semibold  ">{
            isRealtimeDB ? 'Realtime DB' : 'Realtime DB '
          }</span>
          <input type="checkbox" className="toggle" onClick={() => {
            setIsRealtimeDB(!isRealtimeDB)
          }} checked={isRealtimeDB} />
        </label>
      </div>
    </div>
    <div className="bg-[#f9fafb] p-6">
      <h2 className="font-semibold text-2xl px-2">Available Chain Data</h2><div className="divider"></div>
      <div className="mt-8 bg-[#f9fafb]">
        {
          (isRealtimeDB ? realtimeDB : databases).filter((e: { name: string }) => {
            return dbGroup1.includes(e.name)
          }).map((db: { name: string }) => {
            return <DataCatalog key={db.name} name={db.name} onClick={handleSelect} />
          })
        }
      </div>
    </div>
    {
      (isRealtimeDB ? realtimeDB : databases).filter((e: { name: string }) => {
        return dbGroup2.includes(e.name)
      }).map((db: { name: string }) => {
        return <DataCatalog key={db.name} name={db.name} onClick={handleSelect} />
      }).length > 0 && <div className="bg-[#f9fafb] p-6 mt-6">
        <h2 className="font-semibold text-2xl  px-2">Available Abstraction Data
        </h2>
        <div className="divider"></div>
        <div className="mt-8 bg-[#f9fafb]">
          {
            (isRealtimeDB ? realtimeDB : databases).filter((e: { name: string }) => {
              return dbGroup2.includes(e.name)
            }).map((db: { name: string }) => {
              return <DataCatalog key={db.name} name={db.name} onClick={handleSelect} />
            })
          }
        </div>
      </div>}

    <div className="bg-[#f9fafb] p-6 mt-6 mb-6">
      <h2 className="font-semibold text-2xl px-2">Available Project Data
      </h2><div className="divider"></div>
      <div className="mt-8 bg-[#f9fafb]">
        {
          (isRealtimeDB ? realtimeDB : databases).filter((e: { name: string }) => {
            return !dbGroup2.includes(e.name) && !dbGroup1.includes(e.name)
          }).map((db: { name: string }) => {
            return <DataCatalog key={db.name} name={db.name} onClick={handleSelect} />
          })
        }
      </div>
    </div>

    <Modal open={showPopup} onClickBackdrop={() => { setShowPopup(false) }}>
      <div className="p-4">
        <h2 className="font-semibold text-2xl">Table Schema</h2>
        <div className="divider"></div>
        <div className="mt-4">
          {
            tables.map((table: TableProps) => {
              return <TableCollapse
                key={table.name}
                table={table}
              />
            })
          }
        </div>
      </div>
    </Modal>
  </div>
}

export default Docs