import { useState, useEffect, RefObject } from 'react';

const useDragX = <T extends HTMLElement>(
  ref: RefObject<T>,
  {
    onDraging,
    onDragEnd,
  }: {
    onDraging: (n: number) => void;
    onDragEnd: () => void;
  }
) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const handlePointerDown = (e: MouseEvent) => {
    setStartX(e.screenX);
    setIsDragging(true);
  };

  const handlePointerUp = () => {
    setIsDragging(false);
    onDragEnd();
  };

  // eslint-disable-next-line
  function handlePointerMove(e: MouseEvent) {
    if (isDragging) {
      onDraging(e.screenX - startX);
    }
  }

  useEffect(() => {
    if (ref?.current) {
      const element = ref.current;
      if (element) {
        element.addEventListener('mousedown', handlePointerDown);
        window.addEventListener('mouseup', handlePointerUp);
        window.addEventListener('mousemove', handlePointerMove);

        return () => {
          element.removeEventListener('mousedown', handlePointerDown);
          window.removeEventListener('mouseup', handlePointerUp);
          window.removeEventListener('mousemove', handlePointerMove);
        };
      }
    }

    return () => {};
  }, [isDragging, onDraging, onDragEnd]);

  return { isDragging };
};

export default useDragX;
