import { atom, selector } from 'recoil';
import { toast } from 'react-toastify';
import { DatabaseApi } from '../../api/client';
import { ModelDatabase } from '../../api/__gen__/data-contracts';

export const DatabaseCache = new Map<string, ModelDatabase[]>();

export const FetchDatabase = selector({
  key: 'FetchDatabase',
  get: async () => {
    try {
      const client = DatabaseApi();
      const resp = await client.databasesList();

      if (Array.isArray(resp.data.items)) {
        DatabaseCache.set('cache', resp.data.items);
        return resp.data.items;
      }
    } catch (error) {
      toast.error('Error loading databases.');
      return [];
    }

    return [];
  },
});

export const DatabasesQueryState = atom({
  key: 'DatabasesQueryState',
  default: FetchDatabase,
});

export const SelectedDBState = atom<string | undefined>({
  key: 'SelectedDBState',
  default: undefined,
});
