import '../../css/react-grid-layout.css';
import '../../css/react-resizable.css';

import Modal from '../../components/modals/Modal';
import { MdWidgetType } from './dashboard-models';
import { useState } from 'react';
import classNames from 'classnames';
import { MdInfoOutline } from 'react-icons/md';

export default function DashboardWidgetConfigModal({
  widget,
  onClose,
  onDelete,
  onApply,
}: {
  widget: MdWidgetType;
  onClose: () => void;
  onDelete?: (widget: MdWidgetType) => void;
  onApply?: (widget: MdWidgetType) => void;
}) {
  const [mdWidget, setMdWidget] = useState<MdWidgetType>(widget);
  const [openInfo, setOpenInfo] = useState(false);

  return (
    <Modal open={!!mdWidget.id}>
      <h3 className='font-bold text-lg'>Configure Widget</h3>
      <div className='h-4' />
      <div className='pb-2'>
        <textarea
          className='textarea textarea-bordered w-full min-h-[18rem]'
          value={mdWidget.mdContent}
          onChange={(e) => {
            setMdWidget({ ...mdWidget, mdContent: e.target.value });
          }}
        />
      </div>
      <div>
        <div className={classNames('collapse', { 'collapse-open': openInfo })}>
          <div
            className='flex items-center collapse-title font-medium cursor-pointer min-h-fit p-2'
            onClick={() => {
              setOpenInfo(!openInfo);
            }}
          >
            Markdown supported <MdInfoOutline className='ml-2 opacity-60' />
          </div>
          <div className='collapse-content'>
            <p>
              <table className='table table-compact w-full text-sm'>
                <tbody>
                  {[
                    {
                      label: 'Bold',
                      value: (
                        <span className='font-bold'>{'**bold text**'}</span>
                      ),
                    },
                    {
                      label: 'Italic',
                      value: <span className='italic'>{'_italic text_'}</span>,
                    },
                    {
                      label: 'Heading 1',
                      value: '# Heading1 text',
                    },
                    {
                      label: 'Heading 2',
                      value: '## Heading2 text',
                    },
                    {
                      label: 'Heading 3',
                      value: '### Heading3 text',
                    },
                    {
                      label: 'Link',
                      value: '[Link](https://app.zettablock.com)',
                    },
                    {
                      label: 'Image',
                      value: (
                        <div className='max-w-[300px] whitespace-pre-wrap break-all'>
                          {
                            '![image](https://i.giphy.com/media/qy5puFfG5DGAFbr0SD/giphy.webp)'
                          }
                        </div>
                      ),
                    },
                    {
                      label: 'Inline code',
                      value: '`function call();`',
                    },
                    {
                      label: 'Code block',
                      value: (
                        <pre>
                          ```
                          <br />
                          call();
                          <br />
                          ```
                        </pre>
                      ),
                    },
                    {
                      label: 'Horizontal rule',
                      value: '---',
                    },
                    {
                      label: 'Ordered list',
                      value: (
                        <ul>
                          <li>1. item 1</li>
                          <li>2. item 2</li>
                          <li>3. item 3</li>
                        </ul>
                      ),
                    },

                    {
                      label: 'List',
                      value: (
                        <ul>
                          <li>- item 1</li>
                          <li>- item 2</li>
                          <li>- item 3</li>
                        </ul>
                      ),
                    },
                  ].map((item) => (
                    <tr key={item.label}>
                      <th>{item.label}</th>
                      <td>{item.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </p>
          </div>
        </div>
      </div>
      <div className='modal-action items-center'>
        <div className='w-2' />
        <button type='button' className='btn btn-outline' onClick={onClose}>
          Cancel
        </button>
        {onDelete && (
          <button
            type='button'
            className='btn btn-warning'
            onClick={() => {
              onDelete(mdWidget);
            }}
          >
            Delete
          </button>
        )}
        {onApply && (
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => {
              onApply(mdWidget);
            }}
          >
            Apply
          </button>
        )}
      </div>
    </Modal>
  );
}
