import { HiMenuAlt4 } from 'react-icons/hi';
import { TEChartTypeTypeValues } from '../../chart/components/EChart';
import { SetBarChartOptions, YCol } from './BarChartComposeV2Hooks';
import produce from 'immer';
import { get, set, throttle } from 'lodash';
import { MdOutlineFormatColorReset } from 'react-icons/md';
import { useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { SERIES_OPTION_ROW } from '../const';
import classNames from 'classnames';
import { defaultChartTheme } from '../../chart/utils/const';
import { ChartOptionsV2 } from '../../chart/types-v2';

export default function DraggableSeriesOptionV2({
  idx,
  yCol,
  barChartOptions,
  setBarChartOptions,
  yColsPath,
}: {
  idx: number;
  yCol: YCol;
  yColsPath: string;
  setBarChartOptions: SetBarChartOptions;
  barChartOptions: ChartOptionsV2;
}) {
  const throttledColorChangeHandler = useMemo(
    () =>
      throttle((newColor) => {
        setBarChartOptions(
          produce(barChartOptions, (draft) => {
            set(draft, `${yColsPath}.[${idx}].color`, newColor);
          })
        );
      }, 500),
    [idx, barChartOptions, setBarChartOptions]
  );

  const [dropRef, dragRef] = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];

  const [, drop] = useDrop<{ index: number; name: string }, void>({
    accept: SERIES_OPTION_ROW,
    hover(item) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = idx;

      if (dragIndex === hoverIndex) {
        return;
      }

      // update series
      // moveSeriesOption(dragIndex, hoverIndex);
      setBarChartOptions(
        produce(barChartOptions, (draft) => {
          const v1 = get(draft, `${yColsPath}.[${dragIndex}]`);
          const v2 = get(draft, `${yColsPath}.[${hoverIndex}]`);

          if (v1 && v2) {
            set(draft, `${yColsPath}.[${dragIndex}]`, v2);
            set(draft, `${yColsPath}.[${hoverIndex}]`, v1);
          }
        })
      );
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: SERIES_OPTION_ROW,
    item: () => {
      return { index: idx, name: yCol.customLabel || yCol.colName };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(dropRef));
  drag(dragRef);

  const defaultColor = defaultChartTheme[idx] || '';

  return (
    <div
      key={`${idx}-${yCol.colName}-${yColsPath}`}
      ref={dropRef}
      className={classNames(
        'w-full px-2 inline-flex flex-row items-center space-x-2 border-t',
        isDragging ? 'opacity-0' : 'opacity-100'
      )}
    >
      <div className='w-8 h-full' ref={dragRef}>
        <button className='btn-ghost h-full w-full p-1 justify-center items-center cursor-grab'>
          <HiMenuAlt4 size='1.5rem' />
        </button>
      </div>
      <div className='flex-1'>
        <input
          type='text'
          className='input input-sm w-full focus:outline-none px-2 bg-base-300 rounded-none'
          placeholder={yCol.colName}
          value={yCol.customLabel}
          onChange={(e) => {
            setBarChartOptions(
              produce(barChartOptions, (draft) => {
                set(draft, `${yColsPath}.[${idx}].customLabel`, e.target.value);
              })
            );
          }}
        />
      </div>
      <div className='flex-1'>
        <select
          className='select select-sm w-full p-0 focus:outline-none'
          value={yCol.chartType}
          onChange={(e) => {
            setBarChartOptions(
              produce(barChartOptions, (draft) => {
                set(draft, `${yColsPath}.[${idx}].chartType`, e.target.value);
              })
            );
          }}
        >
          {TEChartTypeTypeValues.map((chartType: string) => (
            <option key={chartType} value={chartType}>
              {chartType}
            </option>
          ))}
        </select>
      </div>

      <div className='flex-1 flex flex-row w-full items-center space-x-2'>
        <input
          type='color'
          className='flex-1 w-full'
          value={yCol?.color || '#ffffff'}
          onChange={(e) => {
            throttledColorChangeHandler(e.target.value);
          }}
        />
        <MdOutlineFormatColorReset
          size='1.2rem'
          color={defaultColor}
          className={classNames(
            yCol?.color
              ? 'cursor-pointer opacity-100'
              : 'cursor-not-allowed opacity-20'
          )}
          onClick={() => {
            setBarChartOptions(
              produce(barChartOptions, (draft) => {
                set(draft, `${yColsPath}.[${idx}].color`, defaultColor);
              })
            );
          }}
        />
      </div>
    </div>
  );
}
