/* eslint-disable @typescript-eslint/no-explicit-any */
import { Input } from "antd"

export const typeConvert = (type: string, value: any) => {
  if (type === 'integer') {
    return parseInt(value, 10)
  }

  if (type === 'number') {
    return parseFloat(value)
  }

  if (type === 'boolean') {
    return value === 'true'
  }

  return value
}

const BodyParams = ({
  requestBody, setData, values
}: any) => {

  if (!requestBody) return null


  return <div className="">
    <div className="font-semibold mb-1 text-[14px]">
      Body Params
    </div>
    <div className="border bg-[#fafafa] p-3 rounded ">
      {
        requestBody && Object.keys(requestBody)?.map((key) => {
          return <div className="flex justify-between mb-3 gap-3" key={key}>
            <div>
              <div className="flex justify-between">
                <div className="flex gap-2 items-center text-[16px]">
                  <span className="font-semibold  max-w-[140px]  2xl:max-w-[400px]  text-ellipsis overflow-hidden">{key}</span>
                  <span className="text-[#B8BABD]">{requestBody[key].type}</span>
                  {/* <span className="text-[#dd1e2e]">required</span> */}
                </div>
              </div>
              <div>
                <div className="text-[14px] text-[#777A80] 2xl:max-w-[400px] max-w-[250px]">
                  dataset id to execute query
                </div>
              </div>
            </div>

            <div>
              <Input
                type={requestBody[key].type === 'boolean' ? 'checkbox' : 'text'}
                value={values?.[key] || ''}
                name={key} onChange={(e) => {
                  setData('body', {
                    [key]: typeConvert(requestBody[key].type, e.target.value)
                  })
                }} />
            </div>
          </div>
        })
      }
    </div>
  </div>
}

export default BodyParams