/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelGetTableDetailRequest,
  ModelGetTableDetailResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class TableDetail<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description get table detail
   *
   * @tags table
   * @name TableDetailCreate
   * @summary get table detail
   * @request POST:/tableDetail
   */
  tableDetailCreate = (
    request: ModelGetTableDetailRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelGetTableDetailResponse, any>({
      path: `/tableDetail`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
