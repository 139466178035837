import produce from 'immer';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { atomFamily, useRecoilState } from 'recoil';
import {
  ModelCreateUserApiRequest,
  ModelGetQueryDetailResponse,
} from '../../api/__gen__/data-contracts';
import { useRpcStatusV3 } from '../../hooks/network';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import {
  AQueryDetails,
  loadQuery,
  // runQueryById,
  SQueryResults,
} from '../queryBuilder/QueryState';
import { zToast } from '../toast/toast';
import { ApiQueryTextState } from './ApiQueryPadHooks';
import { postCreateApi } from './api-rpc';
import { useApiId } from './ViewApiHooks';
import { AllColTypes, queryResultFormat } from '../queryBuilder/api-utils';
import { StreamApi } from '../../api/client';
import { useQueryIdV3 } from '../queryBuilder/QueryPadStateV3';

export const ApiCreateValues = atomFamily<
  ModelCreateUserApiRequest & { isRealTimeApi?: boolean },
  string | undefined | null
>({
  key: 'ApiCreateValues',
  default: {
    displayName: 'New API',
    resultDatabase: 'zettadb1',
    dataIngestIntervalSeconds: 0,
    groupByField: '',
    orderByValue: false,
    orderByDesc: true,
    resultPrimaryKey: [],
    resultCaseInsensitive: true,
    isRealTimeApi: false,
  },
});

export function useQueryIdSearchParams() {
  const [searchParams] = useSearchParams();
  const queryId = searchParams.get('queryId');

  return queryId;
}

export function useNewApiStep2(v2?: boolean) {
  const [, { getPath, navigate }] = useWorkspace();
  const v1QueryId = useQueryIdSearchParams();
  const v2QueryId = useQueryIdV3();
  const queryId = v2 ? v2QueryId : v1QueryId;
  const apiId = useApiId();

  const [queryDetail, setQueryDetail] = useRecoilState(AQueryDetails(queryId));
  const [values, setValues] = useRecoilState(ApiCreateValues(apiId));
  const [, setQuery] = useRecoilState(ApiQueryTextState(apiId));
  const [queryResults, setQueryResults] = useRecoilState(
    SQueryResults(queryId)
  );

  const [rpcStatus, setRpcStatus] = useRpcStatusV3('new-api-step2');
  const [rpcStatusResults, setRpcStatusResults] = useRpcStatusV3(
    'new-api-step2-query'
  );
  const [rpcStatusCreating, setRpcStatusCreating] = useRpcStatusV3();

  const fetchAndSetQuery = async (queryID: string) => {
    setRpcStatus({ isLoading: true });

    // fetch query
    const resp = await loadQuery({
      queryId: queryID,
    });

    // @ts-ignore
    const error = resp?.error;

    setRpcStatus({ error, isLoading: false });

    if (error) {
      zToast.error(error);
    } else {
      const data = resp?.data as ModelGetQueryDetailResponse;
      setQueryDetail(data);
    }
  };
  // load query detail
  useEffect(() => {
    async function run() {
      if (queryId && isEmpty(queryDetail)) {
        setRpcStatus({ isLoading: true });

        // fetch query
        const resp = await loadQuery({
          queryId,
        });

        // @ts-ignore
        const error = resp?.error;

        setRpcStatus({ error, isLoading: false });

        if (error) {
          zToast.error(error);
        } else {
          const data = resp?.data as ModelGetQueryDetailResponse;
          setQueryDetail(data);
        }
      }
    }

    run();
  }, [queryId]);

  // populate query text in query pad
  useEffect(() => {
    setQuery(queryDetail.text || '');
  }, [queryDetail]);

  const columns = queryResults?.results?.metadata?.columns || [];
  const isEmptyResult = isEmpty(columns);

  // load query results
  useEffect(() => {
    async function run() {
      // populate form values
      if (queryDetail && !isEmptyResult) {
        const nValue = produce(values, (draft) => {
          draft.query = queryDetail.text;
          draft.database = queryDetail.database;

          draft.resultColumnTypes = columns.reduce((p, i) => {
            const { type } = i;

            const nType = AllColTypes.includes(type) ? type : 'VARCHAR';

            return { ...p, [i.name]: nType };
          }, {});
        });

        setTimeout(() => {
          setValues(nValue);
        }, 0);
      }

      if (
        !rpcStatusResults.isLoading &&
        queryDetail?.id &&
        isEmpty(queryResults)
      ) {
        setRpcStatusResults({ isLoading: true });

        // busines logic here
        // we will take the last queryRun result ==> we don't need to execute a new run
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const client: any = StreamApi();

        const QueryParamsForGetResults = {
          includeMetadata: true,
          includeColumnName: true,
        };

        const resultResp = await client.queryrunsResultDetail(
          queryDetail.LastQueryrunId,
          QueryParamsForGetResults
        );
        const results = await queryResultFormat(queryDetail.id, resultResp);

        // Note(jjin): we might need this later, this part is executing a new query run
        // const results = await runQueryById({
        //   queryId: queryDetail.id,
        //   paramsStr: queryDetail.paramsStr || '{}',
        //   resultCacheExpireMillis: queryDetail.resultCacheExpireMillis,
        // });

        // @ts-ignore
        const error = results?.error;

        setRpcStatusResults({ error, isLoading: false });

        if (error) {
          zToast.error(error);
        } else {
          setQueryResults(results);
        }
      }
    }

    run();
  }, [queryDetail, columns]);

  async function createNewApi(isV2?: boolean) {
    // 2 kinds of refresh
    const { resultWriteMode, init } = values;

    const params = { ...values };

    if (!params) {
      return;
    }

    if (resultWriteMode === 'Append') {
      // 1) Append
      if (!init) {
        // if the incremental data refresh SQL query is empty
        toast.warn('Please add the incremental data refresh SQL code');
        return;
      }

      const query = init[0];

      params.init = [values.query!];
      params.query = query;
    } else {
      // 2) Overwrite
    }

    if (values.isRealTimeApi) {
      params.dataIngestIntervalSeconds = 0;
      // if (params.database === 'realtimeEvmDB') {
      //   params.database = 'realtimeEvmDB';
      //   params.resultDatabase = 'realtimeEvmDB';
      // } else {
      //   params.database = 'realtimeDB';
      //   params.resultDatabase = 'realtimeDB';
      // }
      params.resultDatabase = params.database;
    } else {
      // console.log('not real time api');
    }

    if (rpcStatusCreating.isLoading) return;

    setRpcStatusCreating({ isLoading: true });

    const resp = await postCreateApi(params);
    const error = resp?.data?.message;

    setRpcStatusCreating({ isLoading: false, error });

    if (error) {
      toast.dismiss();
      toast.error(error);
    } else {
      // success
      const id = resp?.data?.id;
      if (id) {
        if (isV2) {
          navigate(`/v2/workspace/graphql-apis/${id}/overview`);
          return;
        }
        navigate(`${getPath('apis')}/${id}`);
      } else {
        toast.error('something went wrong. please report to engineering team.');
      }
    }
  }

  return [
    {
      values,
      queryDetail,
      rpcStatusQueryLoading: rpcStatus,
      rpcStatusCreating,
      fetchAndSetQuery,
      isEmptyResult,
    },
    { setValues, createNewApi },
  ] as const;
}
