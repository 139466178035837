export default () => {
  return (
    <div className='h-96 flex flex-col justify-center items-center flex-wrap'>
      <p>Backend ready, and frontend coming soon.</p>
      <p>
        If needed now, Please{' '}
        <a
          rel='noreferrer'
          target='_blank'
          className='text-blue-500'
          href='https://discord.com/invite/px42xPBFkN'
        >
          contact ZettaBlock team
        </a>{' '}
        to set up the data ingestion pipeline via backend credentials.
      </p>
    </div>
  );
};
