import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { first, get, sortBy, isEqual, values } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import { SelectedDBState } from '../database/DatabaseState';
import {
  AFetchingPreview,
  APreviewTableTitle,
  AQueryDetails,
  AQueryRunErrorMessage,
  AQueryRunMetrics,
  AQueryRunStartAt,
  AStaticTabType,
  CacheTTLSate,
  createQuery,
  ForkedQueryFromState,
  getPreviewData,
  loadQuery,
  QueryDisplayNameState,
  QueryState,
  runQueryById,
  runQueryByText,
  SQueryResults,
  TQueryResult,
  updateQueryText,
} from './QueryState';
import { ChartEntity, isTableChartV2 } from '../chart/types';
import { AChartsList } from './ChartsState';
import { ModelGetQueryDetailResponse } from '../../api/__gen__/data-contracts';
import { convertDBChartToUIChart } from '../chart/utils';
import {
  AQueryParamDefaultValuesCache,
  AQueryParams,
  convertDBParamsStrToParams,
  convertParamsArrToMap,
  convertParamsToDBParamsStr,
} from './QueryPadState';
import { TParamState, TPreviewParams, TQueryRunParams } from './types';
import { TABLE_CHART, useAddChart } from './NewVizHooks';
import { zToast } from '../toast/toast';
import { useSearchParams } from 'react-router-dom';
import usePrompt from '../../hooks/router';
import { QueryRunsApi } from '../../api/client';
import { PreviewDataState } from '../../v2/build/usePreviewData';

export const TemporaryQueryResultId = 'temp';
export const PreviewQueryResultId = 'preview';

export function useChartIdSearchParam() {
  const [searchParams] = useSearchParams();
  const chartId = searchParams.get('chartId');
  return chartId;
}

export function useQueryRun(
  { queryPath }: { queryPath: string } = { queryPath: '/query-builder' }
) {
  const params = useParams();
  const navigate = useNavigate();
  const queryId = params?.id;
  const chartIdSearchParam = useChartIdSearchParam();

  // selector states
  const [selectedDB, setDB] = useRecoilState(SelectedDBState);

  // query states
  const setQueryStartAt = useSetRecoilState(AQueryRunStartAt);
  const [query, setQuery] = useRecoilState(QueryState);
  const [displayName, setDisplayName] = useRecoilState(QueryDisplayNameState);
  const [ttl, setTtl] = useRecoilState(CacheTTLSate);
  const queryResults = useRecoilValue(SQueryResults(queryId));
  const [isQueryDataLoading, setIsQueryDataLoading] = useState(!!params.id);

  const [forkedQuery, setForkedQuery] = useRecoilState(
    ForkedQueryFromState(queryId)
  );

  const [isQueryRunning, setIsQueryRunning] = useState(false);
  // charts
  const setCharts = useSetRecoilState(AChartsList);

  /**
   * TODO: WIP: consolidating all query details in a recoil state
   * charts, query text, query params, creator etc
   */
  const [queryDetail, setQueryDetail] = useRecoilState(AQueryDetails(queryId));

  // tabs
  const [currentTab, setCurrentTab] = useRecoilState(AStaticTabType);
  // query params
  const [queryParams, setQueryParams] = useRecoilState(AQueryParams);
  // query param cached values
  const setQueryParamDefaultValues = useSetRecoilState(
    AQueryParamDefaultValuesCache
  );
  // to figure out the query inputs are editted
  const [queryRunParamsCache, setQueryRunParamsCache] =
    useState<TQueryRunParams>();

  const [addChart] = useAddChart();

  const queryRunParams = {
    displayName,
    selectedDB,
    queryId,
    query,
    queryParams,
  };

  const needQueryUpdate = !isEqual(queryRunParams, queryRunParamsCache);

  // TODO better caching runtime (map by query ID)
  // reset query run time when query Id changes
  useEffect(() => {
    if (!queryId) {
      setQueryStartAt(null);
    }
  }, [queryId]);
  // reset when first render
  useEffect(() => {
    setQueryStartAt(null);
  }, []);

  usePrompt('A query is running, do you want to continue?', isQueryRunning);

  const handleRun = useRecoilCallback(
    ({ set }) =>
      async ({
        displayName: displayNameLocal,
        queryId: queryIdLocal,
        query: queryLocal,
        selectedDB: selectedDBLocal,
        queryParams: queryParamsLocal,
      }: TQueryRunParams) => {
        // if (!displayNameLocal) {
        //   toast.info('please name the query');
        // }
        if (!queryLocal) {
          toast.info('please write a query');
        }
        if (
          isQueryRunning ||
          // !displayNameLocal ||
          !queryLocal ||
          !selectedDBLocal
        ) {
          return;
        }

        toast.dismiss();
        setIsQueryRunning(true);
        setQueryStartAt(null);

        // skip saving when loading query first time
        if (queryIdLocal && queryRunParamsCache && queryParamsLocal) {
          const resp = await updateQueryText({
            queryId: queryIdLocal,
            query: queryLocal,
            paramsStr: convertParamsToDBParamsStr(queryParamsLocal),
          });

          if (resp?.data) {
            // success
            if (needQueryUpdate) {
              toast.success('Query saved');
            }
          } else {
            zToast.error('Query update failed');
            setIsQueryRunning(false);
            return;
          }
        }

        setQueryRunParamsCache({
          displayName: displayNameLocal,
          selectedDB: selectedDBLocal,
          query: queryLocal,
          queryId: queryIdLocal,
          queryParams: queryParamsLocal,
        });

        setQueryStartAt(new Date());

        let result: TQueryResult | null = null;
        // run query
        if (queryIdLocal && queryLocal && queryParamsLocal) {
          result = await runQueryById({
            queryId: queryIdLocal,
            paramsStr: convertParamsToDBParamsStr(queryParamsLocal),
            resultCacheExpireMillis: ttl,
          });
        }

        // create query
        if (
          !queryIdLocal &&
          queryLocal &&
          selectedDBLocal &&
          queryParamsLocal
        ) {
          result = await createQuery({
            query: queryLocal,
            paramsStr: convertParamsToDBParamsStr(queryParamsLocal),
            database: selectedDBLocal,
            resultCacheExpireMillis: ttl,
          });

          const newQueryId = result?.queryId;
          if (newQueryId) {
            // ignore eror state
            await addChart(TABLE_CHART, { newQueryId });
          }
        }

        setIsQueryRunning(false);

        if (result !== undefined) {
          const newQueryId = result?.queryId;

          // error handle
          if (result?.error) {
            setQueryStartAt(null);
            zToast.error(result?.error);
          }

          // remove temp results
          set(SQueryResults(TemporaryQueryResultId), null);

          if (newQueryId) {
            // new query
            set(SQueryResults(newQueryId), result);
            if (newQueryId !== queryIdLocal) {
              setTimeout(() => {
                navigate(`${queryPath}/${newQueryId}`);
              }, 200);
            }
          } else {
            // update query
            set(SQueryResults(queryIdLocal), result);
          }
        }
      },
    [isQueryRunning, needQueryUpdate, queryParams, ttl]
  );

  // load query on initial page load
  useEffect(() => {
    async function init() {
      if (queryId) {
        setIsQueryDataLoading(true);

        const resp = await loadQuery({
          queryId,
        });

        // @ts-ignore
        const error = resp?.error;

        if (error) {
          zToast.error(error);
        }

        const data = resp?.data as ModelGetQueryDetailResponse;
        const {
          text,
          charts: chartsFromDB,
          database,
          paramsStr,
          displayName: nDisplayName,
          resultCacheExpireMillis,
          forkQueryId,
        } = data;

        setQueryDetail(data);
        setQuery(text);
        setDisplayName(nDisplayName);
        setTtl(resultCacheExpireMillis ?? 3 * 60 * 1000);
        setIsQueryDataLoading(false);

        if (forkQueryId) {
          // get forked query
          const respForked = await loadQuery({
            queryId: forkQueryId,
          });

          if (respForked?.data) {
            setForkedQuery(respForked?.data);
          }
        }

        if (selectedDB !== database) {
          setDB(database);
        }

        // load query params
        let nQueryParams = [] as TParamState;
        if (paramsStr) {
          nQueryParams = convertDBParamsStrToParams(paramsStr);
        }

        setQueryParamDefaultValues(convertParamsArrToMap(nQueryParams));
        setQueryParams(nQueryParams);

        const chartsUIModel = (chartsFromDB || []).reduce<
          Record<string, ChartEntity>
        >((p, dbChart) => {
          if (dbChart.id) {
            p[dbChart.id] = convertDBChartToUIChart(dbChart);
          }

          return p;
        }, {});

        setCharts(chartsUIModel);

        if (chartIdSearchParam) {
          const currentActiveChart = chartsFromDB?.find(
            (c) => c.id === chartIdSearchParam
          );

          if (currentActiveChart?.id) {
            const parsedChart = get(chartsUIModel, [currentActiveChart.id]);
            setCurrentTab({
              label: `${parsedChart.type} chart`,
              key: parsedChart.id,
            });
          }
        } else {
          // setting correct active tab after loading a query
          const currentActiveChart = chartsFromDB?.find(
            (c) => c.id === currentTab?.key
          );

          // selected chart not available on this query any longer
          // OR new viz tab is enabled (before the query created)
          if (
            !currentActiveChart ||
            currentTab.label === '+ new visualization'
          ) {
            const firstChart = first(sortBy(chartsFromDB, 'createdTime'));
            if (firstChart?.id) {
              const parsedFirstChart = get(chartsUIModel, [firstChart.id]);

              setCurrentTab({
                label: `${parsedFirstChart.type} chart`,
                key: parsedFirstChart.id,
              });
            }
          }
        }

        if (database && text && paramsStr) {
          const nQueryRunParams = {
            selectedDB: database,
            queryId,
            query: text,
            queryParams: nQueryParams,
          };

          if (!queryResults) {
            setQueryStartAt(null);
          }

          setQueryRunParamsCache(nQueryRunParams);
        }
      } else {
        setCharts({});

        setQueryStartAt(null);
      }
    }

    init();
  }, [queryId]);

  return [
    handleRun,
    {
      forkedQuery,
      queryDetail,
      isQueryDataLoading,
      isQueryRunning,
      queryRunParamsCache,
    },
  ] as const;
}

export function useRunHighlightedQuery() {
  const params = useParams();
  const queryId = params?.id;
  // query states
  const setQueryStartAt = useSetRecoilState(AQueryRunStartAt);
  const setQueryRunErrorMessage = useSetRecoilState(AQueryRunErrorMessage);

  const [isQueryRunning, setIsQueryRunning] = useState(false);
  const [queryParams] = useRecoilState(AQueryParams);

  const chartsMap = useRecoilValue(AChartsList);
  const charts = values(chartsMap).filter((chart) => chart.queryId === queryId);

  const setQueryRunMetrics = useSetRecoilState(AQueryRunMetrics);

  const runHighlightedQuery = useRecoilCallback(
    ({ set }) =>
      async ({
        queryId: queryIdLocalT,
        query: queryLocal,
        selectedDB: selectedDBLocal,
        queryParams: queryParamsLocal,
      }: TQueryRunParams) => {
        const queryIdLocal = queryIdLocalT || TemporaryQueryResultId;

        if (!queryLocal) {
          toast.info('please write a query');
        }

        if (isQueryRunning || !queryLocal || !selectedDBLocal) {
          return;
        }

        toast.dismiss();
        setIsQueryRunning(true);
        setQueryStartAt(null);
        setQueryStartAt(new Date());

        let result: TQueryResult | null = null;

        // run query
        if (queryLocal && queryParamsLocal) {
          result = await runQueryByText({
            queryId: queryIdLocal,
            query: queryLocal,
            database: selectedDBLocal,
            paramsStr: convertParamsToDBParamsStr(queryParamsLocal),
          });
        }

        if (result !== undefined) {
          // error handle
          if (result?.error) {
            setQueryStartAt(null);
            setQueryRunErrorMessage(result?.error);
            setQueryRunMetrics({
              cu: 0,
              read: 0,
              executionMillis: 0,
              write: 0,
            });
            // zToast.error(result?.error); // temp remoal - error toast
          } else {
            if (result && result?.results?.metadata.queryrunId) {
              const client = QueryRunsApi();
              const res = await client.detailDetail(
                result?.results?.metadata.queryrunId
              );

              setQueryRunMetrics({
                read: res.data.metrics?.readBytes ?? 0,
                cu: res.data.metrics?.computeUnits ?? 0,
                write: res.data.metrics?.writeBytes ?? 0,
                executionMillis: res.data.metrics?.executionMillis ?? 0,
              });
            }

            // add temp result
            set(SQueryResults(queryIdLocal), result);

            if (queryIdLocal === TemporaryQueryResultId) {
              // add temp tab
              set(AStaticTabType, { label: 'selected query' });
            } else {
              const firstChart = first(charts);
              const isFirstChartTableChart = isTableChartV2(firstChart);
              if (isFirstChartTableChart && firstChart?.id) {
                set(AStaticTabType, { label: 'results', key: firstChart?.id });
              }
            }
          }
        }

        setIsQueryRunning(false);
      },
    [isQueryRunning, queryParams]
  );

  return [runHighlightedQuery, { isQueryRunning }] as const;
}

export function useRunPreviewData(isV2?: boolean) {
  const runPreviewData = useRecoilCallback(
    ({ set }) =>
      async ({
        queryId: queryIdLocalT,
        selectedDB: selectedDBLocal,
        schema,
        table,
      }: TPreviewParams) => {
        const queryIdLocal = queryIdLocalT || PreviewQueryResultId;

        if ((!selectedDBLocal && !isV2) || !schema || !table) {
          return;
        }

        toast.dismiss();

        set(AFetchingPreview, true);

        set(AStaticTabType, { label: 'preview' });

        let result: TQueryResult | null = null;

        // remove preview results
        set(SQueryResults(PreviewQueryResultId), null);

        // get preview
        if (selectedDBLocal && schema && table) {
          result = await getPreviewData({
            source: isV2 ? 'auto' : selectedDBLocal,
            database: schema,
            table,
          });
        }

        if (result !== undefined) {
          // error handle
          if (result?.error) {
            zToast.error(result?.error);
          } else {
            // add preview result
            set(SQueryResults(queryIdLocal), result);

            const uniqueId = `${schema}.${table}`;
            set(PreviewDataState, (prevState) => {
              return {
                ...prevState,
                [uniqueId]: result?.results,
              };
            });

            if (queryIdLocal === PreviewQueryResultId) {
              // add previews tab
              set(AStaticTabType, { label: 'preview' });
              set(APreviewTableTitle, `${schema}.${table}`);
            }
          }
        }
        set(AFetchingPreview, false);
      },
    []
  );

  return [runPreviewData] as const;
}
