/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactECharts from 'echarts-for-react';
import { defaultChartTheme } from '../chart/utils/const';
import { DefaultEChartOptions } from '../chart/utils/echart';
import { memo } from 'react';
import Logo from '../../components/app/Logo';
import envs from '../../config/envs';

// Note(joseph): the reason why we put the business logic inside of this component is
// -> it is not used anywhere else
const filterOutNoneUsedUsages = (data: any[], propertyName: string) => {
  if (!data[0]) return [];

  const noneZeroUsages = data.filter((e: any) => {
    const totalCount = e.usageSegments.reduce((acc: any, cur: any) => {
      return acc + cur[propertyName];
    }, 0);

    return totalCount !== 10;
  });

  // return 10 usages if all of them are 0 usage
  if (noneZeroUsages.length === 0) {
    return data.slice(0, 10)
  }

  noneZeroUsages.sort((a: any, b: any) => {
    const totalCountA = a.usageSegments.reduce((acc: any, cur: any) => {
      return acc + cur[propertyName];
    }, 0);

    const totalCountB = b.usageSegments.reduce((acc: any, cur: any) => {
      return acc + cur[propertyName];
    }, 0);

    return totalCountB - totalCountA
  })

  // return the top 10 usages
  return noneZeroUsages.slice(0, 10)
};

const generateBarChartOption = (data: any, propertyName: string) => {
  const res = {
    ...DefaultEChartOptions,
    version: 2,
    chartOptions: {
      showLegend: false,
    },
    xAxis: {
      type: 'category',
      data: data[0]?.usageSegments.map((e: any) =>
        new Date(e.startSeconds * 1000).toUTCString().replaceAll('00:00:00 ', '')
      ),
    },
    yAxis: {
      type: 'value',
    },
    legend: {
      orient: 'horizontal',
      top: 0,
      right: 'center',
      show: false,
    },
    grid: {
      right: '0%',
    },
    series: filterOutNoneUsedUsages(data, propertyName).map(
      (e: any, colIdx: number) => {
        return {
          name: e.api.displayName,
          type: 'bar',
          stack: 'usage',
          color: defaultChartTheme[colIdx % defaultChartTheme.length],
          data: e.usageSegments.map((item: any) => item[propertyName]),
        };
      }
    ),
  };

  return res;
};

export const ApiUsageBarChart = memo(
  ({ data, propertyName }: { data: any; propertyName: string }) => {
    if (!data) return null;

    return (
      data && (
        <div className='w-full relative'>
          <ReactECharts
            option={generateBarChartOption(data, propertyName)}
          ></ReactECharts>
          <div className='h-full w-full absolute flex justify-center opacity-30 top-0 items-center pointer-events-none'>
            <div>
              {
                envs.isCircleIntegrationEnabled ? null
                  : <Logo />
              }
            </div>
          </div>
        </div>
      )
    );
  }
);
