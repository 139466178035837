/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ModelListImagesResponse } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Images<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description list images
   *
   * @tags image
   * @name ImagesList
   * @summary list images
   * @request GET:/images
   */
  imagesList = (params: RequestParams = {}) =>
    this.request<ModelListImagesResponse, any>({
      path: `/images`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
