/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';
import LayoutBodyCard from '../../../components/cards/LayoutBodyCard';
import { HorizontalLine } from '../../../components/HorizontalLine';
import { random } from 'lodash';
import { useRecoilStateLoadable, useSetRecoilState } from 'recoil';
import { SelectedTableSchemaState } from '../../database/TableSchemaState';
import { BiChevronDown, BiChevronRight } from 'react-icons/bi';
import { DatabasesQueryState } from '../../database/DatabaseState';
import {
  ModelDatabase,
  ModelTable,
  ModelSchema,
} from '../../../api/__gen__/data-contracts';
import { VerticalLine } from '../../../components/VerticalLine';
import { DatabaseApi } from '../../../api/client';
import './block-arrow.css';
import { UploadPayload, UploadStatus } from './types';
import usePrompt from '../../../hooks/router';
import { defaultErrorMessage } from '../../error/const';
import { zToast } from '../../toast/toast';
import useAuth from '../../../hooks/auth';

interface IProcessingUploadProps {
  payload: UploadPayload;
  onNext: () => void;
  setUploadStatus: Dispatch<SetStateAction<UploadStatus>>;
}

export default function ProcessingUpload({
  payload,
  onNext,
  setUploadStatus,
}: IProcessingUploadProps) {
  const [requestDuration] = useState(random(10, 15));
  const [time, setTime] = useState(0);
  const { s3, database, schema, table } = payload;
  const timerRef = useRef<NodeJS.Timer>();
  const percentage = time / requestDuration;
  const setTableSchema = useSetRecoilState(SelectedTableSchemaState);
  const [{ contents: databaseContents }] =
    useRecoilStateLoadable(DatabasesQueryState);
  const databaseList: ModelDatabase[] = databaseContents || [];
const [schemas, setSchema] = useState<ModelSchema[]>([]);
  const [tables, setTables] = useState<ModelTable[]>([]);

  const DATABASE_LIST = [
    {
      id: 'myTables',
      displayName: 'My Tables',
    },
    {
      id: 'Prices',
      displayName: 'Prices',
    },
    {
      id: 'Arbitrum',
      displayName: 'Arbitrum',
    },
    {
      id: 'Avalanche C Chain',
      displayName: 'Avalanche C Chain',
    },
    {
      id: 'Avalanche P Chain',
      displayName: 'Avalanche P Chain',
    },
    {
      id: 'more',
      displayName: '... ...',
    },
  ]
  
  const [{auth}] = useAuth()

  useEffect(() => {
    setTime(0);
  }, []);

  useEffect(() => {
    const uploadTimer = setInterval(() => {
      setTime((prev) => prev + 1);
    }, 1000);
    timerRef.current = uploadTimer;
    return () => clearInterval(uploadTimer);
  }, []);

  useEffect(() => {
    if (time >= requestDuration) {
      clearInterval(timerRef.current);
    }
  }, [time]);

  useEffect(() => {
    const fetchData = async () => {
      if (database && schema) {
        const client = DatabaseApi();
        const schemaResp = await client.schemasDetail(database);
        if (Array.isArray(schemaResp.data.items)) {
          setSchema(schemaResp.data.items);
        }
        const tableResp = await client.schemasTablesDetail(database, schema);
        const { items } = tableResp.data;
        if (Array.isArray(items)) {
          setTables(items);
        }
      }
    };
    const upload = async () => {
      const bodyFormData = new FormData();
      if (
        table &&
        s3 &&
        auth &&
        auth?.id
      ) {
        bodyFormData.append('table', table);
        bodyFormData.append('s3_path', s3);
        bodyFormData.append('username', auth.name!);

        try {
          await axios.post(
            'https://csv-api.zettablock.com/register',
            bodyFormData,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            }
          );
        } catch (error) {
          if (error instanceof AxiosError) {
            const msg = error?.response?.data?.error;
            zToast.error(msg || defaultErrorMessage);
            setTime(0);
            setUploadStatus('submit');
          }
        }
      }
    };
    fetchData();
    upload();
  }, [payload]);

  usePrompt(
    'The process will continue when you leave the page, confirm?',
    time < requestDuration
  );

  return (
    <LayoutBodyCard className='lg:basis-2/5 basis-full'>
      {/* header  */}
      <div className='inline-flex p-6 items-center w-full'>
        <div className='flex-1' />
        {time >= requestDuration && (
          <button
            className='btn btn-primary btn-wide'
            onClick={() => {
              setTableSchema(table);
              onNext();
            }}
          >
            Create a New Query
          </button>
        )}
      </div>
      {time >= requestDuration && <HorizontalLine />}
      {/* body */}
      <div className='h-10' />
      <div className='flex flex-col px-8 w-full h-full items-center justify-center'>
        {time >= requestDuration ? (
          <>
            <h3 className='text-2xl font-bold'>Your data is here</h3>
            <h3 className='text-lg'>
              <span className='opacity-50'>Successfully uploaded</span>{' '}
              <span className='font-bold'>{table}</span>
              <span className='opacity-50'>.</span>
            </h3>
            <h3 className='text-lg'>
              <span className='opacity-50'>You can find the data in</span>{' '}
              <span className='font-bold'>My Tables</span>{' '}
              <span className='opacity-50'>after 3-5 minutes.</span>
            </h3>
            <div className='h-5' />
            <div className='card w-1/2 h-full bg-base-300 p-6'>
              {DATABASE_LIST.map(({ id, displayName }) =>
                id === 'myTables' ? (
                  <div className='flex flex-col' key={id}>
                    <div className='inline-flex space-x-2 items-center'>
                      <BiChevronDown size='1.5rem' className='opacity-50' />
                      <span className='text-lg'>{displayName}</span>
                    </div>
                    <div className='inline-flex w-full h-full'>
                      <VerticalLine className='h-auto mx-[0.75rem] border-gray-300' />
                      <div className='flex flex-col w-full h-full'>
                        {schemas.slice(0, 5).map(
                          ({ name }, index) =>
                            index < 4 &&
                            name !== schema && (
                              <div
                                className='inline-flex space-x-2 items-center h-full'
                                key={`${name} ${index}`}
                              >
                                <BiChevronRight
                                  size='1.5rem'
                                  className='opacity-50'
                                />
                                <span className='text-lg'>{name}</span>
                              </div>
                            )
                        )}
                        {schemas.length > 3 && (
                          <div className='inline-flex space-x-2 items-center h-full py-2'>
                            <VerticalLine className='h-[1.5rem] mx-[0.75rem] border-gray-300' />
                            <span className='text-lg'>
                              ...... more databases
                            </span>
                          </div>
                        )}
                        <div className='flex flex-col'>
                          {/* <div className='inline-flex space-x-2 items-center h-full'>
                            <BiChevronDown
                              size='1.5rem'
                              className='opacity-50'
                            />
                            <span className='text-lg'>{schema}</span>
                          </div> */}
                          <div className='inline-flex w-full h-full'>
                            <VerticalLine className='h-auto mx-[0.75rem] border-gray-300' />
                            <div className='flex flex-col w-full h-full'>
                              {tables.slice(0, 5).map(
                                ({ name }, index) =>
                                  index < 4 &&
                                  name !== table && (
                                    <div
                                      className='inline-flex space-x-2 items-center h-full'
                                      key={`${name} ${index}`}
                                    >
                                      <BiChevronRight
                                        size='1.5rem'
                                        className='opacity-50'
                                      />
                                      <span className='text-lg'>{name}</span>
                                    </div>
                                  )
                              )}
                              {tables.length > 4 && (
                                <div className='inline-flex space-x-2 items-center h-full py-2'>
                                  <VerticalLine className='h-[1.5rem] mx-[0.75rem] border-gray-300' />
                                  <span className='text-lg'>
                                    ...... more tables
                                  </span>
                                </div>
                              )}
                              <div className='inline-flex space-x-2 items-center h-full'>
                                <BiChevronRight
                                  size='1.5rem'
                                  className='opacity-50'
                                />
                                <span className='text-lg'>{table}</span>
                                <div className='inline-flex items-center'>
                                  <div className='w-4' />
                                  <div className='blockArrow flex items-center h-full justify-center px-2 text-primary bg-primary'>
                                    <span className='text-white'>
                                      Your data is here
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='inline-flex space-x-2 items-center' key={id}>
                    <BiChevronRight size='1.5rem' className='opacity-50' />
                    <span className='text-lg'>{displayName}</span>
                  </div>
                )
              )}
            </div>
            <button
              className='btn btn-primary btn-wide btn-md my-10'
              onClick={() => {
                setTableSchema(table);
                onNext();
              }}
            >
              Create a New Query
            </button>
            <div className='h-32' />
          </>
        ) : (
          <>
            <h3 className='text-3xl'>Uploading CSV to Database</h3>
            <span className='text-base font-bold m-0 opacity-40'>
              Est. {requestDuration} seconds
            </span>
            <div className='h-10' />
            <progress
              className='progress max-w-2xl h-3 progress-primary'
              value={time}
              max={requestDuration}
            />
            <div className='h-2' />
            <div className='inline-flex max-w-2xl w-full'>
              <h3 className='text-lg font-bold'>
                {percentage < 0.3
                  ? 'Creating'
                  : `${percentage < 0.9 ? 'Processing' : 'Ready'}`}
              </h3>
              <div className='flex-1' />
              <h3 className='text-lg font-bold'>
                {(percentage * 100).toFixed(0)}%
              </h3>
            </div>
            <div className='h-48' />
          </>
        )}
      </div>
      <div className='h-10' />
    </LayoutBodyCard>
  );
}
