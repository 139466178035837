/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useState } from "react";
import JoyRide from "react-joyride";
import Icon from "../components/Icon";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import { moveOnboardingWorkflowForward } from "../onboarding/api";
import useAuth from "../../hooks/auth";

const IntroCard = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}: any) => {
  const [searchParams] = useSearchParams()
  const stateId = searchParams.get('state_id')
  const [{ auth }, { setAuth },] = useAuth()

  const completeOnboarding = async () => {
    if (stateId && auth && !(auth.subscription as any).onboardingCompleted) {
      await moveOnboardingWorkflowForward(stateId, {}, auth.tenant, auth.email, auth.id)
      setAuth({
        ...auth,
        subscription: {
          ...auth.subscription,
          onboardingCompleted: true
        }
      })
      window.history.pushState({}, '', `?`)
    }
  }

  return <div className="px-[40px] py-[35px] border rounded-[10px] bg-[#fff] w-[580px]">
    <div className="flex justify-center mb-[25px]">
      <Icon iconName="ztblicon" className="w-[50px] h-[50px]" />
    </div>
    <div className="font-semibold text-[20px] text-center">
      Welcome to ZettaBlock
    </div>
    <div className="text-center mt-[10px] mb-[30px] w-[350px] text-[#777A80] text-[12px] mx-auto">
      <p>ZettaBlock is a full-stack Web3 infrastructure platform that you can explore, build and use Web3 data</p>
    </div>

    <div className="w-[500px] mx-auto mb-[35px]">
      <video controls preload="" poster="https://cdn.chao.link/zettablock/ZettaBlock-Video-Open-Beta-Cover.png" src="https://cdn.chao.link/zettablock/ZettaBlock-Video-Open-Beta.mp4"></video>
    </div>

    <div className="flex justify-center" onClick={() => {
      completeOnboarding()
    }}>
      <div id="next" onClick={primaryProps.onClick} className="border border-[#A6B8F7] rounded-[4px] bg-[#F7F9FF] font-semibold text-[12px] text-[#2915F7] px-[34px] py-[9px] cursor-pointer"
      >
        Get Started
      </div>
    </div>
  </div>
}

const Tooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}: any) => {
  const Content = step.content || <div>No Content</div>


  return (
    <Content {...{
      continuous,
      index,
      step,
      backProps,
      closeProps,
      primaryProps,
      tooltipProps,
    }} />

  )
};

const StepCard = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}: any) => {
  const stepIndexArr = [1, 2, 3, 4]

  return <div className="bg-[#fff] w-[380px] h-[350px] flex flex-col justify-between">
    <div className="w-full p-[20px]">
      <div className="flex justify-between items-center">
        {/* Step Index */}
        <div className="flex gap-[4px] items-center">
          {
            stepIndexArr.map((item, i) => {
              return <div key={i} className={
                classNames("rounded-full w-[14px] h-[14px] text-[10px] leading-[10px] text-semibold flex items-center justify-center", {
                  'bg-[#F1F1FE] text-[#89A1F3]': index !== item,
                  'bg-[#263DFF] text-[#FFFFFF]': index === item,
                })
              }>{item}</div>
            })
          }
        </div>

        {/* Nav Button Group */}
        <div className="flex items-center gap-[8px]">
          {
            index !== 1 && <div className="bg-[#F1F1FE] rounded-full w-[24px] h-[24px] flex items-center justify-center cursor-pointer" onClick={backProps.onClick}>
              <Icon iconName="chevl" className="w-[12px] h-[12px]" />
            </div>
          }
          {
            index !== 4 && <div className="bg-[#263DFF] rounded-full w-[24px] h-[24px] flex items-center justify-center cursor-pointer" onClick={primaryProps.onClick}>
              <Icon iconName="chevr" className="w-[12px] h-[12px]" />
            </div>
          }
          {
            index === 4 && <div className="bg-[#263DFF] rounded-full w-[24px] h-[24px] flex items-center justify-center cursor-pointer" onClick={closeProps.onClick} >
              <Icon iconName="cross-large" className="w-[12px] h-[12px]" />
            </div>
          }
        </div>
      </div>
      <div className="mt-[24px] text-[20px] font-semibold mb-[8px]">
        {step.title}
      </div>
      <div className="text-[14px] leading-[18px] font-[500] text-[#000000B2]">
        {step.description}
      </div>
    </div>
    <Icon iconName={`bg${index}`} className="w-[full] h-[150px] " />
  </div>
}

const Tour = () => {
  const [searchParams] = useSearchParams()
  const [run, setRun] = useState(false);
  useEffect(() => {
    setRun(searchParams.get('runTour') === 'true')
  }, [searchParams.get('runTour')])

  const TOUR_STEPS = [
    {
      content: IntroCard,
      placement: 'center',
      target: 'body',
      disableBeacon: true,
      hideCloseButton: true,
    },

    {
      content: StepCard,
      // placement: 'center',
      target: '#datahub',
      title: 'DataHub',
      description: 'Empower your Web3 applications with real-time, decoded data across 12+ blockchains, 500+ datasets',
      disableBeacon: true,
    },
    {
      content: StepCard,
      // placement: 'center',
      target: '#devstudio',
      title: 'DevStudio',
      description: `Build custom APIs in minutes.Transform, filter and stream data in any shape to anywhere you want via custom low-latency APIs. Index 100X faster than subgraphs, 
      with analytics use cases supported.`,
      disableBeacon: true,
    },
    {
      content: StepCard,
      // placement: 'center',
      disableScrolling: true,
      target: '#prebuilt-apis',
      title: 'Prebuilt APIs',
      disableBeacon: true,
      description: `Launch fast with pre-configured APIs. Get instant access to common blockchain data points and accelerate your project's time-to-market.`
    },
    {
      content: StepCard,
      // placement: 'center',
      target: '.developer-support',
      title: 'Developer Support',
      description: `Need Help? It's one click away!`,
      disableBeacon: true,
    }
  ]

  return (
    <JoyRide
      key={Math.random()}
      disableOverlayClose
      callback={() => {

      }}
      tooltipComponent={Tooltip}
      steps={TOUR_STEPS as any}
      continuous
      run={run}
      styles={{
        options: {
          width: 600,
        },
      }}
    />
  );
}

export default Tour;
